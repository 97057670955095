export const getParams = (str) => {
  if (!str) return {};
  const searchArr = str.toString().substr(1).split("&");
  const searchKeywords = {};

  searchArr.forEach((searchStr) => {
    const [key, value] = searchStr.split("=");

    searchKeywords[key] = value;
  });

  return searchKeywords;
};

export const getParamByName = (queryObj, name) => {
  if (typeof queryObj === "string") {
    const queryParams = getParams(queryObj);

    return queryParams[name];
  }

  if (!queryObj || !name) return null;

  return queryObj[name];
};
