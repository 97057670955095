import { RoleApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

export const ROLE_FETCH_REQUEST = "ROLE_FETCH_REQUEST";
export const ROLE_FETCH_RECEIVED = "ROLE_FETCH_RECEIVED";
export const ROLE_FETCH_FAILED = "ROLE_FETCH_FAILED";
export const PBX_USER_ROLES_FETCH_REQUEST = "PBX_USER_ROLES_FETCH_REQUEST";
export const PBX_USER_ROLES_FETCH_RECEIVED = "PBX_USER_ROLES_FETCH_RECEIVED";
export const PBX_USER_ROLES_FETCH_FAILED = "PBX_USER_ROLES_FETCH_FAILED";
export const ROLE_FETCH_CLEAR_ERRORS = "ROLE_FETCH_CLEAR_ERRORS";

// Fetch all roles
export function roleFetchReceived(response) {
  return {
    type: ROLE_FETCH_RECEIVED,
    roles: response.data,
  };
}

export function roleFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: ROLE_FETCH_FAILED,
    message,
  };
}

export const roleFetchRequest = () => (dispatch) => {
  dispatch({ type: ROLE_FETCH_REQUEST });

  RoleApi.fetchAll()
    .then((data) => dispatch(roleFetchReceived(data)))
    .catch((e) => {
      dispatch(roleFetchFailed(e));
    });
};

// fetch pbx roles
export function pbxUserRolesFetchReceived(response) {
  return {
    type: PBX_USER_ROLES_FETCH_RECEIVED,
    pbxRoles: response.data,
  };
}

export function pbxUserRolesFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: PBX_USER_ROLES_FETCH_FAILED,
    message,
  };
}

export const pbxUserRolesFetchRequest = () => (dispatch) => {
  dispatch({ type: PBX_USER_ROLES_FETCH_REQUEST });

  RoleApi.fetchPbxUserRoles()
    .then((data) => dispatch(pbxUserRolesFetchReceived(data)))
    .catch((e) => {
      dispatch(pbxUserRolesFetchFailed(e));
    });
};
