import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { Translate, t } from "react-i18nify";
import PropTypes from "prop-types";
import { toastr } from "react-redux-toastr";
import { Navigate, useParams } from "react-router-dom";
import { connect } from "react-redux";

import PhoneNumberForm from "components/PhoneNumber/Form/PhoneNumberForm";
import {
  clearPhoneNumberError,
  phoneNumberDetailRequest,
  updatePhoneNumberRequest,
} from "redux/actions/phoneNumber";
import WholePageSpinner from "components/Spinner/WholePageSpinner";
import PageNotFound from "components/Error/PageNotFound/PageNotFound";

import { PHONE_NUMBER } from "constants/routes";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const PhoneNumberEdit = ({
  phoneNumber,
  fetchPhoneNumberDetail,
  updatePhoneNumber,
  isLoading,
  isSubmitting,
  commitSuccess,
  clearErrors,
}) => {
  const { phoneNumberID } = useParams();

  const [isGold, setCostType] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const fetchPhoneNumberData = () => {
    fetchPhoneNumberDetail(phoneNumberID);
  };

  useEffect(() => {
    fetchPhoneNumberData();
  }, [phoneNumberID]);

  useEffect(() => {
    if (!phoneNumber?.id) return;
    const {
      phone_number: number,
      number_type: type,
      status,
      service_provider: { id },
      gold_phone_number: goldPhoneNumber,
    } = phoneNumber;
    const attributes = {
      phone_number: number,
      number_type: type,
      status,
      owner: id,
    };

    if (goldPhoneNumber?.id) {
      setCostType(true);

      attributes.price = goldPhoneNumber.selling_price;
    }

    setInitialValues(attributes);
  }, [phoneNumber]);

  const onSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};
    let attributes = {};

    const {
      phone_number: number,
      number_type: numberType,
      price,
      owner,
    } = values;
    const goldNumberID = phoneNumber.gold_phone_number?.id;
    let goldNumberAttributes = {};

    if (!goldNumberID && isGold) {
      goldNumberAttributes = {
        gold_phone_number_attributes: {
          selling_price: price,
        },
      };
    } else if (goldNumberID && !isGold) {
      goldNumberAttributes = {
        gold_phone_number_attributes: {
          id: goldNumberID,
          _destroy: true,
        },
      };
    }

    attributes = {
      phone_number: {
        phone_number: number,
        number_type: numberType,
        service_provider_id: owner,
        is_hosted: false,
        status: phoneNumber.status,
        ...goldNumberAttributes,
      },
    };

    await updatePhoneNumber(phoneNumber.id, attributes, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess) {
    const { id } = phoneNumber;
    const phoneNumberTranslation = t("toasterMessage.phoneNumber");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.update", {
        resource: phoneNumberTranslation,
      })
    );

    clearErrors();

    return <Navigate to={PHONE_NUMBER.addId(PHONE_NUMBER.SHOW, id)} />;
  }

  if (isLoading) return <WholePageSpinner />;

  if (!phoneNumber.id) {
    return (
      <div className="d-flex h-100 justify-content-center">
        <PageNotFound />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.edit"
            resource={t("title.resource.phoneNumber")}
          />
        </h3>
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, pristine }) => (
          <form
            className="flexo-form px-4 py-4 pt-5 w-100"
            onSubmit={handleSubmit}
          >
            <PhoneNumberForm
              submitting={submitting}
              pristine={pristine}
              isLoading={isSubmitting}
              isGold={isGold}
              setCostType={setCostType}
              isEditForm
            />
          </form>
        )}
      </Form>
    </div>
  );
};

PhoneNumberEdit.defaultProps = {
  isLoading: false,
  isSubmitting: false,
  commitSuccess: false,
};

PhoneNumberEdit.propTypes = {
  isLoading: PropTypes.bool,
  fetchPhoneNumberDetail: PropTypes.func.isRequired,
  phoneNumber: PropTypes.shape({
    id: PropTypes.number,
    phone_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    service_provider: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
    status: PropTypes.string,
    number_type: PropTypes.string,
    gold_phone_number: PropTypes.shape({
      id: PropTypes.number,
      selling_price: PropTypes.number,
    }),
  }).isRequired,
  isSubmitting: PropTypes.bool,
  updatePhoneNumber: PropTypes.func.isRequired,
  commitSuccess: PropTypes.bool,
  clearErrors: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ phoneNumber: phoneNumberState }) => {
  const { phoneNumber, fetchError, commitSuccess, isLoading, isSubmitting } =
    phoneNumberState;

  return {
    phoneNumber,
    fetchError,
    isLoading,
    isSubmitting,
    commitSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPhoneNumberDetail: (id) => {
    dispatch(phoneNumberDetailRequest(id));
  },
  updatePhoneNumber: async (phoneNumberID, attributes, callback) => {
    await dispatch(
      updatePhoneNumberRequest(phoneNumberID, attributes, callback)
    );
  },
  clearErrors: () => {
    dispatch(clearPhoneNumberError());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberEdit);
