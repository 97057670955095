import {
  FAX_STATUS_FETCH_REQUEST,
  FAX_STATUS_FETCH_RECEIVED,
  FAX_STATUS_FETCH_FAILED,
  SINGLE_FAX_STATUS_FETCH_REQUEST,
  SINGLE_FAX_STATUS_FETCH_RECEIVED,
  SINGLE_FAX_STATUS_FETCH_FAILED,
  FAX_STATUS_SEARCH_REQUEST,
  FAX_STATUS_FETCH_CLEAR_ERRORS,
} from "redux/actions/pbxEngine/faxStatus";

const defaultState = {
  faxStatuses: [],
  faxStatusesFetchError: "",
  isSearching: false,
};

function faxStatus(state = defaultState, action) {
  const { faxStatuses, faxStatus: singleFaxStatus } = action;

  switch (action.type) {
    case FAX_STATUS_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FAX_STATUS_FETCH_RECEIVED:
      return {
        ...state,
        faxStatuses,
        isLoading: false,
      };

    case FAX_STATUS_FETCH_FAILED:
      return {
        ...state,
        faxStatusesFetchError: action.message,
      };

    case SINGLE_FAX_STATUS_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitSuccess: false,
      };

    case SINGLE_FAX_STATUS_FETCH_RECEIVED:
      return {
        ...state,
        faxStatus: singleFaxStatus,
        isLoading: false,
      };

    case SINGLE_FAX_STATUS_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case FAX_STATUS_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    case FAX_STATUS_FETCH_CLEAR_ERRORS:
      return { ...state, faxStatusesFetchError: "" };

    default:
      return state;
  }
}

export default faxStatus;
