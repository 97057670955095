import React from "react";
import PropTypes from "prop-types";
import { Localize } from "react-i18nify";

const TranslateNumber = ({
  value,
  minimumFractionDigits,
  maximumFractionDigits,
}) => {
  if (value === undefined || value === null) return null;

  return (
    <Localize
      value={value}
      options={{
        minimumFractionDigits,
        maximumFractionDigits,
      }}
    />
  );
};

TranslateNumber.defaultProps = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

TranslateNumber.propTypes = {
  value: PropTypes.number.isRequired,
  minimumFractionDigits: PropTypes.number,
  maximumFractionDigits: PropTypes.number,
};

export default TranslateNumber;
