import { CELL_PHONE_ALLOWED_ACTIONS } from "constants/rolePermission";
import USER_ROLES, {
  ADMIN_ROLES,
  BUSINESS_USER_ROLES,
  BUSINESS_ADMIN_ROLES,
  ADMIN_ROLES_WITH_PBX_ADMIN,
} from "constants/userRoles";

/*
**
Super Admin:
  - Should be able to access everything

Admin
  - Restriction on crud of super admin

Customer Admin:
  - Contacts - CRU (Create, read and update)
  - Agreements - View details
  - Invoices - View Details
  - PBX - View Details

  For features inside PBX:
    User License - CRU
    Did Numbers - CRU
    Cell Phone: CRU

Customer user:
  - Invoice - restrict access
  - Agreement - restrict access
  - Debtor - Detail only
  - PBX - Detail only

PBX User:
  - User License: Read
  - Cell Phone: CRU
**
*/

const rules = {};

// Super admin has all access
rules[USER_ROLES.SUPER_ADMIN] = {
  dynamic: {
    "users:delete": ({ userId, currentUser }) => userId !== currentUser.id,
    "users:edit": ({ userId, currentUser }) => userId === currentUser.id,
  },
};

rules[USER_ROLES.ADMIN] = {
  dynamic: {
    "users:edit": ({ role }) => role !== USER_ROLES.SUPER_ADMIN,
    "settings:delete": ({ currentUser: { role } }) =>
      role === USER_ROLES.SUPER_ADMIN,
    "subscriptionProduct:delete": ({ currentUser: { role } }) =>
      role === USER_ROLES.SUPER_ADMIN,
    "agreements:delete": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "pbx:delete": ({ currentUser: { role } }) => ADMIN_ROLES.includes(role),
    "pbx:sipDevices": ({ currentUser: { role } }) => ADMIN_ROLES.includes(role),
    "userLicense:delete": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "userLicense:edit": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "cellPhone:delete": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "cellPhone:edit": ({ currentUser: { role } }) => ADMIN_ROLES.includes(role),
    "didNumber:delete": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "didNumber:update": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "users:view": ({ currentUser: { role } }) => ADMIN_ROLES.includes(role),
    "invoiceDetail:view": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "addOnSubscription:edit": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "cellPhone:simCardOrder": ({ currentUser: { role } }) =>
      BUSINESS_USER_ROLES.includes(role),
    "quotation:view": ({ currentUser: { role } }) => ADMIN_ROLES.includes(role),
    "invoiceCreditNotesAndBills:view": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "pbx:didNumbers:list": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "agreement:generateInvoice": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "agreement:statusLabel": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "cellPhone:assignNewSIMCard": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "cellPhone:orderSIMCard": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "cellPhone:reactivate": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "cellPhone:suspend": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "cellPhone:subscriptionDetails": ({ currentUser: { role } }) =>
      BUSINESS_ADMIN_ROLES.includes(role),
    "userLicense:didNumber:list": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "pbx:cellPhones": ({ currentUser: { role } }) => ADMIN_ROLES.includes(role),
    "userLicense:subscriptionDetails:view": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "pbx:subscriptionDetails:view": ({ currentUser: { role } }) =>
      ADMIN_ROLES.includes(role),
    "pbx:subscriptionDetails": ({ currentUser: { role } }) =>
      BUSINESS_ADMIN_ROLES.includes(role),
    "didNumber:subscriptionDetails": ({ currentUser: { role } }) =>
      BUSINESS_ADMIN_ROLES.includes(role),
  },
};

rules[USER_ROLES.CUSTOMER_ADMIN] = {
  static: [
    "invoices:sidemenu",
    "agreements:sidemenu",
    "userLicense:subscriptionDetails:view",
    "pbx:subscriptionDetails:view",
    "pbx:didNumbers:list",
    "userLicense:didNumbers:list",
    "contacts:sidemenu",
  ],
  dynamic: {
    "users:edit": ({ userId, currentUser }) => userId === currentUser.id,
    "users:view": ({ userId, currentUser }) => userId === currentUser.id,
    "debtors:view": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "debtors:edit": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "contacts:list": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "agreements:list": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "agreements:view": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "invoices:list": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "credit_notes:list": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "bills:list": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "vendors:list": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "pbx:list": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "users:pbxs_view": ({ userId, currentUser }) => userId === currentUser.id,
    "vendors:view": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "pbx:didNumbers:list": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "cellPhone:assignNewSIMCard": ({ action, currentUser: { role } }) =>
      BUSINESS_ADMIN_ROLES.includes(role) &&
      CELL_PHONE_ALLOWED_ACTIONS.includes(action),
    "cellPhone:orderSIMCard": ({ action, currentUser: { role } }) =>
      BUSINESS_ADMIN_ROLES.includes(role) &&
      CELL_PHONE_ALLOWED_ACTIONS.includes(action),
    "cellPhone:reactivate": ({ action, currentUser: { role } }) =>
      BUSINESS_ADMIN_ROLES.includes(role) &&
      CELL_PHONE_ALLOWED_ACTIONS.includes(action),
    "cellPhone:suspend": ({ action, currentUser: { role } }) =>
      BUSINESS_ADMIN_ROLES.includes(role) &&
      CELL_PHONE_ALLOWED_ACTIONS.includes(action),
    "userLicense:didNumber:list": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "pbx:cellPhones": ({ currentUser: { role } }) =>
      USER_ROLES.CUSTOMER_ADMIN === role,
    "pbx:sipDevices": ({ currentUser: { role } }) =>
      USER_ROLES.CUSTOMER_ADMIN === role,
    "userLicense:cellPhones": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "pbx:subscriptionDetails": ({ currentUser: { role } }) =>
      BUSINESS_ADMIN_ROLES.includes(role),
    "didNumber:subscriptionDetails": ({ currentUser: { role } }) =>
      BUSINESS_ADMIN_ROLES.includes(role),
  },
};

rules[USER_ROLES.CUSTOMER_USER] = {
  static: ["contacts:sidemenu"],
  dynamic: {
    "users:edit": ({ userId, currentUser }) => userId === currentUser.id,
    "users:view": ({ userId, currentUser }) => userId === currentUser.id,
    "debtors:view": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "contacts:list": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "pbx:list": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "users:pbxs_view": () => false,
    "didNumbers:list": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "cellPhone:assignNewSIMCard": ({ action, currentUser: { role } }) =>
      BUSINESS_USER_ROLES.includes(role) &&
      CELL_PHONE_ALLOWED_ACTIONS.includes(action),
    "cellPhone:orderSIMCard": ({ action, currentUser: { role } }) =>
      BUSINESS_USER_ROLES.includes(role) &&
      CELL_PHONE_ALLOWED_ACTIONS.includes(action),
    "cellPhone:reactivate": ({ action, currentUser: { role } }) =>
      BUSINESS_USER_ROLES.includes(role) &&
      CELL_PHONE_ALLOWED_ACTIONS.includes(action),
    "cellPhone:suspend": ({ action, currentUser: { role } }) =>
      BUSINESS_USER_ROLES.includes(role) &&
      CELL_PHONE_ALLOWED_ACTIONS.includes(action),
    "pbx:cellPhones": ({ currentUser: { role } }) =>
      USER_ROLES.CUSTOMER_USER === role,
    "pbx:didNumbers:list": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "userLicense:didNumber:list": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "userLicense:cellPhones": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "pbx:sipDevices": ({ currentUser: { role } }) =>
      USER_ROLES.CUSTOMER_USER === role,
  },
};

rules[USER_ROLES.PBX_ADMIN] = {
  static: [],
  dynamic: {
    "debtors:view": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "pbx:list": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "users:view": ({ userId, currentUser }) => userId === currentUser.id,
    "cellPhone:assignNewSIMCard": ({ action, currentUser: { role } }) =>
      BUSINESS_USER_ROLES.includes(role) &&
      CELL_PHONE_ALLOWED_ACTIONS.includes(action),
    "cellPhone:orderSIMCard": ({ action, currentUser: { role } }) =>
      BUSINESS_USER_ROLES.includes(role) &&
      CELL_PHONE_ALLOWED_ACTIONS.includes(action),
    "cellPhone:reactivate": ({ action, currentUser: { role } }) =>
      BUSINESS_USER_ROLES.includes(role) &&
      CELL_PHONE_ALLOWED_ACTIONS.includes(action),
    "cellPhone:suspend": ({ action, currentUser: { role } }) =>
      BUSINESS_USER_ROLES.includes(role) &&
      CELL_PHONE_ALLOWED_ACTIONS.includes(action),
    "pbx:cellPhones": ({ currentUser: { role } }) =>
      ADMIN_ROLES_WITH_PBX_ADMIN.includes(role),
    "userLicense:cellPhones": ({ debtorID, currentUser }) =>
      debtorID === currentUser.odoo_debtor_id,
    "pbx:sipDevices": ({ currentUser: { role } }) =>
      ADMIN_ROLES_WITH_PBX_ADMIN.includes(role),
  },
};

export default rules;
