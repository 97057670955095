import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

const ProductNameWithLabel = ({ isSetup, name }) => {
  if (!isSetup) return name;

  return (
    <>
      {name}
      <span className="pill status bg-green px-3 py-1 ms-2">
        <Translate value="pill.status.setup" />
      </span>
    </>
  );
};

ProductNameWithLabel.defaultProps = {
  isSetup: false,
};

ProductNameWithLabel.propTypes = {
  isSetup: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default ProductNameWithLabel;
