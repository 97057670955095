import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";
import { Col, Spinner } from "reactstrap";

import FetchError from "components/Error/FetchError";
import {
  UsersSkeleton,
  AgreementsSkeleton,
  DebtorsSkeleton,
} from "components/Skeleton/Dashboard";
import "components/Dashboard/DashboardComponent/DashboardComponent.scss";

import { DASHBOARD_COMPONENT_FEATURE_TYPES } from "constants/constant";

const DefaultLoadingComponent = () => (
  <div className="d-flex justify-content-center align-items-center h-75">
    <Spinner />
  </div>
);

const getSkeleton = (title) => {
  switch (title) {
    case DASHBOARD_COMPONENT_FEATURE_TYPES.INVOICES:
    case DASHBOARD_COMPONENT_FEATURE_TYPES.BILLS:
    case DASHBOARD_COMPONENT_FEATURE_TYPES.AGREEMENTS:
      return <AgreementsSkeleton />;
    case DASHBOARD_COMPONENT_FEATURE_TYPES.USERS:
      return <UsersSkeleton />;
    case DASHBOARD_COMPONENT_FEATURE_TYPES.DEBTORS:
      return <DebtorsSkeleton />;
    default:
      return <DefaultLoadingComponent />;
  }
};

const DisplayContent = ({
  isLoading,
  fetchError,
  onRetry,
  content,
  featureType,
}) => {
  if (isLoading) {
    return getSkeleton(featureType);
  }

  if (fetchError) {
    return <FetchError onRetry={onRetry} buttonTitle="error.retry" />;
  }

  return content;
};

const DashboardComponent = ({
  translationTitle,
  isLoading,
  fetchError,
  onRetry,
  children,
  columnSize: { lg, md, sm },
  height,
  featureType,
}) => (
  <Col lg={lg} md={md} sm={sm}>
    <div className="dashboard-item" style={{ height }} data-cy="dashboard-item">
      <div className="item-title">
        <div className="icon-title d-flex">
          <Translate value={translationTitle} />
        </div>
      </div>
      <DisplayContent
        featureType={featureType}
        isLoading={isLoading}
        translationTitle={translationTitle}
        fetchError={fetchError}
        onRetry={onRetry}
        content={children}
      />
    </div>
  </Col>
);

DisplayContent.defaultProps = {
  isLoading: false,
  fetchError: false,
  onRetry: () => {},
  content: null,
};

DisplayContent.propTypes = {
  isLoading: PropTypes.bool,
  fetchError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onRetry: PropTypes.func,
  content: PropTypes.node,
  featureType: PropTypes.string.isRequired,
};

DashboardComponent.defaultProps = {
  children: null,
  isLoading: false,
  fetchError: false,
  onRetry: () => {},
  item: {},
  columnSize: {
    lg: 4,
    md: 6,
    sm: 12,
  },
  height: "24.5em",
};

DashboardComponent.propTypes = {
  translationTitle: PropTypes.string.isRequired,
  item: PropTypes.shape({
    count: PropTypes.number,
    new: PropTypes.number,
    active_count: PropTypes.number,
    active_amount: PropTypes.number,
    archive_count: PropTypes.number,
    archive_amount: PropTypes.number,
    paid_count: PropTypes.number,
    unpaid_count: PropTypes.number,
    unpaid_amount: PropTypes.number,
    draft_count: PropTypes.number,
    draft_amount: PropTypes.number,
    new_list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        business_name: PropTypes.string,
      })
    ),
  }),
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  fetchError: PropTypes.bool,
  onRetry: PropTypes.func,
  columnSize: PropTypes.shape({
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
  }),
  height: PropTypes.string,
  featureType: PropTypes.string.isRequired,
};

export default DashboardComponent;
