import {
  DID_NUMBER_FETCH_REQUEST,
  DID_NUMBER_FETCH_RECEIVED,
  DID_NUMBER_FETCH_FAILED,
  DID_NUMBER_CREATE_REQUEST,
  DID_NUMBER_CREATE_RECEIVED,
  DID_NUMBER_CREATE_FAILED,
  SINGLE_DID_NUMBER_FETCH_REQUEST,
  SINGLE_DID_NUMBER_FETCH_RECEIVED,
  SINGLE_DID_NUMBER_FETCH_FAILED,
  DID_NUMBER_UPDATE_REQUEST,
  DID_NUMBER_UPDATE_RECEIVED,
  DID_NUMBER_UPDATE_FAILED,
  DID_NUMBER_FETCH_CLEAR_ERRORS,
  DID_NUMBER_SEARCH_REQUEST,
} from "redux/actions/didNumber";

const defaultState = {
  didNumbers: [],
  commitError: "",
  fetchError: "",
  commitSuccess: false,
  isLoading: false,
  isSearching: false,
};

function didNumber(state = defaultState, action) {
  const { didNumbers, didNumber: singleDidNumber } = action;

  switch (action.type) {
    case DID_NUMBER_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitError: "",
      };

    case DID_NUMBER_FETCH_RECEIVED:
      return {
        ...state,
        didNumbers,
        isLoading: false,
      };

    case DID_NUMBER_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case SINGLE_DID_NUMBER_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitError: "",
        commitSuccess: false,
      };

    case SINGLE_DID_NUMBER_FETCH_RECEIVED:
      return {
        ...state,
        didNumber: singleDidNumber,
        isLoading: false,
      };

    case SINGLE_DID_NUMBER_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case DID_NUMBER_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSubmitting: true,
        commitSuccess: false,
        commitError: "",
      };

    case DID_NUMBER_UPDATE_RECEIVED:
      return {
        ...state,
        didNumber: singleDidNumber,
        isLoading: false,
        isSubmitting: false,
        commitSuccess: true,
      };

    case DID_NUMBER_UPDATE_FAILED:
      return {
        ...state,
        isSubmitting: false,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case DID_NUMBER_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case DID_NUMBER_CREATE_RECEIVED:
      return {
        ...state,
        didNumber: singleDidNumber,
        isLoading: false,
        commitSuccess: true,
      };

    case DID_NUMBER_CREATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case DID_NUMBER_FETCH_CLEAR_ERRORS:
      return {
        ...state,
        fetchError: "",
        isLoading: false,
        commitSuccess: false,
        commitError: "",
      };

    case DID_NUMBER_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    default:
      return state;
  }
}

export default didNumber;
