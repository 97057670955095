import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Form, FormGroup } from "reactstrap";
import { Form as ReactFinalForm, Field } from "react-final-form";
import { Translate } from "react-i18nify";

import { email, required } from "utils/validation";

import { renderInputField } from "components/ReduxForm/RenderField";
import LoginSpinner from "components/Login/LoginSpinner";

import { AUTH } from "constants/routes";

const ResetPasswordErrorMessage = ({ resetPasswordError }) => {
  if (!resetPasswordError) return null;

  return (
    <p className="text-danger">
      <Translate value={resetPasswordError} />;
    </p>
  );
};

const ResetPasswordForm = ({ onSubmit, resetPasswordError, isLoading }) => (
  <ReactFinalForm
    onSubmit={onSubmit}
    render={({ pristine, submitting, handleSubmit }) => {
      const isButtonDisabled = pristine || submitting || isLoading;
      const validateEmailField = (value) => [required(value), email(value)];

      return (
        <Form className="login-form w-100" onSubmit={handleSubmit}>
          <ResetPasswordErrorMessage resetPasswordError={resetPasswordError} />
          <p className="mb-4 text-secondary mb-4">
            <Translate value="auth.resetPasswordLabel" />
          </p>
          <FormGroup>
            <Field
              type="text"
              name="email"
              component={renderInputField}
              labelTranslation="auth.emailField"
              placeholder="user@sc.dk"
              validate={validateEmailField}
            />
          </FormGroup>
          <Link
            to={AUTH.LOGIN}
            className="mt-3 d-flex align-items-end justify-content-start justify-content-sm-end"
          >
            <Translate value="auth.signInInstead" />
          </Link>

          <button
            disabled={isButtonDisabled}
            type="submit"
            className="btn btn-lg btn-block login-button btn-primary mt-4 mb-3 d-flex justify-content-center align-items-center w-100"
            value="Submit"
            data-cy="submit"
          >
            <LoginSpinner isLoading={isLoading} submitting={submitting} />
            <Translate value="auth.resetPasswordText" />
          </button>
        </Form>
      );
    }}
  />
);

ResetPasswordErrorMessage.defaultProps = { resetPasswordError: "" };
ResetPasswordErrorMessage.propTypes = {
  resetPasswordError: PropTypes.bool,
};

ResetPasswordForm.defaultProps = {
  resetPasswordError: "",
};
ResetPasswordForm.propTypes = {
  resetPasswordError: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ResetPasswordForm;
