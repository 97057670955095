import { toastr } from "react-redux-toastr";

import { t } from "react-i18nify";
import { createSearchAction } from "redux-search";

import { SipDevicesApi } from "internal";

import {
  getAndDisplayErrors,
  displayFetchErrorToaster,
  getServerError,
} from "utils/utils";

export const FETCH_SIP_DEVICES_REQUEST = "FETCH_SPI_DEVICES_REQUEST";
export const FETCH_SIP_DEVICES_RECEIVED = "FETCH_SIP_DEVICES_RECEIVED";
export const FETCH_SIP_DEVICES_FAILED = "FETCH_SIP_DEVICES_FAILED";
export const SIP_DEVICES_SEARCH_REQUEST = "SIP_DEVICES_SEARCH_REQUEST";

export const SINGLE_SIP_DEVICE_FETCH_REQUEST =
  "SINGLE_SIP_DEVICE_FETCH_REQUEST";
export const SINGLE_SIP_DEVICE_FETCH_RECEIVED =
  "SINGLE_SIP_DEVICE_FETCH_RECEIVED";
export const SINGLE_SIP_DEVICE_FETCH_FAILED = "SINGLE_SIP_DEVICE_FETCH_FAILED";

export const SIP_DEVICE_CREATE_REQUEST = "SIP_DEVICE_CREATE_REQUEST";
export const SIP_DEVICE_CREATE_RECEIVED = "SIP_DEVICE_CREATE_RECEIVED";
export const SIP_DEVICE_CREATE_FAILED = "SIP_DEVICE_CREATE_FAILED";

export const SIP_DEVICE_UPDATE_REQUEST = "SIP_DEVICE_UPDATE_REQUEST";
export const SIP_DEVICE_UPDATE_RECEIVED = "SIP_DEVICE_UPDATE_RECEIVED";
export const SIP_DEVICE_UPDATE_FAILED = "SIP_DEVICE_UPDATE_FAILED";

export const SINGLE_SIP_DEVICE_DELETE_REQUEST =
  "SINGLE_SIP_DEVICE_DELETE_REQUEST";
export const SINGLE_SIP_DEVICE_DELETE_RECEIVED =
  "SINGLE_SIP_DEVICE_DELETE_RECEIVED";
export const SINGLE_SIP_DEVICE_DELETE_FAILED =
  "SINGLE_SIP_DEVICE_DELETE_FAILED";

export const FETCH_SIP_DEVICE_DEFAULT_FORM_VALUE_REQUEST =
  "FETCH_SIP_DEVICE_FORM_VALUE_REQUEST";
export const FETCH_SIP_DEVICE_DEFAULT_FORM_VALUE_RECEIVED =
  "FETCH_SIP_DEVICE_FORM_VALUE_RECEIVED";
export const FETCH_SIP_DEVICE_DEFAULT_FORM_VALUE_FAILED =
  "FETCH_SIP_DEVICE_FORM_VALUE_FAILED";

export const SIP_DEVICE_FETCH_CLEAR_ERRORS = "SIP_DEVICE_FETCH_CLEAR_ERRORS";

export function sipDeviceFetchClearErrors() {
  return {
    type: SIP_DEVICE_FETCH_CLEAR_ERRORS,
  };
}

// Create a sip device
export function sipDeviceCreateReceived(response) {
  return {
    type: SIP_DEVICE_CREATE_RECEIVED,
    sipDevice: response.data,
  };
}

export function sipDeviceCreateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: SIP_DEVICE_CREATE_FAILED,
    message: response.data,
  };
}

export const sipDeviceCreateRequest =
  (userLicense, attributes, callback) => async (dispatch) => {
    dispatch({ type: SIP_DEVICE_CREATE_REQUEST });

    await SipDevicesApi.create(userLicense, attributes)
      .then((data) => {
        dispatch(sipDeviceCreateReceived(data));
        callback();
      })
      .catch((e) => {
        dispatch(sipDeviceCreateFailed(e, callback));
      });
  };

// fetch default sip device form value
const sipDeviceDefaultFormValueReceived = (response) => ({
  type: FETCH_SIP_DEVICE_DEFAULT_FORM_VALUE_RECEIVED,
  sipDevice: response.data,
});

const sipDeviceDefaultFormValueFailed = (errorData) => {
  const { response } = errorData;

  if (response.data.status) {
    getAndDisplayErrors(errorData);
  }

  return {
    type: FETCH_SIP_DEVICE_DEFAULT_FORM_VALUE_FAILED,
    message: response.data,
  };
};

export const fetchSipDeviceDefaultFormValueRequest =
  (userLicenseID) => (dispatch) => {
    dispatch({ type: FETCH_SIP_DEVICE_DEFAULT_FORM_VALUE_REQUEST });

    SipDevicesApi.fetchDefaultFormValue(userLicenseID)
      .then((data) => dispatch(sipDeviceDefaultFormValueReceived(data)))
      .catch((e) => {
        dispatch(sipDeviceDefaultFormValueFailed(e));
      });
  };

const singleSipDeviceFetchReceived = (response) => ({
  type: SINGLE_SIP_DEVICE_FETCH_RECEIVED,
  sipDevice: response.data,
});

const singleSipDeviceFetchFailed = (errorData) => {
  const message = getAndDisplayErrors(errorData);

  return {
    type: SINGLE_SIP_DEVICE_FETCH_FAILED,
    message,
  };
};

export const singleSipDeviceFetchRequest = (id) => (dispatch) => {
  dispatch({ type: SINGLE_SIP_DEVICE_FETCH_REQUEST });

  SipDevicesApi.fetchOne(id)
    .then((data) => dispatch(singleSipDeviceFetchReceived(data)))
    .catch((e) => {
      dispatch(singleSipDeviceFetchFailed(e));
    });
};

// Update a sip device
const sipDeviceUpdateReceived = (response) => ({
  type: SIP_DEVICE_UPDATE_RECEIVED,
  sipDevice: response.data,
});

const sipDeviceUpdateFailed = (errorData, callback = () => {}) => {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: SIP_DEVICE_UPDATE_FAILED,
    message: response.data,
  };
};

export const sipDeviceUpdateRequest =
  (id, attributes, callback) => async (dispatch) => {
    dispatch({ type: SIP_DEVICE_UPDATE_REQUEST });

    await SipDevicesApi.update(id, attributes)
      .then((data) => {
        dispatch(sipDeviceUpdateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(sipDeviceUpdateFailed(errors, callback));
      });
  };

// delete single sip device
export const singleSipDeviceDeleteReceived =
  (response, callback) => (dispatch) => {
    dispatch({
      type: SINGLE_SIP_DEVICE_DELETE_RECEIVED,
    });

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.delete", {
        resource: t("common.sipDevice"),
      })
    );

    if (typeof callback === "function") {
      callback();
    }
  };

export const singleSipDeviceDeleteFailed = (errorData) => {
  const { response } = errorData;
  const error = getServerError(response);
  const errorText =
    error === "not found" ? t("toasterMessage.processError") : error;

  displayFetchErrorToaster(errorText);

  return (dispatch) => {
    dispatch({
      type: SINGLE_SIP_DEVICE_DELETE_FAILED,
      message: errorText,
    });
  };
};

export const singleSipDeviceDeleteRequest = (id, callback) => (dispatch) => {
  dispatch({
    type: SINGLE_SIP_DEVICE_DELETE_REQUEST,
  });

  SipDevicesApi.deleteOne(id)
    .then((data) => dispatch(singleSipDeviceDeleteReceived(data, callback)))
    .catch((e) => {
      dispatch(singleSipDeviceDeleteFailed(e));
    });
};
export function sipPhonesFetchReceived(response) {
  return {
    type: FETCH_SIP_DEVICES_RECEIVED,
    sipDevices: response.data,
  };
}

export function sipPhonesFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: FETCH_SIP_DEVICES_FAILED,
    message,
  };
}

// Fetch all sip devices
export const sipDevicesFetchRequest = (userLicenseID) => (dispatch) => {
  dispatch({ type: FETCH_SIP_DEVICES_REQUEST });

  SipDevicesApi.fetchAllSipDevices(userLicenseID)
    .then((data) => dispatch(sipPhonesFetchReceived(data)))
    .catch((e) => {
      dispatch(sipPhonesFetchFailed(e));
    });
};

// Search sip device
export const searchSipDevices = createSearchAction("sipDevices");

export const sipDevicesSearchRequest = (value) => {
  const isSearching = Boolean(value);

  return {
    type: SIP_DEVICES_SEARCH_REQUEST,
    isSearching,
  };
};
