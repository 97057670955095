import React from "react";
import { Outlet } from "react-router-dom";
import { Translate } from "react-i18nify";

import TabWithRoute from "components/Tab/TabWithRoute/TabWithRoute";

const NumberPortingRequestIndex = () => {
  const tabRoutesDetail = [
    {
      tabRoute: "imports",
      tabTitle: <Translate value="title.page.numberPortingRequest.imports" />,
    },
    {
      tabRoute: "exports",
      tabTitle: <Translate value="title.page.numberPortingRequest.exports" />,
    },
  ];

  return (
    <TabWithRoute resourceName="number-porting" routesDetail={tabRoutesDetail}>
      <Outlet />
    </TabWithRoute>
  );
};

export default NumberPortingRequestIndex;
