import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Translate } from "react-i18nify";
import { useForm } from "react-final-form";
import { useFieldArray } from "react-final-form-arrays";

import MainSubscriptionSection from "components/Quotation/Form/Modal/MainSubscriptionSection";
import OtherProductsSection from "components/Quotation/Form/Modal/OtherProductsSection";

const SubscriptionLinesModal = ({
  title,
  subTitle,
  buttonTitle,
  modalVisible,
  onCancel,
  fields,
  products,
  isProductFetching,
  onSubmit,
  subscriptionField,
  dataModalButton,
}) => {
  const form = useForm();
  const subscriptionLineFieldArrayRenderProps = useFieldArray(fields.name);

  if (!modalVisible) return null;

  const handleOnSubmit = () => {
    const {
      meta: { invalid },
    } = subscriptionLineFieldArrayRenderProps;

    if (invalid) {
      form.submit();
    }

    onSubmit();
  };

  const zeroSubscriptions = fields.length === 0;

  return (
    <Modal
      isOpen={modalVisible}
      centered
      toggle={onCancel}
      className="modal-xl"
    >
      <ModalHeader className="border-0 pb-0 mb-4" toggle={onCancel}>
        {title}
      </ModalHeader>
      <ModalBody className="subscription-modal-body pt-0">
        <h6 className="main-subscription-title border-bottom pb-2 mb-4 fw-bold">
          {subTitle}
        </h6>
        <MainSubscriptionSection
          fields={fields}
          products={products}
          isProductFetching={isProductFetching}
          subscriptionField={subscriptionField}
        />

        <h6 className="border-bottom pb-2 mt-3 mb-4 fw-bold">
          <Translate value="quotation.otherProducts" />
        </h6>
        <OtherProductsSection
          fields={fields}
          products={products}
          subscriptionField={subscriptionField}
          isProductFetching={isProductFetching}
        />
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-primary mt-3 d-flex ms-auto"
          type="button"
          onClick={handleOnSubmit}
          data-cy={dataModalButton}
          disabled={zeroSubscriptions}
        >
          {buttonTitle}
        </button>
      </ModalFooter>
    </Modal>
  );
};

SubscriptionLinesModal.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    update: PropTypes.func,
    push: PropTypes.func,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        subscriptionProducts: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.number,
        }),
        subscription_attributes: PropTypes.shape({
          subscription_lines_attributes: PropTypes.shape({
            line_type: PropTypes.string,
            odoo_product_id: PropTypes.number,
            price: PropTypes.number,
            product_name: PropTypes.string,
            quantity: PropTypes.number,
            subscription_lines_properties_attributes: PropTypes.arrayOf(
              PropTypes.shape({
                property: PropTypes.string,
                value: PropTypes.string,
              })
            ),
            setup_subscription_line: PropTypes.shape({
              context_type: PropTypes.number,
              line_type: PropTypes.string,
              odoo_product_id: PropTypes.number,
              price: PropTypes.number,
              product_name: PropTypes.string,
              subscription_lines_properties_attributes: PropTypes.arrayOf(
                PropTypes.shape({
                  property: PropTypes.string,
                  value: PropTypes.string,
                })
              ),
            }),
          }),
        }),
      })
    ),
    length: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isProductFetching: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    reset: PropTypes.func,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  subscriptionField: PropTypes.shape({}).isRequired,
  dataModalButton: PropTypes.string.isRequired,
};

const mapStateToProps = ({ product }) => {
  const { products, isLoading: isProductFetching } = product;

  return {
    products,
    isProductFetching,
  };
};

export default connect(mapStateToProps)(SubscriptionLinesModal);
