import React from "react";
import PropTypes from "prop-types";
import { Translate, t } from "react-i18nify";
import { FieldArray } from "react-final-form-arrays";
import { useField } from "react-final-form";

import TranslateNumber from "components/i18n/TranslateNumber";
import SubscriptionListTableRow from "components/Quotation/Form/Subscription/SubscriptionListTableRow";
import SubscriptionLinesModal from "components/Quotation/Form/Modal/SubscriptionLinesModal";

const SubscriptionListTable = ({
  fields,
  toggleModalVisibility,
  modalVisible,
  addNewSubscription,
  resetTemporarySubscription,
  temporarySubscriptionField,
}) => {
  const {
    input: {
      value: {
        all_total_setup_price: allTotalSetupPrice,
        all_total_monthly_price: allTotalMonthlyPrice,
      },
    },
  } = useField("agreement");

  const subscriptionList = fields.value.map((subscription, index) => {
    const fieldName = `agreement.subscriptions[${index}]`;

    const { _destroy: isDeleted } = subscription;

    if (isDeleted) {
      return null;
    }

    return (
      <SubscriptionListTableRow
        fieldName={fieldName}
        toggleModalVisibility={toggleModalVisibility}
        key={fieldName}
        fields={fields}
        currentIndex={index}
      />
    );
  });

  const enabledClassName = fields.length === 0 ? "pe-none" : "";

  return (
    <div className={`subscription-listing-table ${enabledClassName}`}>
      <div className="overflow-auto col-12">
        <table className="table table-borderless subscription-listing-table-container mb-0">
          <thead className="accordion-header">
            <tr className="w-100 subscription-detail add-on-detail">
              <th className="py-3 align-middle">
                <p className="subscription-detail-header mb-0 text-center fw-bold">
                  <Translate value="attributes.subscriptions" />
                </p>
              </th>
              <th className="py-3 align-middle">
                <p className="subscription-detail-header mb-0 text-center fw-bold">
                  <Translate value="attributes.quantity" />
                </p>
              </th>
              <th className="py-3 align-middle">
                <p className="subscription-detail-header mb-0 text-center fw-bold">
                  <Translate value="common.setupPrice" />
                </p>
              </th>
              <th className="py-3 align-middle">
                <p className="subscription-detail-header mb-0 text-center fw-bold">
                  <Translate value="attributes.monthlyPrice" />
                </p>
              </th>
              <th className="py-3 align-middle">
                <p className="subscription-detail-header mb-0 text-center fw-bold">
                  <Translate value="attributes.totalSetupPrice" />
                </p>
              </th>
              <th className="py-3 align-middle">
                <p className="subscription-detail-header mb-0 text-center fw-bold">
                  <Translate value="attributes.totalMonthlyPrice" />
                </p>
              </th>
              <th className="py-3 align-middle">
                <p className="subscription-detail-header mb-0 text-center fw-bold">
                  <Translate value="common.action" />
                </p>
              </th>
            </tr>
          </thead>

          <tbody className={`border-bottom-0 px-3 py-0 `}>
            {subscriptionList}

            <tr className="add-on-subscription-row total-calculation">
              <td colSpan={3} />
              <td className="mb-0 text-center fw-bold py-3">
                <Translate value="common.total" />
              </td>
              <td
                className="mb-0 text-center fw-bold py-3"
                data-field-name="subscriptions-total-setup-price"
              >
                <TranslateNumber
                  value={allTotalSetupPrice ?? 0}
                  minimumFractionDigits={2}
                />
              </td>
              <td
                className="mb-0 text-center fw-bold py-3"
                data-field-name="subscriptions-total-monthly-price"
              >
                <TranslateNumber
                  value={allTotalMonthlyPrice ?? 0}
                  minimumFractionDigits={2}
                />
              </td>
            </tr>

            <tr className="subscription-listing-table-row py-3">
              <td className="align-middle text-end" colSpan={7}>
                <FieldArray
                  name="agreement.temporary_subscription.subscription_lines"
                  component={SubscriptionLinesModal}
                  title={<Translate value="common.addSubscription" />}
                  subTitle={<Translate value="common.mainSubscription" />}
                  buttonTitle={<Translate value="common.addSubscription" />}
                  modalVisible={modalVisible}
                  onCancel={resetTemporarySubscription}
                  onSubmit={addNewSubscription}
                  subscriptionField={temporarySubscriptionField}
                  dataModalButton="add-subscription-btn"
                />

                <button
                  className="btn btn-primary mt-3"
                  type="button"
                  onClick={toggleModalVisibility}
                  data-cy="add-subscription"
                >
                  <Translate
                    value="title.resource.button.addResource"
                    resource={t(`common.subscription`)}
                  />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

SubscriptionListTable.defaultProps = {};

SubscriptionListTable.propTypes = {
  fields: PropTypes.shape({
    remove: PropTypes.func,
    push: PropTypes.func,
    pop: PropTypes.func,
    map: PropTypes.func,
    length: PropTypes.func,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        length: PropTypes.func,
      })
    ),
  }).isRequired,
  toggleModalVisibility: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  addNewSubscription: PropTypes.func.isRequired,
  resetTemporarySubscription: PropTypes.func.isRequired,
  temporarySubscriptionField: PropTypes.shape({}).isRequired,
};

export default SubscriptionListTable;
