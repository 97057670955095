import React, { useState } from "react";
import PropTypes from "prop-types";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faArchive } from "@fortawesome/free-solid-svg-icons";

import { Translate } from "react-i18nify";

import SubscriptionGroupTab from "components/Agreement/AgreementDetail/Subscription/SubscriptionGroupTab/SubscriptionGroupTab";
import TranslateNumber from "components/i18n/TranslateNumber";

const SubscriptionTab = ({
  subscriptions,
  isAgreementArchived,
  products,
  activeSubscriptionsCount,
  archivedSubscriptionsCount,
}) => {
  const [activeTab, setActiveTab] = useState("active");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const subscriptionTabSections = [
    {
      icon: <FontAwesomeIcon icon={faUsers} className="me-3" />,
      title: <Translate value="title.tab.agreement.subscription.active" />,
      tabID: "active",
    },
    {
      icon: <FontAwesomeIcon icon={faArchive} className="me-3" />,
      title: <Translate value="title.tab.agreement.subscription.archived" />,
      tabID: "archived",
    },
  ];

  const subscriptionTabs = subscriptionTabSections.map(
    (subscriptionDetailSection) => {
      const { title, tabID, icon } = subscriptionDetailSection;
      const lineCount =
        tabID === "active"
          ? activeSubscriptionsCount
          : archivedSubscriptionsCount;

      return (
        <NavItem key={tabID} className={{ active: activeTab === tabID }}>
          <NavLink
            className="py-4 px-4 d-flex align-items-center"
            onClick={() => {
              toggleTab(tabID);
            }}
            data-tab-name={tabID}
          >
            <span className="text-tertiary">{icon}</span>
            <span
              className="text-secondary"
              data-tab-title={`${tabID}-subscriptions-title`}
            >
              {title}
            </span>
            <span
              className="ms-2 badge bg-primary rounded-circle"
              data-tab-count={`${tabID}-subscriptions-count`}
            >
              <TranslateNumber value={lineCount} minimumFractionDigits={0} />
            </span>
          </NavLink>
        </NavItem>
      );
    }
  );

  const subscriptionsTabContent = subscriptionTabSections.map(
    (subscriptionDetailSection) => {
      const { tabID } = subscriptionDetailSection;

      return (
        <TabPane tabId={tabID} key={tabID}>
          <SubscriptionGroupTab
            subscriptions={subscriptions}
            isAgreementArchived={isAgreementArchived}
            isArchived={tabID === "archived"}
            products={products}
          />
        </TabPane>
      );
    }
  );

  return (
    <div className="br-all shadow-sm mt-3">
      <Nav tabs className="m-0 custom-nav-tab">
        {subscriptionTabs}
      </Nav>
      <TabContent
        activeTab={activeTab}
        className="subscription-detail-tab-content p-0 mb-4 bg-white"
      >
        {subscriptionsTabContent}
      </TabContent>
    </div>
  );
};

SubscriptionTab.defaultProps = {};

SubscriptionTab.propTypes = {
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      product_id: PropTypes.arrayOf(PropTypes.string),
      quantity: PropTypes.number,
      product_uom_id: PropTypes.arrayOf(PropTypes.string),
      price_unit: PropTypes.number,
      price_subtotal: PropTypes.number,
    })
  ).isRequired,
  isAgreementArchived: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeSubscriptionsCount: PropTypes.number.isRequired,
  archivedSubscriptionsCount: PropTypes.number.isRequired,
};

export default SubscriptionTab;
