import {
  SETTING_FETCH_REQUEST,
  SETTING_FETCH_RECEIVED,
  SETTING_FETCH_FAILED,
  SETTING_FETCH_CLEAR_ERRORS,
  SETTING_UPDATE_REQUEST,
  SETTING_UPDATE_RECEIVED,
  SETTING_UPDATE_FAILED,
  SETTING_CREATE_REQUEST,
  SETTING_CREATE_RECEIVED,
  SETTING_CREATE_FAILED,
  SINGLE_SETTING_FETCH_REQUEST,
  SINGLE_SETTING_FETCH_RECEIVED,
  SINGLE_SETTING_FETCH_FAILED,
  SINGLE_SETTING_DELETE_REQUEST,
  SINGLE_SETTING_DELETE_RECEIVED,
  SINGLE_SETTING_DELETE_FAILED,
  SETTING_SEARCH_REQUEST,
  CLEAR_SETTING,
} from "redux/actions/setting";

const defaultState = {
  settings: [],
  commitError: "",
  fetchError: "",
  commitSuccess: false,
  isLoading: false,
  isSearching: false,
  createSuccess: false,
};

function setting(state = defaultState, action) {
  const { settings, setting: singleSetting } = action;

  switch (action.type) {
    case SETTING_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitSuccess: false,
      };

    case SETTING_FETCH_RECEIVED:
      return {
        ...state,
        settings,
        isLoading: false,
      };

    case SETTING_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case SETTING_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case SETTING_UPDATE_RECEIVED:
      return {
        ...state,
        setting: singleSetting,
        isLoading: false,
        commitSuccess: true,
      };

    case SETTING_UPDATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case SETTING_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        createSuccess: false,
        commitError: "",
      };

    case SETTING_CREATE_RECEIVED:
      return {
        ...state,
        setting: singleSetting,
        isLoading: false,
        createSuccess: true,
      };

    case SETTING_CREATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        createSuccess: false,
      };

    case SINGLE_SETTING_FETCH_REQUEST:
      return {
        ...state,
        setting: undefined,
        isLoading: true,
        fetchError: "",
        commitSuccess: false,
      };

    case SINGLE_SETTING_FETCH_RECEIVED:
      return {
        ...state,
        setting: singleSetting,
        isLoading: false,
      };

    case SINGLE_SETTING_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case SINGLE_SETTING_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case SINGLE_SETTING_DELETE_RECEIVED:
      return {
        ...state,
        isLoading: false,
        commitSuccess: true,
      };

    case SINGLE_SETTING_DELETE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case SETTING_FETCH_CLEAR_ERRORS:
      return {
        ...state,
        fetchError: "",
        isLoading: false,
        commitSuccess: false,
        commitError: "",
      };

    case SETTING_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    case CLEAR_SETTING:
      return {
        ...state,
        setting: null,
      };

    default:
      return state;
  }
}

export default setting;
