import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Translate, t } from "react-i18nify";

import {
  subscriptionProductCreateRequest,
  subscriptionProductFetchClearErrors,
} from "redux/actions/subscriptionProduct";

import { SUBSCRIPTION_PRODUCT } from "constants/routes";

import SubscriptionProductForm from "components/SubscriptionProduct/Form/SubscriptionProductForm";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const SubscriptionProductCreate = ({
  createSubscriptionProduct,
  commitSuccess,
  isLoading,
  subscriptionProduct,
  clearErrors,
}) => {
  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    await createSubscriptionProduct(values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess) {
    const { id } = subscriptionProduct;
    const subscriptionProductTranslation = t(
      "title.tab.agreement.subscriptionModal.subscriptionProduct"
    );

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.create", {
        resource: subscriptionProductTranslation,
      })
    );

    clearErrors();

    return (
      <Navigate
        to={`${SUBSCRIPTION_PRODUCT.addId(SUBSCRIPTION_PRODUCT.EDIT, id)}`}
      />
    );
  }

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.create"
            resource={t(
              "title.tab.agreement.subscriptionModal.subscriptionProduct"
            )}
          />
        </h3>
      </div>
      <SubscriptionProductForm
        onSubmit={handleSubmit}
        formName="subscriptionProductCreateForm"
        isLoading={isLoading}
      />
    </div>
  );
};

SubscriptionProductCreate.defaultProps = {
  subscriptionProduct: undefined,
};

SubscriptionProductCreate.propTypes = {
  createSubscriptionProduct: PropTypes.func.isRequired,
  subscriptionProduct: PropTypes.shape({
    id: PropTypes.number,
  }),
  isLoading: PropTypes.bool.isRequired,
  commitSuccess: PropTypes.bool.isRequired,
  clearErrors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { subscriptionProduct, commitSuccess, isLoading } =
    state.subscriptionProduct;

  return {
    subscriptionProduct,
    isLoading,
    commitSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createSubscriptionProduct: async (attributes, callback) => {
    await dispatch(subscriptionProductCreateRequest(attributes, callback));
  },
  clearErrors: () => {
    dispatch(subscriptionProductFetchClearErrors());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionProductCreate);
