import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-i18nify";

import { camelize } from "utils/string";

import AddOnSubscriptionsModal from "components/Modal/AddOnSubscriptionsModal";
import TranslateNumber from "components/i18n/TranslateNumber";

const QuotationSubscriptionListContent = ({
  subscriptions,
  groupedSubscriptions,
  allTotalSetupPrice,
  allTotalMonthlyPrice,
  mainSubscriptionLine,
  selectedSubscriptionLines,
  modalVisible,
  setModalVisibility,
}) => {
  if (!subscriptions || subscriptions.length === 0) {
    return (
      <>
        <h4 className="my-3">
          <Translate value="attributes.subscriptions" />
        </h4>
        <div className="p-5 shadow-sm bg-white d-flex flex-column align-items-center justify-content-center empty-table">
          <div className="info-icon-wrapper border-danger mb-2">
            <FontAwesomeIcon className="info-icon text-danger" icon={faInfo} />
          </div>
          <div className="text-center fw-bold">
            <p className="d-inline-block mb-2">
              <Translate value="message.subscription.noSubscription" />
            </p>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="table-responsive quotation-subscription-list shadow-sm">
      <table
        className="table-light w-100"
        data-cy="quotation-subscription-table"
      >
        <thead className="quotation-subscription-list-header">
          <tr className="text-center">
            <th className="header-cell p-4 text-start">
              <Translate value="title.section.subscription" />
            </th>
            <th className="header-cell p-4">
              <Translate value="attributes.quantity" />
            </th>
            <th className="header-cell p-4">
              <Translate value="common.setupPrice" />
            </th>
            <th className="header-cell p-4">
              <Translate value="attributes.monthlyPrice" />
            </th>
            <th className="header-cell p-4">
              <Translate value="attributes.totalSetupPrice" />
            </th>
            <th className="header-cell p-4">
              <Translate value="attributes.totalMonthlyPrice" />
            </th>
            <th className="header-cell p-4">
              <Translate value="common.action" />
            </th>
          </tr>
        </thead>

        <tbody className="subscriptions">{groupedSubscriptions}</tbody>

        <tfoot>
          <tr className="fw-bold text-center">
            <td colSpan={3} />
            <td>
              <Translate value="common.total" />
            </td>
            <td>
              <TranslateNumber
                value={allTotalSetupPrice}
                minimumFractionDigits={2}
              />
            </td>
            <td>
              <TranslateNumber
                value={allTotalMonthlyPrice}
                minimumFractionDigits={2}
              />
            </td>
            <td />
          </tr>
        </tfoot>
      </table>

      <AddOnSubscriptionsModal
        title={mainSubscriptionLine.product_name}
        secondarySubscriptions={selectedSubscriptionLines}
        modalVisible={modalVisible}
        onCancel={() => setModalVisibility((previousState) => !previousState)}
        installationAddress={mainSubscriptionLine.termination_address}
      />
    </div>
  );
};

const QuotationSubscriptionList = ({
  subscriptions: allSubscriptions,
  allTotalMonthlyPrice,
  allTotalSetupPrice,
}) => {
  const [modalVisible, setModalVisibility] = useState(false);
  const [mainSubscriptionLine, setMainSubscriptionLine] = useState({});
  const [selectedSubscriptionLines, setSelectedSubscriptionLines] =
    useState(null);

  const toggleModal = (subscriptionID) => {
    const selectedSubscription = allSubscriptions.find(
      ({ id }) => id === subscriptionID
    );

    const { subscription_lines: subscriptionLines } = selectedSubscription;

    const mainSubscription = subscriptionLines.find(
      (line) => line.line_type === "subscription"
    );

    setMainSubscriptionLine(mainSubscription);

    const otherSubscriptions = subscriptionLines.filter(
      (line) => line.line_type !== "subscription"
    );

    setSelectedSubscriptionLines(otherSubscriptions);

    setModalVisibility((previousState) => !previousState);
  };

  const subscriptionTypes = [
    "voip",
    "cell_phone",
    "internet_connections",
    "hosting_services",
    "service_contracts",
  ];

  const groupedSubscriptions = subscriptionTypes.map((subscriptionType) => {
    const subscriptions = allSubscriptions.filter(
      ({ subscription_type: currentSubscriptionType }) =>
        currentSubscriptionType === subscriptionType
    );

    if (subscriptions.length === 0) return null;

    const subscriptionList = subscriptions.map(
      ({
        id: subscriptionID,
        subscription_lines: subscriptionLines,
        quantity,
        setup_price: setupPrice,
        monthly_price: monthlyPrice,
        total_setup_price: totalSetupPrice,
        total_monthly_price: totalMonthlyPrice,
      }) => {
        const mainSubscription = subscriptionLines.find(
          (line) => line.line_type === "subscription"
        );

        return (
          <tr className="bg-white text-center" data-cy="subscription">
            <td className="text-start ps-4">{mainSubscription.product_name}</td>
            <td>{quantity}</td>
            <td>
              <TranslateNumber value={setupPrice} minimumFractionDigits={2} />
            </td>
            <td>
              <TranslateNumber value={monthlyPrice} minimumFractionDigits={2} />
            </td>
            <td>
              <TranslateNumber
                value={totalSetupPrice}
                minimumFractionDigits={2}
              />
            </td>
            <td>
              <TranslateNumber
                value={totalMonthlyPrice}
                minimumFractionDigits={2}
              />
            </td>
            <td>
              <button
                className="btn btn-outline-primary p-2 d-flex m-auto"
                type="button"
                onClick={() => {
                  toggleModal(subscriptionID);
                }}
                data-cy={camelize(mainSubscription.product_name)}
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
            </td>
          </tr>
        );
      }
    );

    return (
      <>
        <tr>
          <td colSpan={7} className="subscription-type fw-bold">
            <Translate
              value={`title.tab.agreement.${camelize(subscriptionType)}`}
            />
          </td>
        </tr>

        {subscriptionList}
      </>
    );
  });

  return (
    <QuotationSubscriptionListContent
      subscriptions={allSubscriptions}
      groupedSubscriptions={groupedSubscriptions}
      allTotalSetupPrice={allTotalSetupPrice}
      allTotalMonthlyPrice={allTotalMonthlyPrice}
      mainSubscriptionLine={mainSubscriptionLine}
      selectedSubscriptionLines={selectedSubscriptionLines}
      modalVisible={modalVisible}
      setModalVisibility={setModalVisibility}
    />
  );
};

QuotationSubscriptionListContent.defaultProps = {};

QuotationSubscriptionListContent.propTypes = {
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      subscription_lines: PropTypes.shape({
        line_type: PropTypes.string,
        product_name: PropTypes.string,
      }),
      quantity: PropTypes.number,
      setup_price: PropTypes.number,
      monthly_price: PropTypes.number,
      total_setup_price: PropTypes.number,
      total_monthly_price: PropTypes.number,
    })
  ).isRequired,
  allTotalMonthlyPrice: PropTypes.number.isRequired,
  allTotalSetupPrice: PropTypes.number.isRequired,
  groupedSubscriptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mainSubscriptionLine: PropTypes.shape({
    product_name: PropTypes.string,
    termination_address: PropTypes.string,
  }).isRequired,
  selectedSubscriptionLines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modalVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
};

QuotationSubscriptionList.defaultProps = {};

QuotationSubscriptionList.propTypes = {
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      subscription_lines: PropTypes.shape({
        line_type: PropTypes.string,
        product_name: PropTypes.string,
      }),
      quantity: PropTypes.number,
      setup_price: PropTypes.number,
      monthly_price: PropTypes.number,
      total_setup_price: PropTypes.number,
      total_monthly_price: PropTypes.number,
    })
  ).isRequired,
  allTotalMonthlyPrice: PropTypes.number.isRequired,
  allTotalSetupPrice: PropTypes.number.isRequired,
};

export default QuotationSubscriptionList;
