import React from "react";
import { FormGroup, Col, Row } from "reactstrap";
import { Field } from "react-final-form";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import { required, email, number, composeValidators } from "utils/validation";

import { renderInputField } from "components/ReduxForm/RenderField";
import PasswordField from "components/ReduxForm/PasswordField/PasswordField";

const FORM_TYPE = {
  EDIT: "edit",
};

export const FIELD_TYPE = {
  FINAL_FORM: "final-form",
  REDUX_FORM: "redux-form",
};

const VoicemailField = ({ validate, ...props }) => (
  <Field
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
    validate={composeValidators(validate[0], validate[1])}
  />
);

const VoicemailBox = ({ formType, showTitle }) => {
  if (formType === FORM_TYPE.EDIT) {
    return null;
  }

  const addTitle = showTitle && (
    <Col md="12">
      <h4 className="mt-3 mb-3">
        <Translate value="attributes.voicemailBox" />
      </h4>
      <hr />
    </Col>
  );

  return (
    <>
      <Row>
        {addTitle}
        <Col md="6">
          <FormGroup>
            <VoicemailField
              name="voicemail_box.email"
              component={renderInputField}
              validate={[required, email]}
              placeholder="user@sc.dk"
              labelTranslation="attributes.email"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <VoicemailField
              name="voicemail_box.password"
              component={PasswordField}
              validate={[required, number]}
              placeholderTranslation="attributes.pinCode"
              labelTranslation="attributes.pinCode"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <VoicemailField
              component={renderInputField}
              validate={[required, number]}
              placeholderTranslation="attributes.maximumNumberOfMessageInVoicemailBox"
              labelTranslation="attributes.maximumNumberOfMessageInVoicemailBox"
              name="voicemail_box.maxmsg"
              type="number"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <VoicemailField
              component={renderInputField}
              validate={[required, number]}
              placeholderTranslation="attributes.minimumLengthOfMessageInSeconds"
              labelTranslation="attributes.minimumLengthOfMessageInSeconds"
              name="voicemail_box.minsecs"
              type="number"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <VoicemailField
              component={renderInputField}
              validate={[required, number]}
              placeholderTranslation="attributes.maximumLengthOfMessageInSeconds"
              labelTranslation="attributes.maximumLengthOfMessageInSeconds"
              name="voicemail_box.maxsecs"
              type="number"
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

VoicemailField.defaultProps = {
  validate: [],
};

VoicemailField.propTypes = {
  validate: PropTypes.shape([]),
};

VoicemailBox.defaultProps = {
  showTitle: true,
};

VoicemailBox.propTypes = {
  formType: PropTypes.string.isRequired,
  showTitle: PropTypes.bool,
};

export default VoicemailBox;
