import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const HideShowElement = ({ showContent, content }) => {
  if (showContent) return content;

  return Array.from({ length: content.length }, (_, k) => k).map((key) => (
    <span className="mb-0 hidden-content" key={key}>
      *
    </span>
  ));
};

const HideShowContent = ({ content }) => {
  const [showContent, setContent] = useState(false);

  const icon = !showContent ? faEyeSlash : faEye;

  const iconClassName = !showContent ? "mb-2" : "";
  const containerClassName = !showContent ? "mt-2" : "";

  const handleContent = () => {
    setContent((previous) => !previous);
  };

  return (
    <div className={`col-md-7 ${containerClassName}`}>
      <HideShowElement content={content} showContent={showContent} />
      <FontAwesomeIcon
        icon={icon}
        className={`text-tertiary ms-3 ${iconClassName} hide-show-icon`}
        onClick={handleContent}
      />
    </div>
  );
};

HideShowElement.propTypes = {
  showContent: PropTypes.bool.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

HideShowContent.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default HideShowContent;
