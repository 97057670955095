import { createSearchAction } from "redux-search";

import { PbxApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

export const PBX_SIP_DEVICES_FETCH_REQUEST = "PBX_SIP_DEVICES_FETCH_REQUEST";
export const PBX_SIP_DEVICES_FETCH_RECEIVED = "PBX_SIP_DEVICES_FETCH_RECEIVED";
export const PBX_SIP_DEVICES_FETCH_FAILED = "PBX_SIP_DEVICES_FETCH_FAILED";
export const PBX_SIP_DEVICES_SEARCH_REQUEST = "PBX_SIP_DEVICES_SEARCH_REQUEST";

export function pbxSipPhonesFetchReceived(response) {
  return {
    type: PBX_SIP_DEVICES_FETCH_RECEIVED,
    sipDevices: response.data,
  };
}

export function pbxSipPhonesFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: PBX_SIP_DEVICES_FETCH_FAILED,
    message,
  };
}

export const pbxSipDevicesFetchRequest = (pbxID) => (dispatch) => {
  dispatch({ type: PBX_SIP_DEVICES_FETCH_REQUEST });

  PbxApi.fetchAllSipDevices(pbxID)
    .then((data) => dispatch(pbxSipPhonesFetchReceived(data)))
    .catch((e) => {
      dispatch(pbxSipPhonesFetchFailed(e));
    });
};

export const pbxSearchSipDevices = createSearchAction("pbxSipDevices");

export const pbxSipDevicesSearchRequest = (value) => {
  const isSearching = Boolean(value);

  return {
    type: PBX_SIP_DEVICES_SEARCH_REQUEST,
    isSearching,
  };
};
