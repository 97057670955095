import { setAppLocale } from "redux/actions/i18n";
import { setLocale } from "react-i18nify";

import { store } from "redux/store";

const setLanguage = (lang) => {
  store.dispatch(setAppLocale(lang));
  setLocale(lang);
};

// eslint-disable-next-line import/prefer-default-export
export { setLanguage };
