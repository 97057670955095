import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import logo from "assets/img/company-logo.png";

import { AUTH, DASHBOARD } from "constants/routes";

const LoginPageWrapper = ({ children }) => {
  const { isLoggedIn } = useSelector(({ auth }) => auth);

  const path = isLoggedIn ? `${DASHBOARD.INDEX}` : AUTH.LOGIN;

  return (
    <main className="login-container h-100 w-100 py-5">
      <section className="container h-100 w-100 d-flex flex-column justify-content-center">
        <header className="w-100 d-flex flex-column align-items-center justify-content-between mb-2 mb-xl-4">
          <Link to={path}>
            <figure>
              <img
                src={logo}
                alt="flexonet logo"
                className="text-center brand-logo"
              />
            </figure>
          </Link>
        </header>
        <div
          className="card d-flex justify-content-center align-items-center px-3 px-sm-5 py-4 mx-md-auto col-md-10 col-lg-8 col-xl-6 border-0"
          role="form"
        >
          {children}
        </div>
      </section>
    </main>
  );
};

LoginPageWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default LoginPageWrapper;
