import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Navigate, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Translate, t } from "react-i18nify";

import {
  singleSipDeviceFetchRequest,
  sipDeviceUpdateRequest,
} from "redux/actions/sipDevices";

import { SIP_DEVICES } from "constants/routes";
import FORM from "constants/form";

import SipDeviceForm from "components/SipDevices/Form/SipDeviceForm";
import WholePageSpinner from "components/Spinner/WholePageSpinner";
import PageNotFound from "components/Error/PageNotFound/PageNotFound";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const SipDeviceEdit = ({
  updateSipDevice,
  commitSuccess,
  sipDevice,
  isLoading,
  fetchSingleSipDevice,
}) => {
  const { sipDeviceID } = useParams();

  const {
    ex_description: description,
    ex_name: name,
    extension_number: extensionNumber,
  } = sipDevice;

  useEffect(() => {
    fetchSingleSipDevice(sipDeviceID);
  }, []);

  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    const { id } = sipDevice;
    let serverResponse = {};

    await updateSipDevice(id, values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess) {
    const { id } = sipDevice;
    const sipDeviceTranslation = t("common.desktopPhone");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.update", {
        resource: sipDeviceTranslation,
      })
    );

    return <Navigate to={SIP_DEVICES.addId(SIP_DEVICES.SHOW, id)} />;
  }

  if (isLoading) {
    return <WholePageSpinner />;
  }

  if (!sipDevice?.id) {
    return (
      <div className="d-flex h-100 justify-content-center">
        <PageNotFound />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.edit"
            resource={t("common.desktopPhone")}
          />
        </h3>
      </div>
      <SipDeviceForm
        onSubmit={handleSubmit}
        formName={FORM.SIP_DEVICE_FORM}
        formType="edit"
        isLoading={isLoading}
        initialValues={{
          ex_name: name,
          ex_description: description,
          extension_number: extensionNumber,
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ sipDevice: sipDeviceReducer }) => {
  const { sipDevice, commitSuccess, commitError, isLoading } = sipDeviceReducer;

  return {
    isLoading,
    sipDevice,
    commitError,
    commitSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleSipDevice: (id) => {
    dispatch(singleSipDeviceFetchRequest(id));
  },
  updateSipDevice: async (id, attributes, callback) => {
    await dispatch(sipDeviceUpdateRequest(id, attributes, callback));
  },
});

SipDeviceEdit.defaultProps = {
  commitError: {},
};

SipDeviceEdit.propTypes = {
  updateSipDevice: PropTypes.func.isRequired,
  commitSuccess: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchSingleSipDevice: PropTypes.func.isRequired,
  commitError: PropTypes.shape({}),
  sipDevice: PropTypes.shape({
    id: PropTypes.number,
    extension_number: PropTypes.number,
    user_license_id: PropTypes.number,
    ex_description: PropTypes.string,
    ex_name: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SipDeviceEdit);
