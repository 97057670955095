import { t } from "react-i18nify";

import {
  NETWORK_SERVICE_LOCATION_CONSTRAINT_TYPES,
  NETWORK_SERVICES_WITH_RESTRICTIONS,
  NETWORK_SERVICE_DEFAULT_LOCATIONS,
} from "constants/constant";

const validateLocationConstraintForNetworkService = (
  serviceID,
  cellPhoneServices,
  locationConstraint
) => {
  if (!serviceID || !locationConstraint) return undefined;

  const selectedNetworkService = cellPhoneServices.find(
    ({ id }) => id === serviceID
  );

  const { name: serviceName } = selectedNetworkService;

  const restrictedService = NETWORK_SERVICES_WITH_RESTRICTIONS.find(
    (currentRestrictedService) => serviceName.includes(currentRestrictedService)
  );

  if (!restrictedService) {
    return undefined;
  }

  if (
    restrictedService &&
    locationConstraint === NETWORK_SERVICE_LOCATION_CONSTRAINT_TYPES.OUTSIDE
  ) {
    return t(
      "validationErrors.networkService.LOCATION_CONSTRAINT_OUTSIDE_RESTRICTION",
      {
        restrictedServices: NETWORK_SERVICES_WITH_RESTRICTIONS.join(", "),
      }
    );
  }

  return undefined;
};

const validateLocationForNetworkService = (
  serviceID,
  cellPhoneServices,
  location
) => {
  if (!serviceID || !location) return undefined;

  const selectedNetworkService = cellPhoneServices.find(
    ({ id }) => id === serviceID
  );

  const { name: serviceName } = selectedNetworkService;

  const restrictedService = NETWORK_SERVICES_WITH_RESTRICTIONS.find(
    (currentRestrictedService) => serviceName.includes(currentRestrictedService)
  );

  if (!restrictedService) {
    return undefined;
  }

  if (
    restrictedService &&
    location === NETWORK_SERVICE_DEFAULT_LOCATIONS.ENTIRE_WORLD
  ) {
    return t(
      "validationErrors.networkService.LOCATION_ENTIRE_WORLD_RESTRICTION",
      {
        restrictedServices: NETWORK_SERVICES_WITH_RESTRICTIONS.join(", "),
      }
    );
  }

  return undefined;
};

export {
  validateLocationConstraintForNetworkService,
  validateLocationForNetworkService,
};
