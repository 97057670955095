import React from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import {
  cannotBeNegative,
  composeValidators,
  requiredForNumber,
} from "utils/validation";

import { renderPriceInputField } from "components/ReduxForm/RenderField";
import ProductNameWithLabel from "components/Quotation/Form/Modal/ProductNameWithLabel";

const OtherProductsTableRow = ({
  subscriptionLine,
  subscriptionLineIndex,
  handleRemoveAddOnService,
  productName,
  isSetup,
  subscriptionLinesFieldName,
}) => (
  <tr className="other-products-table-row py-3" key={productName}>
    <td className="align-middle">
      <p className="mb-0 align-self-center">
        <ProductNameWithLabel name={productName} isSetup={isSetup} />
      </p>
    </td>
    <td className="align-middle">
      <div className="w-50 mx-auto">
        <Field
          name={`${subscriptionLinesFieldName}[${subscriptionLineIndex}].price`}
          component={renderPriceInputField}
          placeholderTranslation="attributes.price"
          inputClassName="form-control text-center"
          validate={composeValidators(requiredForNumber, cannotBeNegative)}
        />
      </div>
    </td>

    <td className="pe-4 align-middle">
      <button
        className="border border-danger rounded p-2 text-danger d-flex bg-transparent ms-auto me-2"
        type="button"
        onClick={() =>
          handleRemoveAddOnService(subscriptionLine, subscriptionLineIndex)
        }
        data-field-name={`${subscriptionLinesFieldName}[${subscriptionLineIndex}].delete-btn`}
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>
    </td>
  </tr>
);

OtherProductsTableRow.defaultProps = {};

OtherProductsTableRow.propTypes = {
  subscriptionLine: PropTypes.shape({}).isRequired,
  subscriptionLineIndex: PropTypes.number.isRequired,
  handleRemoveAddOnService: PropTypes.func.isRequired,
  productName: PropTypes.string.isRequired,
  isSetup: PropTypes.bool.isRequired,
  subscriptionLinesFieldName: PropTypes.string.isRequired,
};

export default OtherProductsTableRow;
