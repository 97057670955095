import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as searchReducer } from "redux-search";
import { reducer as toastrReducer } from "react-redux-toastr";

import i18nReducer from "redux/reducers/i18n";
import { LOGOUT } from "redux/actions/auth";
import auth from "redux/reducers/auth";
import business from "redux/reducers/business";
import user from "redux/reducers/user";
import role from "redux/reducers/role";
import debtor from "redux/reducers/debtor";
import invoice from "redux/reducers/invoice";
import creditNote from "redux/reducers/creditNote";
import agreement from "redux/reducers/agreement";
import setting from "redux/reducers/setting";
import userSetting from "redux/reducers/userSetting";
import dashboard from "redux/reducers/dashboard/index";
import contact from "redux/reducers/odooEngine/contact";
import debtorType from "redux/reducers/debtorType";
import country from "redux/reducers/country";
import paymentTerm from "redux/reducers/paymentTerm";
import product from "redux/reducers/product";
import pbx from "redux/reducers/pbx";
import routingProfile from "redux/reducers/pbxEngine/routingProfile";
import callRate from "redux/reducers/pbxEngine/callRate";
import userLicense from "redux/reducers/userLicense";
import didNumber from "redux/reducers/didNumber";
import cellPhone from "redux/reducers/cellPhone";
import subscriptionProduct from "redux/reducers/subscriptionProduct";
import sipDevice from "redux/reducers/sipDevice";
import faxStatus from "redux/reducers/pbxEngine/faxStatus";
import vendor from "redux/reducers/vendor";
import bill from "redux/reducers/bill";
import cellPhoneReport from "redux/reducers/cellPhoneReport/index";
import agreementBilling from "redux/reducers/agreement/index";
import pbxCellPhone from "redux/reducers/pbx/cellPhone";
import pbxSipDevice from "redux/reducers/pbx/sipDevice";
import userLicenseCellPhone from "redux/reducers/userLicense/cellPhone";
import userLicenseDidNumber from "redux/reducers/userLicense/didNumber";
import voicemailBox from "redux/reducers/voicemailBox";
import provider from "redux/reducers/pbxEngine/provider";
import numberPortingRequest from "redux/reducers/numberPortingRequest";
import numberPortingRequestPhoneNumber from "redux/reducers/numberPortingRequestPhoneNumber";
import phoneNumber from "redux/reducers/phoneNumber";
import simCard from "redux/reducers/simCard";
import quotation from "redux/reducers/quotation";
import cellPhoneServices from "redux/reducers/cellPhoneServices";

const appReducer = combineReducers({
  form: formReducer,
  i18n: i18nReducer,
  search: searchReducer,
  toastr: toastrReducer,
  // Feature wise reducers (In alphabetical order)
  agreement,
  agreementBilling,
  auth,
  bill,
  business,
  callRate,
  cellPhone,
  cellPhoneReport,
  cellPhoneServices,
  contact,
  country,
  creditNote,
  dashboard,
  debtor,
  debtorType,
  didNumber,
  faxStatus,
  invoice,
  numberPortingRequest,
  numberPortingRequestPhoneNumber,
  paymentTerm,
  pbx,
  pbxCellPhone,
  pbxSipDevice,
  product,
  provider,
  phoneNumber,
  quotation,
  role,
  routingProfile,
  setting,
  subscriptionProduct,
  sipDevice,
  simCard,
  user,
  userLicense,
  userLicenseCellPhone,
  userLicenseDidNumber,
  userSetting,
  vendor,
  voicemailBox,
});

const rootReducer = (state, action) => {
  let appState = state;

  const applicationEnvironment = process.env.NODE_ENV;

  if (action.type === LOGOUT) {
    const { search, i18n } = appState;
    const initialSearchState = {};

    if (applicationEnvironment !== "test") {
      Object.keys(search).forEach((key) => {
        initialSearchState[key] = {};
      });
    }

    appState = { search: initialSearchState, i18n };
  }

  return appReducer(appState, action);
};

export default rootReducer;
