import React from "react";
import { Form } from "react-final-form";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";
import VoicemailBox from "components/VoicemailBox/Form/VoicemailBox";

const VoicemailBoxForm = ({
  onSubmit,
  isLoading,
  showVoicemailBoxTitle,
  initialValues,
}) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    keepDirtyOnReinitialize
    render={({ handleSubmit, submitting, pristine }) => (
      <form className="flexo-form px-4 py-4 pt-5 w-100" onSubmit={handleSubmit}>
        <div>
          <VoicemailBox showTitle={showVoicemailBoxTitle} />
          <div className="flexo-form-footer">
            <ReduxFormCommitButton
              title={<Translate value="common.save" />}
              submitting={submitting}
              pristine={pristine}
              isLoading={isLoading}
            />
          </div>
        </div>
      </form>
    )}
  />
);

VoicemailBoxForm.defaultProps = {
  isLoading: false,
  showVoicemailBoxTitle: true,
  initialValues: {},
};

VoicemailBoxForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  showVoicemailBoxTitle: PropTypes.bool,
  initialValues: PropTypes.shape({}),
};

const mapStateToProps = (state) => {
  const { isProcessing } = state.voicemailBox;

  return {
    isLoading: isProcessing,
  };
};

export default compose(connect(mapStateToProps))(VoicemailBoxForm);
