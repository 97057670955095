import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";

const RenderPreviewFile = ({ filePreview, title }) => {
  if (!filePreview) return <Spinner size="md" />;

  const blob = new Blob([filePreview], { type: "application/pdf" });
  const previewFileUrl = window.URL.createObjectURL(blob);

  return (
    <iframe
      className="p-3"
      src={previewFileUrl}
      title={title}
      width="100%"
      height="100%"
      data-cy="preview-file"
    />
  );
};

const PreviewFileModal = ({ filePreview, title, modalVisible, onCancel }) => {
  if (!modalVisible) return null;

  return (
    <Modal
      isOpen={modalVisible}
      centered
      toggle={onCancel}
      className="modal-xl preview-file-modal"
    >
      <ModalHeader className="border-0 pb-0" toggle={onCancel}>
        {title}
      </ModalHeader>
      <ModalBody className="preview-file-modal-body d-flex align-items-center justify-content-center">
        <RenderPreviewFile filePreview={filePreview} title={title} />
      </ModalBody>
    </Modal>
  );
};

PreviewFileModal.defaultProps = {};

PreviewFileModal.propTypes = {
  title: PropTypes.string.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  filePreview: PropTypes.shape({}).isRequired,
};

RenderPreviewFile.defaultProps = {};

RenderPreviewFile.propTypes = {
  filePreview: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
};

export default PreviewFileModal;
