import React from "react";
import { Field } from "react-final-form";
import { Col, FormGroup } from "reactstrap";
import PropTypes from "prop-types";

import { renderInputField } from "components/ReduxForm/RenderField";
import {
  composeValidators,
  minValue,
  number,
  required,
} from "utils/validation";

const PriceField = ({ show }) => {
  if (!show) return null;

  return (
    <Col md="8">
      <FormGroup>
        <Field
          name="price"
          component={renderInputField}
          labelTranslation="attributes.price"
          placeholderTranslation="attributes.price"
          type="number"
          validate={composeValidators(required, number, minValue(1))}
          min={1}
        />
      </FormGroup>
    </Col>
  );
};

PriceField.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default PriceField;
