import React from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Translate } from "react-i18nify";
import { Col, FormGroup, Row } from "reactstrap";

import { required } from "utils/validation";

import renderDateTimeField from "components/ReduxForm/DateTimeField/DateTimeField";
import RequestedNumberList from "components/NumberPortingRequest/Import/Form/RequestedNumberList";
import FileImportSection from "components/NumberPortingRequest/Import/Form/FileImportSection";

const RequestNumberImportSection = () => (
  <Row>
    <Col md="12">
      <h4 className="mb-3">
        <Translate value="title.section.numberPortingRequest.numbersToImport" />
      </h4>
      <hr />
    </Col>

    <Col md="6">
      <FormGroup>
        <Field
          name="requested_porting_date"
          component={renderDateTimeField}
          labelTranslation="attributes.portingDate"
          disabledDays={{
            before: new Date(),
          }}
        />
      </FormGroup>
    </Col>

    <Col md="12">
      <FileImportSection />
    </Col>

    <Col md="12">
      <FieldArray
        name="number_porting_request_phone_numbers_attributes"
        component={RequestedNumberList}
        validate={required}
      />
    </Col>
  </Row>
);

RequestNumberImportSection.defaultProps = {};

RequestNumberImportSection.propTypes = {};

export default RequestNumberImportSection;
