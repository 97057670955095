import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import PropTypes from "prop-types";
import ReduxToastr from "react-redux-toastr";

import { loadTranslations, setAppLocale } from "redux/actions/i18n";
import { store, persistor } from "redux/store";
import { setLocale } from "react-i18nify";

import translationsObject from "locales/root";

import USER_ROLES, { Admins } from "constants/userRoles";
import {
  AUTH,
  DASHBOARD,
  USER_SETTING,
  CONTACT,
  FAX_STATUS,
  NUMBER_PORTING_REQUEST,
  VOICEMAIL_BOX,
  AGREEMENT,
  BILLS,
  CELL_PHONE,
  CREDIT_NOTE,
  DEBTOR,
  DID_NUMBER,
  INVOICE,
  PBX,
  QUOTATION,
  SETTING,
  APP_SETTING,
  SIP_DEVICES,
  SUBSCRIPTION_PRODUCT,
  PHONE_NUMBER,
  SIM_CARD,
  USER,
  USER_LICENSE,
  VENDORS,
} from "constants/routes";

import "assets/css/main.scss";

import DebtorRoutes from "routes/Debtor";
import InvoiceRoutes from "routes/Invoice";
import AgreementRoutes from "routes/Agreement";
import PbxRoutes from "routes/Pbx";
import UserRoutes from "routes/User";
import UserLicenseRoutes from "routes/UserLicense";
import DidNumberRoutes from "routes/DidNumber";
import SettingRoutes from "routes/Setting";
import SubscriptionProductRoutes from "routes/SubscriptionProduct";
import CellPhoneRoutes from "routes/CellPhone";
import CreditNotesRoutes from "routes/CreditNote";
import VendorRoutes from "routes/Vendor";
import BillsRoutes from "routes/Bill";
import NumberPortingRequestRoutes from "routes/NumberPortingRequest";
import SipDeviceRoutes from "routes/SipDevice";
import QuotationRoutes from "routes/Quotation";
import { PhoneNumberRoutes, SimCardRoutes } from "routes/Telecom";
import AppSettingRoutes from "routes/AppSetting";

import Dashboard from "views/Dashboard/Dashboard";
import UserSettingIndex from "views/UserSetting/UserSettingIndex";
import ResetPassword from "views/Auth/ResetPassword";
import SetPassword from "views/Auth/SetPassword";
import LoginView from "views/Auth/Login";
import ContactIndex from "views/Contact/ContactIndex";
import FaxStatusDetail from "views/FaxStatus/FaxStatusDetail/FaxStatusDetail";
import VoicemailBoxDetail from "views/VoicemailBox/VoicemailBoxDetail";
import Forbidden from "views/Forbidden";
import VoicemailBoxEdit from "views/VoicemailBox/VoicemailBoxEdit";
import AssignSimCard from "views/SimCard/AssignSimCard";

import SideMenu from "components/Menu/SideMenu/SideMenu";
import UnauthorizedPage from "components/Auth/UnauthorizedPage";
import SessionExpiredModal from "components/Modal/SessionExpiredModal";

const AdminAssignSimCard = Admins(AssignSimCard);

// Extract component from the authorization HOC before using them as component inside <Route />
const AdminDashboard = Admins(Dashboard);
const AdminUserSettingIndex = Admins(UserSettingIndex);

function PrivateRoute({ children, reduxStore }) {
  const { isLoggedIn, currentUser } = useSelector(({ auth }) => auth);
  const { pathname } = useLocation();

  if (isLoggedIn) {
    if (currentUser.role === USER_ROLES.PBX_USER)
      return (
        <div className="app-content h-100">
          <UnauthorizedPage className="h-100" onlyMobileLogin />
        </div>
      );

    return (
      <>
        <SideMenu reduxStore={reduxStore} />
        <div className="app-content h-100">{children}</div>
      </>
    );
  }

  return (
    <Navigate
      to={AUTH.LOGIN}
      state={{
        from: pathname === AUTH.LOGIN ? DASHBOARD.INDEX : pathname,
      }}
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.shape({}).isRequired,
  reduxStore: PropTypes.shape({
    getState: PropTypes.func,
  }).isRequired,
};

function AppRouter({ reduxStore }) {
  const { i18n } = reduxStore.getState();

  if (!i18n.locale) {
    store.dispatch(setAppLocale("da-DK"));

    setLocale("da-DK");
  }

  useEffect(() => {
    store.dispatch(loadTranslations(translationsObject));
  }, []);

  return (
    <div className="app">
      <ReduxToastr
        timeOut={4000}
        key={i18n.locale}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        getState={(state) => state.toastr}
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
      <Router>
        <Routes>
          <Route path={AUTH.LOGIN} element={<LoginView />} />
          <Route path={AUTH.FORGOT_PASSWORD} element={<ResetPassword />} />
          <Route path={AUTH.SET_PASSWORD} element={<SetPassword />} />
          <Route path={AUTH.FORBIDDEN} element={<Forbidden />} />
          <Route path={AUTH.INDEX} element={<Navigate to={AUTH.LOGIN} />} />
          <Route
            path={DASHBOARD.INDEX}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <AdminDashboard />
              </PrivateRoute>
            }
          />

          <Route
            path={USER_SETTING.INDEX}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <AdminUserSettingIndex />
              </PrivateRoute>
            }
          />

          <Route
            path={CONTACT.INDEX}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <ContactIndex />
              </PrivateRoute>
            }
          />

          <Route
            path={FAX_STATUS.SHOW}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <FaxStatusDetail />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path={VOICEMAIL_BOX.INDEX}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <VoicemailBoxDetail />
              </PrivateRoute>
            }
          />

          <Route
            path={VOICEMAIL_BOX.EDIT}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <VoicemailBoxEdit />
              </PrivateRoute>
            }
          />

          <Route
            path={`${AGREEMENT.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <AgreementRoutes />
              </PrivateRoute>
            }
          />

          <Route
            path={`${BILLS.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <BillsRoutes />
              </PrivateRoute>
            }
          />

          <Route
            path={`${CELL_PHONE.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <CellPhoneRoutes />
              </PrivateRoute>
            }
          />

          <Route
            path={`${CREDIT_NOTE.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <CreditNotesRoutes />
              </PrivateRoute>
            }
          />

          <Route
            path={`${DEBTOR.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <DebtorRoutes reduxStore={reduxStore} />
              </PrivateRoute>
            }
          />

          <Route
            path={`${DID_NUMBER.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <DidNumberRoutes />
              </PrivateRoute>
            }
          />

          <Route
            path={`${INVOICE.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <InvoiceRoutes />
              </PrivateRoute>
            }
          />

          <Route
            path={`${NUMBER_PORTING_REQUEST.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <NumberPortingRequestRoutes />
              </PrivateRoute>
            }
          />

          <Route
            path={`${PBX.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <PbxRoutes />
              </PrivateRoute>
            }
          />

          <Route
            path={`${QUOTATION.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <QuotationRoutes />
              </PrivateRoute>
            }
          />

          <Route
            path={`${SETTING.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <SettingRoutes />
              </PrivateRoute>
            }
          />

          <Route
            path={`${APP_SETTING.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <AppSettingRoutes />
              </PrivateRoute>
            }
          />

          <Route
            path={`${SIP_DEVICES.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <SipDeviceRoutes />
              </PrivateRoute>
            }
          />

          <Route
            path={`${SUBSCRIPTION_PRODUCT.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <SubscriptionProductRoutes />
              </PrivateRoute>
            }
          />

          <Route
            path={`${PHONE_NUMBER.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <PhoneNumberRoutes />
              </PrivateRoute>
            }
          />

          <Route
            path={`${SIM_CARD.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <SimCardRoutes />
              </PrivateRoute>
            }
          />

          <Route
            path={SIM_CARD.ASSIGN}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <AdminAssignSimCard />
              </PrivateRoute>
            }
          />

          <Route
            path={`${USER.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <UserRoutes />
              </PrivateRoute>
            }
          />

          <Route
            path={`${USER_LICENSE.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <UserLicenseRoutes />
              </PrivateRoute>
            }
          />

          <Route
            path={`${VENDORS.INDEX}/*`}
            element={
              <PrivateRoute reduxStore={reduxStore}>
                <VendorRoutes />
              </PrivateRoute>
            }
          />

          {/* Not working right now */}
          {/* <Route path="*" component={() => '404 page'} /> */}
        </Routes>
        <SessionExpiredModal />
      </Router>
    </div>
  );
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRouter reduxStore={store} />
      </PersistGate>
    </Provider>
  );
}

AppRouter.propTypes = {
  reduxStore: PropTypes.shape({
    getState: PropTypes.func,
  }).isRequired,
};

export default App;
