import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { dashboardAgreementFetchRequest } from "redux/actions/dashboard/agreement";

import DashboardComponent from "components/Dashboard/DashboardComponent/DashboardComponent";
import AgreementItem from "components/Dashboard/DashboardItem/AgreementItem";

import { DASHBOARD_COMPONENT_FEATURE_TYPES } from "constants/constant";

const AgreementView = ({
  fetchAgreement,
  agreement,
  isLoading,
  fetchError,
}) => {
  useEffect(() => {
    fetchAgreement();
  }, [fetchAgreement]);

  return (
    <DashboardComponent
      translationTitle="menuItem.agreement"
      isLoading={isLoading || !agreement}
      fetchError={fetchError}
      onRetry={fetchAgreement}
      featureType={DASHBOARD_COMPONENT_FEATURE_TYPES.AGREEMENTS}
    >
      <AgreementItem item={agreement} />
    </DashboardComponent>
  );
};

AgreementView.defaultProps = {
  fetchAgreement: () => {},
  agreement: null,
  isLoading: false,
  fetchError: false,
};

AgreementView.propTypes = {
  fetchAgreement: PropTypes.func,
  agreement: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  fetchError: PropTypes.bool,
};

const mapStateToProps = ({ dashboard }) => ({
  ...dashboard.agreement,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAgreement: () => dispatch(dashboardAgreementFetchRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgreementView);
