import React, { useState } from "react";
import { useField, useForm } from "react-final-form";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import Papa from "papaparse";

import { Translate } from "react-i18nify";

import DotsLoadingIndicator from "components/Spinner/DotsLoadingIndicator";

const CSVFileUpload = ({ setInstructionSectionOpen }) => {
  const form = useForm();

  const {
    input: { value: numberType },
  } = useField("number_type");

  const {
    input: { value: requestedPortingDate },
  } = useField("requested_porting_date");

  const {
    input: { value: numberPortingRequestPhoneNumbers },
  } = useField("number_porting_request_phone_numbers_attributes");

  const [fileUploadError, setFileUploadError] = useState("");
  const [isProcessingFile, setIsProcessingFile] = useState(false);

  const hasSupportedHeaderFormats = (csvFields) => {
    const supportedHeaderFormat =
      numberType === "cell_phone"
        ? ["cell_number", "icc_number"]
        : ["phone_number"];

    const isSupported =
      JSON.stringify(csvFields) === JSON.stringify(supportedHeaderFormat);

    if (!isSupported) {
      setFileUploadError(
        <Translate
          value="numberPortingRequest.importForm.csvFileImport.validationErrors.headerFormatNotSupported"
          supportedHeaderFormat={supportedHeaderFormat.join()}
          uploadedHeaderFormat={csvFields.join()}
        />
      );
    }

    return isSupported;
  };

  const formatPhoneNumber = (cellNumber, iccNumber, phoneNumber) => {
    let formattedNumber;

    if (numberType === "cell_phone" && (cellNumber || iccNumber)) {
      formattedNumber = {
        service_number: cellNumber,
        icc_number: iccNumber,
        requested_porting_date: requestedPortingDate,
      };
    } else if (numberType === "did_number" && phoneNumber) {
      formattedNumber = {
        service_number: phoneNumber,
        requested_porting_date: requestedPortingDate,
      };
    } else {
      formattedNumber = false;
    }

    return formattedNumber;
  };

  const formatUploadedPhoneNumbers = (data) =>
    data.map(
      ({
        cell_number: cellNumber,
        icc_number: iccNumber,
        phone_number: phoneNumber,
      }) => {
        const formattedNumber = formatPhoneNumber(
          cellNumber,
          iccNumber,
          phoneNumber
        );

        return formattedNumber;
      }
    );

  const processImportedCSVFile = (csvParsedResults) => {
    const {
      meta: { fields },
      data,
    } = csvParsedResults;

    if (!hasSupportedHeaderFormats(fields)) {
      return;
    }

    setIsProcessingFile(true);

    const formattedPhoneNumbers = formatUploadedPhoneNumbers(data);

    const allNumberPortingRequestPhoneNumbers = [
      ...numberPortingRequestPhoneNumbers,
      ...formattedPhoneNumbers.filter(Boolean),
    ];

    setTimeout(() => {
      form.change(
        "number_porting_request_phone_numbers_attributes",
        allNumberPortingRequestPhoneNumbers
      );

      setInstructionSectionOpen(false);
      setIsProcessingFile(false);
    }, 3000);
  };

  const importNumbersFromCSV = (file) => {
    Papa.parse(file, {
      header: true,
      complete(results) {
        processImportedCSVFile(results);
      },
    });
  };

  const handleCSVFileUpload = ({ target: { files } }) => {
    setFileUploadError("");
    const file = files[0];

    if (!file) return;

    const { type: fileType } = file;

    if (files.length > 1) {
      setFileUploadError(
        <Translate value="numberPortingRequest.importForm.csvFileImport.validationErrors.multipleFileImportNotSupported" />
      );

      return;
    }

    if (fileType !== "text/csv") {
      setFileUploadError(
        <Translate value="numberPortingRequest.importForm.csvFileImport.validationErrors.onlyCSVFileSupported" />
      );

      return;
    }

    importNumbersFromCSV(file);
  };

  const processingIcon = isProcessingFile ? (
    <>
      <span className="me-2 text-warning">
        <Translate value="numberPortingRequest.importForm.csvFileImport.importingNumbers" />
      </span>
      <DotsLoadingIndicator />
    </>
  ) : null;

  return (
    <div>
      <h5>
        <Translate value="numberPortingRequest.importForm.csvFileImport.selectAndUploadCSVFile" />
      </h5>

      <Input
        type="file"
        name="numbers_to_import.csv"
        accept=".csv"
        onChange={handleCSVFileUpload}
        className="form-control-file"
      />
      <div
        className="d-flex align-items-center mt-3"
        hidden={!isProcessingFile}
      >
        {processingIcon}
      </div>

      <span className="invalid-text text-danger">{fileUploadError}</span>
    </div>
  );
};

CSVFileUpload.defaultProps = {};

CSVFileUpload.propTypes = {
  setInstructionSectionOpen: PropTypes.func.isRequired,
};

export default CSVFileUpload;
