import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FormGroup, Row, Col } from "reactstrap";
import { Field, useForm } from "react-final-form";
import { Translate, t } from "react-i18nify";
import { OnChange } from "react-final-form-listeners";
import PropTypes from "prop-types";

import { agreementFetchRequest } from "redux/actions/agreement";
import { paymentTermFetchRequest } from "redux/actions/paymentTerm";

import {
  agreementOptions,
  invoicePeriodOptions,
  terminationTermOptions,
} from "constants/dropdownOptions";

import { required } from "utils/validation";
import { formatDropdownOptions } from "utils/utils";

import { renderDropdownField } from "components/ReduxForm/RenderField";

const RenderAgreementContent = ({
  existingAgreement,
  filteredAgreements,
  agreementTranslation,
  isLoading,
  onAgreementSelect,
  invoicePeriodTranslation,
  paymentTerms,
  isPaymentTermFetching,
}) => {
  if (existingAgreement)
    return (
      <Col md={6}>
        <FormGroup>
          <Field
            name="subscription_attributes.agreement_attributes.id"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={formatDropdownOptions(
              filteredAgreements,
              "ref_number"
            )}
            placeholderTranslation="label.dropdown.selectResource"
            replacementTranslations={{
              placeholder: { resource: agreementTranslation },
            }}
            labelTranslation="agreement.select"
            isLoading={isLoading}
          />

          <OnChange name="subscription_attributes.agreement_attributes.id">
            {(value) => onAgreementSelect(value)}
          </OnChange>
        </FormGroup>
      </Col>
    );

  return (
    <>
      <Col md={6}>
        <FormGroup>
          <Field
            name="subscription_attributes.agreement_attributes.invoice_period"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={invoicePeriodOptions}
            labelTranslation={invoicePeriodTranslation}
            placeholderTranslation="label.dropdown.selectResource"
            replacementTranslations={{
              placeholder: { resource: invoicePeriodTranslation },
            }}
          />
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <Field
            name="subscription_attributes.agreement_attributes.odoo_payment_term_id"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={formatDropdownOptions(paymentTerms)}
            labelTranslation="attributes.paymentTerm"
            isLoading={isPaymentTermFetching}
          />
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <Field
            name="subscription_attributes.agreement_attributes.termination_term"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={terminationTermOptions}
            labelTranslation="attributes.terminationTerm"
          />
        </FormGroup>
      </Col>
    </>
  );
};

const AgreementSection = ({
  agreements,
  fetchAgreements,
  odooDebtorID,
  isLoading,
  invoiceStartDate,
  fetchPaymentTerms,
  isPaymentTermFetching,
  paymentTerms,
  odooPaymentTermID,
}) => {
  const form = useForm();

  const [existingAgreement, setExistingAgreement] = useState(true);

  useEffect(() => {
    fetchAgreements();
    fetchPaymentTerms();
  }, []);

  const handleAgreementOptionSelect = (value) => {
    const addToExistingAgreement = value === "existing";

    if (addToExistingAgreement) {
      form.change(
        "subscription_attributes.agreement_attributes.odoo_payment_term_id",
        undefined
      );
    } else {
      form.batch(() => {
        form.change("subscription_attributes.agreement_id", undefined);
        form.change(
          "subscription_attributes.agreement_attributes.start_date",
          invoiceStartDate
        );
        form.change(
          "subscription_attributes.agreement_attributes.id",
          undefined
        );
        form.change(
          "subscription_attributes.agreement_attributes.odoo_payment_term_id",
          odooPaymentTermID
        );
        form.change(
          "subscription_attributes.agreement_attributes.odoo_debtor_id",
          odooDebtorID
        );
      });
    }

    setExistingAgreement(addToExistingAgreement);
  };

  const handleAgreementSelect = (value) => {
    form.batch(() => {
      form.change("subscription_attributes.agreement_id", value);
      form.change(
        "subscription_attributes.agreement_attributes.odoo_payment_term_id",
        undefined
      );
    });
  };

  const agreementTranslation = "attributes.agreement";

  if (!agreements) {
    return (
      <div>
        <Translate
          value="common.fetchingResource"
          resource={t(agreementTranslation)}
        />
      </div>
    );
  }

  const filteredAgreements = agreements.filter(
    (agreement) => agreement.odoo_debtor_id === odooDebtorID
  );

  const invoicePeriodTranslation = "attributes.invoicePeriod";

  return (
    <Row>
      <Col md={6}>
        <FormGroup>
          <Field
            name="agreement_option"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={agreementOptions}
            placeholderTranslation="agreement.option"
            labelTranslation="agreement.option"
          />
          <OnChange name="agreement_option">
            {(value) => {
              handleAgreementOptionSelect(value);
            }}
          </OnChange>
        </FormGroup>
      </Col>

      <RenderAgreementContent
        agreementTranslation={agreementTranslation}
        existingAgreement={existingAgreement}
        filteredAgreements={filteredAgreements}
        invoicePeriodTranslation={invoicePeriodTranslation}
        isLoading={isLoading}
        isPaymentTermFetching={isPaymentTermFetching}
        onAgreementSelect={handleAgreementSelect}
        paymentTerms={paymentTerms}
      />
    </Row>
  );
};

const mapStateToProps = ({ agreement, paymentTerm }) => {
  const { agreements, isLoading } = agreement;
  const { paymentTerms, isLoading: isPaymentTermFetching } = paymentTerm;

  return {
    agreements,
    isLoading,
    paymentTerms,
    isPaymentTermFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAgreements: () => dispatch(agreementFetchRequest("active", false)),
  fetchPaymentTerms: () => dispatch(paymentTermFetchRequest()),
});

RenderAgreementContent.defaultProps = {
  existingAgreement: true,
  filteredAgreements: [],
  agreementTranslation: "",
  isLoading: false,
  isPaymentTermFetching: false,
  invoicePeriodTranslation: "",
  paymentTerms: [],
};
RenderAgreementContent.propTypes = {
  existingAgreement: PropTypes.bool,
  filteredAgreements: PropTypes.shape([]),
  agreementTranslation: PropTypes.string,
  isLoading: PropTypes.bool,
  isPaymentTermFetching: PropTypes.bool,
  invoicePeriodTranslation: PropTypes.string,
  paymentTerms: PropTypes.arrayOf(PropTypes.shape({})),
  onAgreementSelect: PropTypes.func.isRequired,
};

AgreementSection.defaultProps = {
  isLoading: false,
  isPaymentTermFetching: true,
};
AgreementSection.propTypes = {
  agreements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchAgreements: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
    push: PropTypes.func,
    removeAll: PropTypes.func,
  }).isRequired,
  odooDebtorID: PropTypes.number.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool,
  invoiceStartDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  fetchPaymentTerms: PropTypes.func.isRequired,
  isPaymentTermFetching: PropTypes.bool,
  paymentTerms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  odooPaymentTermID: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgreementSection);
