import React from "react";
import { Form, FormGroup, Label, Row, Col } from "reactstrap";
import { Form as ReactFinalForm, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { Translate } from "react-i18nify";
import PropTypes from "prop-types";

import {
  renderInputField,
  renderDropdownField,
  renderOptions,
} from "components/ReduxForm/RenderField";
import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";

import { required } from "utils/validation";

const RenderDisplayTypeField = ({ dataType, displayTypeOptions }) => {
  if (dataType === "string" || dataType === "integer")
    return (
      <Row className="user-setting">
        <Col md="6">
          <FormGroup>
            <Label for="display_type">
              <Translate value="appSetting.displayType.self" />
            </Label>
            <p className="user-setting-description">
              <Translate value="appSetting.displayType.desc" />
            </p>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Field
              name="display_type"
              component={renderDropdownField}
              validate={required}
              dropdownOptions={displayTypeOptions}
            />
          </FormGroup>
        </Col>
      </Row>
    );

  return null;
};

const RenderOptionsFieldArray = ({ displayType }) => {
  if (displayType === "dropdown")
    return (
      <Row className="user-setting">
        <Col md="6">
          <FormGroup>
            <Label for="options">
              <Translate value="appSetting.options.self" />
            </Label>
            <p className="user-setting-description">
              <Translate value="appSetting.options.desc" />
            </p>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup className="setting-options">
            <div className="add-options">
              <FieldArray
                name="options"
                component={renderOptions}
                placeholder="Custom option"
                validation={required}
                addFieldText="Add new option"
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
    );

  return null;
};

const SettingForm = ({ isLoading, onSubmit, initialValues }) => {
  const categoryOptions = [
    {
      value: "invoice",
      label: <Translate value="enum.setting.categoryOptions.invoice" />,
    },
    {
      value: "pbx",
      label: <Translate value="enum.setting.categoryOptions.pbx" />,
    },
  ];
  const dataTypeOptions = [
    { value: "integer", label: <Translate value="enum.dataTypes.integer" /> },
    { value: "string", label: <Translate value="enum.dataTypes.string" /> },
    { value: "boolean", label: <Translate value="enum.dataTypes.boolean" /> },
    {
      value: "date_time",
      label: <Translate value="enum.dataTypes.dateTime" />,
    },
    { value: "time", label: <Translate value="enum.dataTypes.time" /> },
  ];

  const userTypeOptions = [
    {
      value: "super_admin",
      label: <Translate value="attributes.superAdmin" />,
    },
    { value: "customer", label: <Translate value="attributes.customer" /> },
  ];

  const displayTypeOptions = [
    { value: "input", label: <Translate value="common.inputField" /> },
    { value: "dropdown", label: <Translate value="common.dropdown" /> },
  ];

  return (
    <ReactFinalForm
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ pristine, submitting, handleSubmit, values }) => {
        const { data_type: dataType, display_type: displayType } = values;

        return (
          <Form
            className="flexo-form px-4 py-4 pt-5 w-100 mt-4"
            onSubmit={handleSubmit}
          >
            <div className="setting-content">
              <Row className="user-setting">
                <Col md="6">
                  <FormGroup>
                    <Label className="setting-form-label" for="name">
                      <Translate value="appSetting.name.self" />
                    </Label>
                    <p className="user-setting-description">
                      <Translate value="appSetting.name.desc" />
                    </p>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Field
                      name="name"
                      type="text"
                      placeholder="Name"
                      component={renderInputField}
                      validate={required}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="user-setting">
                <Col md="6">
                  <FormGroup>
                    <Label className="setting-form-label" for="description">
                      <Translate value="appSetting.description.self" />
                    </Label>
                    <p className="user-setting-description">
                      <Translate value="appSetting.description.desc" />
                    </p>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Field
                      name="description"
                      type="textarea"
                      placeholder="Description"
                      component={renderInputField}
                      validate={required}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="user-setting">
                <Col md="6">
                  <FormGroup>
                    <Label className="setting-form-label" for="category">
                      <Translate value="appSetting.category.self" />
                    </Label>
                    <p className="user-setting-description">
                      <Translate value="appSetting.category.desc" />
                    </p>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Field
                      name="category"
                      component={renderDropdownField}
                      validate={required}
                      dropdownOptions={categoryOptions}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="user-setting">
                <Col md="6">
                  <FormGroup>
                    <Label className="setting-form-label" for="data_type">
                      <Translate value="appSetting.dataType.self" />
                    </Label>
                    <p className="user-setting-description">
                      <Translate value="appSetting.dataType.desc" />
                    </p>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Field
                      name="data_type"
                      component={renderDropdownField}
                      validate={required}
                      dropdownOptions={dataTypeOptions}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <RenderDisplayTypeField
                dataType={dataType}
                displayTypeOptions={displayTypeOptions}
              />
              <RenderOptionsFieldArray displayType={displayType} />

              <Row className="user-setting">
                <Col md="6">
                  <FormGroup>
                    <Label className="setting-form-label" for="user_type">
                      <Translate value="appSetting.userType.self" />
                    </Label>
                    <p className="user-setting-description">
                      <Translate value="appSetting.userType.desc" />
                    </p>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Field
                      name="user_type"
                      component={renderDropdownField}
                      validate={required}
                      dropdownOptions={userTypeOptions}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="flexo-form-footer">
              <ReduxFormCommitButton
                title={<Translate value="common.save" />}
                submitting={submitting}
                pristine={pristine}
                isLoading={isLoading}
              />
            </div>
          </Form>
        );
      }}
    />
  );
};

RenderDisplayTypeField.defaultProps = { dataType: "", displayTypeOptions: [] };
RenderDisplayTypeField.propTypes = {
  dataType: PropTypes.string,
  displayTypeOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

RenderOptionsFieldArray.defaultProps = { displayType: "" };
RenderOptionsFieldArray.propTypes = { displayType: PropTypes.string };

SettingForm.defaultProps = {
  initialValues: {},
};
SettingForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
};

export default SettingForm;
