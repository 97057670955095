import React from "react";

import HeaderSkeleton from "components/Skeleton/HeaderSkeleton";
import TabTableSkeleton from "components/Skeleton/TabTableSkeleton";

const SimCardDetailSkeleton = () => {
  return (
    <div className="container-fluid">
      <HeaderSkeleton />
      <TabTableSkeleton />
    </div>
  );
};

export default SimCardDetailSkeleton;
