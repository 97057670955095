import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

import { Translate } from "react-i18nify";

import { numberPortingRequestPhoneNumberUpdateRequest } from "redux/actions/numberPortingRequestPhoneNumber";

import TranslateDate from "components/i18n/TranslateDate";
import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import ResponseForm from "components/NumberPortingRequest/Export/ResponseForm";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const RequestRespondModal = ({
  requestedPortingDate,
  numberPortingRequestPhoneNumber,
  updateNumberPortingRequestPhoneNumber,
  refreshPortingRequest,
  commitSuccess,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleSubmit = async (values, registeredFields) => {
    let serverResponse = {};
    const { id: recordID } = numberPortingRequestPhoneNumber;

    await updateNumberPortingRequestPhoneNumber(recordID, values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  const { export_status: exportStatus } = numberPortingRequestPhoneNumber;

  if (exportStatus !== "Flow created") {
    return null;
  }

  if (commitSuccess && modalOpen) {
    refreshPortingRequest();
    setModalOpen(!modalOpen);
  }

  const translatedRequestedPortingDate = requestedPortingDate && (
    <TranslateDate date={requestedPortingDate} />
  );

  const portingDateCardContent = [
    {
      icon: faCalendarDay,
      title: <Translate value="attributes.requestedPortingDate" />,
      content: translatedRequestedPortingDate,
    },
  ];

  return (
    <div>
      <Button onClick={toggleModal} color="primary">
        <Translate value="numberPortingRequest.respondToRequest" />
      </Button>

      <Modal isOpen={modalOpen} toggle={toggleModal} className="modal-lg">
        <ModalHeader toggle={toggleModal}>
          <Translate value="numberPortingRequest.respondToRequest" />
        </ModalHeader>

        <ModalBody>
          {requestedPortingDate && (
            <Row className="mb-2">
              <Col md={12}>
                <CardWithHeader
                  displayHeader={false}
                  className="shadow-sm"
                  cardContent={portingDateCardContent}
                />
              </Col>
            </Row>
          )}

          <ResponseForm
            requestedPortingDate={requestedPortingDate}
            onSubmit={handleSubmit}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ numberPortingRequestPhoneNumber }) => {
  const { commitSuccess, commitError } = numberPortingRequestPhoneNumber;

  return {
    commitError,
    commitSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateNumberPortingRequestPhoneNumber: async (id, attributes, callback) => {
    await dispatch(
      numberPortingRequestPhoneNumberUpdateRequest(id, attributes, callback)
    );
  },
});

RequestRespondModal.propTypes = {
  requestedPortingDate: PropTypes.string.isRequired,
  numberPortingRequestPhoneNumber: PropTypes.shape({
    id: PropTypes.number,
    export_status: PropTypes.string,
  }).isRequired,
  updateNumberPortingRequestPhoneNumber: PropTypes.func.isRequired,
  refreshPortingRequest: PropTypes.func.isRequired,
  commitError: PropTypes.shape({}).isRequired,
  commitSuccess: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestRespondModal);
