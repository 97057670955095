import { AuthApi } from "internal";

import { setKeepMeLoggedIn } from "utils/auth";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_RECEIVED = "LOGIN_RECEIVED";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_CLEAR_ERRORS = "LOGIN_CLEAR_ERRORS";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_RECEIVED = "RESET_PASSWORD_RECEIVED";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const RESET_PASSWORD_CLEAR_ERRORS = "RESET_PASSWORD_CLEAR_ERRORS";
export const REFRESH_BEARER_TOKEN_RECEIVED = "REFRESH_BEARER_TOKEN_RECEIVED";

export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const SET_PASSWORD_RECEIVED = "SET_PASSWORD_RECEIVED";
export const SET_PASSWORD_FAILED = "SET_PASSWORD_FAILED";
export const SET_PASSWORD_CLEAR_ERRORS = "SET_PASSWORD_CLEAR_ERRORS";

export const SESSION_EXPIRED = "SESSION_EXPIRED";

export const LOGOUT = "LOGOUT";

export function loginReceived(response) {
  return {
    type: LOGIN_RECEIVED,
    tokens: response.data,
  };
}

export function loginFailed(message) {
  return {
    type: LOGIN_FAILED,
    message,
  };
}

export function loginClearErrors() {
  return {
    type: LOGIN_CLEAR_ERRORS,
  };
}

export const loginRequest =
  (username, password, keepMeLoggedIn) => (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });
    AuthApi.login(username, password)
      .then((data) => {
        setKeepMeLoggedIn(keepMeLoggedIn);

        return dispatch(loginReceived(data));
      })
      .catch((errors) => dispatch(loginFailed(errors)));
  };

export function resetPasswordReceived(response) {
  return {
    type: RESET_PASSWORD_RECEIVED,
    tokens: response.data,
  };
}

export const updateBearerTokenReceived = (tokens) => async (dispatch) =>
  dispatch({
    type: REFRESH_BEARER_TOKEN_RECEIVED,
    tokens,
  });

export function resetPasswordFailed(errorData, callback) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: RESET_PASSWORD_FAILED,
    message: errorData,
  };
}

export function resetPasswordClearErrors() {
  return {
    type: RESET_PASSWORD_CLEAR_ERRORS,
  };
}

export const resetPasswordRequest = (email, callback) => async (dispatch) => {
  dispatch({ type: RESET_PASSWORD_REQUEST });

  await AuthApi.resetPassword(email)
    .then((data) => {
      dispatch(resetPasswordReceived(data));
      callback();
    })
    .catch((errors) => dispatch(resetPasswordFailed(errors, callback)));
};

export function setPasswordReceived(response) {
  return {
    type: SET_PASSWORD_RECEIVED,
    tokens: response.data,
  };
}

export function setPasswordFailed(errorData, callback) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: SET_PASSWORD_FAILED,
    message: errorData,
  };
}

export const sessionExpiredRequest = () => (dispatch) => {
  if (dispatch) {
    return dispatch({ type: SESSION_EXPIRED });
  }

  return { type: SESSION_EXPIRED };
};

export const setPasswordRequest =
  (password, passwordConfirmation, resetPasswordToken, callback) =>
  async (dispatch) => {
    dispatch({ type: SET_PASSWORD_REQUEST });

    await AuthApi.setPassword(
      password,
      passwordConfirmation,
      resetPasswordToken
    )
      .then((data) => {
        dispatch(setPasswordReceived(data));
        callback();
      })
      .catch((errors) => dispatch(setPasswordFailed(errors, callback)));
  };

export const logoutRequest = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};
