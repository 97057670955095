import React, { useState } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { t, Translate } from "react-i18nify";
import PropTypes from "prop-types";
import { toastr } from "react-redux-toastr";
import { Navigate } from "react-router-dom";

import PhoneNumberForm from "components/PhoneNumber/Form/PhoneNumberForm";

import {
  clearPhoneNumberError,
  createBulkPhoneNumberRequest,
  createPhoneNumberRequest,
} from "redux/actions/phoneNumber";

import { PHONE_NUMBER } from "constants/routes";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const PhoneNumberCreate = ({
  isLoading,
  commitSuccess,
  createSinglePhoneNumber,
  createBulkPhoneNumber,
  clearError,
}) => {
  const [isNumberRange, setNumberType] = useState(false);
  const [isGold, setCostType] = useState(false);

  const onSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    if (isNumberRange) {
      const {
        phone_number_range_start: startRange,
        phone_number_range_end: endRange,
        number_type: numberType,
        owner,
      } = values;

      const attributes = {
        phone_number: {
          phone_number_range_start: startRange,
          phone_number_range_end: endRange,
          number_type: numberType,
          service_provider_id: owner,
          is_hosted: false,
        },
      };

      await createBulkPhoneNumber(attributes, (errors) => {
        serverResponse = errors;
      });
    } else {
      let attributes = {};
      const {
        phone_number: phoneNumber,
        number_type: numberType,
        price,
        owner,
      } = values;

      if (isGold) {
        attributes = {
          phone_number: {
            phone_number: phoneNumber,
            number_type: numberType,
            service_provider_id: owner,
            is_hosted: false,
            gold_phone_number_attributes: {
              selling_price: price,
            },
          },
        };
      } else {
        attributes = {
          phone_number: {
            phone_number: phoneNumber,
            number_type: numberType,
            service_provider_id: owner,
            is_hosted: false,
          },
        };
      }

      await createSinglePhoneNumber(attributes, (errors) => {
        serverResponse = errors;
      });
    }

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess) {
    const phoneNumberTranslation = isNumberRange
      ? t("title.resource.rangeOfPhoneNumbers")
      : t("title.resource.phoneNumber");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.create", {
        resource: phoneNumberTranslation,
      })
    );

    clearError();

    return <Navigate to={PHONE_NUMBER.INDEX} />;
  }

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.create"
            resource="Phone Number"
          />
        </h3>
      </div>
      <Form onSubmit={onSubmit} keepDirtyOnReinitialize>
        {({ handleSubmit, submitting, pristine }) => (
          <form
            className="flexo-form px-4 py-4 pt-5 w-100"
            onSubmit={handleSubmit}
          >
            <PhoneNumberForm
              submitting={submitting}
              pristine={pristine}
              isLoading={isLoading}
              isNumberRange={isNumberRange}
              setNumberType={setNumberType}
              isGold={isGold}
              setCostType={setCostType}
            />
          </form>
        )}
      </Form>
    </div>
  );
};

PhoneNumberCreate.defaultProps = {
  isLoading: false,
  commitSuccess: false,
  createSinglePhoneNumber: () => {},
  createBulkPhoneNumber: () => {},
};

PhoneNumberCreate.propTypes = {
  isLoading: PropTypes.bool,
  commitSuccess: PropTypes.bool,
  createSinglePhoneNumber: PropTypes.func,
  createBulkPhoneNumber: PropTypes.func,
  clearError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { isSubmitting, commitSuccess } = state.phoneNumber;

  return {
    isLoading: isSubmitting,
    commitSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createSinglePhoneNumber: async (attributes, callback) => {
    await dispatch(createPhoneNumberRequest(attributes, callback));
  },
  createBulkPhoneNumber: async (attributes, callback) => {
    await dispatch(createBulkPhoneNumberRequest(attributes, callback));
  },
  clearError: () => {
    dispatch(clearPhoneNumberError());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberCreate);
