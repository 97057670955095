import React from "react";
import { Translate } from "react-i18nify";

import UserView from "views/Dashboard/List/UserView";
import AgreementView from "views/Dashboard/List/AgreementView";
import DebtorsView from "views/Dashboard/List/DebtorsView";
import InvoiceView from "views/Dashboard/List/InvoiceView";
import BillView from "views/Dashboard/List/BillView";
import AgreementSearchView from "views/Dashboard/List/AgreementSearchView";
import CellPhoneImportRequestView from "views/Dashboard/List/CellPhoneImportRequestView";
import CellPhoneExportRequestView from "views/Dashboard/List/CellPhoneExportRequestView";

const Dashboard = () => (
  <div className="flex-wrap container-fluid">
    <h3 className="mt-3">
      <Translate value="title.page.dashboard" />
    </h3>
    <div className="row">
      <AgreementSearchView />
      <UserView />
      <AgreementView />
      <DebtorsView />
      <InvoiceView />
      <BillView />
      <CellPhoneImportRequestView />
      <CellPhoneExportRequestView />
    </div>
  </div>
);

Dashboard.defaultProps = {};

Dashboard.propTypes = {};

export default Dashboard;
