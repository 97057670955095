import React from "react";
import PropTypes from "prop-types";
import {
  faUser,
  faPhone,
  faEnvelope,
  faMobile,
  faLanguage,
  faStore,
  faBookmark,
  faMapMarkedAlt,
  faFlag,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Translate, t } from "react-i18nify";

import CardWithHeader from "components/Card/WithHeader/CardWithHeader";

const DebtorAdditionalDetail = ({ debtor, resourceName }) => {
  const {
    lang,
    payment_term: paymentTerm,
    invoice_email: invoiceEmail,
    zip,
    technical_contact: technicalAddress,
    country,
    debtor_type: debtorType,
    vat,
  } = debtor;
  const { name, email: technicalEmail, phone, mobile } = technicalAddress;

  const additionalDetailContent = [
    {
      icon: faMapMarkedAlt,
      title: <Translate value="attributes.zipCode" />,
      content: zip,
      dataCyFieldName: "zip",
    },
    {
      icon: faFlag,
      title: <Translate value="attributes.country" />,
      content: country,
      dataCyFieldName: "country",
    },
    {
      icon: faLanguage,
      title: <Translate value="attributes.language" />,
      content: <Translate value={`enum.locale.${lang}`} />,
      dataCyFieldName: "language",
    },
    {
      icon: faStore,
      title: <Translate value="attributes.debtorType" />,
      content: debtorType,
      dataCyFieldName: "type",
    },
  ];

  const invoiceDetailContent = [
    {
      icon: faBookmark,
      title: <Translate value="attributes.vatNumber" />,
      content: vat,
      dataCyFieldName: "invoice-vat-number",
    },
    {
      icon: faEnvelope,
      title: <Translate value="attributes.email" />,
      content: invoiceEmail,
      dataCyFieldName: "invoice-email",
    },
    {
      icon: faCalendarCheck,
      title: <Translate value="attributes.paymentTerm" />,
      content: paymentTerm,
      dataCyFieldName: "invoice-payment-term",
    },
    {
      icon: faStore,
      title: <Translate value="attributes.debtorType" />,
      content: debtorType,
      dataCyFieldName: "invoice-debtor-type",
    },
  ];

  const contactDetailContent = [
    {
      icon: faUser,
      title: <Translate value="attributes.name" />,
      content: name,
      dataCyFieldName: "contact-name",
    },
    {
      icon: faEnvelope,
      title: <Translate value="attributes.email" />,
      content: technicalEmail,
      dataCyFieldName: "contact-email",
    },
    {
      icon: faPhone,
      title: <Translate value="attributes.phone" />,
      content: phone,
      dataCyFieldName: "contact-phone",
    },
    {
      icon: faMobile,
      title: <Translate value="attributes.mobile" />,
      content: mobile,
      dataCyFieldName: "contact-mobile",
    },
  ];

  return (
    <div className="mt-0 px-3">
      <CardWithHeader
        title={<Translate value="common.additionalDetails" />}
        className="shadow-sm"
        cardContent={additionalDetailContent}
        dataCy={resourceName}
      />
      <CardWithHeader
        title={`${t("attributes.invoice")} ${t("common.detail")}`}
        className="shadow-sm"
        cardContent={invoiceDetailContent}
        dataCy={resourceName}
      />
      <CardWithHeader
        title={<Translate value="common.contactDetails" />}
        className="shadow-sm"
        cardContent={contactDetailContent}
        dataCy={resourceName}
      />
    </div>
  );
};

DebtorAdditionalDetail.defaultProps = {
  debtor: {
    technical_contact: {
      name: "",
      email: "",
      phone: "",
      mobile: "",
    },
  },
  resourceName: "",
};

DebtorAdditionalDetail.propTypes = {
  debtor: PropTypes.shape({
    customerNumber: PropTypes.string,
    lang: PropTypes.string,
    payment_term: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    invoice_email: PropTypes.string,
    zip: PropTypes.string,
    technical_contact: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      mobile: PropTypes.string,
    }),
    country: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    debtor_type: PropTypes.string,
    vat: PropTypes.string,
  }),
  resourceName: PropTypes.string,
};

export default DebtorAdditionalDetail;
