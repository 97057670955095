import React from "react";

import HeaderSkeleton from "components/Skeleton/HeaderSkeleton";
import TabTableSkeleton from "components/Skeleton/TabTableSkeleton";

const PhoneNumberDetailSkeleton = () => (
  <div className="container-fluid">
    <HeaderSkeleton />
    <TabTableSkeleton />
  </div>
);

export default PhoneNumberDetailSkeleton;
