import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";

import { Translate } from "react-i18nify";

const CancelButton = ({ handleButtonClick, className, forPortingRequest }) => {
  if (forPortingRequest) {
    return (
      <Button
        color="danger"
        onClick={handleButtonClick}
        className={`${className} me-2`}
      >
        <Translate value="numberPortingRequest.cancelAllImportFlows" />
      </Button>
    );
  }

  return (
    <Button
      color="danger"
      onClick={handleButtonClick}
      className={`${className} d-flex justify-content-center align-items-center`}
      outline
    >
      <FontAwesomeIcon icon={faMinus} className="m-0" />
    </Button>
  );
};

CancelButton.defaultProps = {
  className: "",
  forPortingRequest: false,
};

CancelButton.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  forPortingRequest: PropTypes.bool,
};

export default CancelButton;
