import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.apiEndPoint()}/subscription_products`,
  UPDATE: `${apiUrlConfig.apiEndPoint()}/subscription_products/:id`,
  CREATE: `${apiUrlConfig.apiEndPoint()}/subscription_products`,
  SHOW: `${apiUrlConfig.apiEndPoint()}/subscription_products/:id`,
  DELETE: `${apiUrlConfig.apiEndPoint()}/subscription_products/:id`,
};

class SubscriptionProductApi extends AuthenticatedRequest {
  /**
   * Fetch all subscription_products in the system
   *
   */
  static fetchAll() {
    return this.get(URLS.INDEX);
  }

  /**
   * Update a subscription_product
   *
   * @param {int} id ID of a subscription_product
   *
   * @param {object} attributes attributes of a subscription_product
   * @param {string} subscription_type type of a subscription_product
   * @param {number} odoo_product_id product id of a subscription_product in ODOO
   * @param {string[]} properties array of products' properties
   * @param {string} product_name name of a product
   * @param {number} price price of a product
   * @param {date} default_deployment_date default deployment date for a subscription
   * @param {date} default_invoice_start_date default invoice start date for a subscription
   *
   */
  static update(id, attributes) {
    const updateUrl = URLS.UPDATE.replace(":id", id);

    const requestBody = {
      subscription_product: {
        ...attributes,
      },
    };

    return this.patch(updateUrl, requestBody);
  }

  /**
   * Create a subscription_product
   *
   * @param {object} attributes attributes of a subscription_product
   * @param {string} subscription_type type of a subscription_product
   * @param {number} odoo_product_id product id of a subscription_product in ODOO
   * @param {string[]} properties array of products' properties
   * @param {string} product_name name of a product
   * @param {number} price price of a product
   * @param {date} default_deployment_date default deployment date for a subscription
   * @param {date} default_invoice_start_date default invoice start date for a subscription
   *
   */
  static create(attributes) {
    const requestBody = {
      subscription_product: {
        ...attributes,
      },
    };

    return this.post(URLS.CREATE, requestBody);
  }

  /**
   * Fetch single subscription_product
   *
   * @param {int} id ID of a subscription_product to fetch
   */
  static fetchOne(id) {
    const fetchOneUrl = URLS.SHOW.replace(":id", id);

    return this.get(fetchOneUrl);
  }

  /**
   * Delete single subscription_product
   *
   * @param {int} id ID of a subscription_product to delete
   */
  static deleteOne(id) {
    const deleteUrl = URLS.DELETE.replace(":id", id);

    return this.delete(deleteUrl);
  }
}

export default SubscriptionProductApi;
