import apiUrlConfig from "configs/apiUrlConfig";
import { AuthenticatedRequest } from "internal";

const URLS = {
  SUBSCRIPTION_USAGE: `${apiUrlConfig.apiEndPoint()}/cell_phone_reports/:cell_number/subscription_usage`,
};

class CellPhoneReportApi extends AuthenticatedRequest {
  /**
   * Fetch subscription usage report by cell number
   *
   * @param {int} cellNumber cell number of a cell phone to fetch report for
   *
   */
  static fetchSubscriptionUsage(cellNumber) {
    const subscriptionUsageUrl = URLS.SUBSCRIPTION_USAGE.replace(
      ":cell_number",
      cellNumber
    );

    return this.get(subscriptionUsageUrl);
  }
}

export default CellPhoneReportApi;
