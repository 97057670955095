import React from "react";
import { Translate } from "react-i18nify";

const agreementOptions = [
  {
    value: "new",
    label: <Translate value="enum.pbx.agreement.addNew" />,
  },
  {
    value: "existing",
    label: <Translate value="enum.pbx.agreement.addToExisting" />,
  },
];

const invoicePeriodOptions = [
  {
    value: "monthly",
    label: <Translate value="enum.invoicePeriod.monthly" />,
  },
  {
    value: "quarterly",
    label: <Translate value="enum.invoicePeriod.quarterly" />,
  },
  {
    value: "semi_annually",
    label: <Translate value="enum.invoicePeriod.semiAnnually" />,
  },
  {
    value: "annually",
    label: <Translate value="enum.invoicePeriod.annually" />,
  },
];

const paymentTypeOptions = [
  { value: "Post Paid", label: "Post Paid" },
  { value: "Pre Paid", label: "Pre Paid" },
];

const pbxBillingGroupOptions = [
  {
    value: "user_license",
    label: <Translate value="enum.pbx.billingGroup.userLicense" />,
  },
  {
    value: "cid_num",
    label: <Translate value="enum.pbx.billingGroup.cidNum" />,
  },
  {
    value: "pbx",
    label: <Translate value="enum.pbx.billingGroup.pbx" />,
  },
];

const recordCallOptions = [
  { value: "yes", label: <Translate value="common.yes" /> },
  { value: "no", label: <Translate value="common.no" /> },
  {
    value: "external",
    label: <Translate value="enum.cellPhone.recordCall.external" />,
  },
  {
    value: "yeschange",
    label: <Translate value="enum.cellPhone.recordCall.yesChange" />,
  },
  {
    value: "nochange",
    label: <Translate value="enum.cellPhone.recordCall.noChange" />,
  },
];

const storeFaxOptions = [
  { label: <Translate value="common.yes" />, value: "yes" },
  { label: <Translate value="common.no" />, value: "no" },
];

const terminationTermOptions = [
  {
    value: "thirty_days",
    label: <Translate value="enum.terminationTerm.thirty_days" />,
  },
  {
    value: "next_month_end",
    label: <Translate value="enum.terminationTerm.next_month_end" />,
  },
  {
    value: "three_months",
    label: <Translate value="enum.terminationTerm.three_months" />,
  },
  {
    value: "next_quarter_end",
    label: <Translate value="enum.terminationTerm.next_quarter_end" />,
  },
];

const exportStatusOptions = [
  {
    value: "accepted",
    label: <Translate value="enum.numberPorting.exportStatus.accept" />,
  },
  {
    value: "rejected",
    label: <Translate value="enum.numberPorting.exportStatus.decline" />,
  },
  {
    value: "proposed_new_date",
    label: (
      <Translate value="enum.numberPorting.exportStatus.propose_new_date" />
    ),
  },
];

const proposedDateReasonOptions = [
  {
    value: "Too early according to Rules & Procedures",
    label: <Translate value="enum.numberPorting.proposedDateReason.tooEarly" />,
  },
  {
    value: "Termination period is violated",
    label: (
      <Translate value="enum.numberPorting.proposedDateReason.terminationPeriodViolated" />
    ),
  },
  {
    value: "Contract period is violated",
    label: (
      <Translate value="enum.numberPorting.proposedDateReason.contractPeriodViolated" />
    ),
  },
  {
    value: "Date moved due to excessive load",
    label: (
      <Translate value="enum.numberPorting.proposedDateReason.excessiveLoad" />
    ),
  },
];

const ochRejectionCodeOptions = [
  {
    value: 330,
    label:
      "The number type II configuration does not match donor's registration",
  },
  {
    value: 338,
    label: "Telephone number not located at donor operator",
  },
  {
    value: 339,
    label: "The Customer ID does not match the telephone number",
  },
  {
    value: 348,
    label: "Internal porting in progress to recipient operator",
  },
  {
    value: 349,
    label: "The telephone number is not in use at the donor operator",
  },
  {
    value: 350,
    label: "The FIXED telephone number address is undefined",
  },
  {
    value: 351,
    label: "Rejected due to pending change of telephone number",
  },
  {
    value: 352,
    label: "The telephone has a pending reactivate order",
  },
  {
    value: 353,
    label: "Rejected due to pending change of customer",
  },
  {
    value: 354,
    label: "Rejected due to pending special terminate order - Not in use",
  },
  {
    value: 355,
    label: "The customer rejects porting",
  },
  {
    value: 356,
    label: "Donor operator is the customer",
  },
  {
    value: 357,
    label: "Rejected due to national defence considerations - Not in use",
  },
  {
    value: 358,
    label:
      "Telephone number is the main telephone number in a DDI range - Not in use",
  },
  {
    value: 359,
    label: "The telephone number is part of a DDI range - Not in use",
  },
  {
    value: 360,
    label:
      "The telephone number is the main number in a number dedicated ringing - Not in use",
  },
  {
    value: 361,
    label:
      "The telephone number is part of a number dedicated ringing - Not in use",
  },
  {
    value: 376,
    label: "Written termination not received by Donor within time frame",
  },
  {
    value: 377,
    label:
      "Time to RequestedExecutionDate or possible earliest date exceeds the limit. - Not in use",
  },
  {
    value: 378,
    label:
      "Network Operator rejects porting Request. Contact Network Operator for reason.",
  },
  {
    value: 380,
    label:
      "The Claimant of the porting is not the subscriber of the telephone number",
  },
  {
    value: 381,
    label: "More than one mobile telephone number in the porting. - Not in use",
  },
  {
    value: 382,
    label: "ICC number does not match telephone number",
  },
  {
    value: 383,
    label: "Illegal CVR number used in written termination",
  },
];

const numberTypeDropdown = [
  {
    value: "cell_phone",
    label: <Translate value="common.cellPhone" />,
  },
  {
    value: "did_number",
    label: <Translate value="common.fixed" />,
  },
];

const bindingPeriodSubscriptionType = [
  {
    value: "voice",
    label: <Translate value="quotation.bindingPeriod.subscriptionType.voice" />,
  },
  {
    value: "fiber",
    label: <Translate value="quotation.bindingPeriod.subscriptionType.fiber" />,
  },
  {
    value: "fwa",
    label: <Translate value="quotation.bindingPeriod.subscriptionType.fwa" />,
  },
  {
    value: "x_dsl",
    label: <Translate value="quotation.bindingPeriod.subscriptionType.x_dsl" />,
  },
  {
    value: "hosting_services",
    label: (
      <Translate value="quotation.bindingPeriod.subscriptionType.hosting_services" />
    ),
  },
];

const quotationSubscriptionBindingPeriodInMonths = [
  {
    value: 0,
    label: <Translate value="common.numberOfMonths" months={0} />,
  },
  {
    value: 3,
    label: <Translate value="common.numberOfMonths" months={3} />,
  },
  {
    value: 6,
    label: <Translate value="common.numberOfMonths" months={6} />,
  },
  {
    value: 12,
    label: <Translate value="common.numberOfMonths" months={12} />,
  },
  {
    value: 24,
    label: <Translate value="common.numberOfMonths" months={24} />,
  },
  {
    value: 36,
    label: <Translate value="common.numberOfMonths" months={36} />,
  },
  {
    value: 48,
    label: <Translate value="common.numberOfMonths" months={48} />,
  },
  {
    value: 60,
    label: <Translate value="common.numberOfMonths" months={60} />,
  },
];

const cellPhoneServicesLocation = [
  {
    value: "entire_world",
    label: (
      <Translate value="cellPhone.networkServices.location.entire_world" />
    ),
  },
  {
    value: "eu_ess",
    label: <Translate value="cellPhone.networkServices.location.eu_ess" />,
  },
  {
    value: "sweden",
    label: <Translate value="cellPhone.networkServices.location.sweden" />,
  },
  {
    value: "denmark",
    label: <Translate value="cellPhone.networkServices.location.denmark" />,
  },
  {
    value: "us_verizon",
    label: <Translate value="cellPhone.networkServices.location.us_verizon" />,
  },
  {
    value: "ipn_kjit_countries",
    label: (
      <Translate value="cellPhone.networkServices.location.ipn_kjit_countries" />
    ),
  },
  {
    value: "iot_sydamerika",
    label: (
      <Translate value="cellPhone.networkServices.location.iot_sydamerika" />
    ),
  },
  {
    value: "iot_nordamerika",
    label: (
      <Translate value="cellPhone.networkServices.location.iot_nordamerika" />
    ),
  },
  {
    value: "iot_eu_plus",
    label: <Translate value="cellPhone.networkServices.location.iot_eu_plus" />,
  },
  {
    value: "iot_nordics",
    label: <Translate value="cellPhone.networkServices.location.iot_nordics" />,
  },
  {
    value: "maritime_and_satellite",
    label: (
      <Translate value="cellPhone.networkServices.location.maritime_and_satellite" />
    ),
  },
];

const cellPhoneServicesLocationConstraint = [
  {
    value: "inside",
    label: (
      <Translate value="cellPhone.networkServices.locationConstraint.inside" />
    ),
  },
  {
    value: "outside",
    label: (
      <Translate value="cellPhone.networkServices.locationConstraint.outside" />
    ),
  },
];

const subscriptionForOptions = [
  {
    value: "pbx",
    label: <Translate value="enum.setting.categoryOptions.pbx" />,
  },
  {
    value: "did_number",
    label: <Translate value="title.resource.didNumber" />,
  },
  {
    value: "fax_service",
    label: <Translate value="title.section.faxService" />,
  },
  {
    value: "user_license",
    label: <Translate value="attributes.userLicense" />,
  },
  {
    value: "cell_phone",
    label: <Translate value="common.cellPhone" />,
  },
  {
    value: "voip",
    label: <Translate value="title.tab.agreement.voip" />,
  },
];

const subscriptionCategoryOptions = [
  { value: 1, label: <Translate value="title.tab.agreement.voip" /> },
  { value: 2, label: <Translate value="common.cellPhone" /> },
];

const subscriptionTypeOptions = [
  {
    value: "subscription",
    label: <Translate value="title.section.subscription" />,
  },
  { value: "setup", label: <Translate value="attributes.setup" /> },
  {
    value: "add_on",
    label: (
      <Translate value="enum.subscriptionProduct.subscriptionType.addon" />
    ),
  },
  {
    value: "usage",
    label: <Translate value="attributes.usage" />,
  },
];

const cellPhoneProductTypeOptions = [
  { value: "sms", label: <Translate value="common.sms" /> },
  { value: "mms", label: <Translate value="common.mms" /> },
  {
    value: "voice",
    label: <Translate value="common.voice" />,
  },
  {
    value: "data",
    label: <Translate value="common.data" />,
  },
];

const cellPhoneUsageTypeOptions = [
  { value: "domestic", label: <Translate value="common.domestic" /> },
  { value: "eu", label: <Translate value="common.eu" /> },
  {
    value: "international",
    label: <Translate value="common.international" />,
  },
  {
    value: "eu_roaming",
    label: <Translate value="common.euRoaming" />,
  },
  {
    value: "international_roaming",
    label: <Translate value="common.internationalRoaming" />,
  },
];

export {
  agreementOptions,
  invoicePeriodOptions,
  paymentTypeOptions,
  pbxBillingGroupOptions,
  recordCallOptions,
  storeFaxOptions,
  terminationTermOptions,
  exportStatusOptions,
  proposedDateReasonOptions,
  ochRejectionCodeOptions,
  numberTypeDropdown,
  bindingPeriodSubscriptionType,
  quotationSubscriptionBindingPeriodInMonths,
  cellPhoneServicesLocation,
  cellPhoneServicesLocationConstraint,
  subscriptionForOptions,
  subscriptionCategoryOptions,
  subscriptionTypeOptions,
  cellPhoneProductTypeOptions,
  cellPhoneUsageTypeOptions,
};
