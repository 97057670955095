import React from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import { FormGroup, Label, Row, Col } from "reactstrap";

import ToggleButtonField from "components/ReduxForm/ToggleButtonField";
import {
  renderDropdownField,
  renderInputField,
  renderTimeField,
} from "components/ReduxForm/RenderField";

import { camelize } from "utils/string";
import { formatDropdownOptions } from "utils/utils";

const RenderValueField = ({ displayType, dataType, options, fieldName }) => {
  if (displayType === "dropdown") {
    return (
      <Field
        name={fieldName}
        component={renderDropdownField}
        dropdownOptions={formatDropdownOptions(options)}
      />
    );
  }

  switch (dataType) {
    case "time":
      return <Field name={fieldName} component={renderTimeField} />;
    case "string":
      return (
        <Field name={fieldName} type="text" component={renderInputField} />
      );
    case "boolean":
      return <Field name={fieldName} component={ToggleButtonField} />;
    default:
      return null;
  }
};

RenderValueField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataType: PropTypes.string.isRequired,
  displayType: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
};

const SettingField = ({ setting, index }) => {
  const { dataType, displayType, name, options, description } = setting;

  const fieldName = camelize(name);

  return (
    <Row className="user-setting" key={index}>
      <Col md="6">
        <FormGroup>
          <Label for={name}>{name}</Label>
          <p className="user-setting-description">{description}</p>
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <RenderValueField
            dataType={dataType}
            displayType={displayType}
            fieldName={fieldName}
            options={options}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

RenderValueField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataType: PropTypes.string.isRequired,
  displayType: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
};

SettingField.propTypes = {
  setting: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    dataType: PropTypes.string,
    displayType: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default SettingField;
