import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import SubscriptionBindingPeriodTable from "components/Quotation/Form/SubscriptionBindingPeriod/SubscriptionBindingPeriodTable";

const SubscriptionBindingPeriodSection = ({ fields }) => (
  <div>
    <h4 className="my-4">
      <Translate value="common.bindingPeriods" />
    </h4>

    <SubscriptionBindingPeriodTable fields={fields} />
  </div>
);

SubscriptionBindingPeriodSection.defaultProps = {};

SubscriptionBindingPeriodSection.propTypes = {
  fields: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.shape({
        subscription_type: PropTypes.string,
        binding_period_in_months: PropTypes.number,
      })
    ),
    push: PropTypes.func,
  }).isRequired,
};

export default SubscriptionBindingPeriodSection;
