import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  faBoxOpen,
  faDollarSign,
  faCalendarDay,
  faShieldAlt,
  faHandPeace,
  faServer,
  faFax,
} from "@fortawesome/free-solid-svg-icons";
import { Translate, t } from "react-i18nify";

import { DID_NUMBER } from "constants/routes";

import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import TranslateNumber from "components/i18n/TranslateNumber";
import TranslateDate from "components/i18n/TranslateDate";

const FaxServiceDetail = ({
  didNumber: {
    fax_service: faxService,
    di_fax: type,
    di_faxstationid: name,
    di_faxprotocol: protocol,
    di_fax_store: storeInServer,
    phone_number: phoneNumber,
  },
}) => {
  const {
    subscription_line: subscriptionLine,
    accept_emails_from: acceptEmailsFrom,
  } = faxService;
  const {
    start_date: startDate,
    product_name: productName,
    price,
    last_invoice_date: lastInvoiceDate,
  } = subscriptionLine;

  const faxServiceContent = [
    {
      icon: faFax,
      title: <Translate value="attributes.name" />,
      content: (
        <>
          {name}
          <Link
            to={DID_NUMBER.addNumber(DID_NUMBER.FAX_STATUS_INDEX, phoneNumber)}
            className="ml-1"
          >
            {"( "}
            <Translate value="attributes.viewStatus" />
            {" )"}
          </Link>
        </>
      ),
    },
    {
      icon: faHandPeace,
      title: <Translate value="attributes.type" />,
      content: type,
    },
    {
      icon: faShieldAlt,
      title: <Translate value="attributes.protocol" />,
      content: protocol,
    },
    {
      icon: faServer,
      title: <Translate value="faxService.storeInServer" />,
      content: storeInServer,
    },
    {
      icon: faServer,
      title: <Translate value="faxService.senderEmails" />,
      content: acceptEmailsFrom && acceptEmailsFrom.join(" | "),
    },
  ];

  const subscriptionDetailContent = [
    {
      icon: faBoxOpen,
      title: <Translate value="attributes.product" />,
      content: productName,
    },
    {
      icon: faDollarSign,
      title: <Translate value="attributes.price" />,
      content: <TranslateNumber value={price} minimumFractionDigits={2} />,
    },
    {
      icon: faCalendarDay,
      title: <Translate value="attributes.startDate" />,
      content: <TranslateDate date={startDate} />,
    },
    {
      icon: faCalendarDay,
      title: <Translate value="attributes.lastInvoiceDate" />,
      content: <TranslateDate date={lastInvoiceDate} />,
    },
  ];

  return (
    <div>
      <CardWithHeader
        title={
          <Translate
            value="title.card.resourceDetails"
            resource={t("title.section.faxService")}
          />
        }
        className="shadow-sm"
        cardContent={faxServiceContent}
      />
      <CardWithHeader
        title={`${t("attributes.fax")} ${t("title.section.subscription")} ${t(
          "common.detail"
        )}`}
        className="shadow-sm"
        cardContent={subscriptionDetailContent}
      />
    </div>
  );
};

FaxServiceDetail.defaultProps = {};

FaxServiceDetail.propTypes = {
  didNumber: PropTypes.shape({
    fax_service: PropTypes.shape({
      subscription_line: PropTypes.shape({
        start_date: PropTypes.instanceOf(Date),
        product_name: PropTypes.string,
        price: PropTypes.number,
        last_invoice_date: PropTypes.instanceOf(Date),
      }),
      deployment_date: PropTypes.instanceOf(Date),
      invoice_start_date: PropTypes.instanceOf(Date),
      accept_emails_from: PropTypes.arrayOf(PropTypes.string),
    }),
    di_fax: PropTypes.string,
    di_faxstationid: PropTypes.string,
    di_faxprotocol: PropTypes.string,
    di_fax_store: PropTypes.string,
    phone_number: PropTypes.string,
  }).isRequired,
};

export default FaxServiceDetail;
