import LocalStorageManager, { CONSTANTS } from "./localStorageManager";

export default function token() {
  const bearerToken = LocalStorageManager.get(CONSTANTS.BEARER_TOKEN);

  return bearerToken;
}

export function getKeepMeLoggedIn() {
  return LocalStorageManager.get(CONSTANTS.KEEP_ME_LOGGED_IN);
}

export function setKeepMeLoggedIn(value) {
  LocalStorageManager.set(CONSTANTS.KEEP_ME_LOGGED_IN, value);
}
