import { createSearchAction } from "redux-search";

import { AgreementCreditNoteApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

export const AGREEMENT_CREDIT_NOTE_FETCH_REQUEST =
  "AGREEMENT_CREDIT_NOTE_FETCH_REQUEST";
export const AGREEMENT_CREDIT_NOTE_FETCH_RECEIVED =
  "AGREEMENT_CREDIT_NOTE_FETCH_RECEIVED";
export const AGREEMENT_CREDIT_NOTE_FETCH_FAILED =
  "AGREEMENT_CREDIT_NOTE_FETCH_FAILED";
export const AGREEMENT_CREDIT_NOTE_SEARCH_REQUEST =
  "AGREEMENT_CREDIT_NOTE_SEARCH_REQUEST";

export function creditNoteFetchReceived(response) {
  return {
    type: AGREEMENT_CREDIT_NOTE_FETCH_RECEIVED,
    data: response.data,
  };
}

export function creditNoteFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: AGREEMENT_CREDIT_NOTE_FETCH_FAILED,
    message,
  };
}

export const creditNoteFetchRequest = (agreementID) => (dispatch) => {
  dispatch({ type: AGREEMENT_CREDIT_NOTE_FETCH_REQUEST });

  AgreementCreditNoteApi.fetchAll(agreementID)
    .then((data) => dispatch(creditNoteFetchReceived(data)))
    .catch((e) => {
      dispatch(creditNoteFetchFailed(e));
    });
};

export const searchCreditNotes = createSearchAction("agreementCreditNotes");

export const creditNoteSearchRequest = (value) => {
  const isSearching = Boolean(value);

  return {
    type: AGREEMENT_CREDIT_NOTE_SEARCH_REQUEST,
    isSearching,
  };
};
