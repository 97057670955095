import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import {
  didNumberFetchRequest,
  didNumberSearchRequest,
  searchDidNumbers,
} from "redux/actions/userLicense/didNumber";

import filterSearchResults from "utils/filterSearchResults";
import { getParams } from "utils/queryString";

import DidNumberIndexContent from "components/DidNumber/Index/DidNumberIndexContent";

const DidNumberIndex = ({
  didNumbers,
  isLoading,
  isSearching,
  parentActiveStatus,
  didNumberFetchRequest: fetchAllDidNumbers,
  parentID,
  searchRecords,
}) => {
  const { search } = useLocation();
  const { filter = "active" } = getParams(search);

  useEffect(() => {
    fetchAllDidNumbers(parentID, filter);
  }, [parentID, filter]);

  return (
    <DidNumberIndexContent
      parentActiveStatus={parentActiveStatus}
      parentIdentifierKey="user_license_id"
      parentID={parentID}
      didNumbers={didNumbers}
      isLoading={isLoading}
      isSearching={isSearching}
      searchRecords={searchRecords}
      showFaxServiceSection={false}
    />
  );
};

DidNumberIndex.defaultProps = {
  parentID: undefined,
  isLoading: true,
  didNumbers: undefined,
  isSearching: false,
};

DidNumberIndex.propTypes = {
  didNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      cell_number: PropTypes.number,
      invoice_start_date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]),
      deployment_date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]),
      is_default: PropTypes.bool,
    })
  ),
  didNumberFetchRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  parentID: PropTypes.string,
  parentActiveStatus: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool,
  searchRecords: PropTypes.func.isRequired,
};

const mapStateToProps = ({ userLicenseDidNumber, search }) => {
  const { didNumbers, isLoading, isSearching } = userLicenseDidNumber;

  const results = filterSearchResults({
    resource: didNumbers,
    searchResult: search.userLicenseDidNumbers,
  });

  return {
    didNumbers: results,
    isLoading,
    isSearching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    didNumberFetchRequest: (userLicenseID, filter) => {
      dispatch(didNumberFetchRequest(userLicenseID, filter));
    },
    searchRecords: (e) => {
      dispatch(didNumberSearchRequest(e));
      dispatch(searchDidNumbers(e));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DidNumberIndex);
