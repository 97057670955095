import { VoicemailBoxApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

export const VOICEMAIL_BOX_FETCH_REQUEST = "VOICEMAIL_BOX_FETCH_REQUEST";
export const VOICEMAIL_BOX_FETCH_RECEIVED = "VOICEMAIL_BOX_FETCH_RECEIVED";
export const VOICEMAIL_BOX_FETCH_FAILED = "VOICEMAIL_BOX_FETCH_FAILED";

export const VOICEMAIL_BOX_UPDATE_REQUEST = "VOICEMAIL_BOX_UPDATE_REQUEST";
export const VOICEMAIL_BOX_UPDATE_RECEIVED = "VOICEMAIL_BOX_UPDATE_RECEIVED";
export const VOICEMAIL_BOX_UPDATE_FAILED = "VOICEMAIL_BOX_UPDATE_FAILED";

export const VOICEMAIL_BOX_CLEAR_ERRORS = "VOICEMAIL_BOX_CLEAR_ERRORS";

export function voicemailBoxFetchReceived(response) {
  return {
    type: VOICEMAIL_BOX_FETCH_RECEIVED,
    voicemailBox: response.data,
  };
}

export function voicemailBoxFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: VOICEMAIL_BOX_FETCH_FAILED,
    message,
  };
}

export const fetchVoicemailBoxRequest =
  (voicemailBoxID) => async (dispatch) => {
    dispatch({ type: VOICEMAIL_BOX_FETCH_REQUEST });

    VoicemailBoxApi.fetch(voicemailBoxID)
      .then((data) => {
        dispatch(voicemailBoxFetchReceived(data));
      })
      .catch((error) => {
        dispatch(voicemailBoxFetchFailed(error));
      });
  };

// update voicemail box
export function voicemailBoxUpdateReceived(response) {
  return {
    type: VOICEMAIL_BOX_UPDATE_RECEIVED,
    voicemailBox: response.data,
  };
}

export function voicemailBoxUpdateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: VOICEMAIL_BOX_UPDATE_FAILED,
    message: response.data,
  };
}

export const updateVoicemailBoxRequest =
  (voicemailBoxID, attributes, callback) => async (dispatch) => {
    dispatch({ type: VOICEMAIL_BOX_UPDATE_REQUEST });

    await VoicemailBoxApi.update(voicemailBoxID, attributes)
      .then((data) => {
        dispatch(voicemailBoxUpdateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(voicemailBoxUpdateFailed(errors, callback));
      });
  };
