import React from "react";
import { Translate } from "react-i18nify";

const LANGUAGE_DROPDOWN = [
  {
    value: "en-US",
    label: <Translate value="enum.locale.en-US" />,
  },
  {
    value: "da-DK",
    label: <Translate value="enum.locale.da-DK" />,
  },
];

export default LANGUAGE_DROPDOWN;
