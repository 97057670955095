import {
  VOICEMAIL_BOX_FETCH_REQUEST,
  VOICEMAIL_BOX_FETCH_RECEIVED,
  VOICEMAIL_BOX_FETCH_FAILED,
  VOICEMAIL_BOX_UPDATE_REQUEST,
  VOICEMAIL_BOX_UPDATE_RECEIVED,
  VOICEMAIL_BOX_UPDATE_FAILED,
  VOICEMAIL_BOX_CLEAR_ERRORS,
} from "redux/actions/voicemailBox";

const defaultState = {
  voicemailBox: {},
  fetchError: "",
  isLoading: false,
  isProcessing: false,
  commitError: "",
  commitSuccess: false,
};

const voicemailBox = (state = defaultState, action) => {
  switch (action.type) {
    case VOICEMAIL_BOX_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: null,
        commitSuccess: false,
      };

    case VOICEMAIL_BOX_FETCH_RECEIVED:
      return {
        ...state,
        voicemailBox: action.voicemailBox,
        isLoading: false,
        fetchError: null,
      };

    case VOICEMAIL_BOX_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case VOICEMAIL_BOX_UPDATE_REQUEST:
      return {
        ...state,
        isProcessing: true,
        commitError: null,
        commitSuccess: false,
      };

    case VOICEMAIL_BOX_UPDATE_RECEIVED:
      return {
        ...state,
        voicemailBox: action.voicemailBox,
        isProcessing: false,
        commitError: null,
        commitSuccess: true,
      };

    case VOICEMAIL_BOX_UPDATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isProcessing: false,
      };

    case VOICEMAIL_BOX_CLEAR_ERRORS:
      return {
        ...state,
        fetchError: null,
        commitError: null,
        commitSuccess: false,
      };

    default:
      return state;
  }
};

export default voicemailBox;
