import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import PropTypes from "prop-types";
import { FieldArray } from "react-final-form-arrays";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTty,
  faMobileAlt,
  faServer,
  faNetworkWired,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-i18nify";

import { required } from "utils/validation";

import SubscriptionList from "components/Agreement/Form/Subscription/SubscriptionList";

const RenderSubscriptionTabs = ({ subscriptionSections, activeTab, toggle }) =>
  subscriptionSections.map((subscription) => {
    const { title, tabID, icon } = subscription;

    return (
      <NavItem
        data-testid={tabID}
        key={tabID}
        className={{ active: activeTab === tabID }}
      >
        <NavLink
          className="py-4 px-4"
          onClick={() => {
            toggle(tabID);
          }}
        >
          <span className="text-tertiary">{icon}</span>
          <span className="text-secondary">{title}</span>
        </NavLink>
      </NavItem>
    );
  });

const RenderSubscriptionGroupTabs = ({
  subscriptionSections,
  products,
  agreementStartDate,
}) =>
  subscriptionSections.map((subscription) => {
    const { tabID, productContext, subscriptionType } = subscription;

    const subscriptionProducts = products.filter(
      (product) => parseInt(product.context, 10) === productContext
    );

    return (
      <TabPane tabId={tabID} key={tabID}>
        <FieldArray
          name="subscriptions"
          component={SubscriptionList}
          subscriptionProducts={subscriptionProducts}
          subscriptionType={subscriptionType}
          agreementStartDate={agreementStartDate}
          validate={required}
          dataCy={subscriptionType}
        />
      </TabPane>
    );
  });

const SubscriptionGroup = ({ products, agreementStartDate }) => {
  const [activeTab, setActiveTab] = useState("voip");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const subscriptionSections = [
    {
      title: <Translate value="title.tab.agreement.voip" />,
      productContext: 1,
      subscriptionType: "voip",
      modalTitle: "identifier",
      tabID: "voip",
      icon: <FontAwesomeIcon icon={faTty} className="me-3" />,
    },
    {
      title: <Translate value="common.cellPhone" />,
      productContext: 2,
      subscriptionType: "cell_phone",
      modalTitle: "identifier",
      tabID: "cellPhone",
      icon: <FontAwesomeIcon icon={faMobileAlt} className="me-3" />,
    },
    {
      title: <Translate value="title.tab.agreement.internetConnections" />,
      productContext: 3,
      subscriptionType: "internet_connections",
      modalTitle: "line number",
      tabID: "internetConnections",
      icon: <FontAwesomeIcon icon={faNetworkWired} className="me-3" />,
    },
    {
      title: <Translate value="title.tab.agreement.hostingServices" />,
      productContext: 4,
      subscriptionType: "hosting_services",
      modalTitle: "description identify",
      tabID: "hostingServices",
      icon: <FontAwesomeIcon icon={faServer} className="me-3" />,
    },
    {
      title: <Translate value="title.tab.agreement.serviceContract" />,
      productContext: 5,
      subscriptionType: "service_contracts",
      modalTitle: "identifier",
      tabID: "serviceContracts",
      icon: <FontAwesomeIcon icon={faFileAlt} className="me-3" />,
    },
  ];

  return (
    <div className="br-all shadow-sm mt-4">
      <Nav
        data-testid="subscription-tabs-container"
        tabs
        className="custom-nav-tab light-blue-bg m-0"
      >
        <RenderSubscriptionTabs
          activeTab={activeTab}
          subscriptionSections={subscriptionSections}
          toggle={toggle}
        />
      </Nav>
      <TabContent data-testid="tab-content" activeTab={activeTab}>
        <RenderSubscriptionGroupTabs
          agreementStartDate={agreementStartDate}
          products={products}
          subscriptionSections={subscriptionSections}
        />
      </TabContent>
    </div>
  );
};

RenderSubscriptionTabs.defaultProps = {
  subscriptionSections: [],
  activeTab: "",
};

RenderSubscriptionTabs.propTypes = {
  subscriptionSections: PropTypes.shape([]),
  activeTab: PropTypes.string,
  toggle: PropTypes.func.isRequired,
};

RenderSubscriptionGroupTabs.defaultProps = {
  subscriptionSections: [],
  products: [],
  agreementStartDate: "",
};

RenderSubscriptionGroupTabs.propTypes = {
  subscriptionSections: PropTypes.shape([]),
  products: PropTypes.arrayOf(PropTypes.shape()),
  agreementStartDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
};

SubscriptionGroup.defaultProps = {
  agreementStartDate: undefined,
};

SubscriptionGroup.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  agreementStartDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
};

export default SubscriptionGroup;
