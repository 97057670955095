import React from "react";
import { Translate } from "react-i18nify";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { sortObjectArray } from "utils/utils";

import SubscriptionDetailModal from "components/Agreement/Modal/Subscription/SubscriptionDetailModal/SubscriptionDetailModal";
import TranslateNumber from "components/i18n/TranslateNumber";

const SubscriptionList = ({
  subscriptions,
  isAgreementArchived,
  products,
  dataCy,
}) => {
  const isSubscriptionLinesEmpty = subscriptions?.length === 0;

  const noSubscriptionContent = isSubscriptionLinesEmpty ? (
    <div className="w-100 h-100 empty-screen">
      <div className="icon-holder mb-4 bg-danger">
        <FontAwesomeIcon icon={faTimes} className="m-0" />
      </div>

      <h5
        className="text-secondary"
        data-field-name={`${dataCy}-subscriptions-not-found`}
      >
        <Translate value="message.subscription.noSubscription" />
      </h5>
    </div>
  ) : null;

  const subscriptionList = subscriptions.map((subscription, index) => {
    const {
      id,
      is_archived: isArchived,
      subscription_lines: subscriptionLines,
      monthly_price: monthlyPrice,
      setup_price: setupPrice,
    } = subscription;

    const lineToDisplay = subscriptionLines.find(
      (subscriptionLine) => subscriptionLine.line_type === "subscription"
    );

    const { subscription_line_properties: subscriptionLineProperties } =
      lineToDisplay;
    const sortedSubscriptionLineProperties = sortObjectArray(
      subscriptionLineProperties,
      "property"
    );
    const lineProperties = sortedSubscriptionLineProperties.filter(
      (lineProperty) => lineProperty.property !== "Topic"
    );

    const propertiesToDisplay = lineProperties.map((lineProperty) => {
      const { id: linePropertyID, property, value } = lineProperty;

      return <div key={linePropertyID}>{`${property}: ${value}`}</div>;
    });

    return (
      <tr key={id}>
        <td data-field-name={`${dataCy}-subscriptions[${index}]-properties`}>
          {propertiesToDisplay}
        </td>
        <td data-field-name={`${dataCy}-subscriptions[${index}]-monthly_price`}>
          <TranslateNumber value={monthlyPrice} minimumFractionDigits={2} />
        </td>
        <td data-field-name={`${dataCy}-subscriptions[${index}]-setup_price`}>
          <TranslateNumber value={setupPrice} minimumFractionDigits={2} />
        </td>
        <td data-field-name={`${dataCy}-subscriptions[${index}]-action`}>
          <SubscriptionDetailModal
            subscription={subscription}
            subscriptionLines={subscriptionLines}
            subscriptionProduct={lineToDisplay}
            products={products}
            isAgreementArchived={isAgreementArchived}
            isSubscriptionArchived={isArchived}
            dataCyFieldName={`${dataCy}-subscriptions[${index}]-view_action`}
          />
        </td>
      </tr>
    );
  });

  return (
    <div className="h-100">
      <div
        className="table-responsive subscription-list-table"
        hidden={isSubscriptionLinesEmpty}
      >
        <table className="w-100" data-cy={`${dataCy}-subscriptions-table`}>
          <thead>
            <tr>
              <th>
                <Translate value="attributes.properties" />
              </th>
              <th>
                <Translate value="attributes.monthlyPrice" />
              </th>
              <th>
                <Translate value="common.setupPrice" />
              </th>
              <th>
                <Translate value="common.action" />
              </th>
            </tr>
          </thead>
          <tbody>{subscriptionList}</tbody>
        </table>
      </div>
      {noSubscriptionContent}
    </div>
  );
};

SubscriptionList.defaultProps = {};

SubscriptionList.propTypes = {
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      is_archived: PropTypes.bool,
      subscription_lines: PropTypes.arrayOf(PropTypes.shape({})),
      monthly_price: PropTypes.number,
      setup_price: PropTypes.number,
    })
  ).isRequired,
  isAgreementArchived: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataCy: PropTypes.string.isRequired,
};

export default SubscriptionList;
