import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Translate } from "react-i18nify";

const OrderNewSimCardModal = ({
  modalData,
  modalVisible,
  onConfirm,
  onCancel,
}) => {
  if (!modalVisible) return null;

  return (
    <Modal isOpen={modalVisible} toggle={onCancel}>
      <ModalHeader className="border-0 pb-0" toggle={onCancel} />
      <ModalBody className="pt-0">
        <Translate value="modal.orderNewSimCard.resource" />{" "}
        <p className="text-danger pt-3">
          <Translate value="modal.orderNewSimCard.existingSimCardBlockWarning" />
        </p>
      </ModalBody>
      <ModalFooter>
        <button
          className=" btn btn-outline-secondary"
          type="button"
          onClick={onCancel}
        >
          <Translate value="common.cancel" />
        </button>
        <Button color="primary" onClick={() => onConfirm(modalData)}>
          <Translate value="common.order" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

OrderNewSimCardModal.propTypes = {
  modalData: PropTypes.number.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default OrderNewSimCardModal;
