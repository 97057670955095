import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { Translate } from "react-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive } from "@fortawesome/free-solid-svg-icons";

const ArchiveButton = ({ handleButtonClick, className }) => (
  <Button
    color="danger"
    onClick={handleButtonClick}
    className={`${className} me-2`}
    data-testid="archive-button"
  >
    <FontAwesomeIcon
      icon={faArchive}
      className="m-0 me-2"
      data-testid="fa-archive"
    />
    <Translate value="common.archive" />
  </Button>
);

ArchiveButton.defaultProps = {
  className: "",
};

ArchiveButton.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default ArchiveButton;
