import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { Translate } from "react-i18nify";

import DotsLoadingIndicator from "components/Spinner/DotsLoadingIndicator";
import CancelButton from "components/NumberPortingRequest/Detail/CancelButton";

const CancelAllImportFlowsModal = ({
  resourceIdentifier,
  cancelPortingFlow,
  isLoading,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const loadingIcon = isLoading ? (
    <DotsLoadingIndicator className="ms-2" />
  ) : null;

  return (
    <div>
      <CancelButton
        handleButtonClick={() => setModalOpen(true)}
        forPortingRequest
      />

      <Modal isOpen={modalOpen} toggle={closeModal}>
        <ModalHeader>{resourceIdentifier}</ModalHeader>

        <ModalBody>
          <Translate value="numberPortingRequest.requestCancelModal.cancelRequest" />

          <p className="text-danger pt-3">
            <Translate value="modal.delete.irreversible" />
          </p>
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary d-flex align-items-center justify-content-center"
            onClick={() => cancelPortingFlow(closeModal)}
          >
            {loadingIcon}
            <Translate value="common.confirm" />
          </Button>

          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={closeModal}
          >
            <Translate value="common.cancel" />
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

CancelAllImportFlowsModal.propTypes = {
  resourceIdentifier: PropTypes.string.isRequired,
  cancelPortingFlow: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default CancelAllImportFlowsModal;
