import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Row,
  Col,
  ModalFooter,
} from "reactstrap";
import { Field, useForm, useField } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import { required } from "utils/validation";

import { renderDateField } from "components/ReduxForm/RenderField";
import SubscriptionLineGroup from "components/Agreement/Form/SubscriptionLine/SubscriptionLineGroup/SubscriptionLineGroup";

/**
 *
 * TODO:
 *
 * 1. Reset subscription dates if agreement start date changes and is greater than previously chosen subscription dates
 *
 */

const SubscriptionEditModal = ({
  subscriptionProducts,
  subscription: initialSubscription,
  agreementStartDate,
  subscriptionIndex,
  subscriptionStartDate,
  isPropertyEditable,
  subscriptionEndDate,
}) => {
  const form = useForm();

  const {
    meta: { invalid },
  } = useField(`subscriptions[${subscriptionIndex}]`);

  const [modal, setModal] = useState(false);
  const [subscription, setSubscription] = useState(initialSubscription);

  useEffect(() => {
    if (!modal) setSubscription(initialSubscription);
  }, [modal, initialSubscription]);

  const setModalStatus = (saveChanges) => {
    if (invalid) {
      form.submit();

      return;
    }

    if (!saveChanges) {
      form.change(
        `subscriptions[${subscriptionIndex}].subscription_lines`,
        subscription.subscription_lines
      );
    }

    setModal(!modal);
  };

  const toggleModal = () => {
    setModalStatus();
  };

  const onUpdate = () => {
    const saveChanges = true;

    setModalStatus(saveChanges);
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-success table-action-btn me-3 text-white"
        onClick={toggleModal}
        data-cy={`subscriptions[${subscriptionIndex}].edit-btn`}
        data-testid={`subscriptions[${subscriptionIndex}].edit-btn`}
      >
        <FontAwesomeIcon icon={faPencilAlt} />
      </button>

      <Modal
        data-testid={`subscription[${subscriptionIndex}].edit-modal`}
        isOpen={modal}
        toggle={toggleModal}
        className="subscription-modal modal-xl"
      >
        <ModalHeader toggle={toggleModal} className="px-4">
          <Translate value="common.editSubscription" />
        </ModalHeader>
        <ModalBody className="subscription-modal-body px-4 p-3">
          <Row className="mt-2">
            <Col md="6">
              <FormGroup>
                <Field
                  name={`subscriptions[${subscriptionIndex}].start_date`}
                  component={renderDateField}
                  validate={required}
                  labelTranslation="attributes.startDate"
                  disabledDays={{
                    before: new Date(agreementStartDate),
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Field
                  name={`subscriptions[${subscriptionIndex}].end_date`}
                  component={renderDateField}
                  labelTranslation="attributes.terminationDate"
                  disabledDays={{
                    before: new Date(agreementStartDate),
                  }}
                  disabled={!isPropertyEditable}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="subscription-lines-section mt-3">
            <FieldArray
              name={`subscriptions[${subscriptionIndex}].subscription_lines`}
              component={SubscriptionLineGroup}
              subscriptionProducts={subscriptionProducts}
              subscriptionIndex={subscriptionIndex}
              subscriptionStartDate={subscriptionStartDate}
              subscriptionEndDate={subscriptionEndDate}
              isPropertyEditable={isPropertyEditable}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary"
            onClick={onUpdate}
            data-cy={`subscriptions[${subscriptionIndex}].update-btn`}
            data-testid={`subscriptions[${subscriptionIndex}].update-btn`}
          >
            <Translate value="common.updateSubscription" />
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

SubscriptionEditModal.defaultProps = {
  agreementStartDate: undefined,
  subscriptionStartDate: undefined,
  subscriptionEndDate: undefined,
  isPropertyEditable: true,
};

SubscriptionEditModal.propTypes = {
  subscriptionProducts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  agreementStartDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  subscriptionStartDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  subscriptionEndDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  subscription: PropTypes.shape().isRequired,
  subscriptionIndex: PropTypes.number.isRequired,
  isPropertyEditable: PropTypes.bool,
};

export default SubscriptionEditModal;
