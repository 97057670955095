import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useLocation, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import { singleDebtorFetchRequest } from "redux/actions/debtor";

import HasPermission from "hoc/RbacCTA";

import DebtorBasicDetail from "components/Debtor/Detail/BasicDetail/DebtorBasicDetail";
import DebtorAdditionalDetail from "components/Debtor/Detail/AdditionalDetail/AdditionalDetail";
import TabWithRoute from "components/Tab/TabWithRoute/TabWithRoute";
import FetchError from "components/Error/FetchError";
import DebtorDetailSkeleton from "components/Skeleton/ResourceDetail/DebtorDetail/DebtorDetailSkeleton";

import { CREDIT_NOTE } from "constants/routes";
import { RESOURCE_NAMES } from "constants/constant";
import {
  AGREEMENTS,
  CONTACTS,
  CREDIT_NOTES,
  DETAILS,
  INVOICES,
  PBXS,
} from "constants/routes/routesResources";
import ContactIndex from "views/Contact/ContactIndex";
import AgreementIndex from "views/Agreement/AgreementIndex";
import CreditNotesIndex from "views/CreditNotes/CreditNotesIndex";
import InvoiceIndex from "views/Invoice/InvoiceIndex";
import PbxIndex from "views/Pbx/PbxIndex";

const renderDebtorAdditionalDetail = ({ debtorID, debtor }) => (
  <DebtorAdditionalDetail
    debtorID={debtorID}
    debtor={debtor}
    resourceName={RESOURCE_NAMES.DEBTOR}
  />
);

const DebtorDetail = ({ fetchSingleDebtor, debtor, isLoading, fetchError }) => {
  const location = useLocation();
  const { debtorID } = useParams();

  const fetchDebtor = () => {
    fetchSingleDebtor(debtorID);
  };

  useEffect(() => {
    fetchDebtor();
  }, [debtorID]);

  if (fetchError) {
    return <FetchError onRetry={fetchDebtor} />;
  }

  if (isLoading || !debtor) {
    return <DebtorDetailSkeleton />;
  }

  const getSharedRouteComponent = () => {
    const debtorDetailsRoute = `/debtors/${debtorID}`;

    if (location.pathname === `${debtorDetailsRoute}/details`)
      return (
        <DebtorAdditionalDetail
          debtorID={debtorID}
          debtor={debtor}
          resourceName={RESOURCE_NAMES.DEBTOR}
        />
      );

    if (location.pathname.includes(`${debtorDetailsRoute}/contacts`))
      return <ContactIndex parentID={debtorID} />;

    if (location.pathname.includes(`${debtorDetailsRoute}/agreements`))
      return <AgreementIndex parentID={debtorID} />;

    if (location.pathname.includes(`${debtorDetailsRoute}/credit_notes`))
      return <CreditNotesIndex parentID={debtorID} />;

    if (location.pathname.includes(`${debtorDetailsRoute}/invoices`))
      return <InvoiceIndex parentID={debtorID} />;

    if (location.pathname.includes(`${debtorDetailsRoute}/pbxs`))
      return <PbxIndex parentID={debtorID} />;

    return <Outlet />;
  };

  const getCreditNotesOrInvoicesRouteDetail = () => {
    if (location.pathname.includes(CREDIT_NOTE.INDEX))
      return {
        tabRoute: CREDIT_NOTES,
        tabTitle: <Translate value="menuItem.creditNotes" />,
      };

    return {
      tabRoute: INVOICES,
      tabTitle: <Translate value="menuItem.invoice" />,
    };
  };

  const tabRoutesDetail = [
    { tabRoute: DETAILS, tabTitle: <Translate value="common.detail" /> },
    {
      tabRoute: CONTACTS,
      tabTitle: <Translate value="menu.customerAdmin.contact" />,
    },
    {
      tabRoute: AGREEMENTS,
      tabTitle: <Translate value="menuItem.agreement" />,
    },

    {
      tabRoute: PBXS,
      tabTitle: <Translate value="menuItem.pbx" />,
    },
    getCreditNotesOrInvoicesRouteDetail(),
  ];

  return (
    <HasPermission
      perform="debtors:view"
      data={{ debtorID: debtor.id || debtorID }}
      redirect
    >
      <div className="debtor-detail container-fluid w-100">
        <DebtorBasicDetail
          debtor={debtor}
          resourceName={RESOURCE_NAMES.DEBTOR}
        />
        <HasPermission
          perform="debtors:tabs"
          no={() => renderDebtorAdditionalDetail({ debtor, debtorID })}
        >
          <TabWithRoute
            resourceName={RESOURCE_NAMES.DEBTOR}
            routesDetail={tabRoutesDetail}
          >
            {getSharedRouteComponent()}
          </TabWithRoute>
        </HasPermission>
      </div>
    </HasPermission>
  );
};

DebtorDetail.defaultProps = {
  isLoading: true,
  debtor: undefined,
  fetchError: null,
};

DebtorDetail.propTypes = {
  debtor: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    customer_number: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    phone: PropTypes.string,
    status: PropTypes.string,
    address: PropTypes.string,
    website: PropTypes.string,
    created_at: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  fetchSingleDebtor: PropTypes.func.isRequired,
  fetchError: PropTypes.string,
};

const mapStateToProps = ({ debtor: debtorResource }) => {
  const { debtor, isLoading, fetchError } = debtorResource;

  return {
    debtor,
    isLoading,
    fetchError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleDebtor: (id) => {
    dispatch(singleDebtorFetchRequest(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DebtorDetail);
