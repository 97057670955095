import React from "react";

import PropTypes from "prop-types";

const FormError = ({
  error,
  submitError,
  touched,
  active,
  className,
  warning,
  name,
  inputError,
}) => {
  if (inputError && active) {
    return (
      <span
        className={`${className} text-danger`}
        data-field-error={`${name}-field-errors`}
        data-testid="error-text"
      >
        {inputError}
      </span>
    );
  }

  if ((error || submitError) && touched) {
    return (
      <span
        className={`${className} text-danger`}
        data-field-error={`${name}-field-errors`}
        data-testid="error-text"
      >
        {error || submitError}
      </span>
    );
  }

  if (warning) {
    return (
      <span
        className={`${className} text-warning`}
        data-field-warning={`${name}-field-warnings`}
        data-testid="warning-text"
      >
        {warning}
      </span>
    );
  }

  return null;
};

FormError.defaultProps = {
  error: null,
  submitError: null,
  touched: false,
  active: false,
  className: "",
  warning: null,
  name: null,
  inputError: null,
};

FormError.propTypes = {
  touched: PropTypes.bool,
  active: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  submitError: PropTypes.bool,
  className: PropTypes.string,
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  name: PropTypes.string,
  inputError: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

export default FormError;
