import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import FilterElementList from "components/ResourceHeader/FilterElementList";

const ResourceHeader = ({
  changeFilter,
  resource,
  allFilters,
  resourceStatusComponent,
}) => (
  <div className="agreement-status-wrapper p-2 p-md-4">
    <div className="d-flex align-items-center">
      <div className="stats-icon-holder primary m-2">
        <FontAwesomeIcon icon={faFilter} />
      </div>

      <FilterElementList
        filterList={allFilters}
        resource={resource}
        changeFilter={changeFilter}
      />
    </div>

    {resourceStatusComponent}
  </div>
);

ResourceHeader.defaultProps = {
  resource: undefined,
  changeFilter: () => {},
  resourceStatusComponent: undefined,
};

ResourceHeader.propTypes = {
  resource: PropTypes.string,
  allFilters: PropTypes.arrayOf(
    PropTypes.shape({
      filter: PropTypes.arrayOf(PropTypes.string),
      selectedFilter: PropTypes.string,
      type: PropTypes.string,
      headerTranslation: PropTypes.string,
    })
  ).isRequired,
  changeFilter: PropTypes.func,
  resourceStatusComponent: PropTypes.node,
};

export default ResourceHeader;
