import {
  ROLE_FETCH_REQUEST,
  ROLE_FETCH_RECEIVED,
  ROLE_FETCH_FAILED,
  PBX_USER_ROLES_FETCH_REQUEST,
  PBX_USER_ROLES_FETCH_RECEIVED,
  PBX_USER_ROLES_FETCH_FAILED,
  ROLE_FETCH_CLEAR_ERRORS,
} from "redux/actions/role";

const defaultState = {
  roles: [],
  pbxRoles: [],
  roleFetchError: "",
};

function role(state = defaultState, action) {
  const { roles, type, pbxRoles } = action;

  switch (type) {
    case ROLE_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ROLE_FETCH_RECEIVED:
      return {
        ...state,
        roles,
        isLoading: false,
      };

    case ROLE_FETCH_FAILED:
      return {
        ...state,
        roleFetchError: action.message,
      };

    case PBX_USER_ROLES_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case PBX_USER_ROLES_FETCH_RECEIVED:
      return {
        ...state,
        pbxRoles,
        isLoading: false,
      };

    case PBX_USER_ROLES_FETCH_FAILED:
      return {
        ...state,
        roleFetchError: action.message,
      };

    case ROLE_FETCH_CLEAR_ERRORS:
      return { ...state, roleFetchError: "", singleROLEFetchError: "" };

    default:
      return state;
  }
}

export default role;
