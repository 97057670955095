import React from "react";
import PropTypes from "prop-types";

const SubscriptionNameList = ({ subscriptions }) => (
  <div className="d-md-flex align-items-center justify-content-md-between rounded product-item mb-2 border-0 p-3">
    <div className="mt-4 mt-md-0 mb-3 mb-md-0 d-md-flex align-items-center">
      {subscriptions.map((subscriptionName) => {
        return (
          <span
            key={subscriptionName}
            className="badge rounded-pill bg-primary py-1 px-2 tags d-inline-block mt-2 mt-md-0"
          >
            {subscriptionName}
          </span>
        );
      })}
    </div>
  </div>
);

SubscriptionNameList.defaultProps = {
  subscriptions: [],
};

SubscriptionNameList.propTypes = {
  subscriptions: PropTypes.arrayOf(PropTypes.string),
};

export default SubscriptionNameList;
