import {
  CELL_PHONE_SERVICES_FETCH_FAILED,
  CELL_PHONE_SERVICES_FETCH_RECEIVED,
  CELL_PHONE_SERVICES_FETCH_REQUEST,
} from "redux/actions/cellPhoneServices";

const defaultState = {
  cellPhoneServices: [],
};

function cellPhoneServices(state = defaultState, action) {
  switch (action.type) {
    case CELL_PHONE_SERVICES_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitError: "",
        commitSuccess: false,
      };

    case CELL_PHONE_SERVICES_FETCH_RECEIVED:
      return {
        ...state,
        cellPhoneServices: action.cellPhoneServices,
        isLoading: false,
      };

    case CELL_PHONE_SERVICES_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    default:
      return state;
  }
}

export default cellPhoneServices;
