import React from "react";
import { toastr } from "react-redux-toastr";
import { t } from "react-i18nify";

import STATUS from "constants/statusCode";

/*
 * Displays the toaster when there is unmapped form errors after API call which cannot be mapped to any particular form field.
 * It takes response object from backend as parameter and displays errors according to the properties received.
 */
const displayUnMappedFormErrors = ({ data, status }, registeredFormFields) => {
  if (status === STATUS.INTERNAL_SERVER_ERROR) {
    toastr.error(t("common.error"), t("serverErrors.internalServerError"));

    return;
  }

  let finalResponseArray = null;

  const responseArray = Object.entries(data);
  const filteredResponseArray = responseArray.filter(
    (response) => !registeredFormFields.includes(response[0])
  );

  if (filteredResponseArray.length) finalResponseArray = filteredResponseArray;
  else finalResponseArray = responseArray;

  // Generate different toaster for different errors
  finalResponseArray.forEach((responseValue) => {
    const capitalizedFirstLetter = responseValue[0][0].toUpperCase();
    const formattedRemainingString = responseValue[0]
      .substring(1)
      .replaceAll("_", " ")
      .replaceAll(".", " ");
    const toasterTitle = `${capitalizedFirstLetter}${formattedRemainingString}`;

    // Generate multiple messages if present
    const messageComponent = (
      <>
        {responseValue[1].map((value) => (
          <p>{value}</p>
        ))}
      </>
    );

    toastr.error(toasterTitle, "", {
      component: messageComponent,
    });
  });
};

export default displayUnMappedFormErrors;
