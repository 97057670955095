import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import ActivityLogTable from "components/NumberPortingRequest/Export/Detail/ActivityLogTable";
import EmptyTableUI from "components/Table/RecordListingTable/EmptyTableUI";
import ViewButton from "./ViewButton";

const ActivityLogModal = ({ resourceIdentifier, activityLogs }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const modalContent =
    activityLogs.length === 0 ? (
      <EmptyTableUI hideAddButton />
    ) : (
      <ActivityLogTable activityLogs={activityLogs} />
    );

  return (
    <div>
      <ViewButton handleButtonClick={() => setModalOpen(true)} />

      <Modal
        isOpen={modalOpen}
        toggle={closeModal}
        className="min-w-70-percent"
      >
        <ModalHeader>{resourceIdentifier}</ModalHeader>

        <ModalBody className="h-30em">{modalContent}</ModalBody>
      </Modal>
    </div>
  );
};

ActivityLogModal.propTypes = {
  resourceIdentifier: PropTypes.string.isRequired,
  activityLogs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      status: PropTypes.string,
      activity_datetime: PropTypes.instanceOf(Date),
    })
  ).isRequired,
};

export default ActivityLogModal;
