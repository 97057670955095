import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import { capitalizeWords } from "utils/string";

import TranslateNumber from "components/i18n/TranslateNumber";
import TranslateDate from "components/i18n/TranslateDate";

const SubscriptionLineList = ({ subscriptionLines }) => {
  const isEndDateAvailable = subscriptionLines.some(
    (subscriptionLine) => subscriptionLine.end_date !== null
  );

  const subscriptionList = subscriptionLines.map((subscriptionLine) => {
    const {
      id,
      product_name: productName,
      price,
      end_date: endDate,
      start_date: startDate,
      line_type: lineType,
      subscription_line_properties: subscriptionLineProperties,
    } = subscriptionLine;

    const propertiesWithoutTopic = subscriptionLineProperties.filter(
      (lineProperty) => lineProperty.property !== "Topic"
    );
    const propertiesContent = propertiesWithoutTopic.map((lineProperty) => {
      const { id: linePropertyID, property, value } = lineProperty;

      return <p key={linePropertyID}>{`${property}: ${value}`}</p>;
    });

    return (
      <tr key={id}>
        <td>{capitalizeWords(lineType)}</td>
        <td>{productName}</td>
        <td>{propertiesContent}</td>
        <td>
          <TranslateDate date={startDate} />
        </td>
        {isEndDateAvailable && (
          <td>
            <TranslateDate date={endDate} />
          </td>
        )}
        <td>
          <TranslateNumber value={price} minimumFractionDigits={2} />
        </td>
      </tr>
    );
  });

  return (
    <table
      className="table flexo-table"
      hidden={subscriptionLines.length === 0}
    >
      <thead>
        <tr>
          <th>
            <Translate value="attributes.type" />
          </th>
          <th>
            <Translate value="attributes.product" />
          </th>
          <th>
            <Translate value="attributes.properties" />
          </th>
          <th>
            <Translate value="attributes.startDate" />
          </th>
          {isEndDateAvailable && (
            <th>
              <Translate value="attributes.terminationDate" />
            </th>
          )}

          <th>
            <Translate value="attributes.price" />
          </th>
        </tr>
      </thead>
      <tbody>{subscriptionList}</tbody>
    </table>
  );
};

SubscriptionLineList.propTypes = {
  subscriptionLines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      product_name: PropTypes.string,
      price: PropTypes.number,
      start_date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]),
      line_type: PropTypes.string,
      subscription_line_properties: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          property: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ).isRequired,
};

export default SubscriptionLineList;
