import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.odooEndPoint()}/debtors/:debtorID/contacts`,
  UPDATE: `${apiUrlConfig.odooEndPoint()}/contacts/:id`,
  TELECOM_USER_EMAILS: `${apiUrlConfig.odooEndPoint()}/contacts/telecom_user_emails`,
};

class ContactApi extends AuthenticatedRequest {
  /**
   * Fetch all contacts of a debtor
   *
   * @param {int} debtorID ID of a debtor for whom contacts are to be fetched
   *
   */
  static fetchAll(debtorID) {
    const fetchUrl = URLS.INDEX.replace(":debtorID", debtorID);

    return this.get(fetchUrl);
  }

  /**
   * Update single contact
   *
   * @param {int} debtorID ID of a debtor for whom settings are to be updated
   *
   * @param {object[]} attributes Array of debtor settings
   * @param {string} type contact type
   * @param {string} name name of a contact/user
   * @param {string} email email of a contact
   * @param {string} phone phone number of a contact
   * @param {string} mobile mobile number of a contact
   *
   */
  static updateAll(id, attributes) {
    const updateUrl = URLS.UPDATE_ALL.replace(":id", id);

    const requestBody = {
      contact: attributes,
    };

    return this.patch(updateUrl, requestBody);
  }

  /**
   *
   * Fetch all emails of users configured as telecom contacts in ODOO
   *
   */
  static fetchTelecomUserEmails() {
    return this.get(URLS.TELECOM_USER_EMAILS);
  }
}

export default ContactApi;
