import React, { useState } from "react";
import { connect } from "react-redux";
import { Translate } from "react-i18nify";
import PropTypes from "prop-types";

import CellPhoneReactivateModal from "components/Modal/CellPhoneReactivateModal";

import {
  cellPhoneFetchClearErrors,
  reactivateCellPhoneRequest,
} from "redux/actions/cellPhone";

import HasPermission from "hoc/RbacCTA";

import { CELL_PHONE_ACTIONS } from "constants/rolePermission";

const ReactivateCellPhone = ({
  cellNumberID,
  clearError,
  isSuspended,
  reactivateCellPhone,
}) => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [modalData, setModalData] = useState();

  const resetModalState = () => {
    clearError();
    setModalVisibility(false);
    setModalData();
  };

  const handleCellPhoneReactivation = () => {
    reactivateCellPhone(cellNumberID);
    resetModalState();
  };

  return (
    <HasPermission
      perform="cellPhone:reactivate"
      data={{ action: CELL_PHONE_ACTIONS.REACTIVATE_CELL_PHONE }}
    >
      <div className="ps-2 cell-phone-suspended">
        <button
          className="btn btn-outline-primary"
          type="button"
          disabled={!isSuspended}
          onClick={() => setModalVisibility((prevState) => !prevState)}
        >
          <Translate value="common.reactivate" />
        </button>
        <CellPhoneReactivateModal
          modalVisible={isModalVisible}
          onConfirm={handleCellPhoneReactivation}
          onCancel={resetModalState}
          modalData={modalData}
        />
      </div>
    </HasPermission>
  );
};

ReactivateCellPhone.defaultProps = {};

ReactivateCellPhone.propTypes = {
  cellNumberID: PropTypes.number.isRequired,
  reactivateCellPhone: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  isSuspended: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ cellPhone: cellPhoneResource }) => {
  const { commitError, commitSuccess, isSubmitting } = cellPhoneResource;

  return {
    commitSuccess,
    error: commitError,
    isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearError: () => {
    dispatch(cellPhoneFetchClearErrors());
  },
  reactivateCellPhone: (id) => {
    dispatch(reactivateCellPhoneRequest(id));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReactivateCellPhone);
