import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.odooEndPoint()}/invoices/all`,
  SHOW: `${apiUrlConfig.odooEndPoint()}/invoices/:id`,
  UPDATE: `${apiUrlConfig.odooEndPoint()}/invoices/:id`,
  CREATE: `${apiUrlConfig.odooEndPoint()}/invoices`,
  DOWNLOAD: `${apiUrlConfig.odooEndPoint()}/invoices/:id/pdf_report`,
};

class InvoiceApi extends AuthenticatedRequest {
  // Fetch all invoices
  static fetchAll() {
    return this.get(URLS.INDEX);
  }

  /**
   * Fetch single invoice by its ID
   *
   * @param {int} id ID of a invoice to be fetched
   */
  static fetchOne(id) {
    const fetchOneUrl = URLS.SHOW.replace(":id", id);

    return this.get(fetchOneUrl);
  }

  /**
   * Create invoice in ODOO
   *
   * @param {object} attributes attributes of a setting
   * @param {number} invoice_payment_term_id payment term for invoice
   * @param {number} partner_id debtor id to whom invoice belongs to
   * @param {date} invoice_date_due due date of invoice
   * @param {date} invoice_date invoice date
   * @param {number} amount_untaxed untaxed amount
   * @param {number} amount_total total amount
   * @param {number} amount_tax tax amount
   * @param {boolean} state invoice state
   *
   * @param {object[]} deleted_products array of deleted invoice lines
   * @param {number} id invoice line id
   *
   * @param {object[]} invoice_lines array of invoice line
   * @param {number} id invoice line id
   * @param {number} product_id product id for a invoice line
   * @param {string} name product name
   * @param {string} display_name name of a product that is to be displayed in UI
   * @param {number} quantity quantity of a product
   * @param {number} price_unit price per unit of a product
   * @param {number} quantity quantity of a product
   * @param {number} price_subtotal subtotal price of a invoice line
   * @param {number} price_total total price of a invoice line
   * @param {number} account_id account id for a invoice line
   * @param {string} display_type display type for a invoice line, it can either be displayed as invoice line with prices and all or a note that will only include text box for writing notes
   * @param {array} product_uom_id uom id of a product; uom id means product unit like kg, second, etc.
   *
   */
  static create(attributes) {
    return this.post(URLS.CREATE, attributes);
  }

  /**
   * Update invoice in ODOO
   *
   * @param {object} attributes attributes of a setting
   * @param {number} invoice_payment_term_id payment term for invoice
   * @param {number} partner_id debtor id to whom invoice belongs to
   * @param {date} invoice_date_due due date of invoice
   * @param {date} invoice_date invoice date
   * @param {number} amount_untaxed untaxed amount
   * @param {number} amount_total total amount
   * @param {number} amount_tax tax amount
   * @param {boolean} state invoice state
   *
   * @param {object[]} deleted_products array of deleted invoice lines
   * @param {number} id invoice line id
   *
   * @param {object[]} invoice_lines array of invoice line
   * @param {number} id invoice line id
   * @param {number} product_id product id for a invoice line
   * @param {string} name product name
   * @param {string} display_name name of a product that is to be displayed in UI
   * @param {number} quantity quantity of a product
   * @param {number} price_unit price per unit of a product
   * @param {number} quantity quantity of a product
   * @param {number} price_subtotal subtotal price of a invoice line
   * @param {number} price_total total price of a invoice line
   * @param {number} account_id account id for a invoice line
   * @param {string} display_type display type for a invoice line, it can either be displayed as invoice line with prices and all or a note that will only include text box for writing notes
   * @param {array} product_uom_id uom id of a product; uom id means product unit like kg, second, etc.
   *
   */
  static update(id, attributes) {
    const updateUrl = URLS.UPDATE.replace(":id", id);

    return this.patch(updateUrl, attributes);
  }

  /**
   * Download single invoice by its ID
   *
   * @param {int} id ID of a invoice to be downloaded
   */
  static downloadOne(id) {
    const downloadUrl = URLS.DOWNLOAD.replace(":id", id);

    return this.get(downloadUrl, true);
  }
}

export default InvoiceApi;
