import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const ClearDateIcon = ({ value, disabled, onClick }) => {
  if (!value || disabled) return null;

  return (
    <FontAwesomeIcon
      onClick={onClick}
      icon={faTimesCircle}
      className="clear-date-icon position-absolute icon-circle"
      data-testid="clear-date-icon"
    />
  );
};

ClearDateIcon.defaultProps = {
  value: "",
  disabled: false,
};

ClearDateIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ClearDateIcon;
