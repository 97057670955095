import React from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  faUserClock,
  faUser,
  faPhone,
  faEnvelope,
  faGlobe,
  faMobile,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-i18nify";

import getNameInitials from "utils/user";

import { DEBTOR, VENDORS } from "constants/routes";

import DetailUIHeader from "components/Header/DetailUI/DetailUIHeader";
import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import TranslateDate from "components/i18n/TranslateDate";

const DebtorBasicDetail = ({ debtor, resourceName }) => {
  const {
    name,
    email,
    phone,
    mobile,
    customer_number: customerNumber,
    status,
    address,
    website,
    created_at: createdAt,
  } = debtor;

  const { vendorID } = useParams();

  const basicDetailContent = [
    {
      icon: faUser,
      title: <Translate value="attributes.customerNumber" />,
      content: customerNumber,
      dataCyFieldName: "customer-number",
    },
    {
      icon: faUserClock,
      title: <Translate value="attributes.customerSince" />,
      content: <TranslateDate date={createdAt} />,
      dataCyFieldName: "created-at",
    },
    {
      icon: faMapMarkerAlt,
      title: <Translate value="attributes.address" />,
      content: address,
      dataCyFieldName: "address",
    },
    {
      icon: faPhone,
      title: <Translate value="attributes.phone" />,
      content: phone,
      dataCyFieldName: "phone",
    },
    {
      icon: faMobile,
      title: <Translate value="attributes.mobile" />,
      content: mobile,
      dataCyFieldName: "mobile",
    },
    {
      icon: faEnvelope,
      title: <Translate value="attributes.email" />,
      content: email,
      dataCyFieldName: "email",
    },
    {
      icon: faGlobe,
      title: <Translate value="attributes.website" />,
      content: (
        <a href={website} target="_blank" rel="noopener noreferrer">
          {website}
        </a>
      ),
      dataCyFieldName: "website",
    },
  ];

  return (
    <div>
      <DetailUIHeader
        headerTitle={name}
        perform="debtors:edit"
        data={{
          debtorID: debtor.id,
        }}
        editPath={
          vendorID
            ? VENDORS.addId(VENDORS.EDIT, vendorID)
            : DEBTOR.addId(DEBTOR.EDIT, debtor.id)
        }
      />

      <div
        className="profile-detail br-all shadow-sm mt-3 mb-4"
        data-cy="profile-detail"
      >
        <div className="row w-100">
          <div className="col-md-4 avatar-container text-center">
            <div className="avatar-details">
              <div className="avatar">
                <div className="icon mx-auto mb-md-4 mb-2">
                  {getNameInitials(name)}
                  <div className="active-indicator" />
                </div>
                <div className="status d-none">{status}</div>
              </div>
              <div className="fw-bold customer-name mb-1">{name}</div>
              <div className="customer-number">{customerNumber}</div>
            </div>
          </div>

          <div className="col-md-7 basic-detail">
            <CardWithHeader
              title={<Translate value="common.basicDetails" />}
              cardContent={basicDetailContent}
              displayHeader={false}
              dataCy={resourceName}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

DebtorBasicDetail.defaultProps = {
  resourceName: "",
};

DebtorBasicDetail.propTypes = {
  debtor: PropTypes.shape({
    id: PropTypes.number,
    customer_number: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    phone: PropTypes.string,
    status: PropTypes.string,
    address: PropTypes.string,
    website: PropTypes.string,
    created_at: PropTypes.string,
  }).isRequired,
  resourceName: PropTypes.string,
};

export default DebtorBasicDetail;
