import React from "react";
import { Label, Input } from "reactstrap";
import PropTypes from "prop-types";

import InputLabel from "components/ReduxForm/InputLabel";

const ToggleButtonField = ({
  input: { name, onBlur, onChange, value, ...input },
  label,
  labelTranslation,
  disabled,
  hidden,
  meta: { touched, error, warning },
  className,
  replacementTranslations,
  dataTestId,
}) => {
  const defaultClassName = ["switch"];

  defaultClassName.push(className);

  return (
    <div data-testid={dataTestId}>
      <InputLabel
        name={name}
        label={label}
        labelTranslation={labelTranslation}
        replacementTranslations={replacementTranslations.label}
      />
      <Label className={defaultClassName.join(" ")}>
        <Input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...input}
          type="checkbox"
          id={name}
          value={value}
          disabled={disabled}
          hidden={hidden}
          onBlur={onBlur}
          onChange={onChange}
          checked={value}
          data-testid={name}
        />
        <span className="slider round" />
      </Label>

      {touched &&
        ((error && (
          <span className="invalid-text text-danger" data-testid="error-text">
            {error}
          </span>
        )) ||
          (warning && (
            <span
              className="invalid-text text-warning"
              data-testid="warning-text"
            >
              {warning}
            </span>
          )))}
    </div>
  );
};

ToggleButtonField.defaultProps = {
  className: "",
  label: undefined,
  disabled: false,
  hidden: false,
  labelTranslation: undefined,
  replacementTranslations: {
    label: {},
    placeholder: {},
  },
  dataTestId: "",
};

ToggleButtonField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.bool,
  }).isRequired,
  className: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  labelTranslation: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  replacementTranslations: PropTypes.shape({
    label: PropTypes.shape({}),
    placeholder: PropTypes.shape({}),
  }),
  dataTestId: PropTypes.string,
};

export default ToggleButtonField;
