import { createSearchAction } from "redux-search";

import { CreditNoteApi, DebtorApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

export const CREDIT_NOTE_FETCH_REQUEST = "CREDIT_NOTE_FETCH_REQUEST";
export const CREDIT_NOTE_FETCH_RECEIVED = "CREDIT_NOTE_FETCH_RECEIVED";
export const CREDIT_NOTE_FETCH_FAILED = "CREDIT_NOTE_FETCH_FAILED";
export const DEBTOR_CREDIT_NOTE_FETCH_REQUEST =
  "DEBTOR_CREDIT_NOTE_FETCH_REQUEST";
export const DEBTOR_CREDIT_NOTE_FETCH_RECEIVED =
  "DEBTOR_CREDIT_NOTE_FETCH_RECEIVED";
export const DEBTOR_CREDIT_NOTE_FETCH_FAILED =
  "DEBTOR_CREDIT_NOTE_FETCH_FAILED";
export const CREDIT_NOTE_SEARCH_REQUEST = "CREDIT_NOTE_SEARCH_REQUEST";
export const CREDIT_NOTE_FETCH_CLEAR_ERRORS = "CREDIT_NOTE_FETCH_CLEAR_ERRORS";

export const searchCreditNotes = createSearchAction("creditNotes");

export function creditNotesFetchReceived(response) {
  return {
    type: CREDIT_NOTE_FETCH_RECEIVED,
    creditNotes: response.data,
  };
}

export function creditNotesFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: CREDIT_NOTE_FETCH_FAILED,
    message,
  };
}

export const creditNotesFetchRequest = () => (dispatch) => {
  dispatch({ type: CREDIT_NOTE_FETCH_REQUEST });

  CreditNoteApi.fetchAll()
    .then((data) => dispatch(creditNotesFetchReceived(data)))
    .catch((e) => {
      dispatch(creditNotesFetchFailed(e));
    });
};

export const debtorCreditNotesFetchReceived = (response) => ({
  type: DEBTOR_CREDIT_NOTE_FETCH_RECEIVED,
  creditNotes: response.data,
});

export const debtorCreditNoteFetchFailed = (errorData) => {
  const message = getAndDisplayErrors(errorData);

  return {
    type: DEBTOR_CREDIT_NOTE_FETCH_FAILED,
    message,
  };
};

export const debtorCreditNotesFetchRequest = (debtorID) => (dispatch) => {
  dispatch({
    type: DEBTOR_CREDIT_NOTE_FETCH_REQUEST,
  });

  DebtorApi.creditNotes(debtorID)
    .then((data) => dispatch(debtorCreditNotesFetchReceived(data)))
    .catch((err) => {
      dispatch(debtorCreditNoteFetchFailed(err));
    });
};

export function creditNotesFetchClearErrors() {
  return {
    type: CREDIT_NOTE_FETCH_CLEAR_ERRORS,
  };
}

export function creditNoteSearchRequest(value) {
  const isSearching = Boolean(value);

  return {
    type: CREDIT_NOTE_SEARCH_REQUEST,
    isSearching,
  };
}
