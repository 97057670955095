import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.apiEndPoint()}/subscription_lines`,
  TERMINATION_DATE: `${apiUrlConfig.apiEndPoint()}/subscription_lines/:subscriptionLineId/termination_term_end_limit`,
};

class SubscriptionLineApi extends AuthenticatedRequest {
  /**
   * Fetch subscription_line termination date
   *
   */
  static fetchTerminationDateLimit(id) {
    const url = URLS.TERMINATION_DATE.replace(":subscriptionLineId", id);

    return this.get(url);
  }
}

export default SubscriptionLineApi;
