import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { Translate } from "react-i18nify";
import { Row, Col, Form } from "reactstrap";
import { Form as ReactFinalForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";

import { singleCellPhoneFetchRequest } from "redux/actions/cellPhone";
import { cellPhoneServicesFetchRequest } from "redux/actions/cellPhoneServices";

import { CELL_PHONE } from "constants/routes";

import WholePageSpinner from "components/Spinner/WholePageSpinner";
import ServiceSection from "components/NetworkOperatorSubscriptionServices/ServiceSection";

const NetworkServicesEdit = ({
  cellPhone,
  fetchSingleCellPhone,
  fetchCellPhoneServices,
  isCellPhoneServicesLoading,
  cellPhoneServices,
}) => {
  const navigate = useNavigate();
  const { cellPhoneID } = useParams();

  useEffect(() => {
    fetchSingleCellPhone(cellPhoneID);
    fetchCellPhoneServices();
  }, []);

  if (!cellPhone || isCellPhoneServicesLoading) {
    return <WholePageSpinner />;
  }

  const {
    id: cellPhoneId,
    cell_number: cellNumber,
    network_operator_subscription_services: networkOperatorSubscriptionServices,
  } = cellPhone;

  const filterSelectedServices = () => {
    if (networkOperatorSubscriptionServices.length === 0) {
      const defaultCellPhoneServices = cellPhoneServices.filter(
        ({ is_default: isDefaultService }) => isDefaultService
      );

      return defaultCellPhoneServices;
    }

    const initiallySelectedNetworkServices =
      networkOperatorSubscriptionServices.map(
        ({ cell_phone_service_id: serviceID }) => {
          const selectedCellPhoneService = cellPhoneServices.find(
            ({ id }) => id === serviceID
          );

          return selectedCellPhoneService;
        }
      );

    const unselectedNetworkServices = cellPhoneServices.filter(
      ({ id: currentServiceID }) =>
        !initiallySelectedNetworkServices.some(
          ({ id: selectedServicdID }) => selectedServicdID === currentServiceID
        )
    );

    return unselectedNetworkServices;
  };

  const handleCancelUpdate = () => {
    navigate(CELL_PHONE.addId(CELL_PHONE.SHOW, cellPhoneId), { replace: true });
  };

  return (
    <div className="container-fluid">
      <h3 className="mt-3">
        <Translate value="title.tab.cellPhone.networkServices" />({cellNumber})
      </h3>

      <ReactFinalForm
        onSubmit={() => {}}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          network_operator_subscription_services:
            networkOperatorSubscriptionServices,
        }}
        keepDirtyOnReinitialize
        render={({ handleSubmit, pristine }) => (
          <Form
            className="flexo-form px-4 py-4 pt-5 w-100"
            onSubmit={handleSubmit}
          >
            <div className="pbx-content">
              <Row>
                <Col md="12">
                  <FieldArray
                    name="network_operator_subscription_services"
                    component={ServiceSection}
                    formType="create"
                    cellPhoneServices={cellPhoneServices}
                    filterSelectedServices={filterSelectedServices()}
                    cellPhoneID={cellPhoneId}
                    pristine={pristine}
                    hideTitle
                  />
                </Col>
              </Row>
            </div>

            <div className="d-flex justify-content-between align-items-center btn-container my-3">
              <button
                className="btn btn-outline-secondary px-5"
                type="button"
                onClick={handleCancelUpdate}
              >
                <Translate value="common.cancel" />
              </button>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

const mapStateToProps = ({
  cellPhone: cellPhoneReducer,
  cellPhoneServices: cellPhoneServicesReducer,
}) => {
  const { cellPhone, commitError } = cellPhoneReducer;
  const { cellPhoneServices, isLoading: isCellPhoneServicesLoading } =
    cellPhoneServicesReducer;

  return {
    cellPhone,
    commitError,
    cellPhoneServices,
    isCellPhoneServicesLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleCellPhone: (id) => {
    dispatch(singleCellPhoneFetchRequest(id));
  },
  fetchCellPhoneServices: () => {
    dispatch(cellPhoneServicesFetchRequest());
  },
});

NetworkServicesEdit.defaultProps = {
  cellPhone: undefined,
  commitError: {},
};

NetworkServicesEdit.propTypes = {
  commitError: PropTypes.shape({}),
  fetchSingleCellPhone: PropTypes.func.isRequired,
  fetchCellPhoneServices: PropTypes.func.isRequired,
  cellPhone: PropTypes.shape({
    id: PropTypes.number,
    cell_number: PropTypes.string,
    network_operator_subscription_services: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        service_name: PropTypes.string,
        cell_phone_service_id: PropTypes.number,
        location: PropTypes.string,
        location_constraint: PropTypes.string,
      })
    ),
  }),
  isCellPhoneServicesLoading: PropTypes.bool.isRequired,
  cellPhoneServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      is_default: PropTypes.bool,
    })
  ).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NetworkServicesEdit);
