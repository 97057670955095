import React from "react";
import Skeleton from "react-loading-skeleton";

import DetailListSkeleton from "components/Skeleton/DetailListSkeleton";
import HeaderSkeleton from "components/Skeleton/HeaderSkeleton";
import SubscriptionsContentSkeleton from "components/Skeleton/ResourceDetail/AgreementDetail/SubscriptionsContentSkeleton";

const AgreementDetailSkeleton = () => (
  <div className="container-fluid">
    <HeaderSkeleton />
    <div className="bg-white rounded shadow-sm mb-4">
      <div className="row m-0 justify-content-between align-items-center">
        <div className="col-md-4">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Skeleton height={100} width={100} circle className="mb-4" />
            <Skeleton height={20} width={150} className="mb-1" />
            <Skeleton height={18} width={100} />
          </div>
          <div className="d-flex p-3 border-top mt-4">
            <div className="d-flex flex-column border-right w-50 align-items-center justify-content-center">
              <Skeleton height={18} width={60} />
              <Skeleton height={40} width={100} />
            </div>
            <div className="d-flex flex-column w-50 align-items-center justify-content-center">
              <Skeleton height={18} width={100} />
              <Skeleton height={40} width={70} />
            </div>
          </div>
        </div>
        <DetailListSkeleton className="h-100 border-left border-gray-100" />
      </div>
    </div>
    <SubscriptionsContentSkeleton />
  </div>
);

export default AgreementDetailSkeleton;
