import React from "react";
import { Translate } from "react-i18nify";
import { useNavigate } from "react-router-dom";
import { Field } from "react-final-form";
import { Col, FormGroup, Row } from "reactstrap";
import PropTypes from "prop-types";

import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";
import {
  composeValidators,
  pinCodeValidation,
  pukCodeValidation,
  required,
} from "utils/validation";
import PasswordField from "components/ReduxForm/PasswordField/PasswordField";

const ICCIDNumberForm = ({ submitting, pristine, isLoading }) => {
  const navigate = useNavigate();

  return (
    <div>
      <Row>
        <Col md="6">
          <FormGroup>
            <Field
              name="pin_1"
              component={PasswordField}
              validate={composeValidators(required, pinCodeValidation)}
              labelTranslation="attributes.pin1"
              placeholderTranslation="attributes.pin1"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Field
              name="pin_2"
              component={PasswordField}
              validate={composeValidators(required, pinCodeValidation)}
              labelTranslation="attributes.pin2"
              placeholderTranslation="attributes.pin2"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Field
              name="puk_1"
              component={PasswordField}
              validate={composeValidators(required, pukCodeValidation)}
              labelTranslation="attributes.puk1"
              placeholderTranslation="attributes.puk1"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Field
              name="puk_2"
              component={PasswordField}
              validate={composeValidators(required, pukCodeValidation)}
              labelTranslation="attributes.puk2"
              placeholderTranslation="attributes.puk2"
            />
          </FormGroup>
        </Col>
      </Row>

      <div className="flexo-form-footer d-flex flex-column flex-md-row justify-content-between">
        <button
          className="btn btn-outline-secondary mt-4 mb-md-3 px-md-3"
          type="button"
          onClick={() => navigate(-1)}
        >
          <Translate value="common.cancel" />
        </button>

        <ReduxFormCommitButton
          title={<Translate value="common.update" />}
          submitting={submitting}
          pristine={pristine}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

ICCIDNumberForm.defaultProps = {
  submitting: false,
  pristine: false,
  isLoading: false,
};

ICCIDNumberForm.propTypes = {
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default ICCIDNumberForm;
