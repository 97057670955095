import {
  NUMBER_PORTING_REQUEST_FETCH_REQUEST,
  NUMBER_PORTING_REQUEST_FETCH_RECEIVED,
  NUMBER_PORTING_REQUEST_FETCH_FAILED,
  NUMBER_PORTING_REQUEST_UPDATE_REQUEST,
  NUMBER_PORTING_REQUEST_UPDATE_RECEIVED,
  NUMBER_PORTING_REQUEST_UPDATE_FAILED,
  NUMBER_PORTING_REQUEST_CREATE_REQUEST,
  NUMBER_PORTING_REQUEST_CREATE_RECEIVED,
  NUMBER_PORTING_REQUEST_CREATE_FAILED,
  SINGLE_NUMBER_PORTING_REQUEST_FETCH_REQUEST,
  SINGLE_NUMBER_PORTING_REQUEST_FETCH_RECEIVED,
  SINGLE_NUMBER_PORTING_REQUEST_FETCH_FAILED,
  NUMBER_PORTING_REQUEST_FETCH_CLEAR_ERRORS,
  NUMBER_PORTING_REQUESTS_SEARCH_REQUEST,
  NUMBER_PORTING_REQUEST_IMPORT_STATUS_POLL_REQUEST,
  NUMBER_PORTING_REQUEST_IMPORT_STATUS_POLL_RECEIVED,
  NUMBER_PORTING_REQUEST_IMPORT_STATUS_POLL_FAILED,
  NUMBER_PORTING_REQUEST_CANCEL_RECEIVED,
  NUMBER_PORTING_REQUEST_CANCEL_FAILED,
  NUMBER_PORTING_REQUEST_CANCEL_REQUEST,
} from "redux/actions/numberPortingRequest";

const defaultState = {
  numberPortingRequests: [],
  fetchError: "",
  commitError: "",
  commitSuccess: false,
  isSearching: false,
  isLoading: false,
};

function numberPortingRequest(state = defaultState, action) {
  const {
    numberPortingRequests,
    numberPortingRequest: singleNumberPortingRequest,
  } = action;

  switch (action.type) {
    case NUMBER_PORTING_REQUEST_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        numberPortingRequest: undefined,
        commitSuccess: false,
      };

    case NUMBER_PORTING_REQUEST_FETCH_RECEIVED:
      return {
        ...state,
        numberPortingRequests,
        isLoading: false,
      };

    case NUMBER_PORTING_REQUEST_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case NUMBER_PORTING_REQUEST_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case NUMBER_PORTING_REQUEST_UPDATE_RECEIVED:
      return {
        ...state,
        numberPortingRequest: singleNumberPortingRequest,
        isLoading: false,
        commitSuccess: true,
      };

    case NUMBER_PORTING_REQUEST_UPDATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case NUMBER_PORTING_REQUEST_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case NUMBER_PORTING_REQUEST_CREATE_RECEIVED:
      return {
        ...state,
        numberPortingRequest: singleNumberPortingRequest,
        isLoading: false,
        commitSuccess: true,
      };

    case NUMBER_PORTING_REQUEST_CREATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case SINGLE_NUMBER_PORTING_REQUEST_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitSuccess: false,
      };

    case SINGLE_NUMBER_PORTING_REQUEST_FETCH_RECEIVED:
      return {
        ...state,
        numberPortingRequest: singleNumberPortingRequest,
        isLoading: false,
      };

    case SINGLE_NUMBER_PORTING_REQUEST_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case NUMBER_PORTING_REQUEST_FETCH_CLEAR_ERRORS:
      return {
        ...state,
        fetchError: "",
        isLoading: false,
        commitSuccess: false,
        commitError: "",
      };

    case NUMBER_PORTING_REQUESTS_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    case NUMBER_PORTING_REQUEST_IMPORT_STATUS_POLL_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitSuccess: false,
      };

    case NUMBER_PORTING_REQUEST_IMPORT_STATUS_POLL_RECEIVED:
      return {
        ...state,
        numberPortingRequest: singleNumberPortingRequest,
        isLoading: false,
      };

    case NUMBER_PORTING_REQUEST_IMPORT_STATUS_POLL_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case NUMBER_PORTING_REQUEST_CANCEL_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case NUMBER_PORTING_REQUEST_CANCEL_RECEIVED:
      return {
        ...state,
        numberPortingRequest: singleNumberPortingRequest,
        isLoading: false,
        commitSuccess: true,
      };

    case NUMBER_PORTING_REQUEST_CANCEL_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    default:
      return state;
  }
}

export default numberPortingRequest;
