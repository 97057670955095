import { createSearchAction } from "redux-search";
import { toastr } from "react-redux-toastr";
import { t } from "react-i18nify";

import { DidNumberApi } from "internal";

import {
  displayFetchErrorToaster,
  getServerError,
  getAndDisplayErrors,
} from "utils/utils";

import { ACTIVE_UNDER_PBX_FILTER } from "constants/filters";

export const DID_NUMBER_FETCH_REQUEST = "DID_NUMBER_FETCH_REQUEST";
export const DID_NUMBER_FETCH_RECEIVED = "DID_NUMBER_FETCH_RECEIVED";
export const DID_NUMBER_FETCH_FAILED = "DID_NUMBER_FETCH_FAILED";
export const SINGLE_DID_NUMBER_FETCH_REQUEST =
  "SINGLE_DID_NUMBER_FETCH_REQUEST";
export const SINGLE_DID_NUMBER_FETCH_RECEIVED =
  "SINGLE_DID_NUMBER_FETCH_RECEIVED";
export const SINGLE_DID_NUMBER_FETCH_FAILED = "SINGLE_DID_NUMBER_FETCH_FAILED";
export const SINGLE_DID_NUMBER_DELETE_REQUEST =
  "SINGLE_DID_NUMBER_DELETE_REQUEST";
export const SINGLE_DID_NUMBER_DELETE_RECEIVED =
  "SINGLE_DID_NUMBER_DELETE_RECEIVED";
export const SINGLE_DID_NUMBER_DELETE_FAILED =
  "SINGLE_DID_NUMBER_DELETE_FAILED";
export const DID_NUMBER_UPDATE_REQUEST = "DID_NUMBER_UPDATE_REQUEST";
export const DID_NUMBER_UPDATE_RECEIVED = "DID_NUMBER_UPDATE_RECEIVED";
export const DID_NUMBER_UPDATE_FAILED = "DID_NUMBER_UPDATE_FAILED";
export const DID_NUMBER_CREATE_REQUEST = "DID_NUMBER_CREATE_REQUEST";
export const DID_NUMBER_CREATE_RECEIVED = "DID_NUMBER_CREATE_RECEIVED";
export const DID_NUMBER_CREATE_FAILED = "DID_NUMBER_CREATE_FAILED";
export const DID_NUMBER_FETCH_CLEAR_ERRORS = "DID_NUMBER_FETCH_CLEAR_ERRORS";
export const DID_NUMBER_SEARCH_REQUEST = "DID_NUMBER_SEARCH_REQUEST";

export const searchDidNumber = createSearchAction("didNumbers");

export function didNumberFetchReceived(response) {
  return {
    type: DID_NUMBER_FETCH_RECEIVED,
    didNumbers: response.data,
  };
}

export function didNumberFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: DID_NUMBER_FETCH_FAILED,
    message,
  };
}

export function didNumberFetchClearErrors() {
  return {
    type: DID_NUMBER_FETCH_CLEAR_ERRORS,
  };
}

// Fetch all didNumbers
export const didNumberFetchRequest =
  (pbxID, filter = ACTIVE_UNDER_PBX_FILTER) =>
  (dispatch) => {
    dispatch({ type: DID_NUMBER_FETCH_REQUEST });

    DidNumberApi.fetchAll(pbxID, filter)
      .then((data) => dispatch(didNumberFetchReceived(data)))
      .catch((e) => {
        dispatch(didNumberFetchFailed(e));
      });
  };

// Fetch single didNumber
export function singleDidNumberFetchReceived(response) {
  return {
    type: SINGLE_DID_NUMBER_FETCH_RECEIVED,
    didNumber: response.data,
  };
}

export function singleDidNumberFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: SINGLE_DID_NUMBER_FETCH_FAILED,
    message,
  };
}

export const singleDidNumberFetchRequest = (id) => (dispatch) => {
  dispatch({ type: SINGLE_DID_NUMBER_FETCH_REQUEST });

  DidNumberApi.fetchOne(id)
    .then((data) => dispatch(singleDidNumberFetchReceived(data)))
    .catch((e) => {
      dispatch(singleDidNumberFetchFailed(e));
    });
};

// Update a didNumber
export function didNumberUpdateReceived(response, isAssignedToUserLicense) {
  return (dispatch) => {
    dispatch({
      type: DID_NUMBER_UPDATE_RECEIVED,
      didNumber: response.data,
    });

    if (isAssignedToUserLicense) {
      toastr.success(
        t("common.success"),
        t("toasterMessage.didNumber.assignedToUserLicense")
      );
    } else {
      const didNumberTranslation = t("title.resource.didNumber");

      toastr.success(
        t("common.success"),
        t("toasterMessage.resource.update", {
          resource: didNumberTranslation,
        })
      );
    }
  };
}

export function didNumberUpdateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: DID_NUMBER_UPDATE_FAILED,
    message: response.data,
  };
}

export const didNumberUpdateRequest =
  (id, attributes, isAssignedToUserLicense = false, callback) =>
  async (dispatch) => {
    dispatch({ type: DID_NUMBER_UPDATE_REQUEST });

    await DidNumberApi.update(id, attributes)
      .then((data) => {
        dispatch(didNumberUpdateReceived(data, isAssignedToUserLicense));
        callback();
      })
      .catch((errors) => {
        dispatch(didNumberUpdateFailed(errors, callback));
      });
  };

// Create a didNumber
export function didNumberCreateReceived(response) {
  return {
    type: DID_NUMBER_CREATE_RECEIVED,
    didNumber: response.data,
  };
}

export function didNumberCreateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: DID_NUMBER_CREATE_FAILED,
    message: response.data,
  };
}

export const didNumberCreateRequest =
  (attributes, callback) => async (dispatch) => {
    dispatch({ type: DID_NUMBER_CREATE_REQUEST });

    await DidNumberApi.create(attributes)
      .then((data) => {
        dispatch(didNumberCreateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(didNumberCreateFailed(errors, callback));
      });
  };

// delete single did number
export const singleDidNumberDeleteReceived = (response, cb) => (dispatch) => {
  dispatch({
    type: SINGLE_DID_NUMBER_DELETE_RECEIVED,
  });

  toastr.success(
    t("common.success"),
    t("toasterMessage.resource.delete", {
      resource: t("title.tab.pbx.didNumber"),
    })
  );

  if (typeof cb === "function") {
    cb();
  }
};

export const singleDidNumberDeleteFailed = (errorData) => {
  const { response } = errorData;
  const error = getServerError(response);
  const errorText =
    error === "not found" ? t("toasterMessage.processError") : error;

  displayFetchErrorToaster(errorText);

  return (dispatch) => {
    dispatch({
      type: SINGLE_DID_NUMBER_DELETE_FAILED,
      message: errorText,
    });
  };
};

export const singleDidNumberDeleteRequest = (id, cb) => (dispatch) => {
  dispatch({
    type: SINGLE_DID_NUMBER_DELETE_REQUEST,
  });

  DidNumberApi.deleteOne(id)
    .then((data) => dispatch(singleDidNumberDeleteReceived(data, cb)))
    .catch((e) => {
      dispatch(singleDidNumberDeleteFailed(e));
    });
};

export const didNumberSearchRequest = (value) => {
  const isSearching = Boolean(value);

  return {
    type: DID_NUMBER_SEARCH_REQUEST,
    isSearching,
  };
};
