import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Translate } from "react-i18nify";
import {
  faIdBadge,
  faUser,
  faCommentAlt,
} from "@fortawesome/free-solid-svg-icons";

import { USER_LICENSE } from "constants/routes";

import CardWithHeader from "components/Card/WithHeader/CardWithHeader";

const SipDeviceBasicDetail = ({ sipDevice }) => {
  const {
    user_license_id: userLicenseId,
    ex_description: description,
    ex_name: name,
    user_license_extension_number: userLicenseExtensionNumber,
  } = sipDevice;

  const basicDetailContent = [
    {
      icon: faUser,
      title: <Translate value="attributes.name" />,
      content: name,
    },
    userLicenseId && {
      icon: faIdBadge,
      title: <Translate value="attributes.userLicense" />,
      content: (
        <>
          {userLicenseExtensionNumber}
          <Link
            to={USER_LICENSE.addId(USER_LICENSE.SHOW, userLicenseId)}
            className="ml-1"
          >
            {"( "}
            <Translate value="common.viewDetails" />
            {" )"}
          </Link>
        </>
      ),
    },
    description && {
      icon: faCommentAlt,
      title: <Translate value="attributes.description" />,
      content: description,
    },
  ];

  return (
    <div>
      <CardWithHeader
        title={<Translate value="common.basicDetails" />}
        className="shadow-sm"
        cardContent={basicDetailContent.filter(
          (item) => item != null || item !== false
        )}
      />
    </div>
  );
};

SipDeviceBasicDetail.propTypes = {
  sipDevice: PropTypes.shape({
    id: PropTypes.number,
    extension_number: PropTypes.number,
    user_license_id: PropTypes.number,
    ex_description: PropTypes.string,
    ex_name: PropTypes.string,
    user_license_extension_number: PropTypes.number,
  }).isRequired,
};

export default SipDeviceBasicDetail;
