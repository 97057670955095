import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Translate } from "react-i18nify";
import {
  faUserEdit,
  faUserClock,
  faUserShield,
  faPhone,
  faTty,
  faEnvelope,
  faSignInAlt,
  faCalculator,
  faCalendarTimes,
  faBriefcase,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import getNameInitials from "utils/user";

import { singleUserFetchRequest, userDeleteRequest } from "redux/actions/user";

import { USER, PBX, DEBTOR } from "constants/routes";

import HasPermission from "hoc/RbacCTA";

import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import DetailUIHeader from "components/Header/DetailUI/DetailUIHeader";
import TranslateNumber from "components/i18n/TranslateNumber";
import TranslateDate from "components/i18n/TranslateDate";
import FetchError from "components/Error/FetchError";
import UserProfileSkeleton from "components/Skeleton/ResourceDetail/UserProfile/UserProfileSkeleton";
import DeleteModal from "views/User/Modal/DeleteModal";

const UserDetail = ({
  fetchSingleUser,
  user,
  fetchError,
  deleteUser,
  isLoading,
}) => {
  const { userID } = useParams();
  const navigate = useNavigate();

  const fetchUser = () => {
    fetchSingleUser(userID);
  };

  const [modalData, setModalData] = useState();
  const [modalVisible, setModalVisibility] = useState(false);

  const toggle = () => {
    setModalData(user);
    setModalVisibility(!modalVisible);
  };

  const resetModalState = () => {
    setModalVisibility(false);
    setModalData();
  };

  const onDeleteSuccess = () => {
    navigate(USER.INDEX, { replace: true });
  };

  const onConfirmDelete = (id) => {
    if (id) deleteUser(id, onDeleteSuccess);

    resetModalState();
  };

  useEffect(() => {
    fetchUser();
  }, [userID]);

  if (fetchError) {
    return <FetchError onRetry={fetchUser} />;
  }

  if (!user || isLoading) {
    return <UserProfileSkeleton />;
  }

  const {
    id,
    name,
    email,
    phone,
    sign_in_count: loginCount,
    last_sign_in_at: lastLoginAt,
    created_at: createdAt,
    role,
    pbx_id: pbxID,
    pbx_number: pbxNumber,
    is_active: isActive,
    odoo_debtor_id: odooDebtorID,
    debtor_name: debtorName,
  } = user;

  const basicDetailContent = [
    {
      icon: faUser,
      title: <Translate value="attributes.fullName" />,
      content: name,
      dataCyFieldName: "full-name",
    },
    odooDebtorID && {
      icon: faBriefcase,
      title: <Translate value="attributes.debtor" />,
      content: (
        <div>
          <span>{debtorName}</span>
          <Link
            to={`${DEBTOR.addId(DEBTOR.DETAIL, odooDebtorID)}`}
            className="ml-1"
          >
            {" ( "}
            <Translate value="common.viewDetails" />
            {" )"}
          </Link>
        </div>
      ),
      dataCyFieldName: "role",
    },
    {
      icon: faEnvelope,
      title: <Translate value="attributes.email" />,
      content: email,
      dataCyFieldName: "email",
    },
    {
      icon: faUserShield,
      title: <Translate value="attributes.role" />,
      content: role,
      dataCyFieldName: "role",
    },
    {
      icon: faPhone,
      title: <Translate value="attributes.phone" />,
      content: phone,
      dataCyFieldName: "phone",
    },
    {
      icon: faUserClock,
      title: <Translate value="attributes.customerSince" />,
      content: <TranslateDate date={createdAt} />,
      dataCyFieldName: "created-at",
    },
    {
      icon: faSignInAlt,
      title: <Translate value="attributes.lastLogin" />,
      content: <TranslateDate date={lastLoginAt} />,
    },

    {
      icon: faCalculator,
      title: <Translate value="attributes.loginCount" />,
      content: (
        <TranslateNumber
          value={loginCount}
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      ),
    },
  ];

  if (pbxNumber) {
    basicDetailContent.splice(4, 0, {
      icon: faTty,
      title: <Translate value="attributes.pbxNumber" />,
      perform: "users:pbxs_view",
      performData: { userId: id },
      content: (
        <Link data-cy="pbxNumber" to={PBX.addId(PBX.DETAIL, pbxID)}>
          {pbxNumber}
        </Link>
      ),
      fieldName: "pbxNumber",
    });
  }

  const additionalHeaderContent = !isActive && (
    <span className="is-deactivated ms-3 px-2 py-2">
      <FontAwesomeIcon icon={faCalendarTimes} className="me-1" />
      <Translate value="attributes.isDeactivated" />
    </span>
  );

  return (
    <HasPermission
      perform="users:view"
      data={{
        role,
        userId: id,
      }}
      redirect
    >
      <DeleteModal
        modalVisible={modalVisible}
        modalData={modalData}
        toggleModal={toggle}
        onConfirm={onConfirmDelete}
        onCancel={resetModalState}
      />
      <div className="container-fluid">
        <DetailUIHeader
          headerTitle={name}
          actionTitle={<Translate value="user.editProfile" />}
          perform="users:edit"
          deletePerform="users:delete"
          icon={faUserEdit}
          data={{ role, userId: id }}
          editPath={USER.addId(USER.EDIT, id)}
          readOnly={!isActive}
          additionalHeaderContent={additionalHeaderContent}
          handleDelete={toggle}
          hasDelete
        />
        <div className="row mt-3">
          <div className="col-lg-3 col-md-12 col-sm-12">
            <div
              className="avatar-container text-center pt-5 pb-3 pb-md-0"
              data-cy="avatar-container"
            >
              <div className="avatar-details">
                <div className="avatar">
                  <div
                    className="icon mx-auto mb-md-4 mb-2 bg-tertiary"
                    data-cy="profile-avatar"
                  >
                    {getNameInitials(name)}
                  </div>
                </div>
                <div className="fw-bold main-info mb-1">{name}</div>
              </div>
            </div>
          </div>

          <div className="col-lg-9 col-md-8 col-sm-12">
            <div className="user-detail-list p-0" data-cy="user-detail-list">
              <CardWithHeader
                title={<Translate value="users.basicDetails" />}
                cardContent={basicDetailContent}
                displayHeader={false}
                className="mb-0"
                dataCy="user"
              />
            </div>
          </div>
        </div>
      </div>
    </HasPermission>
  );
};

UserDetail.defaultProps = {
  user: undefined,
  fetchError: null,
};

UserDetail.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    created_at: PropTypes.string,
    sign_in_count: PropTypes.number,
    last_sign_in_at: PropTypes.string,
    pbx_id: PropTypes.number,
    pbx_number: PropTypes.string,
    role: PropTypes.string,
    is_active: PropTypes.bool,
    odoo_debtor_id: PropTypes.number,
    debtor_name: PropTypes.string,
  }),
  fetchSingleUser: PropTypes.func.isRequired,
  fetchError: PropTypes.string,
  deleteUser: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ user: userResource }) => {
  const { user, isLoading, fetchError } = userResource;

  return {
    user,
    isLoading,
    fetchError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleUser: (id) => {
    dispatch(singleUserFetchRequest(id));
  },
  deleteUser: (id, cb) => {
    dispatch(userDeleteRequest(id, cb));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
