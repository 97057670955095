import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Navigate, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Translate, t } from "react-i18nify";

import { didNumberCreateRequest } from "redux/actions/didNumber";

import { DID_NUMBER } from "constants/routes";

import DidNumberForm from "components/DidNumber/Form/DidNumberForm";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const DidNumberCreate = ({
  createDidNumber,
  commitSuccess,
  isLoading,
  didNumber,
}) => {
  const { pbxID } = useParams();

  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    await createDidNumber(values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess) {
    const { id } = didNumber;
    const didNumberTranslation = t("title.resource.didNumber");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.create", {
        resource: didNumberTranslation,
      })
    );

    return <Navigate to={`${DID_NUMBER.addId(DID_NUMBER.SHOW, id)}`} />;
  }

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.create"
            resource={t("title.resource.didNumber")}
          />
        </h3>
      </div>
      <DidNumberForm
        onSubmit={handleSubmit}
        formName="didNumberForm"
        formType="create"
        isLoading={isLoading}
        initialValues={{
          pbx_id: pbxID,
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { didNumber, isLoading, commitSuccess, commitError } = state.didNumber;

  return {
    didNumber,
    isLoading,
    commitSuccess,
    commitError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createDidNumber: async (attributes, callback) => {
    await dispatch(didNumberCreateRequest(attributes, callback));
  },
});

DidNumberCreate.defaultProps = {
  didNumber: undefined,
  commitError: {},
};

DidNumberCreate.propTypes = {
  didNumber: PropTypes.shape({
    id: PropTypes.number,
  }),
  isLoading: PropTypes.bool.isRequired,
  commitSuccess: PropTypes.bool.isRequired,
  createDidNumber: PropTypes.func.isRequired,
  commitError: PropTypes.shape({}),
};

export default connect(mapStateToProps, mapDispatchToProps)(DidNumberCreate);
