import React, { useState } from "react";
import Select from "react-select";
import { FormGroup, Row, Col, Spinner } from "reactstrap";
import { Field, useForm } from "react-final-form";
import PropTypes from "prop-types";
import { Translate, t } from "react-i18nify";
import { FieldArray } from "react-final-form-arrays";

import ContextPropertyApi from "apis/ContextProperty";

import {
  required,
  cannotBeNegative,
  composeValidators,
  requiredForNumber,
} from "utils/validation";
import { formatDropdownOptions, quotationStyle } from "utils/utils";

import {
  renderInputField,
  renderPriceInputField,
} from "components/ReduxForm/RenderField";
import TerminationAddressField from "components/Quotation/Form/SubscriptionLineProperty/TerminationAddressField";

import {
  getSubscriptionLineType,
  getSubscriptionType,
} from "utils/subscription";

const SubscriptionProductsField = ({
  meta,
  subscriptionProducts,
  onProductSelect,
  productFetchError,
}) => {
  const productFetchErrorField =
    productFetchError.length === 0 ? null : (
      <div className="text-danger">{productFetchError}</div>
    );

  return (
    <>
      <p className="fw-bold mb-1">
        <Translate value="attributes.product" />
      </p>
      <Select
        className="pl-0"
        options={formatDropdownOptions(subscriptionProducts)}
        onChange={onProductSelect}
        value={[
          {
            label: t("label.dropdown.chooseProduct"),
          },
        ]}
        placeholderTranslation="common.chooseSubscription"
        styles={quotationStyle()}
      />
      {meta.touched && meta.error && (
        <span className=" text-danger">{meta.error}</span>
      )}
      {productFetchErrorField}
    </>
  );
};

SubscriptionProductsField.defaultProps = {};

SubscriptionProductsField.propTypes = {
  meta: PropTypes.shape({ error: PropTypes.string, touched: PropTypes.bool })
    .isRequired,
  productFetchError: PropTypes.string.isRequired,
  subscriptionProducts: PropTypes.shape([]).isRequired,
  onProductSelect: PropTypes.func.isRequired,
};

const MainSubscriptionSection = ({
  fields,
  products,
  isProductFetching,
  subscriptionField,
}) => {
  const [fetchingProperties, setFetchingProperties] = useState(false);
  const [productFetchError, setProductFetchError] = useState("");

  const form = useForm();

  const mainSubscriptionContextTypeID = 1;

  const {
    input: {
      value: { subscription_type: subscriptionType },
    },
  } = subscriptionField;

  const handleProductSelect = ({ value }) => {
    const selectedProduct = products.find((product) => product.id === value);

    const {
      id,
      name,
      list_price: price,
      context_properties_ids: contextPropertiesIDs,
      context_type: contextType,
      context,
    } = selectedProduct;

    setFetchingProperties(true);

    ContextPropertyApi.fetchAll(id, contextPropertiesIDs)
      .then(({ data: contextProperties }) => {
        const subscriptionLineProperties = contextProperties.map(
          (contextProperty) => {
            const { property, value: propertyValue } = contextProperty;

            const subscriptionLineProperty = {
              property,
              value: propertyValue,
            };

            return subscriptionLineProperty;
          }
        );

        const lineType = getSubscriptionLineType(contextType);
        const currentSubscriptionType = getSubscriptionType(context);

        setFetchingProperties(false);

        form.change("agreement.temporary_subscription", {
          subscription_type: currentSubscriptionType,
          subscription_lines: [
            {
              odoo_product_id: id,
              product_name: name,
              price,
              line_type: lineType,
              subscription_line_properties: subscriptionLineProperties,
              context,
            },
          ],
        });
      })
      .catch((err) => {
        setFetchingProperties(false);
        setProductFetchError(err.message);
      });
  };

  if (isProductFetching && !products) {
    return (
      <div>
        <Translate
          value="common.fetchingResource"
          resource={t("attributes.product")}
        />
      </div>
    );
  }

  if (fetchingProperties) {
    return (
      <div className="empty-screen text-center py-5">
        <Spinner as="span" size="md" />
      </div>
    );
  }

  const subscriptionProducts = products.filter(
    (product) =>
      parseInt(product.context_type, 10) === mainSubscriptionContextTypeID
  );

  const mainSubscriptionLine = fields.value?.find(
    (line) => line.line_type === "subscription" && line.odoo_product_id
  );

  if (!mainSubscriptionLine) {
    return (
      <FormGroup>
        <Field
          className="p-2"
          validate={required}
          name="subscriptionProducts"
          component={SubscriptionProductsField}
          subscriptionProducts={subscriptionProducts}
          productFetchError={productFetchError}
          onProductSelect={handleProductSelect}
        />
      </FormGroup>
    );
  }

  const mainSubscriptionLineIndex = fields.value.findIndex(
    (line) => line.line_type === "subscription" && line.odoo_product_id
  );

  return (
    <>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Field
              name={`${fields.name}[${mainSubscriptionLineIndex}].product_name`}
              component={renderInputField}
              validate={required}
              placeholderTranslation="attributes.product"
              labelTranslation="attributes.product"
            />
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Field
              name={`${fields.name}[${mainSubscriptionLineIndex}].price`}
              component={renderPriceInputField}
              validate={composeValidators(requiredForNumber, cannotBeNegative)}
              placeholderTranslation="attributes.price"
              labelTranslation="attributes.price"
              type="number"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FieldArray
            name={`${fields.name}[${mainSubscriptionLineIndex}].subscription_line_properties`}
            component={TerminationAddressField}
            subscriptionType={subscriptionType}
          />
        </Col>
      </Row>
    </>
  );
};

MainSubscriptionSection.defaultProps = {};

MainSubscriptionSection.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func,
    push: PropTypes.func,
    pop: PropTypes.func,
    remove: PropTypes.func,
    length: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
  }).isRequired,
  isProductFetching: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    reset: PropTypes.func,
  }).isRequired,
  subscriptionField: PropTypes.shape({
    input: PropTypes.shape({
      value: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default MainSubscriptionSection;
