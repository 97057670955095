import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";
import { Translate } from "react-i18nify";

import {
  creditNoteFetchRequest,
  searchCreditNotes,
  creditNoteSearchRequest,
} from "redux/actions/agreement/creditNote";
import { singleAgreementFetchRequest } from "redux/actions/agreement";

import filterSearchResults from "utils/filterSearchResults";

import HasPermission from "hoc/RbacCTA";

import TranslateNumber from "components/i18n/TranslateNumber";
import TranslateDate from "components/i18n/TranslateDate";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import ResourceListSkeleton from "components/Skeleton/ResourceList/ResourceListSkeleton";

const renderAgreementCreditNodeTranslateDate = ({ cellData }) => (
  <TranslateDate date={cellData} />
);

const renderAgreementCreditNoteTranslateNumber = ({ cellData }) => (
  <TranslateNumber value={cellData} minimumFractionDigits={2} />
);

const AgreementCreditNoteIndex = ({
  creditNotes,
  isLoading,
  className,
  parentID,
  searchRecords,
  isSearching,
  fetchCreditNotes,
  fetchError,
  fetchSingleAgreement,
  isAgreementLoading,
  agreement,
}) => {
  const { agreementID } = useParams();

  useEffect(() => {
    fetchSingleAgreement(agreementID);
    fetchCreditNotes(agreementID);
  }, [agreementID]);

  if (isAgreementLoading || !agreement || isLoading || !creditNotes) {
    return <ResourceListSkeleton resourceName="invoices" />;
  }

  const title = (
    <>
      {`${agreement.ref_number} `}
      <Translate value="title.page.resource.list" resource="Credit Notes" />
    </>
  );

  return (
    <HasPermission
      data={{
        debtorID: agreement.odoo_debtor_id,
      }}
      perform="credit_notes:list"
      redirect
    >
      <RecordListingTable
        records={creditNotes}
        hideAddButton
        isLoading={isLoading}
        resourceName="credit_notes"
        resourceTitle={title}
        className={className}
        parentID={parentID}
        searchRecords={searchRecords}
        isSearching={isSearching}
        fetchError={fetchError}
        onRetry={fetchCreditNotes}
      >
        <Column
          label={<Translate value="attributes.number" />}
          dataKey="number"
          width={180}
        />
        <Column
          width={220}
          label={<Translate value="attributes.refundDate" />}
          dataKey="created_at"
          cellRenderer={renderAgreementCreditNodeTranslateDate}
        />
        <Column
          width={200}
          label={<Translate value="attributes.total" />}
          dataKey="amount_total_signed"
          cellRenderer={renderAgreementCreditNoteTranslateNumber}
        />
        <Column
          width={200}
          label={<Translate value="attributes.refundedMonths" />}
          dataKey="refund_months"
          cellRenderer={renderAgreementCreditNoteTranslateNumber}
        />
        <Column
          width={130}
          label={<Translate value="attributes.status" />}
          dataKey="state"
        />
      </RecordListingTable>
    </HasPermission>
  );
};

AgreementCreditNoteIndex.defaultProps = {
  isLoading: true,
  className: "",
  parentID: undefined,
  creditNotes: undefined,
  isSearching: false,
  fetchError: null,
  isAgreementLoading: false,
};

AgreementCreditNoteIndex.propTypes = {
  creditNotes: PropTypes.arrayOf(
    PropTypes.shape({
      agreement_ref_number: PropTypes.string,
      partner_id: PropTypes.arrayOf[PropTypes.any],
    })
  ),
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  parentID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fetchCreditNotes: PropTypes.func.isRequired,
  searchRecords: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  fetchError: PropTypes.string,
  agreement: PropTypes.shape({
    ref_number: PropTypes.string,
    odoo_debtor_id: PropTypes.number,
  }).isRequired,
  fetchSingleAgreement: PropTypes.func.isRequired,
  isAgreementLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { creditNotes, isLoading, isSearching, fetchError } =
    state.agreementBilling.creditNote;
  const results = filterSearchResults({
    resource: creditNotes,
    searchResult: state.search.agreementCreditNotes,
  });
  const {
    agreement,
    isLoading: isAgreementLoading,
    fetchError: fetchAgreeementError,
  } = state.agreement;

  return {
    creditNotes: results,
    isLoading,
    isSearching,
    fetchError,
    agreement,
    isAgreementLoading,
    fetchAgreeementError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchRecords: (e) => {
    dispatch(creditNoteSearchRequest(e));
    dispatch(searchCreditNotes(e));
  },
  fetchCreditNotes: (agreementID) => {
    dispatch(creditNoteFetchRequest(agreementID));
  },
  fetchSingleAgreement: (id) => {
    dispatch(singleAgreementFetchRequest(id, false, true));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgreementCreditNoteIndex);
