import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import { subscriptionUsageReportFetchRequest } from "redux/actions/cellPhoneReport/subscriptionUsage";

import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import SubscriptionNameList from "components/CellPhone/SubscriptionUsage/SubscriptionNameList/SubscriptionNameList";
import ConsumedUsage from "components/CellPhone/SubscriptionUsage/ConsumedUsage/ConsumedUsage";
import WholePageSpinner from "components/Spinner/WholePageSpinner";

const CellPhoneSubscriptionUsage = ({
  fetchSubscriptionUsageReport,
  subscriptionUsage,
}) => {
  const { cellPhoneNumber } = useParams();

  const fetchSubscriptionUsageReportData = () => {
    fetchSubscriptionUsageReport(cellPhoneNumber);
  };

  useEffect(() => {
    fetchSubscriptionUsageReportData();
  }, [cellPhoneNumber]);

  if (!subscriptionUsage) {
    return <WholePageSpinner />;
  }

  const {
    subscriptions: { subscription, add_ons: addOnSubscriptions },
    domestic: domesticUsages,
    eu_roaming: euUsages,
  } = subscriptionUsage;
  const allSubscriptions = [subscription, ...addOnSubscriptions];

  return (
    <div className="cell-phone-detail container-fluid w-100">
      <h3 className="mt-3">{cellPhoneNumber}</h3>

      <div className="detail-view-wrapper">
        <CardWithHeader
          title={<Translate value="attributes.subscriptions" />}
          className="shadow-sm"
          cardContent={
            <SubscriptionNameList subscriptions={allSubscriptions} />
          }
        />

        <CardWithHeader
          title={<Translate value="common.consumedNationalUsages" />}
          className="shadow-sm"
          cardContent={<ConsumedUsage usages={domesticUsages} />}
        />

        <CardWithHeader
          title={<Translate value="common.consumedEuUsages" />}
          className="shadow-sm"
          cardContent={<ConsumedUsage usages={euUsages} />}
        />
      </div>
    </div>
  );
};

CellPhoneSubscriptionUsage.defaultProps = {};

CellPhoneSubscriptionUsage.propTypes = {
  fetchSubscriptionUsageReport: PropTypes.func.isRequired,
  subscriptionUsage: PropTypes.shape({
    subscriptions: PropTypes.shape({
      subscription: PropTypes.string,
      add_ons: PropTypes.arrayOf(PropTypes.string),
    }),
    domestic: PropTypes.shape({
      usage_type: PropTypes.string,
      subscribed_usage: PropTypes.number,
      consumed_usage: PropTypes.number,
      consumed_percentage: PropTypes.number,
      usage_metric: PropTypes.string,
    }),
    eu_roaming: PropTypes.shape({
      usage_type: PropTypes.string,
      subscribed_usage: PropTypes.number,
      consumed_usage: PropTypes.number,
      consumed_percentage: PropTypes.number,
      usage_metric: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = ({ cellPhoneReport }) => ({
  ...cellPhoneReport.subscriptionUsage,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSubscriptionUsageReport: (cellNumber) => {
    dispatch(subscriptionUsageReportFetchRequest(cellNumber));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CellPhoneSubscriptionUsage);
