import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Admins, BusinessUsers } from "constants/userRoles";
import { PBX as PBX_ROUTES } from "constants/routes";
import { NEW, PBX, DETAILS, EDIT } from "constants/routes/routesResources";

import PbxIndex from "views/Pbx/PbxIndex";
import PbxCreate from "views/Pbx/PbxCreate";
import PbxDetail from "views/Pbx/PbxDetail";
import PbxEdit from "views/Pbx/PbxEdit";
import UserLicenseCreate from "views/UserLicense/UserLicenseCreate";
import DidNumberCreate from "views/DidNumber/DidNumberCreate";
import CellPhoneCreate from "views/Pbx/CellPhone/CellPhoneCreate";

const BusinessUserPbxIndex = BusinessUsers(PbxIndex);
const AdminPbxCreate = Admins(PbxCreate);
const AdminUserLicenseCreate = Admins(UserLicenseCreate);
const AdminCellPhoneCreate = Admins(CellPhoneCreate);
const AdminDidNumberCreate = Admins(DidNumberCreate);
const AdminPbxEdit = Admins(PbxEdit);

const PbxRoutes = () => (
  <Routes>
    <Route index element={<BusinessUserPbxIndex />} />
    <Route path={NEW} element={<AdminPbxCreate />} />
    <Route path={`${PBX.PBX_ID}/*`} element={<PbxDetail />}>
      <Route index element={<Navigate to={DETAILS} replace />} />
    </Route>

    <Route path={`${PBX.PBX_ID}/${EDIT}`} element={<AdminPbxEdit />} />
    <Route
      path={PBX_ROUTES.NEW_USER_LICENSE}
      element={<AdminUserLicenseCreate />}
    />
    <Route
      path={PBX_ROUTES.NEW_CELL_PHONE}
      element={<AdminCellPhoneCreate />}
    />
    <Route
      path={PBX_ROUTES.NEW_DID_NUMBER}
      element={<AdminDidNumberCreate />}
    />
  </Routes>
);

export default PbxRoutes;
