export default function getNameInitials(fullName = "No Name") {
  const nameArray = fullName.split(" ");

  const initials = nameArray.map((name) => name.charAt(0).toUpperCase());

  if (initials.length > 1) {
    return `${initials[0]}${initials[initials.length - 1]}`;
  }

  return initials[0];
}

const formatUserSetting = ({ value, dataType }) => {
  if (value === true) {
    return "true";
  }
  if (value === false) {
    return "false";
  }
  if (dataType === "time") {
    return new Date(`${value}`);
  }

  return value;
};

export { formatUserSetting };

export const formatRequestValues = (values) => {
  const formattedObj = {};

  Object.keys(values).forEach((key) => {
    let value = values[key];
    let newKey = key;

    if (typeof value === "object" && value !== null) {
      value = formatRequestValues(value);
      if (!newKey.endsWith("_attributes")) {
        newKey += "_attributes";
      }
    }

    formattedObj[newKey] = value;
  });

  return formattedObj;
};
