import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";
import { Translate, t } from "react-i18nify";
import { useLocation, useNavigate } from "react-router-dom";

import {
  didNumberFetchRequest,
  searchDidNumber,
  didNumberSearchRequest,
} from "redux/actions/didNumber";

import filterSearchResults from "utils/filterSearchResults";
import { getParams } from "utils/queryString";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import TranslateDate from "components/i18n/TranslateDate";
import ResourceHeader from "components/ResourceHeader/ResourceHeader";

import {
  PBX_DID_NUMBERS_FILTERS,
  ACTIVE_UNDER_PBX_FILTER,
} from "constants/filters";
import { RESOURCE_NAMES } from "constants/constant";

const renderDidNumberTranslateDate = ({ cellData }) => (
  <TranslateDate date={cellData} />
);

const DidNumberIndex = ({
  pbxActiveStatus,
  didNumberFetchRequest: fetchAllDidNumbers,
  parentID,
  didNumbers,
  isLoading,
  searchRecords,
  isSearching,
}) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const { filter = ACTIVE_UNDER_PBX_FILTER } = getParams(search);

  useEffect(() => {
    fetchAllDidNumbers(parentID, filter);
  }, [fetchAllDidNumbers, filter]);

  useEffect(() => {
    const searchParams = `?filter=${filter}`;

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath, { replace: true });
  }, [filter]);

  let records;

  if (didNumbers) {
    records = parentID
      ? didNumbers.filter(
          (didNumber) => didNumber.pbx_id === parseInt(parentID, 10)
        )
      : didNumbers;
  }

  const changeFilter = ({ filter: selectedFilter }) => {
    // clearFetchedPbxs();
    const searchParams = `?filter=${selectedFilter}`;
    const newPath = `${pathname}${searchParams}`;

    navigate(newPath, { replace: true });
  };

  const didNumberTranslation = t("common.didNumber");

  const allFilters = [
    {
      filter: PBX_DID_NUMBERS_FILTERS,
      selectedFilter: filter,
      type: "filter",
      headerTranslation: "common.filters",
    },
  ];

  const userLicenseColumn = filter === ACTIVE_UNDER_PBX_FILTER && (
    <Column
      width={180}
      label={<Translate value="attributes.userLicense" />}
      dataKey="user_license_extension_number"
    />
  );

  return (
    <RecordListingTable
      records={records}
      isLoading={isLoading}
      hideAddButton={!pbxActiveStatus}
      resourceName="did_numbers"
      resourceTitle={t("title.page.resource.list", {
        resource: didNumberTranslation,
      })}
      additionalHeaderContent={
        <ResourceHeader allFilters={allFilters} changeFilter={changeFilter} />
      }
      parentID={parentID}
      searchRecords={searchRecords}
      isSearching={isSearching}
      dataCy={`create-${RESOURCE_NAMES.PBX}-did_numbers`}
    >
      <Column
        width={180}
        label={<Translate value="attributes.phone" />}
        dataKey="phone_number"
      />
      <Column
        width={180}
        label={<Translate value="attributes.fullName" />}
        dataKey="full_name"
      />
      <Column
        width={200}
        label={<Translate value="attributes.invoiceStartDate" />}
        dataKey="invoice_start_date"
        cellRenderer={renderDidNumberTranslateDate}
      />
      <Column
        width={210}
        label={<Translate value="attributes.deploymentDate" />}
        dataKey="deployment_date"
        cellRenderer={renderDidNumberTranslateDate}
      />
      <Column
        width={210}
        label={<Translate value="attributes.faxServiceEnabled" />}
        dataKey="fax_service_enabled"
      />
      {userLicenseColumn}
    </RecordListingTable>
  );
};

DidNumberIndex.defaultProps = {
  pbxActiveStatus: true,
  isLoading: true,
  didNumbers: undefined,
  parentID: undefined,
  isSearching: false,
};

DidNumberIndex.propTypes = {
  pbxActiveStatus: PropTypes.bool,
  didNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      phone_number: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  didNumberFetchRequest: PropTypes.func.isRequired,
  parentID: PropTypes.string,
  searchRecords: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { isLoading, isSearching, didNumbers } = state.didNumber;

  const results = filterSearchResults({
    resource: didNumbers,
    searchResult: state.search.didNumbers,
  });

  return {
    didNumbers: results,
    isLoading,
    isSearching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchRecords: (e) => {
    dispatch(didNumberSearchRequest(e));
    dispatch(searchDidNumber(e));
  },
  didNumberFetchRequest: (pbxID, filter) => {
    dispatch(didNumberFetchRequest(pbxID, filter));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DidNumberIndex);
