import React from "react";
import PropTypes from "prop-types";
import { Field, useField } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Translate } from "react-i18nify";

import {
  required,
  cannotBeNegative,
  composeValidators,
  requiredForNumber,
} from "utils/validation";

import {
  renderPriceInputField,
  renderDateField,
} from "components/ReduxForm/RenderField";
import ProductNameWithLabel from "components/Quotation/Form/ConvertToAgreement/Modal/ProductNameWithLabel";
import SubscriptionLineProperties from "components/Quotation/Form/ConvertToAgreement/Modal/SubscriptionLineProperties";

const RenderSubscriptionLineProperties = ({
  subscriptionLineProperties,
  subscriptionLineIndex,
  subscriptionLinesFieldName,
}) => {
  if (subscriptionLineProperties.length !== 0) {
    return (
      <FieldArray
        name={`${subscriptionLinesFieldName}[${subscriptionLineIndex}].subscription_line_properties`}
        component={SubscriptionLineProperties}
      />
    );
  }

  return (
    <Translate value="message.quotation.convertToAgreement.noProperties" />
  );
};

const OtherProductsTableRow = ({
  subscriptionLineIndex,
  isSetup,
  subscriptionLinesFieldName,
}) => {
  const subscriptionLinePropertiesFieldsName = `${subscriptionLinesFieldName}[${subscriptionLineIndex}].subscription_line_properties`;

  const {
    input: { value: subscriptionLineProperties },
  } = useField(subscriptionLinePropertiesFieldsName);

  return (
    <tr
      className="other-products-table-row py-3"
      key={`${subscriptionLinesFieldName}[${subscriptionLineIndex}]`}
    >
      <td className="align-middle">
        <ProductNameWithLabel
          fieldName={`${subscriptionLinesFieldName}[${subscriptionLineIndex}].product_name`}
          isSetup={isSetup}
        />
      </td>
      <td className="align-middle">
        <div className="mx-auto d-flex flex-column gap-2">
          <RenderSubscriptionLineProperties
            subscriptionLineProperties={subscriptionLineProperties}
            subscriptionLineIndex={subscriptionLineIndex}
            subscriptionLinesFieldName={subscriptionLinesFieldName}
          />
        </div>
      </td>
      <td className="align-middle">
        <div className="w-75 mx-auto">
          <Field
            name={`${subscriptionLinesFieldName}[${subscriptionLineIndex}].price`}
            component={renderPriceInputField}
            placeholderTranslation="attributes.price"
            inputClassName="form-control text-center"
            validate={composeValidators(requiredForNumber, cannotBeNegative)}
          />
        </div>
      </td>
      <td className="align-middle">
        <div className="w-75 mx-auto">
          <Field
            name={`${subscriptionLinesFieldName}[${subscriptionLineIndex}].start_date`}
            component={renderDateField}
            placeholderTranslation="attributes.startDate"
            validate={required}
            inputClassName="form-control text-center"
            readOnly={false}
            datePickerClassNames="position-absolute top-0 start-50 translate-middle-x bg-light"
          />
        </div>
      </td>
    </tr>
  );
};

RenderSubscriptionLineProperties.defaultProps = {};

RenderSubscriptionLineProperties.propTypes = {
  subscriptionLineProperties: PropTypes.arrayOf(
    PropTypes.shape({
      property: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  subscriptionLineIndex: PropTypes.number.isRequired,
  subscriptionLinesFieldName: PropTypes.string.isRequired,
};

OtherProductsTableRow.defaultProps = {};

OtherProductsTableRow.propTypes = {
  subscriptionLineIndex: PropTypes.number.isRequired,
  isSetup: PropTypes.bool.isRequired,
  subscriptionLinesFieldName: PropTypes.string.isRequired,
};

export default OtherProductsTableRow;
