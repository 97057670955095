import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Navigate, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { t } from "react-i18nify";

import {
  singleAgreementFetchRequest,
  agreementUpdateRequest,
} from "redux/actions/agreement";
import { paymentTermFetchRequest } from "redux/actions/paymentTerm";
import { debtorFetchRequest } from "redux/actions/debtor";
import { productFetchRequest } from "redux/actions/product";

import { AGREEMENT } from "constants/routes";

import { formatRequestValues } from "utils/agreement";
import { processServerResponseForAsyncValidationErrors } from "utils/forms";

import AgreementForm from "components/Agreement/Form/AgreementForm";
import WholePageSpinner from "components/Spinner/WholePageSpinner";
import PageNotFound from "components/Error/PageNotFound/PageNotFound";

const AgreementEdit = ({
  fetchAgreement,
  updateAgreement,
  commitSuccess,
  agreement,
  isLoading,
  fetchDebtors,
  fetchPaymentTerms,
  fetchProducts,
  debtors,
  paymentTerms,
  products,
  isDebtorFetching,
  isPaymentTermFetching,
  isProductFetching,
}) => {
  const { agreementID } = useParams();

  useEffect(() => {
    fetchAgreement(agreementID);
    fetchDebtors();
    fetchPaymentTerms();
    fetchProducts();
  }, []);

  const handleSubmit = async (values, registeredFields) => {
    let serverResponse = {};

    const formattedValues = formatRequestValues(values);

    await updateAgreement(agreementID, formattedValues, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess) {
    const { id } = agreement;
    const agreementTranslation = t("attributes.agreement");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.update", {
        resource: agreementTranslation,
      })
    );

    return <Navigate to={AGREEMENT.addId(AGREEMENT.SHOW, id)} />;
  }

  if (!agreement || isLoading) {
    return <WholePageSpinner />;
  }

  const { ref_number: refNumber, is_archived: isArchived } = agreement;

  if (isArchived) {
    return (
      <div className="d-flex h-100 justify-content-center">
        <PageNotFound />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">{refNumber}</h3>
      </div>
      <AgreementForm
        onSubmit={handleSubmit}
        isLoading={isLoading}
        initialValues={agreement}
        formName="agreementForm"
        formType="edit"
        debtors={debtors}
        paymentTerms={paymentTerms}
        products={products}
        isDebtorFetching={isDebtorFetching}
        isPaymentTermFetching={isPaymentTermFetching}
        isProductFetching={isProductFetching}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { fetchError, agreement, isLoading, commitSuccess, commitError } =
    state.agreement;
  const { paymentTerms, isLoading: isPaymentTermFetching } = state.paymentTerm;
  const { debtors, isLoading: isDebtorFetching } = state.debtor;
  const { products, isLoading: isProductFetching } = state.product;

  return {
    fetchError,
    agreement,
    isLoading,
    commitError,
    commitSuccess,
    paymentTerms,
    isPaymentTermFetching,
    debtors,
    isDebtorFetching,
    products,
    isProductFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAgreement: (id) => {
    dispatch(singleAgreementFetchRequest(id, true));
  },
  updateAgreement: async (id, attributes, callback) => {
    await dispatch(agreementUpdateRequest(id, attributes, callback));
  },
  fetchPaymentTerms: () => {
    dispatch(paymentTermFetchRequest());
  },
  fetchDebtors: () => {
    dispatch(debtorFetchRequest());
  },
  fetchProducts: () => {
    dispatch(productFetchRequest());
  },
});

AgreementEdit.defaultProps = {
  agreement: undefined,
  isLoading: true,
  isDebtorFetching: true,
  isPaymentTermFetching: true,
  isProductFetching: true,
  commitError: {},
};

AgreementEdit.propTypes = {
  commitError: PropTypes.shape({}),
  commitSuccess: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  fetchAgreement: PropTypes.func.isRequired,
  updateAgreement: PropTypes.func.isRequired,
  fetchPaymentTerms: PropTypes.func.isRequired,
  fetchDebtors: PropTypes.func.isRequired,
  fetchProducts: PropTypes.func.isRequired,
  debtors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  paymentTerms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isDebtorFetching: PropTypes.bool,
  isPaymentTermFetching: PropTypes.bool,
  isProductFetching: PropTypes.bool,
  agreement: PropTypes.shape({
    id: PropTypes.number,
    ref_number: PropTypes.string,
    is_archived: PropTypes.bool,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(AgreementEdit);
