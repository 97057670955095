import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.odooEndPoint()}/products`,
};

class ProductApi extends AuthenticatedRequest {
  // Fetch all products
  static fetchAll() {
    return this.get(URLS.INDEX);
  }
}

export default ProductApi;
