import {
  NUMBER_PORTING_REQUEST_PHONE_NUMBER_UPDATE_REQUEST,
  NUMBER_PORTING_REQUEST_PHONE_NUMBER_UPDATE_RECEIVED,
  NUMBER_PORTING_REQUEST_PHONE_NUMBER_UPDATE_FAILED,
  NUMBER_PORTING_REQUEST_PHONE_NUMBER_CANCEL_REQUEST,
  NUMBER_PORTING_REQUEST_PHONE_NUMBER_CANCEL_RECEIVED,
  NUMBER_PORTING_REQUEST_PHONE_NUMBER_CANCEL_FAILED,
  NUMBER_PORTING_REQUEST_PHONE_NUMBER_RESET_STATES,
} from "redux/actions/numberPortingRequestPhoneNumber";

const defaultState = {
  commitError: "",
  commitSuccess: false,
  isLoading: false,
};

function numberPortingRequestPhoneNumber(state = defaultState, action) {
  const {
    numberPortingRequestPhoneNumber: singleNumberPortingRequestPhoneNumber,
  } = action;

  switch (action.type) {
    case NUMBER_PORTING_REQUEST_PHONE_NUMBER_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case NUMBER_PORTING_REQUEST_PHONE_NUMBER_UPDATE_RECEIVED:
      return {
        ...state,
        numberPortingRequestPhoneNumber: singleNumberPortingRequestPhoneNumber,
        isLoading: false,
        commitSuccess: true,
      };

    case NUMBER_PORTING_REQUEST_PHONE_NUMBER_UPDATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case NUMBER_PORTING_REQUEST_PHONE_NUMBER_CANCEL_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case NUMBER_PORTING_REQUEST_PHONE_NUMBER_CANCEL_RECEIVED:
      return {
        ...state,
        numberPortingRequestPhoneNumber: singleNumberPortingRequestPhoneNumber,
        isLoading: false,
        commitSuccess: true,
      };

    case NUMBER_PORTING_REQUEST_PHONE_NUMBER_CANCEL_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case NUMBER_PORTING_REQUEST_PHONE_NUMBER_RESET_STATES:
      return defaultState;

    default:
      return state;
  }
}

export default numberPortingRequestPhoneNumber;
