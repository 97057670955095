import { createSearchAction } from "redux-search";
import { toastr } from "react-redux-toastr";

import { t } from "react-i18nify";

import { SubscriptionProductApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

export const SUBSCRIPTION_PRODUCT_FETCH_REQUEST =
  "SUBSCRIPTION_PRODUCT_FETCH_REQUEST";
export const SUBSCRIPTION_PRODUCT_FETCH_RECEIVED =
  "SUBSCRIPTION_PRODUCT_FETCH_RECEIVED";
export const SUBSCRIPTION_PRODUCT_FETCH_FAILED =
  "SUBSCRIPTION_PRODUCT_FETCH_FAILED";
export const SUBSCRIPTION_PRODUCT_FETCH_CLEAR_ERRORS =
  "SUBSCRIPTION_PRODUCT_FETCH_CLEAR_ERRORS";
export const SUBSCRIPTION_PRODUCT_UPDATE_REQUEST =
  "SUBSCRIPTION_PRODUCT_UPDATE_REQUEST";
export const SUBSCRIPTION_PRODUCT_UPDATE_RECEIVED =
  "SUBSCRIPTION_PRODUCT_UPDATE_RECEIVED";
export const SUBSCRIPTION_PRODUCT_UPDATE_FAILED =
  "SUBSCRIPTION_PRODUCT_UPDATE_FAILED";
export const SUBSCRIPTION_PRODUCT_CREATE_REQUEST =
  "SUBSCRIPTION_PRODUCT_CREATE_REQUEST";
export const SUBSCRIPTION_PRODUCT_CREATE_RECEIVED =
  "SUBSCRIPTION_PRODUCT_CREATE_RECEIVED";
export const SUBSCRIPTION_PRODUCT_CREATE_FAILED =
  "SUBSCRIPTION_PRODUCT_CREATE_FAILED";
export const SINGLE_SUBSCRIPTION_PRODUCT_FETCH_REQUEST =
  "SINGLE_SUBSCRIPTION_PRODUCT_FETCH_REQUEST";
export const SINGLE_SUBSCRIPTION_PRODUCT_FETCH_RECEIVED =
  "SINGLE_SUBSCRIPTION_PRODUCT_FETCH_RECEIVED";
export const SINGLE_SUBSCRIPTION_PRODUCT_FETCH_FAILED =
  "SINGLE_SUBSCRIPTION_PRODUCT_FETCH_FAILED";
export const SINGLE_SUBSCRIPTION_PRODUCT_DELETE_REQUEST =
  "SINGLE_SUBSCRIPTION_PRODUCT_DELETE_REQUEST";
export const SINGLE_SUBSCRIPTION_PRODUCT_DELETE_RECEIVED =
  "SINGLE_SUBSCRIPTION_PRODUCT_DELETE_RECEIVED";
export const SINGLE_SUBSCRIPTION_PRODUCT_DELETE_FAILED =
  "SINGLE_SUBSCRIPTION_PRODUCT_DELETE_FAILED";
export const SEARCH_SUBSCRIPTION_PRODUCT_REQUEST =
  "SEARCH_SUBSCRIPTION_PRODUCT_REQUEST";

export const searchSubscriptionProducts = createSearchAction(
  "subscriptionProducts"
);

export const subscriptionProductsSearchRequest = (value) => {
  const isSearching = Boolean(value);

  return {
    type: SEARCH_SUBSCRIPTION_PRODUCT_REQUEST,
    isSearching,
  };
};

// Fetch all subscription products
export function subscriptionProductFetchReceived(response) {
  return {
    type: SUBSCRIPTION_PRODUCT_FETCH_RECEIVED,
    subscriptionProducts: response.data,
  };
}

export function subscriptionProductFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: SUBSCRIPTION_PRODUCT_FETCH_FAILED,
    message,
  };
}

export function subscriptionProductFetchClearErrors() {
  return {
    type: SUBSCRIPTION_PRODUCT_FETCH_CLEAR_ERRORS,
  };
}

export const subscriptionProductFetchRequest = () => (dispatch) => {
  dispatch({ type: SUBSCRIPTION_PRODUCT_FETCH_REQUEST });

  SubscriptionProductApi.fetchAll()
    .then((data) => dispatch(subscriptionProductFetchReceived(data)))
    .catch((errors) => {
      dispatch(subscriptionProductFetchFailed(errors));
    });
};

// Update single subscriptionProduct
export function subscriptionProductUpdateReceived(response) {
  const subscriptionProductTranslation = t(
    "title.tab.agreement.subscriptionModal.subscriptionProduct"
  );

  toastr.success(
    t("common.success"),
    t("toasterMessage.resource.update", {
      resource: subscriptionProductTranslation,
    })
  );

  return {
    type: SUBSCRIPTION_PRODUCT_UPDATE_RECEIVED,
    subscriptionProduct: response.data,
  };
}

export function subscriptionProductUpdateFailed(errorData, callback) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: SUBSCRIPTION_PRODUCT_UPDATE_FAILED,
    message: response.data,
  };
}

export const subscriptionProductUpdateRequest =
  (id, attributes, callback) => async (dispatch) => {
    dispatch({ type: SUBSCRIPTION_PRODUCT_UPDATE_REQUEST });

    await SubscriptionProductApi.update(id, attributes)
      .then((data) => {
        dispatch(subscriptionProductUpdateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(subscriptionProductUpdateFailed(errors, callback));
      });
  };

// Create new subscription product
export function subscriptionProductCreateReceived(response) {
  return {
    type: SUBSCRIPTION_PRODUCT_CREATE_RECEIVED,
    subscriptionProduct: response.data,
  };
}

export function subscriptionProductCreateFailed(
  errorData,
  callback = () => {}
) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: SUBSCRIPTION_PRODUCT_CREATE_FAILED,
    message: response.data,
  };
}

export const subscriptionProductCreateRequest =
  (attributes, callback) => async (dispatch) => {
    dispatch({ type: SUBSCRIPTION_PRODUCT_CREATE_REQUEST });

    await SubscriptionProductApi.create(attributes)
      .then((data) => {
        dispatch(subscriptionProductUpdateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(subscriptionProductCreateFailed(errors, callback));
      });
  };

// Fetch single subscription product
export function singleSubscriptionProductFetchReceived(response) {
  return {
    type: SINGLE_SUBSCRIPTION_PRODUCT_FETCH_RECEIVED,
    subscriptionProduct: response.data,
  };
}

export function singleSubscriptionProductFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: SINGLE_SUBSCRIPTION_PRODUCT_FETCH_FAILED,
    message,
  };
}

export const singleSubscriptionProductFetchRequest = (id) => (dispatch) => {
  dispatch({ type: SINGLE_SUBSCRIPTION_PRODUCT_FETCH_REQUEST });

  SubscriptionProductApi.fetchOne(id)
    .then((data) => dispatch(singleSubscriptionProductFetchReceived(data)))
    .catch((errors) => {
      dispatch(singleSubscriptionProductFetchFailed(errors));
    });
};

// Delete single subscription product
export function singleSubscriptionProductDeleteReceived(response, callback) {
  return (dispatch) => {
    dispatch({
      type: SINGLE_SUBSCRIPTION_PRODUCT_DELETE_RECEIVED,
      subscriptionProduct: response.data,
    });

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.delete", {
        resource: t(
          "title.tab.agreement.subscriptionModal.subscriptionProduct"
        ),
      })
    );

    if (typeof callback === "function") {
      callback();
    }
  };
}

export function singleSubscriptionProductDeleteFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: SINGLE_SUBSCRIPTION_PRODUCT_DELETE_FAILED,
    message,
  };
}

export const singleSubscriptionProductDeleteRequest =
  (id, callback) => (dispatch) => {
    dispatch({ type: SINGLE_SUBSCRIPTION_PRODUCT_DELETE_REQUEST });

    SubscriptionProductApi.deleteOne(id)
      .then((data) =>
        dispatch(singleSubscriptionProductDeleteReceived(data, callback))
      )
      .catch((errors) => {
        dispatch(singleSubscriptionProductDeleteFailed(errors));
      });
  };
