import React, { useEffect, useState } from "react";
import { Translate } from "react-i18nify";
import { Col, FormGroup, Input, Label } from "reactstrap";
import PropTypes from "prop-types";
import { Field } from "react-final-form";

import { SIM_CARD_HOLDER } from "constants/constant";
import { renderDropdownField } from "components/ReduxForm/RenderField";
import { required } from "utils/validation";
import { connect } from "react-redux";
import {
  customerListRequest,
  employeeListRequest,
  resellerListRequest,
} from "redux/actions/simCard";
import { formatDropdownOptions } from "utils/utils";

const SimCardType = ({
  simCardHolder,
  employeeList,
  isProcessing,
  isLoading,
  customerList,
}) => {
  const name =
    simCardHolder === SIM_CARD_HOLDER.EMPLOYEE ? "user_id" : "odoo_debtor_id";

  const attribute =
    simCardHolder === SIM_CARD_HOLDER.EMPLOYEE
      ? SIM_CARD_HOLDER.EMPLOYEE
      : SIM_CARD_HOLDER.CUSTOMER;

  const dropdownData =
    simCardHolder === SIM_CARD_HOLDER.EMPLOYEE ? employeeList : customerList;
  const loading =
    simCardHolder === SIM_CARD_HOLDER.EMPLOYEE ? isProcessing : isLoading;

  return (
    <Col md="12" className="py-0">
      <FormGroup>
        <Field
          name={name}
          component={renderDropdownField}
          validate={required}
          labelTranslation={`attributes.${attribute}`}
          placeholderTranslation={`attributes.${attribute}`}
          dropdownOptions={dropdownData}
          disabled={loading}
          isLoading={loading}
        />
      </FormGroup>
    </Col>
  );
};

const BasicSimCardForm = ({
  isReseller,
  setSimCardHolder,
  simCardHolder,
  fetchEmployeesDetail,
  employees,
  isProcessing,
  fetchCustomersDetail,
  fetchResellersDetail,
  isLoading,
  customers,
  resellers,
}) => {
  const [employeeList, setEmployeeList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [resellerList, setResellerList] = useState([]);

  useEffect(() => {
    fetchEmployeesDetail();
    fetchCustomersDetail();
    fetchResellersDetail();
  }, []);

  useEffect(() => {
    if (!employees.length) return;

    setEmployeeList(
      employees.map(({ id, first_name: firstName, last_name: lastName }) => ({
        value: id,
        label: `${firstName} ${lastName}`,
      }))
    );
  }, [employees]);

  useEffect(() => {
    if (!customers.length) return;

    setCustomerList(formatDropdownOptions(customers));
  }, [customers]);

  useEffect(() => {
    if (!resellers.length) return;

    setResellerList(formatDropdownOptions(resellers));
  }, [resellers]);

  const employeeClassName =
    simCardHolder === SIM_CARD_HOLDER.EMPLOYEE ? "active" : "";

  const customerClassName =
    simCardHolder === SIM_CARD_HOLDER.CUSTOMER || simCardHolder == null
      ? "active"
      : "";

  if (isReseller)
    return (
      <Col md="12" className="py-0">
        <FormGroup>
          <Field
            name="service_provider_id"
            component={renderDropdownField}
            validate={required}
            labelTranslation="attributes.reseller"
            placeholderTranslation="attributes.reseller"
            dropdownOptions={resellerList}
            isLoading={isProcessing}
          />
        </FormGroup>
      </Col>
    );

  return (
    <div>
      <p className="h4 fw-bold">
        <Translate value="common.simCardHolder" />
      </p>
      <div className="mb-3 d-flex flex-column flex-md-row">
        <FormGroup check className="ps-0 py-3 flex-fill">
          <button
            className={`sim-card-holder ${employeeClassName} py-2 px-2 d-flex  align-items-center w-100`}
            type="button"
            onClick={() => setSimCardHolder(SIM_CARD_HOLDER.EMPLOYEE)}
          >
            <Input
              type="checkbox"
              id="employee"
              onClick={() => setSimCardHolder(SIM_CARD_HOLDER.EMPLOYEE)}
              checked={simCardHolder === SIM_CARD_HOLDER.EMPLOYEE}
              className="ms-2"
            />
            <Label htmlFor="employee" className="fw-normal ms-2 mt-1">
              <Translate value="attributes.employee" />
            </Label>
          </button>
        </FormGroup>
        <FormGroup check className="py-md-3 ps-0 ps-md-3 flex-fill">
          <button
            className={`sim-card-holder ${customerClassName} py-2 px-2 d-flex align-items-center w-100`}
            type="button"
            onClick={() => setSimCardHolder(SIM_CARD_HOLDER.CUSTOMER)}
          >
            <Input
              type="checkbox"
              id="customer"
              onClick={() => setSimCardHolder(SIM_CARD_HOLDER.CUSTOMER)}
              checked={
                simCardHolder === SIM_CARD_HOLDER.CUSTOMER || !simCardHolder
              }
              className="ms-2"
            />
            <Label htmlFor="customer" className="fw-normal ms-2 mt-1">
              <Translate value="menu.customer" />
            </Label>
          </button>
        </FormGroup>
      </div>
      <SimCardType
        simCardHolder={simCardHolder}
        employeeList={employeeList}
        isProcessing={isProcessing}
        isLoading={isLoading}
        customerList={customerList}
      />
    </div>
  );
};

SimCardType.defaultProps = {
  simCardHolder: "",
  employeeList: [],
  isProcessing: false,
  isLoading: false,
  customerList: [],
};

SimCardType.propTypes = {
  simCardHolder: PropTypes.string,
  employeeList: PropTypes.arrayOf(PropTypes.shape({})),
  customerList: PropTypes.arrayOf(PropTypes.shape({})),
  isProcessing: PropTypes.bool,
  isLoading: PropTypes.bool,
};

BasicSimCardForm.defaultProps = {
  isReseller: false,
  setSimCardHolder: () => {},
  simCardHolder: "",
  isProcessing: false,
  isLoading: false,
};

BasicSimCardForm.propTypes = {
  isReseller: PropTypes.bool,
  setSimCardHolder: PropTypes.func,
  simCardHolder: PropTypes.string,
  fetchEmployeesDetail: PropTypes.func.isRequired,
  fetchCustomersDetail: PropTypes.func.isRequired,
  fetchResellersDetail: PropTypes.func.isRequired,
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    })
  ).isRequired,
  resellers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  isProcessing: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const mapStateToProps = ({ simCard }) => {
  const { employees, isProcessing, customers, isLoading, resellers } = simCard;

  return {
    employees,
    isProcessing,
    customers,
    isLoading,
    resellers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmployeesDetail: () => {
      dispatch(employeeListRequest());
    },
    fetchResellersDetail: () => {
      dispatch(resellerListRequest());
    },
    fetchCustomersDetail: () => {
      dispatch(customerListRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicSimCardForm);
