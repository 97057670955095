import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.odooEndPoint()}/invoices/all?type=bill`,
};

class BillsApi extends AuthenticatedRequest {
  // Fetch all bills
  static fetchAll() {
    return this.get(URLS.INDEX);
  }
}

export default BillsApi;
