import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toastr } from "react-redux-toastr";
import { t } from "react-i18nify";

import {
  resetPasswordRequest,
  resetPasswordClearErrors,
} from "redux/actions/auth";

import LoginPageWrapper from "components/Login/LoginPageWrapper";
import ResetPasswordForm from "components/Login/ResetPassword";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const ResetPassword = ({
  clearErrors,
  resetPassword,
  isLoading,
  resetPasswordError,
  resetPasswordSuccessMessage,
}) => {
  useEffect(() => {
    clearErrors();
  }, [clearErrors]);

  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    const { email } = values;

    await resetPassword(email, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (resetPasswordSuccessMessage) {
    toastr.success("Success", t(resetPasswordSuccessMessage));
    clearErrors();
  }

  return (
    <LoginPageWrapper>
      <ResetPasswordForm
        onSubmit={handleSubmit}
        resetPasswordSuccessMessage={resetPasswordSuccessMessage}
        resetPasswordError={resetPasswordError}
        isLoading={isLoading}
      />
    </LoginPageWrapper>
  );
};

ResetPassword.defaultProps = {};

ResetPassword.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  resetPassword: PropTypes.func.isRequired,
  resetPasswordError: PropTypes.string.isRequired,
  resetPasswordSuccessMessage: PropTypes.string.isRequired,
  clearErrors: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => {
  const { isLoading, resetPasswordError, resetPasswordSuccessMessage } = auth;

  return {
    resetPasswordSuccessMessage,
    resetPasswordError,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetPassword: async (email, callback) => {
    await dispatch(resetPasswordRequest(email, callback));
  },
  clearErrors: () => {
    dispatch(resetPasswordClearErrors());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
