import React from "react";
import PropTypes from "prop-types";
import { Translate, t } from "react-i18nify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Row,
  Col,
  ModalFooter,
} from "reactstrap";
import { Field, useField, useForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import { required } from "utils/validation";

import { renderDateField } from "components/ReduxForm/RenderField";
import SubscriptionLineGroup from "components/Agreement/Form/SubscriptionLine/SubscriptionLineGroup/SubscriptionLineGroup";

const SubscriptionAddModal = ({
  subscriptionProducts,
  agreementStartDate,
  onSubscriptionCreate,
  isModalVisible,
  onModalCancel,
  dataTestId,
}) => {
  const form = useForm();

  const {
    meta: { invalid },
  } = useField("temporary_subscription");

  const {
    input: { value: temporaryEndDate },
  } = useField("temporary_subscription.end_date");

  const {
    input: { value: subscriptionLines },
  } = useField("temporary_subscription.subscription_lines");

  const handleModalSubmission = () => {
    if (invalid) {
      form.submit();

      return;
    }

    onSubscriptionCreate();
  };

  const isAddButtonDisabled = !subscriptionLines || !subscriptionLines.length;

  return (
    <Modal
      data-testid={dataTestId}
      isOpen={isModalVisible}
      toggle={onModalCancel}
      className="subscription-modal modal-xl"
    >
      <ModalHeader toggle={onModalCancel} className="px-4">
        <Translate value="subscription.add" />
      </ModalHeader>
      <ModalBody className="subscription-modal-body px-4 p-3">
        <Row className="mt-2">
          <Col md="6">
            <FormGroup>
              <Field
                name="temporary_subscription.start_date"
                component={renderDateField}
                validate={required}
                labelTranslation="attributes.startDate"
                disabledDays={{
                  before: new Date(agreementStartDate),
                }}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Field
                name="temporary_subscription.end_date"
                component={renderDateField}
                labelTranslation="attributes.terminationDate"
                disabledDays={{
                  before: new Date(agreementStartDate),
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <div className="subscription-lines-section mt-3">
          <FieldArray
            name="temporary_subscription.subscription_lines"
            component={SubscriptionLineGroup}
            subscriptionProducts={subscriptionProducts}
            subscriptionStartDate={agreementStartDate}
            subscriptionEndDate={temporaryEndDate}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleModalSubmission}
          data-cy="temporary_subscription.add-btn"
          data-testid="temporary_subscription.add-btn"
          disabled={isAddButtonDisabled}
        >
          <Translate
            value="title.resource.button.addResource"
            resource={t(`common.subscription`)}
          />
        </button>
      </ModalFooter>
    </Modal>
  );
};

SubscriptionAddModal.defaultProps = {
  agreementStartDate: undefined,
  isModalVisible: false,
  dataTestId: "",
};

SubscriptionAddModal.propTypes = {
  subscriptionProducts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  agreementStartDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  onSubscriptionCreate: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool,
  onModalCancel: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
};

export default SubscriptionAddModal;
