import axios from "axios";

import { AuthApi } from "internal";

import { AUTH } from "constants/routes";

import {
  sessionExpiredRequest,
  updateBearerTokenReceived,
} from "redux/actions/auth";
import { store } from "redux/store";

import { getKeepMeLoggedIn } from "utils/auth";
import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";
import {
  displayNetworkErrorToaster,
  removeNetworkErrorToaster,
} from "utils/networkToaster";
import updateStatusReducer, { REDUCER_TYPE } from "utils/updateReducer";
import STATUS from "constants/statusCode";

/**
 * Interceptor to catch Unauthenticated responses.
 *
 * @param {*} err - Response error
 */

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, bearerToken = null) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(bearerToken);
    }
  });

  failedQueue = [];
};

const refreshBearerToken = async (err) => {
  const originalRequest = err.config;

  if (!originalRequest.retry) {
    const {
      auth: { refreshToken },
    } = store.getState();

    if (isRefreshing) {
      return new Promise(function retryWithToken(resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;

          return axios(originalRequest);
        })
        .catch((error) => Promise.reject(error));
    }

    originalRequest.retry = true;
    isRefreshing = true;

    try {
      const response = await AuthApi.refreshBearerToken(refreshToken);

      originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;

      store.dispatch(updateBearerTokenReceived(response.data));

      processQueue(null, response.data.access_token);

      return axios(originalRequest);
    } catch (_) {
      processQueue(err, null);

      return Promise.reject(err);
    } finally {
      isRefreshing = false;
    }
  }

  return null;
};

const errorHandlerInterceptor = (err) => {
  if (err.message === "Network Error") {
    removeNetworkErrorToaster();
    displayNetworkErrorToaster();
    throw err;
  } else if (
    err.response &&
    err.response.status === 401 &&
    window.location.pathname !== AUTH.LOGIN
  ) {
    const keepMeLoggedIn = getKeepMeLoggedIn();

    if (keepMeLoggedIn === "false" || !keepMeLoggedIn) {
      const translationItem = LocalStorageManager.get(
        CONSTANTS.FLEX_STORE
      )?.i18n;

      LocalStorageManager.clear();
      LocalStorageManager.set(
        CONSTANTS.FLEX_STORE,
        JSON.stringify({ i18n: translationItem })
      );

      store.dispatch(sessionExpiredRequest());
    } else {
      return refreshBearerToken(err);
    }
  } else if (err.response && err.response.status === STATUS.FORBIDDEN) {
    const {
      location: { pathname },
    } = window;

    const reducerType = pathname.includes(AUTH.LOGIN)
      ? REDUCER_TYPE.AUTH
      : null;

    if (!reducerType) return null;

    store.dispatch(updateStatusReducer(err.response.status, reducerType));
  } else {
    throw err;
  }

  return null;
};

export default errorHandlerInterceptor;
