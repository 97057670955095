import { createSearchAction } from "redux-search";

import { CellPhoneApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

import { ACTIVE_UNDER_PBX_FILTER } from "constants/filters";

export const PBX_CELL_PHONE_FETCH_REQUEST = "PBX_CELL_PHONE_FETCH_REQUEST";
export const PBX_CELL_PHONE_FETCH_RECEIVED = "PBX_CELL_PHONE_FETCH_RECEIVED";
export const PBX_CELL_PHONE_FETCH_FAILED = "PBX_CELL_PHONE_FETCH_FAILED";
export const PBX_CELL_PHONE_SEARCH_REQUEST = "PBX_CELL_PHONE_SEARCH_REQUEST";

export function cellPhoneFetchReceived(response) {
  return {
    type: PBX_CELL_PHONE_FETCH_RECEIVED,
    cellPhones: response.data,
  };
}

export function cellPhoneFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: PBX_CELL_PHONE_FETCH_FAILED,
    message,
  };
}

// Fetch all cellPhones
export const cellPhoneFetchRequest =
  (pbxID, filter = ACTIVE_UNDER_PBX_FILTER) =>
  (dispatch) => {
    dispatch({ type: PBX_CELL_PHONE_FETCH_REQUEST });

    CellPhoneApi.fetchAll(pbxID, filter, "pbx")
      .then((data) => dispatch(cellPhoneFetchReceived(data)))
      .catch((e) => {
        dispatch(cellPhoneFetchFailed(e));
      });
  };

// Search Cell Phone
export const searchCellPhones = createSearchAction("pbxCellPhones");

export const cellPhoneSearchRequest = (value) => {
  const isSearching = Boolean(value);

  return {
    type: PBX_CELL_PHONE_SEARCH_REQUEST,
    isSearching,
  };
};
