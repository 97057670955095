import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import {
  faFileInvoice,
  faClock,
  faMoneyBill,
  faCalendar,
  faCalendarCheck,
  faCalendarDay,
  faFileContract,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";

import { Translate } from "react-i18nify";

import getNameInitials from "utils/user";
import { capitalizeWords } from "utils/string";
import { getPaymentTermInDays } from "utils/invoice";

import { DEBTOR } from "constants/routes";

import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import TranslateNumber from "components/i18n/TranslateNumber";
import TranslateDate from "components/i18n/TranslateDate";

const AgreementInformation = ({ agreement, paymentTerms }) => {
  const {
    start_date: startDate,
    end_date: endDate,
    odoo_debtor_id: debtorID,
    odoo_debtor_name: debtorName,
    odoo_payment_term_id: paymentTermID,
    invoice_period: invoicePeriod,
    invoiced_until: invoicedUntil,
    total_amount: totalAmount,
    last_invoice_date: lastInvoiceDate,
    next_invoice_date: nextInvoiceDate,
    active_subscriptions_count: activeSubscriptionsCount,
    archived_subscriptions_count: archivedSubscriptionsCount,
    termination_term: terminationTerm,
  } = agreement;

  const paymentTerm = paymentTerms.find(
    (odooPaymentTerm) => odooPaymentTerm.id === paymentTermID
  );

  const { name: paymentTermName } = paymentTerm;

  const nextInvoiceDateWithTerm = new Date(nextInvoiceDate);

  if (nextInvoiceDate) {
    const paymentTermDays = getPaymentTermInDays(paymentTermName);

    nextInvoiceDateWithTerm.setDate(
      nextInvoiceDateWithTerm.getDate() - paymentTermDays - 1
    );
  }

  const endDateContent = endDate && {
    icon: faCalendarDay,
    title: <Translate value="attributes.terminationDate" />,
    content: <TranslateDate date={endDate} />,
    dataCyFieldName: "end-date",
  };

  const basicDetails = [
    {
      icon: faFileInvoice,
      title: <Translate value="attributes.invoicePeriod" />,
      content: invoicePeriod && capitalizeWords(invoicePeriod),
      dataCyFieldName: "invoice-period",
    },
    {
      icon: faClock,
      title: <Translate value="attributes.startDate" />,
      content: <TranslateDate date={startDate} />,
      dataCyFieldName: "start-date",
    },
    {
      icon: faMoneyBill,
      title: <Translate value="attributes.totalAmount" />,
      content: (
        <TranslateNumber value={totalAmount} minimumFractionDigits={2} />
      ),
      dataCyFieldName: "total-amount",
    },
    {
      icon: faFileContract,
      title: <Translate value="attributes.terminationTerm" />,
      content: terminationTerm && capitalizeWords(terminationTerm),
      dataCyFieldName: "termination-term",
    },
    {
      icon: faCalendar,
      title: <Translate value="attributes.invoicedUntil" />,
      content: <TranslateDate date={invoicedUntil} />,
      dataCyFieldName: "invoice-until",
    },
    {
      icon: faCalendarCheck,
      title: <Translate value="attributes.lastInvoiceDate" />,
      content: <TranslateDate date={lastInvoiceDate} />,
      dataCyFieldName: "last-invoice-date",
    },
    {
      ...endDateContent,
    },
    {
      icon: faCalendarDay,
      title: <Translate value="agreement.nextInvoiceDate" />,
      content: (
        <TranslateDate date={nextInvoiceDate && nextInvoiceDateWithTerm} />
      ),
      dataCyFieldName: "next-invoice-date",
    },
    {
      icon: faReceipt,
      title: <Translate value="menuItem.invoice" />,
      content: (
        <Link
          data-cy="view-invoices-button"
          to="invoices"
          className="btn-outline-primary btn"
        >
          <Translate value="common.viewInvoices" />
        </Link>
      ),
      dataCyFieldName: "view-invoices",
    },
  ];

  return (
    <Row>
      <Col lg={4} md="12" sm={12} className="pr-0">
        <div
          className="agreement-information text-center h-100"
          data-cy="agreement-information"
        >
          <div className="avatar-details mt-3">
            <div className="avatar">
              <div className="icon mx-auto mb-md-4 mb-2">
                {getNameInitials(debtorName)}
              </div>
            </div>
            <div className="customer-name mb-1">
              <span
                className="fw-bold "
                data-field-name="agreement-debtor-name"
              >
                {debtorName}
              </span>
              <Link
                to={`${DEBTOR.addId(DEBTOR.DETAIL, debtorID)}`}
                className="ml-1"
              >
                {"( "}
                <Translate value="common.viewDetails" />
                {" )"}
              </Link>
            </div>
            <div
              className="customer-number"
              data-field-name="agreement-payment-term-name"
            >
              {paymentTermName}
            </div>
          </div>
          <div className="subscription-status py-4">
            <div>
              <h3
                className="mb-1"
                data-field-name="agreement-active-subscriptions-count"
              >
                <TranslateNumber
                  value={activeSubscriptionsCount}
                  minimumFractionDigits={0}
                />
              </h3>
              <small className="text-secondary">
                <Translate value="title.tab.agreement.subscription.active" />
              </small>
            </div>
            <div className="center-border h-100" />
            <div>
              <h3
                className="mb-1"
                data-field-name="agreement-archived-subscriptions-count"
              >
                <TranslateNumber
                  value={archivedSubscriptionsCount}
                  minimumFractionDigits={0}
                />
              </h3>
              <small className="text-secondary">
                <Translate value="title.tab.agreement.subscription.archived" />
              </small>
            </div>
          </div>
        </div>
      </Col>

      <Col lg={8} md="12" sm={12} className="basic-detail">
        <CardWithHeader
          title={<Translate value="common.basicDetails" />}
          cardContent={basicDetails}
          displayHeader={false}
          dataCy="agreement"
        />
      </Col>
    </Row>
  );
};

AgreementInformation.defaultProps = {};

AgreementInformation.propTypes = {
  agreement: PropTypes.shape({
    id: PropTypes.number,
    start_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    end_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    odoo_debtor_id: PropTypes.number,
    odoo_debtor_name: PropTypes.string,
    odoo_payment_term_id: PropTypes.number,
    invoice_period: PropTypes.string,
    invoiced_until: PropTypes.instanceOf(Date),
    total_amount: PropTypes.number,
    last_invoice_date: PropTypes.instanceOf(Date),
    next_invoice_date: PropTypes.instanceOf(Date),
    active_subscriptions_count: PropTypes.number,
    archived_subscriptions_count: PropTypes.number,
    termination_term: PropTypes.string,
  }).isRequired,
  paymentTerms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default AgreementInformation;
