import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { singleUserLicenseFetchRequest } from "redux/actions/userLicense";
import { cellPhoneServicesFetchRequest } from "redux/actions/cellPhoneServices";

import WholePageSpinner from "components/Spinner/WholePageSpinner";
import CellPhoneFormContent from "components/CellPhone/Form/CellPhoneFormContent";

const CellPhoneCreate = ({ fetchUserLicense, userLicense }) => {
  const { userLicenseID } = useParams();

  useEffect(() => {
    fetchUserLicense(userLicenseID);
  }, []);

  const isResourceValidOrLoading =
    !userLicense ||
    (userLicense && userLicense.id !== parseInt(userLicenseID, 10));

  if (isResourceValidOrLoading) {
    return <WholePageSpinner />;
  }

  const {
    agreement_id: agreementID,
    user: { first_name: firstName, last_name: lastName, email },
    pbx_id: pbxID,
  } = userLicense;

  const initialValues = {
    pbx_id: pbxID,
    user_license_id: userLicenseID,
    ex_name: `${firstName} ${lastName}`,
    ex_recording: "no",
    ex_emailrecording: email,
    ex_email: email,
    ex_dialtimeout: 30,
    voicemail_box_attributes: {
      email,
    },
    subscription_attributes: {
      agreement_id: agreementID,
      subscription_type: "cell_phone",
    },
  };

  return (
    <CellPhoneFormContent
      initialValues={initialValues}
      parentType="userLicense"
      email={email}
    />
  );
};

const mapStateToProps = ({ userLicense: userLicenseReducer }) => {
  const { userLicense } = userLicenseReducer;

  return {
    userLicense,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUserLicense: (id) => {
    dispatch(singleUserLicenseFetchRequest(id));
  },
  fetchCellPhoneServices: () => dispatch(cellPhoneServicesFetchRequest()),
});

CellPhoneCreate.defaultProps = {};

CellPhoneCreate.propTypes = {
  fetchUserLicense: PropTypes.func.isRequired,
  userLicense: PropTypes.shape().isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CellPhoneCreate);
