import { createSearchAction } from "redux-search";
import { toastr } from "react-redux-toastr";
import { t } from "react-i18nify";

import { UserApi } from "internal";

import {
  displayFetchErrorToaster,
  getServerError,
  getAndDisplayErrors,
} from "utils/utils";

import { USER_ROLE_TYPES, USER_STATUSES } from "constants/constant";

export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_RECEIVED = "USER_CREATE_RECEIVED";
export const USER_CREATE_FAILED = "USER_CREATE_FAILED";
export const USER_FETCH_REQUEST = "USER_FETCH_REQUEST";
export const USER_FETCH_RECEIVED = "USER_FETCH_RECEIVED";
export const USER_FETCH_FAILED = "USER_FETCH_FAILED";
export const SINGLE_USER_FETCH_REQUEST = "SINGLE_USER_FETCH_REQUEST";
export const SINGLE_USER_FETCH_RECEIVED = "SINGLE_USER_FETCH_RECEIVED";
export const SINGLE_USER_FETCH_FAILED = "SINGLE_USER_FETCH_FAILED";
export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_RECEIVED = "USER_UPDATE_RECEIVED";
export const USER_UPDATE_FAILED = "USER_UPDATE_FAILED";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_RECEIVED = "USER_DELETE_RECEIVED";
export const USER_DELETE_FAILED = "USER_DELETE_FAILED";

export const SWITCH_USER_LANGUAGE_REQUEST = "SWITCH_USER_LANGUAGE_REQUEST";
export const SWITCH_USER_LANGUAGE_RECEIVED = "SWITCH_USER_LANGUAGE_RECEIVED";
export const SWITCH_USER_LANGUAGE_FAILED = "SWITCH_USER_LANGUAGE_FAILED";

export const USER_FETCH_CLEAR_ERRORS = "USER_FETCH_CLEAR_ERRORS";
export const USER_SEARCH_REQUEST = "USER_SEARCH_REQUEST";

export const searchUsers = createSearchAction("users");

// Fetch all users
export function userFetchReceived(response) {
  return {
    type: USER_FETCH_RECEIVED,
    users: response.data,
  };
}

export function userFetchFailed(errorData) {
  const { response } = errorData;
  const error = getServerError(response);

  const errorText =
    error === "not found"
      ? t("common.message.userFetchError")
      : t("common.message.requestFail");

  displayFetchErrorToaster(errorText);

  return {
    type: USER_FETCH_FAILED,
    message: errorText,
  };
}

export const userFetchRequest =
  (status = USER_STATUSES.ACTIVE, roleType = USER_ROLE_TYPES.COMPANY_USERS) =>
  (dispatch) => {
    dispatch({ type: USER_FETCH_REQUEST });

    UserApi.fetchAll(status, roleType)
      .then((data) => dispatch(userFetchReceived(data)))
      .catch((e) => {
        dispatch(userFetchFailed(e));
      });
  };

// Fetch single user
export function singleUserFetchReceived(response) {
  return {
    type: SINGLE_USER_FETCH_RECEIVED,
    user: response.data,
  };
}

export function singleUserFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: SINGLE_USER_FETCH_FAILED,
    message,
  };
}

export const singleUserFetchRequest = (id) => (dispatch) => {
  dispatch({ type: SINGLE_USER_FETCH_REQUEST });

  UserApi.fetchOne(id)
    .then((data) => dispatch(singleUserFetchReceived(data)))
    .catch((e) => {
      dispatch(singleUserFetchFailed(e));
    });
};

// Create user
export function userCreateReceived(response) {
  return {
    type: USER_CREATE_RECEIVED,
    user: response.data,
  };
}

export function userCreateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: USER_CREATE_FAILED,
    message: response.data,
  };
}

export const userCreateRequest = (values, callback) => async (dispatch) => {
  dispatch({ type: USER_CREATE_REQUEST });

  await UserApi.create(values)
    .then((data) => {
      dispatch(userCreateReceived(data));
      callback();
    })
    .catch((errors) => {
      dispatch(userCreateFailed(errors, callback));
    });
};

// Update a user
export function userUpdateReceived(response) {
  return {
    type: USER_UPDATE_RECEIVED,
    user: response.data,
  };
}

export function userUpdateFailed(errorData, callback) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: USER_UPDATE_FAILED,
    message: response.data,
  };
}

export const userUpdateRequest =
  (id, attributes, callback) => async (dispatch) => {
    dispatch({ type: USER_UPDATE_REQUEST });

    await UserApi.update(id, attributes)
      .then((data) => {
        dispatch(userUpdateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(userUpdateFailed(errors, callback));
      });
  };

export const userDeleteReceived = (response, callback) => (dispatch) => {
  dispatch({
    type: USER_DELETE_RECEIVED,
  });

  toastr.success(
    t("common.success"),
    t("toasterMessage.resource.delete", {
      resource: t("attributes.user"),
    })
  );

  if (typeof callback === "function") {
    callback();
  }
};

export const userDeleteFailed = (errorData) => {
  const { response } = errorData;
  const error = getServerError(response);
  const errorText =
    error === "not found" ? t("toasterMessage.processError") : error;

  displayFetchErrorToaster(errorText);

  return (dispatch) => {
    dispatch({
      type: USER_DELETE_FAILED,
      message: errorText,
    });
  };
};

export const userDeleteRequest = (id, callback) => (dispatch) => {
  dispatch({
    type: USER_DELETE_REQUEST,
  });

  UserApi.deleteUser(id)
    .then((data) => dispatch(userDeleteReceived(data, callback)))
    .catch((e) => {
      dispatch(userDeleteFailed(e));
    });
};

export function switchUserReceived(response) {
  return {
    type: SWITCH_USER_LANGUAGE_RECEIVED,
    user: response.data,
  };
}

export function switchUserFailed(errorData) {
  const { response } = errorData;

  if (response.data.status) {
    getAndDisplayErrors(errorData);
  }

  return {
    type: SWITCH_USER_LANGUAGE_FAILED,
    message: response.data,
  };
}

export const switchUserLanguageRequest = (id, language) => async (dispatch) => {
  try {
    dispatch({ type: SWITCH_USER_LANGUAGE_REQUEST });

    const response = await UserApi.updateLanguage(id, language);

    dispatch(switchUserReceived(response));
  } catch (error) {
    dispatch(switchUserFailed(error));
  }
};

export function userFetchClearErrors() {
  return {
    type: USER_FETCH_CLEAR_ERRORS,
  };
}

export function userSearchRequest(value) {
  const isSearching = Boolean(value);

  return {
    type: USER_SEARCH_REQUEST,
    isSearching,
  };
}
