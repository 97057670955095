import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import { t } from "react-i18nify";
import { useSelector } from "react-redux";

import { getPlaceholderText } from "utils/utils";

import InputLabel from "components/ReduxForm/InputLabel";
import FormError from "components/Form/FormError";

const formatPasteValue = (pasteValue) => pasteValue.replaceAll(",", "");

const formatPriceValue = (priceValue) => ({
  "en-US": priceValue?.toString?.()?.replaceAll?.(",", ".") ?? priceValue,
  "da-DK": priceValue?.toString?.()?.replaceAll?.(".", ",") ?? priceValue,
});

const PriceField = ({
  input: { name, onBlur, onChange, ...input },
  placeholder,
  placeholderTranslation,
  label,
  labelTranslation,
  disabled,
  hidden,
  replacementTranslations,
  meta: { touched, error, warning, active },
}) => {
  const locale = useSelector(({ i18n }) => i18n.locale);

  const [currentLocale, setCurrentLocale] = useState(locale);

  const [value, setValue] = useState(formatPriceValue(input.value));
  const [inputError, setInputError] = useState(null);

  useEffect(() => {
    const formattedPriceValue = formatPriceValue(input.value);

    setValue(formattedPriceValue);
  }, [input.value]);

  useEffect(() => {
    if (currentLocale !== locale) {
      const formattedPriceValue = formatPriceValue(value[currentLocale]);

      setValue(formattedPriceValue);
      setCurrentLocale(locale);
    }
  }, [locale]);

  const checkInputValidity = (inputValue, inputType, text) => {
    const isCopiedValue = inputType === "insertFromPaste";
    const isDecimalPoint = locale === "en-US" && inputValue === ".";
    const isDanishDecimalPoint = locale === "da-DK" && inputValue === ",";
    const inclusionError = t("validationErrors.INCLUSION");

    setInputError(null);

    // + unary operator is used to convert the text value to number
    // if text value is not a valid number in string, NaN is returned
    if (!Number.isNaN(+text) && !isCopiedValue) return true;

    if (isDanishDecimalPoint) {
      if (value["da-DK"]?.indexOf?.(",") >= 0) {
        setInputError(inclusionError);

        return false;
      }

      return true;
    }

    if (isDecimalPoint) {
      if (value["en-US"]?.indexOf?.(".") >= 0) {
        setInputError(inclusionError);

        return false;
      }

      return true;
    }

    if (isCopiedValue) {
      return /^(0|[1-9]\d*)((,|\.)\d+)?$/gm.test(text);
    }

    setInputError(inclusionError);

    return false;
  };

  const handleChange = ({ target, nativeEvent }) => {
    const { data, inputType } = nativeEvent;
    let fieldValue = target.value;

    if (inputType === "insertFromPaste")
      fieldValue = formatPasteValue(fieldValue);

    if (!checkInputValidity(data, inputType, fieldValue)) return;

    const localeValue = formatPriceValue(fieldValue, locale);

    onChange(localeValue["en-US"]);
    setValue(localeValue);
  };

  const placeholderText = getPlaceholderText({
    label,
    placeholder,
    placeholderTranslation,
    labelTranslation,
    replacementTranslations: replacementTranslations.placeholder,
  });

  return (
    <div>
      <InputLabel
        name={name}
        label={label}
        labelTranslation={labelTranslation}
        replacementTranslations={replacementTranslations.label}
      />
      <Input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
        value={value[currentLocale]}
        placeholder={placeholderText}
        type="text"
        id={name}
        disabled={disabled}
        hidden={hidden}
        onBlur={(e) => {
          e.target.value = value["en-US"];
          onBlur(e);
        }}
        onChange={handleChange}
        autoComplete="off"
        data-field-name={name}
        data-testid={name}
      />
      <FormError
        inputError={inputError}
        error={error}
        touched={touched}
        active={active}
        className="invalid-text"
        warning={warning}
        name={name}
      />
    </div>
  );
};

PriceField.defaultProps = {
  placeholder: "",
  label: undefined,
  labelTranslation: undefined,
  disabled: false,
  hidden: false,
  min: undefined,
  max: undefined,
  placeholderTranslation: undefined,
  replacementTranslations: {
    label: {},
    placeholder: {},
  },
};

PriceField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  placeholderTranslation: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
    active: PropTypes.bool,
  }).isRequired,
  label: PropTypes.string,
  labelTranslation: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  replacementTranslations: PropTypes.shape({
    label: PropTypes.shape({}),
    placeholder: PropTypes.shape({}),
  }),
};

export default PriceField;
