import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";
import { connect } from "react-redux";
import { useField, useForm } from "react-final-form";

import {
  calculateSubscriptionMonthlyPrice,
  calculateSubscriptionSetupPrice,
  calculateTotalAmount,
} from "utils/quotation";
import { getQuotationBindingPeriodForSubscription } from "utils/subscription";

import REGEX_TO_CHECK_XDSL_SUBSCRIPTION_TYPE from "constants/regex";
import { XDSL_SUBSCRIPTION } from "constants/constant";

import { productFetchRequest } from "redux/actions/product";

import SubscriptionTableEmptyUI from "components/Quotation/Form/Subscription/SubscriptionTableEmptyUI";
import SubscriptionListTable from "components/Quotation/Form/Subscription/SubscriptionListTable";

const SubscriptionSection = ({ fields, fetchProducts }) => {
  const [isModalVisible, setModalVisibility] = useState(false);

  const form = useForm();

  useEffect(() => {
    fetchProducts();
  }, []);

  const temporarySubscription = useField("agreement.temporary_subscription");

  const {
    input: {
      value: {
        all_total_setup_price: allTotalSetupPrice,
        all_total_monthly_price: allTotalMonthlyPrice,
      },
    },
  } = useField("agreement");

  const toggleModalVisibility = () => {
    setModalVisibility((prevState) => !prevState);
  };

  const handleSubscriptionAddEffectForQuotationAmountAttributes = (
    updatedTotalSetupPrice,
    updatedTotalMonthlyPrice
  ) => {
    const updatedAllTotalSetupPrice =
      (allTotalSetupPrice || 0) + updatedTotalSetupPrice;
    const updatedAllTotalMonthlyPrice =
      (allTotalMonthlyPrice || 0) + updatedTotalMonthlyPrice;
    const totalQuotationAmount = calculateTotalAmount(
      updatedAllTotalSetupPrice,
      updatedAllTotalMonthlyPrice
    );

    form.batch(() => {
      form.change("agreement.all_total_setup_price", updatedAllTotalSetupPrice);
      form.change(
        "agreement.all_total_monthly_price",
        updatedAllTotalMonthlyPrice
      );
      form.change("agreement.total_amount", totalQuotationAmount);
    });
  };

  const autoPopulateSubscriptionBindingPeriod = (subscriptionLines) => {
    const quotationSubscriptionBindingPeriodsFieldsState = form.getFieldState(
      `quotation_subscription_binding_periods`
    );

    const { value: quotationSubscriptionBindingPeriods } =
      quotationSubscriptionBindingPeriodsFieldsState;

    const mainSubscriptionLine = subscriptionLines.find(
      ({ line_type: lineType }) => lineType === "subscription"
    );

    const { product_name: productName } = mainSubscriptionLine;

    const alreadyExistingBindingPeriod =
      quotationSubscriptionBindingPeriods?.find(
        ({ subscription_type: bindingPeriodSubscriptionType }) => {
          const isxDSLSubscription =
            Boolean(
              bindingPeriodSubscriptionType.match(
                REGEX_TO_CHECK_XDSL_SUBSCRIPTION_TYPE
              )
            ) && Boolean(productName.toLowerCase().includes(XDSL_SUBSCRIPTION));

          return (
            productName.toLowerCase().includes(bindingPeriodSubscriptionType) ||
            isxDSLSubscription
          );
        }
      );

    if (alreadyExistingBindingPeriod) return;

    const quotationBindingPeriodToPopulate =
      getQuotationBindingPeriodForSubscription(productName.toLowerCase());

    if (!quotationBindingPeriodToPopulate) return;

    const quotationBindingPeriodIndexToAddTo =
      quotationSubscriptionBindingPeriods
        ? quotationSubscriptionBindingPeriods.length
        : 0;

    form.batch(() => {
      form.change(
        `quotation_subscription_binding_periods[${quotationBindingPeriodIndexToAddTo}]`,
        quotationBindingPeriodToPopulate
      );
    });
  };

  const addNewSubscription = () => {
    const {
      input: { value: subscription },
      meta: { invalid },
    } = temporarySubscription;
    const { subscription_lines: subscriptionLines } = subscription;

    if (invalid) {
      return;
    }

    const monthlyPrice = calculateSubscriptionMonthlyPrice(subscriptionLines);
    const setupPrice = calculateSubscriptionSetupPrice(subscriptionLines);

    const subscriptionAttributes = {
      ...subscription,
      quantity: 1,
      monthly_price: monthlyPrice,
      setup_price: setupPrice,
      total_monthly_price: monthlyPrice,
      total_setup_price: setupPrice,
      total_amount: calculateTotalAmount(setupPrice, monthlyPrice),
    };

    fields.push(subscriptionAttributes);

    autoPopulateSubscriptionBindingPeriod(subscriptionLines);

    handleSubscriptionAddEffectForQuotationAmountAttributes(
      setupPrice,
      monthlyPrice
    );

    toggleModalVisibility();
    form.change("agreement.temporary_subscription", {});
  };

  const resetTemporarySubscription = () => {
    toggleModalVisibility();
    form.change("agreement.temporary_subscription", {});
  };

  let subscriptionSectionContent;

  if (fields.length === 0) {
    subscriptionSectionContent = (
      <SubscriptionTableEmptyUI
        show
        resourceName="subscription"
        toggleModalVisibility={toggleModalVisibility}
        isModalVisible={isModalVisible}
        addNewSubscription={addNewSubscription}
        resetTemporarySubscription={resetTemporarySubscription}
        temporarySubscription={temporarySubscription}
      />
    );
  } else {
    subscriptionSectionContent = (
      <SubscriptionListTable
        fields={fields}
        modalVisible={isModalVisible}
        toggleModalVisibility={toggleModalVisibility}
        addNewSubscription={addNewSubscription}
        resetTemporarySubscription={resetTemporarySubscription}
        temporarySubscriptionField={temporarySubscription}
      />
    );
  }

  return (
    <div>
      <h4 className="my-4">
        <Translate value="attributes.subscriptions" />
      </h4>

      {subscriptionSectionContent}
    </div>
  );
};

SubscriptionSection.defaultProps = {};

SubscriptionSection.propTypes = {
  form: PropTypes.shape({}).isRequired,
  fetchProducts: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.shape({})),
    length: PropTypes.number,
    push: PropTypes.func,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  fetchProducts: () => dispatch(productFetchRequest()),
});

export default connect(null, mapDispatchToProps)(SubscriptionSection);
