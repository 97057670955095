const addParams = (paramsObj) => {
  let params = "";

  Object.entries(paramsObj).map(([key, value], index) => {
    if (index === 0) {
      params = `?${key}=${value}`;
    } else params = `${params}&${key}=${value}`;

    return null;
  });

  return params;
};

export default addParams;
