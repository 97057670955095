import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { FormGroup, Row, Col, Button, Spinner, Label } from "reactstrap";
import { Field, useField, useForm } from "react-final-form";
import { Translate } from "react-i18nify";
import PropTypes from "prop-types";

import { productFetchRequest } from "redux/actions/product";

import ContextPropertyApi from "apis/ContextProperty";

import { required } from "utils/validation";
import { formatDropdownOptions } from "utils/utils";

import {
  renderInputField,
  renderDropdownField,
  renderPriceInputField,
} from "components/ReduxForm/RenderField";

import { RESOURCE_NAMES } from "constants/constant";
import { SUBSCRIPTION_LINE_TYPE } from "constants/subscriptionLine";
import {
  subscriptionForOptions,
  subscriptionCategoryOptions,
  subscriptionTypeOptions,
  cellPhoneProductTypeOptions,
  cellPhoneUsageTypeOptions,
} from "constants/dropdownOptions";

const RenderPropertiesContent = () => {
  const {
    input: { value: properties },
  } = useField("properties");

  const isPropertiesUnavailable =
    !properties || (properties && !properties.length);

  if (isPropertiesUnavailable)
    return <Translate value="message.subscriptionProduct.noProperties" />;

  if (typeof properties === "string") return properties;

  return (
    <div className="d-md-flex align-items-center justify-content-md-between p-3 product-item border-bottom-0">
      <div className="mt-4 mt-md-0 mb-3 mb-md-0 d-md-flex align-items-center">
        {properties.map((property) => (
          <span
            key={property}
            className="badge rounded-pill bg-primary py-1 px-2 tags d-inline-block mt-2 mt-md-0"
          >
            {property}
          </span>
        ))}
      </div>
    </div>
  );
};

const RenderPriceAndPropertiesSection = () => {
  const {
    input: { value: subscriptionType },
  } = useField("subscription_type");

  if (subscriptionType !== SUBSCRIPTION_LINE_TYPE.USAGE) return null;

  return (
    <>
      <Col md={6}>
        <FormGroup>
          <Field
            name="price"
            component={renderPriceInputField}
            validate={required}
            placeholderTranslation="attributes.price"
            labelTranslation="attributes.price"
            type="number"
          />
        </FormGroup>
      </Col>

      <Col md={6}>
        <FormGroup>
          <Label>
            <Translate value="subscription.productProperties" />
          </Label>
          <div>
            <RenderPropertiesContent />
          </div>
        </FormGroup>
      </Col>
    </>
  );
};

const RenderUsageProductPropertySection = ({ subscriptionCategory }) => {
  const {
    input: { value: subscriptionType },
  } = useField("subscription_type");

  if (
    subscriptionType !== SUBSCRIPTION_LINE_TYPE.USAGE &&
    subscriptionCategory !== RESOURCE_NAMES.CELL_PHONE
  )
    return null;

  return (
    <>
      <Col md={6}>
        <FormGroup>
          <Field
            name="usage_product_property_attributes.cell_phone_product_type"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={cellPhoneProductTypeOptions}
            labelTranslation="attributes.productType"
          />
        </FormGroup>
      </Col>

      <Col md={6}>
        <FormGroup>
          <Field
            name="usage_product_property_attributes.cell_phone_usage_type"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={cellPhoneUsageTypeOptions}
            labelTranslation="attributes.usageType"
          />
        </FormGroup>
      </Col>
    </>
  );
};

const RenderProductSection = ({ subscriptionCategory, onProductChange }) => {
  const filterSubscriptionForOptions = subscriptionForOptions.filter(
    (subscriptionForOption) => {
      switch (subscriptionCategory) {
        case 1: // voip subscription category value
          return subscriptionForOption.value !== RESOURCE_NAMES.CELL_PHONE;
        case 2: // cell phone subscription category value
          return subscriptionForOption.value === RESOURCE_NAMES.CELL_PHONE;
        default:
          return true;
      }
    }
  );

  return (
    <>
      <Col md={6}>
        <FormGroup>
          <Field
            name="subscription_for"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={filterSubscriptionForOptions}
            labelTranslation="subscription.for"
          />
        </FormGroup>
      </Col>

      <Col md={6}>
        <FormGroup>
          <Field
            name="product_name"
            component={renderInputField}
            validate={required}
            placeholderTranslation="attributes.productName"
            labelTranslation="attributes.productName"
          />
        </FormGroup>
      </Col>

      <RenderPriceAndPropertiesSection />

      <RenderUsageProductPropertySection
        subscriptionCategory={subscriptionCategory}
      />

      <Col md={12}>
        <FormGroup>
          <Button onClick={onProductChange}>
            <Translate value="message.product.chooseDifferent" />
          </Button>
        </FormGroup>
      </Col>
    </>
  );
};

const RenderProductFetchErrorField = ({ productFetchError }) => {
  if (productFetchError.length)
    return <div className="text-danger">{productFetchError}</div>;

  return null;
};

const RenderProductContent = ({
  subscriptionCategory,
  onProductChange,
  onProductSelect,
  onSubscriptionCategorySelect,
  products,
  isProductFetching,
  productFetchError,
}) => {
  const {
    input: { value: odooProductID },
  } = useField("odoo_product_id");

  const {
    input: { value: subscriptionType },
  } = useField("subscription_type");

  const contextTypeByLineType = (subscriptionLineType) => {
    switch (subscriptionLineType) {
      case SUBSCRIPTION_LINE_TYPE.SUBSCRIPTION:
        return 1;
      case SUBSCRIPTION_LINE_TYPE.ADD_ON:
        return 2;
      case SUBSCRIPTION_LINE_TYPE.SETUP:
        return 3;
      case SUBSCRIPTION_LINE_TYPE.USAGE:
        return 4;
      default:
        return 1;
    }
  };

  const subscriptionProducts =
    subscriptionType && subscriptionCategory
      ? products.filter(
          (product) =>
            parseInt(product.context, 10) === subscriptionCategory &&
            parseInt(product.context_type, 10) ===
              contextTypeByLineType(subscriptionType)
        )
      : products;

  if (odooProductID)
    return (
      <RenderProductSection
        subscriptionCategory={subscriptionCategory}
        onProductChange={onProductChange}
      />
    );

  return (
    <>
      <Col md={6}>
        <FormGroup>
          <Label>
            <Translate value="subscription.category" />
          </Label>
          <Select
            id="subscription-category"
            className="w-100"
            options={subscriptionCategoryOptions}
            onChange={onSubscriptionCategorySelect}
          />
        </FormGroup>
      </Col>

      <Col md={6}>
        <FormGroup>
          <Field
            name="subscription_type"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={subscriptionTypeOptions}
            labelTranslation="attributes.subscriptionType"
          />
        </FormGroup>
      </Col>

      <Col md={6}>
        <FormGroup>
          <Label>
            <Translate value="attributes.product" />
          </Label>
          <Select
            id="subscription-product"
            className="w-100"
            options={formatDropdownOptions(subscriptionProducts)}
            onChange={onProductSelect}
            isLoading={isProductFetching}
            isDisabled={!subscriptionCategory || !subscriptionType}
          />
          <RenderProductFetchErrorField productFetchError={productFetchError} />
        </FormGroup>
      </Col>
    </>
  );
};

const ProductSection = ({ fetchProducts, products, isProductFetching }) => {
  const form = useForm();

  const [fetchingProperties, setFetchingProperties] = useState(false);
  const [productFetchError, setProductFetchError] = useState("");
  const [subscriptionCategory, setSubscriptionCategory] = useState(undefined);

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleProductSelect = ({ value }) => {
    const selectedProduct = products.find((product) => product.id === value);
    const {
      id,
      name,
      list_price: price,
      context_properties_ids: contextPropertiesIDs,
    } = selectedProduct;

    setFetchingProperties(true);

    ContextPropertyApi.fetchAll(id, contextPropertiesIDs)
      .then(({ data: contextProperties }) => {
        const subscriptionLineProperties = contextProperties.map(
          (contextProperty) => {
            const { property } = contextProperty;

            return property;
          }
        );

        form.batch(() => {
          form.change("odoo_product_id", id);
          form.change("product_name", name);
          form.change("price", price);
          form.change("properties", subscriptionLineProperties);
        });

        setFetchingProperties(false);
      })
      .catch((errors) => {
        setFetchingProperties(false);
        setProductFetchError(errors.message);
      });
  };

  const handleProductChange = () => {
    form.change("odoo_product_id", null);

    setSubscriptionCategory(undefined);
  };

  const handleSubscriptionCategorySelect = ({ value }) => {
    setSubscriptionCategory(value);

    if (value === 2) {
      form.change("subscription_for", "cell_phone");
    }
  };

  if (isProductFetching || fetchingProperties) {
    return (
      <Row>
        <Col md={12}>
          <div className="empty-screen text-center py-5">
            <Spinner as="span" size="md" />
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <RenderProductContent
        isProductFetching={isProductFetching}
        onProductChange={handleProductChange}
        onSubscriptionCategorySelect={handleSubscriptionCategorySelect}
        productFetchError={productFetchError}
        products={products}
        subscriptionCategory={subscriptionCategory}
        onProductSelect={handleProductSelect}
      />
    </Row>
  );
};

RenderUsageProductPropertySection.defaultProps = { subscriptionCategory: "" };

RenderUsageProductPropertySection.propTypes = {
  subscriptionCategory: PropTypes.string,
};

RenderProductSection.defaultProps = { subscriptionCategory: "" };

RenderProductSection.propTypes = {
  subscriptionCategory: PropTypes.string,
  onProductChange: PropTypes.func.isRequired,
};

RenderProductContent.defaultProps = {
  subscriptionCategory: "",
  products: [],
  isProductFetching: false,
  productFetchError: "",
};

RenderProductFetchErrorField.defaultProps = { productFetchError: "" };

RenderProductFetchErrorField.propTypes = {
  productFetchError: PropTypes.string,
};

RenderProductContent.propTypes = {
  subscriptionCategory: PropTypes.string,
  onProductChange: PropTypes.func.isRequired,
  products: PropTypes.shape([]),
  onSubscriptionCategorySelect: PropTypes.func.isRequired,
  isProductFetching: PropTypes.bool,
  productFetchError: PropTypes.string,
  onProductSelect: PropTypes.func.isRequired,
};

ProductSection.defaultProps = {};

ProductSection.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchProducts: PropTypes.func.isRequired,
  isProductFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ product }) => {
  const { products, isLoading: isProductFetching } = product;

  return {
    products,
    isProductFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProducts: () => dispatch(productFetchRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSection);
