import {
  SUBSCRIPTION_TYPES,
  SUBSCRIPTION_LINE_TYPES,
  ODOO_CONTEXT_TYPES,
  BINDING_PERIOD_AND_SUBSCRIPTION_TYPES_MAPPING,
} from "constants/constant";

export const getSubscriptionType = (context) => SUBSCRIPTION_TYPES[context];

export const getSubscriptionLineType = (contextType) =>
  SUBSCRIPTION_LINE_TYPES[contextType];

export const getSubscriptionOdooContext = (subscriptionType) =>
  ODOO_CONTEXT_TYPES[subscriptionType];

export const getQuotationBindingPeriodForSubscription = (productName) => {
  const subscriptionTypesKeys = Object.keys(
    BINDING_PERIOD_AND_SUBSCRIPTION_TYPES_MAPPING
  );

  const productNameSubstringMatch = subscriptionTypesKeys.find(
    (subscriptionTypeKey) => {
      const extractedBindingPeriodSubscriptionType =
        subscriptionTypeKey.substring(0, subscriptionTypeKey.indexOf("-"));

      return (
        extractedBindingPeriodSubscriptionType.includes(productName) ||
        productName.includes(extractedBindingPeriodSubscriptionType)
      );
    }
  );

  if (productNameSubstringMatch) {
    let bindingPeriodSubscriptionType;

    if (productNameSubstringMatch === "xdsl-internet_connections") {
      bindingPeriodSubscriptionType = "x_dsl";
    } else {
      bindingPeriodSubscriptionType = productNameSubstringMatch.substring(
        0,
        productNameSubstringMatch.indexOf("-")
      );
    }

    return {
      subscription_type: bindingPeriodSubscriptionType,
      binding_period_in_months:
        BINDING_PERIOD_AND_SUBSCRIPTION_TYPES_MAPPING[
          productNameSubstringMatch
        ],
    };
  }

  return null;
};
