import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Translate } from "react-i18nify";

import { logoutRequest } from "redux/actions/auth";

const SessionExpiredModal = () => {
  const dispatch = useDispatch();

  const { sessionExpired } = useSelector(({ auth }) => auth);

  const handleConfirm = () => {
    dispatch(logoutRequest());
  };

  if (!sessionExpired) return null;

  return (
    <Modal
      isOpen={sessionExpired}
      backdropClassName="session-expired-overlay"
      centered
      data-cy="session-expired-modal"
    >
      <ModalHeader className="border-0 pb-0" />
      <ModalBody className="pt-0 text-center">
        <h5 className="mt-2 mb-0">
          <Translate value="modal.sessionExpired.whoopsYourSessionHasExpired" />{" "}
        </h5>
        <p className="text-danger pt-3 pb-1 text-center">
          <Translate value="modal.sessionExpired.description" />
        </p>
        <div className="d-flex justify-content-center">
          <Button color="primary" type="button" onClick={handleConfirm}>
            <Translate value="common.logout" />
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SessionExpiredModal;
