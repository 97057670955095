import React from "react";
import Skeleton from "react-loading-skeleton";

const SubscriptionsContentSkeleton = () => {
  return (
    <div className="bg-white rounded shadow-sm">
      <div className="d-flex p-3">
        <Skeleton width={75} height={20} className="me-4" />
        <Skeleton width={75} height={20} className="me-4" />
        <Skeleton width={75} height={20} />
      </div>
      <div className="m-0 border-bottom border-gray-100" />
    </div>
  );
};

export default SubscriptionsContentSkeleton;
