import React from "react";
import { Link } from "react-router-dom";
import { Translate } from "react-i18nify";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  faCalendarDay,
  faIdBadge,
  faPager,
  faEnvelope,
  faCommentAlt,
  faCalendarTimes,
  faTty,
  faBlenderPhone,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";

import { PBX, USER_LICENSE, VOICEMAIL_BOX } from "constants/routes";

import TranslateDate from "components/i18n/TranslateDate";

import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import CellPhoneAdditionalDetail from "components/CellPhone/Detail/CellPhoneAdditionalDetail";
import CellPhoneSubscriptionDetail from "components/CellPhone/Detail/CellPhoneSubscriptionDetail";
import Pill from "components/Pill/Pill";
import Tooltip from "components/Tooltip/Tooltip";

import { CELL_PHONE_STATUS, RESOURCE_NAMES } from "constants/constant";
import { ICON_INFO } from "constants/images";

import { isABusinessAdminUser } from "utils/userRole";

const SuspendedTooltip = ({ show, title, children }) => {
  if (!show) return children;

  return (
    <div className="d-inline-block suspended-tooltip">
      {children}
      <Tooltip
        title={title}
        containerClassName="d-inline ms-1 pt-2"
        className="disabled-tooltip"
      >
        <img src={ICON_INFO.SOURCE} alt="information icon" />
      </Tooltip>
    </div>
  );
};

const CellPhoneBasicDetail = ({ cellPhone, providers }) => {
  const {
    deployment_date: deploymentDate,
    invoice_start_date: invoiceStartDate,
    user_license_id: userLicenseID,
    ex_name: name,
    is_default: isDefault,
    ex_description: description,
    ex_email: emailAddress,
    termination_date: terminationDate,
    pbx_id: pbxID,
    pbx_ref_number: pbxRefNumber,
    asterisk_voicemail_box_id: voicemailBoxId,
    cell_number: cellNumber,
    extension_number: userLicenseExtension,
    is_suspended: isSuspended,
    is_active: isActive,
    suspended_reason: suspendedReason,
    suspended_date: suspendedDate,
  } = cellPhone;

  const status =
    !isSuspended && isActive
      ? CELL_PHONE_STATUS.ACTIVE
      : CELL_PHONE_STATUS.SUSPENDED;

  const pillClassName =
    status === CELL_PHONE_STATUS.ACTIVE ? "bg-green" : "bg-red";

  const {
    currentUser: { role },
  } = useSelector(({ auth }) => auth);

  const isBusinessAdminUser = isABusinessAdminUser(role);

  const basicDetailContent = [
    {
      icon: faPager,
      title: <Translate value="attributes.name" />,
      content: name,
      dataCyFieldName: "name",
    },
    emailAddress && {
      icon: faEnvelope,
      title: <Translate value="attributes.email" />,
      content: emailAddress,
      dataCyFieldName: "email",
    },
    userLicenseID && {
      icon: faIdBadge,
      title: <Translate value="attributes.userLicense" />,
      content: (
        <>
          {userLicenseExtension}
          <Link
            to={USER_LICENSE.addId(USER_LICENSE.SHOW, userLicenseID)}
            className="ml-1"
          >
            {" ( "}
            <Translate value="common.viewDetails" />
            {" )"}
          </Link>
        </>
      ),
      dataCyFieldName: "view-userLicense-details",
    },
    pbxID && {
      icon: faTty,
      title: <Translate value="attributes.pbx" />,
      content: (
        <>
          {pbxRefNumber}
          <Link to={PBX.addId(PBX.SHOW, pbxID)} className="ml-1">
            {" ( "}
            <Translate value="common.viewDetails" />
            {" )"}
          </Link>
        </>
      ),
      dataCyFieldName: "view-pbx-details",
    },
    voicemailBoxId && {
      icon: faBlenderPhone,
      title: <Translate value="attributes.voicemailBox" />,
      content: (
        <>
          {cellNumber}{" "}
          <Link
            to={VOICEMAIL_BOX.addId(VOICEMAIL_BOX.INDEX, voicemailBoxId)}
            className="ml-1"
          >
            ( <Translate value="common.viewDetails" /> )
          </Link>
        </>
      ),
      dataCyFieldName: "view-voicemailBox-details",
    },
    isBusinessAdminUser && {
      icon: faCalendarDay,
      title: <Translate value="attributes.deploymentDate" />,
      content: <TranslateDate date={deploymentDate} />,
      dataCyFieldName: "deploymentDate",
    },
    isBusinessAdminUser && {
      icon: faCalendarDay,
      title: <Translate value="attributes.invoiceStartDate" />,
      content: <TranslateDate date={invoiceStartDate} />,
      dataCyFieldName: "invoiceStartDate",
    },
    {
      icon: faCalendarDay,
      title: <Translate value="cellPhone.defaultInMobileApp" />,
      content: isDefault ? (
        <Translate value="common.yes" />
      ) : (
        <Translate value="common.no" />
      ),
      dataCyFieldName: "defaultInMobileApp",
    },
    description && {
      icon: faCommentAlt,
      title: <Translate value="attributes.description" />,
      content: description,
      dataCyFieldName: "description",
    },
    terminationDate !== null && {
      icon: faCalendarTimes,
      title: <Translate value="attributes.terminationDate" />,
      content: <TranslateDate date={terminationDate} />,
      dataCyFieldName: "terminationDate",
    },
    {
      icon: faUserCheck,
      title: <Translate value="attributes.status" />,
      content: (
        <SuspendedTooltip
          show={isSuspended}
          title={
            <span className="suspended-tooltip d-inline-block">
              <span className="suspended-tooltip-date d-flex flex-column pb-2 pt-2">
                <Translate value="attributes.suspendedDate" /> :{" "}
                <TranslateDate date={suspendedDate} />
              </span>
              <span className="suspended-tooltip-reason">
                {suspendedReason}
              </span>
            </span>
          }
          className="d-inline-block"
        >
          <Pill
            show
            content={<Translate value={`pill.status.${status}`} />}
            className={`${pillClassName} py-1 px-3`}
          />
        </SuspendedTooltip>
      ),
      dataCyFieldName: "status",
    },
  ];

  return (
    <div>
      <CardWithHeader
        title={<Translate value="common.basicDetails" />}
        className="shadow-sm"
        cardContent={basicDetailContent.filter((item) => item != null)}
        dataCy={RESOURCE_NAMES.CELL_PHONE}
      />
      <CellPhoneAdditionalDetail cellPhone={cellPhone} providers={providers} />
      <CellPhoneSubscriptionDetail cellPhone={cellPhone} />
    </div>
  );
};

SuspendedTooltip.defaultProps = {
  title: "",
};

SuspendedTooltip.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CellPhoneBasicDetail.defaultProps = {};

CellPhoneBasicDetail.propTypes = {
  cellPhone: PropTypes.shape({
    extension_number: PropTypes.string,
    pbx_ref_number: PropTypes.string,
    deployment_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    invoice_start_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    termination_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    user_license_id: PropTypes.number,
    pbx_id: PropTypes.number,
    ex_name: PropTypes.string,
    is_default: PropTypes.bool,
    ex_description: PropTypes.string,
    ex_email: PropTypes.string,
    asterisk_voicemail_box_id: PropTypes.number,
    cell_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    is_suspended: PropTypes.bool,
    is_active: PropTypes.bool,
    suspended_reason: PropTypes.string,
    suspended_date: PropTypes.string,
  }).isRequired,
  providers: PropTypes.shape().isRequired,
};

export default CellPhoneBasicDetail;
