import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.pbxEndPoint()}/voicemail_boxes/:voicemail_box_id`,
  UPDATE: `${apiUrlConfig.pbxEndPoint()}/voicemail_boxes/:voicemail_box_id`,
};

class VoicemailBoxApi extends AuthenticatedRequest {
  // Fetch voicemail details
  static fetch(voicemailBoxId) {
    const FETCH_URL = URLS.INDEX.replace(":voicemail_box_id", voicemailBoxId);

    return this.get(FETCH_URL);
  }

  static update(voicemailBoxId, attributes) {
    const UPDATE_URL = URLS.UPDATE.replace(":voicemail_box_id", voicemailBoxId);

    return this.patch(UPDATE_URL, attributes);
  }
}

export default VoicemailBoxApi;
