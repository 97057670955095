import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { AutoSizer } from "react-virtualized";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";

import CreateButton from "components/Button/Table/CreateButton/CreateButton";
import SortableTable from "components/Table/SortableTable/SortableTable";
import FetchError from "components/Error/FetchError";
import ResourceListSkeleton from "components/Skeleton/ResourceList/ResourceListSkeleton";
import RefreshButton from "components/Button/Table/RefreshButton/RefreshButton";
import EmptyTableUI from "components/Table/RecordListingTable/EmptyTableUI";
import TableHeader from "components/Table/RecordListingTable/TableHeader";
import { NEW } from "constants/routes/routesResources";

const TableContent = ({
  fetchError,
  onRetry,
  children,
  records,
  handleRowClick,
  rowClassName,
  createResourceUrl,
  isArchivedResource,
  hideAddButton,
  showHeader,
  defaultClassName,
}) => {
  if (!fetchError && records.length === 0) {
    return (
      <EmptyTableUI
        createResourceUrl={createResourceUrl}
        hideAddButton={
          isArchivedResource || hideAddButton || fetchError || showHeader
        }
      />
    );
  }

  if (fetchError) {
    return (
      <div className={defaultClassName.join(" ")}>
        <FetchError onRetry={onRetry} errorText={fetchError} />;
      </div>
    );
  }

  return (
    <div className={defaultClassName.join(" ")}>
      <div className="record-listing-table" data-cy="record-listing-table">
        <AutoSizer>
          {({ height, width }) => {
            const tableWidth = Math.max(1000, width);

            return (
              <SortableTable
                width={tableWidth}
                height={height}
                headerHeight={60}
                rowHeight={60}
                records={records}
                onRowClick={handleRowClick}
                rowClassName={rowClassName}
              >
                {children}
              </SortableTable>
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
};

const RecordListingTable = ({
  records,
  children,
  searchRecords,
  resourceName,
  resourceTitle,
  className,
  isArchivedResource,
  parentID,
  redirectToEdit,
  isSearching,
  additionalHeaderContent,
  totalDeactivated,
  isLoading,
  hideSearchBar,
  hideAddButton,
  fetchError,
  showHeader,
  onRetry,
  recordDetailIDAttribute,
  disableRowClick,
  rowClassName,
  isRefreshing,
  refreshRecords,
  dataCy,
}) => {
  const navigate = useNavigate();
  const { userLicenseID } = useParams();
  const location = useLocation();

  useEffect(
    () =>
      // Clear search text when component is unmounted
      () =>
        searchRecords && searchRecords(),
    []
  );

  const defaultClassName = [
    "record-listing-table-container w-100 d-flex flex-column shadow-sm mb-3 h-30em",
  ];

  defaultClassName.push(className);

  // eslint-disable-next-line consistent-return
  const handleRowClick = ({ rowData }) => {
    if (disableRowClick) return null;

    const { id, source_number: didNumber } = rowData;

    const recordID = recordDetailIDAttribute
      ? rowData[recordDetailIDAttribute]
      : id;

    const url = `/${resourceName}/${recordID}`;
    const redirectUrl = redirectToEdit ? `${url}/edit` : url;

    if (didNumber) {
      navigate(`${url}?did_number=${didNumber}`);
    } else if (userLicenseID === parentID) {
      navigate(redirectUrl, { state: userLicenseID });
    } else {
      navigate(redirectUrl);
    }
  };

  if ((!records || isLoading || isRefreshing) && !fetchError) {
    return <ResourceListSkeleton resourceName={resourceName} />;
  }

  // Having parentID means that we are coming from the nested routes like debtors, pbxs, etc.
  const createResourceUrl = parentID
    ? `${location.pathname}/${NEW}`
    : `/${resourceName}/${NEW}`;

  if (
    records?.length === 0 &&
    totalDeactivated === 0 &&
    !isSearching &&
    !fetchError &&
    !showHeader
  ) {
    return (
      <EmptyTableUI
        hideAddButton={isArchivedResource || hideAddButton || fetchError}
        createResourceUrl={createResourceUrl}
      />
    );
  }

  const recordCreateButton = !hideAddButton && !fetchError && (
    <CreateButton
      isArchivedResource={isArchivedResource}
      resourceName={resourceName}
      createResourceUrl={createResourceUrl}
      dataCy={dataCy}
      data-testid="create-button"
    />
  );

  const refreshButton = refreshRecords && (
    <RefreshButton
      isRefreshing={isRefreshing}
      refreshRecords={refreshRecords}
    />
  );

  return (
    <div className="container-fluid h-100">
      <div className="record-listing-table-top-header bg-white mb-0 full-width-top-header shadow-sm">
        <Row>
          <Col lg="12">
            <TableHeader
              resourceTitle={resourceTitle}
              recordCreateButton={recordCreateButton}
              hideSearchBar={hideSearchBar}
              searchRecords={searchRecords}
              fetchError={fetchError}
              refreshButton={refreshButton}
              resourceName={resourceName}
            />
          </Col>
        </Row>

        {(!fetchError || showHeader) && additionalHeaderContent}
      </div>

      {/* eslint-disable react/no-children-prop */}
      <TableContent
        fetchError={fetchError}
        onRetry={onRetry}
        children={children}
        records={records}
        handleRowClick={handleRowClick}
        rowClassName={rowClassName}
        createResourceUrl={createResourceUrl}
        isArchivedResource={isArchivedResource}
        hideAddButton={hideAddButton}
        showHeader={showHeader}
        defaultClassName={defaultClassName}
      />
    </div>
  );
};

TableContent.defaultProps = {
  records: undefined,
  fetchError: null,
  onRetry: () => {},
  rowClassName: "",
  handleRowClick: () => {},
  isArchivedResource: false,
  hideAddButton: false,
  defaultClassName:
    "record-listing-table-container w-100 d-flex flex-column shadow-sm mb-3 h-30em",
};

TableContent.propTypes = {
  fetchError: PropTypes.string,
  onRetry: PropTypes.func,
  records: PropTypes.arrayOf({}),
  children: PropTypes.node.isRequired,
  handleRowClick: PropTypes.func,
  rowClassName: PropTypes.string,
  createResourceUrl: PropTypes.string.isRequired,
  isArchivedResource: PropTypes.bool,
  hideAddButton: PropTypes.bool,
  showHeader: PropTypes.bool.isRequired,
  defaultClassName: PropTypes.string,
};

RecordListingTable.defaultProps = {
  className: "",
  isArchivedResource: false,
  records: undefined,
  totalDeactivated: undefined,
  parentID: undefined,
  redirectToEdit: false,
  isSearching: false,
  showHeader: false,
  additionalHeaderContent: null,
  searchRecords: () => {},
  hideSearchBar: false,
  hideAddButton: false,
  fetchError: null,
  onRetry: () => {},
  recordDetailIDAttribute: undefined,
  disableRowClick: false,
  rowClassName: "",
  refreshRecords: undefined,
  dataCy: "",
};

RecordListingTable.propTypes = {
  records: PropTypes.arrayOf({}),
  children: PropTypes.node.isRequired,
  resourceName: PropTypes.string.isRequired,
  resourceTitle: PropTypes.node.isRequired,
  additionalHeaderContent: PropTypes.node,
  totalDeactivated: PropTypes.number,
  searchRecords: PropTypes.func,
  className: PropTypes.string,
  isArchivedResource: PropTypes.bool,
  parentID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  redirectToEdit: PropTypes.bool,
  isSearching: PropTypes.bool,
  showHeader: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  hideSearchBar: PropTypes.bool,
  hideAddButton: PropTypes.bool,
  fetchError: PropTypes.string,
  onRetry: PropTypes.func,
  recordDetailIDAttribute: PropTypes.string,
  disableRowClick: PropTypes.bool,
  rowClassName: PropTypes.string,
  isRefreshing: PropTypes.bool.isRequired,
  refreshRecords: PropTypes.func,
  dataCy: PropTypes.string,
};

export default RecordListingTable;
