import React, { useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { FormGroup, Row, Col } from "reactstrap";
import { Field, useForm, useFormState } from "react-final-form";
import PropTypes from "prop-types";

import { pbxFetchRequest } from "redux/actions/pbx";
import {
  fetchDebtorLanguageRequest,
  debtorFetchRequest,
} from "redux/actions/debtor";
import { userLicenseFetchRequest } from "redux/actions/userLicense";

import {
  required,
  email,
  phoneNumber,
  composeValidators,
} from "utils/validation";

import { isAnAdminUser } from "utils/userRole";

import {
  renderInputField,
  renderDropdownField,
} from "components/ReduxForm/RenderField";
import PasswordField from "components/ReduxForm/PasswordField/PasswordField";
import LanguageField, { FIELD_TYPE } from "components/User/Form/LanguageField";

import ROLE_ID from "constants/roleID";

const RenderPasswordField = ({ isAdminUser, userID }) => {
  if (!isAdminUser && !userID) return null;

  const validations = !userID ? required : () => {};

  return (
    <Col md="6">
      <FormGroup>
        <Field
          name="password"
          placeholderTranslation="attributes.password"
          component={PasswordField}
          validate={validations}
          labelTranslation="attributes.password"
        />
      </FormGroup>
    </Col>
  );
};

const UserFormContent = ({ userID, initialValues }) => {
  const form = useForm();
  const dispatch = useDispatch();

  const {
    values: {
      role_id: roleID,
      odoo_debtor_id: odooDebtorId,
      email: userEmail,
      pbx_id: pbxId,
    },
    dirtyFields: {
      locale: isLanguageDirty,
      pbx_id: isPbxDirty,
      odoo_debtor_id: isDebtorDirty,
    },
  } = useFormState();

  const { roles, isLoading: isRolesLoading } = useSelector(({ role }) => role);
  const { debtors, isLoading: isDebtorsLoading } = useSelector(
    ({ debtor }) => debtor
  );
  const { language: debtorLanguage, isProcessing: isDebtorLanguageLoading } =
    useSelector(({ debtor }) => debtor);
  const { pbxs, isLoading: isPbxsLoading } = useSelector(({ pbx }) => pbx);
  const { userLicenses, isLoading: isUserLicenseLoading } = useSelector(
    ({ userLicense }) => userLicense
  );
  const { currentUser } = useSelector(({ auth }) => auth);
  const {
    currentUser: { email: loggedInUserEmail, role: loggedInUserRole },
  } = useSelector(({ auth }) => auth);

  const isAdminUser = isAnAdminUser(loggedInUserRole);

  const isPbxRoles =
    roleID === ROLE_ID.PBX_USER || roleID === ROLE_ID.PBX_ADMIN;

  const fetchDebtors = () => dispatch(debtorFetchRequest());

  const fetchPbx = () => {
    dispatch(pbxFetchRequest(null, true));
  };

  const fetchAllUserLicense = () => dispatch(userLicenseFetchRequest());

  const fetchDebtorLanguage = (debtorID) =>
    dispatch(fetchDebtorLanguageRequest(debtorID));

  useEffect(() => {
    if (odooDebtorId && isPbxRoles) {
      fetchPbx();
      fetchAllUserLicense();
    }
  }, [roleID, odooDebtorId]);

  const isLogggedInUser = loggedInUserEmail === userEmail;
  const isBusinessRoles = roleID > ROLE_ID.ADMIN;

  const dropdownRoles =
    roles.length > 0
      ? roles.map((role) => ({
          id: role.id,
          value: role.id,
          label: role.name,
        }))
      : [];

  const dropdownDebtors =
    debtors?.length > 0
      ? debtors.map((debtor) => ({
          id: debtor.id,
          value: debtor.id,
          label: debtor.name,
        }))
      : [];

  const dropdownPbx =
    pbxs?.length > 0
      ? pbxs.map((pbx) => ({
          id: pbx.id,
          value: pbx.id,
          debtorId: pbx.odoo_debtor_id,
          label: pbx.name,
        }))
      : [];

  const filteredDropdownPbx = dropdownPbx.filter(
    (pbx) => pbx.debtorId === odooDebtorId
  );

  const dropdownUserLicense =
    filteredDropdownPbx.length > 0 && userLicenses.length > 0
      ? userLicenses.map((userLicense) => ({
          id: userLicense.id,
          pbxId: userLicense.pbx_id,
          value: userLicense.id,
          label: userLicense.extension_number,
        }))
      : [];

  const filteredUserLicense = dropdownUserLicense.filter(
    (userLicense) => userLicense.pbxId === pbxId
  );

  const showLanguageField =
    odooDebtorId && roleID > ROLE_ID.RESELLER_ADMIN && !isLogggedInUser;

  useEffect(() => {
    if (isDebtorDirty) {
      form.change("pbx_id", null);
    }
  }, [odooDebtorId]);

  useEffect(() => {
    if (odooDebtorId && showLanguageField && !isLanguageDirty) {
      fetchDebtorLanguage(odooDebtorId);
    }
  }, [odooDebtorId]);

  useEffect(() => {
    if (debtorLanguage) {
      form.change("locale", debtorLanguage);
    }
  }, [debtorLanguage]);

  useEffect(() => {
    if (isBusinessRoles && !debtors.length) {
      fetchDebtors();
    }
  }, [roleID]);

  useEffect(() => {
    if (isPbxDirty || isDebtorDirty) {
      form.change("user_license_id", null);
    }
  }, [pbxId, odooDebtorId]);

  return (
    <div className="flexo-form-content">
      <Row>
        {currentUser.id !== userID && (
          <Col md="6">
            <FormGroup>
              <Field
                name="role_id"
                component={renderDropdownField}
                validate={required}
                dropdownOptions={dropdownRoles}
                isLoading={isRolesLoading}
                placeholderTranslation="attributes.role"
                disabled={userID && initialValues.role === currentUser.role}
                labelTranslation="attributes.role"
              />
            </FormGroup>
          </Col>
        )}
        {isBusinessRoles ? (
          <Col md="6">
            <FormGroup>
              <Field
                name="odoo_debtor_id"
                component={renderDropdownField}
                validate={required}
                dropdownOptions={dropdownDebtors}
                isLoading={isDebtorsLoading}
                labelTranslation="attributes.affiliation"
              />
            </FormGroup>
          </Col>
        ) : null}
      </Row>
      {isPbxRoles && (
        <Row className="user-setting">
          <Col md="6">
            <FormGroup>
              <Field
                name="pbx_id"
                component={renderDropdownField}
                validate={required}
                dropdownOptions={filteredDropdownPbx}
                isLoading={isPbxsLoading}
                disabled={!odooDebtorId}
                labelTranslation="attributes.pbx"
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Field
                name="user_license_id"
                component={renderDropdownField}
                validate={required}
                dropdownOptions={filteredUserLicense}
                isLoading={isUserLicenseLoading}
                disabled={!pbxId}
                labelTranslation="attributes.userLicense"
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row className="user-setting">
        <Col md="6">
          <FormGroup>
            <Field
              name="first_name"
              type="text"
              placeholderTranslation="attributes.firstName"
              component={renderInputField}
              validate={required}
              labelTranslation="attributes.firstName"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Field
              name="last_name"
              type="text"
              placeholderTranslation="attributes.lastName"
              component={renderInputField}
              validate={required}
              labelTranslation="attributes.lastName"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Field
              name="email"
              type="text"
              placeholderTranslation="attributes.email"
              component={renderInputField}
              validate={composeValidators(required, email)}
              labelTranslation="attributes.email"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Field
              name="phone"
              type="text"
              placeholderTranslation="attributes.phone"
              component={renderInputField}
              labelTranslation="attributes.phone"
              validate={phoneNumber}
            />
          </FormGroup>
        </Col>
        <RenderPasswordField
          isAdminUser={isAdminUser}
          currentUser={currentUser}
          userID={userID}
        />
        <LanguageField
          show={currentUser.id !== userID}
          fieldType={FIELD_TYPE.FINAL_FORM}
          isDebtorLanguageLoading={isDebtorLanguageLoading}
        />
      </Row>
    </div>
  );
};

RenderPasswordField.defaultProps = {};

RenderPasswordField.propTypes = {
  isAdminUser: PropTypes.bool.isRequired,
  userID: PropTypes.number.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

UserFormContent.defaultProps = {
  initialValues: {},
};

UserFormContent.propTypes = {
  initialValues: PropTypes.shape(),
  userID: PropTypes.number.isRequired,
};

export default connect()(UserFormContent);
