import React from "react";
import { FormGroup, Row, Col } from "reactstrap";
import { Field } from "react-final-form";
import { Translate } from "react-i18nify";

import { required } from "utils/validation";

import { pbxBillingGroupOptions } from "constants/dropdownOptions";

import { renderDropdownField } from "components/ReduxForm/RenderField";
import ToggleButtonField from "components/ReduxForm/ToggleButtonField";

const BillingSettingSection = () => (
  <Row>
    <Col md="12">
      <h4 className="mt-3 mb-3">
        <Translate value="title.section.billingSettings" />
      </h4>
      <hr />
    </Col>

    <Col md="12">
      <Row>
        <Col md={6}>
          <FormGroup>
            <Field
              name="pbx_billing_setting_attributes.billing_group"
              component={renderDropdownField}
              validate={required}
              dropdownOptions={pbxBillingGroupOptions}
              placeholderTranslation="attributes.billingGroup"
              labelTranslation="attributes.billingGroup"
            />
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Field
              name="pbx_billing_setting_attributes.is_voip_billing_enabled"
              component={ToggleButtonField}
              labelTranslation="pbx.billVoipUsages"
              className="d-block"
            />
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Field
              name="pbx_billing_setting_attributes.is_cell_phone_billing_enabled"
              component={ToggleButtonField}
              labelTranslation="attributes.billCellPhoneUsages"
              className="d-block"
            />
          </FormGroup>
        </Col>
      </Row>
    </Col>
  </Row>
);

export default BillingSettingSection;
