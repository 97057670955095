import React from "react";
import PropTypes from "prop-types";

import ResourceHeader from "components/ResourceHeader/ResourceHeader";
import TotalResourceCount from "components/ResourceHeader/TotalResourceCount";
import { AGREEMENTS_FILTERS } from "constants/agreements";

const AgreementStatus = ({
  resource,
  selectedFilter,
  totalAgreement,
  changeFilter,
  totalAmount,
}) => {
  const allFilters = [
    {
      filter: AGREEMENTS_FILTERS,
      selectedFilter,
      type: "filter",
      headerTranslation: "common.filters",
    },
  ];

  return (
    <ResourceHeader
      allFilters={allFilters}
      changeFilter={changeFilter}
      resourceStatusComponent={
        <TotalResourceCount
          totalResource={totalAgreement}
          totalAmount={totalAmount}
          resource={resource}
        />
      }
    />
  );
};

AgreementStatus.defaultProps = {
  selectedFilter: undefined,
  resource: undefined,
  totalAgreement: 0,
  totalAmount: 0,
  // isLoading: true,
  changeFilter: () => {},
};

AgreementStatus.propTypes = {
  selectedFilter: PropTypes.string,
  resource: PropTypes.string,
  totalAgreement: PropTypes.number,
  totalAmount: PropTypes.number,
  changeFilter: PropTypes.func,
};

export default AgreementStatus;
