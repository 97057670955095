import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Outlet, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import { singleUserLicenseFetchRequest } from "redux/actions/userLicense";

import CellPhoneIndex from "views/UserLicense/CellPhone/CellPhoneIndex";
import DidNumberIndex from "views/UserLicense/DidNumber/DidNumberIndex";
import SipDeviceIndex from "views/UserLicense/SipDevice/SipDeviceIndex";

import PbxDetailSkeleton from "components/Skeleton/ResourceDetail/PbxDetail/PbxDetailSkeleton";
import UserLicenseBasicDetail from "components/UserLicense/Detail/UserLicenseBasicDetail";
import TabWithRoute from "components/Tab/TabWithRoute/TabWithRoute";
import UserLicenseAdditionalDetail from "components/UserLicense/Detail/UserLicenseAdditionalDetail";
import FetchError from "components/Error/FetchError";

import { isAnAdminUser } from "utils/userRole";

import { RESOURCE_NAMES } from "constants/constant";
import {
  CELL_PHONES,
  DETAILS,
  DID_NUMBERS,
  SIP_DEVICES,
} from "constants/routes/routesResources";

const UserLicenseDetail = ({
  userLicense,
  fetchSingleUserLicense,
  fetchError,
  currentlyLoggedInUser,
}) => {
  const location = useLocation();
  const { userLicenseID } = useParams();

  const fetchUserLicenseData = () => {
    fetchSingleUserLicense(userLicenseID);
  };

  useEffect(() => {
    fetchUserLicenseData();
  }, [userLicenseID]);

  if (fetchError) {
    return <FetchError onRetry={fetchUserLicenseData} />;
  }

  if (
    !userLicense ||
    (userLicense && userLicense.id !== parseInt(userLicenseID, 10))
  ) {
    return <PbxDetailSkeleton />;
  }

  const {
    user: { odoo_debtor_id: odooDebtorID },
  } = userLicense;
  const { role: userRole } = currentlyLoggedInUser;

  const isAdminUser = isAnAdminUser(userRole);

  const getSharedRouteComponent = () => {
    const userLicenseDetailsRoute = `/user_licenses/${userLicenseID}`;

    if (location.pathname.includes(`${userLicenseDetailsRoute}/details`))
      return <UserLicenseAdditionalDetail userLicense={userLicense} />;

    if (location.pathname.includes(`${userLicenseDetailsRoute}/cell_phones`))
      return (
        <CellPhoneIndex
          parentActiveStatus={userLicense.is_active}
          parentID={userLicenseID}
        />
      );

    if (location.pathname.includes(`${userLicenseDetailsRoute}/did_numbers`))
      return (
        <DidNumberIndex
          parentActiveStatus={userLicense.is_active && isAdminUser}
          parentID={userLicenseID}
        />
      );

    if (location.pathname.includes(`${userLicenseDetailsRoute}/sip_devices`))
      return (
        <SipDeviceIndex
          parentActiveStatus={userLicense.is_active}
          parentID={userLicenseID}
          parentResource="userLicense"
        />
      );

    return <Outlet />;
  };

  const tabRoutesDetail = [
    {
      tabRoute: DETAILS,
      tabTitle: <Translate value="common.additionalDetails" />,
    },
    {
      tabRoute: CELL_PHONES,
      tabTitle: <Translate value="title.tab.userLicense.cellPhones" />,
      data: { debtorID: odooDebtorID },
    },
    {
      tabRoute: DID_NUMBERS,
      tabTitle: <Translate value="title.tab.userLicense.didNumbers" />,
      data: { debtorID: odooDebtorID },
      perform: "userLicense:didNumbers:list",
    },
    {
      tabRoute: SIP_DEVICES,
      tabTitle: <Translate value="title.page.desktopPhones" />,
    },
  ];

  return (
    <div className="userLicense-detail w-100">
      <UserLicenseBasicDetail userLicense={userLicense} />

      <TabWithRoute
        resourceName={RESOURCE_NAMES.USER_LICENSE}
        routesDetail={tabRoutesDetail}
      >
        {getSharedRouteComponent()}
      </TabWithRoute>
    </div>
  );
};

UserLicenseDetail.defaultProps = {
  fetchError: null,
};

UserLicenseDetail.propTypes = {
  userLicense: PropTypes.shape({
    id: PropTypes.number,
    is_active: PropTypes.bool,
    user: PropTypes.shape({
      odoo_debtor_id: PropTypes.number,
    }),
  }).isRequired,
  fetchSingleUserLicense: PropTypes.func.isRequired,
  fetchError: PropTypes.string,
  currentlyLoggedInUser: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = ({ userLicense: userLicenseResource, auth }) => {
  const { userLicense, fetchError } = userLicenseResource;
  const { currentUser: currentlyLoggedInUser } = auth;

  return {
    userLicense,
    fetchError,
    currentlyLoggedInUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleUserLicense: (id) => {
    dispatch(singleUserLicenseFetchRequest(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserLicenseDetail);
