import displayUnMappedFormErrors from "utils/displayUnMappedFormErrors";

const formatAsyncValidationErrors = (submissionErrors) => {
  if (!submissionErrors) return null;

  const formValidationErrors = {};

  Object.keys(submissionErrors).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(submissionErrors, key)) {
      const [objKey, subKey] = key.split(".");
      const message = submissionErrors[key][0];

      if (subKey) {
        if (
          !Object.prototype.hasOwnProperty.call(formValidationErrors, objKey)
        ) {
          formValidationErrors[objKey] = {};
        }

        formValidationErrors[objKey][subKey] = message;
      } else {
        formValidationErrors[key] = message;
      }
    }
  });

  if (Object.keys(formValidationErrors).length > 0) {
    return formValidationErrors;
  }

  return null;
};

export const processServerResponseForAsyncValidationErrors = (
  serverResponse,
  registeredFields
) => {
  if (!serverResponse) return null;

  const { data: submissionError } = serverResponse;

  if (!submissionError) return null;

  displayUnMappedFormErrors(serverResponse, registeredFields);

  return formatAsyncValidationErrors(submissionError);
};

export default formatAsyncValidationErrors;
