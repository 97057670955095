import iconInfo from "assets/img/icon-info.svg";
import settingsTransfer from "assets/img/settings-transfer.svg";
import forbiddenBanner from "assets/img/forbidden-banner.svg";

const ICON_INFO = {
  SOURCE: iconInfo,
  SETTINGS_TRANSFER: settingsTransfer,
};

const FORBIDDEN_BANNER = {
  SOURCE: forbiddenBanner,
};

// eslint-disable-next-line import/prefer-default-export
export { ICON_INFO, FORBIDDEN_BANNER };
