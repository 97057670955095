import { DashboardApi } from "internal";
import { getAndDisplayErrors } from "utils/utils";

export const DASHBOARD_USER_FETCH_REQUEST = "DASHBOARD_USER_FETCH_REQUEST";
export const DASHBOARD_USER_FETCH_RECEIVED = "DASHBOARD_USER_FETCH_RECEIVED";
export const DASHBOARD_USER_FETCH_FAILED = "DASHBOARD_USER_FETCH_FAILED";
export const DASHBOARD_USER_FETCH_CLEAR_ERRORS =
  "DASHBOARD_USER_FETCH_CLEAR_ERRORS";

export function dashboardUserFetchReceived(response) {
  return {
    type: DASHBOARD_USER_FETCH_RECEIVED,
    data: response.data,
  };
}

export function dashboardUserFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: DASHBOARD_USER_FETCH_FAILED,
    message,
  };
}

export function dashboardUserFetchClearErrors() {
  return {
    type: DASHBOARD_USER_FETCH_CLEAR_ERRORS,
  };
}

export const dashboardUserFetchRequest = () => (dispatch) => {
  dispatch({ type: DASHBOARD_USER_FETCH_REQUEST });

  DashboardApi.fetchUserDasboardDetail()
    .then((data) => dispatch(dashboardUserFetchReceived(data)))
    .catch((e) => {
      dispatch(dashboardUserFetchFailed(e));
    });
};
