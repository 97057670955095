import React from "react";
import { Route, Routes } from "react-router-dom";

import { Admins } from "constants/userRoles";

import PhoneNumberIndex from "views/PhoneNumber/PhoneNumberIndex";
import PhoneNumberCreate from "views/PhoneNumber/PhoneNumberCreate";
import PhoneNumberDetail from "views/PhoneNumber/PhoneNumberDetail";
import PhoneNumberEdit from "views/PhoneNumber/PhoneNumberEdit";

import SimCardIndex from "views/SimCard/SimCardIndex";
import SimCardDetail from "views/SimCard/SimCardDetail";
import SimCardEdit from "views/SimCard/SimCardEdit";

import {
  EDIT,
  NEW,
  PHONE_NUMBER,
  SIM_CARD,
} from "constants/routes/routesResources";

const AdminPhoneNumberIndex = Admins(PhoneNumberIndex);
const AdminPhoneNumberCreate = Admins(PhoneNumberCreate);
const AdminPhoneNumberDetail = Admins(PhoneNumberDetail);
const AdminPhoneNumberEdit = Admins(PhoneNumberEdit);

const AdminSimCardIndex = Admins(SimCardIndex);
const AdminSimCardDetail = Admins(SimCardDetail);
const AdminSimCardEdit = Admins(SimCardEdit);

const PhoneNumberRoutes = () => (
  <Routes>
    <Route index element={<AdminPhoneNumberIndex />} />
    <Route path={NEW} element={<AdminPhoneNumberCreate />} />
    <Route path={PHONE_NUMBER.PHONE_NUMBER_ID}>
      <Route index element={<AdminPhoneNumberDetail />} />
      <Route path={EDIT} element={<AdminPhoneNumberEdit />} />
    </Route>
  </Routes>
);

const SimCardRoutes = () => (
  <Routes>
    <Route index element={<AdminSimCardIndex />} />

    <Route path={SIM_CARD.SIM_CARD_ID}>
      <Route index element={<AdminSimCardDetail />} />
      <Route path={EDIT} element={<AdminSimCardEdit />} />
    </Route>
  </Routes>
);

export { PhoneNumberRoutes, SimCardRoutes };
