function capitalizeWords(word, separator = "_") {
  return word
    .toLowerCase()
    .split(separator)
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
}

// Convert any string to camelCase
// WARNING: Doesn't work if string is already camelized, it return lower cased string in that case
function camelize(string) {
  return string
    .toLowerCase()
    .replace(/[-_ ](.)/g, (_match, group) => group.toUpperCase())
    .replace(/[^a-zA-Z0-9 ]/g, "");
}

function replaceMultiple(str, mapObj) {
  const re = new RegExp(Object.keys(mapObj).join("|"), "gi");

  return str.replace(re, (matched) => mapObj[matched]);
}

export { capitalizeWords, camelize, replaceMultiple };
