import React from "react";
import PropTypes from "prop-types";
import { I18n, t } from "react-i18nify";
import { Spinner } from "reactstrap";
import Select from "react-select";

import { formatSetupTypeDropdownOptions } from "utils/utils";

const ProductSelectionSection = ({
  products,
  isLoading,
  handleProductSelection,
}) => {
  const productSelectionDropdown = isLoading ? (
    <div className="text-center">
      <Spinner size="sm" />
    </div>
  ) : (
    <I18n
      render={() => (
        <Select
          options={formatSetupTypeDropdownOptions(products)}
          styles={{
            loadingIndicator: (provided) => ({
              ...provided,
              color: "black",
            }),
            control: (provided) => ({
              ...provided,
              padding: "0.7em",
            }),
          }}
          onChange={(options) => {
            let dropdownValue;

            if (Array.isArray(options)) {
              dropdownValue = options.map((opt) => opt.value);
            } else {
              dropdownValue = options?.value;
            }

            handleProductSelection(dropdownValue);
          }}
          value={null}
          placeholder={t("quotation.chooseProduct")}
        />
      )}
    />
  );

  return (
    <div className="add-on-subscription-field ms-2 fw-normal">
      {productSelectionDropdown}
    </div>
  );
};

ProductSelectionSection.defaultProps = {};

ProductSelectionSection.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleProductSelection: PropTypes.func.isRequired,
};

export default ProductSelectionSection;
