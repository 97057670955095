import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";
import { Spinner, FormGroup, Input, Label } from "reactstrap";
import { Navigate } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Translate, t } from "react-i18nify";

import { DebtorApi } from "internal";

import { debtorCreateRequest } from "redux/actions/debtor";
import { debtorTypeFetchRequest } from "redux/actions/debtorType";
import { countryFetchRequest } from "redux/actions/country";
import { paymentTermFetchRequest } from "redux/actions/paymentTerm";

import { formatDropdownOptions } from "utils/utils";
import { processServerResponseForAsyncValidationErrors } from "utils/forms";

import DebtorForm from "components/Debtor/Form/DebtorForm";

import { DEBTOR } from "constants/routes";
import WholePageSpinner from "components/Spinner/WholePageSpinner";

class DebtorCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cvrSearchField: "",
      error: "",
      cvrDebtor: {},
      initialView: true,
      cvrFetch: false,
      dropdownID: "",
    };

    this.changeValue = this.changeValue.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEnterPress = this.handleEnterPress.bind(this);
    this.fetchDebtor = this.fetchDebtor.bind(this);
    this.displayCreateForm = this.displayCreateForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { fetchDebtorTypes, fetchCountries, fetchPaymentTerms } = this.props;

    fetchDebtorTypes();
    fetchCountries();
    fetchPaymentTerms();
  }

  componentDidUpdate(prevProps) {
    const { debtorTypes, isDebtorTypeLoading } = this.props;

    if (!isDebtorTypeLoading && debtorTypes !== prevProps.debtorTypes) {
      const defaultDebtorType = debtorTypes.find(({ name }) =>
        ["DK Business", "DK Erhverv"].includes(name)
      );
      const defaultDebtorOption = {
        value: defaultDebtorType?.id,
        label: defaultDebtorType?.name,
      };

      this.changeValue(defaultDebtorOption);
    }
  }

  async handleSubmit(values, form) {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    const { createDebtor } = this.props;

    await createDebtor(values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  }

  handleInputChange(event) {
    this.setState({ cvrSearchField: event.target.value });
  }

  handleEnterPress(event) {
    if (event.key === "Enter") {
      this.fetchDebtor();
    }

    return null;
  }

  fetchDebtor() {
    const { cvrSearchField } = this.state;

    if (cvrSearchField.length === 0) {
      this.setState({
        error: <Translate value="validationErrors.BLANK" />,
      });

      return null;
    }

    this.setState({ cvrFetch: true });

    DebtorApi.fetchByCVR(cvrSearchField)
      .then((res) => {
        this.setState({
          cvrDebtor: res.data[0],
          initialView: false,
          cvrFetch: false,
        });
      })
      .catch((err) => {
        this.setState({
          error: err.response.statusText,
          cvrFetch: false,
        });
      });

    return null;
  }

  changeValue({ value, label }) {
    this.setState({ dropdownValue: label, dropdownID: value, error: "" });
  }

  displayCreateForm() {
    const { dropdownValue, dropdownID } = this.state;

    if (dropdownID === "") {
      this.setState({
        error: <Translate value="validationErrors.BLANK" />,
      });
    }

    const excludesDanishBusiness = !["DK Business", "DK Erhverv"].includes(
      dropdownValue
    );

    if (excludesDanishBusiness && dropdownID !== "") {
      this.setState({ initialView: false });
    }
  }

  render() {
    const {
      debtorTypes,
      isDebtorTypeLoading,
      countries,
      paymentTerms,
      commitSuccess,
      commitError,
      isLoading,
      debtor,
      isCountryFetching,
      isPaymentTermFetching,
    } = this.props;
    const {
      dropdownValue,
      error,
      initialView,
      cvrDebtor,
      dropdownID,
      cvrFetch,
    } = this.state;

    if (
      isLoading ||
      isDebtorTypeLoading ||
      isCountryFetching ||
      isPaymentTermFetching
    ) {
      return <WholePageSpinner />;
    }

    const findPaymentTermID = (paymentTermsArray) => {
      const paymentTermValues = ["8 Days", "8 dage", "8dg"];

      const paymentTerm = paymentTermsArray.find(({ name }) =>
        paymentTermValues.includes(name)
      );

      const { id: paymentTermID } = paymentTerm;

      return paymentTermID;
    };

    const findCountryID = (countriesArray) => {
      const country = countriesArray.find(({ name }) => name === "Denmark");

      const { id: countryID } = country;

      return countryID;
    };

    const errorField =
      error.length === 0 ? null : <p className="text-danger">{error}</p>;

    const vatField = ["DK Business", "DK Erhverv"].includes(dropdownValue) ? (
      <div>
        <FormGroup>
          <Label for="vat">
            <Translate value="attributes.cvrSearchField" />
          </Label>
          <Input
            name="vat"
            onChange={this.handleInputChange}
            onKeyPress={this.handleEnterPress}
            placeholder="Vat Number or Company Name"
            data-field-name="vat"
          />
          {errorField}
        </FormGroup>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            onClick={this.fetchDebtor}
            className="btn btn-primary"
            disabled={cvrFetch}
            data-cy="form-next-btn"
          >
            {cvrFetch ? <Spinner as="span" size="sm" className="me-2" /> : null}
            <Translate value="common.next" />
          </button>
        </div>
      </div>
    ) : (
      <div>
        <div className="text-start">{errorField}</div>

        <div className="text-end">
          <button
            type="button"
            onClick={this.displayCreateForm}
            className="btn btn-primary"
            disabled={isDebtorTypeLoading}
            data-cy="form-next-btn"
          >
            <Translate value="common.next" />
          </button>
        </div>
      </div>
    );

    if (commitSuccess) {
      const { id } = debtor;
      const debtorTranslation = t("attributes.debtor");

      toastr.success(
        t("common.success"),
        t("toasterMessage.resource.create", {
          resource: debtorTranslation,
        })
      );

      return <Navigate to={DEBTOR.addId(DEBTOR.DETAIL, id)} />;
    }

    if (commitError.length > 0) {
      toastr.error("Error", commitError);
    }

    if (initialView) {
      const defaultDebtorType = debtorTypes.find(({ name }) =>
        ["DK Business", "DK Erhverv"].includes(name)
      );
      const defaultDebtorOption = {
        value: defaultDebtorType?.id,
        label: defaultDebtorType?.name,
      };

      return (
        <div className="container">
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div className="w-25">
              <FormGroup className="mb-3">
                <Label for="debtor_type">
                  <Translate value="attributes.debtorType" />
                </Label>
                <Select
                  id="debtor_type"
                  options={formatDropdownOptions(debtorTypes)}
                  onChange={this.changeValue}
                  name="debtor_type"
                  isLoading={isDebtorTypeLoading}
                  defaultValue={defaultDebtorOption}
                />
              </FormGroup>
              {vatField}
            </div>
          </div>
        </div>
      );
    }

    const initialValues =
      Object.entries(cvrDebtor).length === 0
        ? { property_account_position_id: dropdownID }
        : cvrDebtor;

    return (
      <div className="container-fluid">
        <div>
          <h3 className="mt-3">
            <Translate value="title.page.resource.create" resource="Debtor" />
          </h3>
        </div>
        <DebtorForm
          initialValues={{
            ...initialValues,
            property_account_position_id: dropdownID,
            property_payment_term_id: findPaymentTermID(paymentTerms),
            country_id: findCountryID(countries),
            lang: "da_DK",
            customer: true,
          }}
          onSubmit={this.handleSubmit}
          formName="debtorForm"
          debtorTypes={formatDropdownOptions(debtorTypes)}
          countries={formatDropdownOptions(countries)}
          paymentTerms={formatDropdownOptions(paymentTerms)}
          formType="create"
          isLoading={isLoading}
          isDebtorTypeLoading={isDebtorTypeLoading}
          isCountryFetching={isCountryFetching}
          isPaymentTermFetching={isPaymentTermFetching}
        />
      </div>
    );
  }
}

DebtorCreate.defaultProps = {
  isDebtorTypeLoading: true,
  debtor: undefined,
  isCountryFetching: false,
  isPaymentTermFetching: false,
  commitError: "",
};

DebtorCreate.propTypes = {
  createDebtor: PropTypes.func.isRequired,
  debtorTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isDebtorTypeLoading: PropTypes.bool,
  commitError: PropTypes.string,
  commitSuccess: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  paymentTerms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fetchDebtorTypes: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  fetchPaymentTerms: PropTypes.func.isRequired,
  debtor: PropTypes.shape({
    id: PropTypes.number,
  }),
  isCountryFetching: PropTypes.bool,
  isPaymentTermFetching: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { debtor, isLoading, commitSuccess, commitError } = state.debtor;
  const { debtorTypes, isLoading: isDebtorTypeLoading } = state.debtorType;
  const { countries, isLoading: isCountryFetching } = state.country;
  const { paymentTerms, isLoading: isPaymentTermFetching } = state.paymentTerm;

  return {
    debtorTypes,
    isLoading,
    commitSuccess,
    commitError,
    countries,
    isCountryFetching,
    paymentTerms,
    isPaymentTermFetching,
    isDebtorTypeLoading,
    debtor,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchDebtorTypes: () => {
    dispatch(debtorTypeFetchRequest());
  },
  fetchCountries: () => {
    dispatch(countryFetchRequest());
  },
  fetchPaymentTerms: () => {
    dispatch(paymentTermFetchRequest());
  },
  createDebtor: async (attributes, callback) => {
    await dispatch(debtorCreateRequest(attributes, callback));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DebtorCreate);
