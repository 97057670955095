import React from "react";
import { Field } from "react-final-form";
import { UncontrolledTooltip } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import { Translate } from "react-i18nify";

import renderInputField from "components/ReduxForm/InputField/InputField";

const renderOptions = ({ fields, placeholder, addFieldText, validation }) => {
  let validateOptionsField = null;

  if (Array.isArray(validation)) {
    validateOptionsField = (value) =>
      validation.map((validator) => validator(value));
  } else {
    validateOptionsField = validation;
  }

  return (
    <ul className="ml-0">
      {fields.map((option, index) => (
        <li key={option}>
          <div className="position-relative">
            <Field
              name={option}
              type="text"
              component={renderInputField}
              placeholder={placeholder}
              validate={validateOptionsField}
            />
            <button
              type="button"
              className="btn option-delete"
              onClick={() => fields.remove(index)}
            >
              <FontAwesomeIcon icon={faTrash} id="trashToolTip" />
              <UncontrolledTooltip placement="top" target="trashToolTip">
                <Translate value="common.delete" />
              </UncontrolledTooltip>
            </button>
          </div>
        </li>
      ))}
      <li hidden={fields.length === 0}>
        <hr />
      </li>
      <li>
        <button
          data-cy="add-btn"
          type="button"
          className="btn btn-add"
          onClick={() => fields.push()}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          {addFieldText}
        </button>
      </li>
    </ul>
  );
};

renderOptions.defaultProps = {
  placeholder: "Option",
};

renderOptions.propTypes = {
  fields: PropTypes.shape({
    push: PropTypes.func,
    remove: PropTypes.func,
    map: PropTypes.func,
    length: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    submitFailed: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  placeholder: PropTypes.string,
  addFieldText: PropTypes.string.isRequired,
};

export default renderOptions;
