import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import SimCardBasicDetail from "components/SimCard/Detail/SimCardBasicDetail";
import DetailUIHeader from "components/Header/DetailUI/DetailUIHeader";
import { simCardDetailRequest } from "redux/actions/simCard";
import FetchError from "components/Error/FetchError";
import SimCardDetailSkeleton from "components/Skeleton/ResourceDetail/SimCardDetail/SimCardDetail";

const SimCardDetail = ({
  simCard,
  fetchError,
  isLoading,
  fetchSimCardDetail,
}) => {
  const { simCardID } = useParams();

  const fetchSimCardData = () => {
    fetchSimCardDetail(simCardID);
  };

  useEffect(() => {
    fetchSimCardData();
  }, [simCardID]);

  if (fetchError) {
    return <FetchError onRetry={fetchSimCardData} />;
  }

  if (isLoading || simCard?.id !== parseInt(simCardID, 10)) {
    return <SimCardDetailSkeleton />;
  }

  return (
    <div className="sim-card-detail">
      <DetailUIHeader headerTitle={simCard.iccid_number} />
      <SimCardBasicDetail simCard={simCard} />
    </div>
  );
};

SimCardDetail.defaultProps = {
  simCard: {},
  fetchError: null,
  isLoading: false,
};

SimCardDetail.propTypes = {
  simCard: PropTypes.shape({
    id: PropTypes.number,
    iccid_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  fetchError: PropTypes.string,
  isLoading: PropTypes.bool,
  fetchSimCardDetail: PropTypes.func.isRequired,
};

const mapStateToProps = ({ simCard: simCardState }) => {
  const { simCard, fetchError, isLoading } = simCardState;

  return {
    simCard,
    fetchError,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSimCardDetail: (id) => {
    dispatch(simCardDetailRequest(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SimCardDetail);
