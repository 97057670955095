import {
  CONTACT_FETCH_REQUEST,
  CONTACT_FETCH_RECEIVED,
  CONTACT_FETCH_FAILED,
  CONTACT_FETCH_CLEAR_ERRORS,
  CONTACT_UPDATE_REQUEST,
  CONTACT_UPDATE_RECEIVED,
  CONTACT_UPDATE_FAILED,
  CONTACT_SEARCH_REQUEST,
  CONTACT_TELECOM_USER_EMAILS_FETCH_REQUEST,
  CONTACT_TELECOM_USER_EMAILS_FETCH_RECEIVED,
  CONTACT_TELECOM_USER_EMAILS_FETCH_FAILED,
} from "redux/actions/odooEngine/contact";

const defaultState = {
  contacts: [],
  fetchError: "",
  isSearching: false,
};

function contact(state = defaultState, action) {
  const { contacts, telecomUserEmails } = action;

  switch (action.type) {
    case CONTACT_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: null,
      };

    case CONTACT_FETCH_RECEIVED:
      return {
        ...state,
        contacts,
        isLoading: false,
        fetchError: null,
      };

    case CONTACT_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case CONTACT_FETCH_CLEAR_ERRORS:
      return { ...state, fetchError: "", isLoading: false };

    case CONTACT_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CONTACT_UPDATE_RECEIVED:
      return {
        ...state,
        contacts,
        isLoading: false,
      };

    case CONTACT_UPDATE_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case CONTACT_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    case CONTACT_TELECOM_USER_EMAILS_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: null,
      };

    case CONTACT_TELECOM_USER_EMAILS_FETCH_RECEIVED:
      return {
        ...state,
        telecomUserEmails,
        isLoading: false,
        fetchError: null,
      };

    case CONTACT_TELECOM_USER_EMAILS_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    default:
      return state;
  }
}

export default contact;
