import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faDollarSign } from "@fortawesome/free-solid-svg-icons";

import { Translate } from "react-i18nify";

import TranslateNumber from "components/i18n/TranslateNumber";

const TotalResourceCount = ({ totalResource, totalAmount, resource }) => {
  return (
    <div className="agreement-status-content">
      <div className="d-flex align-items-center justify-content-center mb-3 mb-sm-0">
        <div className="stats-icon-holder yellow">
          {resource === "agreement" && <FontAwesomeIcon icon={faMoneyBill} />}
        </div>
        <div className="d-flex flex-column  justify-content-center ms-3">
          <p className="mb-0 text-gray">
            <Translate value="dashboard.agreement.total" />
          </p>
          <h4 className="mb-0 text-dark" data-cy={`${resource}-total-count`}>
            {totalResource ? (
              <TranslateNumber
                value={totalResource}
                minimumFractionDigits={0}
              />
            ) : (
              "NA"
            )}
          </h4>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center ms-0 ms-sm-3 ms-lg-5">
        <div className="stats-icon-holder blue">
          <FontAwesomeIcon icon={faDollarSign} />
        </div>
        <div className="d-flex flex-column  justify-content-center ms-3">
          <p className="mb-0 text-gray">
            <Translate value="attributes.totalAmount" />
          </p>
          <h4 className="mb-0 text-dark" data-cy={`${resource}-total-amount`}>
            {totalAmount ? (
              <TranslateNumber value={totalAmount} minimumFractionDigits={2} />
            ) : (
              "NA"
            )}
          </h4>
        </div>
      </div>
    </div>
  );
};

TotalResourceCount.defaultProps = {
  resource: undefined,
  totalResource: "NA",
  totalAmount: "NA",
};

TotalResourceCount.propTypes = {
  totalResource: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resource: PropTypes.string,
};

export default TotalResourceCount;
