// NOTE: Please maintain by alphabetical order; it will be easier to search and look through the file.

export default {
  // Application wide attributes of features
  attributes: {
    address: "Adresse",
    address1: "Adresse 1",
    address2: "Adresse 2",
    addANewSubscription: "Tilføj et nyt abonnement",
    affiliation: "Afdeling",
    agreement: "Aftale",
    amount: "Beløb",
    amountTaxSigned: "moms",
    amountTotalSigned: "I alt",
    amountUntaxedInvoiceSigned: "Ekskl. moms",
    bill: "Regning",
    bills: "Regninger",
    billable: "Kan faktureres",
    billingGroup: "Faktureringsgruppe",
    billCellPhoneUsages: "Billede mobiltelefoner",
    billVoipUsages: "Bill VoIP-brug",
    bindingPeriodInMonths: "Bindingsperiode i måneder",
    callRate: "Kaldstakst",
    callWaiting: "Kald på hold",
    campaignRoutingProfile: "Kampagneroutningsprofil",
    category: "Kategori",
    cellPhone: "Mobiltelefon",
    cellPhoneNumber: "Mobiltelefonnummer",
    circuit: "Kredsløb",
    city: "By",
    comment: "Kommentar",
    confirmedPortingDate: "Bekræftet overførselsdato",
    country: "Land",
    createdAt: "Oprettelsesdato",
    creditNote: "Kreditnota",
    creditNotes: "Kreditnoter",
    customer: "Kunde",
    customerEmail: "Kundens e-mailadresse",
    customOption: "Brugerdefineret mulighed",
    customerNumber: "Kundenummer",
    customerSince: "Kunde siden",
    cvrSearchField: "Søg efter CVR-nr. eller firmanavn.",
    dataType: "Data type",
    date: "Dato",
    dateDue: "Forfaldsdato",
    dateFormat: "Datoformat",
    dateInvoice: "Fakturadato",
    dateInvoiced: "Faktureret den",
    debtor: "Debitor",
    debtorName: "Debitornavn",
    debtorType: "Kundegruppe",
    deploymentDate: "Implementeringsdato",
    description: "Beskrivelse",
    destination: "Destination",
    destinationNumber: "Destinationsnummer",
    dialTimeout: "Opkaldstimeout",
    direction: "Retning",
    due: "Forfald",
    dueDate: "Forfaldsdato",
    duration: "Varighed",
    email: "E-mailadresse",
    emailAddress: "E-mailadresse",
    emailNotification: "E-mailmeddelelse",
    emergencyServices: "Emergency Services",
    employee: "Ansat",
    endNumber: "Slutnummer",
    endingNumber: "Slutnummer",
    establishedAt: "Oprettet den",
    expiryDate: "Udløbsdato",
    exportStatus: "Eksportstatus",
    extensionNumber: "Lokalnummer",
    extensionNumberAbbr: "Lokalnr.",
    fax: "Fax",
    faxName: "Fax Navn",
    faxServiceEnabled: "Fax-tjeneste Aktiveret",
    fileSize: "Filstørrelse",
    firstName: "Fornavn",
    flowID: "Flow-id",
    fmfmDialMethod: "Følg mig opkaldsmetode",
    fmfmDialTimeout: "Følg mig timeout",
    forwardTo: "Videresend til",
    from: "Fra",
    fullName: "Fulde navn",
    holdMusic: "Ventemusik",
    hostname: "Værtsnavn",
    iccNumber: "ICC-nummer",
    iccid: "ICCID",
    iccidNumber: "ICCID Number",
    id: "#",
    imsi: "IMSI",
    imsiNumber: "IMSI Nummer",
    inboundCalls: "Indgående kald",
    inboundNumber: "Indgåenden nummer",
    inboundNumberAbbr: "Indgående nr.",
    installationAddress: "Installationsadresse",
    interfaceLanguage: "Grænsefladesprog",
    interval: "Interval",
    invoice: "Faktura",
    invoiceNumber: "Fakturanummer",
    invoicePeriod: "Faktureret periode",
    invoiceStartDate: "Faktura startdato",
    invoicedUntil: "Faktureret indtil",
    isArchived: "Arkiveret",
    isACustomer: "er kunde",
    language: "Sprog",
    lastActiveAt: "Var sidst aktivt",
    lastInvoiceDate: "Sidste fakturadato",
    lastLogin: "Sidste login",
    lastName: "Efternavn",
    lineNumber: "Linjenummer",
    location: "Beliggenhed",
    locationConstraint: "Placeringsbegrænsning",
    login: "Login",
    loginCount: "Antal login",
    macAddress: "MAC-adresse",
    mainEmailAddress: "E-mailadresse",
    mainNumber: "Hovednummer",
    mainPhoneNumber: "Telefonnummer",
    maxChannels: "Maksimim antal talekanaler",
    maximumLengthOfMessageInSeconds: "Maksimal længde af besked i sekunder",
    maximumNumberOfMessageInVoicemailBox:
      "Maksimalt antal meddelelser tilladt i telefonsvarerboksen",
    members: "Medlemmer",
    messages: "Beskeder",
    minimumLengthOfMessageInSeconds: "Minimumlængde af en besked i sekunder",
    maximumNumberOfAllowedMessages: "Maksimalt antal tilladte beskeder",
    minimumLengthOfAMessage: "Minimumlængde af en besked",
    maximumLengthOfAMessage: "Maksimal længde af en besked",
    mobile: "Mobilnummer",
    model: "Model",
    modelName: "Modelnavn",
    monthlyPrice: "Månedlig pris",
    name: "Navn",
    newPassword: "Nyt kodeord",
    notes: "Notater",
    number: "Numrer",
    numberOfPages: "Antal sider",
    numberPortingRequest: "Nummerportinganmodning",
    numberRange: "Nummerserie",
    numberType: "Nummer Type",
    networkOperator: "Netværksoperatør",
    ochRejectionCode: "OCH-afvisningskode",
    odooDebtorID: "Kunde",
    options: "Egenskaber",
    owner: "Ejer",
    outgoingCalls: "Udgående kald",
    outgoingNumber: "Udgående nummer",
    parkingLotEnd: "Parkring af kald slut",
    parkingLotStart: "Parkering af kald start",
    password: "Kodeord",
    paymentTerm: "Betalingsbetingelser",
    paymentType: "Betalingsmulighed",
    pbx: "PBX",
    pbxNumber: "Pbxnummer",
    phoneNumber: "Telefonnummer",
    phoneNumbers: "Telefonnumre",
    phone: "telefon",
    phones: "Telefoner",
    pinCode: "PIN-kode",
    pin1: "PIN 1",
    pin2: "PIN 2",
    puk1: "PUK 1",
    puk2: "PUK 2",
    poi: "POI",
    portingDate: "Porteringsdato",
    preview: "Forhåndsvisning",
    price: "Pris",
    product: "Produkt",
    productName: "Produktnavn",
    productNumber: "Produktnummer",
    productPrice: "Produktpris",
    productProperties: "Produktegenskaber",
    productType: "produkttype",
    properties: "Egenskaber",
    proposedDate: "Foreslået dato",
    proposedDateReason: "Foreslået datoårsag",
    providers: "Udbyder",
    puk1Code: "PUK1-kode",
    quantity: "Antal",
    quotation: "Citat",
    reason: "Grund",
    recordCalls: "Optag samtaler",
    refNumber: "Nummer",
    refundDate: "Refusionsdato",
    refundedMonths: "Refunderede måneder",
    rejectionDate: "Afvisningsdato",
    rejectionReason: "Afvisning Årsag",
    release: "Udgive",
    reseller: "Forhandler",
    reactivate: "Genaktiver",
    requestReceivedDate: "Anmodning om modtagelsesdato",
    requestedNumberStatus: "Anmodet nummerstatus",
    requestedPortingDate: "Anmodet overførselsdato",
    residualSigned: "Forfalden beløb",
    role: "Rolle",
    routingProfile: "Routningsprofil-ID",
    seller: "Sælger",
    sender: "Afsender",
    senderNumber: "Afsendernummer",
    sentDate: "Afsendelsesdato",
    services: "Tjenester",
    serviceName: "Tjenestenavn",
    serviceType: "Servicetype",
    setup: "Oprettelse",
    selectICCIDNumber: "Vælg ICCID-nummer",
    selectUserLicense: "Vælg en brugerlicens",
    shortcuts: "Genveje",
    simCardHolder: "SIM kort holder",
    size: "Størrelse",
    smsNotification: "SMS-besked",
    speed: "Hastighed",
    startDate: "Startdato",
    startNumber: "Startnummer",
    startingNumber: "Startnummer",
    state: "Status",
    status: "Status",
    strategy: "Strategi",
    subTotal: "Subtotal",
    subscriptions: "Abonnementer",
    subscriptionType: "Abonnementstype",
    superAdmin: "Superadmin",
    suspended: "Suspenderet",
    suspendedDate: "Suspenderet dato",
    symbol: "Symbol",
    terminationAddress: "Afslutningsadresse",
    terminationDate: "Afslutningsdato",
    terminationTerm: "Opsigelsesperiode",
    termsAndConditions: "Vilkår og betingelser",
    timeFormat: "Tidsformat",
    timeZone: "Tidszone",
    timeout: "Udløbet",
    to: "Til",
    total: "Total",
    totalAmount: "Total beløb",
    totalPrice: "Total pris",
    totalMonthlyPrice: "Samlet månedlig pris",
    totalSetupPrice: "Samlet opsætningspris",
    type: "Type",
    unit: "Enhed",
    unitOfMeasure: "Målenhed",
    upcomingInvoiceDate: "Kommende fakturadato",
    usage: "Forbrug",
    usageType: "Brugstype",
    user: "Bruger",
    userInterfaceLocale: "Grænsefladesprog",
    userLicense: "Brugerlicens",
    username: "Brugernavn",
    userRole: "Brugerrolle",
    // userSetting: 'Brugeregenskaber',
    userType: "Brugertype",
    validityPeriod: "Gyldighedsperiode",
    vatNumber: "CVR-nummer",
    vendor: "Sælger",
    vendors: "Leverandører",
    viewStatus: "Vis status",
    voicemailBox: "Voicemail boks",
    wallpaper: "Baggrundsbillede",
    website: "Webside",
    zipCode: "Postnummer",
  },

  // common words used throughout the app
  common: {
    action: "Handling",
    active: "Aktiv",
    add: "Tilføj",
    addNew: "Tilføj ny",
    addNewOption: "Tilføj ny egenskab",
    addNumber: "Tilføj et nummer",
    addOneNow: "Tilføj en nu",
    addOnSubscriptions: "Tillægsabonnementer",
    addService: "Tilføj service",
    addSubscription: "Tilføj abonnement",
    additionalDetails: "Yderligere detaljer",
    advanced: "Avanceret",
    addTermsAndConditions: "Tilføj vilkår og betingelser...",
    allActive: "Alle aktive",
    allowedKeys: "Tilladte taster",
    announcement: "Annoncering",
    archive: "Arkiv",
    archived: "Arkiveret",
    assign: "Tildel",
    assigned: "Tildelt",
    assignToSimCardHolder: "Tildel til SIM-kortholder",
    assignToUserLicense: "Tildel til brugerlicens",
    assignSimCard: "Tildel SIM-kort",
    assignNewSimCard: "Tildel nyt SIM-kort",
    attachedTermsAndConditions: "Vedhæftede vilkår og betingelser",
    automaticallySelected: "Automatisk valg",
    available: "Ledig",
    basicDetails: "Grundlæggende detaljer",
    billingDisabled: "Fakturering inaktiv",
    bindingPeriod: "Bindingsperiode",
    bindingPeriods: "Bindingsperioder",
    cancel: "Annuller",
    cellPhone: "Mobiltelefon",
    cellPhoneBillingDisabled: "Mobilfakturering deaktiveret",
    changePassword: "Ændre kodeord",
    chooseSubscription: "Vælg Abonnement",
    clear: "Rens",
    comingSoon: "Kommer snart",
    confirm: "Bekræfte",
    confirmation: "Bekræftelse",
    consentForm: "Samtykkeformular",
    consumedEuUsages: "Forbrugte eu-anvendelser",
    consumedNationalUsages: "Forbrugt Nationale Anvendelser",
    contactDetails: "Kontaktdetaljer",
    cost: "Koste",
    currentPassword: "Nuværende kodeord",
    data: "Data",
    deactivated: "Deaktiveret",
    default: "Standard",
    defaultPhoneNumberFormat: "Standard telefonnummerformat",
    delete: "Slet",
    deleteAll: "Slet alt",
    deleteSelected: "Slet markeret",
    detail: "Detaljer",
    desktopPhone: "Desktop telefon",
    didNumber: "Telefonnummer",
    domestic: "Indenlandsk",
    download: "Hent",
    draft: "Udkast",
    dropdown: "Dropdown",
    edit: "Rediger",
    editService: "Rediger tjeneste",
    editSubscription: "Rediger abonnement",
    emailPlaceholder: "name@email.com",
    enable: "Aktiver",
    enabled: "Aktiveret",
    enterThePrice: "Afslut prisen",
    error: "Fejl",
    eu: "EU",
    euRoaming: "EU-roaming",
    fetchingResources: "Indlæsning %{resource}er...",
    for: "For",
    forgotPassword: "Glemt kodeord",
    filters: "Filtre",
    fixed: "Fastnet",
    free: "gratis",
    generateInvoice: "Generer faktura",
    gold: "Guld",
    hide: "Skjul",
    imsiRange: "IMSI Områder",
    info: "Info",
    inputField: "Inputfelt",
    instructions: "Instruktioner",
    international: "International",
    internationalRoaming: "International Roaming",
    internet: "Internet",
    loading: "Indlæser",
    logout: "Log ud",
    mainSubscription: "Hovedabonnement",
    mapUnavailable: "Kortet er utilgængeligt",
    mms: "MMS",
    mobile: "Mobil",
    networkServices: "Netværkstjenester",
    next: "Næste",
    new: "ny",
    no: "Nej",
    noAffiliation: "Ingen tilknytning",
    noName: "Intet navn",
    noOptions: "Ingen muligheder",
    noRecordFound: "Ingen poster fundet",
    noRecords: "Ingen poster",
    noResults: "Intet resultat",
    noValue: "Ingen værdi",
    none: "Intet",
    noneYet: "Intet endnu",
    notAssociatedWithExchange: "Ikke tilknyttet exchange",
    notConfigured: "Ej konfigureret",
    notFound: "Ikke fundet",
    notifications: "Besked",
    notSet: "Ikke indstillet",
    number: "Nummer",
    numberOfDays: "%{days} Dage",
    numberOfMonths: "%{months} måneder",
    numberRange: "Nummerserie",
    optional: "Valgfri",
    order: "Bestille",
    orderNewSimCard: "Bestil nyt simkort",
    owner: "Ejer",
    overview: "Oversigt",
    portedOut: "Portet ud",
    products: "Produkter",
    profile: "Profil",
    reactivate: "Genaktiver",
    quarantined: "I karantæne",
    refresh: "Opdater",
    reload: "Genindlæs siden",
    rename: "Omdøb",
    requestedNumberDetails: "Anmodede nummeroplysninger",
    resourceName: "%{resource} Navn",
    reseller: "Forhandler",
    response: "Respons",
    sales: "Salg",
    save: "Gem",
    search: "Søg",
    seconds: "Sekunder",
    service: "service",
    services: "tjenester",
    sent: "Sendt",
    selectAll: "Vælg alt",
    selectDebtor: "Vælg en debitor",
    selectDebtorType: "Vælg debitortype",
    selectProduct: "Vælg et produkt",
    searching: "Søger",
    searchResultNotFound: "Søgeresultat blev ikke fundet",
    setupPrice: "Oprettelsesomkostninger",
    show: "VIS",
    signIn: "Log ind",
    signOut: "Log ud",
    sipDevice: "SIP-enheder",
    simCard: "SIM-kort",
    simCardDetails: "SIM kort detaljer",
    simCardHolder: "SIM kort holder",
    sms: "SMS",
    subscription: "abonnement",
    subscriptionDetails: "Abonnementsoplysninger",
    subscriptionUsage: "Abonnementsbrug",
    success: "Succes",
    suspend: "Suspendere",
    termsAndConditions: "Vilkår og betingelser",
    thankYou: "Tak skal du have!",
    today: "I dag",
    total: "i alt",
    unauthorized: "Uautoriseret",
    unspecified: "Uspecificeret",
    untitled: "Unavngivet",
    update: "Opdatering",
    updateService: "Opdateringstjeneste",
    updateSubscription: "Opdater abonnement",
    usageDetails: "Brugsdetaljer",
    verify: "Verificere",
    view: "Udsigt",
    viewDetails: "Vis detaljer",
    viewInvoices: "Vis fakturaer",
    viewUsage: "Vis brug",
    voice: "Tale",
    voicemailMessageDetails: "Voicemail Beskeder detaljer",
    voipBillingDisabled: "VoIP-fakturering deaktiveret",
    whoShouldFillTheForm: "Vælg en person til at udfylde anmodningsformularen",
    yes: "Ja",
  },

  date: {
    long: "DD-MM-YYYY",
  },

  // All /dropdown in the app
  enum: {
    cellPhone: {
      recordCall: {
        external: "Udvendig",
        yesChange: "Ja, men kan stoppes af begge parter",
        noChange: "Nej, men kan startes af begge parter",
      },
    },

    dataTypes: {
      integer: "heltal",
      string: "streng",
      boolean: "boolesk",
      dateTime: "dato og tid",
      time: "tid",
    },

    invoicePeriod: {
      monthly: "Månedlig",
      quarterly: "Kvartalsvis",
      semiAnnually: "Halvårlig",
      annually: "Årlig",
    },

    locale: {
      "da-DK": "Dansk",
      "en-US": "Engelsk",
      da_DK: "Danish",
      en_US: "English",
    },

    numberPorting: {
      exportStatus: {
        accept: "Acceptere",
        decline: "Afvis",
        propose_new_date: "Foreslå ny dato",
      },

      proposedDateReason: {
        contractPeriodViolated: "Kontraktperioden overtrædes",
        excessiveLoad: "Dato flyttet på grund af overdreven belastning",
        terminationPeriodViolated: "Opsigelsesperioden overtrædes",
        tooEarly: "For tidligt i henhold til regler og procedurer",
      },

      import: {
        whoShouldFillTheForm: {
          self: "Selv",
          customer: "Kunde",
        },
      },
      simCard: {
        available: "Ledig",
        active: "Aktiv",
        assigned: "Tildelt",
        blocked: "Blokeret",
      },
    },

    pbx: {
      agreement: {
        addNew: "Tilføj PBX til ny aftale",
        addToExisting: "Tilføj PBX til eksisterende aftale",
      },
      billingGroup: {
        userLicense: "Anvendelser grupperet efter brugerlicens",
        cidNum: "Anvendelser grupperet efter opkalds-id-nummer",
        pbx: "Anvendelser grupperet efter PBX",
      },
    },

    setting: {
      categoryOptions: {
        invoice: "Faktura",
        pbx: "PBX",
      },
    },

    subscriptionProduct: {
      subscriptionType: {
        addon: "Tilføjelses",
      },
    },

    terminationTerm: {
      thirty_days: "30 dage",
      next_month_end: "Slutningen af ​​næste måned",
      three_months: "3 måneder",
      next_quarter_end: "Slutningen af ​​næste kvartal",
    },
  },

  // Fetch error
  error: {
    error: "Der opstod en fejl",
    description: "Der opstod en fejl under hentning af data",
    refresh: "Opdater",
    retry: "Prøve igen",
  },

  pill: {
    status: {
      active: "Aktiv",
      assign: "Tildel",
      suspended: "Suspenderet",
      block: "Blok",
      employee: "Medarbejder",
      debtor: "Debitor",
      assigned: "Tildelt",
      available: "Ledig",
      blocked: "Blokeret",
      deactivated: "Deaktiveret",
      lost: "Faret vild",
      newSimCardRequested: "Nyt simkort anmodet",
      quarantined: "I karantæne",
      setup: "Opsætning",
    },
  },
  // Labels used in the app
  label: {
    dropdown: {
      selectResource: "Vælg %{resource}",
      chooseProduct: "Vælg produkt",
    },
  },

  menu: {
    customer: "Kunde",
    user: "Brugere",
    telecom: "Telecom",
    setting: "Indstillinger",
    dropdown: {
      quotation: {
        preview: "Forhåndsvisning",
        delete: "Slet",
        send: "Sende",
        convertToAgreement: "Konverter til aftale",
      },
    },

    customerAdmin: {
      debtor: "Debitor",
      contact: "Kontakter",
    },
  },

  // Menu related Translations
  menuItem: {
    agreement: "Aftaler",
    bills: "Regninger",
    creditNotes: "Kreditnoter",
    debtor: "Debitorer",
    invoice: "Fakturaer",
    numberPortingRequest: "Nummerportinganmodninger",
    pbx: "PBXer",
    profile: "Profil",
    userSetting: "Brugerindstillinger",
    phoneNumbers: "Telefonnumre",
    quotations: "Tilbud",
    simCards: "Sim-kort",
  },

  message: {
    auth: {
      incorrectEmailOrPassword: "Forkert e-mailadresse eller kodeord",
      notExist: "Kontoen eksisterer ikke",
      onlyMobileAllowed:
        "Du har kun tilladelse til at få adgang til mobilappen",
      resetLink:
        "Hvis du har indtastet en eksisterende e-mailadresse, vil du om kort tid modtage en mail omkring nulstilling af dit kodeord.",
      setPasswordSuccessMessage: "Kodeordet er opdateret",
      setPasswordError400: "Kodeordet blev ikke gemt",
      setPasswordError404: "Din session er udløbet",
      setPasswordError:
        "Din forespørgsel kunne ikke behandles, venligst prøv igen senere.",
      signIn: "Log ind ved at bruge en e-mailadresse",
      unableToSignIn: "Kan ikke logge ind",
      unauthorized: "Du har ikke tilladelse til at se denne side",
      unauthorizedToAccessWebApp:
        "Du er ikke autoriseret til at få adgang til denne webapp",
    },

    debtor: {
      blankField: "Søgefeltet må ikke være tomt",
    },

    invoice: {
      noLine: "Ingen fakturalinjer",
    },

    product: {
      chooseDifferent: "Vælg et andet produkt",
    },

    quotation: {
      convertToAgreement: {
        noProperties: "Ingen egenskaber!",
      },
    },

    setting: {
      empty: "Ingen opsætning fundet",
    },

    subscription: {
      noLine: "Ingen abonnementslinjer",
      noProperties: "Ingen egenskaber! Venligst tilføj et abonnement.",
      noSubscription: "Intet abonnement",
      selectProduct: "Venligst vælg et produkt for at kunne fortsætte",
      selectSubscription: "Venligst vælg abonnement først",
    },

    subscriptionProduct: {
      noProperties: "Ingen egenskaber/opsætning tilføjet endnu!",
    },
  },

  resourceStatus: {
    pbxs: "Pbxs",
    users: "Brugere",
    cellPhones: "Mobiltelefoner",
    didNumbers: "Gjorde tal",
    userLicenses: "Brugerlicenser",
    active: "Aktiv %{resource}",
    deactivated: "Deaktiveret %{resource}",
  },
  // modal messages
  modal: {
    archive: {
      resource: "Er du sikker på, at du vil arkivere denne post?",
    },

    reactivate: {
      cellPhone: "Er du sikker på, at du vil genaktivere denne mobiltelefon?",
    },

    delete: {
      resource: "Er du sikker på, at du vil slette denne post?",
      user: "Er du sikker på, at du vil slette bruger",
      userAssociationWarning:
        "Sletning af denne bruger sletter alle data, der er knyttet til denne bruger",
      irreversible: "Denne handling er irreversibel.",
    },

    orderNewSimCard: {
      resource: "Er du sikker på, at du vil bestille et nyt sim-kort?",
      existingSimCardBlockWarning:
        "Det eksisterende simkort spærres umiddelbart efter bestilling af det nye simkort",
    },

    sessionExpired: {
      whoopsYourSessionHasExpired: "Hov, din session er udløbet!",
      description:
        "Din session er udløbet på grund af inaktivitet. Log venligst på igen.",
    },
  },

  // Page or tab titles. Any other titles? Put it here!
  title: {
    card: {
      resourceDetails: "%{resource} detaljer",
    },

    dashboardComponent: {
      cellPhoneExportRequests: "Anmodninger om eksport af mobiltelefoner",
      cellPhoneImportRequests: "Anmodninger om import af mobiltelefon",
    },

    // modal titles
    modal: {
      numberPortingRequest: {
        authenticationModal: {
          verifyEmailAddress: "Verificer email adresse",
        },
      },
    },

    timeUnit: {
      sec: "s",
      m: "m",
      h: "t",
      s: "s",
    },

    page: {
      dashboard: "Oversigt",
      faxStatus: "Faxstatus",
      desktopPhones: "Bordtelefon",

      appSetting: {
        list: "Programindstillinger",
      },

      numberPortingRequest: {
        imports: "Importere",
        exports: "Eksport",
        customerForm: "Nummerporting -anmodningsformular",
      },

      resource: {
        create: "Oprette %{resource}",
        edit: "Rediger %{resource}",
        list: "%{resource} Overisgt",
      },
    },

    resource: {
      appSetting: "Programindstilling",
      didNumber: "Telefonnummer",
      phoneNumber: "Telefonnummer",
      rangeOfPhoneNumbers: "Udvalg af telefonnumre",
      iccidNumber: "ICCID Nummer",
      addResource: "Tilføj venligst en %{resource}",
      convertToAgreement: "Konverter til aftale",

      button: {
        addResource: "Tilføj en %{resource}",
      },

      info: {
        noResources: "No %{resource}",
      },
    },

    section: {
      billingSettings: "Faktureringsindstillinger",
      faxService: "Fax-tjeneste",
      subscription: "Abonnemement",

      numberPortingRequest: {
        numbersToImport: "Tal, der skal importeres",
      },
    },

    tab: {
      agreement: {
        cellPhones: "Mobiltelefon",
        cellPhone: "Mobiltelefoner",
        hostingServices: "Hostingtjenester",
        internetConnections: "Internetforbindelser",
        serviceContract: "Serviceaftaler",
        serviceContracts: "Servicekontrakter",
        voip: "VoIP",

        subscription: {
          active: "Active abonnementer",
          archived: "Arkiverede abonnementer",
        },

        subscriptionModal: {
          addonProducts: "Tilføjelsesprodukter",
          setupProducts: "Oprettelsesprodukter",
          subscriptionProduct: "Abonnementsprodukt",
        },
      },

      cellPhone: {
        networkServices: "Netværkstjenester",
      },

      pbx: {
        userLicense: "Brugerlicenser",
        didNumber: "Telefonnumre",
      },

      setting: {
        subscriptionProducts: "Abonnementsprodukter",
      },

      userLicense: {
        cellPhones: "Mobiltelefoner",
        didNumbers: "Direkte numre",
      },
    },
  },

  // All toaster message by feature
  toasterMessage: {
    networkError: "Netværksfejl",
    processError:
      "Din forespørgsel kunne ikke behandles, venligst prøv igen senere.",
    unauthorized: "Du er ikke autoriseret til at udføre denne handling!",

    numberPortingRequest: {
      importAccepted: "Import Request Accepted",
    },

    resource: {
      create: "%{resource} oprettet",
      delete: "%{resource} blev slettet",
      update: "%{resource} opdateret",
      archive: "%{resource} arkiveret",
      assigned: "%{resource} tildelt",
    },

    requestFailed: "Din forespørgsel kunne ikke behandles.",

    session: {
      expired: "Din session er udløbet",
    },

    user: {
      fetchError: "Der eksisterer ingen bruger med denne e-mailadresse.",
      languageUpdatedSuccessfully: "Sproget blev opdateret",
    },

    viewGeneratedInvoiceHere: "Se genereret faktura her",

    cellPhone: {
      cellPhoneSuspended: "Mobiltelefon suspenderet",
      cellPhoneReactivated: "Mobiltelefonen er genaktiveret",
      newSimCardRequested: "Der er anmodet om nyt simkort",
      newSimCardAssignedSuccessfully: "Nyt sim-kort blev tildelt",

      networkService: {
        networkServiceCreatedSuccessfully:
          "%{networkService} oprettet med succes",
        networkServiceDeletedSuccessfully:
          "%{networkService} slettet med succes",
      },
    },

    didNumber: {
      assignedToUserLicense: "Fik nummeret tildelt en brugerlicens med succes",
    },

    phoneNumber: "Telefonnummer",

    quotation: {
      quotationBeingSentToCustomerByEmail: "Tilbud sendes til kunden på mail",
      quotationHasBeenSentToTheCustomer: "Tilbud er sendt til kunden",
      quotationHasBeenConvertedToAnAgreement:
        "Tilbud er konverteret til en aftale",
    },
  },

  // Validation Errors and messages
  validationErrors: {
    ALPHANUMERIC_ONLY: "Kun alfanumeriske tilladt!",
    BLANK: "Må ikke være tomt",
    CANNOT_BE_NEGATIVE: "Må ikke være mindre end 0",
    CANNOT_BE_ZERO: "Kan ikke være 0",
    CONFIRMATION: "Skal være lig med",
    EXTENSION_PATTERN_RESTRICTED:
      "Lokalnummer som starter med 9**, er ikke tilladt",
    GREATER_THAN: "Skal være større end %{count}",
    GREATER_THAN_OR_EQUAL_TO: "Skal være større end eller lig med %{count}",
    INCLUSION: "Er ikke tilladt",
    INCORRECT_CREDENTIALS: "Forkert kodeord",
    INVALID: "Ugyldigt format",
    INVALID_EMAIL_ADDRESS: "Ugyldig e-mailadresse",
    INVALID_PHONE_NUMBER: "Ugyldigt telefonnummer",
    INVALID_URL: "Ugyldig URL",
    MIN_AGE: "Du lever ikke op til minimumsalderskravet!",
    NOT_A_NUMBER: "Skal være et tal",
    REQUIRED: "Påkrævet",
    TAKEN: "Er allerede i brug",
    TOO_LONG: "Må højest være %{count}",
    TOO_SHORT: "Skal mindst være %{count}",
    WRONG_LENGTH: "Skal være %{count}",
    SHORT_PHONE_NUMBER: "Skal mindst være %{count} ciffernummer",
    ONLY_NUMBER_AND_PLUS:
      "Tal, der starter med “+” eller kun almindelige tal tilladt",
    NOT_A_DIGIT: "Must be a digit",
    PUK_CODE_VALIDATION: "PUK code skal være lig med 8 cifre",
    PIN_CODE_VALIDATION: "PIN code har minimum 4 cifre og maksimalt 8 cifre",

    date: {
      INVALID: "Forkert dato",
      OUT_OF_RANGE: "Out of range",
    },

    networkService: {
      LOCATION_CONSTRAINT_OUTSIDE_RESTRICTION:
        "%{restrictedServices} begrænset til INSIDE",
      LOCATION_ENTIRE_WORLD_RESTRICTION:
        "%{restrictedServices} begrænset til Entire World",
    },
  },

  // Feature wise translations (in alphabetical order)
  agreement: {
    active: "Aktiv",
    archived: "Arkiveret",
    billable: "Fakturerbar",
    disabled: "Deaktiveret",
    empty: "Ingen aftale",
    invoice: "Fakturer aftale",
    nextInvoiceDate: "Næste faktreringsdato",
    number: "Aftalenummer",
    option: "Aftaleegenskaber",
    select: "Vælg aftale",
  },

  appSetting: {
    category: {
      self: "Kategori",
      desc: "Opsæt kategori eller type af indstilling basseret på hvad der skal styres. Er det en faktura eller profil? Du kan vælge hvad du ønsker at indstillingen skal styre eller kontrollere.",
    },

    dataType: {
      self: "Datatype",
      desc: "Type af understøttet dataegenskab. Inputtype for brugerindstillinger i brugergrænsefladen er baseret på datatypen.",
    },

    description: {
      self: "Beskrivelse",
      desc: "Tilføj beskrivelse, så brugerne ved hvad indstillingen funktion er.",
    },

    displayType: {
      self: "Vis type",
      desc: "Vælg hvordan dette felt skal vises for brugerene under deres egenskaber.",
    },

    name: {
      self: "Name",
      desc: "Navn der vises under brugeropsætning.",
    },

    options: {
      self: "Muligheder",
      desc: "Valgmuligheder at vælge imellem.",
    },

    userType: {
      self: "Brygertype",
      desc: "Vælg brugere som denne opsætning skal vises hos. Er det for superadmin, kundeadmin?",
    },
  },

  auth: {
    // login page
    deactivatedAccount: "Din konto er blevet deaktiveret.",
    emailField: "Email adresse",
    forgotPassword: "Glemt din adgangskode?",
    loginLabel: "Log ind her ved hjælp af din e-mail-adresse.",
    loginText: "Log på",
    passwordField: "Kodeord",
    keepMeLoggedIn: "Hold mig logget ind",

    // reset password page
    resetPasswordLabel:
      "Indtast din registrerede e-mail-adresse for at nulstille din adgangskode.",
    resetPasswordHelpText:
      "Låst ude? Hvis du har skrevet en eksisterende mailadresse, modtager du en mail om, hvordan du nulstiller din adgangskode om få minutter",
    resetPasswordText: "Nulstil min adgangskode",
    signInInstead: "Log ind i stedet?",

    // set password page
    setNewPasswordLabel: "Indstil din nye adgangskode",
    confirmPasswordField: "Bekræft kodeord",
    setPasswordBtn: "Indstil adgangskode",
    passwordPlaceholder: "Dit stærke kodeord",
  },

  cellPhone: {
    defaultForMobileApp: "Gør dette nummer til standard for mobil-app",
    defaultInMobileApp: "Standard in mobil-app",
    emailToSendRecordedCalls: "E-mailadresse til modtagelse af optaget kald",
    addASubscriptions: "Tilføj et abonnement",
    addOnSubscriptions: "Tillægsabonnement",
    pleaseSelectTheSubscriptionToAdd:
      "Vælg venligst det abonnement, du vil tilføje",
    pleaseSelectASubscriptionProductFirst:
      "Vælg venligst et abonnementsprodukt først",
    reasonForSuspending: "Årsag til suspendering",
    youCannotEditTheSuspendedCellPhone:
      "Du kan ikke redigere den suspenderede mobiltelefon",
    youCannotDeleteTheSuspendedCellPhone:
      "Mobiltelefon kan ikke slettes. Genaktiver venligst mobiltelefonen for at slette den.",
    chooseICCIDNumberToAssignTheUser:
      "Vælg ICCID-nummer for at tildele til brugeren",

    networkServices: {
      chooseLocation: "Vælg en placering",
      chooseLocationConstraint: "Vælg en begrænsning",
      chooseService: "Vælg en tjeneste",
      pleaseAddService: "Tilføj venligst en tjeneste",
      selectService: "Vælg Service",

      location: {
        entire_world: "Entire World",
        eu_ess: "EU/ESS",
        sweden: "Sweden",
        denmark: "Denmark",
        us_verizon: "US Verizon",
        ipn_kjit_countries: "IPN-KJIT-Countries",
        iot_sydamerika: "IoT-Sydamerika",
        iot_nordamerika: "IoT-Nordamerika",
        iot_eu_plus: "IoT-EU+",
        iot_nordics: "IoT-Nordics",
        maritime_and_satellite: "Maritime & Satellite",
      },

      locationConstraint: {
        inside: "INSIDE",
        outside: "OUTSIDE",
      },
    },
  },

  dashboard: {
    agreement: {
      active: "Aktive aftaler",
      activeAmount: "Beløb for aktive aftaler",
      archived: "Arkiverede aftaler",
      archivedAmount: "Beløb for arkiverede aftaler",
      billableAmount: "Fakturerbare aftalebeløb",
      billableCount: "Fakturerbare aftaler",
      disabled: "Handicappede aftaler",
      new: "Ny aftale",
      total: "Antal aftaler",
      noResults: "Ingen resultater fundet",
      searchTitle: "Søgeaftaler",
      searchHelpText: "Søgeresultater vises her",
    },

    debtor: {
      total: "Antal debitorer",
    },

    invoice: {
      due: "Forfaldne fakturaer",
      dueAmount: "Forfaldent beløb",
      paid: "Betalte fakturaer",
      total: "Alle fakturaer",
      validate: "Fakturaer til godkendelse",
      validateAmount: "Beløb til godkendelse",
    },

    bill: {
      due: "Forfaldne regninger",
      dueAmount: "Forfaldent beløb",
      paid: "Betalte regninger",
      total: "Samlede regninger",
      validate: "Regninger til godkendelse",
      validateAmount: "Beløb til godkendelse",
    },

    user: {
      new: "Nye brugere",
      noNew: "Ingen nye brugere",
      total: "Antal brugere",
    },
  },

  debtor: {
    // create: 'Opret debitor',
    // edit: 'Rediger debitor',
    invoiceAddress: "Faktureringsadresse",
    noDebtors: "Ingen debitorer",
    technicalContact: "Teknisk kontaktperson",
  },

  didNumber: {
    chooseUserLicenseToAssignToThisDidNumber:
      "Vælg brugerlicens for at tildele dette nummer",
  },

  faxService: {
    senderEmails: "E-mails til afsendelse af faxer",
    storeInServer: "Gem fax på server",
  },

  filterType: {
    status: "Status",
  },

  forbidden: {
    accessRestricted: "acceso restringido",
    youAreNotAllowed: "No tienes permiso para acceder a esta aplicación.",
    returnToLoginPage: "Volver a la página de inicio de sesión",
  },

  invoice: {
    addLine: "Tilføj linje",
    addNote: "Tilføj notat",
    detail: "Fakturadetaljer",
    // create: 'Opret faktura',
    download: "Download faktura",
    // edit: 'Rediger faktura',
    line: "Fakturalinje",
    notInvoiced: "Ikke faktureret",
  },

  numberPortingRequest: {
    acceptRequest: "Accepter anmodning",
    activityLogs: "Aktivitetslogs",
    cancelAllImportFlows: "Annuller alle flows",
    requestedPhoneNumbers: "Anmodede telefonnumre",
    respondToRequest: "Besvar forespørgslen",

    authenticationModal: {
      subTitle:
        "Bekræft venligst den e -mail, hvor du modtog meddelelsen om importanmodning",
      incorrectEmail: "Forkert mailadresse!",
    },

    exportRequest: {
      requestedNumberNotFound: "Anmodet nummer blev ikke fundet i appen!",
      askCustomerToSubmitNewRequest:
        "PBX og debitor brugt i denne anmodning er dummy og ikke nøjagtige. Afvis venligst denne anmodning og bed kunderne om at indsende en ny anmodning med det korrekte nummer.",
    },

    importForm: {
      csvFileImport: {
        addCellAndICCNumberToCSV:
          "Tilføj de nødvendige telefonnumre til CSV-filen",
        downloadCSVTemplate:
          "Download CSV-skabelonen med forudkonfigurerede overskrifter",
        importingNumbers: "Import af tal",
        importNumbersFromCSVFile: "Importer numre fra CSV -fil",
        selectAndUploadCSVFile:
          "Vælg og upload CSV -filen for at importere numre",
        uploadSeparateCSVFileInstructions:
          "Du kan også uploade en separat fil end denne skabelon. Hvis du beslutter dig for at gøre det, er der nogle noter, du skal huske på:",

        cellPhone: {
          addNumbersBelowHeader:
            'I CSV-filen vil du finde overskrifterne "cellenummer" og "icc_nummer" tilføjet som standard. Du kan tilføje numre, du vil importere, lige under overskriften.',
          csvColumnSeparatedByComma:
            "Hver kolonne skal adskilles med et komma (,) uden mellemrum efter kommaet. For eksempel: 70160160,12345",
          csvHeaderOrderNote:
            'Filen skal have overskrifter i rækkefølgen "cell_number, icc_number" dvs. mobiltelefon skal være den første kolonne i rækken.',
          downloadCSVTemplateInstructions:
            "Du kan downloade CSV-skabelonfilen med forudkonfigurerede overskrifter til tilføjelse af mobiltelefoner og ICC-numre ved at klikke på knappen herunder.",
        },

        didNumber: {
          addNumbersBelowHeader:
            'I CSV-filen vil du finde overskriften "phone_number" tilføjet som standard. Du kan tilføje numre, du vil importere, lige under overskriften.',
          csvHeaderOrderNote: 'File should only have the header "phone_number"',
          downloadCSVTemplateInstructions:
            "Du kan downloade CSV-skabelonfilen med forudkonfigureret overskrift til tilføjelse af telefonnumre ved at klikke på knappen nedenfor.",
        },

        validationErrors: {
          headerFormatNotSupported:
            "Overskriftsformat, der understøttes af appen, er %{supportedHeaderFormat}, men fik %{uploadedHeaderFormat}",
          multipleFileImportNotSupported:
            "Import af flere filer understøttes ikke!",
          onlyCSVFileSupported:
            "Kun CSV -filer understøttes til import af mobiltelefoner!",
        },
      },
    },

    requestAcceptModal: {
      acceptRequest: "Er du sikker på, at du vil acceptere anmodningen?",
    },

    requestCancelModal: {
      cancelRequest: "Er du sikker på, at du vil annullere dette flow?",
    },

    requestSubmittedModal: {
      successMessage:
        "Din anmodning er blevet indsendt. Du modtager en meddelelse i din e -mail, når anmodningen accepteres eller afvises af donoroperatøren.",
      alreadySubmittedMessage:
        "Du har allerede udfyldt anmodningsformularen. Du modtager en meddelelse i din e -mail, når anmodningen accepteres eller afvises af donoroperatøren.",
    },
  },

  password: {
    veryStrong: "Stærkt kodeord",
  },

  pbx: {
    allDesktopPhones: "Alle BorderTelefoner",
    billVoipUsages: "Bill VoIP-brug",

    subscription: {
      chooseDifferentProduct: "Vælg et andet produkt",
    },
  },

  phoneNumber: {
    numberType: "Nummer Type",
    costType: "Omkostningstype",
    onlyAvailableNumbersCanBeDeleted: "Kun tilgængelige numre kan slettes",
  },

  quotation: {
    addProduct: "Tilføj produkt",
    chooseProduct: "Vælg et produkt",
    otherProducts: "Andre produkter",
    pleaseSelectProductToAdd: "Vælg venligst et produkt at tilføje",
    pleaseSelectAMainSubscriptionFirst: "Vælg først et hovedabonnement",
    preview: "Forhåndsvisning af tilbud",
    quotationOverview: "Tilbudsoversigt",
    selectValidityPeriod: "Vælg en gyldighedsperiode",
    termsAndConditionsPreview: "Forhåndsvisning af vilkår og betingelser",

    bindingPeriod: {
      chooseSubscriptionType: "Vælg en abonnementstype",
      chooseBindingPeriodInMonths: "Vælg en bindingsperiode i måneder",

      subscriptionType: {
        voice: "Telefoni",
        fiber: "Fiber",
        fwa: "FWA",
        x_dsl: "xDSL",
        hosting_services: "Hostingtjenester",
      },
    },
  },

  serverErrors: {
    internalServerError: "Intern serverfejl",
  },

  setting: {},

  subscription: {
    add: "Tilføj abonnemement",
    category: "Abonnementskategori",
    detail: "Abonnementsdetalje",
    edit: "Rediger abonnement",
    for: "Abonnement for",
    line: "Abonnementslinje",
    // notNull: 'Du skal tilføje abonnementsprodukt først',
    // productInfo: 'Abonnementinformation',
    productProperties: "Abonnementegenskab",
  },

  subscriptionUsage: {
    metric: {
      minute: "minutter",
      piece: "stykker",
      gb: "GB",
    },
  },

  tooltip: {
    title: {
      clickOnFilesToPreviewTermsAndConditions:
        "Klik på filen for at se vilkår og betingelser",
    },
  },

  user: {
    active: "Aktiv",
    chooseNewPassword: "Vælg nyt kodeord",
    companyUsers: "Virksomhedsbrugere",
    customerUsers: "Kundebrugere",
    deleteProfile: "Slet profil",
    deactivated: "Deaktiveret",
    editProfile: "Rediger profil",
    // noUsers: 'Ingen brugere',
    pbxUsers: "PBX-brugere",
    resetPassword: "Nulstil kodeord",
    resellers: "Forhandlere",
    roleType: "Rolletype",
    status: "Status",
  },

  userLicense: {
    editUser: "Rediger bruger",
  },
};
