import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  faBoxOpen,
  faDollarSign,
  faCalendarDay,
  faCalendarTimes,
  faTty,
  faHandsHelping,
  faPager,
  faCommentAlt,
  faIdBadge,
} from "@fortawesome/free-solid-svg-icons";
import { Translate, t } from "react-i18nify";

import { PBX, AGREEMENT, USER_LICENSE } from "constants/routes";

import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import TranslateNumber from "components/i18n/TranslateNumber";
import TranslateDate from "components/i18n/TranslateDate";

import HasPermission from "hoc/RbacCTA";

import { isABusinessAdminUser } from "utils/userRole";

const RenderSubscriptionDetailsTable = ({
  isBusinessAdminUser,
  subscriptionDetailContent,
}) => {
  if (!isBusinessAdminUser) return null;

  return (
    <CardWithHeader
      title={
        <Translate
          value="title.card.resourceDetails"
          resource={t("title.section.subscription")}
        />
      }
      className="shadow-sm"
      cardContent={subscriptionDetailContent}
    />
  );
};

const DidNumberBasicDetail = ({ didNumber, currentlyLoggedInUser }) => {
  const {
    deployment_date: deploymentDate,
    invoice_start_date: invoiceStartDate,
    pbx_id: pbxID,
    pbx_number: pbxNumber,
    first_name: firstName,
    last_name: lastName,
    subscription_line: subscriptionLine,
    agreement_id: agreementID,
    agreement_number: agreementNumber,
    termination_date: terminationDate,
    user_license_id: userLicenseId,
    user_license_extension_number: userLicenseExtensionNumber,
    di_comment: description,
  } = didNumber;
  const {
    start_date: startDate,
    product_name: productName,
    price,
    last_invoice_date: lastInvoiceDate,
  } = subscriptionLine;
  const { role: userRole } = currentlyLoggedInUser;

  const isBusinessAdminUser = isABusinessAdminUser(userRole);

  const basicDetailContent = [
    userLicenseId && {
      icon: faPager,
      title: <Translate value="attributes.name" />,
      content: `${firstName} ${lastName}`,
    },
    {
      icon: faTty,
      title: <Translate value="attributes.pbxNumber" />,
      content: (
        <>
          {pbxNumber}
          <Link to={PBX.addId(PBX.DETAIL, pbxID)} className="ml-1">
            {" ( "}
            <Translate value="common.viewDetails" />
            {" )"}
          </Link>
        </>
      ),
    },
    userLicenseId && {
      icon: faIdBadge,
      title: <Translate value="attributes.userLicense" />,
      content: (
        <>
          {userLicenseExtensionNumber}
          <Link
            to={USER_LICENSE.addId(USER_LICENSE.SHOW, userLicenseId)}
            className="ml-1"
          >
            {" ( "}
            <Translate value="common.viewDetails" />
            {" )"}
          </Link>
        </>
      ),
    },
    description && {
      icon: faCommentAlt,
      title: <Translate value="attributes.description" />,
      content: description,
    },
    isBusinessAdminUser && {
      icon: faCalendarDay,
      title: <Translate value="attributes.deploymentDate" />,
      content: <TranslateDate date={deploymentDate} />,
    },
    isBusinessAdminUser && {
      icon: faCalendarDay,
      title: <Translate value="attributes.invoiceStartDate" />,
      content: <TranslateDate date={invoiceStartDate} />,
    },
  ];

  const subscriptionDetailContent = [
    {
      icon: faHandsHelping,
      title: <Translate value="agreement.number" />,
      content: (
        <>
          {agreementNumber}
          <Link
            to={AGREEMENT.addId(AGREEMENT.SHOW, agreementID)}
            className="ml-1"
          >
            {" ( "}
            <Translate value="common.viewDetails" />
            {" )"}
          </Link>
        </>
      ),
    },
    {
      icon: faBoxOpen,
      title: <Translate value="attributes.product" />,
      content: productName,
    },
    {
      icon: faDollarSign,
      title: <Translate value="attributes.price" />,
      content: <TranslateNumber value={price} minimumFractionDigits={2} />,
    },
    {
      icon: faCalendarDay,
      title: <Translate value="attributes.startDate" />,
      content: <TranslateDate date={startDate} />,
    },
    {
      icon: faCalendarDay,
      title: <Translate value="attributes.lastInvoiceDate" />,
      content: <TranslateDate date={lastInvoiceDate} />,
    },
  ];

  if (terminationDate) {
    basicDetailContent.push({
      icon: faCalendarTimes,
      title: <Translate value="attributes.terminationDate" />,
      content: <TranslateDate date={terminationDate} />,
    });
  }

  return (
    <div>
      <CardWithHeader
        title={<Translate value="common.basicDetails" />}
        className="shadow-sm"
        cardContent={basicDetailContent}
      />
      <HasPermission perform="didNumber:subscriptionDetails">
        <RenderSubscriptionDetailsTable
          isBusinessAdminUser={isBusinessAdminUser}
          subscriptionDetailContent={subscriptionDetailContent}
        />
      </HasPermission>
    </div>
  );
};

RenderSubscriptionDetailsTable.defaultProps = {};

RenderSubscriptionDetailsTable.propTypes = {
  isBusinessAdminUser: PropTypes.bool.isRequired,
  subscriptionDetailContent: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.shape({}),
    })
  ).isRequired,
};

DidNumberBasicDetail.defaultProps = {};

DidNumberBasicDetail.propTypes = {
  didNumber: PropTypes.shape({
    user_license_extension_number: PropTypes.string,
    deployment_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    invoice_start_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    termination_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    pbx_id: PropTypes.number,
    pbx_number: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    subscription_line: PropTypes.shape({
      start_date: PropTypes.instanceOf(Date),
      product_name: PropTypes.string,
      price: PropTypes.number,
      last_invoice_date: PropTypes.instanceOf(Date),
    }),
    agreement_id: PropTypes.number,
    agreement_number: PropTypes.number,
    di_comment: PropTypes.string,
    user_license_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  currentlyLoggedInUser: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
};

export default DidNumberBasicDetail;
