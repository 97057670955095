import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.apiEndPoint()}/agreements/:id/credit_notes`,
};

class CreditNoteApi extends AuthenticatedRequest {
  /**
   * Fetch all credit notes of an agreement
   *
   * @param {int} agreementID id of an agreement
   *
   */
  static fetchAll(agreementID) {
    const indexUrl = URLS.INDEX.replace(":id", agreementID);

    return this.get(indexUrl);
  }
}

export default CreditNoteApi;
