// eslint-disable-next-line import/prefer-default-export
export const SUBSCRIPTION_LINE_TYPE = {
  SETUP: "setup",
  SUBSCRIPTION: "subscription",
  USAGE: "usage",
  ADD_ON: "add_on",
};

export const SUBSCRIPTION_LINE_PROPERTIES = {
  TOPIC: "Topic",
};
