import React from "react";
import Skeleton from "react-loading-skeleton";
import Col from "reactstrap/lib/Col";

import DetailListSkeleton from "components/Skeleton/DetailListSkeleton";
import HeaderSkeleton from "components/Skeleton/HeaderSkeleton";

const UserProfileSkeleton = () => (
  <div className="container-fluid">
    <HeaderSkeleton />
    <div className="row justify-content-between align-items-center">
      <Col md={4}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Skeleton height={100} width={100} circle className="mb-4" />
          <Skeleton height={20} width={150} />
        </div>
      </Col>
      <DetailListSkeleton />
    </div>
  </div>
);

export default UserProfileSkeleton;
