import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";
import { useLocation, useNavigate } from "react-router-dom";
import { Translate } from "react-i18nify";

import {
  numberPortingRequestFetchRequest,
  searchNumberPortingRequests,
  numberPortingRequestsSearchRequest,
} from "redux/actions/numberPortingRequest";

import { PORTING_REQUEST_NUMBER_TYPE, STATUS } from "constants/filters";
import { NUMBER_PORTING_REQUEST } from "constants/routes";

import filterSearchResults from "utils/filterSearchResults";
import { getParams } from "utils/queryString";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import TranslateDate from "components/i18n/TranslateDate";
import ResourceHeader from "components/ResourceHeader/ResourceHeader";

const renderExportTranslateDate = ({ cellData }) => (
  <TranslateDate date={cellData} />
);

const ExportIndex = ({
  fetchAllNumberPortingRequests,
  numberPortingRequests,
  isLoading,
  searchRecords,
  isSearching,
  fetchError,
}) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const { status = "active", number_type: numberType = "cell_phone" } =
    getParams(search);

  useEffect(() => {
    fetchAllNumberPortingRequests(status, numberType);
  }, [status, numberType]);

  useEffect(() => {
    const searchParams = `?status=${status}&number_type=${numberType}`;

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath, { replace: true });
  }, [status, numberType]);

  const changeFilter = ({
    status: selectedStatus,
    numberType: selectedNumberType,
  }) => {
    const searchParams = `?status=${selectedStatus}&number_type=${selectedNumberType}`;

    navigate(`${NUMBER_PORTING_REQUEST.EXPORTS}${searchParams}`);
  };

  const allFilters = [
    {
      filter: STATUS,
      selectedFilter: status,
      type: "status",
      headerTranslation: "attributes.status",
    },
    {
      filter: PORTING_REQUEST_NUMBER_TYPE,
      selectedFilter: numberType,
      type: "numberType",
      headerTranslation: "attributes.numberType",
    },
  ];

  return (
    <RecordListingTable
      records={numberPortingRequests}
      isLoading={isLoading}
      resourceName="number_porting_requests/exports"
      resourceTitle={
        <Translate value="title.page.numberPortingRequest.exports" />
      }
      searchRecords={searchRecords}
      isSearching={isSearching}
      fetchError={fetchError}
      onRetry={fetchAllNumberPortingRequests}
      recordDetailIDAttribute="ref_number"
      additionalHeaderContent={
        <ResourceHeader changeFilter={changeFilter} allFilters={allFilters} />
      }
      hideAddButton
    >
      <Column
        width={150}
        label={<Translate value="attributes.flowID" />}
        dataKey="ref_number"
      />
      <Column
        width={200}
        label={<Translate value="attributes.debtor" />}
        dataKey="debtor_name"
      />
      <Column
        width={140}
        label={<Translate value="attributes.phone" />}
        dataKey="requested_phone_numbers"
      />
      <Column
        width={150}
        label={<Translate value="attributes.requestedPortingDate" />}
        dataKey="requested_porting_date"
        cellRenderer={renderExportTranslateDate}
      />
      <Column
        width={180}
        label={<Translate value="attributes.exportStatus" />}
        dataKey="export_status"
        flexGrow={1}
      />
      <Column
        width={150}
        label={<Translate value="attributes.confirmedPortingDate" />}
        dataKey="confirmed_porting_date"
        cellRenderer={renderExportTranslateDate}
      />
    </RecordListingTable>
  );
};

ExportIndex.defaultProps = {
  isLoading: true,
  isSearching: false,
  fetchError: null,
};

ExportIndex.propTypes = {
  numberPortingRequests: PropTypes.arrayOf(
    PropTypes.shape({
      ref_number: PropTypes.string,
      customer_email: PropTypes.string,
      debtor_name: PropTypes.string,
      pbx_ref_number: PropTypes.string,
      requested_phone_numbers: PropTypes.string,
      requested_porting_date: PropTypes.instanceOf(Date),
    })
  ).isRequired,
  isLoading: PropTypes.bool,
  fetchAllNumberPortingRequests: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  searchRecords: PropTypes.func.isRequired,
  fetchError: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { isLoading, isSearching, numberPortingRequests, fetchError } =
    state.numberPortingRequest;

  const results = filterSearchResults({
    resource: numberPortingRequests,
    searchResult: state.search.numberPortingRequests,
  });

  return {
    numberPortingRequests: results,
    isLoading,
    isSearching,
    fetchError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchRecords: (e) => {
    dispatch(numberPortingRequestsSearchRequest(e));
    dispatch(searchNumberPortingRequests(e));
  },
  fetchAllNumberPortingRequests: (status, numberType) => {
    dispatch(numberPortingRequestFetchRequest("export", numberType, status));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportIndex);
