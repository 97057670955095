import React from "react";
import { Field, useField } from "react-final-form";
import { FormGroup } from "reactstrap";
import PropTypes from "prop-types";

import { renderInputField } from "components/ReduxForm/RenderField";

import { required } from "utils/validation";

import {
  SUBCRIPTION_LINE_TYPE,
  SUBSCRIPTION_LINE_PROPERTY_NAME,
} from "constants/constant";

const TerminationAddressField = ({ fields, subscriptionType }) => {
  const {
    input: { value: properties },
  } = useField(fields.name);

  if (subscriptionType !== SUBCRIPTION_LINE_TYPE.INTERNET_CONNECTIONS)
    return null;

  return properties.map(({ property }, index) => {
    if (property !== SUBSCRIPTION_LINE_PROPERTY_NAME.TERMINATION_ADDRESS)
      return null;

    return (
      <FormGroup>
        <Field
          name={`${fields.name}[${index}].value`}
          component={renderInputField}
          validate={required}
          placeholderTranslation="attributes.terminationAddress"
          labelTranslation="attributes.terminationAddress"
        />
      </FormGroup>
    );
  });
};

TerminationAddressField.defaultProps = {};

TerminationAddressField.propTypes = {
  fields: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  subscriptionType: PropTypes.string.isRequired,
};

export default TerminationAddressField;
