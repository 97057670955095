import {
  faEye,
  faTrash,
  faEnvelope,
  faRightLeft,
} from "@fortawesome/free-solid-svg-icons";

import { DROPDOWN_ELLIPSIS_ACTIONS } from "constants/constant";

const dropdownEllipsisList = [
  {
    name: "menu.dropdown.quotation.preview",
    icon: faEye,
    actionType: DROPDOWN_ELLIPSIS_ACTIONS.QUOTATION_PREVIEW,
    hasPermission: false,
    perform: "",
  },
  {
    name: "menu.dropdown.quotation.delete",
    icon: faTrash,
    actionType: DROPDOWN_ELLIPSIS_ACTIONS.DELETE_MODAL,
    hasPermission: false,
    perform: "",
  },
  {
    name: "menu.dropdown.quotation.send",
    icon: faEnvelope,
    actionType: DROPDOWN_ELLIPSIS_ACTIONS.SEND_EMAIL,
    hasPermission: false,
    perform: "",
  },
  {
    name: "menu.dropdown.quotation.convertToAgreement",
    icon: faRightLeft,
    actionType: DROPDOWN_ELLIPSIS_ACTIONS.CONVERT_TO_AGREEMENT,
    hasPermission: false,
    perform: "",
  },
];

export default dropdownEllipsisList;
