import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.pbxEndPoint()}/call_rates`,
};

class CallRateApi extends AuthenticatedRequest {
  /**
   * Fetch all call rates
   *
   */
  static fetchAll() {
    return this.get(URLS.INDEX);
  }
}

export default CallRateApi;
