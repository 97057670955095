import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faArchive,
  faFileInvoice,
  faTty,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Spinner } from "reactstrap";
import { Translate } from "react-i18nify";

import {
  singleAgreementFetchRequest,
  agreementDeleteRequest,
} from "redux/actions/agreement";
import { invoiceGenerateRequest } from "redux/actions/agreement/invoice";
import { paymentTermFetchRequest } from "redux/actions/paymentTerm";
import { productFetchRequest } from "redux/actions/product";

import HasPermission from "hoc/RbacCTA";

import AgreementInformation from "components/Agreement/AgreementDetail/AgreementInformation/AgreementInformation";
import SubscriptionTab from "components/Agreement/AgreementDetail/Subscription/SubscriptionTab/SubscriptionTab";
import SubscriptionGroupTab from "components/Agreement/AgreementDetail/Subscription/SubscriptionGroupTab/SubscriptionGroupTab";
import FetchError from "components/Error/FetchError";
import DeleteButton from "components/Button/DeleteButton";
import DeleteModal from "components/Modal/DeleteModal";
import AgreementDetailSkeleton from "components/Skeleton/ResourceDetail/AgreementDetail/AgreementDetailSkeleton";

import { AGREEMENT } from "constants/routes";

const AgreementDetail = ({
  agreement,
  fetchAgreement,
  fetchPaymentTerms,
  fetchProducts,
  paymentTerms,
  products,
  isPaymentTermFetching,
  isProductFetching,
  isInvoiceGenerating,
  isLoading,
  deleteAgreement,
  generateInvoice,
  fetchError,
}) => {
  const navigate = useNavigate();
  const { agreementID } = useParams();

  const fetchAgreementData = () => {
    fetchAgreement(agreementID);
    fetchPaymentTerms();
    fetchProducts();
  };

  const [modalData, setModalData] = useState();
  const [modalVisible, setModalVisibility] = useState(false);

  const toggle = () => {
    setModalData(agreementID);
    setModalVisibility(!modalVisible);
  };

  const resetModalState = () => {
    setModalVisibility(false);
    setModalData();
  };

  const onDeleteSuccess = () => {
    navigate(AGREEMENT.INDEX);
  };

  const onConfirmDelete = (id) => {
    if (id) deleteAgreement(id, onDeleteSuccess);

    resetModalState();
  };

  const onGenerateInvoice = (id) => {
    generateInvoice(id);
  };

  useEffect(() => {
    fetchAgreementData();
  }, [agreementID]);

  if (fetchError) {
    return <FetchError onRetry={fetchAgreementData} errorText={fetchError} />;
  }

  if (
    !agreement ||
    isPaymentTermFetching ||
    isProductFetching ||
    isLoading ||
    !agreement?.subscriptions
  ) {
    return <AgreementDetailSkeleton />;
  }

  if (isInvoiceGenerating)
    return <Spinner as="span" size="sm" className="ms-2" />;

  const {
    ref_number: agreementNumber,
    is_archived: isAgreementArchived,
    subscriptions,
    active_subscriptions_count: activeSubscriptionsCount,
    archived_subscriptions_count: archivedSubscriptionsCount,
    is_billable: billable,
    is_voip_billing_enabled: enableVoipBilling,
    odoo_debtor_id: odooDebtorID,
  } = agreement;

  const subscriptionsContent = isAgreementArchived ? (
    <SubscriptionGroupTab
      subscriptions={subscriptions}
      isArchived
      isAgreementArchived
      products={products}
    />
  ) : (
    <SubscriptionTab
      subscriptions={subscriptions}
      products={products}
      isAgreementArchived={isAgreementArchived}
      activeSubscriptionsCount={activeSubscriptionsCount}
      archivedSubscriptionsCount={archivedSubscriptionsCount}
    />
  );

  return (
    <HasPermission
      data={{
        debtorID: odooDebtorID,
      }}
      perform="agreements:view"
      redirect
    >
      <div className="container-fluid agreement-detail">
        <DeleteModal
          modalVisible={modalVisible}
          modalData={modalData}
          resourceName="agreement"
          toggleModal={toggle}
          onConfirm={onConfirmDelete}
          onCancel={resetModalState}
        />
        <div className="bg-white full-width-top-header shadow-sm">
          <div className="d-flex justify-content-between align-items-center page-header p-4">
            <div className="d-md-flex">
              <h3 className="m-0 d-flex justify-content-between align-items-center">
                {agreementNumber}
              </h3>

              <span
                className="is-archived ms-3 px-2 py-2"
                hidden={!isAgreementArchived}
                data-field-name="agreement-archive-label"
              >
                <FontAwesomeIcon icon={faArchive} className="me-1" />
                <Translate value="attributes.isArchived" />
              </span>

              <HasPermission perform="agreement:statusLabel">
                <span
                  className="is-archived ms-3 px-2 py-2"
                  hidden={billable}
                  data-field-name="agreement-billing-label"
                >
                  <FontAwesomeIcon icon={faFileInvoice} className="me-1" />
                  <Translate value="common.billingDisabled" />
                </span>

                <span
                  className="is-archived ms-3 px-2 py-2"
                  hidden={enableVoipBilling}
                  data-field-name="agreement-voip-billing-label"
                >
                  <FontAwesomeIcon icon={faTty} className="me-1" />
                  <Translate value="common.voipBillingDisabled" />
                </span>
              </HasPermission>
            </div>

            <div className="d-md-flex">
              <HasPermission perform="agreement:generateInvoice">
                <button
                  className="btn btn-primary me-2 d-flex justify-content-center align-items-center"
                  hidden={isAgreementArchived}
                  onClick={() => onGenerateInvoice(agreementID)}
                  type="button"
                  data-cy="generate-invoice-button"
                >
                  <Translate value="common.generateInvoice" />
                </button>
              </HasPermission>
              <HasPermission perform="agreements:edit">
                <Link
                  to={AGREEMENT.addId(AGREEMENT.EDIT, agreementID)}
                  className="btn btn-primary"
                  hidden={isAgreementArchived}
                  data-cy="edit-btn"
                >
                  <FontAwesomeIcon icon={faPencilAlt} className="me-2" />
                  <Translate value="common.edit" />
                </Link>
              </HasPermission>
              <HasPermission perform="agreements:delete">
                <DeleteButton handleDelete={toggle} />
              </HasPermission>
            </div>
          </div>
          <AgreementInformation
            agreement={agreement}
            paymentTerms={paymentTerms}
          />
        </div>

        <Row className="mt-3">
          <Col md="12">{subscriptionsContent}</Col>
        </Row>
      </div>
    </HasPermission>
  );
};

AgreementDetail.defaultProps = {
  isLoading: true,
  fetchError: null,
  isPaymentTermFetching: true,
  isProductFetching: true,
  isInvoiceGenerating: false,
};

AgreementDetail.propTypes = {
  fetchAgreement: PropTypes.func.isRequired,
  deleteAgreement: PropTypes.func.isRequired,
  fetchPaymentTerms: PropTypes.func.isRequired,
  fetchProducts: PropTypes.func.isRequired,
  generateInvoice: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  fetchError: PropTypes.string,
  agreement: PropTypes.shape({
    ref_number: PropTypes.string,
    active_subscriptions_count: PropTypes.number,
    archived_subscriptions_count: PropTypes.number,
    is_archived: PropTypes.bool,
    subscriptions: PropTypes.arrayOf(PropTypes.shape({})),
    is_billable: PropTypes.bool,
    odoo_debtor_id: PropTypes.number,
    is_voip_billing_enabled: PropTypes.bool,
  }).isRequired,
  paymentTerms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isPaymentTermFetching: PropTypes.bool,
  isProductFetching: PropTypes.bool,
  isInvoiceGenerating: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { agreement, isLoading, fetchError } = state.agreement;
  const { paymentTerms, isLoading: isPaymentTermFetching } = state.paymentTerm;
  const { products, isLoading: isProductFetching } = state.product;
  const { isLoading: isInvoiceGenerating } = state.agreementBilling.invoice;

  return {
    fetchError,
    agreement,
    isLoading,
    paymentTerms,
    isPaymentTermFetching,
    products,
    isProductFetching,
    isInvoiceGenerating,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAgreement: (id) => {
    dispatch(singleAgreementFetchRequest(id));
  },
  fetchPaymentTerms: () => {
    dispatch(paymentTermFetchRequest());
  },
  fetchProducts: () => {
    dispatch(productFetchRequest());
  },
  generateInvoice: (id) => {
    dispatch(invoiceGenerateRequest(id));
  },
  deleteAgreement: (id, cb) => {
    dispatch(agreementDeleteRequest(id, cb));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AgreementDetail);
