import { UserSettingApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

export const USER_SETTING_FETCH_REQUEST = "USER_SETTING_FETCH_REQUEST";
export const USER_SETTING_FETCH_RECEIVED = "USER_SETTING_FETCH_RECEIVED";
export const USER_SETTING_FETCH_FAILED = "USER_SETTING_FETCH_FAILED";
export const USER_SETTING_FETCH_CLEAR_ERRORS =
  "USER_SETTING_FETCH_CLEAR_ERRORS";
export const USER_SETTING_UPDATE_REQUEST = "USER_SETTING_UPDATE_REQUEST";
export const USER_SETTING_UPDATE_RECEIVED = "USER_SETTING_UPDATE_RECEIVED";
export const USER_SETTING_UPDATE_FAILED = "USER_SETTING_UPDATE_FAILED";

// Fetch
export function userSettingFetchReceived(response) {
  return {
    type: USER_SETTING_FETCH_RECEIVED,
    userSettings: response.data,
  };
}

export function userSettingFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: USER_SETTING_FETCH_FAILED,
    message,
  };
}

export function userSettingFetchClearErrors() {
  return {
    type: USER_SETTING_FETCH_CLEAR_ERRORS,
  };
}

export const userSettingFetchRequest = (userID) => (dispatch) => {
  dispatch({ type: USER_SETTING_FETCH_REQUEST });

  UserSettingApi.fetchAll(userID)
    .then((data) => dispatch(userSettingFetchReceived(data)))
    .catch((e) => {
      dispatch(userSettingFetchFailed(e));
    });
};

// Update
export function userSettingUpdateReceived(response) {
  return {
    type: USER_SETTING_UPDATE_RECEIVED,
    userSettings: response.data,
  };
}

export function userSettingUpdateFailed(errorData, callback) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: USER_SETTING_UPDATE_FAILED,
    message: response.data,
  };
}

export const userSettingUpdateRequest =
  (userID, userSettings, callback) => async (dispatch) => {
    dispatch({ type: USER_SETTING_UPDATE_REQUEST });

    await UserSettingApi.updateAll(userID, userSettings)
      .then((data) => {
        dispatch(userSettingUpdateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(userSettingUpdateFailed(errors, callback));
      });
  };
