import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";
import { Translate } from "react-i18nify";
import { useLocation, useNavigate } from "react-router-dom";

import {
  userFetchRequest,
  searchUsers,
  userSearchRequest,
  userDeleteRequest,
} from "redux/actions/user";

import filterSearchResults from "utils/filterSearchResults";
import { getParams } from "utils/queryString";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import ResourceHeader from "components/ResourceHeader/ResourceHeader";

import { PBX_FILTERS, ROLES } from "constants/filters";

const UserIndex = ({
  fetchAllUsers,
  users,
  isLoading,
  searchRecords,
  isSearching,
  fetchError,
}) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const { status = "active", role_type: roleType = "company_users" } =
    getParams(search);

  const fetchData = () => {
    fetchAllUsers(status, roleType);
  };

  useEffect(() => {
    fetchData(status, roleType);
  }, [status, roleType]);

  useEffect(() => {
    const searchParams = `?status=${status}&role_type=${roleType}`;

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath, { replace: true });
  }, [status, roleType]);

  const changeFilter = ({ statusType, role }) => {
    const searchParams = `?status=${statusType}&role_type=${role}`;

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath, { replace: true });
  };

  const allFilters = [
    {
      filter: PBX_FILTERS,
      selectedFilter: status,
      type: "statusType",
      headerTranslation: "user.status",
    },
    {
      filter: ROLES,
      selectedFilter: roleType,
      type: "role",
      headerTranslation: "user.roleType",
    },
  ];

  return (
    <RecordListingTable
      records={users}
      isLoading={isLoading && !fetchError}
      fetchError={fetchError}
      onRetry={fetchData}
      resourceName="users"
      additionalHeaderContent={
        <ResourceHeader changeFilter={changeFilter} allFilters={allFilters} />
      }
      resourceTitle={
        <Translate value="title.page.resource.list" resource="User" />
      }
      searchRecords={searchRecords}
      isSearching={isSearching}
      dataCy="create-user"
    >
      <Column
        width={180}
        label={<Translate value="attributes.firstName" />}
        dataKey="first_name"
      />
      <Column
        width={180}
        label={<Translate value="attributes.lastName" />}
        dataKey="last_name"
      />
      <Column
        width={200}
        label={<Translate value="attributes.email" />}
        dataKey="email"
      />
      <Column
        width={200}
        label={<Translate value="attributes.affiliation" />}
        dataKey="debtor_name"
      />
      <Column
        width={180}
        label={<Translate value="attributes.role" />}
        dataKey="role"
      />
    </RecordListingTable>
  );
};

UserIndex.defaultProps = {
  isLoading: true,
  users: undefined,
  isSearching: false,
  fetchError: null,
};

UserIndex.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      business_name: PropTypes.string,
    })
  ),
  searchRecords: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  fetchAllUsers: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  fetchError: PropTypes.string,
};

const mapstatusToProps = (status) => {
  const { users, isLoading, isSearching, fetchError } = status.user;

  const results = filterSearchResults({
    resource: users,
    searchResult: status.search.users,
  });

  return {
    debtors: status.debtor.debtors,
    users: results,
    isLoading,
    isSearching,
    fetchError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchRecords: (e) => {
    dispatch(userSearchRequest(e));
    dispatch(searchUsers(e));
  },
  fetchAllUsers: (filter, roleType) => {
    dispatch(userFetchRequest(filter, roleType));
  },
  deleteUser: (id) => {
    dispatch(userDeleteRequest(id));
  },
});

export default connect(mapstatusToProps, mapDispatchToProps)(UserIndex);
