import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { format, parseISO } from "date-fns";
import FocusTrap from "focus-trap-react";
import { DayPicker } from "react-day-picker";
import { usePopper } from "react-popper";
import ClearDateIcon from "components/ReduxForm/DateField/ClearDateIcon";
import InputLabel from "components/ReduxForm/InputLabel";
import FormError from "components/Form/FormError";
import DotsLoadingIndicator from "components/Spinner/DotsLoadingIndicator";

const Loader = ({ show }) => {
  if (!show) return null;

  return (
    <DotsLoadingIndicator className="position-absolute top-50 end-0 me-2" />
  );
};

// eslint-disable-next-line react/jsx-no-useless-fragment
const renderEmptyFocusElement = () => <></>;

const DatePicker = ({
  input: { name, onChange, value, ...input },
  meta: { error, warning, submitError, touched },
  disabled,
  isLoadingDate,
  label,
  labelTranslation,
  replacementTranslations,
  disabledDays,
  datePickerClassNames,
  inputClassName,
  dataTestId,
}) => {
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef(null);
  const inputRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);

  const popper = usePopper(popperRef.current, popperElement, {
    placement: "bottom-start",
  });

  const closePopper = () => {
    setIsPopperOpen(false);
  };

  const handleInputClick = () => {
    setIsPopperOpen(true);
  };

  const handleDaySelect = (selectedDate) => {
    if (selectedDate) {
      onChange(selectedDate);
    }

    closePopper();
  };

  const clearDateHandler = () => {
    onChange(null);
  };

  const parsedDateValue = value && parseISO(new Date(value).toISOString());
  const dateInputValue = parsedDateValue
    ? format(parsedDateValue, "y-M-d")
    : "";
  const selectedDate = value ? new Date(value) : null;

  return (
    <div data-testid={dataTestId} className="date-field">
      <InputLabel
        name={name}
        label={label}
        labelTranslation={labelTranslation}
        replacementTranslations={replacementTranslations.label}
      />
      <div ref={popperRef}>
        <div className="position-relative">
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...input}
            id={name}
            name={name}
            ref={inputRef}
            onClick={handleInputClick}
            onChange={onChange}
            type="text"
            placeholder="YYYY-M-D"
            value={dateInputValue}
            className={`form-control ${inputClassName}`}
            data-field-name={name}
            data-testid={name}
            disabled={disabled || isLoadingDate}
            readOnly
          />
          <Loader show={isLoadingDate} />
          <ClearDateIcon
            onClick={clearDateHandler}
            value={value}
            disabled={disabled}
          />
        </div>
        <FormError
          touched={touched}
          className="invalid-text"
          error={error}
          submitError={submitError}
          warning={warning}
          name={name}
        />
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: inputRef.current,
            clickOutsideDeactivates: true,
            onDeactivate: () => closePopper(),
            setReturnFocus: renderEmptyFocusElement,
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className="dialog-sheet"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
            aria-label="DayPicker calendar"
          >
            <DayPicker
              mode="single"
              defaultMonth={selectedDate}
              selected={selectedDate}
              onSelect={handleDaySelect}
              disabled={[disabledDays]}
              classNames={datePickerClassNames}
              modifiersStyles={{
                selected: { backgroundColor: "#37b772" },
                today: { color: "#e35d6a" },
              }}
            />
          </div>
        </FocusTrap>
      )}
    </div>
  );
};

Loader.defaultProps = {
  show: false,
};

Loader.propTypes = {
  show: PropTypes.bool,
};

DatePicker.defaultProps = {
  label: undefined,
  disabledDays: undefined,
  disabled: false,
  labelTranslation: undefined,
  replacementTranslations: {
    label: {},
    placeholder: {},
  },
  isLoadingDate: false,
  datePickerClassNames: "",
  inputClassName: "",
  dataTestId: "",
};

DatePicker.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
    submitError: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  labelTranslation: PropTypes.string,
  disabledDays: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape()),
  ]),
  disabled: PropTypes.bool,
  replacementTranslations: PropTypes.shape({
    label: PropTypes.shape({}),
    placeholder: PropTypes.shape({}),
  }),
  isLoadingDate: PropTypes.bool,

  datePickerClassNames: PropTypes.string,
  inputClassName: PropTypes.string,
  dataTestId: PropTypes.string,
};

export default DatePicker;
