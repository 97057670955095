import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Navigate, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Translate, t } from "react-i18nify";
import { Form } from "react-final-form";

import {
  userLicenseUpdateRequest,
  singleUserLicenseFetchRequest,
} from "redux/actions/userLicense";

import { USER_LICENSE } from "constants/routes";

import UserLicenseForm from "components/UserLicense/Form/UserLicenseForm";
import WholePageSpinner from "components/Spinner/WholePageSpinner";
import PageNotFound from "components/Error/PageNotFound/PageNotFound";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const UserLicenseEdit = ({
  updateUserLicense,
  commitSuccess,
  userLicense,
  isLoading,
  fetchSingleUserLicense,
}) => {
  const { userLicenseID } = useParams();

  useEffect(() => {
    fetchSingleUserLicense(userLicenseID);
  }, [userLicenseID]);

  const onSubmitHandler = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    const { id } = userLicense;

    await updateUserLicense(id, values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess) {
    const { id } = userLicense;
    const userLicenseTranslation = t("attributes.userLicense");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.update", {
        resource: userLicenseTranslation,
      })
    );

    return <Navigate to={USER_LICENSE.addId(USER_LICENSE.SHOW, id)} />;
  }

  if (!userLicense) {
    return <WholePageSpinner />;
  }

  const {
    is_active: isActive,
    termination_date_end_limit: terminationDateEndLimit,
    subscription_line: { id: subscriptionLineId },
    user: { role_id: roleID, user_role_id: userRoleID },
  } = userLicense;

  if (!isActive) {
    return (
      <div className="d-flex h-100 justify-content-center">
        <PageNotFound />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.edit"
            resource={t("attributes.userLicense")}
          />
        </h3>
      </div>
      <Form
        initialValues={{
          ...userLicense,
          user: {
            ...userLicense.user,
            user_role: { id: userRoleID, role_id: roleID },
          },
        }}
        onSubmit={onSubmitHandler}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, pristine }) => (
          <form
            className="flexo-form px-4 py-4 pt-5 w-100"
            onSubmit={handleSubmit}
          >
            <UserLicenseForm
              formType="edit"
              submitting={submitting}
              pristine={pristine}
              isLoading={isLoading}
              initialValues={{
                ...userLicense,
                user: {
                  ...userLicense.user,
                  user_role: { id: userRoleID, role_id: roleID },
                },
              }}
              terminationDateEndLimit={terminationDateEndLimit}
              subscriptionLineId={subscriptionLineId}
            />
          </form>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = ({ userLicense: userLicenseReducer }) => {
  const {
    userLicenseError,
    userLicense,
    commitError,
    commitSuccess,
    isLoading,
  } = userLicenseReducer;

  return {
    userLicenseUpdateError: userLicenseError,
    isLoading,
    userLicense,
    commitError,
    commitSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleUserLicense: (id) => {
    dispatch(singleUserLicenseFetchRequest(id));
  },
  updateUserLicense: async (id, attributes, callback) => {
    await dispatch(userLicenseUpdateRequest(id, attributes, callback));
  },
});

UserLicenseEdit.defaultProps = {
  userLicense: undefined,
  commitError: {},
};

UserLicenseEdit.propTypes = {
  updateUserLicense: PropTypes.func.isRequired,
  commitSuccess: PropTypes.bool.isRequired,
  commitError: PropTypes.shape({}),
  isLoading: PropTypes.bool.isRequired,
  fetchSingleUserLicense: PropTypes.func.isRequired,
  userLicense: PropTypes.shape({
    id: PropTypes.number,
    user: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      role_id: PropTypes.number,
      user_role_id: PropTypes.number,
    }),
    is_active: PropTypes.bool,
    termination_date_end_limit: PropTypes.instanceOf(Date),
    subscription_line: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLicenseEdit);
