import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.apiEndPoint()}/settings`,
  UPDATE: `${apiUrlConfig.apiEndPoint()}/settings/:id`,
  CREATE: `${apiUrlConfig.apiEndPoint()}/settings`,
  SHOW: `${apiUrlConfig.apiEndPoint()}/settings/:id`,
  DELETE: `${apiUrlConfig.apiEndPoint()}/settings/:id`,
};

class SettingApi extends AuthenticatedRequest {
  /**
   * Fetch all settings in the system
   *
   * Explicit to super admins
   *
   */
  static fetchAll() {
    return this.get(URLS.INDEX);
  }

  /**
   * Update a setting
   *
   * @param {int} id ID of a setting
   *
   * @param {object} attributes attributes of a setting
   * @param {string} name name of a setting
   * @param {string} category category of a setting
   * @param {string[]} options array of setting options
   * @param {string} data_type data type for a setting
   * @param {string} display_type display type based on data type of a setting (input field? dropdown?)
   * @param {string} description description of a setting
   * @param {string} userType type of a user that can use or see the setting
   *
   */
  static update(
    id,
    {
      name,
      category,
      data_type: dataType,
      user_type: userType,
      options,
      display_type: displayType,
      description,
    }
  ) {
    const updateUrl = URLS.UPDATE.replace(":id", id);

    const requestBody = {
      setting: {
        name,
        category,
        data_type: dataType,
        user_type: userType,
        options,
        display_type: displayType,
        description,
      },
    };

    return this.patch(updateUrl, requestBody);
  }

  /**
   * Create a setting
   *
   * @param {object} attributes attributes of a setting
   * @param {string} name name of a setting
   * @param {string} category category of a setting
   * @param {string[]} options array of setting options
   * @param {string} data_type data type for a setting
   * @param {string} display_type display type based on data type of a setting (input field? dropdown?)
   * @param {string} description description of a setting
   * @param {string} userType type of a user that can use or see the setting
   *
   */
  static create({
    name,
    category,
    data_type: dataType,
    user_type: userType,
    options,
    display_type: displayType,
    description,
  }) {
    const requestBody = {
      setting: {
        name,
        category,
        data_type: dataType,
        user_type: userType,
        options,
        display_type: displayType,
        description,
      },
    };

    return this.post(URLS.CREATE, requestBody);
  }

  /**
   * Fetch single setting
   *
   * @param {int} id ID of a setting to fetch
   */
  static fetchOne(id) {
    const fetchOneUrl = URLS.SHOW.replace(":id", id);

    return this.get(fetchOneUrl);
  }

  /**
   * Delete single setting
   *
   * @param {int} id ID of a setting to delete
   */
  static deleteOne(id) {
    const deleteUrl = URLS.DELETE.replace(":id", id);

    return this.delete(deleteUrl);
  }
}

export default SettingApi;
