import React from "react";
import PropTypes from "prop-types";

const Pill = ({ content, show, className }) => {
  if (!show) return null;

  return <span className={`pill status ${className}`}>{content}</span>;
};

Pill.defaultProps = {};

Pill.propTypes = {
  show: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default Pill;
