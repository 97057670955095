import apiUrlConfig from "configs/apiUrlConfig";
import { AuthenticatedRequest } from "internal";

const URLS = {
  INDEX: `${apiUrlConfig.apiEndPoint()}/pbxes/all?filter=:filterName`,
  SHOW: `${apiUrlConfig.apiEndPoint()}/pbxes/:id`,
  UPDATE: `${apiUrlConfig.apiEndPoint()}/pbxes/:id`,
  CREATE: `${apiUrlConfig.apiEndPoint()}/pbxes`,
  DELETE: `${apiUrlConfig.apiEndPoint()}/pbxes/:id`,
  USER_LICENSES_DROPDOWN: `${apiUrlConfig.apiEndPoint()}/pbxes/:id/user_licenses_dropdown`,
  USER_LICENSES: `${apiUrlConfig.apiEndPoint()}/pbxes/:pbx_id/user_licenses`,
  SIP_DEVICES: `${apiUrlConfig.apiEndPoint()}/pbxes/:id/sip_devices`,
};

class PbxApi extends AuthenticatedRequest {
  /**
   * Fetch all pbxs
   * @param {string} filter filter to fetch filtered pbx
   */
  static fetchAll(filter, dropDown) {
    if (dropDown) {
      const fetchAllUrl = URLS.INDEX.replace(
        "?filter=:filterName",
        "?dropdown=true"
      );

      return this.get(fetchAllUrl);
    }

    const filterUrl = `${URLS.INDEX.replace(
      ":filterName",
      filter
    )}&with_debtor_name=true`;

    return this.get(filterUrl);
  }

  /**
   * Fetch single pbx by its ID
   *
   * @param {int} id ID of a pbx to be fetched
   */
  static fetchOne(id) {
    const fetchOneUrl = URLS.SHOW.replace(":id", id);

    return this.get(fetchOneUrl);
  }

  /**
   * Update a pbx
   *
   * @param {int} id ID of a pbx
   *
   * @param {object} attributes attributes of a pbx
   * @param {string} name name of a pbx
   * @param {number} payment_type payment type for a pbx (post paid OR pre paid)
   * @param {number} max_channels maximum channel for a pbx
   * @param {number} remote_routing_profile_id routing profile id for a pbx
   * @param {number} remote_campaign_routing_profile_id campaign routing profile for a pbx
   * @param {number} remote_call_rate_id call rate id for a pbx
   * @param {date} deployment_date deployment date for a pbx
   * @param {date} invoice_start_date invoice start date for a pbx
   * @param {number} odoo_debtor_id debtor for whom pbx belongs to
   * @param {number} agreement_id agreement where pbx subscriptions are saved
   *
   * @param {object} agreement_attributes attributes for an agreement for linking to pbx
   * @param {string} invoice_period invoice period for agreement invoicing
   *
   */
  static update(id, attributes) {
    const updateUrl = URLS.UPDATE.replace(":id", id);

    const requestBody = {
      pbx: {
        ...attributes,
      },
    };

    return this.patch(updateUrl, requestBody);
  }

  /**
   * Create a pbx
   *
   * @param {object} attributes attributes of a pbx
   * @param {string} name name of a pbx
   * @param {number} payment_type payment type for a pbx (post paid OR pre paid)
   * @param {number} max_channels maximum channel for a pbx
   * @param {number} remote_routing_profile_id routing profile id for a pbx
   * @param {number} remote_campaign_routing_profile_id campaign routing profile for a pbx
   * @param {number} remote_call_rate_id call rate id for a pbx
   * @param {date} deployment_date deployment date for a pbx
   * @param {date} invoice_start_date invoice start date for a pbx
   * @param {number} odoo_debtor_id debtor for whom pbx belongs to
   * @param {number} agreement_id agreement where pbx subscriptions are saved
   *
   * @param {object} agreement_attributes attributes for an agreement for linking to pbx
   * @param {string} invoice_period invoice period for agreement invoicing
   *
   */
  static create(attributes) {
    const requestBody = {
      pbx: {
        ...attributes,
      },
    };

    return this.post(URLS.CREATE, requestBody);
  }

  /**
   * delete pbx
   *
   * @param {int} id ID of a pbx to delete
   */
  static deleteOne(id) {
    const updateUrl = URLS.DELETE.replace(":id", id);

    return this.delete(updateUrl);
  }

  /**
   * Fetch user licenses under pbx
   *
   * @param {int} id ID of a pbx
   */
  static fetchUserLicenses(id) {
    const userLicensesUrl = URLS.USER_LICENSES.replace(":pbx_id", id);

    return this.get(userLicensesUrl);
  }

  /**
   * Fetch all sip devices under pbx
   *
   * @param {int} id ID of a pbx
   */
  static fetchAllSipDevices(id) {
    const sipDevicesUrl = URLS.SIP_DEVICES.replace(":id", id);

    return this.get(sipDevicesUrl);
  }
}

export default PbxApi;
