import {
  FETCH_SIM_CARDS_REQUEST,
  FETCH_SIM_CARDS_RECEIVED,
  FETCH_SIM_CARDS_ERROR,
  FETCH_SIM_CARD_DETAIL_REQUEST,
  FETCH_SIM_CARD_DETAIL_RECEIVED,
  FETCH_SIM_CARD_DETAIL_ERROR,
  FETCH_EMPLOYEE_LIST_REQUEST,
  FETCH_EMPLOYEE_LIST_RECEIVED,
  FETCH_EMPLOYEE_LIST_ERROR,
  FETCH_CUSTOMER_LIST_REQUEST,
  FETCH_CUSTOMER_LIST_RECEIVED,
  FETCH_CUSTOMER_LIST_ERROR,
  FETCH_RESELLER_LIST_REQUEST,
  FETCH_RESELLER_LIST_RECEIVED,
  FETCH_RESELLER_LIST_ERROR,
  FETCH_SIM_CARD_BORROWER_LIST_REQUEST,
  FETCH_SIM_CARD_BORROWER_LIST_RECEIVED,
  FETCH_SIM_CARD_BORROWER_LIST_ERROR,
  ASSIGN_SIM_CARD_REQUEST,
  ASSIGN_SIM_CARD_RECEIVED,
  ASSIGN_SIM_CARD_ERROR,
  SEARCH_IMSI_RANGES_REQUEST,
  SEARCH_IMSI_RANGES_RECEIVED,
  SEARCH_IMSI_RANGES_ERROR,
  SEARCH_ICCID_RANGES_REQUEST,
  SEARCH_ICCID_RANGES_RECEIVED,
  SEARCH_ICCID_RANGES_ERROR,
  UPDATE_SIM_CARD_REQUEST,
  UPDATE_SIM_CARD_RECEIVED,
  UPDATE_SIM_CARD_ERROR,
  CLEAR_SIM_CARDS_ERRORS,
} from "redux/actions/simCard";

const defaultState = {
  simCards: [],
  fetchError: "",
  commitSuccess: false,
  isSearching: false,
  isLoading: true,
  isProcessing: false,
  isSubmitting: false,
  employees: [],
  customers: [],
  imsiNumbers: [],
  iccidNumbers: [],
  resellers: [],
  simCardBorrowers: [],
};

function simCard(state = defaultState, action) {
  switch (action.type) {
    case FETCH_SIM_CARDS_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: null,
      };

    case FETCH_SIM_CARDS_RECEIVED:
      return {
        ...state,
        simCards: action.phoneNumbers,
        isLoading: false,
        fetchError: null,
      };

    case FETCH_SIM_CARDS_ERROR:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case FETCH_SIM_CARD_DETAIL_REQUEST:
      return {
        ...state,
        fetchError: "",
        isLoading: true,
      };

    case FETCH_SIM_CARD_DETAIL_RECEIVED:
      return {
        ...state,
        isLoading: false,
        simCard: action.simCard,
      };

    case FETCH_SIM_CARD_DETAIL_ERROR:
      return {
        ...state,
        isLoading: false,
        fetchError: action.message,
      };

    case FETCH_EMPLOYEE_LIST_REQUEST:
      return {
        ...state,
        isProcessing: true,
        fetchError: "",
        commitSuccess: false,
      };

    case FETCH_EMPLOYEE_LIST_RECEIVED:
      return {
        ...state,
        isProcessing: false,
        employees: action.employees,
      };

    case FETCH_EMPLOYEE_LIST_ERROR:
      return {
        ...state,
        isProcessing: false,
        fetchError: action.message,
      };

    case FETCH_CUSTOMER_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitSuccess: false,
      };

    case FETCH_CUSTOMER_LIST_RECEIVED:
      return {
        ...state,
        isLoading: false,
        customers: action.customers,
      };

    case FETCH_CUSTOMER_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        fetchError: action.message,
      };

    case ASSIGN_SIM_CARD_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        fetchError: "",
      };

    case ASSIGN_SIM_CARD_RECEIVED:
      return {
        ...state,
        isSubmitting: false,
        commitSuccess: true,
      };

    case ASSIGN_SIM_CARD_ERROR:
      return {
        ...state,
        isSubmitting: false,
        fetchError: action.message,
      };

    case CLEAR_SIM_CARDS_ERRORS:
      return {
        ...state,
        fetchError: "",
        isLoading: false,
        commitSuccess: false,
      };

    case UPDATE_SIM_CARD_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        fetchError: "",
        commitSuccess: false,
      };

    case UPDATE_SIM_CARD_RECEIVED:
      return {
        ...state,
        isSubmitting: false,
        commitSuccess: true,
      };

    case UPDATE_SIM_CARD_ERROR:
      return {
        ...state,
        isLoading: false,
        fetchError: action.message,
      };

    case SEARCH_IMSI_RANGES_REQUEST:
      return {
        ...state,
        isSearching: true,
        fetchError: "",
        commitSuccess: "",
      };

    case SEARCH_IMSI_RANGES_RECEIVED:
      return {
        ...state,
        isSearching: false,
        imsiNumbers: action.imsiNumbers,
      };

    case SEARCH_IMSI_RANGES_ERROR:
      return {
        ...state,
        isSearching: false,
        fetchError: action.message,
      };

    case SEARCH_ICCID_RANGES_REQUEST:
      return {
        ...state,
        isSearching: true,
        fetchError: "",
        commitSuccess: "",
      };

    case SEARCH_ICCID_RANGES_RECEIVED:
      return {
        ...state,
        isSearching: false,
        iccidNumbers: action.iccidNumbers,
      };

    case SEARCH_ICCID_RANGES_ERROR:
      return {
        ...state,
        isSearching: false,
        fetchError: action.message,
      };

    case FETCH_RESELLER_LIST_REQUEST:
      return {
        ...state,
        isProcessing: true,
        fetchError: "",
        commitSuccess: false,
      };

    case FETCH_RESELLER_LIST_RECEIVED:
      return {
        ...state,
        isProcessing: false,
        resellers: action.resellers,
      };

    case FETCH_RESELLER_LIST_ERROR:
      return {
        ...state,
        isProcessing: false,
        fetchError: action.message,
      };

    case FETCH_SIM_CARD_BORROWER_LIST_REQUEST:
      return {
        ...state,
        isProcessing: true,
        fetchError: "",
        commitSuccess: false,
      };

    case FETCH_SIM_CARD_BORROWER_LIST_RECEIVED:
      return {
        ...state,
        isProcessing: false,
        simCardBorrowers: action.simCardBorrowers,
      };

    case FETCH_SIM_CARD_BORROWER_LIST_ERROR:
      return {
        ...state,
        isProcessing: false,
        fetchError: action.message,
      };

    default:
      return state;
  }
}

export default simCard;
