import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

import { Translate } from "react-i18nify";

import { Spinner } from "reactstrap";

const RefreshButton = ({ isRefreshing, refreshRecords }) => {
  const icon = isRefreshing ? (
    <Spinner data-testid="spinner" />
  ) : (
    <FontAwesomeIcon icon={faRedoAlt} data-testid="fa-RedoAlt" />
  );

  return (
    <button
      onClick={refreshRecords}
      type="button"
      className="btn btn-primary"
      disabled={isRefreshing}
      data-testid="refresh-button"
    >
      {icon}
      <Translate value="common.refresh" />
    </button>
  );
};

RefreshButton.defaultProps = {};

RefreshButton.propTypes = {
  isRefreshing: PropTypes.bool.isRequired,
  refreshRecords: PropTypes.func.isRequired,
};

export default RefreshButton;
