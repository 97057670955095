/* eslint-disable import/no-cycle */
import axios from "axios";

import errorHandlerInterceptor from "services/requests/interceptor";

export const DEFAULT_HEADERS = {
  Accept: "application/json",
};

export const RESPONSE_TYPE_HEADERS = {
  "Response-Type": "blob",
};

// Intercept the response to perform any additional tasks.
axios.interceptors.response.use(null, errorHandlerInterceptor);

class BaseRequest {
  static headers(headers) {
    return { headers: headers || DEFAULT_HEADERS };
  }

  static get(url, fileDownload = false, responseType = "json") {
    if (responseType === "blob") {
      return axios.get(url, this.headers(RESPONSE_TYPE_HEADERS));
    }

    return axios.get(url, this.headers(fileDownload));
  }

  static post(url, data) {
    return axios.post(url, data, this.headers());
  }

  static patch(url, data) {
    return axios.patch(url, data, this.headers());
  }

  static put(url, data) {
    return axios.put(url, data, this.headers());
  }

  static delete(url) {
    return axios.delete(url, this.headers());
  }
}
export default BaseRequest;
