import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

import { Translate } from "react-i18nify";

const EmptyTableUI = ({ createResourceUrl, hideAddButton }) => (
  <div
    className="p-4 h-100 shadow-sm bg-white d-flex flex-column align-items-center justify-content-center empty-table"
    data-cy="empty-table-ui"
    data-testid="empty-table-ui"
  >
    <div className="info-icon-wrapper border-danger mb-2">
      <FontAwesomeIcon className="info-icon text-danger" icon={faInfo} />
    </div>
    <div className="text-center fw-bold">
      <p className="d-inline-block mb-2" data-cy="no-record-found">
        <Translate value="common.noRecordFound" />
      </p>
      {!hideAddButton && (
        <>
          {", "}
          <Link to={createResourceUrl} data-testid="create-resource-url">
            <Translate value="common.add" /> <Translate value="common.new" />
          </Link>
        </>
      )}
    </div>
  </div>
);

EmptyTableUI.defaultProps = {
  createResourceUrl: undefined,
  hideAddButton: false,
};

EmptyTableUI.propTypes = {
  createResourceUrl: PropTypes.string,
  hideAddButton: PropTypes.bool,
};

export default EmptyTableUI;
