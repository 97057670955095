import React from "react";
import { Modal, ModalBody, Row, Col } from "reactstrap";
import PropTypes from "prop-types";

import { Translate } from "react-i18nify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const RequestSubmittedModal = ({ successModal }) => {
  const icon = successModal ? faCheckCircle : faInfoCircle;
  const iconColorClassName = successModal ? "text-success" : "text-warning";
  const messageTranslation = successModal
    ? "numberPortingRequest.requestSubmittedModal.successMessage"
    : "numberPortingRequest.requestSubmittedModal.alreadySubmittedMessage";

  return (
    <Modal isOpen>
      <ModalBody>
        <Row>
          <Col md="12" className="mb-2">
            <p className="mt-3 d-flex justify-content-center">
              <FontAwesomeIcon
                icon={icon}
                className={iconColorClassName}
                size="3x"
              />
            </p>

            <p className="text-center">
              <Translate value={messageTranslation} />
            </p>

            <p className="text-center">
              <Translate value="common.thankYou" />
            </p>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

RequestSubmittedModal.defaultProps = {
  successModal: false,
};

RequestSubmittedModal.propTypes = {
  successModal: PropTypes.bool,
};

export default RequestSubmittedModal;
