import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import {
  singleNumberPortingRequestFetchRequest,
  numberPortingRequestUpdateRequest,
} from "redux/actions/numberPortingRequest";

import BasicDetail from "components/NumberPortingRequest/Detail/BasicDetail";
import NumberPortingRequestImportDetailSkeleton from "components/Skeleton/ResourceDetail/NumberPortingRequestImportDetailSkeleton";
import RequestedNumberDetail from "components/NumberPortingRequest/Export/Detail/RequestedNumberDetail";
import RequestRespondModal from "components/NumberPortingRequest/Export/RequestRespondModal/RequestRespondModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive } from "@fortawesome/free-solid-svg-icons";
import ArchiveModal from "components/Modal/ArchiveModal";
import ActivityLogTable from "components/NumberPortingRequest/Export/Detail/ActivityLogTable";

const ExportDetail = ({
  numberPortingRequest,
  fetchSingleNumberPortingRequest,
  archiveNumberPortingRequest,
  isLoading,
}) => {
  const { refNumber } = useParams();

  useEffect(() => {
    fetchSingleNumberPortingRequest(refNumber);
  }, [refNumber]);

  const refreshNumberPortingRequestData = () => {
    fetchSingleNumberPortingRequest(refNumber);
  };

  if (
    isLoading ||
    !numberPortingRequest ||
    numberPortingRequest?.ref_number !== refNumber
  ) {
    return <NumberPortingRequestImportDetailSkeleton />;
  }

  const {
    number_porting_request_phone_numbers: numberPortingRequestPhoneNumbers,
    number_type: numberType,
    requested_porting_date: requestedPortingDate,
    can_archive: canArchive,
    is_archived: isArchived,
  } = numberPortingRequest;

  const archiveModal = canArchive && !isArchived && (
    <ArchiveModal
      resourceIdentifier={refNumber}
      archiveRecord={(callBack) =>
        archiveNumberPortingRequest(refNumber, callBack)
      }
    />
  );

  return (
    <div>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center mb-3 detail-ui-header">
          <div className="d-md-flex">
            <h3 className="m-0 d-flex justify-content-between align-items-center">
              {refNumber}
            </h3>

            <span className="is-archived ms-3 px-2 py-2" hidden={!isArchived}>
              <FontAwesomeIcon icon={faArchive} className="me-1" />
              <Translate value="attributes.isArchived" />
            </span>
          </div>

          <RequestRespondModal
            requestedPortingDate={requestedPortingDate}
            numberPortingRequestPhoneNumber={
              numberPortingRequestPhoneNumbers[0]
            }
            refreshPortingRequest={refreshNumberPortingRequestData}
          />

          {archiveModal}
        </div>

        <BasicDetail numberPortingRequest={numberPortingRequest} />

        {numberPortingRequestPhoneNumbers?.length !== 0 && (
          <>
            <RequestedNumberDetail
              numberPortingRequestPhoneNumbers={
                numberPortingRequestPhoneNumbers
              }
              numberType={numberType}
            />

            <ActivityLogTable
              activityLogs={
                numberPortingRequestPhoneNumbers[0]
                  .number_porting_request_phone_numbers_activity_logs
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

ExportDetail.defaultProps = {
  numberPortingRequest: undefined,
};

ExportDetail.propTypes = {
  numberPortingRequest: PropTypes.shape({
    id: PropTypes.number,
    ref_number: PropTypes.string,
    number_porting_request_phone_numbers: PropTypes.shape({
      length: PropTypes.number,
    }),
    number_type: PropTypes.string,
    requested_porting_date: PropTypes.string,
    can_archive: PropTypes.bool,
    is_archived: PropTypes.bool,
  }),
  fetchSingleNumberPortingRequest: PropTypes.func.isRequired,
  archiveNumberPortingRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps({
  numberPortingRequest: numberPortingRequestReducer,
}) {
  const { numberPortingRequest, isLoading } = numberPortingRequestReducer;

  return {
    numberPortingRequest,
    isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSingleNumberPortingRequest: (refNumber) => {
      dispatch(singleNumberPortingRequestFetchRequest(refNumber));
    },
    archiveNumberPortingRequest: (refNumber, callBack) => {
      dispatch(
        numberPortingRequestUpdateRequest(
          refNumber,
          { is_archived: true },
          callBack
        )
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportDetail);
