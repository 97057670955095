import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navbar, Nav } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faHandsHelping,
  faBriefcase,
  faReceipt,
  faCog,
  faHome,
  faCaretLeft,
  faCaretRight,
  faTimes,
  faBars,
  faTty,
  faAddressBook,
  faUserGear,
  faTowerCell,
} from "@fortawesome/free-solid-svg-icons";

import USER_ROLES from "constants/userRoles";
import { DEBTOR, DASHBOARD, USER, SETTING } from "constants/routes";

import { useMediaQuery } from "utils/customHooks";
import menuList from "utils/menuList";

import HasPermission from "hoc/RbacCTA";

import UserInfo from "components/Menu/SideMenu/UserInfo/UserInfo";
import LanguageToggler from "components/Menu/SideMenu/LanguageToggler/LanguageToggler";
import SubMenu from "components/Menu/SideMenu/SubMenu";
import MenuNavItem from "components/Menu/SideMenu/MenuItem";

const CustomerAdminMenu = ({ currentUser, closeMenu, menuMinimized }) => {
  const { odoo_debtor_id: odooDebtorId } = currentUser;

  const withDebtorId = (url) => DEBTOR.addId(url, odooDebtorId);

  return (
    <Nav
      vertical
      navbar
      className="m-0 side-menu-content px-2 minimized-dashboard"
    >
      <MenuNavItem
        to={withDebtorId(DEBTOR.DETAIL)}
        name="menu.customerAdmin.debtor"
        icon={faBriefcase}
        closeMenu={closeMenu}
        menuMinimized={menuMinimized}
        exact
      />
      <HasPermission perform="contacts:sidemenu">
        <MenuNavItem
          to={withDebtorId(DEBTOR.CONTACTS)}
          name="menu.customerAdmin.contact"
          icon={faAddressBook}
          closeMenu={closeMenu}
          menuMinimized={menuMinimized}
        />
      </HasPermission>
      <HasPermission perform="agreements:sidemenu">
        <MenuNavItem
          to={withDebtorId(DEBTOR.AGREEMENTS)}
          name="menuItem.agreement"
          icon={faHandsHelping}
          closeMenu={closeMenu}
          menuMinimized={menuMinimized}
        />
      </HasPermission>
      <MenuNavItem
        to={withDebtorId(DEBTOR.PBXS)}
        name="menuItem.pbx"
        icon={faTty}
        closeMenu={closeMenu}
        menuMinimized={menuMinimized}
      />
      <HasPermission perform="invoices:sidemenu">
        <MenuNavItem
          to={withDebtorId(DEBTOR.INVOICES)}
          name="menuItem.invoice"
          icon={faReceipt}
          closeMenu={closeMenu}
          menuMinimized={menuMinimized}
        />
      </HasPermission>
    </Nav>
  );
};

CustomerAdminMenu.defaultProps = {
  closeMenu: () => {},
};

CustomerAdminMenu.propTypes = {
  currentUser: PropTypes.shape({
    odoo_debtor_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  closeMenu: PropTypes.func,
  menuMinimized: PropTypes.bool.isRequired,
};

const SuperAdminMenu = ({ closeMenu, menuMinimized }) => (
  <Nav
    vertical
    navbar
    className="m-0 side-menu-content p-0 minimized-dashboard"
  >
    <MenuNavItem
      to={DASHBOARD.INDEX}
      name="title.page.dashboard"
      icon={faHome}
      closeMenu={closeMenu}
      title="menu.customerAdmin.debtor"
      menuMinimized={menuMinimized}
    />
    <MenuNavItem
      to={USER.INDEX}
      name="menu.user"
      icon={faUsers}
      closeMenu={closeMenu}
      menuMinimized={menuMinimized}
      data-cy="users-menu"
    />

    <SubMenu
      menuIcon={faUserGear}
      text="menu.customer"
      list={menuList.customer}
      subMenuName="customer"
      closeMenu={closeMenu}
      menuMinimized={menuMinimized}
      dataCy="customer-menu"
    />
    <SubMenu
      menuIcon={faTowerCell}
      text="menu.telecom"
      list={menuList.telecom}
      subMenuName="telecom"
      closeMenu={closeMenu}
      menuMinimized={menuMinimized}
      dropdownListClassName="telecom"
      dataCy="telecom-menu"
    />
    <HasPermission perform="settings:visit">
      <MenuNavItem
        to={SETTING.INDEX}
        name="menu.setting"
        icon={faCog}
        closeMenu={closeMenu}
        menuMinimized={menuMinimized}
        data-cy="settings-menu"
      />
    </HasPermission>
  </Nav>
);

SuperAdminMenu.defaultProps = {
  closeMenu: () => {},
};

SuperAdminMenu.propTypes = {
  closeMenu: PropTypes.func,
  menuMinimized: PropTypes.bool.isRequired,
};

const Sidebar = ({ reduxStore }) => {
  const isSmallDevice = useMediaQuery("(max-width: 576px)"); // returns true if a user is using mobile-phone

  const [menuMinimized, setMenuMinimized] = useState(true);

  const minimizeMenu = () => setMenuMinimized(!menuMinimized);

  // eslint-disable-next-line consistent-return
  const closeMenu = () => {
    if (!isSmallDevice) return false;

    setMenuMinimized(true);
  };

  const togglePosition = menuMinimized ? "75px" : "260px";

  const menuMinimizeIcon = menuMinimized ? faCaretRight : faCaretLeft;

  const mobileMenuIcon = menuMinimized ? faBars : faTimes;

  const menuMinimizeIconStyle = menuMinimized ? "center" : "absolute";

  const justifyContent = menuMinimized ? "flex-start" : "flex-end";

  const backgroundColor = menuMinimized ? "#0a1a3e" : "transparent";

  const {
    auth,
    i18n: { locale },
  } = reduxStore.getState();
  const { currentUser } = auth;

  const userMenu = (user) => {
    switch (user.role) {
      case USER_ROLES.SUPER_ADMIN:
        return (
          <SuperAdminMenu closeMenu={closeMenu} menuMinimized={menuMinimized} />
        );
      case USER_ROLES.ADMIN:
        return (
          <SuperAdminMenu closeMenu={closeMenu} menuMinimized={menuMinimized} />
        );
      default:
        return (
          <CustomerAdminMenu
            currentUser={user}
            closeMenu={closeMenu}
            menuMinimized={menuMinimized}
          />
        );
    }
  };

  return (
    <section
      className={`sidebar-section ${menuMinimized ? "" : "mobile-menu-open"}`}
    >
      <div className="mobile-menu-bar">
        <button
          className={`menu-close ${menuMinimized ? "nav-shadow" : "none"}`}
          type="button"
          style={{
            justifyContent,
            backgroundColor,
          }}
          onClick={minimizeMenu}
        >
          <FontAwesomeIcon icon={mobileMenuIcon} color="white" />
        </button>
      </div>

      <div
        className={`side-menu d-flex ${
          menuMinimized ? "sidebar-minimized" : ""
        }`}
      >
        <div className="w-100">
          <UserInfo menuMinimized={menuMinimized} />
          <Navbar
            color="faded"
            expand="md"
            className="p-0 w-100 dashboard-side-nav"
          >
            {userMenu(currentUser)}
          </Navbar>
        </div>

        <LanguageToggler currentLocale={locale} />
      </div>

      <button
        type="button"
        className="menu-minimizer"
        onClick={minimizeMenu}
        style={{
          justifyContent: menuMinimizeIconStyle,
          left: togglePosition,
        }}
      >
        <FontAwesomeIcon icon={menuMinimizeIcon} color="#FFFFFF" />
      </button>
    </section>
  );
};

Sidebar.propTypes = {
  reduxStore: PropTypes.shape({
    getState: PropTypes.func,
  }).isRequired,
};

export default Sidebar;
