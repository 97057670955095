export const DASHBOARD = {
  INDEX: "/dashboard",
};

export const AUTH = {
  INDEX: "/",
  LOGIN: "/login",
  SET_PASSWORD: "/set-password",
  FORGOT_PASSWORD: "/forgot-password",
  FORBIDDEN: "/forbidden",
};

export const SETTING = {
  INDEX: "/settings",
};

export const APP_SETTING = {
  INDEX: "/app_settings",
  CREATE: "/app_settings/new",
  EDIT: "/app_settings/:appSettingID/edit",
  addId: (url, id) => url.replace(":appSettingID", id),
};

export const USER_SETTING = {
  INDEX: "/user_settings",
};

export const SUBSCRIPTION_PRODUCT = {
  INDEX: "/subscription_products",
  CREATE: "/subscription_products/new",
  EDIT: "/subscription_products/:subscriptionProductID/edit",
  addId: (url, id) => url.replace(":subscriptionProductID", id),
};

export const USER = {
  INDEX: "/users",
  CREATE: "/users/new",
  SHOW: "/users/:userID",
  EDIT: "/users/:userID/edit",
  DEACTIVATED: "/users/deactivated",
  addId: (url, id) => url.replace(":userID", id),
};

export const AGREEMENT = {
  INDEX: "/agreements",
  CREATE: "/agreements/new",
  ARCHIVED: "/agreements/archived",
  SHOW: "/agreements/:agreementID",
  EDIT: "/agreements/:agreementID/edit",
  INVOICE_INDEX: "/agreements/:agreementID/invoices",
  CREDIT_NOTE_INDEX: "/agreements/:agreementID/credit_notes",
  addId: (url, id) => url.replace(":agreementID", id),
};

export const DID_NUMBER = {
  INDEX: "/did_numbers",
  DEACTIVATED: "/did_numbers/deactivated",
  CREATE: "/did_numbers/new",
  SHOW: "/did_numbers/:didNumberID",
  EDIT: "/did_numbers/:didNumberID/edit",
  FAX_STATUS_INDEX: "/did_numbers/:phone_number/fax_statuses",
  addId: (url, id) => url.replace(":didNumberID", id),
  addNumber: (url, phoneNumber) => url.replace(":phone_number", phoneNumber),
};

export const SIP_DEVICES = {
  INDEX: "/sip_devices",
  CREATE: "/sip_devices/new",
  SHOW: "/sip_devices/:sipDeviceID",
  EDIT: "/sip_devices/:sipDeviceID/edit",
  addId: (url, id) => url.replace(":sipDeviceID", id),
};

export const FAX_STATUS = {
  SHOW: "/fax_statuses/:faxStatusID",
  addId: (url, id) => url.replace(":faxStatusID", id),
};

export const CELL_PHONE = {
  INDEX: "/cell_phones",
  DEACTIVATED: "/cell_phones/deactivated",
  CREATE: "/cell_phones/new",
  SHOW: "/cell_phones/:cellPhoneID",
  EDIT: "/cell_phones/:cellPhoneID/edit",
  NETWORK_SERVICES_EDIT: "/cell_phones/:cellPhoneID/network_services/edit",
  SUBSCRIPTION_USAGE: "/cell_phones/:cellPhoneNumber/subscription_usages",
  addId: (url, id) => url.replace(":cellPhoneID", id),
  addCellNumber: (url, cellNumber) =>
    url.replace(":cellPhoneNumber", cellNumber),
};

export const USER_LICENSE = {
  INDEX: "/user_licenses",
  DEACTIVATED: "/user_licenses/deactivated",
  CREATE: "/user_licenses/new",
  SHOW: "/user_licenses/:userLicenseID",
  EDIT: "/user_licenses/:userLicenseID/edit",
  CELL_PHONES: "/user_licenses/:userLicenseID/cell_phones",
  DID_NUMBERS: "/user_licenses/:userLicenseID/did_numbers",
  SIP_DEVICES: "/user_licenses/:userLicenseID/sip_devices",
  CREATE_SIP_DEVICE: "/user_licenses/:userLicenseID/sip_devices/new",
  CREATE_CELL_PHONE: `/user_licenses/:userLicenseID${CELL_PHONE.CREATE}`,
  CREATE_DID_NUMBER: `/user_licenses/:userLicenseID${DID_NUMBER.CREATE}`,
  NEW_CELL_PHONE: `:userLicenseID/cell_phones/new`,
  NEW_DID_NUMBER: `:userLicenseID/did_numbers/new`,
  NEW_SIP_DEVICE: `:userLicenseID/sip_devices/new`,
  DEACTIVATED_CELL_PHONE: `/user_licenses/:userLicenseID${CELL_PHONE.DEACTIVATED}`,
  addId: (url, id) => url.replace(":userLicenseID", id),
};

export const PBX = {
  INDEX: "/pbxs",
  CREATE: "/pbxs/new",
  SHOW: "/pbxs/:pbxID",
  DETAIL: "/pbxs/:pbxID/details",
  EDIT: "/pbxs/:pbxID/edit",
  CREATE_USER_LICENSE: `/pbxs/:pbxID${USER_LICENSE.CREATE}`,
  NEW_USER_LICENSE: `/:pbxID${USER_LICENSE.CREATE}`,
  DEACTIVATED_USER_LICENSES: `/pbxs/:pbxID${USER_LICENSE.DEACTIVATED}`,
  DID_NUMBERS: "/pbxs/:pbxID/did_numbers",
  DEACTIVATED_DID_NUMBERS: `/pbxs/:pbxID${DID_NUMBER.DEACTIVATED}`,
  CREATE_DID_NUMBER: `/pbxs/:pbxID${DID_NUMBER.CREATE}`,
  NEW_DID_NUMBER: `:pbxID${DID_NUMBER.CREATE}`,
  CELL_PHONES: "/pbxs/:pbxID/cell_phones",
  CREATE_CELL_PHONE: `/pbxs/:pbxID${CELL_PHONE.CREATE}`,
  NEW_CELL_PHONE: `/:pbxID${CELL_PHONE.CREATE}`,
  SIP_DEVICES: "/pbxs/:pbxID/sip_devices",
  addId: (url, id) => url.replace(":pbxID", id),
};

export const VOICEMAIL_BOX = {
  INDEX: "/voicemail_boxes/:voicemailBoxID",
  EDIT: "/voicemail_boxes/:voicemailBoxID/edit",
  addId: (url, id) => url.replace(":voicemailBoxID", id),
};

export const INVOICE = {
  INDEX: "/invoices",
  CREATE: "/invoices/new",
  SHOW: "/invoices/:invoiceID",
  DETAIL: "/invoices/:invoiceID/details",
  EDIT: "/invoices/:invoiceID/edit",
  addId: (url, id) => url.replace(":invoiceID", id),
};

export const CREDIT_NOTE = {
  INDEX: "/credit_notes",
  SHOW: "/credit_notes/:creditNoteID",
  DETAIL: "/credit_notes/:creditNoteID/details",
  addId: (url, id) => url.replace(":creditNoteID", id),
};

export const BILLS = {
  INDEX: "/bills",
  SHOW: "/bills/:billID",
  DETAIL: "/bills/:billID/details",
  addId: (url, id) => url.replace(":billID", id),
};

export const VENDORS = {
  INDEX: "/vendors",
  SHOW: "/vendors/:vendorID",
  DETAIL: "/vendors/:vendorID/details",
  BILLS: "/debtors/:vendorID/bills",
  EDIT: "/vendors/:vendorID/edit",
  addId: (url, id) => url.replace(":vendorID", id),
};

export const CONTACT = {
  INDEX: "/contacts",
  CREATE: "",
  DETAIL: "",
  EDIT: "",
};

export const DEBTOR = {
  INDEX: "/debtors",
  CREATE: "/debtors/new",
  SHOW: "/debtors/:debtorID",
  DETAIL: "/debtors/:debtorID/details",
  EDIT: "/debtors/:debtorID/edit",
  CONTACTS: "/debtors/:debtorID/contacts",
  AGREEMENTS: "/debtors/:debtorID/agreements",
  AGREEMENTS_NEW: "/debtors/:debtorID/agreements/new",
  NEW_AGREEMENTS: ":debtorID/agreements/new",
  AGREEMENTS_ARCHIVED: "/debtors/:debtorID/agreements/archived",
  INVOICES: "/debtors/:debtorID/invoices",
  CREDIT_NOTES: "/debtors/:debtorID/credit_notes",
  INVOICES_NEW: "/debtors/:debtorID/invoices/new",
  PBXS: "/debtors/:debtorID/pbxs",
  PBXS_NEW: "/debtors/:debtorID/pbxs/new",
  NEW_PBXS: ":debtorID/pbxs/new",
  addId: (url, id) => url.replace(":debtorID", id),
};

export const NUMBER_PORTING_REQUEST = {
  EXPORTS: "/number_porting_requests/exports",
  EXPORT_DETAIL: "/number_porting_requests/exports/:refNumber",
  IMPORTS: "/number_porting_requests/imports",
  IMPORT_CREATE: "/number_porting_requests/imports/new",
  IMPORT_DETAIL: "/number_porting_requests/imports/:refNumber",
  IMPORT_EDIT: "/number_porting_requests/imports/:refNumber/edit",
  INDEX: "/number_porting_requests",
  CUSTOMER_IMPORT_FORM:
    "/number_porting_requests/imports/:refNumber/customer_form",
  addRefNumber: (url, refNumber) => url.replace(":refNumber", refNumber),
};

export const PHONE_NUMBER = {
  INDEX: "/phone_numbers",
  CREATE: "/phone_numbers/new",
  SHOW: "/phone_numbers/:phoneNumberID",
  EDIT: "/phone_numbers/:phoneNumberID/edit",
  addId: (url, id) => url.replace(":phoneNumberID", id),
};

export const SIM_CARD = {
  INDEX: "/sim_cards",
  SHOW: "/sim_cards/:simCardID",
  ASSIGN: "/assign_sim_cards",
  EDIT: "/sim_cards/:simCardID/edit",
  addId: (url, id) => url.replace(":simCardID", id),
};

export const QUOTATION = {
  INDEX: "/quotations",
  CREATE: "/quotations/new",
  SHOW: "/quotations/:quotationID",
  EDIT: "/quotations/:quotationID/edit",
  CONVERT_TO_AGREEMENT: "/quotations/:quotationID/convert_to_agreement",
  addId: (url, id) => url.replace(":quotationID", id),
};
