import React from "react";
import { connect } from "react-redux";
import { FormGroup, Row, Col, Form } from "reactstrap";
import { Field, Form as ReactFinalForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import arrayMutators from "final-form-arrays";
import { Translate } from "react-i18nify";
import PropTypes from "prop-types";

import {
  required,
  minValue,
  phoneNumber,
  number,
  composeValidators,
} from "utils/validation";
import { useSubscriptionTerminationDate } from "utils/customHooks";
import { getDirtyFieldsValue } from "utils/utils";

import { recordCallOptions } from "constants/dropdownOptions";
import USER_ROLES, { ADMIN_ROLES } from "constants/userRoles";
import { FORM_TYPES } from "constants/form";

import {
  renderInputField,
  renderDropdownField,
  renderDateField,
} from "components/ReduxForm/RenderField";
import CheckBoxField from "components/ReduxForm/CheckBoxField";
import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";
import TerminationDateField from "components/CellPhone/Form/Field/TerminationDateField";
import ProviderMultiSelectField from "components/CellPhone/Form/Field/ProviderMutliSelectField";
import VoicemailBox from "components/VoicemailBox/Form/VoicemailBox";
import SearchableDropdown from "components/ReduxForm/SearchableDropdown/SearchableDropdown";
import SubscriptionSectionContainer from "components/CellPhone/Form/SubscriptionSectionContainer";
import ServiceSection from "components/CellPhone/Form/NetworkOperatorSubscriptionServices/ServiceSection";

import { searchICCIDRangesRequest } from "redux/actions/simCard";
import { searchPhoneNumberRequest } from "redux/actions/cellPhone";

const minAttributeValue = minValue(0);

const RenderInvoiceDateField = ({
  deploymentDate,
  minimumBeforeDate,
  formType,
  form,
}) => {
  const handleInvoiceStartDateSelect = (value) => {
    if (formType !== "create") return;

    form.batch(() => {
      form.change("subscription_attributes.start_date", value);
    });
  };

  return (
    <FormGroup>
      <Field
        name="invoice_start_date"
        component={renderDateField}
        validate={required}
        labelTranslation="attributes.invoiceStartDate"
        disabledDays={{
          before:
            (deploymentDate && new Date(deploymentDate)) || minimumBeforeDate,
        }}
      />
      <Field name="subscription_attributes.start_date" component={() => null} />

      <OnChange name="invoice_start_date">
        {(value) => {
          handleInvoiceStartDateSelect(value);
        }}
      </OnChange>
    </FormGroup>
  );
};

const RenderEmailField = ({ isParentUserLicense, form }) => {
  if (!isParentUserLicense) return null;

  const handleEmailChange = (email) => {
    form.batch(() => {
      form.change("ex_emailrecording", email);
      form.change("voicemail_box_attributes.email", email);
    });
  };

  return (
    <FormGroup>
      <Field
        name="ex_email"
        component={renderInputField}
        labelTranslation="attributes.email"
        placeholderTranslation="attributes.email"
        type="text"
      />

      <OnChange name="ex_email">
        {(value) => {
          handleEmailChange(value);
        }}
      </OnChange>
    </FormGroup>
  );
};

const RenderSubscriptionSection = ({
  form,
  formType,
  invoiceStartDate,
  deploymentDate,
  isTerminationDateLoading,
  terminationDateEndLimit,
  subscriptionTerminationEndRange,
}) => {
  if (formType === "edit") {
    return (
      <Row>
        <Col md="6">
          <TerminationDateField
            terminationDateEndLimit={terminationDateEndLimit}
            invoiceStartDate={invoiceStartDate}
            deploymentDate={deploymentDate}
            isTerminationDateLoading={isTerminationDateLoading}
            subscriptionTerminationEndRange={subscriptionTerminationEndRange}
          />
        </Col>
      </Row>
    );
  }

  return (
    <FieldArray
      name="subscription_attributes.subscription_lines_attributes"
      component={SubscriptionSectionContainer}
      validate={required}
      contextTypeID={1}
      formType={formType}
      form={form}
    />
  );
};

const CellPhoneForm = ({
  onSubmit,
  initialValues,
  formType,
  isLoading,
  terminationDateEndLimit,
  subscriptionLineId,
  parentType,
  currentUserRole,
  providers,
  isProviderLoading,
  searchIccidNumbers,
  iccidNumbers,
  isSearching,
  phoneNumbers,
  phoneNumberSearching,
  searchPhoneNumber,
  cellPhoneServices,
  filterSelectedServices,
}) => {
  const { isTerminationDateLoading, subscriptionTerminationEndRange } =
    useSubscriptionTerminationDate(subscriptionLineId);

  const isParentUserLicense = parentType === "userLicense";

  const defaultMobileField = isParentUserLicense && (
    <Col md="12">
      <FormGroup check className="mt-2 mb-3">
        <Field
          name="is_default"
          component={CheckBoxField}
          labelTranslation="cellPhone.defaultForMobileApp"
          type="checkbox"
        />
      </FormGroup>
    </Col>
  );

  const voicemailBoxField = isParentUserLicense && (
    <VoicemailBox formType={formType} />
  );

  const minimumBeforeDate =
    currentUserRole === USER_ROLES.SUPER_ADMIN ? "" : new Date();

  const searchResult = iccidNumbers.map(
    ({ iccid_number: iccidNumber, id }) => ({
      value: id,
      label: iccidNumber,
    })
  );

  const searchResultForPhoneNumber = phoneNumbers.map(
    ({ phone_number: cellNumber, id }) => ({
      value: id,
      label: cellNumber,
    })
  );

  const phoneNumberField =
    formType === FORM_TYPES.EDIT ? "cell_number" : "phone_number_id";

  const iccidNumberFieldName =
    formType === FORM_TYPES.EDIT
      ? "active_sim_card.iccid_number"
      : "cell_phone_sim_cards_attributes[0].sim_card_id";

  const handleFormSubmit = (fields, form) => {
    const registeredFields = form.getRegisteredFields();
    const values = getDirtyFieldsValue(fields, form);

    return onSubmit(values, registeredFields);
  };

  return (
    <ReactFinalForm
      onSubmit={handleFormSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={({ pristine, submitting, handleSubmit, form, values }) => {
        const {
          invoice_start_date: invoiceStartDate,
          deployment_date: deploymentDate,
        } = values;

        return (
          <Form
            className="flexo-form px-4 py-4 pt-5 w-100"
            onSubmit={handleSubmit}
          >
            <div className="pbx-content">
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Field
                      name={phoneNumberField}
                      component={SearchableDropdown}
                      validate={composeValidators(
                        required,
                        number,
                        phoneNumber
                      )}
                      labelTranslation="attributes.cellPhoneNumber"
                      placeholderTranslation="attributes.cellPhoneNumber"
                      onChangeCb={(e) => searchPhoneNumber(e)}
                      searchResult={searchResultForPhoneNumber}
                      isLoading={phoneNumberSearching}
                      readable={formType === FORM_TYPES.EDIT}
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name={iccidNumberFieldName}
                      component={SearchableDropdown}
                      validate={composeValidators(required, number)}
                      labelTranslation="attributes.iccidNumber"
                      placeholderTranslation="attributes.iccidNumber"
                      onChangeCb={(e) => searchIccidNumbers(e)}
                      searchResult={searchResult}
                      isLoading={isSearching}
                      readable={formType === FORM_TYPES.EDIT}
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="ex_name"
                      component={renderInputField}
                      validate={required}
                      labelTranslation="attributes.name"
                      placeholderTranslation="attributes.name"
                      type="text"
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <RenderEmailField
                    isParentUserLicense={isParentUserLicense}
                    form={form}
                  />
                </Col>

                {defaultMobileField}

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="ex_recording"
                      component={renderDropdownField}
                      validate={required}
                      dropdownOptions={recordCallOptions}
                      labelTranslation="attributes.recordCalls"
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="ex_dialtimeout"
                      component={renderInputField}
                      validate={minAttributeValue}
                      min={0}
                      labelTranslation="attributes.dialTimeout"
                      placeholderTranslation="attributes.dialTimeout"
                      type="number"
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="ex_description"
                      component={renderInputField}
                      labelTranslation="attributes.description"
                      placeholderTranslation="attributes.description"
                      type="textarea"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <ProviderMultiSelectField
                providers={providers}
                isProviderLoading={isProviderLoading}
                isAdmin={ADMIN_ROLES.includes(currentUserRole)}
              />

              {voicemailBoxField}

              <Row>
                <Col md="12">
                  <h4 className="mt-3 mb-3">
                    <Translate value="title.section.subscription" />
                  </h4>
                  <hr />
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="deployment_date"
                      component={renderDateField}
                      validate={required}
                      labelTranslation="attributes.deploymentDate"
                      disabledDays={{
                        before: minimumBeforeDate,
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <RenderInvoiceDateField
                    deploymentDate={deploymentDate}
                    minimumBeforeDate={minimumBeforeDate}
                    formType={formType}
                    form={form}
                  />
                </Col>

                <Col md="12">
                  <RenderSubscriptionSection
                    form={form}
                    formType={formType}
                    invoiceStartDate={invoiceStartDate}
                    deploymentDate={deploymentDate}
                    isTerminationDateLoading={isTerminationDateLoading}
                    terminationDateEndLimit={terminationDateEndLimit}
                    subscriptionTerminationEndRange={
                      subscriptionTerminationEndRange
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <FieldArray
                    name="network_operator_subscription_services"
                    component={ServiceSection}
                    formType={formType}
                    cellPhoneServices={cellPhoneServices}
                    filterSelectedServices={filterSelectedServices}
                  />
                </Col>
              </Row>
            </div>

            <div className="flexo-form-footer">
              <ReduxFormCommitButton
                title={<Translate value="common.save" />}
                submitting={submitting}
                pristine={pristine}
                isLoading={isLoading || isTerminationDateLoading}
              />
            </div>
          </Form>
        );
      }}
    />
  );
};

RenderInvoiceDateField.defaultProps = {
  deploymentDate: undefined,
  formType: "create",
};

RenderInvoiceDateField.propTypes = {
  deploymentDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  minimumBeforeDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  formType: PropTypes.string,
  form: PropTypes.shape({
    batch: PropTypes.func,
    change: PropTypes.func,
  }).isRequired,
};

RenderEmailField.defaultProps = {};

RenderEmailField.propTypes = {
  isParentUserLicense: PropTypes.string.isRequired,
  form: PropTypes.shape({
    batch: PropTypes.func,
    change: PropTypes.func,
  }).isRequired,
};

RenderSubscriptionSection.defaultProps = {
  formType: "create",
  invoiceStartDate: undefined,
  deploymentDate: undefined,
  terminationDateEndLimit: undefined,
};

RenderSubscriptionSection.propTypes = {
  form: PropTypes.shape({
    batch: PropTypes.func,
    change: PropTypes.func,
  }).isRequired,
  formType: PropTypes.string,
  invoiceStartDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  deploymentDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  isTerminationDateLoading: PropTypes.bool.isRequired,
  terminationDateEndLimit: PropTypes.instanceOf(Date),
  subscriptionTerminationEndRange: PropTypes.shape({}).isRequired,
};

CellPhoneForm.defaultProps = {
  formType: "create",
  terminationDateEndLimit: undefined,
  subscriptionLineId: null,
  isSearching: false,
  phoneNumberSearching: PropTypes.bool,
  initialValues: {},
};

CellPhoneForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  formType: PropTypes.string,
  terminationDateEndLimit: PropTypes.instanceOf(Date),
  subscriptionLineId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  parentType: PropTypes.string.isRequired,
  currentUserRole: PropTypes.string.isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isProviderLoading: PropTypes.bool.isRequired,
  searchIccidNumbers: PropTypes.func.isRequired,
  iccidNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      iccid_number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  isSearching: PropTypes.bool,
  phoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      phone_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  phoneNumberSearching: PropTypes.bool,
  searchPhoneNumber: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  cellPhoneServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      is_default: PropTypes.bool,
    })
  ).isRequired,
  filterSelectedServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      is_default: PropTypes.bool,
    })
  ).isRequired,
};

const mapStateToProps = (state) => {
  const {
    currentUser: { role: currentUserRole },
  } = state.auth;
  const { iccidNumbers, isSearching } = state.simCard;
  const { phoneNumbers, isProcessing: phoneNumberSearching } = state.cellPhone;

  return {
    currentUserRole,
    iccidNumbers,
    isSearching,
    phoneNumbers,
    phoneNumberSearching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchIccidNumbers: (startNumber) => {
    dispatch(searchICCIDRangesRequest(startNumber));
  },
  searchPhoneNumber: (num) => {
    dispatch(searchPhoneNumberRequest(num));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CellPhoneForm);
