import React from "react";
import { Label } from "reactstrap";

import PropTypes from "prop-types";

import { Translate } from "react-i18nify";

const InputLabel = ({
  name,
  label,
  labelTranslation,
  replacementTranslations,
  dataTestId,
}) => {
  if (labelTranslation) {
    return (
      <Label data-testid={dataTestId} for={name}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Translate value={labelTranslation} {...replacementTranslations} />
      </Label>
    );
  }

  return label;
};

InputLabel.defaultProps = {
  label: null,
  labelTranslation: null,
  name: null,
  replacementTranslations: {},
  dataTestId: "",
};

InputLabel.propTypes = {
  label: PropTypes.string,
  labelTranslation: PropTypes.string,
  name: PropTypes.string,
  replacementTranslations: PropTypes.shape({}),
  dataTestId: PropTypes.string,
};

export default InputLabel;
