import React from "react";
import Skeleton from "react-loading-skeleton";

const UsersSkeletion = () => {
  return (
    <div className="bg-white rounded shadow-sm d-flex flex-column justify-content-between">
      <div className="p-3 border-bottom">
        <Skeleton height={18} width={150} />
      </div>
      <div className="p-2">
        <div className="d-flex align-items-center mb-2">
          <Skeleton circle height={50} width={50} />
          <div className="d-flex flex-column ms-3">
            <Skeleton height={18} width={150} />
            <Skeleton height={16} width={100} />
          </div>
        </div>
        <div className="d-flex align-items-center mb-2">
          <Skeleton circle height={50} width={50} />
          <div className="d-flex flex-column ms-3">
            <Skeleton height={18} width={120} />
            <Skeleton height={16} width={80} />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <Skeleton circle height={50} width={50} />
          <div className="d-flex flex-column ms-3">
            <Skeleton height={18} width={180} />
            <Skeleton height={16} width={80} />
          </div>
        </div>
      </div>
      <div className="d-flex p-2 border-top">
        <div className="d-flex flex-column border-right w-50 align-items-center justify-content-center">
          <Skeleton height={18} width={60} />
          <Skeleton height={26} width={100} />
        </div>
        <div className="d-flex flex-column w-50 align-items-center justify-content-center">
          <Skeleton height={18} width={100} />
          <Skeleton height={26} width={70} />
        </div>
      </div>
    </div>
  );
};

export default UsersSkeletion;
