import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { toastr } from "react-redux-toastr";
import { t } from "react-i18nify";

import {
  numberPortingRequestUpdateRequest,
  singleNumberPortingRequestFetchRequest,
} from "redux/actions/numberPortingRequest";

import { NUMBER_PORTING_REQUEST } from "constants/routes";

import ImportForm from "components/NumberPortingRequest/Import/Form/ImportForm";
import WholePageSpinner from "components/Spinner/WholePageSpinner";
import BasicDetail from "components/NumberPortingRequest/Detail/BasicDetail";
import PageNotFound from "components/Error/PageNotFound/PageNotFound";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const ImportEdit = ({
  numberPortingRequest,
  updateNumberPortingRequest,
  isLoading,
  commitSuccess,
  commitError,
  fetchSingleNumberPortingRequest,
}) => {
  const { refNumber } = useParams();

  useEffect(() => {
    fetchSingleNumberPortingRequest(refNumber);
  }, []);

  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    await updateNumberPortingRequest(refNumber, values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess && !commitError) {
    const featureNameTranslation = t("attributes.numberPortingRequest");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.update", {
        resource: featureNameTranslation,
      })
    );

    return (
      <Navigate
        to={`${NUMBER_PORTING_REQUEST.addRefNumber(
          NUMBER_PORTING_REQUEST.IMPORT_DETAIL,
          refNumber
        )}`}
      />
    );
  }

  if (!numberPortingRequest) {
    return <WholePageSpinner />;
  }

  const {
    number_porting_request_phone_numbers: numberPortingRequestPhoneNumbers,
    import_request_submitted_to_np_server: requestSubmittedToNPServer,
  } = numberPortingRequest;

  if (requestSubmittedToNPServer) {
    return (
      <div className="d-flex h-100 justify-content-center">
        <PageNotFound />
      </div>
    );
  }

  return (
    <div>
      <h3 className="mt-3">{refNumber}</h3>

      <BasicDetail numberPortingRequest={numberPortingRequest} />

      <ImportForm
        commitError={commitError}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        initialValues={{
          ...numberPortingRequest,
          number_porting_request_phone_numbers_attributes:
            numberPortingRequestPhoneNumbers,
        }}
        formAction="edit"
        numberPortingRequest={numberPortingRequest}
      />
    </div>
  );
};

ImportEdit.defaultProps = {
  isLoading: true,
  numberPortingRequest: undefined,
  commitError: {},
};

ImportEdit.propTypes = {
  updateNumberPortingRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  commitError: PropTypes.shape({}),
  commitSuccess: PropTypes.bool.isRequired,
  numberPortingRequest: PropTypes.shape({
    id: PropTypes.number,
    ref_number: PropTypes.string,
    number_porting_request_phone_numbers: PropTypes.shape({}),
    import_request_submitted_to_np_server: PropTypes.string,
  }),
  fetchSingleNumberPortingRequest: PropTypes.func.isRequired,
};

function mapStateToProps({
  numberPortingRequest: numberPortingRequestReducer,
}) {
  const { isLoading, commitSuccess, numberPortingRequest, commitError } =
    numberPortingRequestReducer;

  return {
    isLoading,
    commitSuccess,
    numberPortingRequest,
    commitError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSingleNumberPortingRequest: (refNumber) => {
      dispatch(singleNumberPortingRequestFetchRequest(refNumber));
    },
    updateNumberPortingRequest: async (refNumber, values, callback) => {
      await dispatch(
        numberPortingRequestUpdateRequest(refNumber, values, callback)
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportEdit);
