import React from "react";
import PropTypes from "prop-types";
import {
  faFolderPlus,
  faMoneyBillWave,
  faArchive,
  faPager,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";

import ItemRow from "components/Dashboard/DashboardItem/ItemRow";

const BillItem = ({ item }) => {
  const {
    count,
    paid_count: paidCount,
    unpaid_count: dueCount,
    unpaid_amount: dueAmount,
    draft_count: draftCount,
    draft_amount: draftAmount,
  } = item;

  return (
    <div className="item-content">
      <ItemRow
        icon={faFolderPlus}
        iconClassName="text-info"
        title="dashboard.bill.total"
        value={count}
      />
      <ItemRow
        icon={faPager}
        iconClassName="text-success"
        title="dashboard.bill.paid"
        value={paidCount}
      />
      <ItemRow
        icon={faArchive}
        iconClassName="text-danger"
        title="dashboard.bill.due"
        value={dueCount}
      />
      <ItemRow
        icon={faMoneyBillWave}
        iconClassName="text-warning"
        title="dashboard.bill.dueAmount"
        value={dueAmount}
        minFractionDigits={2}
      />
      <ItemRow
        icon={faPager}
        iconClassName="text-success"
        title="dashboard.bill.validate"
        value={draftCount}
      />
      <ItemRow
        icon={faMoneyBill}
        iconClassName="text-info"
        title="dashboard.bill.validateAmount"
        value={draftAmount}
        minFractionDigits={2}
      />
    </div>
  );
};

BillItem.defaultProps = {
  item: {},
};

BillItem.propTypes = {
  item: PropTypes.shape({
    count: PropTypes.number,
    new: PropTypes.number,
    active_count: PropTypes.number,
    active_amount: PropTypes.number,
    archive_count: PropTypes.number,
    archive_amount: PropTypes.number,
    paid_count: PropTypes.number,
    unpaid_count: PropTypes.number,
    unpaid_amount: PropTypes.number,
    draft_count: PropTypes.number,
    draft_amount: PropTypes.number,
    new_list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        business_name: PropTypes.string,
      })
    ),
  }),
};

export default BillItem;
