export const INDEX = "/";
export const NEW = "new";
export const EDIT = "edit";
export const INVOICES = "invoices";
export const DETAILS = "details";
export const CREDIT_NOTES = "credit_notes";
export const AGREEMENTS = "agreements";
export const PBXS = "pbxs";
export const CONTACTS = "contacts";
export const USER_LICENSES = "user_licenses";
export const CELL_PHONES = "cell_phones";
export const DID_NUMBERS = "did_numbers";
export const SIP_DEVICES = "sip_devices";

export const AGREEMENT = {
  AGREEMENT_ID: ":agreementID",
  ARCHIVED: "archived",
};

export const BILLS = {
  BILL_ID: ":billID",
};

export const CELL_PHONE = {
  CELL_PHONE_ID: ":cellPhoneID",
  NETWORK_SERVICES: "network_services",
  SUBSCRIPTION_USAGES: "subscription_usages",
  CELL_PHONE_NUMBER: ":cellPhoneNumber",
};

export const CREDIT_NOTE = {
  CREDIT_NOTE_ID: ":creditNoteID",
};

export const DEBTOR = {
  DEBTOR_ID: ":debtorID",
};

export const DID_NUMBER = {
  DID_NUMBER_ID: ":didNumberID",
  PHONE_NUMBER: ":phone_number",
  FAX_STATUSES: "fax_statuses",
};

export const INVOICE = {
  INVOICE_ID: ":invoiceID",
};

export const NUMBER_PORTING_REQUEST = {
  IMPORTS: "imports",
  REF_NUMBER: ":refNumber",
  CUSTOMER_FORM: "customer_form",
  EXPORTS: "exports",
};

export const PBX = {
  PBX_ID: ":pbxID",
};

export const QUOTATION = {
  QUOTATION_ID: ":quotationID",
  CONVERT_TO_AGREEMENT: "convert_to_agreement",
};

export const APP_SETTING = {
  APP_SETTING_ID: ":appSettingID",
};

export const SETTINGS = {
  APP: "app",
  SUBSCRIPTION_PRODUCT: "subscription_products",
};

export const SIP_DEVICE = {
  SIP_DEVICE_ID: ":sipDeviceID",
};

export const SUBSCRIPTION_PRODUCT = {
  SUBSCRIPTION_PRODUCT_ID: ":subscriptionProductID",
};

export const PHONE_NUMBER = {
  PHONE_NUMBER_ID: ":phoneNumberID",
};

export const SIM_CARD = {
  SIM_CARD_ID: ":simCardID",
};

export const USER = {
  USER_ID: ":userID",
};

export const USER_LICENSE = {
  USER_LICENSE_ID: ":userLicenseID",
};

export const VENDOR = {
  VENDOR_ID: ":vendorID",
};
