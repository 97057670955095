import {
  calculateSumOfObjectsArray,
  calculateSumOfObjectsArrayWithNaNValues,
} from "utils/agreement";

const SUBSCRIPTION_LINES_ATTRIBUTES = {
  PRICE: "price",
  TOTAL_AMOUNT: "total_amount",
  SETUP: "setup",
  TOTAL_SETUP_PRICE: "total_setup_price",
  TOTAL_MONTHLY_PRICE: "total_monthly_price",
};

// Format attributes of agreement before sending to backend for the create or update
function formatRequestValues(values) {
  const { quotation_subscription_binding_periods: bindingPeriods, agreement } =
    values;
  const { subscriptions } = agreement;

  const subscriptionsAttributes = subscriptions.map((subscription) => {
    const {
      subscription_lines: subscriptionLines,
      _destroy: isSubscriptionDeleted,
    } = subscription;

    if (isSubscriptionDeleted) {
      return subscription;
    }

    const subscriptionLinesAttributes = subscriptionLines.map(
      (subscriptionLine) => {
        const {
          subscription_line_properties: subscriptionLineProperties,
          _destroy: isLineDeleted,
        } = subscriptionLine;

        if (isLineDeleted) {
          return subscriptionLine;
        }

        const subscriptionLineAttribute = {
          ...subscriptionLine,
          subscription_line_properties_attributes: subscriptionLineProperties,
        };

        return subscriptionLineAttribute;
      }
    );

    const subscriptionTotalAmount = calculateSumOfObjectsArray(
      subscriptionLines,
      SUBSCRIPTION_LINES_ATTRIBUTES.PRICE
    );

    const subscriptionAttribute = {
      ...subscription,
      total_amount: subscriptionTotalAmount,
      subscription_lines_attributes: subscriptionLinesAttributes,
    };

    return subscriptionAttribute;
  });

  const totalAmount = calculateSumOfObjectsArray(
    subscriptionsAttributes,
    SUBSCRIPTION_LINES_ATTRIBUTES.TOTAL_AMOUNT
  );

  const formValues = {
    ...values,
    total_amount: totalAmount,
    agreement_attributes: {
      subscriptions_attributes: subscriptionsAttributes,
      ...values.agreement,
    },
    quotation_subscription_binding_periods_attributes: bindingPeriods,
  };

  return formValues;
}

const formatSubscriptionLinesAttributes = (subscriptionLines) =>
  subscriptionLines.map((subscriptionLine) => {
    const {
      subscription_line_properties: subscriptionLineProperties,
      ...remainingSubscriptionLinesAttributes
    } = subscriptionLine;

    const subscriptionLineAttribute = {
      ...remainingSubscriptionLinesAttributes,
      subscription_line_properties_attributes: subscriptionLineProperties,
    };

    return subscriptionLineAttribute;
  });

const formatConvertToAgreementRequestValues = (values) => {
  const {
    agreement: { subscriptions },
    ...rest
  } = values;

  const subscriptionsAttributes = subscriptions.map((subscription) => {
    const {
      subscription_lines: subscriptionLines,
      _destroy: isSubscriptionDeleted,
      total_monthly_price: totalMonthlyPrice,
      total_setup_price: totalSetupPrice,
      ...remainingSubscriptionAttributes
    } = subscription;

    if (isSubscriptionDeleted) {
      return subscription;
    }

    const subscriptionLinesAttributes =
      formatSubscriptionLinesAttributes(subscriptionLines);

    const subscriptionTotalAmount = calculateSumOfObjectsArrayWithNaNValues(
      subscriptionLines,
      SUBSCRIPTION_LINES_ATTRIBUTES.PRICE
    );

    const subscriptionAttribute = {
      ...remainingSubscriptionAttributes,
      total_amount: subscriptionTotalAmount,
      subscription_lines_attributes: subscriptionLinesAttributes,
    };

    return subscriptionAttribute;
  });

  const totalAmount = calculateSumOfObjectsArrayWithNaNValues(
    subscriptionsAttributes,
    SUBSCRIPTION_LINES_ATTRIBUTES.TOTAL_AMOUNT
  );

  const formValues = {
    agreement_attributes: {
      ...rest,
      subscriptions_attributes: subscriptionsAttributes,
      total_amount: totalAmount,
    },
  };

  return formValues;
};

const calculateSubscriptionSetupPrice = (subscriptionLines) => {
  const setupLines = subscriptionLines.filter(
    ({ line_type: lineType }) =>
      lineType === SUBSCRIPTION_LINES_ATTRIBUTES.SETUP
  );

  const setupAmount = calculateSumOfObjectsArray(
    setupLines,
    SUBSCRIPTION_LINES_ATTRIBUTES.PRICE
  );

  return setupAmount;
};

const calculateSubscriptionMonthlyPrice = (subscriptionLines) => {
  const monthlyLines = subscriptionLines.filter(
    ({ line_type: lineType }) =>
      lineType !== SUBSCRIPTION_LINES_ATTRIBUTES.SETUP
  );

  const monthlyAmount = calculateSumOfObjectsArray(
    monthlyLines,
    SUBSCRIPTION_LINES_ATTRIBUTES.PRICE
  );

  return monthlyAmount;
};

const calculateSubscriptionTotalSetupPrice = (
  setupPrice,
  subscriptionQuantity
) => setupPrice * subscriptionQuantity;

const calculateSubscriptionTotalMonthlyPrice = (
  monthlyPrice,
  subscriptionQuantity
) => monthlyPrice * subscriptionQuantity;

const calculateSubscriptionTotalAmount = (
  subscriptionLines,
  subscriptionQuantity
) => {
  const totalAmount = calculateSumOfObjectsArray(
    subscriptionLines,
    SUBSCRIPTION_LINES_ATTRIBUTES.PRICE
  );

  return subscriptionQuantity * totalAmount;
};

const calculateAllTotalSetupPrice = (subscriptions) =>
  calculateSumOfObjectsArray(
    subscriptions,
    SUBSCRIPTION_LINES_ATTRIBUTES.TOTAL_SETUP_PRICE
  );

const calculateAllTotalMonthlyPrice = (subscriptions) =>
  calculateSumOfObjectsArray(
    subscriptions,
    SUBSCRIPTION_LINES_ATTRIBUTES.TOTAL_MONTHLY_PRICE
  );

const calculateTotalAmount = (totalSetupPrice, totalMonthlyPrice) =>
  totalSetupPrice + totalMonthlyPrice;

export {
  formatRequestValues,
  formatConvertToAgreementRequestValues,
  calculateSubscriptionSetupPrice,
  calculateSubscriptionMonthlyPrice,
  calculateSubscriptionTotalSetupPrice,
  calculateSubscriptionTotalMonthlyPrice,
  calculateSubscriptionTotalAmount,
  calculateAllTotalSetupPrice,
  calculateAllTotalMonthlyPrice,
  calculateTotalAmount,
};
