import React from "react";
import { FormGroup, Row, Col } from "reactstrap";
import { Field } from "react-final-form";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { FORM_TYPES } from "constants/form";
import { ADMIN_ROLES } from "constants/userRoles";

import { required, email, composeValidators } from "utils/validation";

import {
  renderInputField,
  renderDropdownField,
} from "components/ReduxForm/RenderField";
import PasswordField from "components/ReduxForm/PasswordField/PasswordField";
import LanguageField, { FIELD_TYPE } from "components/User/Form/LanguageField";

const UserSection = ({
  formType,
  dropdownPbxRoles,
  isRolesLoading,
  onEmailChangeHandler,
  isLanguageFieldLoading,
  fieldType,
}) => {
  const {
    currentUser: { role },
  } = useSelector(({ auth }) => auth);
  const isCompanyUser = ADMIN_ROLES.includes(role);
  const emailSection =
    formType === FORM_TYPES.EDIT && !isCompanyUser ? null : (
      <Col md={6}>
        <FormGroup>
          <Field
            name="user.email"
            component={renderInputField}
            validate={composeValidators(required, email)}
            placeholder="user@sc.dk"
            labelTranslation="attributes.email"
            inputOnChange={onEmailChangeHandler}
          />
        </FormGroup>
      </Col>
    );
  const passwordSection =
    formType === FORM_TYPES.EDIT ? null : (
      <Col md={6}>
        <FormGroup>
          <Field
            name="user.password"
            component={PasswordField}
            validate={required}
            placeholderTranslation="password.veryStrong"
            labelTranslation="attributes.password"
            type="password"
          />
        </FormGroup>
      </Col>
    );

  return (
    <Row>
      <Col md={6}>
        <FormGroup>
          <Field
            name="user.first_name"
            component={renderInputField}
            validate={required}
            placeholder="John"
            labelTranslation="attributes.firstName"
          />
        </FormGroup>
      </Col>

      <Col md={6}>
        <FormGroup>
          <Field
            name="user.last_name"
            component={renderInputField}
            placeholder="Doe"
            labelTranslation="attributes.lastName"
            parse={(value) => value}
          />
        </FormGroup>
      </Col>

      {emailSection}
      {passwordSection}

      <Col md={6}>
        <FormGroup>
          <Field
            name="user.user_role.role_id"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={dropdownPbxRoles}
            isLoading={isRolesLoading}
            labelTranslation="attributes.role"
          />
        </FormGroup>
      </Col>
      <LanguageField
        show={formType === "create"}
        isDebtorLanguageLoading={isLanguageFieldLoading}
        name="user.language"
        fieldType={fieldType}
      />
    </Row>
  );
};

UserSection.defaultProps = {
  onEmailChangeHandler: () => {},
  isLanguageFieldLoading: false,
  fieldType: FIELD_TYPE.REDUX_FORM,
};

UserSection.propTypes = {
  formType: PropTypes.string.isRequired,
  isRolesLoading: PropTypes.bool.isRequired,
  dropdownPbxRoles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onEmailChangeHandler: PropTypes.func,
  isLanguageFieldLoading: PropTypes.bool,
  fieldType: PropTypes.oneOf(Object.values(FIELD_TYPE)),
};

export default UserSection;
