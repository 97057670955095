import {
  USER_CREATE_REQUEST,
  USER_CREATE_RECEIVED,
  USER_CREATE_FAILED,
  USER_FETCH_REQUEST,
  USER_FETCH_RECEIVED,
  USER_FETCH_FAILED,
  SINGLE_USER_FETCH_REQUEST,
  SINGLE_USER_FETCH_RECEIVED,
  SINGLE_USER_FETCH_FAILED,
  USER_UPDATE_REQUEST,
  USER_UPDATE_RECEIVED,
  USER_UPDATE_FAILED,
  USER_FETCH_CLEAR_ERRORS,
  USER_SEARCH_REQUEST,
  USER_DELETE_REQUEST,
  USER_DELETE_RECEIVED,
  USER_DELETE_FAILED,
  SWITCH_USER_LANGUAGE_REQUEST,
  SWITCH_USER_LANGUAGE_RECEIVED,
  SWITCH_USER_LANGUAGE_FAILED,
} from "redux/actions/user";

const defaultAuthState = {
  users: [],
  commitError: "",
  fetchError: "",
  commitSuccess: false,
  isLoading: false,
  isSearching: false,
};

function user(state = defaultAuthState, action) {
  const { users, user: singleUser } = action;

  switch (action.type) {
    case USER_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case USER_CREATE_RECEIVED:
      return {
        ...state,
        user: singleUser,
        isLoading: false,
        commitSuccess: true,
      };

    case USER_CREATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case USER_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
      };

    case USER_FETCH_RECEIVED:
      return {
        ...state,
        users,
        isLoading: false,
        fetchError: null,
      };

    case USER_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
      };

    case SINGLE_USER_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitSuccess: false,
      };

    case SINGLE_USER_FETCH_RECEIVED:
      return {
        ...state,
        user: singleUser,
        isLoading: false,
      };

    case SINGLE_USER_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case USER_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case USER_UPDATE_RECEIVED:
      return {
        ...state,
        user: singleUser,
        isLoading: false,
        commitSuccess: true,
      };

    case USER_UPDATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case USER_FETCH_CLEAR_ERRORS:
      return {
        ...state,
        fetchError: "",
        isLoading: false,
        commitSuccess: false,
        commitError: "",
      };

    case USER_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case USER_DELETE_RECEIVED:
      return {
        ...state,
        isLoading: false,
      };

    case USER_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case USER_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    case SWITCH_USER_LANGUAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case SWITCH_USER_LANGUAGE_RECEIVED:
      return {
        ...state,
        isLoading: false,
      };

    case SWITCH_USER_LANGUAGE_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}

export default user;
