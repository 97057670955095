import React from "react";
import Skeleton from "react-loading-skeleton";

const DebtorsHeader = () => {
  return (
    <div className="d-flex justify-content-end p-4">
      <Skeleton height={90} width={275} className="m-0" />
    </div>
  );
};

export default DebtorsHeader;
