import React from "react";
import { FormGroup, Row, Col } from "reactstrap";
import { Field, useForm, useField } from "react-final-form";
import PropTypes from "prop-types";
import { OnChange } from "react-final-form-listeners";

import { required } from "utils/validation";
import { formatDropdownOptions, dropdownFieldStyle } from "utils/utils";
import {
  validateLocationConstraintForNetworkService,
  validateLocationForNetworkService,
} from "utils/validations/networkService";

import {
  cellPhoneServicesLocation,
  cellPhoneServicesLocationConstraint,
} from "constants/dropdownOptions";
import {
  NETWORK_SERVICE_LOCATION_CONSTRAINT_TYPES,
  NETWORK_SERVICES_WITH_RESTRICTIONS,
  NETWORK_SERVICE_DEFAULT_LOCATIONS,
  NETWORK_SERVICE_DEFAULT_LOCATION_CONSTRAINT,
} from "constants/constant";

import { renderDropdownField } from "components/ReduxForm/RenderField";

const FieldsSection = ({
  fieldName,
  filteredSubscriptionServices,
  cellPhoneServices,
}) => {
  const form = useForm();

  const {
    input: { value: serviceID },
  } = useField(`${fieldName}.cell_phone_service_id`);

  const handleNetworkServiceSelect = (value) => {
    const selectedNetworkService = cellPhoneServices.find(
      ({ id }) => id === value
    );

    const { name: serviceName } = selectedNetworkService;

    const matchNetworkServiceSubstring =
      NETWORK_SERVICES_WITH_RESTRICTIONS.find((restrictedService) =>
        serviceName.includes(restrictedService)
      );

    if (matchNetworkServiceSubstring) {
      form.change(
        `${fieldName}.location`,
        NETWORK_SERVICE_DEFAULT_LOCATIONS.ENTIRE_WORLD
      );

      form.change(
        `${fieldName}.location_constraint`,
        NETWORK_SERVICE_LOCATION_CONSTRAINT_TYPES.INSIDE
      );

      return;
    }

    form.resetFieldState(`${fieldName}.location`);
    form.resetFieldState(`${fieldName}.location_constraint`);
  };

  return (
    <>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Field
              name={`${fieldName}.cell_phone_service_id`}
              validate={required}
              validateFields={[
                `${fieldName}.location`,
                `${fieldName}.location_constraint`,
              ]}
              component={renderDropdownField}
              dropdownOptions={formatDropdownOptions(
                filteredSubscriptionServices
              )}
              labelTranslation="attributes.serviceName"
              placeholderTranslation="cellPhone.networkServices.chooseService"
              styles={dropdownFieldStyle()}
            />

            <OnChange name={`${fieldName}.cell_phone_service_id`}>
              {(value) => {
                handleNetworkServiceSelect(value);
              }}
            </OnChange>
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Field
              name={`${fieldName}.location`}
              validate={(value) =>
                validateLocationForNetworkService(
                  serviceID,
                  cellPhoneServices,
                  value
                )
              }
              validateFields={[
                `${fieldName}.location_constraint`,
                `${fieldName}.location`,
              ]}
              component={renderDropdownField}
              dropdownOptions={cellPhoneServicesLocation}
              labelTranslation="attributes.location"
              placeholderTranslation="cellPhone.networkServices.chooseLocation"
              styles={dropdownFieldStyle()}
              defaultValue={NETWORK_SERVICE_DEFAULT_LOCATIONS.DENMARK}
            />
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Field
              name={`${fieldName}.location_constraint`}
              validate={(value) =>
                validateLocationConstraintForNetworkService(
                  serviceID,
                  cellPhoneServices,
                  value
                )
              }
              validateFields={[
                `${fieldName}.location`,
                `${fieldName}.location_constraint`,
              ]}
              component={renderDropdownField}
              dropdownOptions={cellPhoneServicesLocationConstraint}
              labelTranslation="attributes.locationConstraint"
              placeholderTranslation="cellPhone.networkServices.chooseLocationConstraint"
              styles={dropdownFieldStyle()}
              defaultValue={NETWORK_SERVICE_DEFAULT_LOCATION_CONSTRAINT.INSIDE}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

FieldsSection.defaultProps = {};

FieldsSection.propTypes = {
  filteredSubscriptionServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      is_default: PropTypes.bool,
    })
  ).isRequired,
  fieldName: PropTypes.string.isRequired,
  cellPhoneServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      is_default: PropTypes.bool,
      find: PropTypes.func,
    })
  ).isRequired,
};

export default FieldsSection;
