import React from "react";
import PropTypes from "prop-types";
import { Spinner as ReactSpinner } from "reactstrap";

const Spinner = ({ size, isLoading, className }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <ReactSpinner
      size={size}
      className={`me-1 ${className}`}
      data-testid="spinner"
    />
  );
};

Spinner.defaultProps = {
  size: "sm",
  isLoading: false,
  className: "",
};

Spinner.propTypes = {
  size: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};

export default Spinner;
