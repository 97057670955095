import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.apiEndPoint()}/agreements/all?filter=:filterName`,
  UPDATE: `${apiUrlConfig.apiEndPoint()}/agreements/:id`,
  CREATE: `${apiUrlConfig.apiEndPoint()}/agreements`,
  SHOW: `${apiUrlConfig.apiEndPoint()}/agreements/:id`,
  DELETE: `${apiUrlConfig.apiEndPoint()}/agreements/:id`,
  OVERVIEW: `${apiUrlConfig.apiEndPoint()}/agreements/overview`,
  SEARCH_BY_PROPERTIES: `${apiUrlConfig.apiEndPoint()}/agreements/search_by_properties?query=:query`,
};

class AgreementApi extends AuthenticatedRequest {
  /**
   * Fetch all agreements in the system
   *
   * Explicit to super admins
   *
   */
  static fetchAll(withDebtorName, filter) {
    const apiUrl = withDebtorName
      ? `${URLS.INDEX}&with_debtor_name=${withDebtorName}`
      : URLS.INDEX;

    const filterUrl = apiUrl.replace(":filterName", filter);

    return this.get(filterUrl);
  }

  /**
   * Update an agreement
   *
   * @param {int} id ID of an agreement
   *
   * @param {object} attributes attributes of an agreement
   * @param {Date} start_date starting date for an agreement
   * @param {Date} end_date termination date for an agreement
   * @param {boolean} is_archived is agreement disabled or enabled
   * @param {number} odoo_debtor_id debtor in ODOO for whom agreement is created for
   * @param {number} odoo_payment_term_id payment term saved in ODOO for invoicing options of an agreement (how many days in advance should invoice be created and emailed to a debtor or user? 8 days, 2 months ... )
   * @param {string} invoice_period (enum) how frequently does user want the invoice to be paid? monthly, quarterly, yearly ...
   * @param {number} total_amount total amount of an agreement
   * @param {boolean} is_offer is this agreement an offer?
   *
   * @param {object} subscriptions_attributes subscriptions for an agreement
   * @param {Date} start_date starting date for subscription
   * @param {Date} end_date termination date for subscription
   * @param {boolean} is_archived is agreement disabled or enabled
   * @param {number} odoo_context_id subscription type saved under context_id in ODOO (is it voip, internet connections, service contracts)
   * @param {number} total_amount total amount for a subscription
   *
   * * @param {object} subscription_lines_attributes subscription lines for a subscription in an agreement
   * @param {Date} start_date starting date for subscription line
   * @param {Date} end_date termination date for subscription line
   * @param {boolean} is_archived is agreement disabled or enabled
   * @param {number} odoo_product_id product id of a subscription line
   * @param {number} price price of a subscription line
   * @param {string} line_type (enum) type of a subscription line (is it subscription, add_on or setup?)
   * @param {string} product_name name of product for a subscription line
   *
   *  @param {object} subscription_line_properties_attributes properties of a subscription line
   * @param {string} property name of a subscription line property
   * @param {string} property value of a property
   *
   */
  static update(id, attributes) {
    const updateUrl = URLS.UPDATE.replace(":id", id);

    const requestBody = {
      agreement: {
        ...attributes,
      },
    };

    return this.patch(updateUrl, requestBody);
  }

  /**
   * Create an agreement
   *
   * @param {object} attributes attributes of an agreement
   * @param {Date} start_date starting date for an agreement
   * @param {Date} end_date termination date for an agreement
   * @param {boolean} is_archived is agreement disabled or enabled
   * @param {number} odoo_debtor_id debtor in ODOO for whom agreement is created for
   * @param {number} odoo_payment_term_id payment term saved in ODOO for invoicing options of an agreement (how many days in advance should invoice be created and emailed to a debtor or user? 8 days, 2 months ... )
   * @param {string} invoice_period (enum) how frequently does user want the invoice to be paid? monthly, quarterly, yearly ...
   * @param {number} total_amount total amount of an agreement
   * @param {boolean} is_offer is this agreement an offer?
   *
   * @param {object} subscriptions_attributes subscriptions for an agreement
   * @param {Date} start_date starting date for subscription
   * @param {Date} end_date termination date for subscription
   * @param {boolean} is_archived is agreement disabled or enabled
   * @param {number} odoo_context_id subscription type saved under context_id in ODOO (is it voip, internet connections, service contracts)
   * @param {number} total_amount total amount for a subscription
   *
   * @param {object} subscription_lines_attributes subscription lines for a subscription in an agreement
   * @param {Date} start_date starting date for subscription line
   * @param {Date} end_date termination date for subscription line
   * @param {boolean} is_archived is agreement disabled or enabled
   * @param {number} odoo_product_id product id of a subscription line
   * @param {number} price price of a subscription line
   * @param {string} line_type (enum) type of a subscription line (is it subscription, add_on or setup?)
   * @param {string} product_name name of product for a subscription line
   *
   * @param {object} subscription_line_properties_attributes properties of a subscription line
   * @param {string} property name of a subscription line property
   * @param {string} property value of a property
   *
   */
  static create(attributes) {
    const requestBody = {
      agreement: {
        ...attributes,
      },
    };

    return this.post(URLS.CREATE, requestBody);
  }

  /**
   * Fetch single agreement
   *
   * @param {int} id ID of a agreement to fetch
   */
  static fetchOne(
    id,
    activeSubscriptions = false,
    withoutSubscriptions = false
  ) {
    const fetchOneUrl = `${URLS.SHOW.replace(
      ":id",
      id
    )}?without_subscriptions=${withoutSubscriptions}&active_subscriptions=${activeSubscriptions}`;

    return this.get(fetchOneUrl);
  }

  /**
   * Fetch agreement overview or status like active agreements count, total amount, etc.
   *
   */
  static overview(debtorID) {
    const overviewUrl = debtorID
      ? `${URLS.OVERVIEW}?debtor_id=${debtorID}`
      : URLS.OVERVIEW;

    return this.get(overviewUrl);
  }

  static searchAgreements(searchtext) {
    const searchUrl = URLS.SEARCH_BY_PROPERTIES.replace(":query", searchtext);

    return this.get(searchUrl);
  }

  /**
   * delete agreement
   *
   * @param {int} id ID of a agreement to delete
   */
  static deleteOne(id) {
    const updateUrl = URLS.DELETE.replace(":id", id);

    return this.delete(updateUrl);
  }
}

export default AgreementApi;
