import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const ViewButton = ({ handleButtonClick, className }) => {
  return (
    <Button
      color="primary"
      onClick={handleButtonClick}
      className={`${className} d-flex justify-content-center align-items-center`}
      outline
      name="View Activity Logs"
    >
      <FontAwesomeIcon icon={faEye} className="m-0" />
    </Button>
  );
};

ViewButton.defaultProps = {
  className: "",
};

ViewButton.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default ViewButton;
