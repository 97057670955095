import { DashboardApi } from "internal";
import { getAndDisplayErrors } from "utils/utils";

export const DASHBOARD_INVOICE_FETCH_REQUEST =
  "DASHBOARD_INVOICE_FETCH_REQUEST";
export const DASHBOARD_INVOICE_FETCH_RECEIVED =
  "DASHBOARD_INVOICE_FETCH_RECEIVED";
export const DASHBOARD_INVOICE_FETCH_FAILED = "DASHBOARD_INVOICE_FETCH_FAILED";
export const DASHBOARD_INVOICE_FETCH_CLEAR_ERRORS =
  "DASHBOARD_INVOICE_FETCH_CLEAR_ERRORS";

export function dashboardInvoiceFetchReceived(response) {
  return {
    type: DASHBOARD_INVOICE_FETCH_RECEIVED,
    data: response.data,
  };
}

export function dashboardInvoiceFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: DASHBOARD_INVOICE_FETCH_FAILED,
    message,
  };
}

export function dashboardInvoiceFetchClearErrors() {
  return {
    type: DASHBOARD_INVOICE_FETCH_CLEAR_ERRORS,
  };
}

export const dashboardInvoiceFetchRequest = () => (dispatch) => {
  dispatch({ type: DASHBOARD_INVOICE_FETCH_REQUEST });

  DashboardApi.fetchInvoiceDasboardDetail()
    .then((data) => dispatch(dashboardInvoiceFetchReceived(data)))
    .catch((e) => {
      dispatch(dashboardInvoiceFetchFailed(e));
    });
};
