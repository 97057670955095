import React, { useState, useCallback, useEffect, useRef } from "react";
import { Input } from "reactstrap";
import { I18n, Translate } from "react-i18nify";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import InputLabel from "components/ReduxForm/InputLabel";

import { getPlaceholderText } from "utils/utils";

const SearchResult = ({ data, isLoading, handleUpdate, isFocused }) => {
  if (isLoading)
    return (
      <div className="search-field-list loading position-absolute  left-0 w-100 bg-white overflow-auto py-3">
        <p className="mb-0 px-2">
          <Translate value="common.searching" />
          ...
        </p>
      </div>
    );

  if (data.length === 0 && isFocused)
    return (
      <div className="search-field-list loading position-absolute  left-0 w-100 bg-white overflow-auto py-3">
        <p className="mb-0 px-2">
          <Translate value="common.searchResultNotFound" />
        </p>
      </div>
    );

  if (!data || data.length === 0) return null;

  return (
    <ul className="list-group search-field-list position-absolute  left-0 w-100 bg-white overflow-auto">
      {data.map(({ label, value }, index) => (
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        <li
          data-cy="list-group-item"
          data-testid={`list-group-item-${index}`}
          key={index}
          tabIndex="0"
          role="menuitem"
          className="list-group-item px-2"
          onClick={(e) => {
            e.stopPropagation();
            handleUpdate(value);
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.code === "Enter") {
              handleUpdate(value);
            }
          }}
        >
          {label}
        </li>
      ))}
    </ul>
  );
};

const SearchableDropdown = ({
  input: { name, value, onBlur, onChange, onFocus, type, ...input },
  placeholder,
  placeholderTranslation,
  label,
  labelTranslation,
  inputClassName,
  disabled,
  replacementTranslations,
  onChangeCb,
  searchResult,
  isLoading,
  meta: { touched, error, warning },
  readable,
}) => {
  const [openList, setListVisibility] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(null);

  const searchData = openList ? searchResult : [];

  function throttle(cb, delay = 400) {
    let shouldWait = false;
    let timeout;

    return (...args) => {
      if (!shouldWait) {
        shouldWait = true;
        cb(...args);

        return;
      }

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const searchFeature = useCallback(throttle(onChangeCb), []);

  const labelForValue = searchResult.find(
    ({ value: searchValue }) => searchValue === value
  );

  const handleCloseList = () => {
    setListVisibility(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleCloseList);

    return () => document.removeEventListener("click", handleCloseList);
  }, []);

  const searchLoadingCallBack = () => {
    if (loadingRef.current) return;
    loadingRef.current = true;
  };

  useEffect(() => {
    if (!isLoading) setLoading(false);
  }, [isLoading]);

  const handleChange = (e) => {
    e.stopPropagation();

    setListVisibility(true);
    onChange(e);
    searchFeature(e.target.value.trim());
    searchLoadingCallBack();

    if (e.target.id === name && isLoading) setLoading(true);
  };

  const handleOnBlur = (e) => {
    e.stopPropagation();
    onBlur(e);
    setFocused(false);
    if (
      isFocused &&
      !searchResult.some(({ value: searchValue }) => searchValue === value)
    ) {
      e.target.value = "";
      onChange(null);
    }
  };

  const handleFocus = (e) => {
    e.stopPropagation();
    onFocus(e);
    setFocused(true);
  };

  const handleUpdateValue = (selectedValue) => {
    onChange(selectedValue);
    setListVisibility(false);
  };

  const updatedValue = readable ? value : labelForValue?.label;

  return (
    <I18n
      render={() => {
        const placeholderText = getPlaceholderText({
          label,
          placeholder,
          placeholderTranslation,
          labelTranslation,
          replacementTranslations: replacementTranslations.placeholder,
        });

        return (
          <div>
            <InputLabel
              name={name}
              label={label}
              labelTranslation={labelTranslation}
              replacementTranslations={replacementTranslations.label}
            />
            <div className="position-relative">
              <Input
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...input}
                placeholder={placeholderText}
                type={type}
                id={name}
                value={updatedValue}
                disabled={disabled}
                onFocus={handleFocus}
                className={inputClassName}
                onBlur={handleOnBlur}
                onChange={handleChange}
                readOnly={readable}
                autoComplete="off"
                data-field-name={name}
                data-testid={name}
              />
              <FontAwesomeIcon
                icon={faSearch}
                className="search-icon"
                data-testid="search-icon"
              />
              <SearchResult
                data={searchData}
                isLoading={loading}
                isFocused={isFocused && value}
                handleUpdate={handleUpdateValue}
              />
            </div>

            {touched &&
              ((error && (
                <span className="invalid-text text-danger">{error}</span>
              )) ||
                (warning && (
                  <span className="invalid-text text-warning">{warning}</span>
                )))}
          </div>
        );
      }}
    />
  );
};

SearchResult.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  isFocused: PropTypes.bool.isRequired,
};

SearchableDropdown.defaultProps = {
  placeholder: "",
  label: undefined,
  disabled: false,
  hidden: false,
  min: undefined,
  max: undefined,
  inputClassName: "",
  labelTranslation: undefined,
  placeholderTranslation: undefined,
  replacementTranslations: {
    label: {},
    placeholder: {},
  },
  inputOnChange: () => {},
  onChangeCb: () => {},
  searchResult: [],
  readable: false,
};

SearchableDropdown.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    type: PropTypes.string,
    onFocus: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  placeholder: PropTypes.string,
  placeholderTranslation: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  labelTranslation: PropTypes.string,
  inputClassName: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  replacementTranslations: PropTypes.shape({
    placeholder: PropTypes.shape({}),
    label: PropTypes.shape({}),
  }),
  inputOnChange: PropTypes.func,
  onChangeCb: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  searchResult: PropTypes.arrayOf(PropTypes.shape({})),
  readable: PropTypes.bool,
};

export default SearchableDropdown;
