import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";
import { Column } from "react-virtualized";

import {
  settingFetchRequest,
  searchSettings,
  settingSearchRequest,
} from "redux/actions/setting";

import filterSearchResults from "utils/filterSearchResults";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";

const SettingIndex = ({
  fetchAllSettings,
  settings,
  isLoading,
  searchRecords,
  isSearching,
  fetchError,
}) => {
  useEffect(() => {
    fetchAllSettings();
  }, []);

  return (
    <RecordListingTable
      records={settings}
      isLoading={isLoading}
      resourceName="app_settings"
      resourceTitle={<Translate value="title.page.appSetting.list" />}
      searchRecords={searchRecords}
      isSearching={isSearching}
      redirectToEdit
      fetchError={fetchError}
      onRetry={fetchAllSettings}
      dataCy="add-btn"
    >
      <Column
        width={180}
        label={<Translate value="attributes.name" />}
        dataKey="name"
      />
      <Column
        width={180}
        label={<Translate value="attributes.category" />}
        dataKey="category"
      />
      <Column
        width={200}
        label={<Translate value="attributes.dataType" />}
        dataKey="data_type"
      />
      <Column
        width={200}
        label={<Translate value="attributes.userType" />}
        dataKey="user_type"
      />
      <Column
        width={300}
        label={<Translate value="attributes.options" />}
        dataKey="options"
      />
    </RecordListingTable>
  );
};

SettingIndex.defaultProps = {
  isLoading: true,
  isSearching: false,
  fetchError: null,
};

SettingIndex.propTypes = {
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      category: PropTypes.string,
    })
  ).isRequired,
  isLoading: PropTypes.bool,
  fetchAllSettings: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  searchRecords: PropTypes.func.isRequired,
  fetchError: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { isLoading, isSearching, settings, fetchError } = state.setting;

  const results = filterSearchResults({
    resource: settings,
    searchResult: state.search.settings,
  });

  return {
    settings: results,
    isLoading,
    isSearching,
    fetchError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchRecords: (e) => {
    dispatch(settingSearchRequest(e));
    dispatch(searchSettings(e));
  },
  fetchAllSettings: () => {
    dispatch(settingFetchRequest());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingIndex);
