import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Translate, t } from "react-i18nify";
import { Column } from "react-virtualized";

import {
  faxStatusFetchRequest,
  searchFaxStatuses,
  faxStatusSearchRequest,
} from "redux/actions/pbxEngine/faxStatus";

import filterSearchResults from "utils/filterSearchResults";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import TranslateDate from "components/i18n/TranslateDate";

const renderFaxStatusTranslateDate = ({ cellData }) => (
  <TranslateDate date={cellData} />
);

const AgreementIndex = ({
  fetchFaxStatuses,
  faxStatuses,
  isLoading,
  searchRecords,
  isSearching,
}) => {
  const { phone_number: phoneNumber } = useParams();

  useEffect(() => {
    fetchFaxStatuses(phoneNumber);
  }, []);

  let records;

  if (faxStatuses) {
    records = faxStatuses.filter(
      (faxStatus) => faxStatus.source_number === phoneNumber
    );
  }

  return (
    <RecordListingTable
      records={records}
      isLoading={isLoading || (faxStatuses.length === 0 && isLoading)}
      resourceName="fax_statuses"
      resourceTitle={
        <Translate
          value="title.page.resource.list"
          resource={t("title.page.faxStatus")}
        />
      }
      searchRecords={searchRecords}
      isSearching={isSearching}
      hideAddButton
    >
      <Column
        width={230}
        label={<Translate value="attributes.destinationNumber" />}
        dataKey="destination_number"
      />
      <Column
        width={230}
        label={<Translate value="attributes.direction" />}
        dataKey="direction"
      />
      <Column
        width={230}
        label={<Translate value="attributes.date" />}
        dataKey="sent_date"
        cellRenderer={renderFaxStatusTranslateDate}
      />
      <Column
        width={160}
        label={<Translate value="attributes.status" />}
        dataKey="status"
      />
    </RecordListingTable>
  );
};

AgreementIndex.defaultProps = {
  faxStatuses: [],
  isLoading: true,
  isSearching: false,
};

AgreementIndex.propTypes = {
  faxStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      destination_number: PropTypes.string,
    })
  ),
  searchRecords: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  fetchFaxStatuses: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
};

const mapStateToProps = ({ faxStatus, search }) => {
  const { faxStatuses, isLoading, isSearching } = faxStatus;

  const results = filterSearchResults({
    resource: faxStatuses,
    searchResult: search.faxStatuses,
  });

  return {
    faxStatuses: results,
    isLoading,
    isSearching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchRecords: (e) => {
    dispatch(faxStatusSearchRequest(e));
    dispatch(searchFaxStatuses(e));
  },
  fetchFaxStatuses: (phoneNumber) => {
    dispatch(faxStatusFetchRequest(phoneNumber));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AgreementIndex);
