import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTty,
  faMobileAlt,
  faNetworkWired,
  faServer,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";

import SubscriptionList from "components/Agreement/AgreementDetail/Subscription/SubscriptionList";

import { Translate } from "react-i18nify";

const SubscriptionGroupTab = ({
  subscriptions,
  isArchived,
  isAgreementArchived,
  products,
}) => {
  const [activeTab, setActiveTab] = useState("voip");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const subscriptionTypeSections = [
    {
      title: <Translate value="title.tab.agreement.voip" />,
      subscriptionType: "voip",
      icon: <FontAwesomeIcon icon={faTty} className="me-2" />,
    },
    {
      title: <Translate value="common.cellPhone" />,
      subscriptionType: "cell_phone",
      icon: <FontAwesomeIcon icon={faMobileAlt} className="me-2" />,
    },
    {
      title: <Translate value="title.tab.agreement.internetConnections" />,
      subscriptionType: "internet_connections",
      icon: <FontAwesomeIcon icon={faNetworkWired} className="me-2" />,
    },
    {
      title: <Translate value="title.tab.agreement.hostingServices" />,
      subscriptionType: "hosting_services",
      icon: <FontAwesomeIcon icon={faServer} className="me-2" />,
    },
    {
      title: <Translate value="title.tab.agreement.serviceContract" />,
      subscriptionType: "service_contracts",
      icon: <FontAwesomeIcon icon={faFileAlt} className="me-2" />,
    },
  ];

  const subscriptionTypeTabs = subscriptionTypeSections.map(
    (subscriptionDetailSection) => {
      const { title, subscriptionType: tabID } = subscriptionDetailSection;

      const filteredSubscriptions = subscriptions.filter(
        (subscription) =>
          subscription.is_archived === isArchived &&
          subscription.subscription_type === tabID
      );

      return (
        <NavItem key={tabID} className={{ active: activeTab === tabID }}>
          <NavLink
            className="pl-4 pr-2 py-4 d-flex align-items-center"
            onClick={() => {
              toggleTab(tabID);
            }}
            data-field-name={`${tabID}`}
          >
            <span className="badge bg-primary me-2 rounded-circle">
              {filteredSubscriptions.length}
            </span>
            {title}
          </NavLink>
        </NavItem>
      );
    }
  );

  const subscriptionsByType = subscriptionTypeSections.map(
    (subscriptionSection) => {
      const { subscriptionType: tabID } = subscriptionSection;
      const filteredSubscriptions = subscriptions.filter(
        (subscription) =>
          subscription.is_archived === isArchived &&
          subscription.subscription_type === tabID
      );

      return (
        <TabPane
          tabId={tabID}
          key={tabID}
          className="h-100 subscription-group-tab-pane"
        >
          <SubscriptionList
            subscriptions={filteredSubscriptions}
            isAgreementArchived={isAgreementArchived}
            products={products}
            isArchived={isArchived}
            dataCy={tabID}
          />
        </TabPane>
      );
    }
  );

  return (
    <Row>
      <Col lg="3" md="4">
        <Nav className="m-0 left-nav-links" data-cy="left-nav-links">
          {subscriptionTypeTabs}
        </Nav>
      </Col>
      <Col lg="9" md="8">
        <TabContent
          activeTab={activeTab}
          className="subscription-detail-tab-content p-0 mb-4 bg-white h-100"
        >
          {subscriptionsByType}
        </TabContent>
      </Col>
    </Row>
  );
};

SubscriptionGroupTab.defaultProps = {};

SubscriptionGroupTab.propTypes = {
  subscriptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isArchived: PropTypes.bool.isRequired,
  isAgreementArchived: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SubscriptionGroupTab;
