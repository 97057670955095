import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Translate } from "react-i18nify";

const SubscriptionProductAttributeItem = ({
  icon,
  translatedTitle,
  attributeValue,
  propertiesContent,
}) => {
  const attributeContent = propertiesContent || (
    <span className="text-dark">{attributeValue}</span>
  );

  return (
    <div className="d-md-flex align-items-center justify-content-md-between p-3 product-item">
      <div className="d-flex align-items-center justify-content-md-center my-3 my-md-0">
        <div className="icon-wrapper">
          <FontAwesomeIcon icon={icon} className="text-tertiary" />
        </div>

        <span className="text-dark">
          <Translate value={translatedTitle} />
        </span>
      </div>

      {attributeContent}
    </div>
  );
};

SubscriptionProductAttributeItem.defaultProps = {
  attributeValue: null,
  propertiesContent: null,
};

SubscriptionProductAttributeItem.propTypes = {
  icon: PropTypes.node.isRequired,
  translatedTitle: PropTypes.string.isRequired,
  attributeValue: PropTypes.string,
  propertiesContent: PropTypes.node,
};

export default SubscriptionProductAttributeItem;
