import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "reactstrap";

const ReduxFormCommitButton = ({
  title,
  isLoading,
  pristine,
  submitting,
  submitClassName,
  performOnClickAction,
  handleOnClick,
}) => {
  const loadingIcon =
    submitting || isLoading ? (
      <Spinner as="span" size="sm" className="me-1" data-testid="spinner" />
    ) : null;

  if (!performOnClickAction) {
    return (
      <button
        disabled={pristine || submitting || isLoading}
        type="submit"
        className={`btn ${submitClassName}`}
        value="Submit"
        data-cy="submit"
        data-testid="submit"
      >
        {loadingIcon}
        {title}
      </button>
    );
  }

  return (
    <button
      disabled={pristine || submitting || isLoading}
      type="submit"
      className={`btn ${submitClassName}`}
      value="Submit"
      data-cy="submit"
      onClick={handleOnClick}
    >
      {loadingIcon}
      {title}
    </button>
  );
};

ReduxFormCommitButton.defaultProps = {
  submitClassName: "btn-primary mt-4 mb-3",
  handleOnClick: () => {},
  performOnClickAction: false,
};

ReduxFormCommitButton.propTypes = {
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitClassName: PropTypes.string,
  handleOnClick: PropTypes.func,
  performOnClickAction: PropTypes.bool,
};

export default ReduxFormCommitButton;
