import React from "react";
import { connect } from "react-redux";
import { FormGroup, Row, Col } from "reactstrap";
import { Field, useForm, useField } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import PropTypes from "prop-types";

import { Translate } from "react-i18nify";

import { formatDropdownOptions } from "utils/utils";
import { required } from "utils/validation";

import { renderDropdownField } from "components/ReduxForm/RenderField";

import { NUMBER_PORTING_REQUESTS } from "constants/constant";

const ImportFormCreateFields = ({
  debtors,
  pbxes,
  isPbxesLoading,
  isDebtorsLoading,
  formAction,
  telecomUserEmails,
  isTelecomUserEmailsLoading,
}) => {
  const form = useForm();

  const {
    input: { value: personFillingTheRequestForm },
  } = useField("person_filling_the_request_form");
  const {
    input: { value: odooDebtorID },
  } = useField("odoo_debtor_id");

  const numberTypeOptions = [
    {
      value: "cell_phone",
      label: <Translate value="common.cellPhone" />,
    },
    {
      value: "did_number",
      label: <Translate value="title.resource.didNumber" />,
    },
  ];

  const formFillingPersonOptions = [
    {
      value: NUMBER_PORTING_REQUESTS.PERSON_FILLING_FORM.SUPER_ADMIN,
      label: (
        <Translate value="enum.numberPorting.import.whoShouldFillTheForm.self" />
      ),
    },
    {
      value: NUMBER_PORTING_REQUESTS.PERSON_FILLING_FORM.CUSTOMER,
      label: (
        <Translate value="enum.numberPorting.import.whoShouldFillTheForm.customer" />
      ),
    },
  ];

  const debtorDropdown = formatDropdownOptions(debtors);

  const filteredPbxes = pbxes.filter(
    (pbx) => pbx.odoo_debtor_id === odooDebtorID
  );
  const pbxDropdown = formatDropdownOptions(filteredPbxes, "ref_number");

  const formEditAction = formAction === "edit";
  const customerFillsTheForm =
    personFillingTheRequestForm ===
    NUMBER_PORTING_REQUESTS.PERSON_FILLING_FORM.CUSTOMER;

  const debtorTelecomUserEmails = telecomUserEmails.filter(
    (record) => record.debtor_id === parseInt(odooDebtorID, 10)
  );
  const telecomUserEmailDropdown = formatDropdownOptions(
    debtorTelecomUserEmails[0]?.customer_emails || []
  );

  const resetFormFields = (value) => {
    if (value === NUMBER_PORTING_REQUESTS.PERSON_FILLING_FORM.SUPER_ADMIN)
      form.change("customer_email", undefined);
    else {
      form.batch(() => {
        form.change("requested_porting_date", undefined);
        form.change(
          "number_porting_request_phone_numbers_attributes",
          undefined
        );
      });
    }
  };

  const handleNumberTypeSelect = () => {
    form.change("number_porting_request_phone_numbers_attributes", undefined);
  };

  return (
    <Row>
      <Col md="6">
        <FormGroup>
          <Field
            name="number_type"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={numberTypeOptions}
            labelTranslation="attributes.serviceType"
            disabled={formEditAction}
          />

          <OnChange name="number_type">
            {() => handleNumberTypeSelect()}
          </OnChange>
        </FormGroup>
      </Col>

      <Col md="6">
        <FormGroup>
          <Field
            name="odoo_debtor_id"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={debtorDropdown}
            isLoading={isDebtorsLoading}
            labelTranslation="attributes.affiliation"
            disabled={formEditAction}
          />
        </FormGroup>
      </Col>

      {odooDebtorID && (
        <Col md="6">
          <FormGroup>
            <Field
              name="pbx_id"
              component={renderDropdownField}
              validate={required}
              dropdownOptions={pbxDropdown}
              isLoading={isPbxesLoading}
              disabled={formEditAction}
              labelTranslation="attributes.pbx"
            />
          </FormGroup>
        </Col>
      )}

      {odooDebtorID && !formEditAction && (
        <Col md="6">
          <FormGroup>
            <Field
              name="person_filling_the_request_form"
              validate={required}
              dropdownOptions={formFillingPersonOptions}
              component={renderDropdownField}
              labelTranslation="common.whoShouldFillTheForm"
            />

            <OnChange name="person_filling_the_request_form">
              {(value) => {
                resetFormFields(value);
              }}
            </OnChange>
          </FormGroup>
        </Col>
      )}

      {customerFillsTheForm && (
        <Col md="6">
          <FormGroup>
            <Field
              name="customer_email"
              component={renderDropdownField}
              validate={required}
              dropdownOptions={telecomUserEmailDropdown}
              placeholderTranslation="common.emailPlaceholder"
              labelTranslation="attributes.customerEmail"
              isLoading={isTelecomUserEmailsLoading}
            />
          </FormGroup>
        </Col>
      )}
    </Row>
  );
};

ImportFormCreateFields.defaultProps = {
  isDebtorsLoading: false,
  debtors: [],
  isPbxesLoading: true,
  pbxes: [],
  isTelecomUserEmailsLoading: true,
  telecomUserEmails: [],
};

ImportFormCreateFields.propTypes = {
  debtors: PropTypes.arrayOf(PropTypes.shape()),
  pbxes: PropTypes.arrayOf(PropTypes.shape()),
  isPbxesLoading: PropTypes.bool,
  isDebtorsLoading: PropTypes.bool,
  formAction: PropTypes.string.isRequired,
  isTelecomUserEmailsLoading: PropTypes.bool,
  telecomUserEmails: PropTypes.arrayOf(
    PropTypes.shape({
      debtor_id: PropTypes.number,
      customer_emails: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};

const mapStateToProps = (state) => {
  const { debtor, pbx, contact } = state;
  const { debtors, isLoading: isDebtorsLoading } = debtor;
  const { telecomUserEmails, isLoading: isTelecomUserEmailsLoading } = contact;
  const { pbxs: pbxes, isLoading: isPbxesLoading } = pbx;

  return {
    debtors,
    pbxes,
    isPbxesLoading,
    isDebtorsLoading,
    telecomUserEmails,
    isTelecomUserEmailsLoading,
  };
};

export default connect(mapStateToProps, null)(ImportFormCreateFields);
