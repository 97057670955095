import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-i18nify";

import TranslateNumber from "components/i18n/TranslateNumber";

const DebtorItem = ({ item }) => {
  const { count } = item;

  return (
    <div className="item-content">
      <div className="single-stats">
        <div className="circle-icon">
          <FontAwesomeIcon icon={faBriefcase} className="text-success" />
        </div>
        <h1>
          <TranslateNumber value={count} minimumFractionDigits={0} />
        </h1>
        <small>
          <Translate value="dashboard.debtor.total" />
        </small>
      </div>
    </div>
  );
};

DebtorItem.defaultProps = {
  item: {},
};

DebtorItem.propTypes = {
  item: PropTypes.shape({
    count: PropTypes.number,
    new: PropTypes.number,
    active_count: PropTypes.number,
    active_amount: PropTypes.number,
    archive_count: PropTypes.number,
    archive_amount: PropTypes.number,
    paid_count: PropTypes.number,
    unpaid_count: PropTypes.number,
    unpaid_amount: PropTypes.number,
    draft_count: PropTypes.number,
    draft_amount: PropTypes.number,
    new_list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        business_name: PropTypes.string,
      })
    ),
  }),
};

export default DebtorItem;
