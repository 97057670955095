import React from "react";
import { Route, Routes } from "react-router-dom";

import { Admins } from "constants/userRoles";
import { CELL_PHONE, EDIT } from "constants/routes/routesResources";

import CellPhoneDetail from "views/CellPhone/CellPhoneDetail";
import CellPhoneEdit from "views/CellPhone/CellPhoneEdit";
import CellPhoneSubscriptionUsage from "views/CellPhone/CellPhoneSubscriptionUsage";
import NetworkServicesEdit from "views/NetworkOperatorSubscriptionServices/NetworkServicesEdit";

const AdminCellPhoneEdit = Admins(CellPhoneEdit);
const AdminNetworkServicesEdit = Admins(NetworkServicesEdit);

const CellPhoneRoutes = () => (
  <Routes>
    <Route path={CELL_PHONE.CELL_PHONE_ID}>
      <Route index element={<CellPhoneDetail />} />
      <Route path={EDIT} element={<AdminCellPhoneEdit />} />
      <Route path={CELL_PHONE.NETWORK_SERVICES}>
        <Route path={EDIT} element={<AdminNetworkServicesEdit />} />
      </Route>
    </Route>
    <Route path={CELL_PHONE.CELL_PHONE_NUMBER}>
      <Route
        path={CELL_PHONE.SUBSCRIPTION_USAGES}
        element={<CellPhoneSubscriptionUsage />}
      />
    </Route>
  </Routes>
);

export default CellPhoneRoutes;
