import {
  SUBSCRIPTION_PRODUCT_FETCH_REQUEST,
  SUBSCRIPTION_PRODUCT_FETCH_RECEIVED,
  SUBSCRIPTION_PRODUCT_FETCH_FAILED,
  SUBSCRIPTION_PRODUCT_FETCH_CLEAR_ERRORS,
  SUBSCRIPTION_PRODUCT_UPDATE_REQUEST,
  SUBSCRIPTION_PRODUCT_UPDATE_RECEIVED,
  SUBSCRIPTION_PRODUCT_UPDATE_FAILED,
  SUBSCRIPTION_PRODUCT_CREATE_REQUEST,
  SUBSCRIPTION_PRODUCT_CREATE_RECEIVED,
  SUBSCRIPTION_PRODUCT_CREATE_FAILED,
  SINGLE_SUBSCRIPTION_PRODUCT_FETCH_REQUEST,
  SINGLE_SUBSCRIPTION_PRODUCT_FETCH_RECEIVED,
  SINGLE_SUBSCRIPTION_PRODUCT_FETCH_FAILED,
  SINGLE_SUBSCRIPTION_PRODUCT_DELETE_REQUEST,
  SINGLE_SUBSCRIPTION_PRODUCT_DELETE_RECEIVED,
  SINGLE_SUBSCRIPTION_PRODUCT_DELETE_FAILED,
  SEARCH_SUBSCRIPTION_PRODUCT_REQUEST,
} from "redux/actions/subscriptionProduct";

const defaultState = {
  subscriptionProducts: [],
  commitError: "",
  fetchError: "",
  commitSuccess: false,
  isLoading: false,
};

function subscriptionProduct(state = defaultState, action) {
  const {
    subscriptionProducts,
    subscriptionProduct: singleSubscriptionProduct,
  } = action;

  switch (action.type) {
    case SUBSCRIPTION_PRODUCT_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitSuccess: false,
      };

    case SUBSCRIPTION_PRODUCT_FETCH_RECEIVED:
      return {
        ...state,
        subscriptionProducts,
        isLoading: false,
      };

    case SUBSCRIPTION_PRODUCT_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case SUBSCRIPTION_PRODUCT_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case SUBSCRIPTION_PRODUCT_UPDATE_RECEIVED:
      return {
        ...state,
        subscriptionProduct: singleSubscriptionProduct,
        isLoading: false,
        commitSuccess: true,
      };

    case SUBSCRIPTION_PRODUCT_UPDATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case SUBSCRIPTION_PRODUCT_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case SUBSCRIPTION_PRODUCT_CREATE_RECEIVED:
      return {
        ...state,
        subscriptionProduct: singleSubscriptionProduct,
        isLoading: false,
        commitSuccess: true,
      };

    case SUBSCRIPTION_PRODUCT_CREATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case SINGLE_SUBSCRIPTION_PRODUCT_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitSuccess: false,
      };

    case SINGLE_SUBSCRIPTION_PRODUCT_FETCH_RECEIVED:
      return {
        ...state,
        subscriptionProduct: singleSubscriptionProduct,
        isLoading: false,
      };

    case SINGLE_SUBSCRIPTION_PRODUCT_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case SINGLE_SUBSCRIPTION_PRODUCT_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case SINGLE_SUBSCRIPTION_PRODUCT_DELETE_RECEIVED:
      return {
        ...state,
        isLoading: false,
        commitSuccess: true,
      };

    case SINGLE_SUBSCRIPTION_PRODUCT_DELETE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case SUBSCRIPTION_PRODUCT_FETCH_CLEAR_ERRORS:
      return {
        ...state,
        fetchError: "",
        isLoading: false,
        commitSuccess: false,
        commitError: "",
      };

    case SEARCH_SUBSCRIPTION_PRODUCT_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    default:
      return state;
  }
}

export default subscriptionProduct;
