import { createSearchAction } from "redux-search";
import { toastr } from "react-redux-toastr";
import { t } from "react-i18nify";

import { PhoneNumberApi } from "internal";

import { displayFetchErrorToaster, getServerError } from "utils/utils";
import displayUnMappedFormErrors from "utils/displayUnMappedFormErrors";

import { PHONE_NUMBER_REQUEST_ATTRIBUTES } from "constants/constant";

export const FETCH_PHONE_NUMBERS_REQUEST = "FETCH_PHONE_NUMBERS_REQUEST";
export const FETCH_PHONE_NUMBERS_RECEIVED = "FETCH_PHONE_NUMBERS_RECEIVED";
export const FETCH_PHONE_NUMBERS_ERROR = "FETCH_PHONE_NUMBERS_ERROR";

export const FETCH_SERVICE_PROVIDERS_REQUEST =
  "FETCH_SERVICE_PROVIDERS_REQUEST";
export const FETCH_SERVICE_PROVIDERS_RECEIVED =
  "FETCH_SERVICE_PROVIDERS_RECEIVED";
export const FETCH_SERVICE_PROVIDERS_ERROR = "FETCH_SERVICE_PROVIDERS_ERROR";

export const CREATE_PHONE_NUMBER_REQUEST = "CREATE_PHONE_NUMBER_REQUEST";
export const CREATE_PHONE_NUMBER_RECEIVED = "CREATE_PHONE_NUMBER_RECEIVED";
export const CREATE_PHONE_NUMBER_ERROR = "CREATE_PHONE_NUMBER_ERROR";

export const CREATE_BULK_PHONE_NUMBER_REQUEST =
  "CREATE_BULK_PHONE_NUMBER_REQUEST";
export const CREATE_BULK_PHONE_NUMBER_RECEIVED =
  "CREATE_BULK_PHONE_NUMBER_RECEIVED";
export const CREATE_BULK_PHONE_NUMBER_ERROR = "CREATE_BULK_PHONE_NUMBER_ERROR";

export const FETCH_PHONE_NUMBER_DETAIL_REQUEST =
  "FETCH_PHONE_NUMBER_DETAIL_REQUEST";
export const FETCH_PHONE_NUMBER_DETAIL_RECEIVED =
  "FETCH_PHONE_NUMBER_DETAIL_RECEIVED";
export const FETCH_PHONE_NUMBER_DETAIL_ERROR =
  "FETCH_PHONE_NUMBER_DETAIL_ERROR";

export const UPDATE_PHONE_NUMBER_REQUEST = "UPDATE_PHONE_NUMBER_REQUEST";
export const UPDATE_PHONE_NUMBER_RECEIVED = "UPDATE_PHONE_NUMBER_RECEIVED";
export const UPDATE_PHONE_NUMBER_ERROR = "UPDATE_PHONE_NUMBER_ERROR";

export const DELETE_PHONE_NUMBER_REQUEST = "DELETE_PHONE_NUMBER_REQUEST";
export const DELETE_PHONE_NUMBER_RECEIVED = "DELETE_PHONE_NUMBER_RECEIVED";
export const DELETE_PHONE_NUMBER_ERROR = "DELETE_PHONE_NUMBER_ERROR";

export const CLEAR_PHONE_NUMBERS_ERRORS = "CLEAR_PHONE_NUMBERS_ERRORS";

export const PHONE_NUMBER_SEARCH_REQUEST = "PHONE_NUMBER_SEARCH_REQUEST";

export const searchPhoneNumber = createSearchAction("phoneNumbers");

export function phoneNumberSearchRequest(value) {
  const isSearching = Boolean(value);

  return {
    type: PHONE_NUMBER_SEARCH_REQUEST,
    isSearching,
  };
}

export function FetchPhoneNumberReceived(data) {
  return {
    type: FETCH_PHONE_NUMBERS_RECEIVED,
    phoneNumbers: data,
  };
}

export function fetchPhoneNumberFailed(errorData) {
  const { response } = errorData;
  const error = getServerError(response);

  displayFetchErrorToaster(error);

  return {
    type: FETCH_PHONE_NUMBERS_ERROR,
    message: error,
  };
}

export const phoneNumberRequest =
  (
    numberType = PHONE_NUMBER_REQUEST_ATTRIBUTES.NUMBER_TYPE,
    status = PHONE_NUMBER_REQUEST_ATTRIBUTES.STATUS,
    costType = PHONE_NUMBER_REQUEST_ATTRIBUTES.COST_TYPE
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_PHONE_NUMBERS_REQUEST });

      const response = await PhoneNumberApi.fetchAll(
        numberType,
        status,
        costType
      );

      const data = response.data.map((phoneNumber) => ({
        ...phoneNumber,
        service_owner_name: phoneNumber.service_provider.name,
      }));

      dispatch(FetchPhoneNumberReceived(data));
    } catch (error) {
      dispatch(fetchPhoneNumberFailed(error));
    }
  };

export function fetchServiceProviderReceived(data) {
  return {
    type: FETCH_SERVICE_PROVIDERS_RECEIVED,
    serviceProviders: data,
  };
}

export function fetchServiceProviderError(errorData) {
  const { response } = errorData;
  const error = getServerError(response);

  displayFetchErrorToaster(error);

  return {
    type: FETCH_SERVICE_PROVIDERS_ERROR,
    message: error,
  };
}

export const serviceProviderRequest = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SERVICE_PROVIDERS_REQUEST });

    const response = await PhoneNumberApi.fetchServiceProviders();

    dispatch(fetchServiceProviderReceived(response.data));
  } catch (error) {
    dispatch(fetchServiceProviderError(error));
  }
};

export function createPhoneNumberCreate(data) {
  return {
    type: CREATE_PHONE_NUMBER_RECEIVED,
    phoneNumber: data,
  };
}

export function createPhoneNumberError(errorData, callback = () => {}) {
  const { response } = errorData;
  const error = getServerError(response);

  if (response && response.data) {
    callback(response);
  }

  return {
    type: CREATE_PHONE_NUMBER_ERROR,
    message: error,
  };
}

export const createPhoneNumberRequest =
  (attributes, callback) => async (dispatch) => {
    try {
      dispatch({ type: CREATE_PHONE_NUMBER_REQUEST });

      const response = await PhoneNumberApi.createPhoneNumber(attributes);

      dispatch(createPhoneNumberCreate(response.data));

      callback();
    } catch (errors) {
      dispatch(createPhoneNumberError(errors, callback));
    }
  };

export function createBulkPhoneNumberCreate(data) {
  return {
    type: CREATE_BULK_PHONE_NUMBER_RECEIVED,
    phoneNumber: data,
  };
}

export function createBulkPhoneNumberError(errorData) {
  const { response } = errorData;
  const error = getServerError(response);

  displayFetchErrorToaster(error);

  return {
    type: CREATE_BULK_PHONE_NUMBER_ERROR,
    message: error,
  };
}

export const createBulkPhoneNumberRequest =
  (attributes) => async (dispatch) => {
    try {
      dispatch({ type: CREATE_BULK_PHONE_NUMBER_REQUEST });

      const response = await PhoneNumberApi.createBulkPhoneNumber(attributes);

      dispatch(createBulkPhoneNumberCreate(response.data));
    } catch (error) {
      dispatch(createBulkPhoneNumberError(error));
    }
  };

export function FetchPhoneNumberDetailReceived(data) {
  return {
    type: FETCH_PHONE_NUMBER_DETAIL_RECEIVED,
    phoneNumber: data,
  };
}

export function fetchPhoneNumberDetailError(errorData) {
  const { response } = errorData;
  const error = getServerError(response);

  displayFetchErrorToaster(error);

  return {
    type: FETCH_PHONE_NUMBER_DETAIL_ERROR,
    message: error,
  };
}

export const phoneNumberDetailRequest = (phoneNumberID) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_PHONE_NUMBER_DETAIL_REQUEST });

    const response = await PhoneNumberApi.fetchPhoneNumberDetail(phoneNumberID);

    dispatch(FetchPhoneNumberDetailReceived(response.data));
  } catch (errors) {
    dispatch(fetchPhoneNumberDetailError(errors));
  }
};

export function updatePhoneNumberReceived(data) {
  return {
    type: UPDATE_PHONE_NUMBER_RECEIVED,
    phoneNumber: data,
  };
}

export function updatePhoneNumberError(errorData, callback = () => {}) {
  const { response } = errorData;
  const error = getServerError(response);

  if (response.data) {
    displayUnMappedFormErrors(response);
    callback(response.data);
  }

  return {
    type: UPDATE_PHONE_NUMBER_ERROR,
    message: error,
  };
}

export const updatePhoneNumberRequest =
  (phoneNumberID, attributes, callback) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PHONE_NUMBER_REQUEST });

      const response = await PhoneNumberApi.updatePhoneNumber(
        phoneNumberID,
        attributes
      );

      dispatch(updatePhoneNumberReceived(response.data));
      callback();
    } catch (errors) {
      dispatch(updatePhoneNumberError(errors, callback));
    }
  };

export function FetchPhoneNumberDeleteReceived(data, cb) {
  toastr.success(
    t("common.success"),
    t("toasterMessage.resource.delete", {
      resource: t("attributes.phoneNumber"),
    })
  );

  if (typeof cb === "function") {
    cb();
  }

  return {
    type: DELETE_PHONE_NUMBER_RECEIVED,
    phoneNumber: data,
  };
}

export function fetchPhoneNumberDeleteError(errorData) {
  const { response } = errorData;
  const error = getServerError(response);

  displayFetchErrorToaster(error);

  return {
    type: DELETE_PHONE_NUMBER_ERROR,
    message: error,
  };
}

export const phoneNumberDeleteRequest =
  (phoneNumberID, cb) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_PHONE_NUMBER_REQUEST });

      const response = await PhoneNumberApi.deletePhoneNumber(phoneNumberID);

      dispatch(FetchPhoneNumberDeleteReceived(response.data, cb));
    } catch (error) {
      dispatch(fetchPhoneNumberDeleteError(error));
    }
  };

export const clearPhoneNumberError = () => ({
  type: CLEAR_PHONE_NUMBERS_ERRORS,
});
