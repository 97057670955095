import React from "react";
import { Translate, t } from "react-i18nify";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

import ServiceModal from "components/CellPhone/Form/NetworkOperatorSubscriptionServices/Modal/ServiceModal";

const ServiceTableEmptyUI = ({
  toggleModalVisibility,
  isModalVisible,
  addNewSubscriptionService,
  resetTemporarySubscriptionServices,
  filteredSubscriptionServices,
}) => (
  <div className="p-4 h-100 shadow-sm bg-white d-flex flex-column align-items-center justify-content-center empty-table">
    <div className="info-icon-wrapper border-danger mb-2">
      <FontAwesomeIcon className="info-icon text-danger" icon={faInfo} />
    </div>
    <div className="text-center fw-bold">
      <p className="d-inline-block mb-2">
        <Translate
          value="title.resource.info.noResources"
          resource={t(`common.services`)}
        />
      </p>
    </div>

    <ServiceModal
      title={<Translate value="common.addService" />}
      buttonTitle={<Translate value="common.addService" />}
      modalVisible={isModalVisible}
      onSubmit={addNewSubscriptionService}
      onCancel={resetTemporarySubscriptionServices}
      fieldName="temporary_network_operator_subscription_service"
      filteredSubscriptionServices={filteredSubscriptionServices}
    />

    <button
      className="btn btn-primary mt-3"
      type="button"
      onClick={toggleModalVisibility}
    >
      <Translate
        value="title.resource.button.addResource"
        resource={t(`common.service`)}
      />
    </button>
  </div>
);

ServiceTableEmptyUI.defaultProps = {};

ServiceTableEmptyUI.propTypes = {
  toggleModalVisibility: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  resetTemporarySubscriptionServices: PropTypes.func.isRequired,
  filteredSubscriptionServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      is_default: PropTypes.bool,
    })
  ).isRequired,
  addNewSubscriptionService: PropTypes.func.isRequired,
};

export default ServiceTableEmptyUI;
