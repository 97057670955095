import React from "react";
import { Form } from "react-final-form";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";

import { required } from "utils/validation";

import UserFormContent from "components/User/Form/UserFormContent";

const UserForm = ({ onSubmit, isLoading, initialValues, userID }) => (
  <Form
    initialValues={initialValues}
    mutators={{
      setValue: ([field, value], state, { changeValue }) => {
        changeValue(state, field, () => value);
      },
    }}
    onSubmit={onSubmit}
    validate={required}
    render={({ handleSubmit, pristine, submitting }) => (
      <form className="flexo-form px-4 py-4 pt-5 w-100" onSubmit={handleSubmit}>
        <UserFormContent userID={userID} initialValues={initialValues} />
        <div className="flexo-form-footer">
          <ReduxFormCommitButton
            title={<Translate value="common.save" />}
            submitting={submitting}
            pristine={pristine}
            isLoading={isLoading}
          />
        </div>
      </form>
    )}
  />
);

UserForm.defaultProps = {
  initialValues: {},
  userID: null,
};

UserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}),
  userID: PropTypes.number,
};

export default UserForm;
