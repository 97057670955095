import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import { Translate } from "react-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { SUBSCRIPTION_LINE_TYPE } from "constants/subscriptionLine";

import HasPermission from "hoc/RbacCTA";

import { renderInputField } from "components/ReduxForm/RenderField";

const ProductName = ({ isSetup, name }) => {
  if (!isSetup) return name;

  return (
    <>
      {name}
      <span className="pill status setup px-3 ms-2">
        <Translate value="common.setup" />
      </span>
    </>
  );
};

const AddOnSubscriptionDetail = ({ show, fields }) => {
  let editable = false;
  const [totalPrice, setTotalPrice] = useState(null);

  const priceViewClassName = editable
    ? "d-block align-middle"
    : "d-none mb-0 align-middle";

  const { value: subscriptionLines, name: dataCyFieldName } = fields;

  const fieldsData = subscriptionLines ?? [];

  useEffect(() => {
    if (fieldsData.length < 1) return;

    let total = 0;

    fieldsData.forEach(({ price, line_type: lineType }) => {
      if (lineType === SUBSCRIPTION_LINE_TYPE.SUBSCRIPTION) return;

      total += Number(price) || 0;
    });

    setTotalPrice(total);
  }, [fields]);

  const handleRemoveAddOnService = (index) => {
    fields.remove(index);
  };

  if (!show) return null;

  return (
    <>
      {subscriptionLines.map(
        ({ line_type: lineType, product_name: productName, price }, index) => {
          editable = true;

          if (lineType === SUBSCRIPTION_LINE_TYPE.SUBSCRIPTION) return null;

          const isSetup = lineType === SUBSCRIPTION_LINE_TYPE.SETUP;

          return (
            <tr className=" add-on-subscription-row py-3">
              <td className="align-middle">
                <p className="mb-0 align-self-center">
                  <ProductName name={productName} isSetup={isSetup} />
                </p>
              </td>
              <HasPermission perform="addOnSubscription:edit">
                <td className="align-middle">
                  <div className="w-50 mx-auto">
                    <Field
                      name={`${dataCyFieldName}[${index}].price`}
                      component={renderInputField}
                      placeholderTranslation="attributes.price"
                      inputClassName="form-control text-center"
                    />
                  </div>
                </td>
              </HasPermission>
              <td className={priceViewClassName}>
                <p className="text-center">{price}</p>
              </td>

              <td className="text-end d-flex justify-content-end p-4">
                <button
                  className="border border-danger rounded p-2 text-danger d-flex me-3 bg-transparent"
                  type="button"
                  onClick={() => handleRemoveAddOnService(index)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </td>
            </tr>
          );
        }
      )}
      <tr className="add-on-subscription-row total-calculation">
        <td className="mb-0 align-self-center fw-bold py-3">
          <Translate value="common.total" />
        </td>
        <td className=" mb-0 text-center align-self-center fw-bold py-3">
          {totalPrice || 0}
        </td>
      </tr>
    </>
  );
};

ProductName.defaultProps = {
  isSetup: false,
};

ProductName.propTypes = {
  isSetup: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

AddOnSubscriptionDetail.defaultProps = {
  show: false,
  detail: {},
};

AddOnSubscriptionDetail.propTypes = {
  show: PropTypes.bool,
  detail: PropTypes.shape({
    product_name: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  fields: PropTypes.shape({
    map: PropTypes.func,
    name: PropTypes.string,
    length: PropTypes.number,
    remove: PropTypes.func,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        find: PropTypes.func,
        map: PropTypes.func,
      })
    ),
  }).isRequired,
};

export default AddOnSubscriptionDetail;
