import React from "react";
import PropTypes from "prop-types";
import Datetime from "react-datetime";

import InputLabel from "components/ReduxForm/InputLabel";
import DotsLoadingIndicator from "components/Spinner/DotsLoadingIndicator";

import { USER_ALERTS } from "constants/constant";

const ValidationMessage = ({ messageType, message }) => {
  if (!message) return null;

  const testId = `${messageType}-text`;
  const className =
    messageType === USER_ALERTS.ERROR ? "text-danger" : "text-warning";

  return (
    <span data-testid={testId} className={`invalid-text ${className}`}>
      {message}
    </span>
  );
};

const renderDateTimeField = ({
  input: { name, value, onBlur, onChange, ...input },
  meta: { error, warning },
  label,
  labelTranslation,
  // disabledDays,
  disabled,
  replacementTranslations,
  isLoadingDate,
  // initialMonth,
}) => (
  // const formattedInitialMonth = initialMonth
  //   ? {
  //       initialMonth: new Date(
  //         initialMonth.getFullYear(),
  //         initialMonth.getMonth()
  //       ),
  //     }
  //   : {};

  // TODO: Make disabling dates work later
  // const validDate = (currentDate, selectedDate) => {
  //   const { before, after } = disabledDays;

  //   if (before) currentDate.isAfter(before);

  //   if (after) currentDate.isAfter(after);
  // };

  // TODO: Implement date clearing functionality by making this component controlled.
  // const clearDateTimeHandler = () => {
  //   onChange(null);
  // };

  <div>
    <InputLabel
      dataTestId="date-time-field-label"
      name={name}
      label={label}
      labelTranslation={labelTranslation}
      replacementTranslations={replacementTranslations.label}
    />
    <div className="position-relative">
      <Datetime
        id={name}
        value={value && new Date(value)}
        onChange={onChange}
        key={name}
        inputProps={{
          input,
          "data-testid": "date-time-field",
          disabled,
        }}
        dateFormat="YYYY-MM-DD"
        // isValidDate={validDate}
        timeFormat="HH:mm"
      />

      {/* <DayPickerInput
          id={name}
          value={value && new Date(value)}
          onDayChange={(dateValue) => onChange(dateValue)}
          onBlur={onBlur}
          onChange={onChange}
          dayPickerProps={{
            todayButton: <Translate value="common.today" />,
            modifiers: {
              disabled: disabledDays,
            },
            ...formattedInitialMonth,
          }}
          inputProps={{
            ...input,
            disabled: disabled || isLoadingDate,
            readOnly: true,
          }}
          key={name}
        /> */}

      {isLoadingDate && <DotsLoadingIndicator />}
      {/* <ClearDateIcon
          onClick={clearDateTimeHandler}
          value={value}
          disabled={disabled}
        /> */}
    </div>

    <ValidationMessage message={error} messageType={USER_ALERTS.ERROR} />

    <ValidationMessage message={warning} messageType={USER_ALERTS.WARNING} />
  </div>
);

ValidationMessage.propTypes = {
  messageType: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

renderDateTimeField.defaultProps = {
  label: undefined,
  disabledDays: undefined,
  disabled: false,
  labelTranslation: undefined,
  replacementTranslations: {
    label: {},
    placeholder: {},
  },
  isLoadingDate: false,
};

renderDateTimeField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    // onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  labelTranslation: PropTypes.string,
  disabledDays: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape()),
  ]),
  disabled: PropTypes.bool,
  replacementTranslations: PropTypes.shape({
    label: PropTypes.shape({}),
    placeholder: PropTypes.shape({}),
  }),
  isLoadingDate: PropTypes.bool,
  initialMonth: PropTypes.instanceOf(Date),
};

export default renderDateTimeField;
