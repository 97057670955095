import React from "react";
import { Spinner } from "reactstrap";

const WholePageSpinner = () => {
  return (
    <div className="d-flex h-100 w-100 justify-content-center align-items-center">
      <Spinner style={{ width: "3rem", height: "3rem" }} />
    </div>
  );
};

export default WholePageSpinner;
