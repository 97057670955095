import { createSearchAction } from "redux-search";

import { FaxStatusApi } from "internal";
import { getAndDisplayErrors } from "utils/utils";

export const FAX_STATUS_FETCH_REQUEST = "FAX_STATUS_FETCH_REQUEST";
export const FAX_STATUS_FETCH_RECEIVED = "FAX_STATUS_FETCH_RECEIVED";
export const FAX_STATUS_FETCH_FAILED = "FAX_STATUS_FETCH_FAILED";
export const SINGLE_FAX_STATUS_FETCH_REQUEST =
  "SINGLE_FAX_STATUS_FETCH_REQUEST";
export const SINGLE_FAX_STATUS_FETCH_RECEIVED =
  "SINGLE_FAX_STATUS_FETCH_RECEIVED";
export const SINGLE_FAX_STATUS_FETCH_FAILED = "SINGLE_FAX_STATUS_FETCH_FAILED";
export const FAX_STATUS_FETCH_CLEAR_ERRORS = "FAX_STATUS_FETCH_CLEAR_ERRORS";
export const FAX_STATUS_SEARCH_REQUEST = "FAX_STATUS_SEARCH_REQUEST";

// search fax status list table
export const searchFaxStatuses = createSearchAction("faxStatuses");

export function faxStatusSearchRequest(value) {
  const isSearching = Boolean(value);

  return {
    type: FAX_STATUS_SEARCH_REQUEST,
    isSearching,
  };
}

// Fetch all fax status
export function faxStatusFetchReceived(response) {
  return {
    type: FAX_STATUS_FETCH_RECEIVED,
    faxStatuses: response.data,
  };
}

export function faxStatusFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: FAX_STATUS_FETCH_FAILED,
    message,
  };
}

export const faxStatusFetchRequest = (phoneNumber) => (dispatch) => {
  dispatch({ type: FAX_STATUS_FETCH_REQUEST });

  FaxStatusApi.fetchAll(phoneNumber)
    .then((data) => dispatch(faxStatusFetchReceived(data)))
    .catch((e) => {
      dispatch(faxStatusFetchFailed(e));
    });
};

// Fetch single fax status
export function singleFaxStatusFetchReceived(response) {
  return {
    type: SINGLE_FAX_STATUS_FETCH_RECEIVED,
    faxStatus: response.data,
  };
}

export function singleFaxStatusFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: SINGLE_FAX_STATUS_FETCH_FAILED,
    message,
  };
}

export const singleFaxStatusFetchRequest = (id, didNumber) => (dispatch) => {
  dispatch({ type: SINGLE_FAX_STATUS_FETCH_REQUEST });

  FaxStatusApi.fetchOne(id, didNumber)
    .then((data) => dispatch(singleFaxStatusFetchReceived(data)))
    .catch((e) => {
      dispatch(singleFaxStatusFetchFailed(e));
    });
};

export function faxStatusFetchClearErrors() {
  return {
    type: FAX_STATUS_FETCH_CLEAR_ERRORS,
  };
}
