import React from "react";
import Skeleton from "react-loading-skeleton";

const CardHeaderSkeleton = () => (
  <div className="d-flex p-4 d-flex justify-content-between align-items-center">
    <Skeleton height={30} width={150} />
    <div className="d-flex">
      <Skeleton height={40} width={200} className="me-3" />
      <Skeleton height={40} width={100} />
    </div>
  </div>
);

export default CardHeaderSkeleton;
