import React from "react";
import PropTypes from "prop-types";

import SuspendCellPhone from "./SuspendCellPhone";
import ReactivateCellPhone from "./ReactivateCellPhone";
import OrderSimCard from "./OrderSimCard";
import AssignNewSimCard from "./AssignNewSimCard";

const CellPhoneActions = ({
  cellNumber,
  cellNumberID,
  isSuspended,
  newSimCardRequestedDate,
  isActive,
}) => {
  if (!isActive) return null;

  const OrderOrAssignNewSimCard = () => {
    if (newSimCardRequestedDate) {
      return <AssignNewSimCard cellNumberID={cellNumberID} />;
    }

    return <OrderSimCard cellNumberID={cellNumberID} />;
  };

  if (isSuspended) {
    return (
      <div className="cell-phone-suspended d-flex justify-content-center align-items-center">
        <ReactivateCellPhone
          cellNumberID={cellNumberID}
          isSuspended={isSuspended}
        />
        <OrderOrAssignNewSimCard />
      </div>
    );
  }

  return (
    <div className="cell-phone-suspended d-flex justify-content-center align-items-center">
      <SuspendCellPhone cellNumber={cellNumber} cellNumberID={cellNumberID} />
      <OrderOrAssignNewSimCard />
    </div>
  );
};

CellPhoneActions.defaultProps = {};

CellPhoneActions.propTypes = {
  cellNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  cellNumberID: PropTypes.number.isRequired,
  isSuspended: PropTypes.bool.isRequired,
  newSimCardRequestedDate: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default CellPhoneActions;
