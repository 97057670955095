export const PBX_FILTERS = [
  {
    value: "deactivated",
    nameTranslation: "common.deactivated",
  },
  {
    value: "active",
    nameTranslation: "common.active",
  },
];

export const PBX_CELL_PHONES_FILTERS = [
  {
    value: "deactivated",
    nameTranslation: "common.deactivated",
  },
  {
    value: "active",
    nameTranslation: "common.active",
  },
  {
    value: "active_under_pbx",
    nameTranslation: "common.allActive",
  },
];

export const PBX_DID_NUMBERS_FILTERS = [
  {
    value: "deactivated",
    nameTranslation: "common.deactivated",
  },
  {
    value: "active",
    nameTranslation: "common.active",
  },
  {
    value: "active_under_pbx",
    nameTranslation: "common.allActive",
  },
];

export const ROLES = [
  {
    value: "company_users",
    nameTranslation: "user.companyUsers",
  },
  {
    value: "resellers",
    nameTranslation: "user.resellers",
  },
  {
    value: "customer_users",
    nameTranslation: "user.customerUsers",
  },
  {
    value: "pbx_users",
    nameTranslation: "user.pbxUsers",
  },
];

export const STATUS = [
  {
    value: "active",
    nameTranslation: "common.active",
  },
  {
    value: "archived",
    nameTranslation: "common.archived",
  },
];

export const PORTING_REQUEST_NUMBER_TYPE = [
  {
    value: "cell_phone",
    nameTranslation: "common.mobile",
  },
  {
    value: "did_number",
    nameTranslation: "common.fixed",
  },
];

export const FILTER_PHONE_NUMBER_TYPE = [
  {
    value: "cell_phone",
    nameTranslation: "common.cellPhone",
  },
  {
    value: "did_number",
    nameTranslation: "common.fixed",
  },
];

export const FILTER_COST_TYPE = [
  {
    value: "free",
    nameTranslation: "common.free",
  },
  {
    value: "gold",
    nameTranslation: "common.gold",
  },
];

export const FILTER_STATUS = [
  {
    value: "available",
    nameTranslation: "common.available",
  },
  {
    value: "active",
    nameTranslation: "common.active",
  },
  {
    value: "quarantined",
    nameTranslation: "common.quarantined",
  },
  {
    value: "ported_out",
    nameTranslation: "common.portedOut",
  },
];

export const FILTER_STATUS_SIM_CARD = [
  {
    value: "available",
    nameTranslation: "enum.simCard.available",
  },
  {
    value: "active",
    nameTranslation: "enum.simCard.active",
  },
  {
    value: "assigned",
    nameTranslation: "enum.simCard.assigned",
  },
  {
    value: "blocked",
    nameTranslation: "enum.simCard.blocked",
  },
];

export const QUOTATION_FILTERS = [
  {
    value: "sent",
    nameTranslation: "common.sent",
  },
  {
    value: "draft",
    nameTranslation: "common.draft",
  },
  {
    value: "archived",
    nameTranslation: "common.archived",
  },
];

export const DEFAULT_FILTER = "active";
export const ACTIVE_UNDER_PBX_FILTER = "active_under_pbx";
