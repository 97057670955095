import React from "react";
import Skeleton from "react-loading-skeleton";

import DetailListSkeleton from "components/Skeleton/DetailListSkeleton";
import HeaderSkeleton from "components/Skeleton/HeaderSkeleton";
import TabTableSkeleton from "components/Skeleton/TabTableSkeleton";

const DebtorDetailSkeleton = () => (
  <div className="container-fluid">
    <HeaderSkeleton />
    <div className="bg-white rounded shadow-sm mb-4">
      <div className="row m-0 justify-content-between align-items-center">
        <div className="col">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Skeleton height={100} width={100} circle className="mb-4" />
            <Skeleton height={20} width={150} />
          </div>
        </div>
        <DetailListSkeleton className="h-100 border-left border-gray-100" />
      </div>
    </div>

    <div className="bg-white rounded shadow-sm">
      <div className="d-flex p-3">
        <Skeleton width={75} height={20} className="me-4" />
        <Skeleton width={75} height={20} className="me-4" />
        <Skeleton width={75} height={20} />
      </div>
      <div className="m-0 border-bottom border-gray-100" />
      <div className="d-flex flex-column p-3">
        <TabTableSkeleton />
        <TabTableSkeleton />
        <TabTableSkeleton />
      </div>
    </div>
  </div>
);

export default DebtorDetailSkeleton;
