import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

import { replaceMultiple } from "utils/string";

const URLS = {
  INDEX: `${apiUrlConfig.pbxEndPoint()}/fax_statuses`,
  SHOW: `${apiUrlConfig.pbxEndPoint()}/fax_statuses/:id?did_number=:did_number`,
};

class FaxStatusApi extends AuthenticatedRequest {
  /**
   * Fetch all fax status of a particular did number
   *
   * @param {number} did_number Did number of the fax; this is a number like in phone number and not an id of a record
   *
   */
  static fetchAll(didNumber) {
    const indexUrl = `${URLS.INDEX}?did_number=${didNumber}`;

    return this.get(indexUrl);
  }

  /**
   * Fetch single fax status
   *
   * @param {number} id ID of fax status
   * @param {number} didNumber Did number of the fax; this is a number like in phone number and not an id of a record
   *
   */
  static fetchOne(id, didNumber) {
    const replaceWith = {
      ":id": id,
      ":did_number": didNumber,
    };

    const fetchOneUrl = replaceMultiple(URLS.SHOW, replaceWith);

    return this.get(fetchOneUrl);
  }
}

export default FaxStatusApi;
