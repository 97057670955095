import {
  LOGIN_REQUEST,
  LOGIN_RECEIVED,
  LOGIN_FAILED,
  LOGIN_CLEAR_ERRORS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_RECEIVED,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_CLEAR_ERRORS,
  SET_PASSWORD_REQUEST,
  SET_PASSWORD_RECEIVED,
  SET_PASSWORD_FAILED,
  REFRESH_BEARER_TOKEN_RECEIVED,
  LOGOUT,
  SESSION_EXPIRED,
} from "redux/actions/auth";
import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";
import { UPDATE_STATUS_CODE_AUTH } from "utils/updateReducer";

const AUTH_MESSAGES = {
  RESET_PASSWORD_STATUS: "message.auth.resetLink",
};

const defaultAuthState = {
  accessToken: null,
  refreshToken: null,
  resetPasswordSuccessMessage: "",
  resetPasswordError: "",
  loginError: "",
  loginEmailError: "",
  loginPasswordError: "",
  loginVerifyError: "",
  currentUser: {},
  bearerToken: "",
  isLoggedIn: false,
  isLoading: false,
  sessionExpired: false,
  statusCode: null,
  setPasswordSuccessMessage: "",
  setPasswordError: "",
};

function auth(state = defaultAuthState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        statusCode: null,
        loginError: "",
      };

    case LOGIN_RECEIVED: {
      const { tokens } = action;
      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        token_type: tokenType,
        user,
      } = tokens;

      LocalStorageManager.set(
        CONSTANTS.BEARER_TOKEN,
        `${tokenType} ${accessToken}`
      );

      return {
        ...state,
        accessToken,
        refreshToken,
        currentUser: user,
        bearerToken: `${tokenType} ${accessToken}`,
        isLoggedIn: !!user.is_active,
        isLoading: false,
      };
    }

    case LOGIN_FAILED: {
      const { response } = action.message;

      if (!response) return { ...state, isLoading: false };

      switch (response && response.status) {
        case 400:
        case 401:
          return {
            ...state,
            loginError: "message.auth.incorrectEmailOrPassword",
            isLoading: false,
          };

        default:
          return {
            ...state,
            isLoading: false,
            loginError: "message.auth.unableToSignIn",
          };
      }
    }

    case LOGIN_CLEAR_ERRORS:
      return {
        ...state,
        loginEmailError: "",
        loginPasswordError: "",
        loginError: "",
        isLoading: false,
      };

    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case RESET_PASSWORD_RECEIVED: {
      return {
        ...state,
        resetPasswordSuccessMessage: AUTH_MESSAGES.RESET_PASSWORD_STATUS,
        isLoading: false,
      };
    }

    case RESET_PASSWORD_FAILED: {
      const { response } = action.message;

      switch (response.status) {
        case 400:
          return {
            ...state,
            resetPasswordError: AUTH_MESSAGES.RESET_PASSWORD_STATUS,
            isLoading: false,
          };
        default:
          return {
            ...state,
            isLoading: false,
            resetPasswordError: "toasterMessage.processError",
          };
      }
    }

    case RESET_PASSWORD_CLEAR_ERRORS:
      return {
        ...state,
        resetPasswordSuccessMessage: "",
        resetPasswordError: "",
        setPasswordSuccessMessage: "",
        setPasswordError: "",
        isLoading: false,
      };

    case LOGOUT:
      return {
        ...state,
        accessToken: null,
        currentUser: null,
        refreshToken: null,
        bearerToken: null,
        isLoggedIn: false,
        isLoading: false,
        sessionExpired: false,
        loginError: "",
      };

    case REFRESH_BEARER_TOKEN_RECEIVED: {
      const { tokens } = action;
      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        token_type: tokenType,
        user,
      } = tokens;

      LocalStorageManager.set(
        CONSTANTS.BEARER_TOKEN,
        `${tokenType} ${accessToken}`
      );

      return {
        ...state,
        accessToken,
        refreshToken,
        currentUser: user,
        bearerToken: `${tokenType} ${accessToken}`,
        isLoggedIn: !!user?.is_active,
        isLoading: false,
      };
    }

    case SET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case SET_PASSWORD_RECEIVED: {
      return {
        ...state,
        setPasswordSuccessMessage: "message.auth.setPasswordSuccessMessage",
        isLoading: false,
      };
    }

    case SET_PASSWORD_FAILED: {
      const { response } = action.message;

      switch (response.status) {
        case 400:
          return {
            ...state,
            setPasswordError: "message.auth.setPasswordError400",
            isLoading: false,
          };
        case 404:
          return {
            ...state,
            setPasswordError: "message.auth.setPasswordError404",
            isLoading: false,
          };
        default:
          return {
            ...state,
            setPasswordError: "message.auth.setPasswordError",
            isLoading: false,
          };
      }
    }

    case SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: true,
      };

    case UPDATE_STATUS_CODE_AUTH:
      return {
        ...state,
        statusCode: action.statusCode,
      };

    default:
      return state;
  }
}

export default auth;
