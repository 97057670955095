import React from "react";
import { Form } from "reactstrap";
import { Translate } from "react-i18nify";
import { Form as ReactFinalForm } from "react-final-form";
import PropTypes from "prop-types";

import { camelize } from "utils/string";
import { formatUserSetting } from "utils/user";

import SettingField from "components/UserSetting/Form/SettingField";

const PbxsSettingsForm = ({
  pbxSettings,
  userID,
  updateUserSettings,
  initialValues,
}) => {
  const handlePbxsSettingsFormSubmit = (values, form) => {
    const registeredFields = form.getRegisteredFields();

    const userSettings = pbxSettings.map((userSetting) => {
      const { id, settingID, name, dataType } = userSetting;

      const submittedValue = values[camelize(name)];

      const value = formatUserSetting({ value: submittedValue, dataType });

      return {
        id,
        setting_id: settingID,
        value,
      };
    });

    updateUserSettings(userID, userSettings, registeredFields);
  };

  return (
    <ReactFinalForm
      initialValues={initialValues}
      onSubmit={handlePbxsSettingsFormSubmit}
      render={({ handleSubmit }) => (
        <Form className="flexo-form px-4 py-4 pt-4" onSubmit={handleSubmit}>
          <div className="setting-content" id="invoice">
            {pbxSettings.map((setting, index) => (
              <SettingField setting={setting} index={index} />
            ))}
          </div>
          <div className="user-setting-footer mb-1">
            <button
              type="submit"
              className="btn-lg btn btn-primary "
              value="Submit"
            >
              <Translate value="common.save" />
            </button>
          </div>
        </Form>
      )}
    />
  );
};

PbxsSettingsForm.defaultProps = { initialValues: {} };

PbxsSettingsForm.propTypes = {
  pbxSettings: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      dataType: PropTypes.string,
      description: PropTypes.string,
      displayType: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.string),
      settingID: PropTypes.number,
      value: PropTypes.string,
    })
  ).isRequired,

  userID: PropTypes.number.isRequired,
  updateUserSettings: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
};

export default PbxsSettingsForm;
