import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Translate } from "react-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const EditButton = ({ editUrl, hidden }) => (
  <Link
    data-cy="btn-edit"
    data-testid="btn-edit"
    to={editUrl}
    className="btn btn-primary"
    hidden={hidden}
  >
    <FontAwesomeIcon
      icon={faPencilAlt}
      className="me-2"
      data-testid="fa-pencil-at"
    />
    <Translate value="common.edit" />
  </Link>
);

EditButton.defaultProps = {
  hidden: false,
};

EditButton.propTypes = {
  editUrl: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
};

export default EditButton;
