import React from "react";
import ReactDOM from "react-dom/client";
import App from "views/App/App";
import * as serviceWorker from "serviceWorker";
import "assets/css/main.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
