import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Translate, t } from "react-i18nify";

import { settingCreateRequest } from "redux/actions/setting";

import SettingForm from "components/Setting/Form/SettingForm";

import { APP_SETTING } from "constants/routes";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const AppSettingCreate = ({
  createSetting,
  createSuccess,
  isLoading,
  setting,
}) => {
  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    await createSetting(values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (createSuccess) {
    const { id } = setting;
    const appSettingTranslation = t("title.resource.appSetting");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.create", {
        resource: appSettingTranslation,
      })
    );

    const editUrl = APP_SETTING.addId(APP_SETTING.EDIT, id);

    return <Navigate to={editUrl} />;
  }

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.create"
            resource={t("menu.setting")}
          />
        </h3>
      </div>
      <SettingForm
        onSubmit={handleSubmit}
        formName="settingCreateForm"
        isLoading={isLoading}
      />
    </div>
  );
};

AppSettingCreate.defaultProps = {
  setting: undefined,
  commitError: {},
};

AppSettingCreate.propTypes = {
  createSetting: PropTypes.func.isRequired,
  commitError: PropTypes.shape({}),
  setting: PropTypes.shape({
    id: PropTypes.number,
  }),
  isLoading: PropTypes.bool.isRequired,
  createSuccess: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { setting, createSuccess, isLoading, commitError } = state.setting;

  return {
    setting,
    isLoading,
    commitError,
    createSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createSetting: async (attributes, callback) => {
    await dispatch(settingCreateRequest(attributes, callback));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSettingCreate);
