import React from "react";
import { Translate } from "react-i18nify";
import {
  faFileLines,
  faSimCard,
  faUserCheck,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import Pill from "components/Pill/Pill";

import { SIM_CARD } from "constants/routes";
import HideShowContent from "components/HideShowContent/HideShowContent";
import { BRAND_NAME, SIM_CARD_STATUS } from "constants/constant";

const SimCardHolderType = ({ serviceProvider }) => {
  if (serviceProvider?.is_own_brand) {
    return (
      <span className="owner-type employee py-1 px-3 ms-3">
        <Translate value="attributes.employee" />
      </span>
    );
  }

  return (
    <span className="owner-type debtor py-1 px-3 ms-3">
      <Translate value="attributes.debtor" />
    </span>
  );
};

const SimCardBasicDetail = ({ simCard }) => {
  const {
    pin_1: pinOne,
    pin_2: pinTwo,
    puk_1: pukOne,
    puk_2: pukTwo,
    status,
    note,
    id,
    imsi_number: imsiNumber,
    network_operator_name: networkOperatorName,
    service_provider: serviceProvider,
  } = simCard;

  const ownerName = serviceProvider.is_own_brand ? (
    BRAND_NAME
  ) : (
    <Translate value="common.reseller" />
  );

  const pillClassName =
    status === SIM_CARD_STATUS.BLOCKED
      ? "bg-red py-1 px-3"
      : "bg-green py-1 px-3";

  const basicDetailContent = [
    {
      icon: faSimCard,
      title: <Translate value="attributes.imsi" />,
      content: imsiNumber,
    },
    {
      icon: faWifi,
      title: <Translate value="attributes.networkOperator" />,
      content: networkOperatorName,
    },
    {
      icon: faSimCard,
      title: <Translate value="attributes.owner" />,
      content: ownerName,
    },
    {
      icon: faSimCard,
      title: <Translate value="common.simCardHolder" />,
      content: (
        <>
          {serviceProvider.name}
          <SimCardHolderType serviceProvider={serviceProvider} />
        </>
      ),
    },
    {
      icon: faSimCard,
      title: <Translate value="attributes.pin1" />,
      content: <HideShowContent content={pinOne.toString()} />,
    },
    {
      icon: faSimCard,
      title: <Translate value="attributes.puk1" />,
      content: <HideShowContent content={pukOne.toString()} />,
    },
    {
      icon: faSimCard,
      title: <Translate value="attributes.pin2" />,
      content: <HideShowContent content={pinTwo.toString()} />,
    },
    {
      icon: faSimCard,
      title: <Translate value="attributes.puk2" />,
      content: <HideShowContent content={pukTwo.toString()} />,
    },
    {
      icon: faUserCheck,
      title: <Translate value="attributes.status" />,
      content: (
        <Pill
          content={<Translate value={`pill.status.${status}`} />}
          show
          className={`${pillClassName} py-1 px-3`}
        />
      ),
    },
    note && {
      icon: faFileLines,
      title: <Translate value="attributes.note" />,
      content: note,
    },
  ];

  return (
    <div className="sim-card-basic-detail pill">
      <CardWithHeader
        title={<Translate value="common.basicDetails" />}
        className="shadow-sm"
        cardContent={basicDetailContent}
        editPath={SIM_CARD.addId(SIM_CARD.EDIT, id)}
        readOnly={false}
      />
    </div>
  );
};

SimCardHolderType.defaultProps = {
  serviceProvider: {},
};

SimCardHolderType.propTypes = {
  serviceProvider: PropTypes.shape({
    name: PropTypes.string,
    is_own_brand: PropTypes.bool,
  }),
};

SimCardBasicDetail.propTypes = {
  simCard: PropTypes.shape({
    id: PropTypes.number,
    pin_1: PropTypes.number,
    pin_2: PropTypes.number,
    puk_1: PropTypes.number,
    puk_2: PropTypes.number,
    status: PropTypes.string,
    note: PropTypes.string,
    imsi_number: PropTypes.string,
    network_operator_name: PropTypes.string,
    service_provider: PropTypes.shape({
      name: PropTypes.string,
      is_own_brand: PropTypes.bool,
    }),
  }).isRequired,
};

export default SimCardBasicDetail;
