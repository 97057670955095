import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from "reactstrap";
import PropTypes from "prop-types";
import { FieldArray } from "react-final-form-arrays";
import { Field, useForm } from "react-final-form";
import Select from "react-select";
import { Translate } from "react-i18nify";

import {
  faTrash,
  faLongArrowAltRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ContextPropertyApi from "apis/ContextProperty";

import { formatDropdownOptions } from "utils/utils";
import { required, requiredForNumber } from "utils/validation";

import {
  renderInputField,
  renderDateField,
  renderPriceInputField,
} from "components/ReduxForm/RenderField";
import SubscriptionLineProperties from "components/Agreement/Form/SubscriptionLine/SubscriptionLineProperties";

const RenderSubscriptionTabs = ({ subscriptionSections, activeTab, toggle }) =>
  subscriptionSections.map((subscription) => {
    const { title, tabID } = subscription;

    return (
      <NavItem key={tabID} className={{ active: activeTab === tabID }}>
        <NavLink
          className="py-3 px-5"
          onClick={() => {
            toggle(tabID);
          }}
          data-tab-name={tabID}
          data-testid={tabID}
        >
          {title}
        </NavLink>
      </NavItem>
    );
  });

const SubscriptionLineGroup = ({
  fields,
  subscriptionProducts,
  subscriptionStartDate,
  subscriptionEndDate,
  isPropertyEditable,
}) => {
  const form = useForm();

  const [activeTab, setActiveTab] = useState("subscription");
  const [fetchingProperties, setFetchingProperties] = useState(false);
  const [productFetchError, setProductFetchError] = useState("");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleProductSelect = (e, products, subscriptionLineType) => {
    const { value: productID } = e;
    const selectedProduct = products.find(
      (product) => product.id === productID
    );

    const {
      list_price: price,
      name,
      context_properties_ids: contextPropertiesIDs,
    } = selectedProduct;

    setFetchingProperties(true);

    ContextPropertyApi.fetchAll(productID, contextPropertiesIDs)
      .then(({ data: contextProperties }) => {
        const subscriptionLineProperties = contextProperties.map(
          (contextProperty) => {
            const { property, value } = contextProperty;

            const subscriptionLineProperty = {
              property,
              value,
            };

            return subscriptionLineProperty;
          }
        );

        const line = {
          odoo_product_id: productID,
          product_name: name,
          price,
          line_type: subscriptionLineType,
          subscription_line_properties: subscriptionLineProperties,
          start_date: subscriptionStartDate,
        };

        setFetchingProperties(false);

        fields.push(line);
      })
      .catch((err) => {
        setFetchingProperties(false);
        setProductFetchError(err.message);
      });
  };

  const subscriptionSections = [
    {
      title: (
        <Translate value="title.tab.agreement.subscriptionModal.subscriptionProduct" />
      ),
      productContextType: 1,
      tabID: "subscription",
    },
    {
      title: (
        <Translate value="title.tab.agreement.subscriptionModal.setupProducts" />
      ),
      productContextType: 3,
      tabID: "setup",
    },
    {
      title: (
        <Translate value="title.tab.agreement.subscriptionModal.addonProducts" />
      ),
      productContextType: 2,
      tabID: "add_on",
    },
  ];

  const subscriptionTabContent = subscriptionSections.map((subscription) => {
    const { tabID, productContextType } = subscription;
    const lineProducts = subscriptionProducts.filter(
      (product) => parseInt(product.context_type, 10) === productContextType
    );

    const subscriptionLines = fields.map((subscriptionLine, index) => {
      const currentLine = fields.value[index];
      const {
        id,
        line_type: subscriptionLineType,
        property_editable: isLinePropertyEditable,
      } = currentLine;

      if (subscriptionLineType !== tabID) {
        return null;
      }

      if (subscriptionEndDate && id)
        form.change(`${subscriptionLine}.end_date`, subscriptionEndDate);

      return (
        <tr key={index}>
          <td>
            <Field
              name={`${subscriptionLine}.product_name`}
              type="text"
              placeholderTranslation="attributes.productName"
              component={renderInputField}
              validate={required}
            />
          </td>
          <td width="20%">
            <FieldArray
              name={`${subscriptionLine}.subscription_line_properties`}
              component={SubscriptionLineProperties}
              isPropertyEditable={isPropertyEditable && isLinePropertyEditable}
              lineType={subscriptionLineType}
            />
          </td>
          <td>
            <Field
              name={`${subscriptionLine}.price`}
              type="text"
              placeholderTranslation="attributes.productPrice"
              component={renderPriceInputField}
              validate={requiredForNumber}
            />
          </td>
          <td>
            <Field
              name={`${subscriptionLine}.start_date`}
              component={renderDateField}
              validate={required}
              disabledDays={{
                before: new Date(subscriptionStartDate),
              }}
            />
          </td>
          <td>
            {id && (
              <Field
                name={`${subscriptionLine}.end_date`}
                component={renderDateField}
                disabledDays={{
                  before: new Date(subscriptionStartDate),
                }}
                disabled={!(isPropertyEditable && isLinePropertyEditable)}
              />
            )}
          </td>
          <td>
            <button
              type="button"
              className="btn btn-danger table-action-btn"
              onClick={() => fields.remove(index)}
              hidden={id}
              data-cy={`${subscriptionLine}.remove-btn`}
              data-testid={`${subscriptionLine}.remove-btn`}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </td>
        </tr>
      );
    });

    const subscriptionLineArray = fields.value || [];
    const subscriptionProduct =
      subscriptionLineArray.find(
        (subscriptionLine) => subscriptionLine.line_type === "subscription"
      ) || [];

    // remove null OR subscriptions that doesn't belong to selected subscription type
    const filteredSubscriptionLines = subscriptionLines.filter(Boolean);

    const productDropdown =
      subscriptionProduct.length === 0 && tabID !== "subscription" ? (
        <div className="empty-screen text-center py-5">
          <div className="icon-holder mb-4">
            <FontAwesomeIcon icon={faTimes} className="m-0" />
          </div>

          <h5 className="text-secondary">
            <Translate value="message.subscription.selectSubscription" />
          </h5>
        </div>
      ) : (
        <div
          hidden={subscriptionProduct.length !== 0 && tabID === "subscription"}
          className="py-5"
        >
          <div
            className="empty-screen text-center"
            hidden={filteredSubscriptionLines.length > 0}
          >
            <div className="icon-holder mb-4 bg-success">
              <FontAwesomeIcon icon={faLongArrowAltRight} className="m-0" />
            </div>

            <h5 className="text-secondary">
              <Translate value="message.subscription.selectProduct" />
            </h5>
          </div>
          <Select
            id="select-product"
            className="col-4 mx-auto"
            options={formatDropdownOptions(lineProducts, "display_name")}
            onChange={(e) =>
              handleProductSelect(e, subscriptionProducts, tabID)
            }
            value={[{ label: <Translate value="common.selectProduct" /> }]}
          />
        </div>
      );

    const productSelectField = fetchingProperties ? (
      <div className="empty-screen text-center py-5">
        <Spinner as="span" size="md" />
      </div>
    ) : (
      productDropdown
    );

    const productFetchErrorField =
      productFetchError.length === 0 ? null : (
        <div className="text-danger">{productFetchError}</div>
      );

    return (
      <TabPane tabId={tabID} key={tabID} className="row-eq-height">
        <div className="table-responsive subscription-list-table">
          <table className="w-100">
            <thead hidden={filteredSubscriptionLines.length === 0}>
              <tr>
                <th>
                  <Translate value="attributes.product" />
                </th>
                <th>
                  <Translate value="attributes.properties" />
                </th>
                <th>
                  <Translate value="attributes.price" />
                </th>
                <th>
                  <Translate value="attributes.startDate" />
                </th>
                <th>
                  <Translate value="attributes.terminationDate" />
                </th>
                <th>
                  <Translate value="common.action" />
                </th>
              </tr>
            </thead>
            <tbody>{filteredSubscriptionLines}</tbody>
          </table>
        </div>
        {productFetchErrorField}
        {productSelectField}
      </TabPane>
    );
  });

  return (
    <div className="br-all shadow-sm mt-3">
      <Nav tabs className="custom-nav-tab m-0">
        <RenderSubscriptionTabs
          activeTab={activeTab}
          subscriptionSections={subscriptionSections}
          toggle={toggle}
        />
      </Nav>
      <TabContent
        data-testid="tab-content"
        activeTab={activeTab}
        className="pb-1 mb-4"
      >
        {subscriptionTabContent}
      </TabContent>
    </div>
  );
};

RenderSubscriptionTabs.defaultProps = {
  subscriptionSections: [],
  activeTab: "",
};

RenderSubscriptionTabs.propTypes = {
  subscriptionSections: PropTypes.shape([]),
  activeTab: PropTypes.string,
  toggle: PropTypes.func.isRequired,
};

SubscriptionLineGroup.defaultProps = {
  subscriptionStartDate: undefined,
  subscriptionEndDate: undefined,
  isPropertyEditable: true,
};

SubscriptionLineGroup.propTypes = {
  fields: PropTypes.shape({
    value: PropTypes.shape([]),
    map: PropTypes.func,
    push: PropTypes.func,
    remove: PropTypes.func,
  }).isRequired,
  subscriptionProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  subscriptionStartDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  subscriptionEndDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  isPropertyEditable: PropTypes.bool,
};

export default SubscriptionLineGroup;
