import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-i18nify";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

import HasPermission from "hoc/RbacCTA";

import DeleteButton from "components/Button/DeleteButton";
import Tooltip from "components/Tooltip/Tooltip";
import DropdownEllipsisList from "components/DropdownEllipsis/DropdownEllipsisList";

const RenderDropdownEllipsisMenu = ({
  isDropdownOpen,
  toggle,
  handleDelete,
  handleQuotationPreview,
  handleQuotationEmail,
  quotationID,
  canConvertToAgreement,
}) => {
  const ellipsisMenuClassName = canConvertToAgreement
    ? "dropdown-menu-list"
    : "";

  if (isDropdownOpen) {
    return (
      <div className="w-100 dropdown-ellipsis-menu ms-3">
        <Dropdown isOpen={isDropdownOpen} toggle={toggle} direction="end">
          <DropdownToggle
            className="p-0 me-0 justify-content-center btn btn-success text-success"
            caret
          >
            <div>
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className="vertical-ellipsis px-4 py-3"
                data-cy="vertical-ellipsis"
              />
            </div>
          </DropdownToggle>
          <div className="dropdown-ellipsis-menu ellipsis-menu-list position-fixed">
            <DropdownMenu className={ellipsisMenuClassName}>
              <DropdownEllipsisList
                handleQuotationPreview={handleQuotationPreview}
                handleQuotationEmail={handleQuotationEmail}
                handleDelete={handleDelete}
                quotationID={quotationID}
                canConvertToAgreement={canConvertToAgreement}
              />
            </DropdownMenu>
          </div>
        </Dropdown>
      </div>
    );
  }

  return (
    <FontAwesomeIcon
      icon={faEllipsisVertical}
      className="vertical-ellipsis ms-3 py-3 border border-success rounded text-success"
      onClick={toggle}
      data-cy="vertical-ellipsis"
    />
  );
};

const AdditionalComponentContent = ({
  additionalPerform,
  data,
  additionalComponent,
}) => {
  if (additionalPerform)
    return (
      <HasPermission perform={additionalPerform} data={data}>
        {additionalComponent}
      </HasPermission>
    );

  return additionalComponent;
};

const DetailUIHeader = ({
  headerTitle,
  perform,
  data,
  actionTitle,
  icon,
  editPath,
  readOnly,
  additionalHeaderContent,
  hasDelete,
  handleDelete,
  deletePerform,
  historyState,
  hasDeleteTooltip,
  deleteTooltipText,
  performAdditionalActions,
  additionalComponent,
  hasEditTooltip,
  editTooltipText,
  additionalHeaderButtons,
  additionalHeaderButtonsPerform,
  hasDropdownEllipsis,
  handleQuotationPreview,
  handleQuotationEmail,
  performListClassName,
  quotationID,
  canConvertToAgreement,
}) => {
  const location = useLocation();

  const displayIcon = icon || faPencilAlt;
  const toPath = editPath || `${location.pathname}/edit`;

  const navigate = useNavigate();

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleEditNavigation = () => {
    navigate(toPath, { state: historyState });
  };

  const renderLinkComponent = () => {
    if (!readOnly) {
      if (hasEditTooltip) {
        return (
          <Tooltip
            title={<Translate value={editTooltipText} />}
            className="disabled-tooltip"
          >
            <button
              type="button"
              onClick={handleEditNavigation}
              className="btn btn-primary"
              disabled
            >
              <FontAwesomeIcon icon={displayIcon} className="me-2" />
              {actionTitle || <Translate value="common.edit" />}
            </button>
          </Tooltip>
        );
      }

      return (
        <button
          type="button"
          onClick={handleEditNavigation}
          className="btn btn-primary"
          data-cy="edit-btn"
        >
          <FontAwesomeIcon icon={displayIcon} className="me-2" />
          {actionTitle || <Translate value="common.edit" />}
        </button>
      );
    }

    return null;
  };

  const renderDropdownEllipsisOrDelete = () => {
    if (hasDropdownEllipsis) {
      return (
        <RenderDropdownEllipsisMenu
          isDropdownOpen={isDropdownOpen}
          toggle={toggle}
          handleDelete={handleDelete}
          handleQuotationPreview={handleQuotationPreview}
          handleQuotationEmail={handleQuotationEmail}
          quotationID={quotationID}
          canConvertToAgreement={canConvertToAgreement}
        />
      );
    }

    if (hasDelete) {
      if (hasDeleteTooltip) {
        return (
          <Tooltip
            title={<Translate value={deleteTooltipText} />}
            className="disabled-tooltip"
          >
            <DeleteButton handleDelete={handleDelete} disabled />
          </Tooltip>
        );
      }

      return <DeleteButton handleDelete={handleDelete} />;
    }

    return null;
  };

  const performList =
    perform ||
    deletePerform ||
    performAdditionalActions ||
    additionalHeaderButtonsPerform;

  return (
    <div className="d-flex justify-content-between align-items-center mb-3 detail-ui-header">
      <div className="d-flex align-items-center">
        <h3 className="m-0" data-cy="user-name">
          {headerTitle}
        </h3>
        {additionalHeaderContent}
      </div>
      {performList ? (
        <div className={`${performListClassName}`}>
          <HasPermission perform={additionalHeaderButtonsPerform}>
            {additionalHeaderButtons}
          </HasPermission>
          <HasPermission perform={perform} data={data}>
            {renderLinkComponent()}
          </HasPermission>
          <AdditionalComponentContent
            additionalPerform={performAdditionalActions}
            data={data}
            additionalComponent={additionalComponent}
          />
          <HasPermission perform={deletePerform} data={data}>
            {renderDropdownEllipsisOrDelete()}
          </HasPermission>
        </div>
      ) : (
        renderLinkComponent()
      )}
    </div>
  );
};

RenderDropdownEllipsisMenu.defaultProps = {};

RenderDropdownEllipsisMenu.propTypes = {
  isDropdownOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleQuotationPreview: PropTypes.func.isRequired,
  handleQuotationEmail: PropTypes.func.isRequired,
  quotationID: PropTypes.number.isRequired,
  canConvertToAgreement: PropTypes.bool.isRequired,
};

AdditionalComponentContent.defaultProps = {
  additionalPerform: null,
  data: undefined,
};

AdditionalComponentContent.propTypes = {
  additionalPerform: PropTypes.string,
  data: PropTypes.shape({}),
  additionalComponent: PropTypes.node.isRequired,
};

DetailUIHeader.defaultProps = {
  perform: undefined,
  additionalHeaderButtonsPerform: undefined,
  data: undefined,
  actionTitle: undefined,
  icon: null,
  editPath: null,
  readOnly: false,
  additionalHeaderContent: null,
  additionalHeaderButtons: null,
  hasDelete: false,
  handleDelete: () => {},
  deletePerform: null,
  historyState: null,
  hasDeleteTooltip: false,
  deleteTooltipText: "",
  performAdditionalActions: null,
  additionalComponent: null,
  hasEditTooltip: false,
  editTooltipText: "",
  hasDropdownEllipsis: false,
  handleQuotationEmail: () => {},
  handleQuotationPreview: () => {},
  performListClassName: "d-md-flex",
  quotationID: undefined,
  canConvertToAgreement: false,
};

DetailUIHeader.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  editPath: PropTypes.string,
  perform: PropTypes.string,
  additionalHeaderButtonsPerform: PropTypes.string,
  actionTitle: PropTypes.string,
  icon: PropTypes.shape({
    // Dynamic: Font awesome icon
  }),
  data: PropTypes.shape({
    // it will be dynamic
  }),
  readOnly: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  additionalHeaderContent: PropTypes.any,
  hasDelete: PropTypes.bool,
  handleDelete: PropTypes.func,
  deletePerform: PropTypes.string,
  historyState: PropTypes.shape({}),
  hasDeleteTooltip: PropTypes.bool,
  deleteTooltipText: PropTypes.string,
  hasEditTooltip: PropTypes.bool,
  editTooltipText: PropTypes.string,
  performAdditionalActions: PropTypes.string,
  additionalComponent: PropTypes.node,
  additionalHeaderButtons: PropTypes.node,
  hasDropdownEllipsis: PropTypes.bool,
  handleQuotationEmail: PropTypes.func,
  handleQuotationPreview: PropTypes.func,
  performListClassName: PropTypes.string,
  quotationID: PropTypes.number,
  canConvertToAgreement: PropTypes.bool,
};

export default DetailUIHeader;
