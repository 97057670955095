import React from "react";
import PropTypes from "prop-types";
import { faBroadcastTower, faGlobe } from "@fortawesome/free-solid-svg-icons";

import SubscriptionProductAttributeItem from "./SubscriptionProductAttributeItem";

const UsageProductPropertySection = ({ usageProductProperty }) => {
  const {
    formatted_cell_phone_product_type: productType,
    formatted_cell_phone_usage_type: usageType,
  } = usageProductProperty;

  return (
    <>
      <SubscriptionProductAttributeItem
        icon={faBroadcastTower}
        translatedTitle="attributes.productType"
        attributeValue={productType}
      />

      <SubscriptionProductAttributeItem
        icon={faGlobe}
        translatedTitle="attributes.usageType"
        attributeValue={usageType}
      />
    </>
  );
};

UsageProductPropertySection.defaultProps = {};

UsageProductPropertySection.propTypes = {
  usageProductProperty: PropTypes.shape({
    formatted_cell_phone_product_type: PropTypes.string,
    formatted_cell_phone_usage_type: PropTypes.string,
  }).isRequired,
};

export default UsageProductPropertySection;
