import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Navigate, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Translate, t } from "react-i18nify";

import { pbxUpdateRequest, singlePbxFetchRequest } from "redux/actions/pbx";
import { debtorFetchRequest } from "redux/actions/debtor";
import { routingProfileFetchRequest } from "redux/actions/pbxEngine/routingProfile";
import { callRateFetchRequest } from "redux/actions/pbxEngine/callRate";

import { PBX } from "constants/routes";

import PbxForm from "components/Pbx/Form/PbxForm";
import WholePageSpinner from "components/Spinner/WholePageSpinner";
import PageNotFound from "components/Error/PageNotFound/PageNotFound";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const PbxEdit = ({
  updatePbx,
  fetchDebtors,
  debtors,
  isDebtorFetching,
  routingProfiles,
  isRoutingProfileFetching,
  fetchRoutingProfiles,
  fetchCallRates,
  callRates,
  isCallRateFetching,
  commitSuccess,
  pbx,
  isLoading,
  fetchSinglePbx,
}) => {
  const { pbxID } = useParams();

  // React hook for fetching data from api
  useEffect(() => {
    fetchSinglePbx(pbxID);
    fetchDebtors();
    fetchRoutingProfiles();
    fetchCallRates();
  }, []);

  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};
    const { id } = pbx;

    await updatePbx(id, values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess) {
    const { id } = pbx;
    const pbxTranslation = t("enum.setting.categoryOptions.pbx");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.update", { resource: pbxTranslation })
    );

    return <Navigate to={PBX.addId(PBX.DETAIL, id)} />;
  }

  if (!pbx) {
    return <WholePageSpinner />;
  }

  const {
    is_active: isActive,
    termination_date_end_limit: terminationDateEndLimit,
    subscription_line: { id: subscriptionLineId },
    pbx_billing_setting: pbxBillingSetting,
  } = pbx;

  if (!isActive) {
    return (
      <div className="d-flex h-100 justify-content-center">
        <PageNotFound />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.edit"
            resource={t("enum.setting.categoryOptions.pbx")}
          />
        </h3>
      </div>
      <PbxForm
        onSubmit={handleSubmit}
        formName="pbxForm"
        formType="edit"
        debtors={debtors}
        routingProfiles={routingProfiles}
        callRates={callRates}
        isLoading={isLoading}
        initialValues={{
          ...pbx,
          pbx_billing_setting_attributes: pbxBillingSetting,
        }}
        isDebtorFetching={isDebtorFetching}
        isRoutingProfileFetching={isRoutingProfileFetching}
        isCallRateFetching={isCallRateFetching}
        terminationDateEndLimit={terminationDateEndLimit}
        subscriptionLineId={subscriptionLineId}
      />
    </div>
  );
};

const mapStateToProps = ({
  pbx: pbxReducer,
  debtor,
  routingProfile,
  callRate,
}) => {
  const { pbxError, pbx, commitSuccess, isLoading, commitError } = pbxReducer;
  const { debtors, isLoading: isDebtorFetching } = debtor;
  const { routingProfiles, isLoading: isRoutingProfileFetching } =
    routingProfile;
  const { callRates, isLoading: isCallRateFetching } = callRate;

  return {
    pbxUpdateError: pbxError,
    isLoading,
    pbx,
    debtors,
    isDebtorFetching,
    routingProfiles,
    isRoutingProfileFetching,
    callRates,
    isCallRateFetching,
    commitSuccess,
    commitError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSinglePbx: (id) => {
    dispatch(singlePbxFetchRequest(id));
  },
  updatePbx: async (id, attributes, callback) => {
    await dispatch(pbxUpdateRequest(id, attributes, callback));
  },
  fetchDebtors: () => {
    dispatch(debtorFetchRequest());
  },
  fetchRoutingProfiles: () => {
    dispatch(routingProfileFetchRequest());
  },
  fetchCallRates: () => {
    dispatch(callRateFetchRequest());
  },
});

PbxEdit.defaultProps = {
  isDebtorFetching: true,
  isRoutingProfileFetching: true,
  isCallRateFetching: true,
  pbx: undefined,
  commitError: {},
};

PbxEdit.propTypes = {
  updatePbx: PropTypes.func.isRequired,
  fetchDebtors: PropTypes.func.isRequired,
  debtors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  isDebtorFetching: PropTypes.bool,
  routingProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  isRoutingProfileFetching: PropTypes.bool,
  fetchRoutingProfiles: PropTypes.func.isRequired,
  fetchCallRates: PropTypes.func.isRequired,
  isCallRateFetching: PropTypes.bool,
  callRates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  commitSuccess: PropTypes.bool.isRequired,
  commitError: PropTypes.shape({}),
  isLoading: PropTypes.bool.isRequired,
  fetchSinglePbx: PropTypes.func.isRequired,
  pbx: PropTypes.shape({
    id: PropTypes.number,
    is_active: PropTypes.bool,
    termination_date_end_limit: PropTypes.instanceOf(Date),
    subscription_line: PropTypes.shape({
      id: PropTypes.number,
    }),
    pbx_billing_setting: PropTypes.shape({}),
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(PbxEdit);
