import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.apiEndPoint()}/users/:userID/user_settings`,
  UPDATE_ALL: `${apiUrlConfig.apiEndPoint()}/users/:id`,
};

class UserSettingApi extends AuthenticatedRequest {
  /**
   * Fetch all settings of a user
   *
   * @param {int} userID ID of a user for whom settings are to be fetched
   *
   */
  static fetchAll(userID) {
    const fetchUrl = URLS.INDEX.replace(":userID", userID);

    return this.get(fetchUrl);
  }

  /**
   * Update all settings of a user
   *
   * @param {int} userID ID of a user for whom settings are to be updated
   *
   * @param {object[]} userSettings Array of user settings
   * @param {int} id ID of a user setting
   * @param {int} userID userID of a user fow whom settings belong to
   * @param {int} settingID settingID associated to a user setting || setting chosen for a user setting
   * @param {string} value value of a user setting
   *
   */
  static updateAll(userID, userSettings) {
    const updateUrl = URLS.UPDATE_ALL.replace(":id", userID);

    const requestBody = {
      user: {
        user_settings_attributes: userSettings,
      },
    };

    return this.patch(updateUrl, requestBody);
  }
}

export default UserSettingApi;
