import React, { useState } from "react";
import { Translate } from "react-i18nify";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  faEnvelope,
  faStopwatch,
  faDatabase,
  faEthernet,
} from "@fortawesome/free-solid-svg-icons";

import { ICON_INFO } from "constants/images";
import { CELL_PHONE } from "constants/routes";

import { isAnAdminUser } from "utils/userRole";

import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import ItemPills from "components/ItemPills/ItemPills";
import NetworkOperatorSubscriptionServicesModal from "components/CellPhone/Detail/NetworkOperatorSubscriptionServices/NetworkOperatorSubscriptionServicesModal";

const NetworkServicesContent = ({
  isAdminUser,
  cellPhoneID,
  toggleModalVisibility,
}) => {
  if (isAdminUser) {
    return (
      <>
        <button
          className="btn btn-outline-primary px-2 py-1 me-3"
          type="button"
          onClick={toggleModalVisibility}
        >
          <Translate value="common.viewDetails" />
        </button>
        <Link
          to={`${CELL_PHONE.addId(
            CELL_PHONE.NETWORK_SERVICES_EDIT,
            cellPhoneID
          )}`}
          className="btn btn-outline-primary px-4 py-1"
        >
          <Translate value="common.edit" />
        </Link>
      </>
    );
  }

  return (
    <button
      className="btn btn-outline-primary px-2 py-1 me-3"
      type="button"
      onClick={toggleModalVisibility}
    >
      <Translate value="common.viewDetails" />
    </button>
  );
};

const CellPhoneAdditionalDetail = ({ cellPhone, providers }) => {
  const {
    ex_recording: recordCalls,
    ex_emailrecording: emailToSendRecordedCalls,
    ex_dialtimeout: dialTimeout,
    custom_extension_providers: customExtensionProviders,
    network_operator_subscription_services: networkOperatorSubscriptionServices,
    is_active: isActive,
    id: cellPhoneID,
  } = cellPhone;

  const [isModalVisible, setModalVisibility] = useState(false);

  const {
    currentUser: { role },
  } = useSelector(({ auth }) => auth);

  const isAdminUser = isAnAdminUser(role);

  const toggleModalVisibility = () => {
    setModalVisibility((previousState) => !previousState);
  };

  if (!isActive) {
    return null;
  }

  const asteriskProviderIds = customExtensionProviders.map(
    (provider) => provider.cp_pr_id
  );
  const providersForCellPhone = providers.filter((provider) =>
    asteriskProviderIds.includes(provider.id)
  );

  const providerNames = providersForCellPhone.map((provider) => provider.name);

  const additionalDetailContent = [
    {
      icon: faDatabase,
      title: <Translate value="attributes.recordCalls" />,
      content: recordCalls,
      dataCyFieldName: "recordCalls",
    },
    emailToSendRecordedCalls && {
      icon: faEnvelope,
      title: <Translate value="cellPhone.emailToSendRecordedCalls" />,
      content: emailToSendRecordedCalls,
      dataCyFieldName: "emailToSendRecordedCalls",
    },
    {
      icon: faStopwatch,
      title: <Translate value="attributes.dialTimeout" />,
      content: dialTimeout,
      dataCyFieldName: "dialTimeout",
    },
    {
      icon: faEthernet,
      title: <Translate value="attributes.providers" />,
      content: <ItemPills propertiesArray={providerNames} />,
      dataCyFieldName: "providers",
    },
    {
      localIconSource: ICON_INFO.SETTINGS_TRANSFER,
      altAttributeText: "settings transfer icon",
      title: <Translate value="common.networkServices" />,
      content: (
        <NetworkServicesContent
          isAdminUser={isAdminUser}
          cellPhoneID={cellPhoneID}
          toggleModalVisibility={toggleModalVisibility}
        />
      ),
    },
  ];

  return (
    <>
      <NetworkOperatorSubscriptionServicesModal
        title={<Translate value="common.networkServices" />}
        isModalVisible={isModalVisible}
        onCancel={toggleModalVisibility}
        networkOperatorSubscriptionServices={
          networkOperatorSubscriptionServices
        }
      />
      <CardWithHeader
        title={<Translate value="common.additionalDetails" />}
        className="shadow-sm"
        cardContent={additionalDetailContent}
      />
    </>
  );
};

NetworkServicesContent.defaultProps = {};

NetworkServicesContent.propTypes = {
  isAdminUser: PropTypes.bool.isRequired,
  cellPhoneID: PropTypes.number.isRequired,
  toggleModalVisibility: PropTypes.func.isRequired,
};

CellPhoneAdditionalDetail.defaultProps = {};

CellPhoneAdditionalDetail.propTypes = {
  cellPhone: PropTypes.shape({
    ex_recording: PropTypes.string,
    ex_emailrecording: PropTypes.string,
    ex_dialtimeout: PropTypes.number,
    custom_extension_providers: PropTypes.arrayOf(
      PropTypes.shape({
        cp_pr_id: PropTypes.number,
      })
    ),
    is_active: PropTypes.bool,
    network_operator_subscription_services: PropTypes.arrayOf(
      PropTypes.shape({
        service_name: PropTypes.string,
        location: PropTypes.string,
        location_constraint: PropTypes.string,
      })
    ),
    id: PropTypes.number,
  }).isRequired,
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default CellPhoneAdditionalDetail;
