import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FixedBanner = ({
  title,
  text,
  iconName,
  iconColorClass,
  bannerBackgroundClass,
  textColorClass,
}) => {
  return (
    <div
      className={`d-flex align-items-center p-3 ${bannerBackgroundClass} ${textColorClass}`}
    >
      <FontAwesomeIcon
        icon={iconName}
        className={`icon ${iconColorClass}`}
        size="2x"
      />

      <div className="d-flex justify-content-center flex-column ms-3">
        {title && <h4 className="mb-0">{title}</h4>}

        <p className="mb-0 mt-2">{text}</p>
      </div>
    </div>
  );
};

FixedBanner.defaultProps = {
  title: "",
  iconColorClass: "text-white",
  textColorClass: "",
};

FixedBanner.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  bannerBackgroundClass: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  iconColorClass: PropTypes.string,
  textColorClass: PropTypes.string,
};

export default FixedBanner;
