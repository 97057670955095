import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { productFetchRequest } from "redux/actions/product";
import { FORM_TYPES } from "constants/form";

import AddOnSubscription from "./AddOnSubscription/AddOnSubscription";

import SubscriptionSection from "./SubscriptionSection";

const SubscriptionSectionContainer = ({
  fields,
  fetchProducts,
  products,
  isProductFetching,
  contextTypeID,
  formType,
  form,
}) => {
  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div>
      <SubscriptionSection
        fields={fields}
        contextTypeID={contextTypeID}
        products={products}
        isProductFetching={isProductFetching}
        form={form}
      />

      <AddOnSubscription
        fields={fields}
        products={products}
        isProductFetching={isProductFetching}
        contextTypeID={contextTypeID}
        formType={formType}
      />
    </div>
  );
};

SubscriptionSectionContainer.defaultProps = {
  formType: FORM_TYPES.CREATE,
};

SubscriptionSectionContainer.propTypes = {
  fields: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
    push: PropTypes.func,
  }).isRequired,
  contextTypeID: PropTypes.number.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchProducts: PropTypes.func.isRequired,
  isProductFetching: PropTypes.bool.isRequired,
  formType: PropTypes.string,
  form: PropTypes.shape({
    batch: PropTypes.func,
    change: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = ({ product }) => {
  const { products, isLoading: isProductFetching } = product;

  return {
    products,
    isProductFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProducts: () => dispatch(productFetchRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionSectionContainer);
