import React, { useState } from "react";
import { connect } from "react-redux";
import { FormGroup, Row, Col } from "reactstrap";
import { Translate } from "react-i18nify";
import { Form as ReactFinalForm, Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import PropTypes from "prop-types";

import {
  renderInputField,
  renderDropdownField,
  renderDateField,
} from "components/ReduxForm/RenderField";
import AgreementSection from "components/Pbx/Form/AgreementSection/AgreementSection";
import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";
import BillingSettingSection from "components/Pbx/Form/BillingSettingSection";

import { required, minValue } from "utils/validation";
import { formatDropdownOptions } from "utils/utils";
import { useSubscriptionTerminationDate } from "utils/customHooks";

import USER_ROLES from "constants/userRoles";
import { FORM_TYPES } from "constants/form";
import { AGREEMENT_OPTIONS } from "constants/constant";
import { paymentTypeOptions } from "constants/dropdownOptions";

const minAttributeValue = minValue(1);

const RenderAgreementSection = ({
  formType,
  odooDebtorID,
  debtors,
  invoiceStartDate,
  odooPaymentTerm,
  minimumBeforeDate,
  deploymentDate,
  onInvoiceStartDateSelect,
  terminationDateDisabledDays,
  isTerminationDateLoading,
  agreementOption,
  form,
}) => {
  if (formType === FORM_TYPES.CREATE)
    return (
      <Row>
        <Col md="12">
          <h4 className="mt-3 mb-3">
            <Translate value="attributes.agreement" />
          </h4>
          <hr />
        </Col>

        <Col md="12">
          <AgreementSection
            odooDebtorID={odooDebtorID}
            debtors={debtors}
            invoiceStartDate={invoiceStartDate}
            odooPaymentTermID={odooPaymentTerm}
          />
        </Col>

        <Col md="12">
          <h4 className="mt-3 mb-3">
            <Translate value="title.section.subscription" />
          </h4>
          <hr />
        </Col>

        <Col md="6">
          <FormGroup>
            <Field
              name="deployment_date"
              component={renderDateField}
              validate={required}
              labelTranslation="attributes.deploymentDate"
              disabledDays={{ before: minimumBeforeDate }}
            />
          </FormGroup>
        </Col>

        <Col md="6">
          <FormGroup>
            <Field
              name="invoice_start_date"
              component={renderDateField}
              validate={required}
              labelTranslation="attributes.invoiceStartDate"
              disabledDays={{
                before:
                  (deploymentDate && new Date(deploymentDate)) ||
                  minimumBeforeDate,
              }}
            />

            <OnChange name="invoice_start_date">
              {(value) =>
                onInvoiceStartDateSelect(value, form, agreementOption)
              }
            </OnChange>
          </FormGroup>
        </Col>
      </Row>
    );

  return (
    <Row>
      <Col md="6">
        <FormGroup>
          <Field
            name="termination_date"
            component={renderDateField}
            labelTranslation="attributes.terminationDate"
            disabledDays={{
              before: terminationDateDisabledDays,
            }}
            isLoadingDate={isTerminationDateLoading}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

const PbxForm = ({
  onSubmit,
  formType,
  debtors,
  routingProfiles,
  callRates,
  isLoading,
  isDebtorFetching,
  isRoutingProfileFetching,
  isCallRateFetching,
  terminationDateEndLimit,
  subscriptionLineId,
  currentUserRole,
  initialValues,
}) => {
  const [odooPaymentTerm, setOdooPaymentTerm] = useState(undefined);

  const handleRoutingProfileSelect = (value, form) => {
    form.change("te_ca_rp_id", value);
  };

  const updateAgreementAttributes = (debtorID, form) => {
    const agreementAttributes = {
      odoo_debtor_id: debtorID,
    };

    form.change(
      "subscription_attributes.agreement_attributes",
      agreementAttributes
    );
  };

  const handleDebtorSelect = (value, form) => {
    if (!value) return;

    const selectedDebtor = debtors.find((debtor) => debtor.id === value);

    const { customer_number: customerNumber, payment_term_id: paymentTermID } =
      selectedDebtor;

    form.change("te_billingcode", customerNumber);

    if (formType === FORM_TYPES.CREATE) {
      setOdooPaymentTerm(paymentTermID);

      updateAgreementAttributes(value, form);
    }
  };

  const handleInvoiceStartDateSelect = (value, form, agreementOption) => {
    if (formType !== FORM_TYPES.CREATE) return;

    form.change("subscription_attributes.start_date", value);

    if (agreementOption === AGREEMENT_OPTIONS.NEW) {
      form.change(
        "subscription_attributes.agreement_attributes.start_date",
        value
      );
    }
  };

  const { subscriptionTerminationEndRange, isTerminationDateLoading } =
    useSubscriptionTerminationDate(subscriptionLineId);

  const minimumBeforeDate =
    currentUserRole === USER_ROLES.SUPER_ADMIN ? "" : new Date();

  return (
    <ReactFinalForm
      keepDirtyOnReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ pristine, submitting, handleSubmit, values, form }) => {
        const {
          deployment_date: deploymentDate,
          odoo_debtor_id: odooDebtorID,
          invoice_start_date: invoiceStartDate,
          agreement_option: agreementOption,
        } = values;

        const todayDate = new Date();
        const terminationDateEndRange =
          terminationDateEndLimit ||
          subscriptionTerminationEndRange ||
          invoiceStartDate ||
          deploymentDate;
        const terminationDateDisabledDays =
          terminationDateEndRange &&
          new Date(terminationDateEndRange) > todayDate
            ? new Date(terminationDateEndRange)
            : todayDate;

        return (
          <form
            className="flexo-form px-4 py-4 pt-5 w-100"
            onSubmit={handleSubmit}
          >
            <div className="pbx-content">
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Field
                      name="odoo_debtor_id"
                      component={renderDropdownField}
                      validate={required}
                      dropdownOptions={formatDropdownOptions(debtors)}
                      labelTranslation="attributes.debtor"
                      disabled={formType === FORM_TYPES.EDIT}
                      placeholderTranslation="label.dropdown.selectResource"
                      replacementTranslations={{
                        placeholder: { resource: "attributes.debtor" },
                      }}
                      isLoading={isDebtorFetching}
                    />

                    <OnChange name="odoo_debtor_id">
                      {(value) => handleDebtorSelect(value, form)}
                    </OnChange>
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="te_name"
                      type="text"
                      placeholderTranslation="attributes.name"
                      component={renderInputField}
                      validate={required}
                      labelTranslation="attributes.name"
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="te_payment_type"
                      component={renderDropdownField}
                      validate={required}
                      dropdownOptions={paymentTypeOptions}
                      placeholderTranslation="label.dropdown.selectResource"
                      replacementTranslations={{
                        placeholder: { resource: "attributes.paymentType" },
                      }}
                      labelTranslation="attributes.paymentType"
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="te_maxchannels"
                      type="number"
                      placeholderTranslation="attributes.maxChannels"
                      component={renderInputField}
                      validate={(value) => [
                        required(value),
                        minAttributeValue(value),
                      ]}
                      labelTranslation="attributes.maxChannels"
                      min={1}
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="te_rp_id"
                      type="text"
                      component={renderDropdownField}
                      validate={required}
                      dropdownOptions={formatDropdownOptions(routingProfiles)}
                      labelTranslation="attributes.routingProfile"
                      placeholderTranslation="label.dropdown.selectResource"
                      replacementTranslations={{
                        placeholder: { resource: "attributes.routingProfile" },
                      }}
                      isLoading={isRoutingProfileFetching}
                    />

                    <OnChange name="te_rp_id">
                      {(value) => handleRoutingProfileSelect(value, form)}
                    </OnChange>
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="te_cl_id"
                      type="text"
                      placeholderTranslation="attributes.callRate"
                      component={renderDropdownField}
                      validate={required}
                      dropdownOptions={formatDropdownOptions(callRates)}
                      labelTranslation="attributes.callRate"
                      isLoading={isCallRateFetching}
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="pk_start"
                      type="number"
                      placeholderTranslation="attributes.parkingLotStart"
                      component={renderInputField}
                      validate={(value) => [
                        required(value),
                        minAttributeValue(value),
                      ]}
                      labelTranslation="attributes.parkingLotStart"
                      min={1}
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="pk_end"
                      type="number"
                      placeholderTranslation="attributes.parkingLotEnd"
                      component={renderInputField}
                      validate={(value) => [
                        required(value),
                        minAttributeValue(value),
                      ]}
                      labelTranslation="attributes.parkingLotEnd"
                      min={1}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <RenderAgreementSection
                debtors={debtors}
                deploymentDate={deploymentDate}
                formType={formType}
                invoiceStartDate={invoiceStartDate}
                isTerminationDateLoading={isTerminationDateLoading}
                minimumBeforeDate={minimumBeforeDate}
                odooDebtorID={odooDebtorID}
                odooPaymentTerm={odooPaymentTerm}
                onInvoiceStartDateSelect={handleInvoiceStartDateSelect}
                terminationDateDisabledDays={terminationDateDisabledDays}
                agreementOption={agreementOption}
                form={form}
              />

              <BillingSettingSection />
            </div>
            <div className="flexo-form-footer">
              <ReduxFormCommitButton
                title={<Translate value="common.save" />}
                submitting={submitting}
                pristine={pristine}
                isLoading={isLoading || isTerminationDateLoading}
              />
            </div>
          </form>
        );
      }}
    />
  );
};

RenderAgreementSection.defaultProps = {
  formType: "",
  odooDebtorID: null,
  invoiceStartDate: undefined,
  odooPaymentTerm: undefined,
  minimumBeforeDate: "",
  deploymentDate: "",
  terminationDateDisabledDays: undefined,
  isTerminationDateLoading: false,
  agreementOption: "",
  form: {},
};

RenderAgreementSection.propTypes = {
  formType: PropTypes.string,
  odooDebtorID: PropTypes.number,
  debtors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  invoiceStartDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  odooPaymentTerm: PropTypes.number,
  minimumBeforeDate: PropTypes.string,
  deploymentDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  terminationDateDisabledDays: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  onInvoiceStartDateSelect: PropTypes.func.isRequired,
  isTerminationDateLoading: PropTypes.bool,
  agreementOption: PropTypes.string,
  form: PropTypes.shape({}),
};

PbxForm.defaultProps = {
  formType: undefined,
  isDebtorFetching: false,
  isRoutingProfileFetching: false,
  isCallRateFetching: false,
  terminationDateEndLimit: undefined,
  subscriptionLineId: null,
  initialValues: {},
};

PbxForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  debtors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  routingProfiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  callRates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  formType: PropTypes.string,
  isDebtorFetching: PropTypes.string,
  isRoutingProfileFetching: PropTypes.string,
  isCallRateFetching: PropTypes.string,
  terminationDateEndLimit: PropTypes.instanceOf(Date),
  subscriptionLineId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentUserRole: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({}),
};

const mapStateToProps = (state) => {
  const {
    currentUser: { role: currentUserRole },
  } = state.auth;

  return {
    currentUserRole,
  };
};

export default connect(mapStateToProps, null)(PbxForm);
