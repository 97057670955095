import { DashboardApi } from "internal";
import { getAndDisplayErrors } from "utils/utils";

export const DASHBOARD_BILL_FETCH_REQUEST = "DASHBOARD_BILL_FETCH_REQUEST";
export const DASHBOARD_BILL_FETCH_RECEIVED = "DASHBOARD_BILL_FETCH_RECEIVED";
export const DASHBOARD_BILL_FETCH_FAILED = "DASHBOARD_BILL_FETCH_FAILED";
export const DASHBOARD_BILL_FETCH_CLEAR_ERRORS =
  "DASHBOARD_BILL_FETCH_CLEAR_ERRORS";

export function dashboardBillFetchReceived(response) {
  return {
    type: DASHBOARD_BILL_FETCH_RECEIVED,
    data: response.data,
  };
}

export function dashboardBillFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: DASHBOARD_BILL_FETCH_FAILED,
    message,
  };
}

export function dashboardBillFetchClearErrors() {
  return {
    type: DASHBOARD_BILL_FETCH_CLEAR_ERRORS,
  };
}

export const dashboardBillFetchRequest = () => (dispatch) => {
  dispatch({ type: DASHBOARD_BILL_FETCH_REQUEST });

  DashboardApi.fetchBillDasboardDetail()
    .then((data) => dispatch(dashboardBillFetchReceived(data)))
    .catch((e) => {
      dispatch(dashboardBillFetchFailed(e));
    });
};
