import {
  SIP_DEVICE_CREATE_REQUEST,
  SIP_DEVICE_CREATE_RECEIVED,
  SIP_DEVICE_CREATE_FAILED,
  SIP_DEVICE_FETCH_CLEAR_ERRORS,
  FETCH_SIP_DEVICE_DEFAULT_FORM_VALUE_REQUEST,
  FETCH_SIP_DEVICE_DEFAULT_FORM_VALUE_RECEIVED,
  FETCH_SIP_DEVICE_DEFAULT_FORM_VALUE_FAILED,
  SINGLE_SIP_DEVICE_FETCH_REQUEST,
  SINGLE_SIP_DEVICE_FETCH_RECEIVED,
  SINGLE_SIP_DEVICE_FETCH_FAILED,
  SIP_DEVICE_UPDATE_REQUEST,
  SIP_DEVICE_UPDATE_FAILED,
  SIP_DEVICE_UPDATE_RECEIVED,
  FETCH_SIP_DEVICES_REQUEST,
  FETCH_SIP_DEVICES_RECEIVED,
  FETCH_SIP_DEVICES_FAILED,
  SIP_DEVICES_SEARCH_REQUEST,
} from "redux/actions/sipDevices";

const defaultState = {
  sipDevice: {},
  sipDevices: [],
  commitError: "",
  fetchError: "",
  commitSuccess: false,
  isLoading: true,
  isSearching: false,
};

function cellPhone(state = defaultState, action) {
  const { sipDevice } = action;

  switch (action.type) {
    case FETCH_SIP_DEVICES_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitError: "",
      };

    case FETCH_SIP_DEVICES_RECEIVED:
      return {
        ...state,
        sipDevices: action.sipDevices,
        isLoading: false,
      };

    case FETCH_SIP_DEVICES_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case SIP_DEVICES_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };
    case SINGLE_SIP_DEVICE_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitError: "",
        commitSuccess: false,
      };

    case SINGLE_SIP_DEVICE_FETCH_RECEIVED:
      return {
        ...state,
        sipDevice,
        isLoading: false,
      };

    case SINGLE_SIP_DEVICE_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case SIP_DEVICE_CREATE_REQUEST:
      return {
        ...state,
        commitSuccess: false,
        commitError: "",
      };

    case SIP_DEVICE_CREATE_RECEIVED:
      return {
        ...state,
        sipDevice,
        isLoading: false,
        commitSuccess: true,
      };

    case SIP_DEVICE_CREATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case SIP_DEVICE_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case SIP_DEVICE_UPDATE_RECEIVED:
      return {
        ...state,
        sipDevice,
        isLoading: false,
        commitSuccess: true,
      };

    case SIP_DEVICE_UPDATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case SIP_DEVICE_FETCH_CLEAR_ERRORS:
      return {
        ...state,
        fetchError: "",
        commitSuccess: false,
        commitError: "",
      };

    case FETCH_SIP_DEVICE_DEFAULT_FORM_VALUE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case FETCH_SIP_DEVICE_DEFAULT_FORM_VALUE_RECEIVED:
      return {
        ...state,
        sipDevice,
        isLoading: false,
      };

    case FETCH_SIP_DEVICE_DEFAULT_FORM_VALUE_FAILED:
      return {
        ...state,
        isLoading: false,
        commitSuccess: false,
        commitError: action.message,
      };

    default:
      return state;
  }
}

export default cellPhone;
