import {
  ROUTING_PROFILE_FETCH_REQUEST,
  ROUTING_PROFILE_FETCH_RECEIVED,
  ROUTING_PROFILE_FETCH_FAILED,
  ROUTING_PROFILE_FETCH_CLEAR_ERRORS,
} from "redux/actions/pbxEngine/routingProfile";

const defaultState = {
  routingProfiles: [],
  routingProfilesFetchError: "",
};

function routingProfile(state = defaultState, action) {
  const { routingProfiles } = action;

  switch (action.type) {
    case ROUTING_PROFILE_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ROUTING_PROFILE_FETCH_RECEIVED:
      return {
        ...state,
        routingProfiles,
        isLoading: false,
      };

    case ROUTING_PROFILE_FETCH_FAILED:
      return {
        ...state,
        routingProfilesFetchError: action.message,
      };

    case ROUTING_PROFILE_FETCH_CLEAR_ERRORS:
      return { ...state, routingProfilesFetchError: "" };

    default:
      return state;
  }
}

export default routingProfile;
