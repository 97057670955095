import React, { useEffect } from "react";
import { Column } from "react-virtualized";
import { Translate, t } from "react-i18nify";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { getParams } from "utils/queryString";
import { isAnAdminUser } from "utils/userRole";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import TranslateDate from "components/i18n/TranslateDate";
import ResourceHeader from "components/ResourceHeader/ResourceHeader";

import {
  PBX_FILTERS,
  PBX_CELL_PHONES_FILTERS,
  ACTIVE_UNDER_PBX_FILTER,
  DEFAULT_FILTER,
} from "constants/filters";
import { PARENT_IDENTIFIER_KEY } from "constants/constant";

const renderCellPhoneTranslateDate = ({ cellData }) => (
  <TranslateDate date={cellData} />
);

const CellPhoneIndex = ({
  cellPhones,
  isLoading,
  parentActiveStatus,
  parentID,
  isSearching,
  searchRecords,
  parentIdentifierKey,
  parentResourceName,
}) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const {
    currentUser: { role },
  } = useSelector(({ auth }) => auth);

  const isAdminUser = isAnAdminUser(role);

  const filterBasedOnParentIdKey =
    parentIdentifierKey === PARENT_IDENTIFIER_KEY.PBX_ID
      ? ACTIVE_UNDER_PBX_FILTER
      : DEFAULT_FILTER;
  const { filter = filterBasedOnParentIdKey } = getParams(search);

  useEffect(() => {
    const searchParams = `?filter=${filter}`;

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath, { replace: true });
  }, [filter]);

  const changeFilter = ({ filter: filterParam }) => {
    const searchParams = `?filter=${filterParam}`;

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath, { replace: true });
  };

  const filterOptionsBasedOnParentKey =
    parentIdentifierKey === PARENT_IDENTIFIER_KEY.PBX_ID
      ? PBX_CELL_PHONES_FILTERS
      : PBX_FILTERS;

  const allFilters = [
    {
      filter: filterOptionsBasedOnParentKey,
      selectedFilter: filter,
      type: "filter",
      headerTranslation: "common.filters",
    },
  ];

  let records;

  if (cellPhones) {
    records = parentID
      ? cellPhones.filter(
          (cellPhone) =>
            cellPhone[parentIdentifierKey] === parseInt(parentID, 10)
        )
      : cellPhones;
  }

  const userLicenseColumn = filter === "active_under_pbx" && (
    <Column
      width={180}
      label={<Translate value="attributes.userLicense" />}
      dataKey="user_license_extension_number"
    />
  );

  return (
    <RecordListingTable
      records={records}
      isLoading={isLoading}
      hideAddButton={!parentActiveStatus || !isAdminUser}
      resourceName="cell_phones"
      resourceTitle={t("title.page.resource.list", {
        resource: t("title.tab.userLicense.cellPhones"),
      })}
      additionalHeaderContent={
        <ResourceHeader changeFilter={changeFilter} allFilters={allFilters} />
      }
      parentID={parentID}
      isSearching={isSearching}
      searchRecords={searchRecords}
      dataCy={`create-${parentResourceName}-cell_phones`}
    >
      <Column
        width={180}
        label={<Translate value="attributes.number" />}
        dataKey="cell_number"
      />
      <Column
        width={200}
        label={<Translate value="attributes.invoiceStartDate" />}
        dataKey="invoice_start_date"
        cellRenderer={renderCellPhoneTranslateDate}
      />
      <Column
        width={210}
        label={<Translate value="attributes.deploymentDate" />}
        dataKey="deployment_date"
        cellRenderer={renderCellPhoneTranslateDate}
      />
      <Column
        width={150}
        label={<Translate value="common.default" />}
        dataKey="is_default"
      />
      {userLicenseColumn}
    </RecordListingTable>
  );
};

CellPhoneIndex.defaultProps = {
  isLoading: true,
  cellPhones: undefined,
  parentID: undefined,
  isSearching: false,
};

CellPhoneIndex.propTypes = {
  cellPhones: PropTypes.arrayOf(
    PropTypes.shape({
      cell_number: PropTypes.number,
      invoice_start_date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]),
      deployment_date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]),
      is_default: PropTypes.bool,
    })
  ),
  isLoading: PropTypes.bool,
  parentID: PropTypes.string,
  searchRecords: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  parentActiveStatus: PropTypes.bool.isRequired,
  parentIdentifierKey: PropTypes.string.isRequired,
  parentResourceName: PropTypes.string.isRequired,
};

export default CellPhoneIndex;
