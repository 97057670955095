import apiUrlConfig from "configs/apiUrlConfig";
import { AuthenticatedRequest } from "internal";

const URLS = {
  USER_LICENSE_CELL_PHONES: `${apiUrlConfig.apiEndPoint()}/user_licenses/:user_license_id/cell_phones?filter=:filterName`,
  SHOW: `${apiUrlConfig.apiEndPoint()}/cell_phones/:id`,
  UPDATE: `${apiUrlConfig.apiEndPoint()}/cell_phones/:id`,
  CREATE: `${apiUrlConfig.apiEndPoint()}/cell_phones`,
  DELETE: `${apiUrlConfig.apiEndPoint()}/cell_phones/:id`,
  PBX_CELL_PHONES: `${apiUrlConfig.apiEndPoint()}/pbxes/:pbx_id/cell_phones?filter=:filterName`,
  SEARCH_PHONE_NUMBER: `${apiUrlConfig.apiEndPoint()}/phone_numbers/dropdown?filter[number_type]=cell_phone&filter[phone_number]=:phoneNumber`,
  SUSPEND: `${apiUrlConfig.apiEndPoint()}/cell_phones/:id/suspend`,
  REACTIVATE: `${apiUrlConfig.apiEndPoint()}/cell_phones/:id/reactivate`,
  ORDER_SIM_CARD: `${apiUrlConfig.apiEndPoint()}/cell_phones/:id/order_sim_card`,
  ASSIGN_NEW_SIM_CARD: `${apiUrlConfig.apiEndPoint()}/cell_phones/:id/assign_new_sim_card`,
  NETWORK_OPERATOR_SUBSCRIPTION_SERVICES_CREATE: `${apiUrlConfig.apiEndPoint()}/cell_phones/:id/network_operator_subscription_services`,
  NETWORK_OPERATOR_SUBSCRIPTION_SERVICES_DELETE: `${apiUrlConfig.apiEndPoint()}/network_operator_subscription_services/:id`,
};

const UrlForFetchAll = (parentType, parentID) => {
  if (parentType === "pbx") {
    return URLS.PBX_CELL_PHONES.replace(":pbx_id", parentID);
  }

  return URLS.USER_LICENSE_CELL_PHONES.replace(":user_license_id", parentID);
};

class CellPhoneApi extends AuthenticatedRequest {
  /**
   * Fetch all cell_phones of a user_license
   *
   * @param {int} parentID id of a user license or a pbx
   * @param {string} filter filter to fetch filtered cell phones
   * @param {string} parentType can be either 'pbx' or 'userLicense'
   *
   */
  static fetchAll(parentID, filter, parentType) {
    const url = UrlForFetchAll(parentType, parentID);

    const filterUrl = url.replace(":filterName", filter);

    return this.get(filterUrl);
  }

  /**
   * Fetch single cell_phone by its ID
   *
   * @param {int} id ID of a cell_phone to be fetched
   *
   */
  static fetchOne(id) {
    const fetchOneUrl = URLS.SHOW.replace(":id", id);

    return this.get(fetchOneUrl);
  }

  /**
   * Update a cell_phone
   *
   * @param {int} id ID of a cell_phone
   *
   * @param {object} attributes attributes of a cell_phone
   * @param {number} user_license_id user_license_id of a user_license belonging to cell_phone
   * @param {number} cell_number number of a cell_phone
   * @param {date} deployment_date date to configure and deploy cell_phone to server
   * @param {date} invoice_start_date date for starting invoice of a cell_phone subscription product
   * @param {number} asterisk_custom_extension_id id of a custom extension saved in asterisk server to link with cell phone in our app
   *
   * @param {object} subscription_attributes subscription for cell phone
   * @param {object} subscription_line_attributes subscription line for cell phone subscription
   * @param {string} product_name name of a subscription product
   * @param {number} price price of a product
   *
   * @param {object} subscription_line_properties_attributes subscription line properties for a cell phone subscription line
   * @param {string} property property name of a product
   * @param {string} value value of a property
   *
   */
  static update(id, attributes) {
    const updateUrl = URLS.UPDATE.replace(":id", id);

    const requestBody = {
      cell_phone: {
        ...attributes,
        fax_service_attributes: attributes.fax_service,
      },
    };

    return this.patch(updateUrl, requestBody);
  }

  /**
   * Create a cell_phone
   *
   * @param {object} attributes attributes of a cell_phone
   * @param {number} user_license_id user_license_id of a user_license belonging to cell_phone
   * @param {number} cell_number number of a cell_phone
   * @param {date} deployment_date date to configure and deploy cell_phone to server
   * @param {date} invoice_start_date date for starting invoice of a cell_phone subscription product
   * @param {number} asterisk_custom_extension_id id of a custom extension saved in asterisk server to link with cell phone in our app
   *
   * @param {object} subscription_attributes subscription for cell phone
   * @param {object} subscription_line_attributes subscription line for cell phone subscription
   * @param {string} product_name name of a subscription product
   * @param {number} price price of a product
   *
   * @param {object} subscription_line_properties_attributes subscription line properties for a cell phone subscription line
   * @param {string} property property name of a product
   * @param {string} value value of a property
   *
   * @param {object} network_operator_subscription_services_attributes array of network services to provision to a cell phone
   * @param {number} network_operator_subscription_services_attributes[i].cell_phone_service_id ID of the network service
   * @param {string} network_operator_subscription_services_attributes[i].location (enum) area where the service is available for use in the cell phone (is it sweden, denmark, us_verizon, etc...)
   * @param {string} network_operator_subscription_services_attributes[i].location_constraint (enum) area within the service availability (is it inside or outside?)
   *
   */
  static create(attributes) {
    const requestBody = {
      cell_phone: {
        ...attributes,
        fax_service_attributes: attributes.fax_service,
      },
    };

    return this.post(URLS.CREATE, requestBody);
  }

  /**
   * delete cell phone
   *
   * @param {int} id ID of a cell phone to delete
   */
  static deleteOne(id) {
    const updateUrl = URLS.DELETE.replace(":id", id);

    return this.delete(updateUrl);
  }

  static searchPhoneNumber(phoneNumber) {
    const fetchURl = URLS.SEARCH_PHONE_NUMBER.replace(
      ":phoneNumber",
      phoneNumber
    );

    return this.get(fetchURl);
  }

  static suspend(id, attributes) {
    const suspendUrl = URLS.SUSPEND.replace(":id", id);

    return this.patch(suspendUrl, attributes);
  }

  static reactivate(id) {
    const reactivateUrl = URLS.REACTIVATE.replace(":id", id);

    return this.patch(reactivateUrl);
  }

  static orderSimCard(id) {
    const orderSimCardUrl = URLS.ORDER_SIM_CARD.replace(":id", id);

    return this.patch(orderSimCardUrl);
  }

  /**
   *
   * @param {init} id - ID of cell phone number
   * @returns
   */
  static assignNewSimCard(id, attributes) {
    const assignSimCardUrl = URLS.ASSIGN_NEW_SIM_CARD.replace(":id", id);

    return this.patch(assignSimCardUrl, attributes);
  }

  /**
   * delete network service provisioned to a cell phone
   *
   * @param {int} id ID of the network service
   *
   */
  static networkOperatorSubscriptionServiceDelete(id) {
    const deleteUrl =
      URLS.NETWORK_OPERATOR_SUBSCRIPTION_SERVICES_DELETE.replace(":id", id);

    return this.delete(deleteUrl);
  }

  /**
   * add/provision network service to a cell phone
   *
   * @param {int} id ID of the cell phone
   *
   * @param {object} network_operator_subscription_service network service to provision to a cell phone
   * @param {number} cell_phone_service_id ID of the network service
   * @param {string} location (enum) area where the service is available for use in the cell phone (is it sweden, denmark, us_verizon, etc...)
   * @param {string} location_constraint (enum) area within the service availability (is it inside or outside?)
   *
   */
  static networkOperatorSubscriptionServiceCreate(id, attributes) {
    const createUrl =
      URLS.NETWORK_OPERATOR_SUBSCRIPTION_SERVICES_CREATE.replace(":id", id);

    const requestBody = {
      network_operator_subscription_service: {
        ...attributes,
      },
    };

    return this.post(createUrl, requestBody);
  }
}

export default CellPhoneApi;
