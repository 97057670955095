import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

import { Translate } from "react-i18nify";

import { logoutRequest } from "redux/actions/auth";
import { AUTH } from "constants/routes";

const UnauthorizedPage = ({ onlyMobileLogin, className, logout }) => {
  const classNames = ["d-flex", "unauthorized-page"];

  if (className) {
    classNames.push(className);
  }
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate(AUTH.LOGIN);
  };

  return (
    <div className={classNames.join(" ")}>
      <FontAwesomeIcon icon={faUserLock} className="mb-4" />
      <h2 className="mb-2">
        <Translate value="common.unauthorized" />
      </h2>
      {onlyMobileLogin ? (
        <div className="d-flex flex-column">
          <Translate value="message.auth.onlyMobileAllowed" />
          <button
            type="button"
            onClick={handleLogout}
            className="btn btn-primary my-2 my-md-5"
          >
            <Translate value="common.signOut" />
          </button>
        </div>
      ) : (
        <p>
          <Translate value="message.auth.unauthorized" />
        </p>
      )}
    </div>
  );
};

UnauthorizedPage.defaultProps = {
  onlyMobileLogin: false,
  className: "",
};

UnauthorizedPage.propTypes = {
  onlyMobileLogin: PropTypes.bool,
  className: PropTypes.string,
  logout: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutRequest()),
});

export default connect(null, mapDispatchToProps)(UnauthorizedPage);
