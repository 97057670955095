import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";
import { Translate } from "react-i18nify";
import { faStore } from "@fortawesome/free-solid-svg-icons";

import {
  debtorFetchRequest,
  searchDebtors,
  debtorSearchRequest,
} from "redux/actions/debtor";

import filterSearchResults from "utils/filterSearchResults";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import LinkHeader from "components/ResourceHeader/LinkHeader";

import { VENDORS } from "constants/routes";

const DebtorIndex = ({
  fetchDebtors,
  debtors,
  isLoading,
  isSearching,
  searchRecords,
  fetchError,
}) => {
  useEffect(() => {
    fetchDebtors();
  }, []);

  return (
    <RecordListingTable
      isSearching={isSearching}
      searchRecords={searchRecords}
      records={debtors}
      isLoading={isLoading}
      resourceName="debtors"
      resourceTitle={
        <Translate value="title.page.resource.list" resource="Debtor" />
      }
      additionalHeaderContent={
        <LinkHeader
          translationValue="attributes.vendors"
          resource={VENDORS.INDEX}
          icon={faStore}
          resourceName="debtor"
          subResource="vendors"
        />
      }
      fetchError={fetchError}
      onRetry={fetchDebtors}
      dataCy="create-debtor"
    >
      <Column
        label={<Translate value="attributes.customerNumber" />}
        dataKey="customer_number"
        width={195}
      />
      <Column
        width={300}
        label={<Translate value="attributes.name" />}
        dataKey="name"
      />
      <Column
        width={300}
        label={<Translate value="attributes.address" />}
        dataKey="address"
      />
      <Column
        width={200}
        label={<Translate value="attributes.email" />}
        dataKey="email"
      />
      <Column
        width={170}
        label={<Translate value="attributes.phone" />}
        dataKey="phone"
      />
    </RecordListingTable>
  );
};

DebtorIndex.defaultProps = {
  isLoading: true,
  debtors: undefined,
  isSearching: false,
  fetchError: null,
};

DebtorIndex.propTypes = {
  debtors: PropTypes.arrayOf(
    PropTypes.shape({
      customerNumber: PropTypes.string,
    })
  ),
  searchRecords: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  fetchDebtors: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  fetchError: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { debtors, fetchError } = state.debtor;
  const results = filterSearchResults({
    resource: debtors,
    searchResult: state.search.debtors,
  });

  return {
    debtors: results,
    isLoading: state.debtor.isLoading,
    isSearching: state.debtor.isSearching,
    fetchError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchRecords: (e) => {
    dispatch(debtorSearchRequest(e));
    dispatch(searchDebtors(e));
  },
  fetchDebtors: () => {
    dispatch(debtorFetchRequest());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DebtorIndex);
