import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Navigate, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { t } from "react-i18nify";

import {
  singleQuotationFetchRequest,
  quotationUpdateRequest,
} from "redux/actions/quotation";
import { debtorFetchRequest } from "redux/actions/debtor";

import { QUOTATION } from "constants/routes";

import { formatRequestValues } from "utils/quotation";
import { processServerResponseForAsyncValidationErrors } from "utils/forms";

import QuotationForm from "components/Quotation/Form/QuotationForm";
import WholePageSpinner from "components/Spinner/WholePageSpinner";
import PageNotFound from "components/Error/PageNotFound/PageNotFound";

const QuotationEdit = ({
  fetchQuotation,
  updateQuotation,
  commitSuccess,
  quotation,
  isLoading,
  fetchDebtors,
  debtors,
  isDebtorFetching,
}) => {
  const { quotationID } = useParams();

  useEffect(() => {
    fetchQuotation(quotationID);
    fetchDebtors();
  }, [quotationID]);

  const handleSubmit = async (values, registeredFields) => {
    let serverResponse = {};
    const formattedValues = formatRequestValues(values);

    await updateQuotation(quotationID, formattedValues, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess) {
    const { id } = quotation;
    const quotationTranslation = t("attributes.quotation");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.update", {
        resource: quotationTranslation,
      })
    );

    return <Navigate to={QUOTATION.addId(QUOTATION.SHOW, id)} />;
  }

  if (!quotation || isLoading) {
    return <WholePageSpinner />;
  }

  const {
    reference_number: referenceNumber,
    is_accepted_by_customer: isAcceptedByCustomer,
    odoo_debtor_id: odooDebtorID,
    agreement_id: agreementID,
    subscriptions,
    all_total_setup_price: allTotalSetupPrice,
    all_total_monthly_price: allTotalMonthlyPrice,
    sent_date: sentDate,
  } = quotation;

  if (isAcceptedByCustomer || sentDate) {
    return (
      <div className="d-flex h-100 justify-content-center">
        <PageNotFound />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">{referenceNumber}</h3>
      </div>
      <QuotationForm
        onSubmit={handleSubmit}
        isLoading={isLoading}
        initialValues={{
          ...quotation,
          agreement: {
            id: agreementID,
            odoo_debtor_id: odooDebtorID,
            subscriptions,
            all_total_setup_price: allTotalSetupPrice,
            all_total_monthly_price: allTotalMonthlyPrice,
          },
        }}
        formName="quotationForm"
        formType="edit"
        debtors={debtors}
        isDebtorFetching={isDebtorFetching}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { fetchError, quotation, isLoading, commitSuccess, commitError } =
    state.quotation;
  const { debtors, isLoading: isDebtorFetching } = state.debtor;

  return {
    fetchError,
    quotation,
    isLoading,
    commitError,
    commitSuccess,
    debtors,
    isDebtorFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchQuotation: (id) => {
    dispatch(singleQuotationFetchRequest(id, true));
  },
  updateQuotation: async (id, attributes, callback) => {
    await dispatch(quotationUpdateRequest(id, attributes, callback));
  },
  fetchDebtors: () => {
    dispatch(debtorFetchRequest());
  },
});

QuotationEdit.defaultProps = {
  quotation: undefined,
  isLoading: true,
  isDebtorFetching: true,
  commitError: {},
};

QuotationEdit.propTypes = {
  commitError: PropTypes.shape({}),
  commitSuccess: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  fetchQuotation: PropTypes.func.isRequired,
  updateQuotation: PropTypes.func.isRequired,
  fetchDebtors: PropTypes.func.isRequired,
  debtors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isDebtorFetching: PropTypes.bool,
  quotation: PropTypes.shape({
    id: PropTypes.number,
    reference_number: PropTypes.string,
    is_accepted_by_customer: PropTypes.bool,
    odoo_debtor_id: PropTypes.number,
    agreement_id: PropTypes.number,
    subscriptions: PropTypes.arrayOf(PropTypes.shape({})),
    all_total_setup_price: PropTypes.number,
    all_total_monthly_price: PropTypes.number,
    sent_date: PropTypes.instanceOf(Date),
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotationEdit);
