import React from "react";
import Skeleton from "react-loading-skeleton";

import HeaderSkeleton from "components/Skeleton/HeaderSkeleton";
import TabTableSkeleton from "components/Skeleton/TabTableSkeleton";

const SubscriptionProductSkeleton = () => (
  <div className="container-fluid">
    <HeaderSkeleton />
    <TabTableSkeleton hasEdit />
    <div className="p-3 bg-white  my-3  rounded shadow-sm border-bottom border-gray-100">
      <Skeleton height={30} width={150} />
    </div>
    <div className="p-3 bg-white my-3 rounded shadow-sm border-bottom border-gray-100">
      <Skeleton height={30} width={150} />
    </div>
  </div>
);

export default SubscriptionProductSkeleton;
