import {
  USER_SETTING_FETCH_REQUEST,
  USER_SETTING_FETCH_RECEIVED,
  USER_SETTING_FETCH_FAILED,
  USER_SETTING_FETCH_CLEAR_ERRORS,
  USER_SETTING_UPDATE_REQUEST,
  USER_SETTING_UPDATE_RECEIVED,
  USER_SETTING_UPDATE_FAILED,
} from "redux/actions/userSetting";

const defaultState = {
  userSettings: [],
  commitError: "",
  fetchError: "",
  commitSuccess: false,
  isLoading: false,
};

function userSetting(state = defaultState, action) {
  const { userSettings } = action;

  switch (action.type) {
    case USER_SETTING_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitSuccess: false,
      };

    case USER_SETTING_FETCH_RECEIVED:
      return {
        ...state,
        userSettings,
        isLoading: false,
      };

    case USER_SETTING_FETCH_FAILED: {
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };
    }

    case USER_SETTING_FETCH_CLEAR_ERRORS:
      return {
        ...state,
        fetchError: "",
        isLoading: false,
        commitSuccess: false,
        commitError: "",
      };

    case USER_SETTING_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case USER_SETTING_UPDATE_RECEIVED:
      return {
        ...state,
        userSettings: userSettings.user_settings,
        isLoading: false,
        commitSuccess: true,
      };

    case USER_SETTING_UPDATE_FAILED: {
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };
    }

    default:
      return state;
  }
}

export default userSetting;
