import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faCalendarTimes,
  faTty,
  faBlenderPhone,
} from "@fortawesome/free-solid-svg-icons";

import { Translate } from "react-i18nify";

import { singleUserLicenseDeleteRequest } from "redux/actions/userLicense";

import DetailUIHeader from "components/Header/DetailUI/DetailUIHeader";
import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import TranslateDate from "components/i18n/TranslateDate";
import DeleteModal from "components/Modal/DeleteModal";

import { PBX, USER_LICENSE, VOICEMAIL_BOX } from "constants/routes";
import { RESOURCE_NAMES } from "constants/constant";

const UserLicenseBasicDetail = ({ userLicense, deleteUserLicense }) => {
  const navigate = useNavigate();

  const {
    id: userLicenseId,
    extension_number: extensionNumber,
    deployment_date: deploymentDate,
    invoice_start_date: invoiceStartDate,
    pbx_id: pbxID,
    pbx_number: pbxNumber,
    termination_date: terminationDate,
    is_active: isActive,
    asterisk_voicemail_box_id: voicemailBoxId,
    user: { odoo_debtor_id: odooDebtorID },
  } = userLicense;

  const [modalData, setModalData] = useState();
  const [modalVisible, setModalVisibility] = useState(false);

  const toggle = () => {
    setModalData(userLicenseId);
    setModalVisibility(!modalVisible);
  };

  const resetModalState = () => {
    setModalVisibility(false);
    setModalData();
  };

  const onDeleteSuccess = () => {
    const pbxShowUrl = PBX.addId(PBX.SHOW, pbxID);

    navigate(`${pbxShowUrl}/user_licenses`, { replace: true });
  };

  const onConfirmDelete = (id) => {
    if (id) deleteUserLicense(id, onDeleteSuccess);

    resetModalState();
  };

  const basicDetailContent = [
    {
      icon: faTty,
      title: <Translate value="attributes.pbxNumber" />,
      content: (
        <>
          {pbxNumber}
          <Link to={PBX.addId(PBX.DETAIL, pbxID)} className="ml-1">
            {" ( "}
            <Translate value="common.viewDetails" />
            {" )"}
          </Link>
        </>
      ),
      dataCyFieldName: "view-pbx-details",
    },
    voicemailBoxId && {
      icon: faBlenderPhone,
      title: <Translate value="attributes.voicemailBox" />,
      content: (
        <>
          {extensionNumber}
          <Link
            to={VOICEMAIL_BOX.addId(VOICEMAIL_BOX.INDEX, voicemailBoxId)}
            className="ml-1"
          >
            {" ( "}
            <Translate value="common.viewDetails" />
            {" )"}
          </Link>
        </>
      ),
      dataCyFieldName: "view-voicemailBox-details",
    },
    {
      icon: faCalendarDay,
      title: <Translate value="attributes.deploymentDate" />,
      content: <TranslateDate date={deploymentDate} />,
      dataCyFieldName: "deploymentDate",
      perform: "userLicense:subscriptionDetails:view",
    },
    {
      icon: faCalendarDay,
      title: <Translate value="attributes.invoiceStartDate" />,
      content: <TranslateDate date={invoiceStartDate} />,
      dataCyFieldName: "invoiceStartDate",
      perform: "userLicense:subscriptionDetails:view",
    },
  ];

  if (terminationDate) {
    basicDetailContent.push({
      icon: faCalendarTimes,
      title: <Translate value="attributes.terminationDate" />,
      content: <TranslateDate date={terminationDate} />,
      dataCyFieldName: "terminationDate",
    });
  }

  const additionalHeaderContent = !isActive && (
    <span className="is-deactivated ms-3 px-2 py-2">
      <FontAwesomeIcon icon={faCalendarTimes} className="me-1" />
      <Translate value="attributes.isDeactivated" />
    </span>
  );

  return (
    <div>
      <DeleteModal
        modalVisible={modalVisible}
        modalData={modalData}
        resourceName="user license"
        toggleModal={toggle}
        onConfirm={onConfirmDelete}
        onCancel={resetModalState}
      />
      <DetailUIHeader
        headerTitle={extensionNumber}
        perform="userLicense:edit"
        editPath={USER_LICENSE.addId(USER_LICENSE.EDIT, userLicenseId)}
        readOnly={!isActive}
        additionalHeaderContent={additionalHeaderContent}
        deletePerform="userLicense:delete"
        handleDelete={toggle}
        hasDelete
        data={{ debtorID: odooDebtorID }}
      />
      <CardWithHeader
        title={<Translate value="common.basicDetails" />}
        className="shadow-sm"
        cardContent={basicDetailContent}
        dataCy={RESOURCE_NAMES.USER_LICENSE}
      />
    </div>
  );
};

UserLicenseBasicDetail.defaultProps = {};

UserLicenseBasicDetail.propTypes = {
  deleteUserLicense: PropTypes.func.isRequired,
  userLicense: PropTypes.shape({
    id: PropTypes.number,
    extension_number: PropTypes.string,
    deployment_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    invoice_start_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    termination_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    pbx_id: PropTypes.number,
    pbx_number: PropTypes.string,
    is_active: PropTypes.bool,
    asterisk_voicemail_box_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    user: PropTypes.shape({
      odoo_debtor_id: PropTypes.number,
    }),
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  deleteUserLicense: (id, cb) =>
    dispatch(singleUserLicenseDeleteRequest(id, cb)),
});

export default connect(null, mapDispatchToProps)(UserLicenseBasicDetail);
