import apiUrlConfig from "configs/apiUrlConfig";
import { AuthenticatedRequest } from "internal";

const URLS = {
  INDEX: `${apiUrlConfig.apiEndPoint()}/pbxes/:pbx_id/did_numbers?filter=:filterName`,
  SHOW: `${apiUrlConfig.apiEndPoint()}/did_numbers/:id`,
  UPDATE: `${apiUrlConfig.apiEndPoint()}/did_numbers/:id`,
  CREATE: `${apiUrlConfig.apiEndPoint()}/did_numbers`,
  DELETE: `${apiUrlConfig.apiEndPoint()}/did_numbers/:id`,
};

class DidNumberApi extends AuthenticatedRequest {
  /**
   * Fetch all did_numbers of a pbx
   * @param {int} pbxID id of a pbx
   * @param {string} filter filter to fetch filtered did numbers
   *
   */
  static fetchAll(pbxID, filter) {
    const url = URLS.INDEX.replace(":pbx_id", pbxID);
    const filterUrl = url.replace(":filterName", filter);

    return this.get(filterUrl);
  }

  /**
   * Fetch single did_number by its ID
   *
   * @param {int} id ID of a did_number to be fetched
   */
  static fetchOne(id) {
    const fetchOneUrl = URLS.SHOW.replace(":id", id);

    return this.get(fetchOneUrl);
  }

  /**
   * Update a did_number
   *
   * @param {int} id ID of a did_number
   *
   * @param {object} attributes attributes of a did_number
   * @param {number} pbx_id pbx_id of a pbx belonging to did_number
   * @param {number} phone_number phone number of a did_number
   * @param {date} deployment_date date to configure and deploy did_number to server
   * @param {date} invoice_start_date date for starting invoice of a did_number subscription product
   * @param {string} first_name first name of a user of did_number
   * @param {string} last_name first name of a user a did_number
   * @param {number} asterisk_did_number_id id of a did number record saved in asterisk server
   *
   * @param {object} subscription_line_attributes subscription line for a did_number
   * @param {string} product_name name of a subscription product
   * @param {number} price price of a product
   *
   * @param {object} subscription_line__properties_attributes subscription line properties for a did_number subscription line
   * @param {string} property property name of a product
   * @param {string} value value of a property
   *
   * @param {object} fax_service_attributes fax service for a did_number
   * @param {array} accept_emails_from list of emails that can send email for sending data to fax
   * @param {date} deployment_date date to configure and deploy did_number to server
   * @param {date} invoice_start_date date for starting invoice of a fax_service subscription product (these two dates will be same as did_numbers' because it is configured at the same time within one form)
   *
   * @param {object} subscription_attributes subscription for fax service
   * @param {object} subscription_line_attributes subscription line for fax service subscription
   * @param {string} product_name name of a subscription product
   * @param {number} price price of a product
   *
   * @param {object} subscription_line__properties_attributes subscription line properties for a fax_service subscription line
   * @param {string} property property name of a product
   * @param {string} value value of a property
   *
   */
  static update(id, attributes) {
    const updateUrl = URLS.UPDATE.replace(":id", id);

    const requestBody = {
      did_number: {
        ...attributes,
        fax_service_attributes: attributes.fax_service,
      },
    };

    return this.patch(updateUrl, requestBody);
  }

  /**
   * Create a did_number
   *
   * @param {object} attributes attributes of a did_number
   * @param {number} pbx_id pbx_id of a pbx belonging to did_number
   * @param {number} phone_number phone number of a did_number
   * @param {date} deployment_date date to configure and deploy did_number to server
   * @param {date} invoice_start_date date for starting invoice of a did_number subscription product
   * @param {string} first_name first name of a user of did_number
   * @param {string} last_name first name of a user a did_number
   * @param {number} asterisk_did_number_id id of a did number record saved in asterisk server
   *
   * @param {object} subscription_line_attributes subscription line for a did_number
   * @param {string} product_name name of a subscription product
   * @param {number} price price of a product
   *
   * @param {object} subscription_line__properties_attributes subscription line properties for a did_number subscription line
   * @param {string} property property name of a product
   * @param {string} value value of a property
   *
   * @param {object} fax_service_attributes fax service for a did_number
   * @param {array} accept_emails_from list of emails that can send email for sending data to fax
   * @param {date} deployment_date date to configure and deploy did_number to server
   * @param {date} invoice_start_date date for starting invoice of a fax_service subscription product (these two dates will be same as did_numbers' because it is configured at the same time within one form)
   *
   * @param {object} subscription_attributes subscription for fax service
   * @param {object} subscription_line_attributes subscription line for fax service subscription
   * @param {string} product_name name of a subscription product
   * @param {number} price price of a product
   *
   * @param {object} subscription_line__properties_attributes subscription line properties for a fax_service subscription line
   * @param {string} property property name of a product
   * @param {string} value value of a property
   *
   */
  static create(attributes) {
    const requestBody = {
      did_number: {
        ...attributes,
        fax_service_attributes: attributes.fax_service,
      },
    };

    return this.post(URLS.CREATE, requestBody);
  }

  /**
   * delete did number
   *
   * @param {int} id ID of a did number to delete
   */
  static deleteOne(id) {
    const updateUrl = URLS.DELETE.replace(":id", id);

    return this.delete(updateUrl);
  }
}

export default DidNumberApi;
