import React from "react";
import { Route, Routes } from "react-router-dom";

import { Admins } from "constants/userRoles";
import { EDIT, NEW, USER } from "constants/routes/routesResources";

import UserIndex from "views/User/UserIndex";
import UserCreate from "views/User/UserCreate";
import UserDetail from "views/User/UserDetail/UserDetail";
import UserEdit from "views/User/UserEdit";

const AdminUserIndex = Admins(UserIndex);
const AdminUserCreate = Admins(UserCreate);

const UserRoutes = () => (
  <Routes>
    <Route index element={<AdminUserIndex />} />
    <Route path={NEW} element={<AdminUserCreate />} />
    <Route path={USER.USER_ID}>
      <Route index element={<UserDetail />} />
      <Route path={EDIT} element={<UserEdit />} />
    </Route>
  </Routes>
);

export default UserRoutes;
