const nestedResources = ["agreementInvoices", "agreementCreditNotes"];

const varyingIdentifierResources = [
  "pbxCellPhones",
  "userLicenseCellPhones",
  "userLicenseDidNumbers",
  "pbxSipDevices",
];

const nestedResourceObjectList = {
  agreementInvoices: {
    parentResourceName: "agreementBilling",
    resourceName: "invoices",
  },
  agreementCreditNotes: {
    parentResourceName: "agreementBilling",
    resourceName: "creditNotes",
  },
};

const varyingIdentifierResourceObjectList = {
  pbxCellPhones: {
    resourceName: "cellPhones",
  },
  userLicenseCellPhones: {
    resourceName: "cellPhones",
  },
  userLicenseDidNumbers: {
    resourceName: "didNumbers",
  },
  pbxSipDevices: {
    resourceName: "sipDevices",
  },
};

const singularizeResourceName = (resourceName) => {
  // TODO: use some library to singularize and pluralize
  const charactersToRemove = resourceName === "faxStatuses" ? 2 : 1;

  return resourceName.substring(0, resourceName.length - charactersToRemove);
};

// handles only first level nested resources
// For e.g. {agreement: {invoice: {invoices: []}}}
// cannot handle more than that, update this method if in future more levels need to be handled (very unlikely)
const nestedResourceSelector = (resourceName, state) => {
  const resource = nestedResourceObjectList[resourceName];
  const { resourceName: name, parentResourceName } = resource;

  const resourceIdentifier = singularizeResourceName(name);

  return state[parentResourceName][resourceIdentifier][name];
};

const varyingIdentifierResourceSelector = (resourceName, state) => {
  const resource = varyingIdentifierResourceObjectList[resourceName];
  const { resourceName: name } = resource;

  const resourceIdentifier = singularizeResourceName(resourceName);

  return state[resourceIdentifier][name];
};

const customResourceSelector = (resourceName, state) => {
  if (nestedResources.includes(resourceName)) {
    return nestedResourceSelector(resourceName, state);
  }

  if (varyingIdentifierResources.includes(resourceName)) {
    return varyingIdentifierResourceSelector(resourceName, state);
  }

  const resourceIdentifier = singularizeResourceName(resourceName);

  // For e.g. debtor['debtors'] -> debtor is resourceIdentifier and debtors is resourceName or where all debtor records are being stored
  return state[resourceIdentifier][resourceName];
};

export default customResourceSelector;
