import React from "react";
import { Form } from "reactstrap";
import { Form as ReactFinalForm } from "react-final-form";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";
import ProductSection from "components/SubscriptionProduct/Form/ProductSection";

const SubscriptionProductForm = ({ isLoading, initialValues, onSubmit }) => (
  <ReactFinalForm
    initialValues={initialValues}
    onSubmit={onSubmit}
    render={({ pristine, submitting, handleSubmit }) => (
      <Form className="flexo-form px-4 py-4 pt-5 w-100" onSubmit={handleSubmit}>
        <ProductSection />

        <div className="flexo-form-footer">
          <ReduxFormCommitButton
            title={<Translate value="common.save" />}
            submitting={submitting}
            pristine={pristine}
            isLoading={isLoading}
          />
        </div>
      </Form>
    )}
  />
);

SubscriptionProductForm.defaultProps = {
  initialValues: {},
};

SubscriptionProductForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
};

export default SubscriptionProductForm;
