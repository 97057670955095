import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toastr } from "react-redux-toastr";

import { t, Translate } from "react-i18nify";

import { NumberPortingRequestApi } from "internal";

import { getServerError } from "utils/utils";

import DotsLoadingIndicator from "components/Spinner/DotsLoadingIndicator";

const RequestAcceptModal = ({ refNumber, fetchSingleNumberPortingRequest }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [requestError, setRequestError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const toggleModal = () => {
    if (isLoading) return;

    setModalOpen(!modalOpen);
  };

  const handleSubmit = () => {
    setIsLoading(true);

    NumberPortingRequestApi.acceptImportRequest(refNumber)
      .then((data) => {
        if (!data) return;

        setIsLoading(false);
        toggleModal();
        setRequestError("");

        fetchSingleNumberPortingRequest(refNumber);

        toastr.success(
          t("common.success"),
          t("toasterMessage.numberPortingRequest.importAccepted")
        );
      })
      .catch(({ response }) => {
        const error = getServerError(response);

        setRequestError(error);
        setIsLoading(false);
      });
  };

  const modalBodyContent =
    requestError.length > 0 ? (
      <div>
        {isLoading && <DotsLoadingIndicator />}
        {!isLoading && (
          <p className="text-danger mb-0">
            <Translate value="common.error" /> {`: ${requestError}`}
          </p>
        )}
      </div>
    ) : (
      <Translate value="numberPortingRequest.requestAcceptModal.acceptRequest" />
    );

  const loadingIcon = isLoading ? (
    <DotsLoadingIndicator className="ms-2" />
  ) : null;

  const confirmButtonText =
    requestError.length > 0 ? (
      <Translate value="error.retry" />
    ) : (
      <Translate value="common.confirm" />
    );

  return (
    <div>
      <Button color="primary" onClick={toggleModal} className="me-2">
        <Translate value="numberPortingRequest.acceptRequest" />
      </Button>

      <Modal isOpen={modalOpen} toggle={toggleModal} className="response-modal">
        <ModalHeader>{refNumber}</ModalHeader>

        <ModalBody className="mb-3">{modalBodyContent}</ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={isLoading}
            className="d-flex align-items-center justify-content-center"
          >
            {confirmButtonText}
            {loadingIcon}
          </Button>

          <Button
            className="bg-white text-dark"
            onClick={toggleModal}
            disabled={isLoading}
          >
            <Translate value="common.cancel" />
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

RequestAcceptModal.propTypes = {
  refNumber: PropTypes.string.isRequired,
  fetchSingleNumberPortingRequest: PropTypes.func.isRequired,
};

export default RequestAcceptModal;
