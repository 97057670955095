import React from "react";
import { Translate } from "react-i18nify";
import PropTypes from "prop-types";

import { faSimCard } from "@fortawesome/free-solid-svg-icons";

import HideShowContent from "components/HideShowContent/HideShowContent";
import CardWithHeader from "components/Card/WithHeader/CardWithHeader";

import { SIM_CARD } from "constants/routes";
import { RESOURCE_NAMES } from "constants/constant";

const OwnerType = ({ simCardBorrower }) => {
  if (simCardBorrower?.is_employee) {
    return (
      <span className="owner-type employee py-1 px-3 ms-3">
        <Translate value="attributes.employee" />
      </span>
    );
  }

  return (
    <span className="owner-type debtor py-1 px-3 ms-3">
      <Translate value="attributes.debtor" />
    </span>
  );
};

const CellPhoneSimCardDetail = ({ simCard }) => {
  const {
    pin_1: pinOne,
    pin_2: pinTwo,
    puk_1: pukOne,
    puk_2: pukTwo,
    id,
    imsi_number: imsiNumber,
    iccid_number: iccidNumber,
    service_provider: serviceProvider,
    sim_card_borrower: simCardBorrower,
  } = simCard;

  const simCardDetailContent = [
    {
      icon: faSimCard,
      title: <Translate value="attributes.iccid" />,
      content: iccidNumber,
      dataCyFieldName: "iccid",
    },
    {
      icon: faSimCard,
      title: <Translate value="attributes.imsi" />,
      content: imsiNumber,
      dataCyFieldName: "imsi",
    },
    {
      icon: faSimCard,
      title: <Translate value="attributes.pin1" />,
      content: <HideShowContent content={pinOne.toString()} />,
      dataCyFieldName: "pin1",
    },
    {
      icon: faSimCard,
      title: <Translate value="attributes.puk1" />,
      content: <HideShowContent content={pukOne.toString()} />,
      dataCyFieldName: "puk1",
    },
    {
      icon: faSimCard,
      title: <Translate value="attributes.pin2" />,
      content: <HideShowContent content={pinTwo.toString()} />,
      dataCyFieldName: "pin2",
    },
    {
      icon: faSimCard,
      title: <Translate value="attributes.puk2" />,
      content: <HideShowContent content={pukTwo.toString()} />,
      dataCyFieldName: "puk2",
    },
    simCardBorrower?.is_employee && {
      icon: faSimCard,
      title: <Translate value="attributes.simCardHolder" />,
      content: simCardBorrower.name,
      dataCyFieldName: "simCardHolder",
    },
    serviceProvider?.name && {
      icon: faSimCard,
      title: <Translate value="attributes.owner" />,
      content: (
        <>
          {serviceProvider?.name}
          <OwnerType simCardBorrower={simCardBorrower} />
        </>
      ),
      dataCyFieldName: "owner",
    },
  ];

  return (
    <div className="sim-card-basic-detail pill">
      <CardWithHeader
        title={
          <p className="mb-0 fw-bold align-self-sm-center">
            <Translate value="common.simCardDetails" />
          </p>
        }
        className="shadow-sm"
        cardContent={simCardDetailContent}
        editPath={SIM_CARD.addId(SIM_CARD.EDIT, id)}
        readOnly={false}
        dataCy={RESOURCE_NAMES.CELL_PHONE}
      />
    </div>
  );
};

OwnerType.defaultProps = {
  simCardBorrower: {},
};

OwnerType.propTypes = {
  simCardBorrower: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    is_employee: PropTypes.bool,
  }),
};

CellPhoneSimCardDetail.defaultProps = {};

CellPhoneSimCardDetail.propTypes = {
  simCard: PropTypes.shape({
    id: PropTypes.number,
    pin_1: PropTypes.number,
    pin_2: PropTypes.number,
    puk_1: PropTypes.number,
    puk_2: PropTypes.number,
    imsi_number: PropTypes.string,
    iccid_number: PropTypes.string,
    new_sim_card_requested_date: PropTypes.string,
    service_provider: PropTypes.shape({
      name: PropTypes.string,
    }),
    sim_card_borrower: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      is_employee: PropTypes.bool,
    }),
    blocked_on: PropTypes.string,
  }).isRequired,
};

export default CellPhoneSimCardDetail;
