import {
  QUOTATION_FETCH_REQUEST,
  QUOTATION_FETCH_RECEIVED,
  QUOTATION_FETCH_FAILED,
  QUOTATION_SEARCH_REQUEST,
  QUOTATION_PREVIEW_FAILED,
  QUOTATION_PREVIEW_RECEIVED,
  QUOTATION_PREVIEW_REQUEST,
  SEND_QUOTATION_EMAIL_FAILED,
  SEND_QUOTATION_EMAIL_RECEIVED,
  SEND_QUOTATION_EMAIL_REQUEST,
  TERMS_AND_CONDITIONS_PREVIEW_FAILED,
  TERMS_AND_CONDITIONS_PREVIEW_RECEIVED,
  TERMS_AND_CONDITIONS_PREVIEW_REQUEST,
  QUOTATION_CREATE_FAILED,
  QUOTATION_CREATE_REQUEST,
  QUOTATION_CREATE_RECEIVED,
  SINGLE_QUOTATION_FETCH_REQUEST,
  SINGLE_QUOTATION_FETCH_RECEIVED,
  SINGLE_QUOTATION_FETCH_FAILED,
  QUOTATION_UPDATE_REQUEST,
  QUOTATION_UPDATE_RECEIVED,
  QUOTATION_UPDATE_FAILED,
  QUOTATION_DELETE_REQUEST,
  QUOTATION_DELETE_RECEIVED,
  QUOTATION_DELETE_FAILED,
  CONVERT_TO_AGREEMENT_FAILED,
  CONVERT_TO_AGREEMENT_RECEIVED,
  CONVERT_TO_AGREEMENT_REQUEST,
} from "redux/actions/quotation";

const defaultState = {
  quotations: [],
  fetchError: "",
  commitError: "",
  commitSuccess: false,
  isSearching: false,
  isLoading: false,
  quotation: undefined,
  quotationPreview: undefined,
  termsAndConditionsPreview: undefined,
  agreement: undefined,
};

function quotation(state = defaultState, action) {
  const { quotations, quotation: singleQuotation } = action;

  switch (action.type) {
    case QUOTATION_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
      };

    case QUOTATION_FETCH_RECEIVED:
      return {
        ...state,
        quotations,
        isLoading: false,
      };

    case QUOTATION_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case SINGLE_QUOTATION_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitSuccess: false,
      };

    case SINGLE_QUOTATION_FETCH_RECEIVED:
      return {
        ...state,
        quotation: singleQuotation,
        isLoading: false,
      };

    case SINGLE_QUOTATION_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case QUOTATION_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    case QUOTATION_CREATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case QUOTATION_CREATE_RECEIVED:
      return {
        ...state,
        quotation: singleQuotation,
        isLoading: false,
        commitSuccess: true,
      };

    case QUOTATION_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case QUOTATION_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case QUOTATION_UPDATE_RECEIVED:
      return {
        ...state,
        quotation: singleQuotation,
        isLoading: false,
        commitSuccess: true,
      };

    case QUOTATION_UPDATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case QUOTATION_DELETE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case QUOTATION_DELETE_RECEIVED:
      return {
        ...state,
        quotation: null,
        isLoading: false,
        commitSuccess: true,
      };

    case QUOTATION_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
        quotationPreview: undefined,
      };

    case QUOTATION_PREVIEW_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case QUOTATION_PREVIEW_RECEIVED:
      return {
        ...state,
        quotationPreview: action.quotationPreview,
        isLoading: false,
      };

    case QUOTATION_PREVIEW_REQUEST:
      return {
        ...state,
        isLoading: false,
        fetchError: "",
        quotationPreview: undefined,
      };

    case SEND_QUOTATION_EMAIL_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case SEND_QUOTATION_EMAIL_RECEIVED:
      return {
        ...state,
        quotation: singleQuotation,
        isLoading: false,
      };

    case SEND_QUOTATION_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: false,
        fetchError: "",
      };

    case TERMS_AND_CONDITIONS_PREVIEW_REQUEST:
      return {
        ...state,
        isLoading: false,
        fetchError: "",
        termsAndConditionsPreview: undefined,
      };

    case TERMS_AND_CONDITIONS_PREVIEW_FAILED:
      return {
        ...state,
        isLoading: false,
        fetchError: action.message,
      };

    case TERMS_AND_CONDITIONS_PREVIEW_RECEIVED:
      return {
        ...state,
        isLoading: false,
        termsAndConditionsPreview: action.termsAndConditionsPreview,
      };

    case CONVERT_TO_AGREEMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case CONVERT_TO_AGREEMENT_RECEIVED:
      return {
        ...state,
        agreement: action.agreement,
        isLoading: false,
        commitSuccess: true,
      };

    case CONVERT_TO_AGREEMENT_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    default:
      return state;
  }
}

export default quotation;
