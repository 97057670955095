import React from "react";
import PropTypes from "prop-types";

import ResourceDropdown from "components/ResourceHeader/ResourceDropdown";

const FilterElementList = ({ filterList, changeFilter, resource }) =>
  filterList.map(({ filter, selectedFilter, type, headerTranslation }) => {
    const remainingFiltersArray = filterList.filter(
      (remainingFilter) => remainingFilter?.type !== type
    );

    const remainingFilters = remainingFiltersArray.map(
      ({
        type: remainingFilterType,
        selectedFilter: remainingSelectedFilters,
      }) => ({
        [remainingFilterType]: remainingSelectedFilters,
      })
    );

    return (
      <ResourceDropdown
        selectedFilter={selectedFilter}
        changeFilter={changeFilter}
        otherFilters={Object.assign({}, ...remainingFilters)}
        filters={filter}
        headerTranslation={headerTranslation}
        resource={resource}
        filterType={type}
      />
    );
  });

FilterElementList.defaultProps = {};

FilterElementList.propTypes = {
  allFilters: PropTypes.arrayOf(
    PropTypes.shape({
      filter: PropTypes.arrayOf(PropTypes.string),
      selectedFilter: PropTypes.string,
      type: PropTypes.string,
      headerTranslation: PropTypes.string,
    })
  ).isRequired,
  changeFilter: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
};

export default FilterElementList;
