import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";
import { Translate } from "react-i18nify";

import {
  billsFetchRequest,
  vendorBillsFetchRequest,
  searchBills,
  billsSearchRequest,
} from "redux/actions/bill";

import filterSearchResults from "utils/filterSearchResults";

import HasPermission from "hoc/RbacCTA";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import TranslateNumber from "components/i18n/TranslateNumber";
import TranslateDate from "components/i18n/TranslateDate";

const renderBillTranslateDate = ({ cellData }) => (
  <TranslateDate date={cellData} />
);

const renderBillTranslateNumber = ({ cellData }) => (
  <TranslateNumber value={cellData} minimumFractionDigits={2} />
);

const BillsIndex = ({
  bills,
  isLoading,
  className,
  parentID,
  fetchVendorBills,
  searchRecords,
  isSearching,
  fetchBills,
  fetchError,
}) => {
  const { vendorID } = useParams();

  const filterID = parentID || vendorID;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (filterID) {
      return fetchVendorBills(filterID);
    }
    fetchBills();
  }, [filterID]);

  return (
    <HasPermission
      data={{
        debtorID: parseInt(vendorID, 10),
      }}
      perform="bills:list"
      redirect
    >
      <RecordListingTable
        records={bills}
        isLoading={isLoading}
        hideAddButton
        resourceName="bills"
        resourceTitle={
          <Translate value="title.page.resource.list" resource="Bills" />
        }
        totalDeactivated={0}
        className={className}
        parentID={parentID}
        searchRecords={searchRecords}
        isSearching={isSearching}
        fetchError={fetchError}
        onRetry={fetchBills}
      >
        <Column
          label={<Translate value="attributes.number" />}
          dataKey="number"
          width={180}
        />
        {!filterID && (
          <Column
            width={300}
            label={<Translate value="attributes.debtorName" />}
            dataKey="debtor_name"
          />
        )}
        <Column
          width={220}
          label={<Translate value="attributes.dateInvoice" />}
          dataKey="invoice_date"
          cellRenderer={renderBillTranslateDate}
        />
        <Column
          width={200}
          label={<Translate value="attributes.dateDue" />}
          dataKey="invoice_date_due"
          cellRenderer={renderBillTranslateDate}
        />
        <Column
          width={200}
          label={<Translate value="attributes.total" />}
          dataKey="amount_total_signed"
          cellRenderer={renderBillTranslateNumber}
        />
        <Column
          width={230}
          label={<Translate value="attributes.residualSigned" />}
          dataKey="residual_signed"
          cellRenderer={renderBillTranslateNumber}
        />
        <Column
          width={130}
          label={<Translate value="attributes.status" />}
          dataKey="state"
        />
      </RecordListingTable>
    </HasPermission>
  );
};

BillsIndex.defaultProps = {
  isLoading: true,
  className: "",
  parentID: undefined,
  bills: undefined,
  isSearching: false,
  fetchError: null,
};

BillsIndex.propTypes = {
  bills: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  parentID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fetchBills: PropTypes.func.isRequired,
  fetchVendorBills: PropTypes.func.isRequired,
  searchRecords: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  fetchError: PropTypes.string,
};

const mapStateToProps = ({ search, ...state }) => {
  const { bills, isLoading, isSearching, fetchError } = state.bill;

  const results = filterSearchResults({
    resource: bills,
    searchResult: search.bills,
  });

  return {
    bills: results,
    isLoading,
    isSearching,
    fetchError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchRecords: (e) => {
    dispatch(billsSearchRequest(e));
    dispatch(searchBills(e));
  },
  fetchBills: () => {
    dispatch(billsFetchRequest());
  },
  fetchVendorBills: (id) => {
    dispatch(vendorBillsFetchRequest(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BillsIndex);
