import {
  DEBTOR_TYPE_FETCH_REQUEST,
  DEBTOR_TYPE_FETCH_RECEIVED,
  DEBTOR_TYPE_FETCH_FAILED,
  DEBTOR_TYPE_FETCH_CLEAR_ERRORS,
} from "redux/actions/debtorType";

const defaultAuthState = {
  debtorTypes: [],
  debtorTypeFetchError: "",
};

function debtorType(state = defaultAuthState, action) {
  const { debtorTypes } = action;

  switch (action.type) {
    case DEBTOR_TYPE_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case DEBTOR_TYPE_FETCH_RECEIVED:
      return {
        ...state,
        debtorTypes,
        isLoading: false,
      };

    case DEBTOR_TYPE_FETCH_FAILED:
      return {
        ...state,
        debtorTypeFetchError: action.message,
        isLoading: false,
      };

    case DEBTOR_TYPE_FETCH_CLEAR_ERRORS:
      return { ...state, debtorTypeFetchError: "", isLoading: false };

    default:
      return state;
  }
}

export default debtorType;
