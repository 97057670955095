import React from "react";
import { Col, Form, FormGroup, Row } from "reactstrap";
import { Form as ReactFinalForm, Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import PropTypes from "prop-types";

import { Translate } from "react-i18nify";

import {
  exportStatusOptions,
  proposedDateReasonOptions,
  ochRejectionCodeOptions,
} from "constants/dropdownOptions";

import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";
import { renderDropdownField } from "components/ReduxForm/RenderField";
import renderDateTimeField from "components/ReduxForm/DateTimeField/DateTimeField";

import { NUMBER_PORTING_REQUESTS } from "constants/constant";

import { getDirtyFieldsValue } from "utils/utils";
import { required } from "utils/validation";

const RejectionCodeDropdownField = ({
  onOchRejectionCodeChange,
  exportStatus,
  form,
}) => {
  if (exportStatus !== NUMBER_PORTING_REQUESTS.EXPORT_STATUS.REJECTED)
    return null;

  return (
    <Col md="6">
      <FormGroup>
        <Field
          name="och_rejection_code"
          component={renderDropdownField}
          validate={required}
          dropdownOptions={ochRejectionCodeOptions}
          labelTranslation="attributes.ochRejectionCode"
        />
      </FormGroup>

      <OnChange name="och_rejection_code">
        {(value) => {
          onOchRejectionCodeChange(value, form);
        }}
      </OnChange>
    </Col>
  );
};

const RequestedPortingDateInputField = ({
  onRequestedPortingDateChange,
  requestedPortingDate,
  exportStatus,
  form,
}) => {
  if (
    !exportStatus ||
    requestedPortingDate ||
    exportStatus === NUMBER_PORTING_REQUESTS.EXPORT_STATUS.REJECTED
  )
    return null;

  return (
    <Col md="6">
      <FormGroup>
        <Field
          name="requested_porting_date"
          component={renderDateTimeField}
          validate={required}
          labelTranslation="attributes.requestedPortingDate"
          disabledDays={{
            before: new Date(),
          }}
        />
        <OnChange name="requested_porting_date">
          {(value) => {
            onRequestedPortingDateChange(value, exportStatus, form);
          }}
        </OnChange>
      </FormGroup>
    </Col>
  );
};

const ConfirmedPortingDateInputField = ({ requestedPortingDate }) => (
  <Col md="6">
    <FormGroup>
      <Field
        name="confirmed_porting_date"
        component={renderDateTimeField}
        validate={required}
        labelTranslation="attributes.proposedDate"
        disabledDays={{
          before: new Date(requestedPortingDate),
        }}
        initialMonth={new Date(requestedPortingDate)}
      />
    </FormGroup>
  </Col>
);

const ProposedDateReasonDropdownField = () => (
  <Col md="6">
    <FormGroup>
      <Field
        name="proposed_date_reason"
        component={renderDropdownField}
        validate={required}
        dropdownOptions={proposedDateReasonOptions}
        labelTranslation="attributes.reason"
      />
    </FormGroup>
  </Col>
);

const ProposeNewDateInputFields = ({ exportStatus, requestedPortingDate }) => {
  if (exportStatus !== NUMBER_PORTING_REQUESTS.EXPORT_STATUS.PROPOSED_NEW_DATE)
    return null;

  return (
    <>
      <ConfirmedPortingDateInputField
        requestedPortingDate={requestedPortingDate}
      />
      <ProposedDateReasonDropdownField />
    </>
  );
};

const ResponseForm = ({ onSubmit, isLoading, requestedPortingDate }) => {
  const handleExportStatusChange = (value, pristine, form) => {
    if (!pristine) {
      const formFieldsToClear = [
        "och_rejection_code",
        "rejection_reason",
        "confirmed_porting_date",
        "proposed_date_reason_code",
        "proposed_date_reason",
        "rejection_date",
      ];

      form.batch(() => {
        formFieldsToClear.forEach((formField) => form.change(formField, null));
      });
    }

    if (value === NUMBER_PORTING_REQUESTS.EXPORT_STATUS.REJECTED) {
      form.change("rejection_date", new Date());
    } else if (value === NUMBER_PORTING_REQUESTS.EXPORT_STATUS.ACCEPTED) {
      form.change("confirmed_porting_date", requestedPortingDate);
    }
  };

  const handleRejectionCodeChange = (value, form) => {
    const rejectionReason = ochRejectionCodeOptions.find(
      ({ value: code }) => code === value
    );

    form.change("rejection_reason", rejectionReason.label);
  };

  const handleRequestedPortingDateChange = (value, exportStatus, form) => {
    if (exportStatus !== NUMBER_PORTING_REQUESTS.EXPORT_STATUS.REJECTED) {
      form.change("confirmed_porting_date", value);
    }
  };

  const handleFormSubmit = (fields, form) => {
    const registeredFields = form.getRegisteredFields();
    const values = getDirtyFieldsValue(fields, form);

    return onSubmit(values, registeredFields);
  };

  return (
    <ReactFinalForm
      onSubmit={handleFormSubmit}
      render={({ pristine, submitting, handleSubmit, form, values }) => {
        const { export_status: exportStatus } = values;

        return (
          <Form
            className="flexo-form w-100 shadow-none mb-0"
            onSubmit={handleSubmit}
          >
            <div className="flexo-form-content">
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Field
                      name="export_status"
                      component={renderDropdownField}
                      validate={required}
                      dropdownOptions={exportStatusOptions}
                      labelTranslation="common.response"
                    />

                    <OnChange name="export_status">
                      {(value) => {
                        handleExportStatusChange(value, pristine, form);
                      }}
                    </OnChange>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <RequestedPortingDateInputField
                  form={form}
                  exportStatus={exportStatus}
                  requestedPortingDate={requestedPortingDate}
                  onRequestedPortingDateChange={
                    handleRequestedPortingDateChange
                  }
                />

                <RejectionCodeDropdownField
                  form={form}
                  exportStatus={exportStatus}
                  onOchRejectionCodeChange={handleRejectionCodeChange}
                />

                <ProposeNewDateInputFields
                  form={form}
                  exportStatus={exportStatus}
                  onRequestedPortingDateChange={
                    handleRequestedPortingDateChange
                  }
                  requestedPortingDate={requestedPortingDate}
                />
              </Row>
            </div>

            <div className="flexo-form-footer">
              <ReduxFormCommitButton
                title={<Translate value="common.save" />}
                submitting={submitting}
                pristine={pristine}
                isLoading={isLoading}
              />
            </div>
          </Form>
        );
      }}
    />
  );
};

RejectionCodeDropdownField.defaultProps = { exportStatus: "", form: {} };
RejectionCodeDropdownField.propTypes = {
  onOchRejectionCodeChange: PropTypes.func.isRequired,
  exportStatus: PropTypes.string,
  form: PropTypes.shape({}),
};

RequestedPortingDateInputField.defaultProps = {
  requestedPortingDate: "",
  exportStatus: "",
  form: {},
};
RequestedPortingDateInputField.propTypes = {
  onRequestedPortingDateChange: PropTypes.func.isRequired,
  requestedPortingDate: PropTypes.string,
  exportStatus: PropTypes.string,
  form: PropTypes.shape({}),
};

ConfirmedPortingDateInputField.defaultProps = { requestedPortingDate: "" };
ConfirmedPortingDateInputField.propTypes = {
  requestedPortingDate: PropTypes.string,
};

ProposeNewDateInputFields.defaultProps = {
  exportStatus: "",
  requestedPortingDate: "",
};
ProposeNewDateInputFields.propTypes = {
  exportStatus: PropTypes.string,
  requestedPortingDate: PropTypes.string,
};

ResponseForm.defaultProps = {
  numberPortingRequest: undefined,
};
ResponseForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  numberPortingRequest: PropTypes.shape({}),
  isLoading: PropTypes.bool.isRequired,
  requestedPortingDate: PropTypes.string.isRequired,
};

export default ResponseForm;
