import React from "react";
import { FormGroup, Row, Col } from "reactstrap";
import { Field, useForm } from "react-final-form";
import PropTypes from "prop-types";
import { OnChange } from "react-final-form-listeners";

import {
  invoicePeriodOptions,
  terminationTermOptions,
} from "constants/dropdownOptions";

import { required } from "utils/validation";
import { formatDropdownOptions } from "utils/utils";

import {
  renderDropdownField,
  renderDateField,
} from "components/ReduxForm/RenderField";
import ToggleButtonField from "components/ReduxForm/ToggleButtonField";

const BasicInfoSection = ({
  debtors,
  paymentTerms,
  isDebtorFetching,
  isPaymentTermFetching,
}) => {
  const form = useForm();

  const dropdownDebtors =
    debtors?.map((debtor) => ({
      id: debtor.id,
      value: debtor.id,
      label: debtor.name,
    })) || [];

  const handleDebtorSelect = (debtorID) => {
    const selectedDebtor = debtors.find(({ id }) => id === debtorID);
    const { payment_term_id: debtorPaymentTermID } = selectedDebtor;

    if (debtorPaymentTermID !== null) {
      form.batch(() => {
        form.change("odoo_payment_term_id", debtorPaymentTermID);
      });
    }
  };

  return (
    <Row>
      <Col md="4">
        <FormGroup>
          <Field
            name="odoo_debtor_id"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={dropdownDebtors}
            labelTranslation="attributes.debtor"
            placeholderTranslation="common.selectDebtor"
            isLoading={isDebtorFetching}
          />

          <OnChange name="odoo_debtor_id">
            {(value) => {
              handleDebtorSelect(value);
            }}
          </OnChange>
        </FormGroup>
      </Col>

      <Col md="4">
        <FormGroup>
          <Field
            name="odoo_payment_term_id"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={formatDropdownOptions(paymentTerms)}
            labelTranslation="attributes.paymentTerm"
            isLoading={isPaymentTermFetching}
          />
        </FormGroup>
      </Col>

      <Col md="4">
        <FormGroup>
          <Field
            name="invoice_period"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={invoicePeriodOptions}
            labelTranslation="attributes.invoicePeriod"
          />
        </FormGroup>
      </Col>

      <Col md="4">
        <FormGroup>
          <Field
            name="termination_term"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={terminationTermOptions}
            labelTranslation="attributes.terminationTerm"
          />
        </FormGroup>
      </Col>

      <Col md="4">
        <FormGroup>
          <Field
            name="start_date"
            component={renderDateField}
            validate={required}
            labelTranslation="attributes.startDate"
          />
        </FormGroup>
      </Col>

      <Col md="12">
        <Row>
          <Col md="4">
            <FormGroup>
              <Field
                name="is_billable"
                component={ToggleButtonField}
                labelTranslation="attributes.billable"
                className="d-block"
                type="checkbox"
              />
            </FormGroup>
          </Col>

          <Col md="4">
            <FormGroup>
              <Field
                name="is_voip_billing_enabled"
                component={ToggleButtonField}
                labelTranslation="attributes.billVoipUsages"
                className="d-block"
                type="checkbox"
              />
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

BasicInfoSection.defaultProps = {
  isDebtorFetching: false,
  isPaymentTermFetching: false,
};

BasicInfoSection.propTypes = {
  debtors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  paymentTerms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isDebtorFetching: PropTypes.bool,
  isPaymentTermFetching: PropTypes.bool,
};

export default BasicInfoSection;
