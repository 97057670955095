import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import { t, I18n, Translate } from "react-i18nify";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

import { formatDropdownOptions } from "utils/utils";

import ContextPropertyApi from "apis/ContextProperty";

import { FORM_TYPES } from "constants/form";

import AddOnSubscriptionDetail from "components/CellPhone/Form/AddOnSubscription/AddOnSubscriptionDetail";
import SubscriptionDetailHeader from "components/CellPhone/Form/AddOnSubscription/SubscriptionDetailHeader";
import { Spinner } from "reactstrap";
import { getSubscriptionLineType } from "utils/subscription";

const CONTEXT_TYPE = {
  ADD_ONS: 2,
  SETUP: 3,
};

const CONTEXT = {
  CELL_NUMBER: 2,
};

const EmptyAddOnSubscription = ({ show }) => {
  if (!show) return null;

  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center flex-column flex-fill py-2 table-body">
      <div className="info-icon-wrapper border-danger mb-2">
        <FontAwesomeIcon className="info-icon text-danger" icon={faInfo} />
      </div>
      <p className="add-on-subscription-empty-text fw-bold">
        <Translate value="cellPhone.pleaseSelectTheSubscriptionToAdd" />
      </p>
    </div>
  );
};

const SubscriptionHeader = ({ show }) => {
  if (!show) return null;

  return (
    <p className="mb-2 fw-bold add-on-subscription-title">
      <Translate value="cellPhone.addOnSubscriptions" />
    </p>
  );
};

const ProductSelectSection = ({
  isLoading,
  fields,
  handleSubscriptionSection,
  filteredProducts,
}) => {
  const showAddOnInputClassName = fields.length === 0 ? "d-none" : "d-flex";
  const productSelectSection = isLoading ? (
    <Spinner size="sm" />
  ) : (
    <I18n
      render={() => (
        <Select
          id="addon-subscription"
          options={formatDropdownOptions(filteredProducts)}
          styles={{
            loadingIndicator: (provided) => ({
              ...provided,
              color: "black",
            }),
          }}
          onChange={(options) => {
            let dropdownValue;

            if (Array.isArray(options)) {
              dropdownValue = options.map((opt) => opt.value);
            } else {
              dropdownValue = options?.value;
            }

            handleSubscriptionSection(dropdownValue);
          }}
          value={null}
          placeholder={t("label.dropdown.chooseProduct")}
        />
      )}
    />
  );

  return (
    <div
      className={`add-on-subscription-footer border-top-0 px-3 ${showAddOnInputClassName} align-items-center py-3`}
    >
      <p className="mb-0 fw-bold">
        <Translate value="attributes.addANewSubscription" />
      </p>

      <div className="add-on-subscription-field ms-2">
        {productSelectSection}
      </div>
    </div>
  );
};

ProductSelectSection.propTypes = {
  fields: PropTypes.shape({
    push: PropTypes.func,
    length: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pop: PropTypes.func,
  }).isRequired,
  filteredProducts: PropTypes.arrayOf(
    PropTypes.shape({
      context: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      context_type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleSubscriptionSection: PropTypes.func.isRequired,
};

const AddOnSubscription = ({ fields, products, formType }) => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isLoading, setLoader] = useState(false);

  useEffect(() => {
    const filterOutProductList = [];

    products.forEach(({ context, context_type: contextType, ...rest }) => {
      if (
        Number(context) === CONTEXT.CELL_NUMBER &&
        [CONTEXT_TYPE.ADD_ONS, CONTEXT_TYPE.SETUP].includes(Number(contextType))
      ) {
        if (Number(contextType) === CONTEXT_TYPE.SETUP) {
          const { name, ...restProps } = rest;

          filterOutProductList.push({
            context,
            context_type: contextType,
            name: (
              <>
                {name}
                <span className="setup px-3 ms-2">
                  <Translate value="common.setup" />
                </span>
              </>
            ),
            ...restProps,
          });
        } else {
          filterOutProductList.push({
            context,
            context_type: contextType,
            ...rest,
          });
        }
      }
    });

    setFilteredProducts(filterOutProductList);
  }, [products]);

  const handleSubscriptionSection = (value) => {
    setLoader(true);
    const selectedProduct = products.find(({ id }) => id === value);

    const {
      display_name: displayName,
      list_price: price,
      id,
      context_properties_ids: contextPropertiesIDs,
      context_type: contextTypeID,
    } = selectedProduct;

    ContextPropertyApi.fetchAll(id, contextPropertiesIDs)
      .then(({ data: contextProperties }) => {
        const subscriptionLineProperties = contextProperties.map(
          (contextProperty) => {
            const { property, value: propertyValue } = contextProperty;

            const subscriptionLineProperty = {
              property,
              value: propertyValue,
            };

            return subscriptionLineProperty;
          }
        );

        const lineType = getSubscriptionLineType(contextTypeID);

        const subscriptionForPayload = {
          product_name: displayName,
          price,
          odoo_product_id: id,
          line_type: lineType,
          subscription_line_properties_attributes: subscriptionLineProperties,
          context_type: contextTypeID,
        };

        fields.push(subscriptionForPayload);

        setLoader(false);
      })
      .catch((err) => {
        toastr.error(t("common.error"), err.message);
        setLoader(false);
      });
  };

  if (formType === FORM_TYPES.EDIT) return null;

  const enabledClassName = fields.length === 0 ? "pe-none" : "";
  const addOnSubscriptionLoadingClassName = isLoading
    ? "justify-content-center"
    : "";

  return (
    <div className={`add-on-subscription ${enabledClassName}`}>
      <div className=" overflow-auto col-12">
        <SubscriptionHeader show={fields.length === 0} />
        <table className="table table-borderless add-on-subscription-container mb-0">
          <thead className="accordion-header">
            <SubscriptionDetailHeader
              showTitle={fields.length !== 0}
              isProductAdded={fields.length > 1}
            />
          </thead>
          <tbody
            className={`border-bottom-0  ${addOnSubscriptionLoadingClassName} px-3 py-0 `}
          >
            <EmptyAddOnSubscription show={fields.length === 1 && !isLoading} />
            <AddOnSubscriptionDetail
              show={fields.length > 1}
              fields={fields}
              isLoading={isLoading}
            />
          </tbody>
        </table>
      </div>

      <ProductSelectSection
        isLoading={isLoading}
        filteredProducts={filteredProducts}
        fields={fields}
        handleSubscriptionSection={handleSubscriptionSection}
      />
    </div>
  );
};

EmptyAddOnSubscription.defaultProps = {
  show: false,
};

EmptyAddOnSubscription.propTypes = {
  show: PropTypes.bool,
};

SubscriptionHeader.defaultProps = {
  show: true,
};

SubscriptionHeader.propTypes = {
  show: PropTypes.bool,
};

AddOnSubscription.defaultProps = {
  formType: FORM_TYPES.CREATE,
};

AddOnSubscription.propTypes = {
  fields: PropTypes.shape({
    push: PropTypes.func,
    length: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pop: PropTypes.func,
  }).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      context: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      context_type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  formType: PropTypes.string,
};

export default AddOnSubscription;
