import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";
import { Translate } from "react-i18nify";
import { useLocation, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

import {
  searchQuotations,
  quotationSearchRequest,
  quotationFetchRequest,
  quotationPreviewRequest,
} from "redux/actions/quotation";

import { QUOTATION_FILTERS } from "constants/filters";

import filterSearchResults from "utils/filterSearchResults";
import { getParams } from "utils/queryString";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import ResourceHeader from "components/ResourceHeader/ResourceHeader";
import PreviewFileModal from "components/Quotation/Detail/PreviewFileModal";

const renderQuotationPreviewButton = ({ rowData, handleQuotationPreview }) => {
  const { id: selectedQuotationID } = rowData;

  return (
    <button
      className="btn btn-outline-primary p-2 d-flex m-auto"
      type="button"
      aria-label="Preview Quotation Button"
      onClick={(event) => handleQuotationPreview(event, selectedQuotationID)}
      data-field-name="preview-quotation-btn"
    >
      <FontAwesomeIcon icon={faFilePdf} />
    </button>
  );
};

const QuotationIndex = ({
  fetchAllQuotations,
  previewQuotation,
  quotations,
  isLoading,
  searchRecords,
  isSearching,
  fetchError,
  quotationPreview,
}) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const { status = "sent" } = getParams(search);

  const [isModalVisible, setModalVisibility] = useState(false);

  useEffect(() => {
    const searchParams = `?status=${status}`;

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath, { replace: true });
  }, [status]);

  useEffect(() => {
    fetchAllQuotations(status);
  }, [status]);

  const changeFilter = ({ statusType }) => {
    const searchParams = `?status=${statusType}`;

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath, { replace: true });
  };

  const allFilters = [
    {
      filter: QUOTATION_FILTERS,
      selectedFilter: status,
      type: "statusType",
      headerTranslation: "filterType.status",
    },
  ];

  const handleQuotationPreview = (event, previewQuotationID) => {
    event.stopPropagation();

    previewQuotation(previewQuotationID);

    setModalVisibility((prevState) => !prevState);
  };

  return (
    <>
      <RecordListingTable
        records={quotations}
        isLoading={isLoading && !fetchError}
        fetchError={fetchError}
        onRetry={() => fetchAllQuotations(status)}
        resourceName="quotations"
        additionalHeaderContent={
          <ResourceHeader changeFilter={changeFilter} allFilters={allFilters} />
        }
        resourceTitle={<Translate value="quotation.quotationOverview" />}
        searchRecords={searchRecords}
        isSearching={isSearching}
        className="text-center"
        dataCy="create-quotation"
      >
        <Column
          width={200}
          label={<Translate value="attributes.number" />}
          dataKey="reference_number"
        />
        <Column
          width={200}
          label={<Translate value="attributes.debtor" />}
          dataKey="debtor_name"
        />
        <Column
          width={200}
          label={<Translate value="attributes.seller" />}
          dataKey="seller_full_name"
        />
        <Column
          width={180}
          label={<Translate value="attributes.totalAmount" />}
          dataKey="total_amount"
        />
        <Column
          width={160}
          label={<Translate value="attributes.preview" />}
          cellRenderer={({ rowData }) =>
            renderQuotationPreviewButton({ rowData, handleQuotationPreview })
          }
        />
      </RecordListingTable>
      <PreviewFileModal
        modalVisible={isModalVisible}
        filePreview={quotationPreview}
        title={<Translate value="quotation.preview" />}
        onCancel={() => setModalVisibility((prevState) => !prevState)}
      />
    </>
  );
};

QuotationIndex.defaultProps = {
  isLoading: true,
  quotations: undefined,
  isSearching: false,
  fetchError: null,
  quotationPreview: undefined,
};

QuotationIndex.propTypes = {
  quotations: PropTypes.arrayOf(
    PropTypes.shape({
      ref_number: PropTypes.string,
      debtor_name: PropTypes.string,
      seller_name: PropTypes.string,
      total_amount: PropTypes.string,
    })
  ),
  searchRecords: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  fetchAllQuotations: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  fetchError: PropTypes.string,
  previewQuotation: PropTypes.func.isRequired,
  quotationPreview: PropTypes.node,
};

const mapStateToProps = (state) => {
  const { quotations, isLoading, isSearching, fetchError, quotationPreview } =
    state.quotation;

  const results = filterSearchResults({
    resource: quotations,
    searchResult: state.search.quotations,
  });

  return {
    quotations: results,
    isLoading,
    isSearching,
    fetchError,
    quotationPreview,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchRecords: (e) => {
    dispatch(quotationSearchRequest(e));
    dispatch(searchQuotations(e));
  },
  fetchAllQuotations: (filter) => {
    dispatch(quotationFetchRequest(filter));
  },
  previewQuotation: (id) => {
    dispatch(quotationPreviewRequest(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuotationIndex);
