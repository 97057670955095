import React from "react";
import PropTypes from "prop-types";
import { Navigate, Route, Routes } from "react-router-dom";

import {
  Admins,
  ADMIN_ROLES,
  BusinessAdmins,
  BusinessUsersWithPbxAdmin,
  BusinessUsers,
} from "constants/userRoles";
import {
  EDIT,
  NEW,
  DEBTOR,
  DETAILS,
  AGREEMENTS,
  PBXS,
  CONTACTS,
  INVOICES,
  CREDIT_NOTES,
} from "constants/routes/routesResources";
import { DEBTOR as DEBTOR_ROUTES } from "constants/routes";

import { getCurrentUserRole } from "utils/selectors";

import DebtorDetail from "views/Debtor/DebtorDetail/DebtorDetail";
import DebtorEdit from "views/Debtor/DebtorEdit";
import DebtorCreate from "views/Debtor/DebtorCreate";
import DebtorIndex from "views/Debtor/DebtorIndex";
import AgreementCreate from "views/Agreement/AgreementCreate";
import PbxCreate from "views/Pbx/PbxCreate";
import ContactIndex from "views/Contact/ContactIndex";
import AgreementIndex from "views/Agreement/AgreementIndex";
import InvoiceIndex from "views/Invoice/InvoiceIndex";
import PbxIndex from "views/Pbx/PbxIndex";
import CreditNotesIndex from "views/CreditNotes/CreditNotesIndex";

const AdminDebtorIndex = Admins(DebtorIndex);
const AdminDebtorCreate = Admins(DebtorCreate);
const AdminPbxCreate = Admins(PbxCreate);
const BusinessAdminInvoiceIndex = BusinessAdmins(InvoiceIndex);
const BusinessAdminCreditNotes = BusinessAdmins(CreditNotesIndex);
const BusinessAdminDebtorEdit = BusinessAdmins(DebtorEdit);
const PbxAdminDebtorDetail = BusinessUsersWithPbxAdmin(DebtorDetail);
const BusinessUserContactIndex = BusinessUsers(ContactIndex);

const UsersDebtorsRoutes = () => (
  <Routes>
    <Route index element={<Navigate to={DETAILS} replace />} />
    <Route path={DETAILS} element={<PbxAdminDebtorDetail />} />
    <Route path={CONTACTS} element={<BusinessUserContactIndex />} />
    <Route path={AGREEMENTS} element={<AgreementIndex />} />
    <Route path={INVOICES} element={<BusinessAdminInvoiceIndex />} />
    <Route path={CREDIT_NOTES} element={<BusinessAdminCreditNotes />} />
    <Route path={PBXS} element={<PbxIndex />} />
  </Routes>
);

const DebtorRoutes = ({ reduxStore }) => {
  const currentUserRole = getCurrentUserRole(reduxStore.getState());
  const isAdmin = ADMIN_ROLES.includes(currentUserRole);

  return (
    <Routes>
      <Route index element={<AdminDebtorIndex />} />
      <Route path={NEW} element={<AdminDebtorCreate />} />
      <Route
        path={`${DEBTOR.DEBTOR_ID}/*`}
        element={isAdmin ? <DebtorDetail /> : <UsersDebtorsRoutes />}
      >
        <Route index element={<Navigate to={DETAILS} replace />} />
      </Route>

      <Route
        path={`${DEBTOR.DEBTOR_ID}/${EDIT}`}
        element={<BusinessAdminDebtorEdit />}
      />
      <Route
        path={DEBTOR_ROUTES.NEW_AGREEMENTS}
        element={<AgreementCreate />}
      />
      <Route path={DEBTOR_ROUTES.NEW_PBXS} element={<AdminPbxCreate />} />
    </Routes>
  );
};

DebtorRoutes.propTypes = {
  reduxStore: PropTypes.shape({
    getState: PropTypes.func,
  }).isRequired,
};

export default DebtorRoutes;
