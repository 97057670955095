import React from "react";

import renderInputField from "./InputField/InputField";
import renderDropdownField from "./DropDownField/DropDownField";
import renderTimeField from "./TimeField/TimeField";
import renderDateField from "./DateField/DateField";
import renderOptions from "./OptionsField/OptionsField";
import PriceField from "./PriceField/PriceField";

const renderPriceInputField = (props) => (
  <PriceField
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

export {
  renderInputField,
  renderDropdownField,
  renderOptions,
  renderDateField,
  renderTimeField,
  renderPriceInputField,
};
