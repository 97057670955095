import React from "react";
import { actions } from "react-redux-toastr";
import { Translate } from "react-i18nify";
import { store } from "redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

const toasterId = "network-error-toastr";

export const displayNetworkErrorToaster = () => {
  store.dispatch(
    actions.add({
      id: toasterId,
      type: "error",
      title: <Translate value="toasterMessage.networkError" />,
      position: "top-right",
      options: {
        timeOut: 0,
        removeOnHover: false,
        closeOnToastrClick: false,
        component: (
          <button
            className="btn bg-white"
            type="button"
            onClick={() => window.location.reload()}
          >
            <FontAwesomeIcon className="mx-2" icon={faSync} />
            <Translate value="common.reload" />
          </button>
        ),
      },
    })
  );
};

export const removeNetworkErrorToaster = () => {
  store.dispatch(actions.remove(toasterId));
};
