import React, { useState } from "react";
import PropTypes from "prop-types";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Translate } from "react-i18nify";

import SubscriptionLineTabContent from "components/Agreement/AgreementDetail/SubscriptionLine/SubscriptionLineTabContent";
import TranslateNumber from "components/i18n/TranslateNumber";

const SubscriptionLineTab = ({ subscriptionLines, isSubscriptionArchived }) => {
  const [activeTab, setActiveTab] = useState("active");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const subscriptionLineTabSections = [
    {
      title: <Translate value="common.active" />,
      tabID: "active",
    },
    {
      title: <Translate value="attributes.isArchived" />,
      tabID: "archived",
    },
  ];

  const activeLines = subscriptionLines.filter(
    (subscriptionLine) => subscriptionLine.is_archived === false
  );
  const archivedLines = subscriptionLines.filter(
    (subscriptionLine) => subscriptionLine.is_archived === true
  );

  const subscriptionLineTabs = subscriptionLineTabSections.map(
    (subscriptionDetailSection) => {
      const { title, tabID } = subscriptionDetailSection;
      const lineCount =
        tabID === "active" ? activeLines.length : archivedLines.length;

      return (
        <NavItem key={tabID} className={{ active: activeTab === tabID }}>
          <NavLink
            className="py-4 px-5 d-flex"
            onClick={() => {
              toggleTab(tabID);
            }}
          >
            {title}
            <span className="ms-2 badge bg-primary rounded-circle">
              <TranslateNumber value={lineCount} minimumFractionDigits={0} />
            </span>
          </NavLink>
        </NavItem>
      );
    }
  );

  const subscriptionLinesTabContent = subscriptionLineTabSections.map(
    (subscriptionDetailSection) => {
      const { tabID } = subscriptionDetailSection;

      return (
        <TabPane tabId={tabID} key={tabID}>
          <SubscriptionLineTabContent
            subscriptionLines={subscriptionLines}
            isSubscriptionArchived={isSubscriptionArchived}
            isArchived={tabID === "archived"}
          />
        </TabPane>
      );
    }
  );

  return (
    <div className="br-all shadow-sm mt-3">
      <Nav tabs className="custom-nav-tab m-0">
        {subscriptionLineTabs}
      </Nav>
      <TabContent
        activeTab={activeTab}
        className="subscription-detail-tab-content shadow-sm mb-5"
      >
        {subscriptionLinesTabContent}
      </TabContent>
    </div>
  );
};

SubscriptionLineTab.propTypes = {
  subscriptionLines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSubscriptionArchived: PropTypes.bool.isRequired,
};

export default SubscriptionLineTab;
