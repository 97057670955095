import React from "react";
import { Field } from "react-final-form";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import {
  bindingPeriodSubscriptionType,
  quotationSubscriptionBindingPeriodInMonths,
} from "constants/dropdownOptions";

import { required, requiredForNumber } from "utils/validation";

import { renderDropdownField } from "components/ReduxForm/RenderField";

const SubscriptionBindingPeriodTableRow = ({
  bindingPeriod,
  fields,
  currentIndex,
}) => {
  const { id, _destroy: isDestroyed } = bindingPeriod;

  const handleRemoveBindingPeriod = () => {
    fields.remove(currentIndex);

    if (id) {
      fields.push({ id, _destroy: true });
    }
  };

  if (isDestroyed) {
    return null;
  }

  return (
    <tr>
      <td className="align-middle text-center py-2">
        <Field
          name={`quotation_subscription_binding_periods[${currentIndex}].subscription_type`}
          component={renderDropdownField}
          dropdownOptions={bindingPeriodSubscriptionType}
          validate={required}
          placeholderTranslation="quotation.bindingPeriod.chooseSubscriptionType"
          hideLabel
        />
      </td>

      <td className="align-middle py-2">
        <Field
          name={`quotation_subscription_binding_periods[${currentIndex}].binding_period_in_months`}
          component={renderDropdownField}
          dropdownOptions={quotationSubscriptionBindingPeriodInMonths}
          validate={requiredForNumber}
          placeholderTranslation="quotation.bindingPeriod.chooseBindingPeriodInMonths"
          hideLabel
        />
      </td>

      <td className="align-middle">
        <button
          className="border border-danger rounded p-2 text-danger bg-transparent"
          type="button"
          onClick={() => handleRemoveBindingPeriod()}
          data-cy={`quotation_subscription_binding_periods[${currentIndex}].delete-btn`}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </td>
    </tr>
  );
};

SubscriptionBindingPeriodTableRow.defaultProps = {};

SubscriptionBindingPeriodTableRow.propTypes = {
  fields: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.shape({
        subscription_type: PropTypes.string,
        binding_period_in_months: PropTypes.number,
      })
    ),
    push: PropTypes.func,
    remove: PropTypes.func,
  }).isRequired,
  bindingPeriod: PropTypes.shape({
    id: PropTypes.number,
    _destroy: PropTypes.bool,
    subscription_type: PropTypes.string,
    binding_period_in_months: PropTypes.number,
  }).isRequired,
  currentIndex: PropTypes.number.isRequired,
};

export default SubscriptionBindingPeriodTableRow;
