import { toastr } from "react-redux-toastr";

import { t } from "react-i18nify";

import { CellPhoneApi } from "internal";

import {
  displayFetchErrorToaster,
  getServerError,
  getAndDisplayErrors,
} from "utils/utils";
import displayUnMappedFormErrors from "utils/displayUnMappedFormErrors";

export const SINGLE_CELL_PHONE_FETCH_REQUEST =
  "SINGLE_CELL_PHONE_FETCH_REQUEST";
export const SINGLE_CELL_PHONE_FETCH_RECEIVED =
  "SINGLE_CELL_PHONE_FETCH_RECEIVED";
export const SINGLE_CELL_PHONE_FETCH_FAILED = "SINGLE_CELL_PHONE_FETCH_FAILED";
export const SINGLE_CELL_PHONE_DELETE_REQUEST =
  "SINGLE_CELL_PHONE_DELETE_REQUEST";
export const SINGLE_CELL_PHONE_DELETE_RECEIVED =
  "SINGLE_CELL_PHONE_DELETE_RECEIVED";
export const SINGLE_CELL_PHONE_DELETE_FAILED =
  "SINGLE_CELL_PHONE_DELETE_FAILED";
export const CELL_PHONE_UPDATE_REQUEST = "CELL_PHONE_UPDATE_REQUEST";
export const CELL_PHONE_UPDATE_RECEIVED = "CELL_PHONE_UPDATE_RECEIVED";
export const CELL_PHONE_UPDATE_FAILED = "CELL_PHONE_UPDATE_FAILED";
export const CELL_PHONE_CREATE_REQUEST = "CELL_PHONE_CREATE_REQUEST";
export const CELL_PHONE_CREATE_RECEIVED = "CELL_PHONE_CREATE_RECEIVED";
export const CELL_PHONE_CREATE_FAILED = "CELL_PHONE_CREATE_FAILED";
export const CELL_PHONE_FETCH_CLEAR_ERRORS = "CELL_PHONE_FETCH_CLEAR_ERRORS";

export const SEARCH_PHONE_NUMBER_REQUEST = "SEARCH_PHONE_NUMBER_REQUEST";
export const SEARCH_PHONE_NUMBER_RECEIVED = "SEARCH_PHONE_NUMBER_RECEIVED";
export const SEARCH_PHONE_NUMBER_ERROR = "SEARCH_PHONE_NUMBER_ERROR";

export const SUSPEND_CELL_PHONE_REQUEST = "SUSPEND_CELL_PHONE_REQUEST";
export const SUSPEND_CELL_PHONE_RECEIVED = "SUSPEND_CELL_PHONE_RECEIVED";
export const SUSPEND_CELL_PHONE_ERROR = "SUSPEND_CELL_PHONE_ERROR";

export const REACTIVATE_CELL_PHONE_REQUEST = "REACTIVATE_CELL_PHONE_REQUEST";
export const REACTIVATE_CELL_PHONE_RECEIVED = "REACTIVATE_CELL_PHONE_RECEIVED";
export const REACTIVATE_CELL_PHONE_ERROR = "REACTIVATE_CELL_PHONE_ERROR";

export const ORDER_SIM_CARD_REQUEST = "ORDER_SIM_CARD_REQUEST";
export const ORDER_SIM_CARD_RECEIVED = "ORDER_SIM_CARD_RECEIVED";
export const ORDER_SIM_CARD_ERROR = "ORDER_SIM_CARD_ERROR";

export const ASSIGN_NEW_SIM_CARD_REQUEST = "ASSIGN_NEW_SIM_CARD_REQUEST";
export const ASSIGN_NEW_SIM_CARD_RECEIVED = "ASSIGN_NEW_SIM_CARD_RECEIVED";
export const ASSIGN_NEW_SIM_CARD_ERROR = "ASSIGN_NEW_SIM_CARD_ERROR";

export const NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_DELETE_REQUEST =
  "NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_DELETE_REQUEST";
export const NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_DELETE_RECEIVED =
  "NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_DELETE_RECEIVED";
export const NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_DELETE_FAILED =
  "NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_DELETE_FAILED";

export const NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_CREATE_REQUEST =
  "NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_CREATE_REQUEST";
export const NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_CREATE_RECEIVED =
  "NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_CREATE_RECEIVED";
export const NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_CREATE_FAILED =
  "NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_CREATE_FAILED";

export function cellPhoneFetchClearErrors() {
  return {
    type: CELL_PHONE_FETCH_CLEAR_ERRORS,
  };
}

// Fetch single cellPhone
export function singleCellPhoneFetchReceived(response) {
  return {
    type: SINGLE_CELL_PHONE_FETCH_RECEIVED,
    cellPhone: response.data,
  };
}

export function singleCellPhoneFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: SINGLE_CELL_PHONE_FETCH_FAILED,
    message,
  };
}

export const singleCellPhoneFetchRequest = (id) => (dispatch) => {
  dispatch({ type: SINGLE_CELL_PHONE_FETCH_REQUEST });

  CellPhoneApi.fetchOne(id)
    .then((data) => dispatch(singleCellPhoneFetchReceived(data)))
    .catch((e) => {
      dispatch(singleCellPhoneFetchFailed(e));
    });
};

// Update a cellPhone
export function cellPhoneUpdateReceived(response) {
  return {
    type: CELL_PHONE_UPDATE_RECEIVED,
    cellPhone: response.data,
  };
}

export function cellPhoneUpdateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response.data) {
    displayUnMappedFormErrors(response);
    callback(response.data);
  }

  return {
    type: CELL_PHONE_UPDATE_FAILED,
    message: response.data,
  };
}

export const cellPhoneUpdateRequest =
  (id, attributes, callback) => async (dispatch) => {
    dispatch({ type: CELL_PHONE_UPDATE_REQUEST });

    await CellPhoneApi.update(id, attributes)
      .then((data) => {
        dispatch(cellPhoneUpdateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(cellPhoneUpdateFailed(errors, callback));
      });
  };

// Create a cellPhone
export function cellPhoneCreateReceived(response) {
  return {
    type: CELL_PHONE_CREATE_RECEIVED,
    cellPhone: response.data,
  };
}

export function cellPhoneCreateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: CELL_PHONE_CREATE_FAILED,
    message: response.data,
  };
}

export const cellPhoneCreateRequest =
  (attributes, callback) => async (dispatch) => {
    dispatch({ type: CELL_PHONE_CREATE_REQUEST });

    await CellPhoneApi.create(attributes)
      .then((data) => {
        dispatch(cellPhoneCreateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(cellPhoneCreateFailed(errors, callback));
      });
  };

// delete single cellphone
export const singleCellPhoneDeleteReceived = (response, cb) => (dispatch) => {
  dispatch({
    type: SINGLE_CELL_PHONE_DELETE_RECEIVED,
  });

  toastr.success(
    t("common.success"),
    t("toasterMessage.resource.delete", {
      resource: t("attributes.cellPhoneNumber"),
    })
  );

  if (typeof cb === "function") {
    cb();
  }
};

export const singleCellPhoneDeleteFailed = (errorData) => {
  const { response } = errorData;
  const error = getServerError(response);
  const errorText =
    error === "not found" ? t("toasterMessage.processError") : error;

  displayFetchErrorToaster(errorText);

  return (dispatch) => {
    dispatch({
      type: SINGLE_CELL_PHONE_DELETE_FAILED,
      message: errorText,
    });
  };
};

export const singleCellPhoneDeleteRequest = (id, cb) => (dispatch) => {
  dispatch({
    type: SINGLE_CELL_PHONE_DELETE_REQUEST,
  });

  CellPhoneApi.deleteOne(id)
    .then((data) => dispatch(singleCellPhoneDeleteReceived(data, cb)))
    .catch((e) => {
      dispatch(singleCellPhoneDeleteFailed(e));
    });
};

export const searchPhoneNumberReceived = (data) => ({
  type: SEARCH_PHONE_NUMBER_RECEIVED,
  phoneNumbers: data,
});

export const searchPhoneNumberError = (errorData) => {
  const { response } = errorData;
  const error = getServerError(response);

  return {
    type: SEARCH_PHONE_NUMBER_ERROR,
    message: error,
  };
};

export const searchPhoneNumberRequest = (phoneNumber) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_PHONE_NUMBER_REQUEST });

    const response = await CellPhoneApi.searchPhoneNumber(phoneNumber);

    dispatch(searchPhoneNumberReceived(response.data));
  } catch (error) {
    dispatch(searchPhoneNumberError(error));
  }
};

export function suspendCellPhoneReceived(data) {
  return (dispatch) => {
    dispatch({
      type: SUSPEND_CELL_PHONE_RECEIVED,
      cellPhone: data,
    });

    toastr.success(
      t("common.success"),
      t("toasterMessage.cellPhone.cellPhoneSuspended")
    );
  };
}

export function suspendCellPhoneError(errorData) {
  const { response } = errorData;
  const error = getServerError(response);

  return {
    type: SUSPEND_CELL_PHONE_ERROR,
    message: error,
  };
}

export const suspendCellPhoneRequest = (id, attributes) => async (dispatch) => {
  try {
    dispatch({ type: SUSPEND_CELL_PHONE_REQUEST });

    const response = await CellPhoneApi.suspend(id, attributes);

    dispatch(suspendCellPhoneReceived(response.data));
  } catch (error) {
    dispatch(suspendCellPhoneError(error));
  }
};

export function reactivateCellPhoneReceived(data) {
  return (dispatch) => {
    dispatch({
      type: REACTIVATE_CELL_PHONE_RECEIVED,
      cellPhone: data,
    });

    toastr.success(
      t("common.success"),
      t("toasterMessage.cellPhone.cellPhoneReactivated")
    );
  };
}

export function reactivateCellPhoneError(errorData) {
  const { response } = errorData;
  const error = getServerError(response);

  displayFetchErrorToaster(error);

  return {
    type: REACTIVATE_CELL_PHONE_ERROR,
    message: error,
  };
}

export const reactivateCellPhoneRequest = (id) => async (dispatch) => {
  try {
    dispatch({ type: REACTIVATE_CELL_PHONE_REQUEST });

    const response = await CellPhoneApi.reactivate(id);

    dispatch(reactivateCellPhoneReceived(response.data));
  } catch (error) {
    dispatch(reactivateCellPhoneError(error));
  }
};

export function orderSimCardReceived(data) {
  return (dispatch) => {
    dispatch({
      type: ORDER_SIM_CARD_RECEIVED,
      cellPhone: data,
    });

    toastr.success(
      t("common.success"),
      t("toasterMessage.cellPhone.newSimCardRequested")
    );
  };
}

export function orderSimCardError(errorData) {
  const { response } = errorData;
  const error = getServerError(response);

  return {
    type: ORDER_SIM_CARD_ERROR,
    message: error,
  };
}

export const orderSimCardRequest = (id) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_SIM_CARD_REQUEST });

    const response = await CellPhoneApi.orderSimCard(id);

    dispatch(orderSimCardReceived(response.data));
  } catch (error) {
    dispatch(orderSimCardError(error));
  }
};

export function assignNewSimCardReceived(data, cb = () => {}) {
  toastr.success(
    t("common.success"),
    t("toasterMessage.cellPhone.newSimCardAssignedSuccessfully")
  );

  cb();

  return {
    type: ASSIGN_NEW_SIM_CARD_RECEIVED,
    cellPhone: data,
  };
}

export function assignNewSimCardError(errorData) {
  const { response } = errorData;
  const error = getServerError(response);

  displayFetchErrorToaster(error);

  return {
    type: ASSIGN_NEW_SIM_CARD_ERROR,
    message: error,
  };
}

export const assignNewSimCardRequest =
  (id, attributes, cb) => async (dispatch) => {
    try {
      dispatch({ type: ASSIGN_NEW_SIM_CARD_REQUEST });

      const response = await CellPhoneApi.assignNewSimCard(id, attributes);

      dispatch(assignNewSimCardReceived(response.data, cb));
    } catch (error) {
      dispatch(assignNewSimCardError(error));
    }
  };

// delete network service provisioned to a cell phone
export function networkOperatorSubscriptionServiceDeleteReceived(response, cb) {
  cb();

  return {
    type: NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_DELETE_RECEIVED,
  };
}

export function networkOperatorSubscriptionServiceDeleteFailed(errorData) {
  const { response } = errorData;

  const serverErrors = getServerError(response);

  return {
    type: NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_DELETE_FAILED,
    message: serverErrors,
  };
}

export const networkOperatorSubscriptionServiceDeleteRequest =
  (id, cb) => (dispatch) => {
    dispatch({ type: NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_DELETE_REQUEST });

    CellPhoneApi.networkOperatorSubscriptionServiceDelete(id)
      .then((data) =>
        dispatch(networkOperatorSubscriptionServiceDeleteReceived(data, cb))
      )
      .catch((e) => {
        dispatch(networkOperatorSubscriptionServiceDeleteFailed(e));
      });
  };

// create/provision network service to a cell phone
export function networkOperatorSubscriptionServiceCreateReceived(response, cb) {
  const { data: createdNetworkService } = response;

  cb(createdNetworkService);

  return {
    type: NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_CREATE_RECEIVED,
    networkService: createdNetworkService,
  };
}

export function networkOperatorSubscriptionServiceCreateFailed(
  errorData,
  callback = () => {}
) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_CREATE_FAILED,
    message: response.data,
  };
}

export const networkOperatorSubscriptionServiceCreateRequest =
  (id, attributes, networkServiceCreateCallback, callback) =>
  async (dispatch) => {
    dispatch({ type: NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_CREATE_REQUEST });

    await CellPhoneApi.networkOperatorSubscriptionServiceCreate(id, attributes)
      .then((data) => {
        dispatch(
          networkOperatorSubscriptionServiceCreateReceived(
            data,
            networkServiceCreateCallback,
            callback
          )
        );
        callback();
      })
      .catch((errors) => {
        dispatch(
          networkOperatorSubscriptionServiceCreateFailed(errors, callback)
        );
      });
  };
