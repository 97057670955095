import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.apiEndPoint()}/businesses`,
};

class BusinessApi extends AuthenticatedRequest {
  /**
   * Fetch all businesses accessible to users
   *
   */
  static fetchAll() {
    return this.get(URLS.INDEX);
  }
}

export default BusinessApi;
