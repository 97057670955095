const ROLE_ID = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  RESELLER_ADMIN: 3,
  CUSTOMER_ADMIN: 4,
  CUSTOMER_USER: 5,
  PBX_ADMIN: 6,
  PBX_USER: 7,
};

export default ROLE_ID;
