import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Admins } from "constants/userRoles";
import {
  EDIT,
  NEW,
  NUMBER_PORTING_REQUEST,
} from "constants/routes/routesResources";

import NumberPortingRequestIndex from "views/NumberPortingRequest/NumberPortingRequestIndex";
import ImportCreate from "views/NumberPortingRequest/Import/ImportCreate";
import ImportEdit from "views/NumberPortingRequest/Import/ImportEdit";
import ImportDetail from "views/NumberPortingRequest/Import/ImportDetail";
import ExportDetail from "views/NumberPortingRequest/Export/ExportDetail";
import ImportCustomerForm from "views/NumberPortingRequest/Import/ImportCustomerForm";
import ImportIndex from "views/NumberPortingRequest/Import/ImportIndex";
import ExportIndex from "views/NumberPortingRequest/Export/ExportIndex";

const AdminNumberPortingRequestIndex = Admins(NumberPortingRequestIndex);
const AdminImportCreate = Admins(ImportCreate);
const AdminImportEdit = Admins(ImportEdit);
const AdminImportDetail = Admins(ImportDetail);
const AdminExportDetail = Admins(ExportDetail);

const NumberPortingRequestRoutes = () => (
  <Routes>
    <Route path="/" element={<AdminNumberPortingRequestIndex />}>
      <Route path={NUMBER_PORTING_REQUEST.IMPORTS} element={<ImportIndex />} />
      <Route path={NUMBER_PORTING_REQUEST.EXPORTS} element={<ExportIndex />} />
      <Route
        index
        element={<Navigate to={NUMBER_PORTING_REQUEST.IMPORTS} replace />}
      />
    </Route>

    <Route path={NUMBER_PORTING_REQUEST.IMPORTS}>
      <Route path={NEW} element={<AdminImportCreate />} />
      <Route path={NUMBER_PORTING_REQUEST.REF_NUMBER}>
        <Route index element={<AdminImportDetail />} />
        <Route path={EDIT} element={<AdminImportEdit />} />
        <Route
          path={NUMBER_PORTING_REQUEST.CUSTOMER_FORM}
          element={<ImportCustomerForm />}
        />
      </Route>
    </Route>

    <Route path={NUMBER_PORTING_REQUEST.EXPORTS}>
      <Route path={NUMBER_PORTING_REQUEST.REF_NUMBER}>
        <Route index element={<AdminExportDetail />} />
      </Route>
    </Route>
  </Routes>
);

export default NumberPortingRequestRoutes;
