import React from "react";
import Skeleton from "react-loading-skeleton";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";

import HeaderSkeleton from "components/Skeleton/HeaderSkeleton";

const Setting = ({ className }) => {
  return (
    <div
      className={`${className} d-lg-flex align-items-center justify-content-between border-bottom border-gray-100`}
    >
      <div className="d-flex flex-column mb-3">
        <Skeleton height={30} width={150} className="mb-2" />
        <Skeleton height={18} width={450} />
        <Skeleton height={18} width={350} />
      </div>
      <div className="my-3">
        <Skeleton height={40} width={450} />
      </div>
    </div>
  );
};

Setting.defaultProps = {
  className: "",
};

Setting.propTypes = {
  className: PropTypes.string,
};

const UserSettingSkeleton = ({ withHeader }) => (
  <div className="container-fluid">
    {withHeader && <HeaderSkeleton hasEdit={false} />}
    <Row>
      <Col lg="3">
        <div className="d-flex flex-column">
          <Skeleton height={20} width={130} className="mb-2" />
          <Skeleton height={20} width={100} className="mb-2" />
          <Skeleton height={20} width={180} className="mb-4" />
        </div>
      </Col>
      <Col
        lg="9"
        className=" p-3 bg-white rounded shadow-sm border-bottom border-gray-100"
      >
        <Setting />
        <Setting className="mt-3" />
        <div className="my-3 text-right">
          <Skeleton height={40} width={100} />
        </div>
      </Col>
    </Row>
  </div>
);

UserSettingSkeleton.defaultProps = {
  withHeader: true,
};

UserSettingSkeleton.propTypes = {
  withHeader: PropTypes.bool,
};

export default UserSettingSkeleton;
