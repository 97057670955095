import React from "react";
import { t, Translate } from "react-i18nify";
import { toastr } from "react-redux-toastr";

import { getSubscriptionLineType } from "utils/subscription";

import Pill from "components/Pill/Pill";

const formatDropdownOptions = (records, dropdownText = "name") =>
  records.map((record) => {
    let cloneRecord = record;

    if (typeof record === "string") {
      cloneRecord = { id: record, name: record };
    }

    const { id, name } = cloneRecord;
    const textToDisplay = cloneRecord[dropdownText];

    const option = {
      value: id,
      label: textToDisplay || name,
    };

    return option;
  });

const formatSetupTypeDropdownOptions = (records) =>
  records.map((record) => {
    const { id, name, context_type: contextType } = record;
    const subscriptionLineType = getSubscriptionLineType(contextType);

    let textToDisplay = name;

    if (subscriptionLineType === "setup") {
      textToDisplay = (
        <div className="d-flex align-items-center">
          {name}
          <Pill
            show
            content={<Translate value="pill.status.setup" />}
            className="bg-green px-2 ms-2"
          />
        </div>
      );
    }

    const option = {
      value: id,
      label: textToDisplay,
    };

    return option;
  });

const formatUserLicensesDropdownOptions = (
  records,
  attributesToConcat,
  dropdownText = "name"
) => {
  if (!attributesToConcat.length) return null;

  return records.map((record) => {
    let cloneRecord = record;
    let concatenatedLabel;

    if (attributesToConcat.length === 2) {
      const firstKey = attributesToConcat[0];
      const secondKey = attributesToConcat[1];

      concatenatedLabel = record[firstKey].concat(
        " ",
        `(${record[secondKey]})`
      );
    }

    cloneRecord = { id: record.id, name: concatenatedLabel };

    const { id, name } = cloneRecord;
    const textToDisplay = cloneRecord[dropdownText];

    const option = {
      value: id,
      label: textToDisplay || name,
    };

    return option;
  });
};

const getServerError = (response) => {
  const { status, statusText, data } = response || {};

  switch (status) {
    case 422: {
      if (typeof data === "string") return data;
      if (data.status === 400) return statusText;
      const attributes = [];

      Object.keys(data).forEach((key) => {
        const stringWithoutSeparator = key.replace(/[^a-z A-Z 0-9]/g, " ");
        const errorHeading =
          stringWithoutSeparator[0].toUpperCase() +
          stringWithoutSeparator.substr(1);

        attributes.push(`${errorHeading} ${data[key][0]}`);
      });

      return attributes;
    }

    case 403:
      return t("toasterMessage.unauthorized");

    case 400:
    case 404:
      return data || statusText;

    case 500:
      return statusText;

    default:
      return t("toasterMessage.requestFailed");
  }
};

const displayFetchErrorToaster = (error) => {
  if (error && Array.isArray(error)) {
    const errorList = error.map((errorText) =>
      toastr.error(t("common.error"), errorText)
    );

    return errorList;
  }

  return toastr.error(t("common.error"), error);
};

const getAndDisplayErrors = (message) => {
  const { response } = message;
  const { status } = response;
  const error = getServerError(response);

  if (status !== 401) {
    displayFetchErrorToaster(error);
  }

  return error;
};

const getPlaceholderText = ({
  label,
  placeholder,
  placeholderTranslation,
  labelTranslation,
  replacementTranslations = {},
}) => {
  if (placeholder) return placeholder;
  const translatePlaceholderText = placeholderTranslation || labelTranslation;

  if (translatePlaceholderText) {
    const replacements = {};

    Object.keys(replacementTranslations).forEach((v) => {
      replacements[v] = t(replacementTranslations[v]);
    });

    return t(translatePlaceholderText, replacements);
  }

  return label;
};

const quotationStyle = () => ({
  control: (provided) => ({
    ...provided,
    padding: "0.7em",
  }),
});

const dropdownFieldStyle = () => ({
  control: (provided) => ({
    ...provided,
    padding: "0.7em",
  }),
});

const sortObjectArray = (objectArray, attribute) =>
  objectArray.sort((a, b) => {
    if (a[attribute] > b[attribute]) {
      return 1;
    }

    if (a[attribute] < b[attribute]) {
      return -1;
    }

    return 0;
  });

/**
 *
 * @param {Object} allFormFieldsValue - All form fields value updated by react-final-form
 * @param {Object} form - All the required properties and methods provided by react-final-form
 * @returns {Object} - Filtered value that contains all fields which are dirty/updated/touched
 */
const getDirtyFieldsValue = (allFormFieldsValue, form) => {
  const filteredFieldsValue = {};
  const dirtyFormFields = form.getState().dirtyFields;

  Object.keys(allFormFieldsValue).forEach((fieldKey) => {
    if (dirtyFormFields[fieldKey] || allFormFieldsValue[fieldKey])
      filteredFieldsValue[fieldKey] = allFormFieldsValue[fieldKey];
  });

  return filteredFieldsValue;
};

export {
  formatDropdownOptions,
  formatSetupTypeDropdownOptions,
  formatUserLicensesDropdownOptions,
  getServerError,
  getAndDisplayErrors,
  displayFetchErrorToaster,
  getPlaceholderText,
  quotationStyle,
  dropdownFieldStyle,
  sortObjectArray,
  getDirtyFieldsValue,
};
