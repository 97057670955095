import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";
import { Translate } from "react-i18nify";

import {
  vendorsFetchRequest,
  searchVendors,
  vendorsSearchRequest,
} from "redux/actions/vendor";

import filterSearchResults from "utils/filterSearchResults";

import HasPermission from "hoc/RbacCTA";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";

const VendorsIndex = ({
  fetchVendors,
  vendors,
  isLoading,
  isSearching,
  searchRecords,
  fetchError,
}) => {
  useEffect(() => {
    fetchVendors();
  }, []);

  return (
    <HasPermission perform="vendors:list" redirect>
      <RecordListingTable
        isSearching={isSearching}
        searchRecords={searchRecords}
        records={vendors}
        isLoading={isLoading}
        hideAddButton
        resourceName="vendors"
        resourceTitle={
          <Translate value="title.page.resource.list" resource="Vendors" />
        }
        fetchError={fetchError}
        onRetry={fetchVendors}
      >
        <Column
          label={<Translate value="attributes.customerNumber" />}
          dataKey="customer_number"
          width={195}
        />
        <Column
          width={300}
          label={<Translate value="attributes.name" />}
          dataKey="name"
        />
        <Column
          width={300}
          label={<Translate value="attributes.address" />}
          dataKey="address"
        />
        <Column
          width={200}
          label={<Translate value="attributes.email" />}
          dataKey="email"
        />
        <Column
          width={170}
          label={<Translate value="attributes.phone" />}
          dataKey="phone"
        />
      </RecordListingTable>
    </HasPermission>
  );
};

VendorsIndex.defaultProps = {
  isLoading: true,
  vendors: undefined,
  isSearching: false,
  fetchError: null,
};

VendorsIndex.propTypes = {
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      customerNumber: PropTypes.string,
    })
  ),
  searchRecords: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  fetchVendors: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  fetchError: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { vendors, fetchError } = state.vendor;
  const results = filterSearchResults({
    resource: vendors,
    searchResult: state.search.vendors,
  });

  return {
    vendors: results,
    isLoading: state.vendor.isLoading,
    isSearching: state.vendor.isSearching,
    fetchError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchRecords: (e) => {
    dispatch(vendorsSearchRequest(e));
    dispatch(searchVendors(e));
  },
  fetchVendors: () => {
    dispatch(vendorsFetchRequest());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorsIndex);
