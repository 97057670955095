import { BusinessApi } from "internal";
import { getAndDisplayErrors } from "utils/utils";

export const BUSINESS_FETCH_REQUEST = "BUSINESS_FETCH_REQUEST";
export const BUSINESS_FETCH_RECEIVED = "BUSINESS_FETCH_RECEIVED";
export const BUSINESS_FETCH_FAILED = "BUSINESS_FETCH_FAILED";
export const BUSINESS_FETCH_CLEAR_ERRORS = "BUSINESS_FETCH_CLEAR_ERRORS";

// Fetch all business user has access to
export function businessFetchReceived(response) {
  return {
    type: BUSINESS_FETCH_RECEIVED,
    businesses: response.data,
  };
}

export function businessFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: BUSINESS_FETCH_FAILED,
    message,
  };
}

export const businessFetchRequest = () => (dispatch) => {
  dispatch({ type: BUSINESS_FETCH_REQUEST });

  BusinessApi.fetchAll()
    .then((data) => dispatch(businessFetchReceived(data)))
    .catch((e) => {
      dispatch(businessFetchFailed(e));
    });
};
