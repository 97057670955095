import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { dashboardInvoiceFetchRequest } from "redux/actions/dashboard/invoice";

import DashboardComponent from "components/Dashboard/DashboardComponent/DashboardComponent";
import InvoiceItem from "components/Dashboard/DashboardItem/InvoiceItem";

import { DASHBOARD_COMPONENT_FEATURE_TYPES } from "constants/constant";

const InvoiceView = ({ fetchInvoice, invoice, isLoading, fetchError }) => {
  useEffect(() => {
    fetchInvoice();
  }, [fetchInvoice]);

  return (
    <DashboardComponent
      translationTitle="menuItem.invoice"
      isLoading={isLoading || !invoice}
      fetchError={fetchError}
      onRetry={fetchInvoice}
      featureType={DASHBOARD_COMPONENT_FEATURE_TYPES.INVOICES}
    >
      <InvoiceItem item={invoice} />
    </DashboardComponent>
  );
};

InvoiceView.defaultProps = {
  fetchInvoice: () => {},
  invoice: null,
  isLoading: false,
  fetchError: false,
};

InvoiceView.propTypes = {
  fetchInvoice: PropTypes.func,
  invoice: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  fetchError: PropTypes.bool,
};

const mapStateToProps = ({ dashboard }) => ({
  ...dashboard.invoice,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInvoice: () => dispatch(dashboardInvoiceFetchRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceView);
