import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Outlet, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import { singleDebtorFetchRequest } from "redux/actions/debtor";

import BillsIndex from "views/Bills/BillsIndex";

import HasPermission from "hoc/RbacCTA";

import WholePageSpinner from "components/Spinner/WholePageSpinner";
import DebtorBasicDetail from "components/Debtor/Detail/BasicDetail/DebtorBasicDetail";
import DebtorAdditionalDetail from "components/Debtor/Detail/AdditionalDetail/AdditionalDetail";
import TabWithRoute from "components/Tab/TabWithRoute/TabWithRoute";
import FetchError from "components/Error/FetchError";

const renderDebtorAdditionalDetail = ({ vendorID, debtor }) => (
  <DebtorAdditionalDetail debtorID={vendorID} debtor={debtor} />
);

const VendorDetail = ({ fetchSingleDebtor, debtor, isLoading, fetchError }) => {
  const location = useLocation();
  const { vendorID } = useParams();

  const fetchDebtor = () => {
    fetchSingleDebtor(vendorID);
  };

  useEffect(() => {
    fetchDebtor();
  }, [vendorID]);

  if (fetchError) {
    return <FetchError onRetry={fetchDebtor} />;
  }

  if (isLoading || !debtor) {
    return <WholePageSpinner />;
  }

  const getSharedRouteComponent = () => {
    const vendorDetailsRoute = `/vendors/${vendorID}`;

    if (location.pathname.includes(`${vendorDetailsRoute}/details`))
      return <DebtorAdditionalDetail debtorID={vendorID} debtor={debtor} />;

    if (location.pathname.includes(`${vendorDetailsRoute}/bills`))
      return <BillsIndex parentID={vendorID} />;

    return <Outlet />;
  };

  const tabRoutesDetail = [
    { tabRoute: "details", tabTitle: <Translate value="common.detail" /> },
    { tabRoute: "bills", tabTitle: <Translate value="menuItem.bills" /> },
  ];

  return (
    <HasPermission
      perform="vendors:view"
      data={{ debtorID: vendorID }}
      redirect
    >
      <div className="debtor-detail container-fluid w-100">
        <DebtorBasicDetail debtor={debtor} />
        <HasPermission
          perform="debtors:tabs"
          no={() => renderDebtorAdditionalDetail({ debtor, vendorID })}
        >
          <TabWithRoute resourceName="vendor" routesDetail={tabRoutesDetail}>
            {getSharedRouteComponent()}
          </TabWithRoute>
        </HasPermission>
      </div>
    </HasPermission>
  );
};

VendorDetail.defaultProps = {
  isLoading: true,
  debtor: undefined,
  fetchError: null,
};

VendorDetail.propTypes = {
  debtor: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    customer_number: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    phone: PropTypes.string,
    status: PropTypes.string,
    address: PropTypes.string,
    website: PropTypes.string,
    created_at: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  fetchSingleDebtor: PropTypes.func.isRequired,
  fetchError: PropTypes.string,
};

const mapStateToProps = ({ debtor: debtorResource }) => {
  const { debtor, isLoading, fetchError } = debtorResource;

  return {
    debtor,
    isLoading,
    fetchError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleDebtor: (id) => {
    dispatch(singleDebtorFetchRequest(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorDetail);
