import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { Admins } from "constants/userRoles";
import { INDEX, BILLS, DETAILS } from "constants/routes/routesResources";

import Bills from "views/Bills/BillsIndex";
import InvoiceDetail from "views/Invoice/InvoiceDetail/InvoiceDetail";

const AdminBills = Admins(Bills);
const AdminInvoiceDetail = Admins(InvoiceDetail);

const BillsRoute = () => (
  <Routes>
    <Route path={INDEX} element={<AdminBills />} />
    <Route path={BILLS.BILL_ID}>
      <Route index element={<Navigate to={DETAILS} replace />} />
      <Route path={DETAILS} element={<AdminInvoiceDetail />} />
    </Route>
  </Routes>
);

export default BillsRoute;
