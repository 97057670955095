import apiUrlConfig from "configs/apiUrlConfig";
import { AuthenticatedRequest } from "internal";

const URLS = {
  CELL_PHONE_SERVICES: `${apiUrlConfig.apiEndPoint()}/cell_phone_services`,
};

class CellPhoneServicesApi extends AuthenticatedRequest {
  /**
   *
   * @returns network services available to provision to a cell phone
   */
  static fetchAll() {
    return this.get(URLS.CELL_PHONE_SERVICES);
  }
}

export default CellPhoneServicesApi;
