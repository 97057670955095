import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Translate } from "react-i18nify";

import getNameInitials from "utils/user";

import TranslateNumber from "components/i18n/TranslateNumber";
import TranslateDate from "components/i18n/TranslateDate";

const SubscriptionDetail = ({
  subscriptionProduct,
  subscription,
  products,
}) => {
  const {
    start_date: startDate,
    end_date: endDate,
    product_name: productName,
    last_invoice_date: lastInvoiceDate,
    odoo_product_id: odooProductID,
    subscription_line_properties: subscriptionLineProperties,
  } = subscriptionProduct;

  const linePropertiesWithoutTopic = subscriptionLineProperties.filter(
    (lineProperty) => lineProperty.property !== "Topic"
  );

  const propertiesContent = linePropertiesWithoutTopic.map((lineProperty) => {
    const { id: linePropertyID, property, value } = lineProperty;

    return (
      <p className="mb-2" key={linePropertyID}>{`${property}: ${value}`}</p>
    );
  });

  const {
    start_date: subscriptionStartDate,
    end_date: subscriptionTerminationDate,
    monthly_price: monthlyPrice,
    setup_price: setupPrice,
  } = subscription;

  const productForSubscription = products.find(
    (product) => product.id === odooProductID
  );
  const { ref_number: productRefNumber } = productForSubscription;

  const subscriptionInfo = [
    {
      title: <Translate value="attributes.startDate" />,
      content: <TranslateDate date={subscriptionStartDate} />,
    },
    {
      title: <Translate value="attributes.terminationDate" />,
      content: <TranslateDate date={subscriptionTerminationDate} />,
    },
    {
      title: <Translate value="attributes.monthlyPrice" />,
      content: (
        <TranslateNumber value={monthlyPrice} minimumFractionDigits={2} />
      ),
    },
    {
      title: <Translate value="common.setupPrice" />,
      content: <TranslateNumber value={setupPrice} minimumFractionDigits={2} />,
    },
  ];

  const subscriptionProductInfo = [
    {
      title: <Translate value="attributes.startDate" />,
      content: <TranslateDate date={startDate} />,
    },
    {
      title: <Translate value="attributes.lastInvoiceDate" />,
      content: <TranslateDate date={lastInvoiceDate} />,
    },
    {
      title: <Translate value="attributes.terminationDate" />,
      content: <TranslateDate date={endDate} />,
    },
  ];

  const subscriptionInfoListContent = subscriptionInfo.map((item) => {
    const { title, content } = item;

    return (
      <Col md={3}>
        <div className="subscription-info d-flex flex-column">
          <div className="subscription-info-title">
            <h5 className="mb-0">{title}</h5>
          </div>

          <div className="d-flex align-items-center justify-content-center flex-1 subscription-info-content">
            <p className="mb-0">{content}</p>
          </div>
        </div>
      </Col>
    );
  });

  const subscriptionProductInfoContent = subscriptionProductInfo.map((item) => {
    const { title, content } = item;

    return (
      <Col md={2}>
        <div className="subscription-info subscription-product-info d-flex flex-column">
          <div className="subscription-info-title subscription-product-info-title">
            <h5 className="mb-0">{title}</h5>
          </div>

          <div className="d-flex align-items-center justify-content-center flex-1 subscription-info-content">
            <p className="mb-0 p-2">{content}</p>
          </div>
        </div>
      </Col>
    );
  });

  return (
    <div className="subscription-detail p-4 mb-3">
      <div className="subscription-info-container mb-3">
        <Row>{subscriptionInfoListContent}</Row>
      </div>

      <Row>
        <Col md="12">
          <div className="header fw-bold mb-3">
            <Translate value="title.tab.agreement.subscriptionModal.subscriptionProduct" />
          </div>
        </Col>

        <Col md="3">
          <div className="subscription-product-name text-center subscription-product-property p-2">
            <div>
              <div className="user-short-name mb-3 mx-auto">
                {getNameInitials(productName)}
              </div>
              <h5 className="mb-1">{`[${productRefNumber}] ${productName}`}</h5>
            </div>
          </div>
        </Col>

        {subscriptionProductInfoContent}

        <Col md="3">
          <div className="subscription-info subscription-product-info d-flex flex-column">
            <div className="subscription-info-title subscription-product-info-title">
              <h5 className="mb-0">
                <Translate value="subscription.productProperties" />
              </h5>
            </div>

            <div className="d-flex align-items-center justify-content-center flex-1 subscription-info-content">
              <p className="mb-0 p-2">{propertiesContent}</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

SubscriptionDetail.defaultProps = {};

SubscriptionDetail.propTypes = {
  subscriptionProduct: PropTypes.shape({
    start_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    end_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    product_name: PropTypes.string,
    last_invoice_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    odoo_product_id: PropTypes.number,
    subscription_line_properties: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  subscription: PropTypes.shape({
    start_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    end_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    monthly_price: PropTypes.number,
    setup_price: PropTypes.number,
  }).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SubscriptionDetail;
