import React, { useEffect, useRef } from "react";

import { Tooltip } from "bootstrap";
import PropTypes from "prop-types";

const CustomTooltip = ({ children, title, show, className }) => {
  const tooltipRef = useRef();
  const newTooltip = useRef();

  useEffect(() => {
    if (!show) return;

    /* eslint-disable-next-line no-new */
    newTooltip.current = new Tooltip(tooltipRef.current, {
      title: show ? title : "",
      placement: "right",
      trigger: "hover",
      offset: [0, 0],
      template:
        '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner px-3"></div></div>',
    });
  }, [show]);

  useEffect(() => {
    if (show || !newTooltip.current) return;
    newTooltip.current.dispose();
  }, [show]);

  return (
    <div ref={tooltipRef} className={className} role="tooltip">
      {children}
    </div>
  );
};

CustomTooltip.defaultProps = {
  children: <></>,
  title: "",
  className: "",
  show: true,
};

CustomTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  show: PropTypes.bool,
  className: PropTypes.string,
};

export default CustomTooltip;
