import React from "react";
import UnauthorizedPage from "components/Auth/UnauthorizedPage";

const UnAuthorizedView = () => (
  <div className="h-100 d-flex justify-content-center">
    <UnauthorizedPage />
  </div>
);

export default UnAuthorizedView;
