import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarTimes } from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-i18nify";

import {
  singleDidNumberFetchRequest,
  singleDidNumberDeleteRequest,
} from "redux/actions/didNumber";

import { DID_NUMBER, PBX, USER_LICENSE } from "constants/routes";

import DidNumberDetailSkeleton from "components/Skeleton/ResourceDetail/DidNumberDetail/DidNumberDetailSkeleton";
import DidNumberBasicDetail from "components/DidNumber/Detail/DidNumberBasicDetail";
import FaxServiceDetail from "components/DidNumber/Detail/FaxServiceDetail";
import DetailUIHeader from "components/Header/DetailUI/DetailUIHeader";
import FetchError from "components/Error/FetchError";
import DeleteModal from "components/Modal/DeleteModal";
import AssignToUserLicenseModal from "components/Modal/AssignToUserLicenseModal";

const MODAL_TYPES = {
  DELETE: "delete modal",
  ASSIGN_TO_USER_LICENSE: "assign did number to user license modal",
};

const AssignToUserLicense = ({
  userLicenseID,
  setModalType,
  modalType,
  didNumber,
}) => {
  if (userLicenseID) return null;

  return (
    <>
      <button
        className="btn btn-primary me-2"
        type="button"
        aria-label="Assign to User License Button"
        onClick={() => setModalType(MODAL_TYPES.ASSIGN_TO_USER_LICENSE)}
      >
        <Translate value="common.assignToUserLicense" />
      </button>
      <AssignToUserLicenseModal
        modalVisible={modalType === MODAL_TYPES.ASSIGN_TO_USER_LICENSE}
        onCancel={() => setModalType(null)}
        didNumber={didNumber}
      />
    </>
  );
};

const DidNumberDetail = ({
  didNumber,
  fetchSingleDidNumber,
  deleteDidNumber,
  fetchError,
  currentlyLoggedInUser,
}) => {
  const { didNumberID } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const fetchDidNumberDetail = () => {
    fetchSingleDidNumber(didNumberID);
  };

  const [modalData, setModalData] = useState();
  const [modalType, setModalType] = useState(null);

  useEffect(() => {
    fetchDidNumberDetail();
  }, []);

  const toggle = () => {
    setModalData(didNumberID);
    setModalType((previousState) =>
      previousState ? null : MODAL_TYPES.DELETE
    );
  };

  const resetModalState = () => {
    setModalType(null);
    setModalData();
  };

  const onDeleteSuccess = () => {
    const { user_license_id: userLicenseID, pbx_id: pbxID } = didNumber;

    const goBackUrl = userLicenseID
      ? USER_LICENSE.addId(USER_LICENSE.DID_NUMBERS, userLicenseID)
      : PBX.addId(PBX.DID_NUMBERS, pbxID);

    navigate(goBackUrl, { replace: true });
  };

  const onConfirmDelete = (id) => {
    if (id) deleteDidNumber(id, onDeleteSuccess);

    resetModalState();
  };

  if (fetchError) {
    return <FetchError onRetry={fetchDidNumberDetail} />;
  }

  if (
    !didNumber ||
    (didNumber &&
      (didNumber.id !== parseInt(didNumberID, 10) ||
        !didNumber.subscription_line))
  ) {
    return <DidNumberDetailSkeleton />;
  }

  const {
    fax_service_enabled: faxServiceEnabled,
    phone_number: phoneNumber,
    is_active: isActive,
    user_license_id: userLicenseID,
  } = didNumber;

  const additionalHeaderContent = !isActive && (
    <span className="is-deactivated ms-3 px-2 py-2">
      <FontAwesomeIcon icon={faCalendarTimes} className="me-1" />
      <Translate value="attributes.isDeactivated" />
    </span>
  );

  return (
    <div className="didNumber-detail w-100">
      <DeleteModal
        modalVisible={modalType === MODAL_TYPES.DELETE}
        modalData={modalData}
        resourceName="did number"
        toggleModal={toggle}
        onConfirm={onConfirmDelete}
        onCancel={resetModalState}
      />
      <DetailUIHeader
        headerTitle={phoneNumber}
        editPath={DID_NUMBER.addId(DID_NUMBER.EDIT, didNumberID)}
        readOnly={!isActive}
        additionalHeaderContent={additionalHeaderContent}
        deletePerform="didNumber:delete"
        historyState={state}
        handleDelete={toggle}
        hasDelete
        additionalHeaderButtons={
          <AssignToUserLicense
            didNumber={didNumber}
            modalType={modalType}
            setModalType={setModalType}
            userLicenseID={userLicenseID}
          />
        }
        additionalHeaderButtonsPerform="didNumber:update"
      />
      <DidNumberBasicDetail
        didNumber={didNumber}
        currentlyLoggedInUser={currentlyLoggedInUser}
      />
      {faxServiceEnabled && <FaxServiceDetail didNumber={didNumber} />}
    </div>
  );
};

AssignToUserLicense.defaultProps = {};

AssignToUserLicense.propTypes = {
  userLicenseID: PropTypes.number.isRequired,
  setModalType: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
  didNumber: PropTypes.number.isRequired,
};

DidNumberDetail.defaultProps = {
  fetchError: null,
};

DidNumberDetail.propTypes = {
  didNumber: PropTypes.shape({
    id: PropTypes.number,
    phone_number: PropTypes.string,
    fax_service_enabled: PropTypes.bool,
    subscription_line: PropTypes.shape({}),
    is_active: PropTypes.bool,
    pbx_id: PropTypes.number,
    user_license_id: PropTypes.number,
  }).isRequired,
  fetchSingleDidNumber: PropTypes.func.isRequired,
  deleteDidNumber: PropTypes.func.isRequired,
  fetchError: PropTypes.string,
  currentlyLoggedInUser: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = ({ didNumber: didNumberResource, auth }) => {
  const { didNumber, fetchError } = didNumberResource;
  const { currentUser: currentlyLoggedInUser } = auth;

  return {
    didNumber,
    fetchError,
    currentlyLoggedInUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleDidNumber: (id) => {
    dispatch(singleDidNumberFetchRequest(id));
  },
  deleteDidNumber: (id, cb) => {
    dispatch(singleDidNumberDeleteRequest(id, cb));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DidNumberDetail);
