import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.apiEndPoint()}/agreements/:id/invoices`,
  RUN_INVOICING: `${apiUrlConfig.apiEndPoint()}/agreements/:id/run_invoicing`,
};

class InvoiceApi extends AuthenticatedRequest {
  /**
   * Fetch all invoices of an agreement
   *
   * @param {int} agreementID id of an agreement
   *
   */
  static fetchAll(agreementID) {
    const indexUrl = URLS.INDEX.replace(":id", agreementID);

    return this.get(indexUrl);
  }

  /**
   * Run invoicing for an agreement
   *
   * @param {int} agreementID id of an agreement
   *
   */
  static runInvoicing(agreementID) {
    const runInvoicingUrl = URLS.RUN_INVOICING.replace(":id", agreementID);

    return this.get(runInvoicingUrl);
  }
}

export default InvoiceApi;
