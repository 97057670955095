import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { FormGroup, Row, Col } from "reactstrap";
import PropTypes from "prop-types";

import { Field, useField, useForm } from "react-final-form";

import { Translate, t } from "react-i18nify";

import {
  required,
  minValue,
  maxValue,
  composeValidators,
} from "utils/validation";
import { useSubscriptionTerminationDate } from "utils/customHooks";

import { pbxUserRolesFetchRequest } from "redux/actions/role";
import { fetchDebtorLanguageRequest } from "redux/actions/debtor";

import USER_ROLES from "constants/userRoles";

import {
  renderInputField,
  renderDateField,
} from "components/ReduxForm/RenderField";
import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";
import UserSection from "components/UserLicense/Form/UserSection";
import VoicemailBox, {
  FIELD_TYPE,
} from "components/VoicemailBox/Form/VoicemailBox";

const minExtensionValue = minValue(200);
const maxExtensionValue = maxValue(99999);
const numberRangeNotAllowed = (value) =>
  value && /9([0-9]{2})$/i.test(value)
    ? t("validationErrors.EXTENSION_PATTERN_RESTRICTED")
    : null;

const UserLicenseForm = ({
  pristine,
  submitting,
  formType,
  isRolesLoading,
  fetchPbxUserRoles,
  dropdownPbxRoles,
  isLoading,
  terminationDateEndLimit,
  subscriptionLineId,
  currentUserRole,
  odooDebtorId,
  fetchDebtorLanguage,
  isDebtorLanguageLoading,
}) => {
  const {
    input: { value: deploymentDate },
  } = useField("deployment_date");

  const {
    input: { value: invoiceStartDate },
  } = useField("invoice_start_date");

  const {
    meta: { dirty: isVoicemailDirty, touched: voicemailBoxEmailTouched },
  } = useField("voicemail_box.email");

  const { change: updateUserLicenseFormField } = useForm();

  const isVoicemailBoxEmailDirty =
    (isVoicemailDirty && voicemailBoxEmailTouched) || voicemailBoxEmailTouched;

  useEffect(() => {
    fetchPbxUserRoles();
  }, []);

  useEffect(() => {
    if (odooDebtorId) {
      fetchDebtorLanguage(odooDebtorId);
    }
  }, [odooDebtorId]);

  const onEmailChangeHandler = (value) => {
    if (!isVoicemailBoxEmailDirty && formType === "create") {
      updateUserLicenseFormField("voicemail_box.email", value.target.value);
    }
  };

  const dropdownRoles =
    dropdownPbxRoles.length > 0
      ? dropdownPbxRoles.map((role) => ({
          id: role.id,
          value: role.id,
          label: role.name,
        }))
      : [];

  const { subscriptionTerminationEndRange, isTerminationDateLoading } =
    useSubscriptionTerminationDate(subscriptionLineId);

  const terminationDateEndRange =
    terminationDateEndLimit ||
    subscriptionTerminationEndRange ||
    invoiceStartDate ||
    deploymentDate;

  const todayDate = new Date();
  const terminationDateDisabledDays =
    terminationDateEndRange && new Date(terminationDateEndRange) > todayDate
      ? new Date(terminationDateEndRange)
      : todayDate;

  const minimumBeforeDate =
    currentUserRole === USER_ROLES.SUPER_ADMIN ? "" : new Date();

  const subscriptionSection =
    formType === "edit" ? (
      <Row>
        <Col md="6">
          <FormGroup>
            <Field
              name="termination_date"
              component={renderDateField}
              labelTranslation="attributes.terminationDate"
              disabledDays={{
                before: terminationDateDisabledDays,
              }}
              isLoadingDate={isTerminationDateLoading}
            />
          </FormGroup>
        </Col>
      </Row>
    ) : (
      <>
        <Row>
          <Col md="6">
            <FormGroup>
              <Field
                name="deployment_date"
                component={renderDateField}
                validate={required}
                labelTranslation="attributes.deploymentDate"
                disabledDays={{ before: deploymentDate || minimumBeforeDate }}
              />
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <Field
                name="invoice_start_date"
                component={renderDateField}
                validate={required}
                labelTranslation="attributes.invoiceStartDate"
                disabledDays={{
                  before:
                    (deploymentDate && new Date(deploymentDate)) ||
                    minimumBeforeDate,
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </>
    );

  return (
    <>
      <div className="pbx-content">
        <Row>
          <Col md="6">
            <FormGroup>
              <Field
                name="extension_number"
                component={renderInputField}
                validate={composeValidators(
                  required,
                  minExtensionValue,
                  maxExtensionValue,
                  numberRangeNotAllowed
                )}
                labelTranslation="attributes.extensionNumber"
                placeholderTranslation="attributes.extensionNumber"
                type="number"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h4 className="mt-3 mb-3">
              <Translate value="attributes.user" />
            </h4>
            <hr />
          </Col>

          <Col md="12">
            <UserSection
              formType={formType}
              dropdownPbxRoles={dropdownRoles}
              isRolesLoading={isRolesLoading}
              onEmailChangeHandler={onEmailChangeHandler}
              isLanguageFieldLoading={isDebtorLanguageLoading}
              fieldType={FIELD_TYPE.FINAL_FORM}
            />
          </Col>
        </Row>
        <VoicemailBox formType={formType} />

        <Row>
          <Col md="12">
            <h4 className="mt-3 mb-3">
              <Translate value="title.section.subscription" />
            </h4>

            <hr />
          </Col>
        </Row>
        {subscriptionSection}
      </div>
      <div className="flexo-form-footer">
        <ReduxFormCommitButton
          title={<Translate value="common.save" />}
          submitting={submitting}
          pristine={pristine}
          isLoading={isLoading || isTerminationDateLoading}
        />
      </div>
    </>
  );
};

UserLicenseForm.defaultProps = {
  formType: "create",
  terminationDateEndLimit: undefined,
  subscriptionLineId: null,
  isDebtorLanguageLoading: false,
};

UserLicenseForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  fetchDebtorLanguage: PropTypes.func.isRequired,
  isRolesLoading: PropTypes.bool.isRequired,
  fetchPbxUserRoles: PropTypes.func.isRequired,
  dropdownPbxRoles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool.isRequired,
  formType: PropTypes.string,
  terminationDateEndLimit: PropTypes.instanceOf(Date),
  subscriptionLineId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentUserRole: PropTypes.string.isRequired,
  odooDebtorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  isDebtorLanguageLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { pbxRoles: dropdownPbxRoles, isLoading: isRolesLoading } = state.role;
  const { pbx } = state.pbx;

  const {
    currentUser: { role: currentUserRole },
  } = state.auth;

  return {
    dropdownPbxRoles,
    isRolesLoading,
    currentUserRole,
    odooDebtorId: pbx?.odoo_debtor_id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPbxUserRoles: () => dispatch(pbxUserRolesFetchRequest()),
  fetchDebtorLanguage: (debtorID) =>
    dispatch(fetchDebtorLanguageRequest(debtorID)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UserLicenseForm
);
