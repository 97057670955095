import React from "react";
import { Route, Routes } from "react-router-dom";

import { Admins } from "constants/userRoles";
import { EDIT, NEW, QUOTATION } from "constants/routes/routesResources";

import QuotationIndex from "views/Quotation/QuotationIndex";
import QuotationCreate from "views/Quotation/QuotationCreate";
import QuotationDetail from "views/Quotation/QuotationDetail";
import QuotationEdit from "views/Quotation/QuotationEdit";
import ConvertQuotationToAgreement from "views/Quotation/ConvertQuotationToAgreement";

const AdminQuotationIndex = Admins(QuotationIndex);
const AdminQuotationCreate = Admins(QuotationCreate);
const AdminQuotationDetail = Admins(QuotationDetail);
const AdminQuotationEdit = Admins(QuotationEdit);
const AdminConvertQuotationToAgreement = Admins(ConvertQuotationToAgreement);

const QuotationRoutes = () => (
  <Routes>
    <Route index element={<AdminQuotationIndex />} />
    <Route path={NEW} element={<AdminQuotationCreate />} />

    <Route path={QUOTATION.QUOTATION_ID}>
      <Route index element={<AdminQuotationDetail />} />
      <Route path={EDIT} element={<AdminQuotationEdit />} />
      <Route
        path={QUOTATION.CONVERT_TO_AGREEMENT}
        element={<AdminConvertQuotationToAgreement />}
      />
    </Route>
  </Routes>
);

export default QuotationRoutes;
