import React from "react";
import { FormGroup } from "reactstrap";
import { Field } from "react-final-form";
import PropTypes from "prop-types";

import { renderDateField } from "components/ReduxForm/RenderField";

const TerminationDateField = ({
  terminationDateEndLimit,
  invoiceStartDate,
  deploymentDate,
  isTerminationDateLoading,
  subscriptionTerminationEndRange,
}) => {
  const terminationDateEndRange =
    terminationDateEndLimit ||
    subscriptionTerminationEndRange ||
    invoiceStartDate ||
    deploymentDate;
  const todayDate = new Date();
  const terminationDateDisabledDays =
    terminationDateEndRange && new Date(terminationDateEndRange) > todayDate
      ? new Date(terminationDateEndRange)
      : todayDate;

  return (
    <FormGroup>
      <Field
        name="termination_date"
        component={renderDateField}
        labelTranslation="attributes.terminationDate"
        disabledDays={{
          before: terminationDateDisabledDays,
        }}
        isLoadingDate={isTerminationDateLoading}
      />
    </FormGroup>
  );
};

TerminationDateField.defaultProps = {
  terminationDateEndLimit: undefined,
  isTerminationDateLoading: undefined,
  subscriptionTerminationEndRange: undefined,
};

TerminationDateField.propTypes = {
  terminationDateEndLimit: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  invoiceStartDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  deploymentDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  isTerminationDateLoading: PropTypes.bool,
  subscriptionTerminationEndRange: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
};

export default TerminationDateField;
