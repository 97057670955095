import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.odooEndPoint()}/debtors`,
  CVR: `${apiUrlConfig.odooEndPoint()}/debtors_cvr_data`,
  SHOW: `${apiUrlConfig.odooEndPoint()}/debtors/:id`,
  UPDATE: `${apiUrlConfig.odooEndPoint()}/debtors/:id`,
  CREATE: `${apiUrlConfig.odooEndPoint()}/debtors`,
  AGREEMENTS: `${apiUrlConfig.apiEndPoint()}/debtors/:id/agreements?filter=:filterName`,
  INVOICES: `${apiUrlConfig.odooEndPoint()}/debtors/:id/invoices`,
  PBXS: `${apiUrlConfig.apiEndPoint()}/debtors/:id/pbxes?filter=:filterName`,
  CREDIT_NOTES: `${apiUrlConfig.odooEndPoint()}/debtors/:id/credit_notes`,
  BILLS: `${apiUrlConfig.odooEndPoint()}/debtors/:id/invoices?type=bill`,
  LANGUAGE: `${apiUrlConfig.odooEndPoint()}/debtors/:id/language`,
};

class DebtorApi extends AuthenticatedRequest {
  // Fetch all debtors
  static fetchAll(withProspects = false) {
    const fetchAllUrl = withProspects
      ? `${URLS.INDEX}?with_prospects=true`
      : URLS.INDEX;

    return this.get(fetchAllUrl);
  }

  /**
   * Fetch single debtor by its ID
   *
   * @param {int} id ID of a debtor to be fetched
   */
  static fetchOne(id) {
    const fetchOneUrl = URLS.SHOW.replace(":id", id);

    return this.get(fetchOneUrl);
  }

  /**
   * Search/fetch business/debtor detail from external CVR API by given user query
   *
   * @param {string} query User query or search string for fetching debtor detail
   */
  static fetchByCVR(query) {
    return this.get(`${URLS.CVR}?search=${query}`);
  }

  /**
   * Update a ODOO debtor (Update directly in odoo system)
   *
   * @param {int} id ID of a debtor
   *
   * @param {object} attributes attributes of a setting
   * @param {string} name name of a debtor
   * @param {string} email email of a debtor
   * @param {number} vat vat number of a debtor
   * @param {string} phone phone of a debtor
   * @param {string} street street of a debtor
   * @param {string} street2 street2 of a debtor
   * @param {string} mobile mobile of a debtor
   * @param {string} website website of a debtor
   * @param {string} zip zip of a debtor
   * @param {string} city city of a debtor
   * @param {string} lang language of a debtor
   * @param {number} property_payment_term_id payment term id of a debtor
   * @param {number} company_id company id to which debtor belongs to
   * @param {number} country_id country id of a debtor
   * @param {boolean} is_company street of a debtor
   * @param {number} property_account_position_id street of a debtor
   *
   */
  static update(id, attributes) {
    const updateUrl = URLS.UPDATE.replace(":id", id);

    return this.patch(updateUrl, attributes);
  }

  /**
   * Create a ODOO debtor (Update directly in odoo system)
   *
   * @param {object} attributes attributes of a setting
   * @param {string} name name of a debtor
   * @param {string} email email of a debtor
   * @param {number} vat vat number of a debtor
   * @param {string} phone phone of a debtor
   * @param {string} street street of a debtor
   * @param {string} street2 street2 of a debtor
   * @param {string} mobile mobile of a debtor
   * @param {string} website website of a debtor
   * @param {string} zip zip of a debtor
   * @param {string} city city of a debtor
   * @param {string} lang language of a debtor
   * @param {number} property_payment_term_id payment term id of a debtor
   * @param {number} company_id company id to which debtor belongs to
   * @param {number} country_id country id of a debtor
   * @param {boolean} is_company street of a debtor
   * @param {number} property_account_position_id street of a debtor
   *
   */
  static create(attributes) {
    return this.post(URLS.CREATE, attributes);
  }

  /**
   *
   * @param {number | string} debtorID id of debtor to fetch active agreements
   */

  static activeAgreements(debtorID, filter) {
    const fetchUrl = URLS.AGREEMENTS.replace(":id", debtorID);
    const filterUrl = fetchUrl.replace(":filterName", filter);

    return this.get(filterUrl);
  }

  /**
   *
   * @param {number | string} debtorID id of debtor to fetch active pbxs
   * @param {string} filter filter to fetch filtered debtor's pbx
   */

  static invoices(debtorID) {
    const fetchUrl = URLS.INVOICES.replace(":id", debtorID);

    return this.get(fetchUrl);
  }

  /**
   *
   * @param {number | string} debtorID id of debtor to fetch active pbxs
   * @param {string} filter filter to fetch filtered debtor's pbx
   */

  static pbxs(debtorID, filter) {
    const fetchUrl = URLS.PBXS.replace(":id", debtorID);
    const filterUrl = fetchUrl.replace(":filterName", filter);

    return this.get(filterUrl);
  }

  /**
   *
   * @param {number | string} debtorID id of debtor to fetch credit notes
   */

  static creditNotes(debtorID) {
    const fetchUrl = URLS.CREDIT_NOTES.replace(":id", debtorID);

    return this.get(fetchUrl);
  }

  /**
   *
   * @param {number | string} vendorID id of vendor to fetch bills
   */

  static bills(vendorID) {
    const fetchUrl = URLS.BILLS.replace(":id", vendorID);

    return this.get(fetchUrl);
  }

  /**
   *
   * @param {init} debtorID  id of debtor to fetch language
   * @returns
   */

  static language(debtorID) {
    const fetchUrl = URLS.LANGUAGE.replace(":id", debtorID);

    return this.get(fetchUrl);
  }
}

export default DebtorApi;
