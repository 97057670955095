import React from "react";
import { FormGroup, Row, Col, Form } from "reactstrap";
import { Form as ReactFinalForm, Field } from "react-final-form";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";

import { required } from "utils/validation";

import { renderDropdownField } from "components/ReduxForm/RenderField";
import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";
import SubscriptionSection from "components/Quotation/Form/Subscription/SubscriptionSection";
import SubscriptionBindingPeriodSection from "components/Quotation/Form/SubscriptionBindingPeriod/SubscriptionBindingPeriodSection";

import { getDirtyFieldsValue } from "utils/utils";

const VALIDITY_PERIOD_OPTIONS = [30, 60, 90];

const QuotationForm = ({
  debtors,
  isLoading,
  isDebtorsLoading,
  initialValues,
  onSubmit,
}) => {
  const dropdownDebtors =
    debtors?.map((debtor) => ({
      id: debtor.id,
      value: debtor.id,
      label: debtor.name,
    })) || [];

  const validityPeriodOptions = () =>
    VALIDITY_PERIOD_OPTIONS.map((value) => {
      const dropdownJson = {
        value,
        label: <Translate value="common.numberOfDays" days={value} />,
      };

      return dropdownJson;
    });

  const handleFormSubmit = (fields, form) => {
    const registeredFields = form.getRegisteredFields();
    const values = getDirtyFieldsValue(fields, form);

    return onSubmit(values, registeredFields);
  };

  return (
    <ReactFinalForm
      onSubmit={handleFormSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={initialValues}
      render={({ pristine, submitting, handleSubmit }) => (
        <Form
          className="flexo-form px-4 py-4 pt-5 w-100"
          onSubmit={handleSubmit}
        >
          <div className="flexo-form-content">
            <Row>
              <Col md="6">
                <FormGroup>
                  <Field
                    name="agreement.odoo_debtor_id"
                    component={renderDropdownField}
                    validate={required}
                    dropdownOptions={dropdownDebtors}
                    isLoading={isDebtorsLoading}
                    labelTranslation="attributes.debtor"
                    placeholderTranslation="common.selectDebtor"
                  />
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Field
                    name="validity_period_in_days"
                    component={renderDropdownField}
                    validate={required}
                    dropdownOptions={validityPeriodOptions()}
                    labelTranslation="attributes.validityPeriod"
                    placeholderTranslation="quotation.selectValidityPeriod"
                  />
                </FormGroup>
              </Col>
            </Row>

            <FieldArray
              name="agreement.subscriptions"
              component={SubscriptionSection}
            />

            <FieldArray
              name="quotation_subscription_binding_periods"
              component={SubscriptionBindingPeriodSection}
            />
          </div>

          <div className="flexo-form-footer">
            <ReduxFormCommitButton
              title={<Translate value="common.save" />}
              isLoading={isLoading}
              submitting={submitting}
              pristine={pristine}
            />
          </div>
        </Form>
      )}
    />
  );
};

QuotationForm.defaultProps = {
  isDebtorsLoading: false,
};

QuotationForm.propTypes = {
  debtors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDebtorsLoading: PropTypes.bool,
  initialValues: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default QuotationForm;
