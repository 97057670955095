import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import { t, Translate } from "react-i18nify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faInfo } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

import ContextPropertyApi from "apis/ContextProperty";

import { SUBSCRIPTION_LINE_TYPE } from "constants/subscriptionLine";

import {
  getSubscriptionLineType,
  getSubscriptionOdooContext,
} from "utils/subscription";

import OtherProductsTable from "components/Quotation/Form/Modal/OtherProductsTable";
import ProductSelectionSection from "components/Quotation/Form/Modal/ProductSelectionSection";

const CONTEXT_TYPE = {
  SUBSCRIPTION: 1,
  USAGE: 4,
};

const OtherProductsSection = ({
  fields,
  products,
  subscriptionField,
  isProductFetching,
}) => {
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [isLoading, setLoader] = useState(false);

  const {
    input: {
      value: { subscription_type: subscriptionType, _destroy: isDeleted },
    },
  } = subscriptionField;
  const selectedOdooProductIds =
    fields.value?.map(({ odoo_product_id: odooProductID }) => odooProductID) ||
    [];

  const filterSelectedProducts = () => {
    const odooContextID = getSubscriptionOdooContext(subscriptionType);

    const exceptSelectedProducts = products.filter(
      ({ id, context, context_type: contextType }) =>
        context === odooContextID &&
        ![CONTEXT_TYPE.SUBSCRIPTION, CONTEXT_TYPE.USAGE].includes(
          Number(contextType)
        ) &&
        !selectedOdooProductIds.includes(id)
    );

    setFilteredProducts(exceptSelectedProducts);
  };

  useEffect(() => {
    filterSelectedProducts();
  }, [isProductFetching]);

  useEffect(() => {
    filterSelectedProducts();
  }, [subscriptionType]);

  const handleProductSelection = (value) => {
    setLoader(true);

    const selectedProduct = products.find(({ id }) => id === value);

    const {
      name,
      list_price: price,
      id,
      context_properties_ids: contextPropertiesIDs,
      context_type: contextTypeID,
    } = selectedProduct;

    ContextPropertyApi.fetchAll(id, contextPropertiesIDs)
      .then(({ data: contextProperties }) => {
        const subscriptionLineType = getSubscriptionLineType(contextTypeID);

        const subscriptionLineProperties = contextProperties.map(
          (contextProperty) => {
            const { property, value: propertyValue } = contextProperty;

            const subscriptionLineProperty = {
              property,
              value: propertyValue,
            };

            return subscriptionLineProperty;
          }
        );

        fields.push({
          odoo_product_id: id,
          product_name: name,
          price,
          line_type: subscriptionLineType,
          subscription_line_properties: subscriptionLineProperties,
        });

        setFilteredProducts((prev) =>
          prev.filter(({ id: productID }) => productID !== id)
        );

        setLoader(false);
      })
      .catch((err) => {
        toastr.error(t("common.error"), err.message);
        setLoader(false);
      });
  };

  if (isDeleted) {
    return null;
  }

  if (fields.length === 0) {
    return (
      <div className="subscription-detail d-flex justify-content-center w-100 py-5">
        <p className="mb-0 d-inline-block">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="d-block mx-auto text-danger mb-2"
            size="2x"
          />
          <Translate value="quotation.pleaseSelectAMainSubscriptionFirst" />
        </p>
      </div>
    );
  }

  const activeOtherProductsList = fields.value.filter(
    ({ _destroy: isLineDeleted, line_type: lineType }) =>
      !isLineDeleted && lineType !== SUBSCRIPTION_LINE_TYPE.SUBSCRIPTION
  );

  if (activeOtherProductsList.length === 0) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center flex-column flex-fill py-2 table-body">
        <div className="info-icon-wrapper border-danger mb-2">
          <FontAwesomeIcon className="info-icon text-danger" icon={faInfo} />
        </div>
        <p className="add-on-subscription-empty-text fw-bold">
          <Translate value="quotation.pleaseSelectProductToAdd" />
        </p>

        <ProductSelectionSection
          products={filteredProducts}
          isLoading={isLoading || isProductFetching}
          handleProductSelection={handleProductSelection}
        />
      </div>
    );
  }

  return (
    <>
      <OtherProductsTable
        fields={fields}
        setFilteredProducts={setFilteredProducts}
        products={products}
      />

      <div className="d-flex align-items-center other-products-table-row border-top-0">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="border-top-0 px-3 py-3 d-flex align-items-center">
          <Translate value="quotation.addProduct" />
          <ProductSelectionSection
            products={filteredProducts}
            isLoading={isLoading || isProductFetching}
            handleProductSelection={handleProductSelection}
          />
        </label>
      </div>
    </>
  );
};

OtherProductsSection.defaultProps = {};

OtherProductsSection.propTypes = {
  fields: PropTypes.shape({
    remove: PropTypes.func,
    push: PropTypes.func,
    pop: PropTypes.func,
    length: PropTypes.func,
    insert: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      context: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      context_type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  subscriptionField: PropTypes.shape({
    input: PropTypes.shape({
      value: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  isProductFetching: PropTypes.bool.isRequired,
};

export default OtherProductsSection;
