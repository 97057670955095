import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import FieldsSection from "components/NetworkOperatorSubscriptionServices/Modal/FieldsSection";
import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";

const ServiceModal = ({
  title,
  buttonTitle,
  modalVisible,
  onCancel,
  cellPhoneServices,
  onSubmit,
  fieldName,
  filteredSubscriptionServices,
  setFilteredSubscriptionServices,
  serverErrors,
  pristine,
  submitting,
}) => {
  if (!modalVisible) return null;

  return (
    <Modal isOpen={modalVisible} centered toggle={onCancel} size="xl">
      <ModalHeader className="border-0 pb-0 mb-4" toggle={onCancel}>
        {title}
      </ModalHeader>
      <ModalBody className="subscription-modal-body pt-0">
        <FieldsSection
          fieldName={fieldName}
          cellPhoneServices={cellPhoneServices}
          filteredSubscriptionServices={filteredSubscriptionServices}
          setFilteredSubscriptionServices={setFilteredSubscriptionServices}
        />
        <p className="text-danger mt-2">
          {serverErrors && `Errors: ${serverErrors}`}
        </p>
      </ModalBody>
      <ModalFooter>
        <ReduxFormCommitButton
          title={buttonTitle}
          submitting={submitting}
          pristine={pristine}
          submitClassName="btn-primary mt-3 d-flex align-items-center justify-content-center ms-auto"
          handleOnClick={onSubmit}
          performOnClickAction
        />
      </ModalFooter>
    </Modal>
  );
};

ServiceModal.defaultProps = {
  serverErrors: "",
};

ServiceModal.propTypes = {
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  cellPhoneServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  filteredSubscriptionServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      is_default: PropTypes.bool,
    })
  ).isRequired,
  setFilteredSubscriptionServices: PropTypes.func.isRequired,
  serverErrors: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ cellPhoneServices: cellPhoneServicesReducer }) => {
  const { cellPhoneServices, isLoading } = cellPhoneServicesReducer;

  return {
    cellPhoneServices,
    isLoading,
  };
};

export default connect(mapStateToProps)(ServiceModal);
