import React from "react";
import { connect } from "react-redux";
import { FormGroup, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";
import { Form as ReactFinalForm, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";

import {
  composeValidators,
  minPhoneNumber,
  phoneNumberValidation,
  required,
} from "utils/validation";
import { useSubscriptionTerminationDate } from "utils/customHooks";

import USER_ROLES from "constants/userRoles";
import { FORM_TYPES } from "constants/form";

import {
  renderInputField,
  renderDateField,
} from "components/ReduxForm/RenderField";
import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";
import FaxServiceSection from "components/DidNumber/Form/FaxServiceSection";

const RenderFaxServiceSection = ({
  showFaxServiceSection,
  formType,
  isFaxServiceEnabled,
}) => {
  if (!showFaxServiceSection) return null;

  return (
    <FaxServiceSection
      formType={formType}
      isFaxServiceEnabled={isFaxServiceEnabled}
    />
  );
};

const NameInputFields = ({ userLicenseID }) => {
  if (!userLicenseID) return null;

  return (
    <>
      <Col md="6">
        <FormGroup>
          <Field
            name="first_name"
            component={renderInputField}
            validate={required}
            labelTranslation="attributes.firstName"
            placeholderTranslation="attributes.firstName"
            type="text"
          />
        </FormGroup>
      </Col>

      <Col md="6">
        <FormGroup>
          <Field
            name="last_name"
            component={renderInputField}
            validate={required}
            labelTranslation="attributes.lastName"
            placeholderTranslation="attributes.lastName"
            type="text"
          />
        </FormGroup>
      </Col>
    </>
  );
};

const SubscriptionSection = ({
  formType,
  minimumBeforeDate,
  deploymentDate,
  terminationDateDisabledDays,
  isTerminationDateLoading,
}) => {
  if (formType === FORM_TYPES.CREATE)
    return (
      <Row>
        <Col md="6">
          <FormGroup>
            <Field
              name="deployment_date"
              component={renderDateField}
              validate={required}
              labelTranslation="attributes.deploymentDate"
              disabledDays={{
                before: minimumBeforeDate,
              }}
            />
          </FormGroup>
        </Col>

        <Col md="6">
          <FormGroup>
            <Field
              name="invoice_start_date"
              component={renderDateField}
              validate={required}
              labelTranslation="attributes.invoiceStartDate"
              disabledDays={{
                before:
                  (deploymentDate && new Date(deploymentDate)) ||
                  minimumBeforeDate,
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    );

  return (
    <Row>
      <Col md="6">
        <FormGroup>
          <Field
            name="termination_date"
            component={renderDateField}
            labelTranslation="attributes.terminationDate"
            disabledDays={{
              before: terminationDateDisabledDays,
            }}
            isLoadingDate={isTerminationDateLoading}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

const DidNumberForm = ({
  formType,
  isLoading,
  faxServiceEnabled: isFaxServiceEnabled,
  terminationDateEndLimit,
  subscriptionLineId,
  currentUserRole,
  showFaxServiceSection,
  userLicenseID,
  onSubmit,
  initialValues,
}) => {
  const { subscriptionTerminationEndRange, isTerminationDateLoading } =
    useSubscriptionTerminationDate(subscriptionLineId);

  const minimumBeforeDate =
    currentUserRole === USER_ROLES.SUPER_ADMIN ? "" : new Date();

  const descriptionFieldValidation = !userLicenseID ? required : null;

  return (
    <ReactFinalForm
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ submitting, pristine, handleSubmit, values }) => {
        const {
          invoice_start_date: invoiceStartDate,
          deployment_date: deploymentDate,
        } = values;

        const terminationDateEndRange =
          terminationDateEndLimit ||
          subscriptionTerminationEndRange ||
          invoiceStartDate ||
          deploymentDate;
        const todayDate = new Date();
        const terminationDateDisabledDays =
          terminationDateEndRange &&
          new Date(terminationDateEndRange) > todayDate
            ? new Date(terminationDateEndRange)
            : todayDate;

        return (
          <form
            className="flexo-form px-4 py-4 pt-5 w-100"
            onSubmit={handleSubmit}
          >
            <div className="pbx-content">
              <Row>
                <NameInputFields userLicenseID={userLicenseID} />
                <Col md="6">
                  <FormGroup>
                    <Field
                      name="phone_number"
                      component={renderInputField}
                      validate={composeValidators(
                        required,
                        phoneNumberValidation,
                        minPhoneNumber(8)
                      )}
                      labelTranslation="attributes.phone"
                      placeholderTranslation="attributes.phone"
                      type="text"
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="di_comment"
                      component={renderInputField}
                      validate={descriptionFieldValidation}
                      labelTranslation="attributes.description"
                      placeholderTranslation="attributes.description"
                      type="textarea"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <RenderFaxServiceSection
                formType={formType}
                isFaxServiceEnabled={isFaxServiceEnabled}
                showFaxServiceSection={showFaxServiceSection}
              />

              <Row>
                <Col md="12">
                  <h4 className="mt-3 mb-3">
                    <Translate value="title.section.subscription" />
                  </h4>

                  <hr />
                </Col>
              </Row>

              <SubscriptionSection
                deploymentDate={deploymentDate}
                formType={formType}
                minimumBeforeDate={minimumBeforeDate}
                terminationDateDisabledDays={terminationDateDisabledDays}
                isTerminationDateLoading={isTerminationDateLoading}
              />
            </div>
            <div className="flexo-form-footer">
              <ReduxFormCommitButton
                title={<Translate value="common.save" />}
                submitting={submitting}
                pristine={pristine}
                isLoading={isLoading || isTerminationDateLoading}
              />
            </div>
          </form>
        );
      }}
    />
  );
};

RenderFaxServiceSection.defaultProps = {
  showFaxServiceSection: false,
  formType: "",
  isFaxServiceEnabled: false,
};

RenderFaxServiceSection.propTypes = {
  showFaxServiceSection: PropTypes.bool,
  formType: PropTypes.string,
  isFaxServiceEnabled: PropTypes.bool,
};

NameInputFields.defaultProps = { userLicenseID: "" };

NameInputFields.propTypes = { userLicenseID: PropTypes.string };

SubscriptionSection.defaultProps = {
  formType: "",
  minimumBeforeDate: "",
  deploymentDate: "",
  terminationDateDisabledDays: "",
  isTerminationDateLoading: false,
};

SubscriptionSection.propTypes = {
  formType: PropTypes.string,
  minimumBeforeDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  deploymentDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  terminationDateDisabledDays: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  isTerminationDateLoading: PropTypes.bool,
};

DidNumberForm.defaultProps = {
  formType: "create",
  faxServiceEnabled: false,
  terminationDateEndLimit: undefined,
  subscriptionLineId: null,
  showFaxServiceSection: true,
  userLicenseID: "",
  initialValues: {},
};

DidNumberForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  formType: PropTypes.string,
  faxServiceEnabled: PropTypes.bool,
  terminationDateEndLimit: PropTypes.instanceOf(Date),
  subscriptionLineId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentUserRole: PropTypes.string.isRequired,
  showFaxServiceSection: PropTypes.bool,
  userLicenseID: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
};

const mapStateToProps = (state) => {
  const {
    currentUser: { role: currentUserRole },
  } = state.auth;

  return {
    currentUserRole,
  };
};

export default connect(mapStateToProps, null)(DidNumberForm);
