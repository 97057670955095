import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { Translate, t } from "react-i18nify";
import PropTypes from "prop-types";
import { Navigate, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import ICCIDNumberForm from "components/SimCard/Edit/ICCIDNumberForm";
import WholePageSpinner from "components/Spinner/WholePageSpinner";
import PageNotFound from "components/Error/PageNotFound/PageNotFound";

import {
  clearSimCardErrors,
  simCardDetailRequest,
  updateSimCardRequest,
} from "redux/actions/simCard";

import { SIM_CARD } from "constants/routes";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const SimCardEdit = ({
  simCard,
  isLoading,
  isSubmitting,
  commitSuccess,
  clearErrors,
  fetchSimCardDetail,
  updateSimCard,
}) => {
  const { simCardID } = useParams();

  const [initialValues, setInitialValues] = useState({});

  const fetchSimCardData = () => {
    fetchSimCardDetail(simCardID);
  };

  useEffect(() => {
    fetchSimCardData(simCardID);
  }, [simCardID]);

  useEffect(() => {
    if (!simCard?.id) return;
    const { pin_1: pin1, pin_2: pin2, puk_1: puk1, puk_2: puk2 } = simCard;

    const attributes = {
      pin_1: pin1,
      pin_2: pin2,
      puk_1: puk1,
      puk_2: puk2,
    };

    setInitialValues(attributes);
  }, [simCard]);

  const onSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    await updateSimCard(simCardID, values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess) {
    const { id } = simCard;
    const simCardTranslation = t("menuItem.simCards");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.update", {
        resource: simCardTranslation,
      })
    );

    clearErrors();

    return <Navigate to={SIM_CARD.addId(SIM_CARD.SHOW, id)} />;
  }

  if (isLoading) return <WholePageSpinner />;

  if (!simCard?.id) {
    return (
      <div className="d-flex h-100 justify-content-center">
        <PageNotFound />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.edit"
            resource={simCard.iccid_number}
          />
        </h3>
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, pristine }) => (
          <form
            className="flexo-form px-4 py-4 pt-5 w-100"
            onSubmit={handleSubmit}
          >
            <ICCIDNumberForm
              submitting={submitting}
              pristine={pristine}
              isLoading={isSubmitting}
            />
          </form>
        )}
      </Form>
    </div>
  );
};

SimCardEdit.defaultProps = {
  isLoading: false,
  isSubmitting: false,
  commitSuccess: false,
};

SimCardEdit.propTypes = {
  isLoading: PropTypes.bool,
  fetchSimCardDetail: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  commitSuccess: PropTypes.bool,
  clearErrors: PropTypes.bool.isRequired,
  simCard: PropTypes.shape({
    id: PropTypes.number,
    pin_1: PropTypes.number,
    pin_2: PropTypes.number,
    puk_1: PropTypes.number,
    puk_2: PropTypes.number,
    status: PropTypes.string,
    note: PropTypes.string,
    imsi_number: PropTypes.string,
    network_operator_name: PropTypes.string,
    service_provider: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
    iccid_number: PropTypes.string,
    sim_card_borrower: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      is_employee: PropTypes.bool,
    }),
  }).isRequired,
  updateSimCard: PropTypes.func.isRequired,
};

const mapStateToProps = ({ simCard: simCardState }) => {
  const { simCard, fetchError, commitSuccess, isLoading, isSubmitting } =
    simCardState;

  return {
    simCard,
    commitSuccess,
    fetchError,
    isLoading,
    isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSimCardDetail: (id) => {
    dispatch(simCardDetailRequest(id));
  },
  updateSimCard: async (simCardID, attributes, callback) => {
    await dispatch(updateSimCardRequest(simCardID, attributes, callback));
  },
  clearErrors: () => {
    dispatch(clearSimCardErrors());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SimCardEdit);
