import LocalStorageManager, { CONSTANTS } from "./localStorageManager";

function getPaymentTermInDays(paymentTerm) {
  let daysToAdd = paymentTerm;

  if (daysToAdd === "Immediate Payment") {
    daysToAdd = 0;
  } else if (daysToAdd === "2 Months") {
    daysToAdd = 60;
  } else {
    daysToAdd = parseInt(paymentTerm, 10);
  }

  return daysToAdd;
}

function formatDate(date, noLocale = false) {
  const locale = LocalStorageManager.get(CONSTANTS.CURRENT_LOCALE);

  if (locale === "en-US" || noLocale) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
}

function calculateInvoiceDueDate(
  paymentTerm,
  format = true,
  invoiceCreateDate = new Date()
) {
  const daysToAdd = getPaymentTermInDays(paymentTerm);
  const invoiceDate = new Date(invoiceCreateDate);

  const dueDateUnix = invoiceDate.setDate(invoiceDate.getDate() + daysToAdd);
  const dueDateNormal = new Date(dueDateUnix);

  if (format) {
    return formatDate(dueDateNormal, true);
  }

  return dueDateNormal;
}

export { getPaymentTermInDays, calculateInvoiceDueDate, formatDate };
