import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import NetworkOperatorSubscriptionServicesTable from "components/CellPhone/Detail/NetworkOperatorSubscriptionServices/NetworkOperatorSubscriptionServicesTable";

const NetworkOperatorSubscriptionServicesModal = ({
  title,
  networkOperatorSubscriptionServices,
  isModalVisible,
  onCancel,
}) => {
  if (!isModalVisible) return null;

  return (
    <Modal
      isOpen={isModalVisible}
      centered
      toggle={onCancel}
      className="modal-lg"
    >
      <ModalHeader className="border-0 pb-0" toggle={onCancel}>
        {title}
      </ModalHeader>
      <ModalBody className="pt-0 table-responsive">
        <NetworkOperatorSubscriptionServicesTable
          networkOperatorSubscriptionServices={
            networkOperatorSubscriptionServices
          }
        />
      </ModalBody>
    </Modal>
  );
};

NetworkOperatorSubscriptionServicesModal.defaultProps = {};

NetworkOperatorSubscriptionServicesModal.propTypes = {
  title: PropTypes.string.isRequired,
  networkOperatorSubscriptionServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      service_name: PropTypes.string,
      location: PropTypes.string,
      location_constraint: PropTypes.string,
    })
  ).isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default NetworkOperatorSubscriptionServicesModal;
