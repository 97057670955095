import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUserCog,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import { logoutRequest } from "redux/actions/auth";

import getNameInitials from "utils/user";

import { AUTH, USER, USER_SETTING } from "constants/routes";

import HasPermission from "hoc/RbacCTA";

const UserInfo = ({ user, menuMinimized, logout }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    logout();
    navigate(AUTH.LOGIN);
  };

  if (!user) return null;

  const { first_name: firstName, last_name: lastName, id } = user;

  const userName =
    firstName || lastName ? (
      `${firstName} ${lastName}`
    ) : (
      <Translate value="common.noName" />
    );

  return (
    <div
      className={`w-100 menu-user-info my-1 ${
        menuMinimized ? "menu-user-info-minimized" : ""
      }`}
    >
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          className="border-0 d-flex align-items-center pl-0 "
          caret
        >
          <div className="profile-menu d-flex align-items-center pl-1">
            <span className="user-short-name me-3" data-cy="profile-dropdown">
              {getNameInitials(userName)}
            </span>
            <span className="user-name text-white">{userName}</span>
          </div>
        </DropdownToggle>
        <div className="sidebar-dropdown-menu">
          <DropdownMenu>
            <DropdownItem
              tag={Link}
              to={USER.addId(USER.SHOW, id)}
              className="d-flex"
            >
              <div className="menu-icon-dropdown" data-cy="profile-button">
                <FontAwesomeIcon icon={faUser} />
              </div>
              <Translate value="menuItem.profile" />
            </DropdownItem>
            <HasPermission perform="users:setting">
              <DropdownItem
                tag={Link}
                to={USER_SETTING.INDEX}
                className="d-flex"
              >
                <div className="menu-icon-dropdown" data-cy="profile-settings">
                  <FontAwesomeIcon icon={faUserCog} />
                </div>
                <Translate value="menuItem.userSetting" />
              </DropdownItem>
            </HasPermission>
            <DropdownItem
              className="d-flex"
              onClick={handleLogout}
              data-cy="logout"
            >
              <div className="menu-icon-dropdown">
                <FontAwesomeIcon icon={faSignOutAlt} />
              </div>
              <Translate value="common.signOut" />
            </DropdownItem>
          </DropdownMenu>
        </div>
      </Dropdown>
    </div>
  );
};

UserInfo.defaultProps = {
  menuMinimized: false,
};

UserInfo.propTypes = {
  menuMinimized: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { auth } = state;
  const { currentUser: user } = auth;

  return {
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logoutRequest());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
