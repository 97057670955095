import {
  PRODUCT_FETCH_REQUEST,
  PRODUCT_FETCH_RECEIVED,
  PRODUCT_FETCH_FAILED,
  PRODUCT_FETCH_CLEAR_ERRORS,
} from "redux/actions/product";

const defaultState = {
  products: [],
  productFetchError: "",
};

function product(state = defaultState, action) {
  const { products } = action;

  switch (action.type) {
    case PRODUCT_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case PRODUCT_FETCH_RECEIVED:
      return {
        ...state,
        products,
        isLoading: false,
      };

    case PRODUCT_FETCH_FAILED:
      return {
        ...state,
        productFetchError: action.message,
        isLoading: false,
      };

    case PRODUCT_FETCH_CLEAR_ERRORS:
      return { ...state, productFetchError: "", isLoading: false };

    default:
      return state;
  }
}

export default product;
