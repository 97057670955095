import { RoutingProfileApi } from "internal";
import { getAndDisplayErrors } from "utils/utils";

export const ROUTING_PROFILE_FETCH_REQUEST = "ROUTING_PROFILE_FETCH_REQUEST";
export const ROUTING_PROFILE_FETCH_RECEIVED = "ROUTING_PROFILE_FETCH_RECEIVED";
export const ROUTING_PROFILE_FETCH_FAILED = "ROUTING_PROFILE_FETCH_FAILED";
export const ROUTING_PROFILE_FETCH_CLEAR_ERRORS =
  "ROUTING_PROFILE_FETCH_CLEAR_ERRORS";

// Fetch all Routing Profiles
export function routingProfileFetchReceived(response) {
  return {
    type: ROUTING_PROFILE_FETCH_RECEIVED,
    routingProfiles: response.data,
  };
}

export function routingProfileFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: ROUTING_PROFILE_FETCH_FAILED,
    message,
  };
}

export const routingProfileFetchRequest = () => (dispatch) => {
  dispatch({ type: ROUTING_PROFILE_FETCH_REQUEST });

  RoutingProfileApi.fetchAll()
    .then((data) => dispatch(routingProfileFetchReceived(data)))
    .catch((e) => {
      dispatch(routingProfileFetchFailed(e));
    });
};
