import React from "react";

import PropTypes from "prop-types";

const Tooltip = ({
  children,
  title,
  className,
  containerClassName,
  tooltipType = "tool-tip",
}) => {
  if (tooltipType === "custom") {
    return (
      <div className={`custom-tooltip position-relative ${containerClassName}`}>
        {children}
        <span
          className={`tooltip-text position-absolute py-2 px-2 mt-1 ${className}`}
        >
          {title}
        </span>
      </div>
    );
  }

  return (
    <div className={`tool-tip position-relative ${containerClassName}`}>
      {children}
      <span
        className={`tooltip-text position-absolute py-2 px-2 mt-1 ${className}`}
      >
        {title}
      </span>
    </div>
  );
};

Tooltip.defaultProps = {
  className: "text-nowrap d-flex small px-2",
  children: <></>,
  title: "",
  containerClassName: "",
  tooltipType: "tool-tip",
};

Tooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  tooltipType: PropTypes.string,
};

export default Tooltip;
