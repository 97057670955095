import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { toastr } from "react-redux-toastr";
import { Translate, t } from "react-i18nify";

import { debtorFetchRequest } from "redux/actions/debtor";
import { quotationCreateRequest } from "redux/actions/quotation";

import { formatRequestValues } from "utils/quotation";
import { processServerResponseForAsyncValidationErrors } from "utils/forms";

import { QUOTATION } from "constants/routes";
import QuotationForm from "components/Quotation/Form/QuotationForm";

const QuotationCreate = ({
  quotation,
  createQuotation,
  debtors,
  isLoading,
  commitSuccess,
  commitError,
  fetchDebtors,
  isDebtorsLoading,
}) => {
  useEffect(() => {
    fetchDebtors();
  }, []);

  const handleSubmit = async (values, registeredFields) => {
    let serverResponse = {};

    const formattedValues = formatRequestValues(values);

    await createQuotation(formattedValues, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess && !commitError) {
    const { id } = quotation;
    const quotationTranslation = t("attributes.quotation");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.create", {
        resource: quotationTranslation,
      })
    );

    return <Navigate to={`${QUOTATION.addId(QUOTATION.SHOW, id)}`} />;
  }

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate value="title.page.resource.create" resource="Quotation" />
        </h3>
      </div>
      <QuotationForm
        commitError={commitError}
        debtors={debtors}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        isDebtorsLoading={isDebtorsLoading}
        initialValues={{ agreement: { is_billable: false } }}
      />
    </div>
  );
};

QuotationCreate.defaultProps = {
  debtors: [],
  isLoading: true,
  quotation: undefined,
  commitError: {},
  isDebtorsLoading: false,
};

QuotationCreate.propTypes = {
  fetchDebtors: PropTypes.func.isRequired,
  createQuotation: PropTypes.func.isRequired,
  debtors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.integer,
      name: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  commitError: PropTypes.shape({}),
  commitSuccess: PropTypes.bool.isRequired,
  quotation: PropTypes.shape({
    id: PropTypes.number,
  }),
  isDebtorsLoading: PropTypes.bool,
};

function mapStateToProps({ debtor, quotation: quotationReducer }) {
  const { isLoading, commitSuccess, quotation, commitError } = quotationReducer;

  const { debtors, isLoading: isDebtorsLoading } = debtor;

  return {
    debtors,
    isDebtorsLoading,
    isLoading,
    commitSuccess,
    quotation,
    commitError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDebtors: () => {
      dispatch(debtorFetchRequest(true));
    },
    createQuotation: async (attributes, callback) => {
      await dispatch(quotationCreateRequest(attributes, callback));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotationCreate);
