import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import SubscriptionLineList from "components/Agreement/AgreementDetail/SubscriptionLine/SubscriptionLineList";

const SubscriptionLineTabContent = ({ subscriptionLines, isArchived }) => {
  const filteredSubscriptionLines = subscriptionLines.filter(
    (subscriptionLine) => subscriptionLine.is_archived === isArchived
  );

  const isSubscriptionLinesEmpty = filteredSubscriptionLines.length === 0;

  const emptyLinesContent = isSubscriptionLinesEmpty ? (
    <div className="w-100 h-100 empty-screen p-4">
      <div className="icon-holder mb-4 bg-danger">
        <FontAwesomeIcon icon={faTimes} className="m-0" />
      </div>

      <h5 className="text-secondary">
        <Translate value="message.subscription.noLine" />
      </h5>
    </div>
  ) : null;

  return (
    <div>
      <div
        className="table-responsive subscription-list-table"
        hidden={isSubscriptionLinesEmpty}
      >
        <SubscriptionLineList subscriptionLines={filteredSubscriptionLines} />
      </div>
      {emptyLinesContent}
    </div>
  );
};

SubscriptionLineTabContent.propTypes = {
  subscriptionLines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isArchived: PropTypes.bool.isRequired,
};

export default SubscriptionLineTabContent;
