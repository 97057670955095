import React, { useState } from "react";
import { useField } from "react-final-form";
import { Translate } from "react-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, Col, Row, Button, Collapse } from "reactstrap";
import {
  faAngleDown,
  faAngleRight,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

import CSVFileDownload from "components/NumberPortingRequest/Import/Form/FileImportSection/CSVFileDownload";
import CSVFileUpload from "components/NumberPortingRequest/Import/Form/FileImportSection/CSVFileUpload";

const FileImportSection = () => {
  const [instructionSectionOpen, setInstructionSectionOpen] = useState(false);
  const {
    input: { value: numberType },
  } = useField("number_type");
  const cellPhoneNumberType = numberType === "cell_phone";

  const toggleInstructionSection = () =>
    setInstructionSectionOpen(!instructionSectionOpen);

  const instructionToggleIcon = instructionSectionOpen
    ? faAngleDown
    : faAngleRight;

  const instructionViewText = instructionSectionOpen ? (
    <Translate value="common.hide" />
  ) : (
    <Translate value="common.view" />
  );

  const translationPhoneNumberKey = cellPhoneNumberType
    ? "cellPhone"
    : "didNumber";

  return (
    <Row>
      <Col md="12">
        <div className="bg-light p-3 file-import-section">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="icon text-info"
                size="2x"
              />
              <span className="ms-2">
                <Translate value="numberPortingRequest.importForm.csvFileImport.importNumbersFromCSVFile" />
              </span>
            </div>

            <Button
              color="primary"
              onClick={toggleInstructionSection}
              className="d-flex align-items-center btn-sm"
            >
              {instructionViewText} <Translate value="common.instructions" />
              <FontAwesomeIcon
                icon={instructionToggleIcon}
                className="icon text-white ms-2"
                size="1.5x"
              />
            </Button>
          </div>

          <Collapse isOpen={instructionSectionOpen} className="mt-3">
            <Card>
              <CardBody>
                <CSVFileDownload />

                <div>
                  <h5>
                    <Translate value="numberPortingRequest.importForm.csvFileImport.addCellAndICCNumberToCSV" />
                  </h5>

                  <p>
                    <Translate
                      value={`numberPortingRequest.importForm.csvFileImport.${translationPhoneNumberKey}.addNumbersBelowHeader`}
                    />
                  </p>
                </div>

                <CSVFileUpload
                  setInstructionSectionOpen={setInstructionSectionOpen}
                />
              </CardBody>
            </Card>
          </Collapse>
        </div>
      </Col>
    </Row>
  );
};

export default FileImportSection;
