import {
  CELL_PHONE_CREATE_REQUEST,
  CELL_PHONE_CREATE_RECEIVED,
  CELL_PHONE_CREATE_FAILED,
  SINGLE_CELL_PHONE_FETCH_REQUEST,
  SINGLE_CELL_PHONE_FETCH_RECEIVED,
  SINGLE_CELL_PHONE_FETCH_FAILED,
  CELL_PHONE_UPDATE_REQUEST,
  CELL_PHONE_UPDATE_RECEIVED,
  CELL_PHONE_UPDATE_FAILED,
  CELL_PHONE_FETCH_CLEAR_ERRORS,
  SEARCH_PHONE_NUMBER_REQUEST,
  SEARCH_PHONE_NUMBER_RECEIVED,
  SEARCH_PHONE_NUMBER_ERROR,
  SUSPEND_CELL_PHONE_REQUEST,
  SUSPEND_CELL_PHONE_RECEIVED,
  SUSPEND_CELL_PHONE_ERROR,
  REACTIVATE_CELL_PHONE_REQUEST,
  REACTIVATE_CELL_PHONE_RECEIVED,
  REACTIVATE_CELL_PHONE_ERROR,
  ORDER_SIM_CARD_REQUEST,
  ORDER_SIM_CARD_RECEIVED,
  ORDER_SIM_CARD_ERROR,
  ASSIGN_NEW_SIM_CARD_REQUEST,
  ASSIGN_NEW_SIM_CARD_RECEIVED,
  ASSIGN_NEW_SIM_CARD_ERROR,
  NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_DELETE_FAILED,
  NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_DELETE_RECEIVED,
  NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_DELETE_REQUEST,
  NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_CREATE_FAILED,
  NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_CREATE_RECEIVED,
  NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_CREATE_REQUEST,
} from "redux/actions/cellPhone";

const defaultState = {
  cellPhones: [],
  commitError: "",
  fetchError: "",
  commitSuccess: false,
  isLoading: false,
  isSearching: false,
  isProcessing: false,
  phoneNumbers: [],
};

function cellPhone(state = defaultState, action) {
  const { cellPhone: singleCellPhone } = action;

  switch (action.type) {
    case SINGLE_CELL_PHONE_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitError: "",
        commitSuccess: false,
      };

    case SINGLE_CELL_PHONE_FETCH_RECEIVED:
      return {
        ...state,
        cellPhone: singleCellPhone,
        isLoading: false,
      };

    case SINGLE_CELL_PHONE_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case CELL_PHONE_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case CELL_PHONE_UPDATE_RECEIVED:
      return {
        ...state,
        cellPhone: singleCellPhone,
        isLoading: false,
        commitSuccess: true,
      };

    case CELL_PHONE_UPDATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case CELL_PHONE_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case CELL_PHONE_CREATE_RECEIVED:
      return {
        ...state,
        cellPhone: singleCellPhone,
        isLoading: false,
        commitSuccess: true,
      };

    case CELL_PHONE_CREATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case CELL_PHONE_FETCH_CLEAR_ERRORS:
      return {
        ...state,
        fetchError: "",
        isLoading: false,
        commitSuccess: false,
        commitError: "",
      };

    case SEARCH_PHONE_NUMBER_REQUEST:
      return {
        ...state,
        fetchError: "",
        isProcessing: true,
        commitSuccess: false,
        commitError: "",
      };

    case SEARCH_PHONE_NUMBER_RECEIVED:
      return {
        ...state,
        fetchError: "",
        isProcessing: false,
        phoneNumbers: action.phoneNumbers,
      };

    case SEARCH_PHONE_NUMBER_ERROR:
      return {
        ...state,
        fetchError: action.message,
        isProcessing: false,
      };

    case SUSPEND_CELL_PHONE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        commitError: "",
        commitSuccess: false,
      };

    case SUSPEND_CELL_PHONE_RECEIVED:
      return {
        ...state,
        isSubmitting: false,
        cellPhone: action.cellPhone,
        commitSuccess: true,
      };

    case SUSPEND_CELL_PHONE_ERROR:
      return {
        ...state,
        isSubmitting: false,
        commitError: action.message,
      };

    case REACTIVATE_CELL_PHONE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        commitError: "",
        commitSuccess: false,
      };

    case REACTIVATE_CELL_PHONE_RECEIVED:
      return {
        ...state,
        isSubmitting: false,
        cellPhone: action.cellPhone,
        commitSuccess: true,
      };

    case REACTIVATE_CELL_PHONE_ERROR:
      return {
        ...state,
        isSubmitting: false,
        commitError: action.message,
      };

    case ORDER_SIM_CARD_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        commitError: "",
        commitSuccess: false,
      };

    case ORDER_SIM_CARD_RECEIVED:
      return {
        ...state,
        isSubmitting: false,
        cellPhone: action.cellPhone,
        commitSuccess: true,
      };

    case ORDER_SIM_CARD_ERROR:
      return {
        ...state,
        isSubmitting: false,
        commitError: action.message,
      };

    case ASSIGN_NEW_SIM_CARD_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        commitError: "",
      };

    case ASSIGN_NEW_SIM_CARD_RECEIVED:
      return {
        ...state,
        isSubmitting: false,
        cellPhone: action.cellPhone,
      };

    case ASSIGN_NEW_SIM_CARD_ERROR:
      return {
        ...state,
        isSubmitting: false,
        commitError: action.message,
      };

    case NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_DELETE_REQUEST:
      return {
        ...state,
        isLoading: false,
        commitError: "",
      };

    case NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_DELETE_RECEIVED:
      return {
        ...state,
        isLoading: false,
      };

    case NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_DELETE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
      };

    case NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_CREATE_REQUEST:
      return {
        ...state,
        isLoading: false,
        commitError: "",
      };

    case NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_CREATE_RECEIVED:
      return {
        ...state,
        networkService: action.networkService,
        isLoading: false,
      };

    case NETWORK_OPERATOR_SUBSCRIPTION_SERVICE_CREATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
      };

    default:
      return state;
  }
}

export default cellPhone;
