import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  UPDATE: `${apiUrlConfig.apiEndPoint()}/number_porting_request_phone_numbers/:id`,
  CANCEL_IMPORT_FLOW: `${apiUrlConfig.apiEndPoint()}/number_porting_request_phone_numbers/:id/cancel_import_flow`,
};

class NumberPortingRequestPhoneNumberApi extends AuthenticatedRequest {
  /**
   * Update a number_porting_request_phone_number
   *
   * @param {int} id ID of a number_porting_request_phone_number
   *
   * @param {object} attributes attributes of a number_porting_request
   * @param {string} export_status export status of the request, one of [accepted, rejected, proposed_new_date]
   * @param {string} rejection_reason reason for rejecting the request
   * @param {date} confirmed_porting_date date to execute porting order
   * @param {number} proposed_date_reason_code reason code for proposing new date
   *
   */
  static update(id, attributes) {
    const updateUrl = URLS.UPDATE.replace(":id", id);

    const requestBody = {
      number_porting_request_phone_number: {
        ...attributes,
      },
    };

    return this.patch(updateUrl, requestBody);
  }

  /**
   * Cancel single import request
   *
   * @param {int} id ID of a number_porting_request_phone_number
   *
   */
  static cancelImportFlow(id) {
    const url = URLS.CANCEL_IMPORT_FLOW.replace(":id", id);

    return this.patch(url, {});
  }
}

export default NumberPortingRequestPhoneNumberApi;
