import { CellPhoneServicesApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

export const CELL_PHONE_SERVICES_FETCH_REQUEST =
  "CELL_PHONE_SERVICES_FETCH_REQUEST";
export const CELL_PHONE_SERVICES_FETCH_RECEIVED =
  "CELL_PHONE_SERVICES_FETCH_RECEIVED";
export const CELL_PHONE_SERVICES_FETCH_FAILED =
  "CELL_PHONE_SERVICES_FETCH_FAILED";

// fetch cell phone services
export function cellPhoneServicesFetchReceived(response) {
  return {
    type: CELL_PHONE_SERVICES_FETCH_RECEIVED,
    cellPhoneServices: response.data,
  };
}

export function cellPhoneServicesFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: CELL_PHONE_SERVICES_FETCH_FAILED,
    message,
  };
}

export const cellPhoneServicesFetchRequest = () => (dispatch) => {
  dispatch({ type: CELL_PHONE_SERVICES_FETCH_REQUEST });

  CellPhoneServicesApi.fetchAll()
    .then((data) => dispatch(cellPhoneServicesFetchReceived(data)))
    .catch((e) => {
      dispatch(cellPhoneServicesFetchFailed(e));
    });
};
