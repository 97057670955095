import React from "react";
import { connect } from "react-redux";
import { Form, FormGroup, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";
import { Form as ReactFinalForm, Field } from "react-final-form";

import {
  renderInputField,
  renderDropdownField,
} from "components/ReduxForm/RenderField";
import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";
import CheckBoxField from "components/ReduxForm/CheckBoxField";

import { required, email, websiteValidation } from "utils/validation";
import { getCurrentUser } from "utils/selectors";
import { isAnAdminUser } from "utils/userRole";

import { FORM_TYPES } from "constants/form";

const DEBTOR_TYPES = ["DK Business", "DK Erhverv"];

const VatNumberInputField = ({ debtorType }) => {
  if (!DEBTOR_TYPES.includes(debtorType)) return null;

  return (
    <Col md="6">
      <FormGroup>
        <div className="setting-form-width input-name">
          <Field
            name="vat"
            type="text"
            placeholderTranslation="attributes.vatNumber"
            component={renderInputField}
            validate={required}
            labelTranslation="attributes.vatNumber"
          />
        </div>
      </FormGroup>
    </Col>
  );
};

const DebtorTypeDropdownField = ({
  isAdmin,
  debtorTypes,
  isDebtorTypeLoading,
}) => {
  if (!isAdmin) return null;

  return (
    <Col md="6">
      <FormGroup>
        <Field
          name="property_account_position_id"
          component={renderDropdownField}
          validate={required}
          dropdownOptions={debtorTypes}
          labelTranslation="attributes.debtorType"
          isLoading={isDebtorTypeLoading}
        />
      </FormGroup>
    </Col>
  );
};

const CompanyNameInputField = ({ isAdmin }) => {
  if (!isAdmin) return null;

  return (
    <Col md="6">
      <FormGroup>
        <Field
          name="name"
          type="text"
          placeholderTranslation="attributes.name"
          component={renderInputField}
          validate={required}
          labelTranslation="attributes.name"
        />
      </FormGroup>
    </Col>
  );
};

const CustomerCheckboxField = ({ formType }) => {
  if (formType !== FORM_TYPES.CREATE) return null;

  return (
    <Col md="12">
      <FormGroup>
        <Field
          name="customer"
          component={CheckBoxField}
          labelTranslation="attributes.isACustomer"
        />
      </FormGroup>
    </Col>
  );
};

const DebtorForm = ({
  debtorTypes,
  countries,
  paymentTerms,
  isLoading,
  isDebtorTypeLoading,
  isCountryFetching,
  isPaymentTermFetching,
  currentUserRole,
  formType,
  onSubmit,
  initialValues,
}) => {
  const languageOptions = [
    { value: "en_US", label: "English" },
    { value: "da_DK", label: "Danish / Dansk" },
  ];

  const isAdmin = isAnAdminUser(currentUserRole);

  return (
    <ReactFinalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ pristine, submitting, handleSubmit, values }) => {
        const { property_account_position_id: propertyAccountPositionID } =
          values;

        const debtorType =
          propertyAccountPositionID &&
          debtorTypes.find(
            (currentDebtorType) =>
              currentDebtorType.value === propertyAccountPositionID
          )?.label;

        return (
          <Form
            className="flexo-form px-4 py-4 pt-5 w-100"
            onSubmit={handleSubmit}
          >
            <div className="debtor-content">
              <Row>
                <DebtorTypeDropdownField
                  debtorTypes={debtorTypes}
                  isAdmin={isAdmin}
                  isDebtorTypeLoading={isDebtorTypeLoading}
                />

                <VatNumberInputField debtorType={debtorType} />

                <CompanyNameInputField isAdmin={isAdmin} />

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="phone"
                      type="text"
                      placeholderTranslation="attributes.phone"
                      component={renderInputField}
                      labelTranslation="attributes.phone"
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="mobile"
                      type="text"
                      placeholderTranslation="attributes.mobile"
                      component={renderInputField}
                      labelTranslation="attributes.mobile"
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="email"
                      type="email"
                      placeholderTranslation="attributes.email"
                      component={renderInputField}
                      validate={(value) => [required(value), email(value)]}
                      labelTranslation="attributes.email"
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="website"
                      type="text"
                      placeholderTranslation="attributes.website"
                      component={renderInputField}
                      labelTranslation="attributes.website"
                      validate={websiteValidation}
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="country_id"
                      component={renderDropdownField}
                      validate={required}
                      dropdownOptions={countries}
                      labelTranslation="attributes.country"
                      placeholderTranslation="attributes.country"
                      isLoading={isCountryFetching}
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="city"
                      type="text"
                      placeholderTranslation="attributes.city"
                      component={renderInputField}
                      validate={required}
                      labelTranslation="attributes.city"
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      type="text"
                      name="street"
                      component={renderInputField}
                      validate={required}
                      placeholderTranslation="attributes.address1"
                      labelTranslation="attributes.address1"
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="street2"
                      type="text"
                      placeholderTranslation="attributes.address2"
                      component={renderInputField}
                      labelTranslation="attributes.address2"
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="zip"
                      type="text"
                      placeholderTranslation="attributes.zipCode"
                      component={renderInputField}
                      validate={required}
                      labelTranslation="attributes.zipCode"
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="property_payment_term_id"
                      component={renderDropdownField}
                      validate={required}
                      dropdownOptions={paymentTerms}
                      placeholderTranslation="attributes.paymentTerm"
                      labelTranslation="attributes.paymentTerm"
                      isLoading={isPaymentTermFetching}
                      disabled={!isAdmin}
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Field
                      name="lang"
                      component={renderDropdownField}
                      validate={required}
                      dropdownOptions={languageOptions}
                      placeholderTranslation="attributes.language"
                      labelTranslation="attributes.language"
                    />
                  </FormGroup>
                </Col>

                <CustomerCheckboxField formType={formType} />
              </Row>
            </div>
            <div className="flexo-form-footer">
              <ReduxFormCommitButton
                title={<Translate value="common.save" />}
                submitting={submitting}
                pristine={pristine && formType !== FORM_TYPES.CREATE}
                isLoading={isLoading}
              />
            </div>
          </Form>
        );
      }}
    />
  );
};

VatNumberInputField.defaultProps = { debtorType: "" };
VatNumberInputField.propTypes = { debtorType: PropTypes.string };

DebtorTypeDropdownField.defaultProps = {
  isAdmin: false,
  debtorTypes: [],
  isDebtorTypeLoading: false,
};

DebtorTypeDropdownField.propTypes = {
  isAdmin: PropTypes.bool,
  debtorTypes: PropTypes.shape([]),
  isDebtorTypeLoading: PropTypes.bool,
};

CompanyNameInputField.defaultProps = { isAdmin: false };
CompanyNameInputField.propTypes = { isAdmin: PropTypes.bool };

CustomerCheckboxField.defaultProps = { formType: "" };
CustomerCheckboxField.propTypes = { formType: PropTypes.string };

DebtorForm.defaultProps = {
  isDebtorTypeLoading: false,
  isCountryFetching: false,
  isPaymentTermFetching: false,
  formType: "edit",
  initialValues: {},
};

DebtorForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  debtorTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  paymentTerms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isDebtorTypeLoading: PropTypes.bool,
  isCountryFetching: PropTypes.bool,
  isPaymentTermFetching: PropTypes.bool,
  currentUserRole: PropTypes.string.isRequired,
  formType: PropTypes.string,
  initialValues: PropTypes.shape({}),
};

export default connect((state) => {
  const { role: currentUserRole } = getCurrentUser(state);

  return {
    currentUserRole,
  };
})(DebtorForm);
