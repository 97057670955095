import React, { useState } from "react";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";
import { useField, useForm } from "react-final-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";

import { calculateSumOfObjectsArray } from "utils/agreement";
import { sortObjectArray } from "utils/utils";

import SubscriptionEditModal from "components/Agreement/Form/Subscription/SubscriptionEditModal/SubscriptionEditModal";
import SubscriptionAddModal from "components/Agreement/Form/Subscription/SubscriptionAddModal/SubscriptionAddModal";
import TranslateNumber from "components/i18n/TranslateNumber";

const EmptySubscriptionsContent = ({ filteredSubscriptions }) => {
  if (filteredSubscriptions.length) return null;

  return (
    <div
      data-testid="empty-subscription-ui"
      className="empty-screen text-center pt-5"
    >
      <div className="icon-holder mb-4 bg-danger">
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <h5>
        <Translate value="message.subscription.noSubscription" />
      </h5>
    </div>
  );
};

const SubscriptionList = ({
  fields,
  subscriptionType,
  subscriptionProducts,
  agreementStartDate,
}) => {
  const form = useForm();

  const {
    input: { value: subscriptionFromForm },
  } = useField("subscriptions");

  const {
    input: { value: endDate },
  } = useField("end_date");

  const {
    input: { value: temporarySubscription },
  } = useField("temporary_subscription");

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const toggleModalVisibility = () => {
    setIsAddModalVisible((previousVisibilityState) => !previousVisibilityState);
  };

  const resetTemporarySubscription = () => {
    toggleModalVisibility();

    form.change("temporary_subscription", {});
  };

  const handleSubscriptionCreate = () => {
    const newSubscription = {
      ...temporarySubscription,
      total_amount: 0,
      subscription_type: subscriptionType,
    };

    fields.push(newSubscription);

    resetTemporarySubscription();
  };

  const handleAddSubscriptionModalOpen = () => {
    const startDate = agreementStartDate || new Date();

    form.batch(() => {
      form.change("temporary_subscription.start_date", startDate);
      form.change("temporary_subscription.end_date", endDate);
    });

    toggleModalVisibility();
  };

  const subscriptions = subscriptionFromForm || [];

  const subscriptionList = subscriptions.map((subscription, index) => {
    const {
      id,
      subscription_lines: subscriptionLines,
      total_amount: totalAmount,
      subscription_type: currentSubscriptionType,
      start_date: subscriptionStartDate,
      end_date: subscriptionEndDate,
      property_editable: isPropertyEditable,
    } = subscription;

    if (currentSubscriptionType !== subscriptionType) {
      return null;
    }

    let propertiesContent = (
      <Translate value="message.subscription.noProperties" />
    );
    let monthlyPrice = totalAmount;
    let setupPrice = 0;

    const lineToDisplay =
      (subscriptionLines &&
        subscriptionLines.find(
          (subscriptionLine) => subscriptionLine.line_type === "subscription"
        )) ||
      [];

    if (Object.keys(lineToDisplay).length > 0) {
      const { subscription_line_properties: subscriptionLineProperties } =
        lineToDisplay;
      const sortedSubscriptionLineProperties = sortObjectArray(
        subscriptionLineProperties,
        "property"
      );

      propertiesContent = sortedSubscriptionLineProperties.map(
        (lineProperty) => {
          const { property, value } = lineProperty;
          const linePropertyValue = value || (
            <Translate value="common.noValue" />
          );

          if (property === "Topic") {
            return null;
          }

          return (
            <p key={`${index}-${property}`}>
              {`${property}: `}
              {linePropertyValue}
            </p>
          );
        }
      );

      const setupSubscriptionLines = subscriptionLines.filter(
        (subscriptionLine) => subscriptionLine.line_type === "setup"
      );
      const recurringSubscriptionLines = subscriptionLines.filter(
        (subscriptionLine) => subscriptionLine.line_type !== "setup"
      );

      setupPrice = calculateSumOfObjectsArray(setupSubscriptionLines, "price");
      monthlyPrice = calculateSumOfObjectsArray(
        recurringSubscriptionLines,
        "price"
      );
    }

    return (
      <tr key={index}>
        <td>{propertiesContent}</td>
        <td>
          <TranslateNumber value={monthlyPrice} minimumFractionDigits={2} />
        </td>
        <td>
          <TranslateNumber value={setupPrice} minimumFractionDigits={2} />
        </td>
        <td>
          <div className="d-flex justify-content-baseline">
            <SubscriptionEditModal
              dataTestId="subscription-edit-modal"
              subscription={subscriptions[index]}
              subscriptionProducts={subscriptionProducts}
              subscriptionIndex={index}
              agreementStartDate={agreementStartDate}
              subscriptionStartDate={subscriptionStartDate}
              subscriptionEndDate={subscriptionEndDate}
              isPropertyEditable={isPropertyEditable}
              id={id}
            />
            <button
              className="btn btn-danger table-action-btn"
              type="button"
              onClick={() => fields.remove(index)}
              hidden={id}
              data-cy={`subscriptions[${index}].remove-btn`}
              data-testid={`subscriptions[${index}].remove-btn`}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </td>
      </tr>
    );
  });

  // remove null OR subscriptions that doesn't belong to selected subscription type
  const filteredSubscriptions = subscriptionList.filter(Boolean);

  return (
    <div
      data-testid="subscription-list-table"
      className="table-responsive br-bottom subscription-list-table"
    >
      <EmptySubscriptionsContent
        filteredSubscriptions={filteredSubscriptions}
      />

      <SubscriptionAddModal
        dataTestId="subscription-add-modal"
        subscriptionProducts={subscriptionProducts}
        isModalVisible={isAddModalVisible}
        onModalCancel={resetTemporarySubscription}
        onSubscriptionCreate={handleSubscriptionCreate}
        agreementStartDate={agreementStartDate || new Date()}
      />

      <table className="w-100">
        <thead hidden={filteredSubscriptions.length === 0}>
          <tr>
            <th>
              <Translate value="attributes.properties" />
            </th>
            <th>
              <Translate value="attributes.monthlyPrice" />
            </th>
            <th>
              <Translate value="common.setupPrice" />
            </th>
            <th>
              <Translate value="common.action" />
            </th>
          </tr>
        </thead>
        <tbody>{filteredSubscriptions}</tbody>
      </table>
      <div className="px-3 pb-4 br-bottom">
        <button
          data-testid={`${fields.name}-add-btn`}
          onClick={handleAddSubscriptionModalOpen}
          type="button"
          className="btn btn-outline-primary w-100 py-3 mt-3"
          data-cy={`${fields.name}-add-btn`}
        >
          <FontAwesomeIcon icon={faPlus} className="me-3" />
          <Translate
            resource="subscription"
            value="title.resource.button.addResource"
          />
        </button>
      </div>
    </div>
  );
};

EmptySubscriptionsContent.defaultProps = { filteredSubscriptions: [] };
EmptySubscriptionsContent.propTypes = {
  filteredSubscriptions: PropTypes.shape([]),
};

SubscriptionList.defaultProps = {
  agreementStartDate: undefined,
};

SubscriptionList.propTypes = {
  subscriptionProducts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fields: PropTypes.shape({
    getAll: PropTypes.func,
    map: PropTypes.func,
    push: PropTypes.func,
    remove: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  subscriptionType: PropTypes.string.isRequired,
  agreementStartDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
};

export default SubscriptionList;
