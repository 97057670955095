import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { Translate } from "react-i18nify";

const CollapseItem = ({
  title,
  wrapperClass,
  headingSize,
  className,
  children,
  editUrl,
  setCollapse,
  collapse,
  id,
  dataCy,
}) => {
  const [toggle, setToggle] = useState(false);

  const collapseItems = (collapseItem) => {
    /* eslint no-param-reassign: "error" */
    Object.keys(collapse).forEach((key) => {
      collapseItem[key] = false;
    });

    return collapseItem;
  };

  useEffect(() => {
    if (collapse[id]) {
      setTimeout(() => {
        setToggle(true);
      }, 100);
    } else {
      setTimeout(() => {
        setToggle(false);
      }, 100);
    }
  }, [collapse[id]]);

  const editId = editUrl.split("/")[2];

  return (
    <div data-cy={dataCy} className={`card ${wrapperClass}`}>
      <div
        aria-hidden="true"
        className={`${
          collapse[id] ? "rounded-top" : "rounded"
        } card-header py-4 bg-white  card-head`}
        onClick={() => setCollapse({ ...collapseItems, [id]: !collapse[id] })}
      >
        <div className="rounded-bottom">
          <div className="d-md-flex align-items-center justify-content-between collapse-item">
            <h4 className={`mb-4 mb-md-0 ${headingSize}`}>{title}</h4>
            {toggle ? (
              <Link
                data-cy={`button-to-edit-subscription-product-${editId}`}
                to={editUrl}
                className="btn btn-outline-primary"
              >
                <FontAwesomeIcon icon={faPencilAlt} className="me-2" />
                <Translate value="common.edit" />
              </Link>
            ) : (
              <FontAwesomeIcon icon={faChevronDown} className="me-2" />
            )}
          </div>
        </div>
      </div>
      <div className={`${collapse[id] ? "grow" : "shrink"} rounded`}>
        <div className={`${className} card-body`}>{children}</div>
      </div>
    </div>
  );
};

CollapseItem.defaultProps = {
  id: undefined,
  title: undefined,
  wrapperClass: undefined,
  headingSize: undefined,
  className: undefined,
  editUrl: undefined,
  dataCy: "",
};

CollapseItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  wrapperClass: PropTypes.string,
  headingSize: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Element),
    PropTypes.arrayOf(PropTypes.instanceOf(Element)),
  ]).isRequired,
  editUrl: PropTypes.string,
  setCollapse: PropTypes.func.isRequired,
  collapse: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  dataCy: PropTypes.string,
};

export default CollapseItem;
