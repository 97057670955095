import React from "react";
import Skeleton from "react-loading-skeleton";

const InvoicesHeader = () => {
  return (
    <div className="d-md-flex justify-content-between p-4">
      <Skeleton height={90} width={275} className="m-0" />
      <Skeleton height={90} width={275} className="m-0" />
    </div>
  );
};

export default InvoicesHeader;
