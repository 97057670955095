import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { BusinessAdmins, Admins } from "constants/userRoles";
import { DETAILS, INDEX, CREDIT_NOTE } from "constants/routes/routesResources";

import CreditNotes from "views/CreditNotes/CreditNotesIndex";
import InvoiceDetail from "views/Invoice/InvoiceDetail/InvoiceDetail";

const BusinessAdminCreditNotes = BusinessAdmins(CreditNotes);
const AdminInvoiceDetail = Admins(InvoiceDetail);

const CreditNotesRoute = () => (
  <Routes>
    <Route path={INDEX} element={<BusinessAdminCreditNotes />} />
    <Route path={CREDIT_NOTE.CREDIT_NOTE_ID}>
      <Route index element={<Navigate to={DETAILS} replace />} />
      <Route path={DETAILS} element={<AdminInvoiceDetail />} />
    </Route>
  </Routes>
);

export default CreditNotesRoute;
