import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

const ResourceHeader = ({ showOnlyFilters, filterCount }) => {
  return (
    <div className="d-flex justify-content-between p-4">
      <div className="d-flex align-items-center">
        <Skeleton height={55} width={55} circle className="me-3" />
        {Array.from({ length: filterCount }, (_, index) => (
          <Skeleton key={index} height={30} width={100} className="me-2" />
        ))}
      </div>
      {!showOnlyFilters && (
        <div className="d-flex">
          <div className="d-flex align-items-center">
            <Skeleton height={55} width={55} circle className="me-3" />
            <div className="d-flex flex-column">
              <Skeleton height={30} width={100} />
              <Skeleton height={30} width={60} />
            </div>
          </div>
          <div className="d-flex align-items-center ms-5">
            <Skeleton height={55} width={55} circle className="me-3" />
            <div className="d-flex flex-column">
              <Skeleton height={30} width={200} />
              <Skeleton height={30} width={100} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ResourceHeader.defaultProps = {
  showOnlyFilters: false,
  filterCount: 0,
};

ResourceHeader.propTypes = {
  showOnlyFilters: PropTypes.bool,
  filterCount: PropTypes.number,
};

export default ResourceHeader;
