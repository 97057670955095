import { createSearchAction } from "redux-search";

import { ContactApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

export const CONTACT_FETCH_REQUEST = "CONTACT_FETCH_REQUEST";
export const CONTACT_FETCH_RECEIVED = "CONTACT_FETCH_RECEIVED";
export const CONTACT_FETCH_FAILED = "CONTACT_FETCH_FAILED";
export const CONTACT_FETCH_CLEAR_ERRORS = "CONTACT_FETCH_CLEAR_ERRORS";
export const CONTACT_UPDATE_REQUEST = "CONTACT_UPDATE_REQUEST";
export const CONTACT_UPDATE_RECEIVED = "CONTACT_UPDATE_RECEIVED";
export const CONTACT_UPDATE_FAILED = "CONTACT_UPDATE_FAILED";
export const CONTACT_SEARCH_REQUEST = "CONTACT_SEARCH_REQUEST";
export const CONTACT_TELECOM_USER_EMAILS_FETCH_REQUEST =
  "CONTACT_TELECOM_USER_EMAILS_FETCH_REQUEST";
export const CONTACT_TELECOM_USER_EMAILS_FETCH_RECEIVED =
  "CONTACT_TELECOM_USER_EMAILS_FETCH_RECEIVED";
export const CONTACT_TELECOM_USER_EMAILS_FETCH_FAILED =
  "CONTACT_TELECOM_USER_EMAILS_FETCH_FAILED";

export const searchContacts = createSearchAction("contacts");

// Fetch all contacts
export function contactFetchReceived(response) {
  return {
    type: CONTACT_FETCH_RECEIVED,
    contacts: response.data,
  };
}

export function contactFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: CONTACT_FETCH_FAILED,
    message,
  };
}

export function contactFetchClearErrors() {
  return {
    type: CONTACT_FETCH_CLEAR_ERRORS,
  };
}

export const contactFetchRequest = (debtorID) => (dispatch) => {
  dispatch({ type: CONTACT_FETCH_REQUEST });

  ContactApi.fetchAll(debtorID)
    .then((data) => dispatch(contactFetchReceived(data)))
    .catch((e) => {
      dispatch(contactFetchFailed(e));
    });
};

// Update
export function contactUpdateReceived(response) {
  return {
    type: CONTACT_UPDATE_RECEIVED,
    contacts: response.data,
  };
}

export function contactUpdateFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: CONTACT_UPDATE_FAILED,
    message,
  };
}

export const contactUpdateRequest = (debtorID, contacts) => (dispatch) => {
  dispatch({ type: CONTACT_UPDATE_REQUEST });

  ContactApi.updateAll(debtorID, contacts)
    .then((data) => dispatch(contactUpdateReceived(data)))
    .catch((e) => {
      dispatch(contactUpdateFailed(e));
    });
};

export const contactsSearchRequest = (value) => {
  const isSearching = Boolean(value);

  return {
    type: CONTACT_SEARCH_REQUEST,
    isSearching,
  };
};

// Fetch all emails of contacts configured as telecom user
export function contactTelecomUserEmailsFetchReceived(response) {
  return {
    type: CONTACT_TELECOM_USER_EMAILS_FETCH_RECEIVED,
    telecomUserEmails: response.data,
  };
}

export function contactTelecomUserEmailsFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: CONTACT_TELECOM_USER_EMAILS_FETCH_FAILED,
    message,
  };
}

export const contactTelecomUserEmailsFetchRequest =
  (debtorID) => (dispatch) => {
    dispatch({ type: CONTACT_TELECOM_USER_EMAILS_FETCH_REQUEST });

    ContactApi.fetchTelecomUserEmails(debtorID)
      .then((data) => dispatch(contactTelecomUserEmailsFetchReceived(data)))
      .catch((e) => {
        dispatch(contactTelecomUserEmailsFetchFailed(e));
      });
  };
