import React from "react";
import { Route, Routes } from "react-router-dom";

import { BusinessAdmins, Admins } from "constants/userRoles";
import {
  INDEX,
  NEW,
  EDIT,
  INVOICES,
  CREDIT_NOTES,
  AGREEMENT,
} from "constants/routes/routesResources";

import AgreementIndex from "views/Agreement/AgreementIndex";
import AgreementCreate from "views/Agreement/AgreementCreate";
import AgreementEdit from "views/Agreement/AgreementEdit";
import AgreementDetail from "views/Agreement/AgreementDetail/AgreementDetail";
import AgreementInvoiceIndex from "views/Agreement/Invoice/AgreementInvoiceIndex";
import AgreementCreditNoteIndex from "views/Agreement/CreditNote/AgreementCreditNoteIndex";

const AdminAgreementIndex = Admins(AgreementIndex);
const AdminAgreementCreate = Admins(AgreementCreate);
const AdminAgreementEdit = Admins(AgreementEdit);
const BusinessAdminAgreementDetail = BusinessAdmins(AgreementDetail);
const BusinessAdminAgreementInvoiceIndex = BusinessAdmins(
  AgreementInvoiceIndex
);
const BusinessAdminAgreementCreditNoteIndex = BusinessAdmins(
  AgreementCreditNoteIndex
);

const AgreementRoutes = () => (
  <Routes>
    <Route path={INDEX} element={<AdminAgreementIndex />} />
    <Route path={NEW} element={<AdminAgreementCreate />} />
    <Route path={AGREEMENT.AGREEMENT_ID}>
      <Route index element={<BusinessAdminAgreementDetail />} />
      <Route path={EDIT} element={<AdminAgreementEdit />} />
      <Route path={INVOICES} element={<BusinessAdminAgreementInvoiceIndex />} />
      <Route
        path={CREDIT_NOTES}
        element={<BusinessAdminAgreementCreditNoteIndex />}
      />
    </Route>
  </Routes>
);

export default AgreementRoutes;
