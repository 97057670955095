import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  singleSipDeviceFetchRequest,
  singleSipDeviceDeleteRequest,
} from "redux/actions/sipDevices";

import { USER_LICENSE } from "constants/routes";

import DidNumberDetailSkeleton from "components/Skeleton/ResourceDetail/DidNumberDetail/DidNumberDetailSkeleton";
import SipDeviceBasicDetail from "components/SipDevices/Detail/SipDeviceBasicDetail";
import DetailUIHeader from "components/Header/DetailUI/DetailUIHeader";
import FetchError from "components/Error/FetchError";
import DeleteModal from "components/Modal/DeleteModal";

const SipDeviceDetail = ({
  sipDevice,
  fetchSingleSipDevice,
  fetchError,
  isLoading,
  deleteSipDevice,
}) => {
  const navigate = useNavigate();
  const { sipDeviceID } = useParams();

  const { extension_number: extensionNumber } = sipDevice;

  const [modalData, setModalData] = useState();
  const [modalVisible, setModalVisibility] = useState(false);

  const toggle = () => {
    setModalData(sipDeviceID);
    setModalVisibility(!modalVisible);
  };

  const resetModalState = () => {
    setModalVisibility(false);
    setModalData();
  };

  const onSuccess = () => {
    const { user_license_id: userLicenseID } = sipDevice;

    const goBackUrl = USER_LICENSE.addId(
      USER_LICENSE.SIP_DEVICES,
      userLicenseID
    );

    navigate(goBackUrl, { replace: true });
  };

  const onConfirmDelete = (id) => {
    if (id) deleteSipDevice(id, onSuccess);
    resetModalState();
  };

  const fetchSipDeviceDetail = () => {
    fetchSingleSipDevice(sipDeviceID);
  };

  useEffect(() => {
    fetchSipDeviceDetail();
  }, [sipDeviceID]);

  if (fetchError) {
    return <FetchError onRetry={fetchSipDeviceDetail} />;
  }

  if (!sipDevice || sipDevice?.id !== parseInt(sipDeviceID, 10) || isLoading) {
    return <DidNumberDetailSkeleton />;
  }

  return (
    <div className="sip-device-detail w-100">
      <DeleteModal
        modalVisible={modalVisible}
        modalData={modalData}
        resourceName="sip device"
        toggleModal={toggle}
        onConfirm={onConfirmDelete}
        onCancel={resetModalState}
      />
      <DetailUIHeader
        headerTitle={extensionNumber}
        deletePerform="sipDevice:delete"
        handleDelete={toggle}
        hasDelete
      />
      <SipDeviceBasicDetail sipDevice={sipDevice} />
    </div>
  );
};

SipDeviceDetail.defaultProps = {
  fetchError: null,
  isLoading: false,
};

SipDeviceDetail.propTypes = {
  fetchError: PropTypes.string,
  deleteSipDevice: PropTypes.func.isRequired,
  sipDevice: PropTypes.shape({
    id: PropTypes.number,
    extension_number: PropTypes.number,
    user_license_id: PropTypes.number,
    ex_description: PropTypes.string,
    ex_name: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool,
  fetchSingleSipDevice: PropTypes.func.isRequired,
};

const mapStateToProps = ({ sipDevice: sipDeviceResource }) => {
  const { sipDevice, fetchError, isLoading } = sipDeviceResource;

  return {
    sipDevice,
    fetchError,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleSipDevice: (id) => {
    dispatch(singleSipDeviceFetchRequest(id));
  },
  deleteSipDevice: (id, cb) => {
    dispatch(singleSipDeviceDeleteRequest(id, cb));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SipDeviceDetail);
