import React, { useEffect } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { t, Translate } from "react-i18nify";
import PropTypes from "prop-types";

import UserSettingForm from "components/UserSetting/UserSettingForm";
import UserSettingSkeleton from "components/Skeleton/ResourceDetail/UserSetting/UserSettingSkeleton";

import {
  userSettingFetchRequest,
  userSettingUpdateRequest,
} from "redux/actions/userSetting";
import { settingFetchRequest } from "redux/actions/setting";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const UserSettingIndex = ({
  user,
  fetchUserSettings,
  fetchSettings,
  userSettings,
  settings,
  updateUserSettings,
  commitSuccess,
}) => {
  useEffect(() => {
    const { id } = user;

    fetchUserSettings(id);
  }, [user.id]);

  useEffect(() => {
    fetchSettings();
  }, []);

  const { id } = user;

  if (commitSuccess) {
    toastr.success(t("common.success"), t("users.message.settingUpdate"));
  }

  const handleUserSettingsUpdate = async (userID, values, registeredFields) => {
    let serverResponse = {};

    await updateUserSettings(userID, values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (!userSettings) {
    return <UserSettingSkeleton />;
  }

  if (settings.length === 0) {
    return (
      <div className="container-fluid">
        <div data-cy="user-settings-heading">
          <h3 className="mt-3">
            <Translate value="menuItem.userSetting" />
          </h3>
        </div>
        <UserSettingForm
          settings={settings}
          userSettings={userSettings}
          userID={id}
          updateUserSettings={handleUserSettingsUpdate}
        />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div data-cy="user-settings-heading">
        <h3 className="mt-3">
          <Translate value="menuItem.userSetting" />
        </h3>
      </div>
      <UserSettingForm
        settings={settings}
        userSettings={userSettings}
        userID={id}
        updateUserSettings={handleUserSettingsUpdate}
      />
    </div>
  );
};

UserSettingIndex.defaultProps = {
  userSettings: undefined,
};

UserSettingIndex.propTypes = {
  userSettings: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.number,
      setting_id: PropTypes.number,
      value: PropTypes.string,
    })
  ),
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      category: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.string),
      data_type: PropTypes.string,
      user_type: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  fetchUserSettings: PropTypes.func.isRequired,
  fetchSettings: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  updateUserSettings: PropTypes.func.isRequired,
  commitSuccess: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth, userSetting, setting }) => {
  const { currentUser: user } = auth;
  const { userSettings, commitSuccess, commitError, isLoading } = userSetting;

  return {
    userSettings,
    commitError,
    commitSuccess,
    isLoading,
    user,
    settings: setting.settings,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUserSettings: (userID) => {
    dispatch(userSettingFetchRequest(userID));
  },
  fetchSettings: () => {
    dispatch(settingFetchRequest());
  },
  updateUserSettings: async (userID, userSettings, callback) => {
    await dispatch(userSettingUpdateRequest(userID, userSettings, callback));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingIndex);
