import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  faUser,
  faEnvelope,
  faDollarSign,
  faCalendarDay,
  faBoxOpen,
  faHandsHelping,
} from "@fortawesome/free-solid-svg-icons";
import { Translate, t } from "react-i18nify";

import { AGREEMENT, USER } from "constants/routes";
import { RESOURCE_NAMES } from "constants/constant";

import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import TranslateNumber from "components/i18n/TranslateNumber";
import TranslateDate from "components/i18n/TranslateDate";

import HasPermission from "hoc/RbacCTA";

const UserLicenseAdditionalDetail = ({ userLicense }) => {
  const {
    subscription_line: {
      start_date: startDate,
      price,
      product_name: productName,
    },
    user: { id: userID, first_name: firstName, last_name: lastName, email },
    agreement_id: agreementID,
    agreement_number: agreementNumber,
  } = userLicense;

  const userEditPageLink = (
    <Link to={USER.addId(USER.EDIT, userID)} className="ml-1">
      {" ( "}
      <Translate value="userLicense.editUser" />
      {" )"}
    </Link>
  );

  const userDetails = [
    {
      icon: faUser,
      title: <Translate value="attributes.name" />,
      content: (
        <>
          <span>{`${firstName} ${lastName || ""}`}</span>
          {userEditPageLink}
        </>
      ),
      dataCyFieldName: "name",
    },
    {
      icon: faEnvelope,
      title: <Translate value="attributes.emailAddress" />,
      content: email,
      dataCyFieldName: "emailAddress",
    },
  ];

  const subscriptionDetails = [
    {
      icon: faHandsHelping,
      title: <Translate value="agreement.number" />,
      content: (
        <>
          {agreementNumber}
          <Link
            to={AGREEMENT.addId(AGREEMENT.SHOW, agreementID)}
            className="ml-1"
          >
            {" ( "}
            <Translate value="common.viewDetails" />
            {" )"}
          </Link>
        </>
      ),
      dataCyFieldName: "agreement-view-details",
    },
    {
      icon: faBoxOpen,
      title: <Translate value="attributes.product" />,
      content: productName,
      dataCyFieldName: "product",
    },
    {
      icon: faDollarSign,
      title: <Translate value="attributes.price" />,
      content: <TranslateNumber value={price} minimumFractionDigits={2} />,
      dataCyFieldName: "price",
    },
    {
      icon: faCalendarDay,
      title: <Translate value="attributes.startDate" />,
      content: <TranslateDate date={startDate} />,
      dataCyFieldName: "startDate",
    },
  ];

  return (
    <div>
      <CardWithHeader
        title={`${t("attributes.user")} ${t("common.detail")}`}
        className="shadow-sm"
        cardContent={userDetails}
        dataCy={RESOURCE_NAMES.USER_LICENSE}
      />

      <HasPermission perform="userLicense:subscriptionDetails:view">
        <CardWithHeader
          title={`${t("title.section.subscription")} ${t("common.detail")}`}
          className="shadow-sm"
          cardContent={subscriptionDetails}
          dataCy={RESOURCE_NAMES.USER_LICENSE}
        />
      </HasPermission>
    </div>
  );
};

UserLicenseAdditionalDetail.defaultProps = {};

UserLicenseAdditionalDetail.propTypes = {
  userLicense: PropTypes.shape({
    subscription_line: PropTypes.shape({
      start_date: PropTypes.string,
      price: PropTypes.number,
      product_name: PropTypes.string,
    }),
    user: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
    }),
    agreement_id: PropTypes.number,
    agreement_number: PropTypes.number,
  }).isRequired,
};

export default UserLicenseAdditionalDetail;
