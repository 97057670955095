import { createSearchAction } from "redux-search";
import { toastr } from "react-redux-toastr";

import { t } from "react-i18nify";

import { AgreementApi, DebtorApi } from "internal";

import {
  displayFetchErrorToaster,
  getServerError,
  getAndDisplayErrors,
} from "utils/utils";

import { DEFAULT_FILTER } from "constants/filters";
import { AGREEMENT_FILTERS } from "constants/constant";

export const AGREEMENT_FETCH_REQUEST = "AGREEMENT_FETCH_REQUEST";
export const AGREEMENT_FETCH_RECEIVED = "AGREEMENT_FETCH_RECEIVED";
export const AGREEMENT_FETCH_FAILED = "AGREEMENT_FETCH_FAILED";
export const AGREEMENT_UPDATE_REQUEST = "AGREEMENT_UPDATE_REQUEST";
export const AGREEMENT_UPDATE_RECEIVED = "AGREEMENT_UPDATE_RECEIVED";
export const AGREEMENT_UPDATE_FAILED = "AGREEMENT_UPDATE_FAILED";
export const AGREEMENT_CREATE_REQUEST = "AGREEMENT_CREATE_REQUEST";
export const AGREEMENT_CREATE_RECEIVED = "AGREEMENT_CREATE_RECEIVED";
export const AGREEMENT_CREATE_FAILED = "AGREEMENT_CREATE_FAILED";
export const SINGLE_AGREEMENT_FETCH_REQUEST = "SINGLE_AGREEMENT_FETCH_REQUEST";
export const SINGLE_AGREEMENT_FETCH_RECEIVED =
  "SINGLE_AGREEMENT_FETCH_RECEIVED";
export const SINGLE_AGREEMENT_FETCH_FAILED = "SINGLE_AGREEMENT_FETCH_FAILED";
export const AGREEMENT_FETCH_CLEAR_ERRORS = "AGREEMENT_FETCH_CLEAR_ERRORS";
export const AGREEMENT_SEARCH_REQUEST = "AGREEMENT_SEARCH_REQUEST";
export const AGREEMENT_OVERVIEW_REQUEST = "AGREEMENT_OVERVIEW_REQUEST";
export const AGREEMENT_OVERVIEW_RECEIVED = "AGREEMENT_OVERVIEW_RECEIVED";
export const AGREEMENT_OVERVIEW_FAILED = "AGREEMENT_OVERVIEW_FAILED";
export const AGREEMENT_DELETE_REQUEST = "AGREEMENT_DELETE_REQUEST";
export const AGREEMENT_DELETE_RECEIVED = "AGREEMENT_DELETE_RECEIVED";
export const AGREEMENT_DELETE_FAILED = "AGREEMENT_DELETE_FAILED";

export const DEBTOR_AGREEMENT_FETCH_REQUEST = "DEBTOR_AGREEMENT_FETCH_REQUEST";
export const DEBTOR_AGREEMENT_FETCH_RECEIVED =
  "DEBTOR_AGREEMENT_FETCH_RECEIVED";
export const DEBTOR_AGREEMENT_FETCH_FAILED = "DEBTOR_AGREEMENT_FETCH_FAILED";

export const SEARCH_AGREEMENTS_REQUEST = "SEARCH_AGREEMENTS_REQUEST";
export const SEARCH_AGREEMENTS_RECEIVED = "SEARCH_AGREEMENTS_RECEIVED";
export const SEARCH_AGREEMENTS_FAILED = "SEARCH_AGREEMENTS_FAILED";
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const CLEAR_AGREEMENTS = "CLEAR_AGREEMENTS";

export const searchAgreements = createSearchAction("agreements");

export function agreementFetchReceived(response) {
  return {
    type: AGREEMENT_FETCH_RECEIVED,
    agreements: response.data,
  };
}

export function agreementFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: AGREEMENT_FETCH_FAILED,
    message,
  };
}

export const agreementFetchRequest =
  (filter = AGREEMENT_FILTERS.ACTIVE, withDebtorName = true) =>
  (dispatch) => {
    dispatch({ type: AGREEMENT_FETCH_REQUEST });

    AgreementApi.fetchAll(withDebtorName, filter)
      .then((data) => dispatch(agreementFetchReceived(data)))
      .catch((errors) => {
        dispatch(agreementFetchFailed(errors));
      });
  };

// Update single agreement
export function agreementUpdateReceived(response) {
  return {
    type: AGREEMENT_UPDATE_RECEIVED,
    agreement: response.data,
  };
}

export function agreementUpdateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: AGREEMENT_UPDATE_FAILED,
    message: response.data,
  };
}

export const agreementUpdateRequest =
  (id, attributes, callback) => async (dispatch) => {
    dispatch({ type: AGREEMENT_UPDATE_REQUEST });

    await AgreementApi.update(id, attributes)
      .then((data) => {
        dispatch(agreementUpdateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(agreementUpdateFailed(errors, callback));
      });
  };

// Create new agreement
export function agreementCreateReceived(response) {
  return {
    type: AGREEMENT_CREATE_RECEIVED,
    agreement: response.data,
  };
}

export function agreementCreateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: AGREEMENT_CREATE_FAILED,
    message: response.data,
  };
}

export const agreementCreateRequest =
  (attributes, callback) => async (dispatch) => {
    dispatch({ type: AGREEMENT_CREATE_REQUEST });

    await AgreementApi.create(attributes)
      .then((data) => {
        dispatch(agreementCreateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(agreementCreateFailed(errors, callback));
      });
  };

// Fetch single agreement
export function singleAgreementFetchReceived(response) {
  return {
    type: SINGLE_AGREEMENT_FETCH_RECEIVED,
    agreement: response.data,
  };
}

export function singleAgreementFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: SINGLE_AGREEMENT_FETCH_FAILED,
    message,
  };
}

export const singleAgreementFetchRequest =
  (id, activeSubscriptions = false, withoutSubscriptions = false) =>
  (dispatch) => {
    dispatch({ type: SINGLE_AGREEMENT_FETCH_REQUEST });

    AgreementApi.fetchOne(id, activeSubscriptions, withoutSubscriptions)
      .then((data) => dispatch(singleAgreementFetchReceived(data)))
      .catch((errors) => {
        dispatch(singleAgreementFetchFailed(errors));
      });
  };

export const agreementDeleteReceived = (response, callback) => (dispatch) => {
  dispatch({
    type: AGREEMENT_DELETE_RECEIVED,
  });

  toastr.success(
    t("common.success"),
    t("toasterMessage.resource.delete", {
      resource: t("attributes.agreement"),
    })
  );

  if (typeof callback === "function") {
    callback();
  }
};

export const agreementDeleteFailed = (errorData) => {
  const { response } = errorData;
  const error = getServerError(response);
  const errorText =
    error === "not found" ? t("toasterMessage.processError") : error;

  displayFetchErrorToaster(errorText);

  return (dispatch) => {
    dispatch({
      type: AGREEMENT_DELETE_FAILED,
      message: errorText,
    });
  };
};

export const agreementDeleteRequest = (id, callback) => (dispatch) => {
  dispatch({
    type: AGREEMENT_DELETE_REQUEST,
  });

  AgreementApi.deleteOne(id)
    .then((data) => dispatch(agreementDeleteReceived(data, callback)))
    .catch((errors) => {
      dispatch(agreementDeleteFailed(errors));
    });
};

export function agreementFetchClearErrors() {
  return {
    type: AGREEMENT_FETCH_CLEAR_ERRORS,
  };
}

export function agreementSearchRequest(value) {
  const isSearching = Boolean(value);

  return {
    type: AGREEMENT_SEARCH_REQUEST,
    isSearching,
  };
}

export function agreementOverviewReceived(response) {
  return {
    type: AGREEMENT_OVERVIEW_RECEIVED,
    agreementOverview: response.data,
  };
}

export function agreementOverviewFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: AGREEMENT_OVERVIEW_FAILED,
    message,
  };
}

export const agreementOverviewRequest = (debtorID) => (dispatch) => {
  dispatch({ type: AGREEMENT_OVERVIEW_REQUEST });

  AgreementApi.overview(debtorID)
    .then((data) => dispatch(agreementOverviewReceived(data)))
    .catch((errors) => {
      dispatch(agreementOverviewFailed(errors));
    });
};

export const searchAgreementReceived = (response) => ({
  type: SEARCH_AGREEMENTS_RECEIVED,
  searchedAgreements: response.data,
});

export const searchAgreementFailed = (errorData) => {
  const message = getAndDisplayErrors(errorData);

  return {
    type: SEARCH_AGREEMENTS_RECEIVED,
    message,
  };
};

export const searchAgreementRequest = (searchText) => (dispatch) => {
  dispatch({ type: SEARCH_AGREEMENTS_REQUEST });

  AgreementApi.searchAgreements(searchText)
    .then((res) => {
      dispatch(searchAgreementReceived(res));
    })
    .catch((errors) => {
      dispatch(searchAgreementFailed(errors));
    });
};

export const clearAgreementSearch = () => ({
  type: CLEAR_SEARCH,
});

export const debtorAgreementReceived = (response) => ({
  type: DEBTOR_AGREEMENT_FETCH_RECEIVED,
  agreements: response.data,
});

export const debtorAgreementFailed = (errorData) => {
  const message = getAndDisplayErrors(errorData);

  return {
    type: DEBTOR_AGREEMENT_FETCH_FAILED,
    message,
  };
};

export const debtorAgreementRequest =
  (debtorID, filter = DEFAULT_FILTER) =>
  (dispatch) => {
    dispatch({
      type: DEBTOR_AGREEMENT_FETCH_REQUEST,
    });

    DebtorApi.activeAgreements(debtorID, filter)
      .then((response) => dispatch(debtorAgreementReceived(response)))
      .catch((errors) => {
        dispatch(debtorAgreementFailed(errors));
      });
  };

export const clearAgreements = () => ({
  type: CLEAR_AGREEMENTS,
});
