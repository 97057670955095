import { t } from "react-i18nify";

import { store } from "redux/store";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import "dayjs/locale/en-gb";
import "dayjs/locale/da";

dayjs.extend(localizedFormat);

const secondsToMinute = 60;
const secondsToHour = 3600;

export const convertToDuration = (secondsAmount, dateFormat = "m[m] ss[s]") => {
  if (!secondsAmount) return "00:00";

  const {
    i18n: { locale },
  } = store.getState();

  const selectedLocale = locale === "da-DK" ? "da" : "en-gb";

  const hour = Math.floor(secondsAmount / secondsToHour);
  const minute = Math.floor((secondsAmount % secondsToHour) / secondsToMinute);
  const second = secondsAmount % secondsToMinute;

  let formattedDuration = "";

  if (hour >= 1) {
    formattedDuration = dayjs()
      .locale(selectedLocale)
      .hour(hour)
      .minute(minute)
      .second(second)
      .format(dateFormat);
  } else {
    formattedDuration = dayjs()
      .locale(selectedLocale)
      .minute(minute)
      .second(second)
      .format(dateFormat);
  }

  return formattedDuration;
};

/**
 *
 * @param {number} seconds - value in second for the conversion to duration
 * @returns {string} formatted string value according to the seconds value provided
 */
export const getCallDuration = (seconds) => {
  if (seconds === 0) {
    // 0 sec
    return `0 ${t("timeUnit.sec")}`;
  }
  if (seconds < 60) {
    // if seconds === 5, 5 sec
    return `${seconds} ${t("sec")}`;
  }
  if (seconds > 60 && seconds < 3600) {
    // if seconds === 120, 2m 00s
    return convertToDuration(
      seconds,
      `m[${t("timeUnit.m")}] ss[${t("timeUnit.s")}]`
    );
  }

  // if seconds === 5400, 1h 30m 00s
  return convertToDuration(
    seconds,
    `h[${t("timeUnit.hr")}] m[${t("timeUnit.m")}] ss[${t("timeUnit.s")}]`
  );
};
