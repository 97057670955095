import { createSearchAction } from "redux-search";
import { toastr } from "react-redux-toastr";

import { t } from "react-i18nify";

import { PbxApi, DebtorApi } from "internal";

import {
  displayFetchErrorToaster,
  getServerError,
  getAndDisplayErrors,
} from "utils/utils";

import { DEFAULT_FILTER } from "constants/filters";

export const PBX_FETCH_REQUEST = "PBX_FETCH_REQUEST";
export const PBX_FETCH_RECEIVED = "PBX_FETCH_RECEIVED";
export const PBX_FETCH_FAILED = "PBX_FETCH_FAILED";
export const SINGLE_PBX_FETCH_REQUEST = "SINGLE_PBX_FETCH_REQUEST";
export const SINGLE_PBX_FETCH_RECEIVED = "SINGLE_PBX_FETCH_RECEIVED";
export const SINGLE_PBX_FETCH_FAILED = "SINGLE_PBX_FETCH_FAILED";

export const SINGLE_PBX_DELETE_REQUEST = "SINGLE_PBX_DELETE_REQUEST";
export const SINGLE_PBX_DELETE_RECEIVED = "SINGLE_PBX_DELETE_RECEIVED";
export const SINGLE_PBX_DELETE_FAILED = "SINGLE_PBX_DELETE_FAILED";

export const PBX_UPDATE_REQUEST = "PBX_UPDATE_REQUEST";
export const PBX_UPDATE_RECEIVED = "PBX_UPDATE_RECEIVED";
export const PBX_UPDATE_FAILED = "PBX_UPDATE_FAILED";
export const PBX_CREATE_REQUEST = "PBX_CREATE_REQUEST";
export const PBX_CREATE_RECEIVED = "PBX_CREATE_RECEIVED";
export const PBX_CREATE_FAILED = "PBX_CREATE_FAILED";
export const PBX_FETCH_CLEAR_ERRORS = "PBX_FETCH_CLEAR_ERRORS";
export const PBX_SEARCH_REQUEST = "PBX_SEARCH_REQUEST";

export const DEBTOR_PBX_FETCH_REQUEST = "DEBTOR_PBX_FETCH_REQUEST";
export const DEBTOR_PBX_FETCH_RECEIVED = "DEBTOR_PBX_FETCH_RECEIVED";
export const DEBTOR_PBX_FETCH_FAILED = "DEBTOR_PBX_FETCH_FAILED";
export const CLEAR_PBX = "CLEAR_PBX";

export const USER_LICENSES_PBX_FETCH_REQUEST =
  "USER_LICENSES_PBX_FETCH_REQUEST";
export const USER_LICENSES_PBX_FETCH_RECEIVED =
  "USER_LICENSES_PBX_FETCH_RECEIVED";
export const USER_LICENSES_PBX_FETCH_FAILED = "USER_LICENSES_PBX_FETCH_FAILED";

export const searchPbxs = createSearchAction("pbxs");

export function pbxFetchReceived(response) {
  return {
    type: PBX_FETCH_RECEIVED,
    pbxs: response.data,
  };
}

export function pbxFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: PBX_FETCH_FAILED,
    message,
  };
}

// delete single pbxs
export const singlePbxDeleteReceived = (response, cb) => (dispatch) => {
  dispatch({
    type: SINGLE_PBX_DELETE_RECEIVED,
  });

  toastr.success(
    t("common.success"),
    t("toasterMessage.resource.delete", {
      resource: t("attributes.pbx"),
    })
  );

  if (typeof cb === "function") {
    cb();
  }
};

export const singlePbxDeleteFailed = (errorData) => {
  const { response } = errorData;
  const error = getServerError(response);
  const errorText =
    error === "not found" ? t("toasterMessage.processError") : error;

  displayFetchErrorToaster(errorText);

  return (dispatch) => {
    dispatch({
      type: SINGLE_PBX_DELETE_FAILED,
      message: errorText,
    });
  };
};

export const singlePbxDeleteRequest = (id, cb) => (dispatch) => {
  dispatch({
    type: SINGLE_PBX_DELETE_REQUEST,
  });

  PbxApi.deleteOne(id)
    .then((data) => dispatch(singlePbxDeleteReceived(data, cb)))
    .catch((e) => {
      dispatch(singlePbxDeleteFailed(e));
    });
};

export function pbxFetchClearErrors() {
  return {
    type: PBX_FETCH_CLEAR_ERRORS,
  };
}

// Fetch all pbxs
export const pbxFetchRequest =
  (filter = DEFAULT_FILTER, dropdown = false) =>
  (dispatch) => {
    dispatch({ type: PBX_FETCH_REQUEST });

    PbxApi.fetchAll(filter, dropdown)
      .then((data) => dispatch(pbxFetchReceived(data)))
      .catch((e) => {
        dispatch(pbxFetchFailed(e));
      });
  };

// Fetch single pbx
export function singlePbxFetchReceived(response) {
  return {
    type: SINGLE_PBX_FETCH_RECEIVED,
    pbx: response.data,
  };
}

export function singlePbxFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: SINGLE_PBX_FETCH_FAILED,
    message,
  };
}

export const singlePbxFetchRequest = (id) => (dispatch) => {
  dispatch({ type: SINGLE_PBX_FETCH_REQUEST });

  PbxApi.fetchOne(id)
    .then((data) => dispatch(singlePbxFetchReceived(data)))
    .catch((e) => {
      dispatch(singlePbxFetchFailed(e));
    });
};

// Update a pbx
export function pbxUpdateReceived(response) {
  return {
    type: PBX_UPDATE_RECEIVED,
    pbx: response.data,
  };
}

export function pbxUpdateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: PBX_UPDATE_FAILED,
    message: response.data,
  };
}

export const pbxUpdateRequest =
  (id, attributes, callback) => async (dispatch) => {
    dispatch({ type: PBX_UPDATE_REQUEST });

    await PbxApi.update(id, attributes)
      .then((data) => {
        dispatch(pbxUpdateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(pbxUpdateFailed(errors, callback));
      });
  };

// Create a pbx
export function pbxCreateReceived(response) {
  return {
    type: PBX_CREATE_RECEIVED,
    pbx: response.data,
  };
}

export function pbxCreateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: PBX_CREATE_FAILED,
    message: response.data,
  };
}

export const pbxCreateRequest = (attributes, callback) => async (dispatch) => {
  dispatch({ type: PBX_CREATE_REQUEST });

  await PbxApi.create(attributes)
    .then((data) => {
      dispatch(pbxCreateReceived(data));
      callback();
    })
    .catch((errors) => {
      dispatch(pbxCreateFailed(errors, callback));
    });
};

export const pbxSearchRequest = (value) => {
  const isSearching = Boolean(value);

  return {
    type: PBX_SEARCH_REQUEST,
    isSearching,
  };
};

export const debtorPbxFetchReceived = (response) => ({
  type: DEBTOR_PBX_FETCH_RECEIVED,
  pbxs: response.data,
});

export const debtorPbxFetchFailed = (errorData) => {
  const message = getAndDisplayErrors(errorData);

  return {
    type: DEBTOR_PBX_FETCH_FAILED,
    message,
  };
};

export const debtorPbxFetchRequest =
  (debtorID, filter = DEFAULT_FILTER) =>
  (dispatch) => {
    dispatch({
      type: DEBTOR_PBX_FETCH_REQUEST,
    });

    DebtorApi.pbxs(debtorID, filter)
      .then((data) => dispatch(debtorPbxFetchReceived(data)))
      .catch((err) => {
        dispatch(debtorPbxFetchFailed(err));
      });
  };

export const clearPbx = () => ({
  type: CLEAR_PBX,
});

export const userLicensesPbxFetchReceived = (response) => ({
  type: USER_LICENSES_PBX_FETCH_RECEIVED,
  userLicenses: response.data,
});

export const userLicensesPbxFetchFailed = (errorData) => {
  const message = getAndDisplayErrors(errorData);

  return {
    type: USER_LICENSES_PBX_FETCH_FAILED,
    message,
  };
};

export const userLicensesPbxFetchRequest = (pbxID) => (dispatch) => {
  dispatch({
    type: USER_LICENSES_PBX_FETCH_REQUEST,
  });

  PbxApi.fetchUserLicenses(pbxID)
    .then((data) => dispatch(userLicensesPbxFetchReceived(data)))
    .catch((err) => {
      dispatch(userLicensesPbxFetchFailed(err));
    });
};
