import { PaymentTermApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

export const PAYMENT_TERM_FETCH_REQUEST = "PAYMENT_TERM_FETCH_REQUEST";
export const PAYMENT_TERM_FETCH_RECEIVED = "PAYMENT_TERM_FETCH_RECEIVED";
export const PAYMENT_TERM_FETCH_FAILED = "PAYMENT_TERM_FETCH_FAILED";
export const PAYMENT_TERM_FETCH_CLEAR_ERRORS =
  "PAYMENT_TERM_FETCH_CLEAR_ERRORS";

export function paymentTermFetchReceived(response) {
  return {
    type: PAYMENT_TERM_FETCH_RECEIVED,
    paymentTerms: response.data,
  };
}

export function paymentTermFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: PAYMENT_TERM_FETCH_FAILED,
    message,
  };
}

export function paymentTermFetchClearErrors() {
  return {
    type: PAYMENT_TERM_FETCH_CLEAR_ERRORS,
  };
}

export const paymentTermFetchRequest = () => (dispatch) => {
  dispatch({ type: PAYMENT_TERM_FETCH_REQUEST });

  PaymentTermApi.fetchAll()
    .then((data) => dispatch(paymentTermFetchReceived(data)))
    .catch((e) => {
      dispatch(paymentTermFetchFailed(e));
    });
};
