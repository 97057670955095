import React from "react";
import { Translate, t } from "react-i18nify";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

const NetworkOperatorSubscriptionServicesEmptyUI = () => (
  <div className="p-4 h-100 shadow-sm bg-white d-flex flex-column align-items-center justify-content-center empty-table">
    <div className="info-icon-wrapper border-danger mb-2">
      <FontAwesomeIcon className="info-icon text-danger" icon={faInfo} />
    </div>
    <div className="text-center fw-bold">
      <p className="d-inline-block mb-2">
        <Translate
          value="title.resource.info.noResources"
          resource={t(`common.services`)}
        />
      </p>
    </div>
  </div>
);

const NetworkOperatorSubscriptionServicesTable = ({
  networkOperatorSubscriptionServices,
}) => {
  if (networkOperatorSubscriptionServices.length === 0) {
    return <NetworkOperatorSubscriptionServicesEmptyUI />;
  }

  return (
    <div className="pt-0 px-0 table-responsive modal-body">
      <table className="subscription-list-table shadow-sm w-100 mt-3">
        <thead>
          <tr className="thead fw-bold text-center border border-bottom-0">
            <th>
              <p className="mb-0 py-1">
                <Translate value="attributes.services" />
              </p>
            </th>
            <th>
              <p className="mb-0 py-1">
                <Translate value="attributes.location" />
              </p>
            </th>
            <th>
              <p className="mb-0 py-1 ">
                <Translate value="attributes.locationConstraint" />
              </p>
            </th>
          </tr>
        </thead>

        <tbody className="border border-1">
          {networkOperatorSubscriptionServices.map(
            ({
              service_name: serviceName,
              location,
              location_constraint: locationConstraint,
            }) => (
              <tr className="product-list fw-normal" key={serviceName}>
                <td className="align-middle text-center">
                  <p className="mb-0">{serviceName}</p>
                </td>
                <td className="align-middle text-center">
                  <p className="mb-0">
                    <Translate
                      value={`cellPhone.networkServices.location.${location}`}
                    />
                  </p>
                </td>
                <td className="align-middle text-center">
                  <p className="mb-0">
                    <Translate
                      value={`cellPhone.networkServices.locationConstraint.${locationConstraint}`}
                    />
                  </p>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

NetworkOperatorSubscriptionServicesTable.defaultProps = {};

NetworkOperatorSubscriptionServicesTable.propTypes = {
  networkOperatorSubscriptionServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      service_name: PropTypes.string,
      location: PropTypes.string,
      location_constraint: PropTypes.string,
    })
  ).isRequired,
};

export default NetworkOperatorSubscriptionServicesTable;
