import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { NavItem } from "reactstrap";
import { Translate, translate } from "react-i18nify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Tooltip from "components/Tooltip/CustomTooltip";

const MenuNavItem = ({ to, icon, name, menuMinimized, closeMenu, ...rest }) => (
  <NavItem onClick={closeMenu}>
    <Tooltip title={translate(name)} show={menuMinimized}>
      <NavLink
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...rest}
        to={to}
        className="d-flex align-items-center nav-link"
      >
        <div className="menu-icon">
          <FontAwesomeIcon icon={icon} />
        </div>
        <span className="ps-3">
          <Translate value={name} />
        </span>
      </NavLink>
    </Tooltip>
  </NavItem>
);

MenuNavItem.defaultProps = {
  icon: "",
  closeMenu: () => {},
};

MenuNavItem.propTypes = {
  to: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.shape({ fas: PropTypes.string }),
    PropTypes.string,
  ]),
  closeMenu: PropTypes.func,
  menuMinimized: PropTypes.bool.isRequired,
};

export default MenuNavItem;
