import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { Translate } from "react-i18nify";
import {
  faEnvelope,
  faPager,
  faIdBadge,
  faMobileAlt,
  faHourglassStart,
  faHourglassEnd,
  faGaugeHigh,
} from "@fortawesome/free-solid-svg-icons";

import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import DidNumberDetailSkeleton from "components/Skeleton/ResourceDetail/DidNumberDetail/DidNumberDetailSkeleton";
import FetchError from "components/Error/FetchError";
import DetailUIHeader from "components/Header/DetailUI/DetailUIHeader";

import { fetchVoicemailBoxRequest } from "redux/actions/voicemailBox";

import { CELL_PHONE, USER_LICENSE, VOICEMAIL_BOX } from "constants/routes";
import { RESOURCE_NAMES } from "constants/constant";

import { getCallDuration } from "utils/timeFormatter";

const VoicemailBoxDetail = ({
  fetchSingleVoicemailBox,
  voicemailBox,
  fetchError,
  isLoading,
}) => {
  const { voicemailBoxID } = useParams();

  const {
    email,
    extension_number: extensionNumber,
    fullname,
    maxmsg,
    maxsecs,
    minsecs,
    parent_record_id: parentRecordID,
    cell_number: cellNumber,
  } = voicemailBox;

  const basicDetailContent = [
    {
      icon: faPager,
      title: <Translate value="attributes.name" />,
      content: fullname,
      dataCyFieldName: "name",
    },
    {
      icon: faEnvelope,
      title: <Translate value="attributes.emailAddress" />,
      content: email,
      dataCyFieldName: "emailAddress",
    },
    extensionNumber && {
      icon: faIdBadge,
      title: <Translate value="attributes.userLicense" />,
      content: (
        <>
          {extensionNumber}
          <Link
            to={USER_LICENSE.addId(USER_LICENSE.SHOW, parentRecordID)}
            className="ml-1"
          >
            {" ( "}
            <Translate value="common.viewDetails" />
            {" )"}
          </Link>
        </>
      ),
      dataCyFieldName: "view-details",
    },
    cellNumber && {
      icon: faMobileAlt,
      title: <Translate value="attributes.cellPhone" />,
      content: (
        <>
          {cellNumber}
          <Link
            to={CELL_PHONE.addId(CELL_PHONE.SHOW, parentRecordID)}
            className="ml-1"
          >
            {" ( "}
            <Translate value="common.viewDetails" />
            {" )"}
          </Link>
        </>
      ),
      dataCyFieldName: "cellPhone-view-details",
    },
  ];

  const additionalContent = [
    {
      icon: faGaugeHigh,
      title: <Translate value="attributes.maximumNumberOfAllowedMessages" />,
      content: maxmsg,
      dataCyFieldName: "maximumNumberOfAllowedMessages",
    },
    {
      icon: faHourglassEnd,
      title: <Translate value="attributes.minimumLengthOfAMessage" />,
      content: minsecs && getCallDuration(minsecs),
      dataCyFieldName: "minimumLengthOfAMessage",
    },
    {
      icon: faHourglassStart,
      title: <Translate value="attributes.maximumLengthOfAMessage" />,
      content: maxsecs && getCallDuration(maxsecs),
      dataCyFieldName: "maximumLengthOfAMessage",
    },
  ];

  const fetchVoicemailBox = () => {
    fetchSingleVoicemailBox(voicemailBoxID);
  };

  useEffect(() => {
    fetchVoicemailBox();
  }, [voicemailBoxID]);

  if (fetchError) {
    return <FetchError onRetry={fetchVoicemailBox} />;
  }

  if (isLoading || !voicemailBox) {
    return <DidNumberDetailSkeleton />;
  }

  return (
    <div>
      <DetailUIHeader
        headerTitle={extensionNumber || cellNumber}
        editPath={VOICEMAIL_BOX.addId(VOICEMAIL_BOX.EDIT, voicemailBoxID)}
      />
      <CardWithHeader
        title={<Translate value="common.basicDetails" />}
        className="shadow-sm"
        cardContent={basicDetailContent}
        dataCy={RESOURCE_NAMES.USER_LICENSE}
      />

      <CardWithHeader
        title={<Translate value="common.voicemailMessageDetails" />}
        className="shadow-sm"
        cardContent={additionalContent}
        dataCy={RESOURCE_NAMES.USER_LICENSE}
      />
    </div>
  );
};

VoicemailBoxDetail.defaultProps = {
  fetchError: null,
};

VoicemailBoxDetail.propTypes = {
  fetchSingleVoicemailBox: PropTypes.func.isRequired,
  voicemailBox: PropTypes.shape({
    email: PropTypes.string,
    extension_number: PropTypes.number,
    fullname: PropTypes.string,
    maxmsg: PropTypes.number,
    maxsecs: PropTypes.number,
    minsecs: PropTypes.number,
    parent_record_id: PropTypes.number,
    cell_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  fetchError: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ voicemailBox: voicemailBoxResource }) => {
  const { voicemailBox, fetchError, isLoading } = voicemailBoxResource;

  return {
    voicemailBox,
    fetchError,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleVoicemailBox: (id) => {
    dispatch(fetchVoicemailBoxRequest(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VoicemailBoxDetail);
