import React, { useState } from "react";
import { FormGroup, Row, Col, Label, Input } from "reactstrap";
import { Translate, t } from "react-i18nify";
import { Field, useForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import PropTypes from "prop-types";

import { FORM_TYPES } from "constants/form";
import { storeFaxOptions } from "constants/dropdownOptions";

import { required, email } from "utils/validation";

import {
  renderInputField,
  renderDropdownField,
  renderOptions,
} from "components/ReduxForm/RenderField";

const RenderFaxName = ({ formType }) => {
  if (formType !== FORM_TYPES.EDIT) return null;

  return (
    <Col md={6}>
      <FormGroup>
        <Field
          name="di_faxstationid"
          component={renderInputField}
          validate={required}
          placeholderTranslation="attributes.faxName"
          labelTranslation="attributes.faxName"
        />
      </FormGroup>
    </Col>
  );
};

const RenderFaxAttributes = ({ isFaxEnabled, formType }) => {
  if (!isFaxEnabled) return null;

  return (
    <>
      <Col md={12}>
        <hr />
      </Col>

      <RenderFaxName formType={formType} />

      <Col md={12}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>
                <Translate value="faxService.senderEmails" />
              </Label>
            </FormGroup>

            <FormGroup className="setting-options">
              <div className="add-options">
                <FieldArray
                  name="fax_service.accept_emails_from"
                  component={renderOptions}
                  placeholder="fax-sender@email.com"
                  addFieldText={`${t("common.addNew")} ${t(
                    "attributes.email"
                  )}`}
                  validation={[required, email]}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Col>

      <Col md={6}>
        <FormGroup>
          <Field
            name="di_fax_store"
            component={renderDropdownField}
            dropdownOptions={storeFaxOptions}
            validate={required}
            labelTranslation="faxService.storeInServer"
          />
        </FormGroup>
      </Col>
    </>
  );
};

const FaxServiceSection = ({ formType, isFaxServiceEnabled }) => {
  const form = useForm();

  const [isFaxEnabled, setIsFaxEnabled] = useState(
    isFaxServiceEnabled || false
  );

  const handleEnableFax = ({ target }) => {
    const { checked } = target;

    if (!checked) form.change("fax_service_attributes", null);

    setIsFaxEnabled(checked);
  };

  return (
    <Row>
      <Col md="12">
        <h4 className="mt-3 mb-3">
          <Translate value="title.section.faxService" />
        </h4>
        <hr />
      </Col>

      <Col md="12" className="mb-2">
        <FormGroup check>
          <Label for="enable-fax" check>
            <Input
              name="enable-fax"
              type="checkbox"
              onClick={handleEnableFax}
              checked={isFaxEnabled}
            />
            {`${t("common.enable")} ${t("title.section.faxService")}`}
          </Label>
        </FormGroup>
      </Col>

      <RenderFaxAttributes formType={formType} isFaxEnabled={isFaxEnabled} />
    </Row>
  );
};

RenderFaxAttributes.defaultProps = { isFaxEnabled: false, formType: "create" };

RenderFaxAttributes.propTypes = {
  isFaxEnabled: PropTypes.bool,
  formType: PropTypes.string,
};

RenderFaxName.defaultProps = { formType: "create" };

RenderFaxName.propTypes = { formType: PropTypes.string };

FaxServiceSection.defaultProps = {
  formType: "create",
  isFaxServiceEnabled: false,
};

FaxServiceSection.propTypes = {
  formType: PropTypes.string,
  isFaxServiceEnabled: PropTypes.bool,
};

export default FaxServiceSection;
