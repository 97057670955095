import React from "react";
import PropTypes from "prop-types";

const ItemPills = ({ propertiesArray }) => (
  <div>
    {propertiesArray.map((property) => (
      <span
        key={property}
        className="badge rounded-pill bg-light border py-2 px-3 me-2 text-dark"
      >
        {property}
      </span>
    ))}
  </div>
);

ItemPills.defaultProps = {};

ItemPills.propTypes = {
  propertiesArray: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ItemPills;
