import React from "react";
import { Col, FormGroup, Row } from "reactstrap";
import { Field, useField } from "react-final-form";
import PropTypes from "prop-types";

import { renderInputField } from "components/ReduxForm/RenderField";
import {
  composeValidators,
  phoneNumberValidation,
  minValue,
  required,
  minPhoneNumber,
} from "utils/validation";

const NumberType = ({ isNumberRange, isEditForm }) => {
  const {
    input: { value: phoneNumberStartRange },
  } = useField("phone_number_range_start");

  if (!isNumberRange || isEditForm) {
    return (
      <Col md="6">
        <FormGroup>
          <Field
            name="phone_number"
            component={renderInputField}
            validate={composeValidators(
              required,
              phoneNumberValidation,
              minPhoneNumber(8)
            )}
            labelTranslation="attributes.number"
            placeholderTranslation="attributes.number"
          />
        </FormGroup>
      </Col>
    );
  }

  const minimumValue =
    phoneNumberStartRange.charAt(0) === "+"
      ? Number(phoneNumberStartRange.substring(1))
      : Number(phoneNumberStartRange);

  return (
    <Col md="6">
      <Row>
        <Col md="6">
          <FormGroup>
            <Field
              name="phone_number_range_start"
              component={renderInputField}
              validate={composeValidators(
                required,
                phoneNumberValidation,
                minPhoneNumber(8)
              )}
              labelTranslation="attributes.startingNumber"
              placeholderTranslation="attributes.startingNumber"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Field
              name="phone_number_range_end"
              component={renderInputField}
              validate={composeValidators(
                required,
                phoneNumberValidation,
                minPhoneNumber(8),
                minValue(minimumValue + 1)
              )}
              labelTranslation="attributes.endingNumber"
              placeholderTranslation="attributes.endingNumber"
              min={minimumValue + 1}
            />
          </FormGroup>
        </Col>
      </Row>
    </Col>
  );
};

NumberType.defaultProps = {
  isEditForm: false,
  isNumberRange: false,
};

NumberType.propTypes = {
  isNumberRange: PropTypes.bool,
  isEditForm: PropTypes.bool,
};

export default NumberType;
