import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.odooEndPoint()}/products/:productID/context_properties`,
};

class ContextPropertyApi extends AuthenticatedRequest {
  /**
   * Fetch all context properties of a product
   *
   * @param {int} productID ID of a product for which context properties are to be fetched
   *
   */
  static fetchAll(productID, contextPropertiesIds) {
    const fetchUrl = URLS.INDEX.replace(":productID", productID);

    return this.get(
      `${fetchUrl}?context_properties_ids=[${contextPropertiesIds}]`
    );
  }
}

export default ContextPropertyApi;
