import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { Navigate, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Translate, t } from "react-i18nify";

import {
  debtorUpdateRequest,
  singleDebtorFetchRequest,
} from "redux/actions/debtor";
import { debtorTypeFetchRequest } from "redux/actions/debtorType";
import { countryFetchRequest } from "redux/actions/country";
import { paymentTermFetchRequest } from "redux/actions/paymentTerm";

import DebtorForm from "components/Debtor/Form/DebtorForm";
import WholePageSpinner from "components/Spinner/WholePageSpinner";

import HasPermission from "hoc/RbacCTA";

import { formatDropdownOptions } from "utils/utils";
import { processServerResponseForAsyncValidationErrors } from "utils/forms";

import { DEBTOR, VENDORS } from "constants/routes";

const DebtorEdit = ({
  fetchSingleDebtor,
  fetchDebtorTypes,
  fetchCountries,
  fetchPaymentTerms,
  updateDebtor,
  debtor,
  isLoading,
  debtorTypes,
  isDebtorTypeFetching,
  countries,
  isCountryFetching,
  paymentTerms,
  isPaymentTermFetching,
  commitSuccess,
  commitError,
}) => {
  const { debtorID, vendorID } = useParams();

  const filterID = debtorID || vendorID;

  useEffect(() => {
    fetchSingleDebtor(filterID);
  }, [filterID]);

  useEffect(() => {
    fetchDebtorTypes();
    fetchCountries();
    fetchPaymentTerms();
  }, []);

  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    await updateDebtor(filterID, values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess && commitError.length === 0) {
    const { id } = debtor;
    const debtorTranslation = t("attributes.debtor");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.update", {
        resource: debtorTranslation,
      })
    );

    return (
      <Navigate
        to={
          vendorID
            ? VENDORS.addId(VENDORS.DETAIL, id)
            : DEBTOR.addId(DEBTOR.DETAIL, id)
        }
      />
    );
  }

  if (!debtor) {
    return <WholePageSpinner />;
  }

  return (
    <HasPermission
      perform="debtors:edit"
      data={{
        debtorID: debtor.id,
      }}
      redirect
    >
      <div className="container-fluid">
        <div>
          <h3 className="mt-3">
            <Translate
              value="title.page.resource.edit"
              resource={debtor.name}
            />
          </h3>
        </div>

        <Row>
          <Col lg="12">
            <DebtorForm
              onSubmit={handleSubmit}
              formName="debtorForm"
              initialValues={debtor}
              debtorTypes={formatDropdownOptions(debtorTypes)}
              countries={formatDropdownOptions(countries)}
              paymentTerms={formatDropdownOptions(paymentTerms)}
              isLoading={isLoading}
              isDebtorTypeFetching={isDebtorTypeFetching}
              isCountryFetching={isCountryFetching}
              isPaymentTermFetching={isPaymentTermFetching}
            />
          </Col>
        </Row>
      </div>
    </HasPermission>
  );
};

DebtorEdit.defaultProps = {
  debtor: undefined,
  isLoading: true,
  isDebtorTypeFetching: true,
  isPaymentTermFetching: true,
  isCountryFetching: true,
  commitError: "",
};

DebtorEdit.propTypes = {
  commitError: PropTypes.string,
  commitSuccess: PropTypes.bool.isRequired,
  fetchSingleDebtor: PropTypes.func.isRequired,
  updateDebtor: PropTypes.func.isRequired,
  debtor: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    vat: PropTypes.string,
    phone: PropTypes.string,
    street: PropTypes.string,
    street2: PropTypes.string,
    mobile: PropTypes.string,
    website: PropTypes.string,
    zip: PropTypes.string,
    city: PropTypes.string,
    lang: PropTypes.string,
    property_payment_term_id: PropTypes.number,
    company_id: PropTypes.number,
    country_id: PropTypes.number,
    is_company: PropTypes.bool,
    property_account_position_id: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
  fetchDebtorTypes: PropTypes.func.isRequired,
  debtorTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isDebtorTypeFetching: PropTypes.bool,
  countries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isCountryFetching: PropTypes.bool,
  paymentTerms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isPaymentTermFetching: PropTypes.bool,
  fetchCountries: PropTypes.func.isRequired,
  fetchPaymentTerms: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { debtor, isLoading, commitSuccess, commitError } = state.debtor;
  const { debtorTypes, isLoading: isDebtorTypeFetching } = state.debtorType;
  const { countries, isLoading: isCountryFetching } = state.country;
  const { paymentTerms, isLoading: isPaymentTermFetching } = state.paymentTerm;

  return {
    debtor,
    commitSuccess,
    commitError,
    isLoading,
    debtorTypes,
    isDebtorTypeFetching,
    countries,
    isCountryFetching,
    paymentTerms,
    isPaymentTermFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleDebtor: (id) => {
    dispatch(singleDebtorFetchRequest(id));
  },
  updateDebtor: async (id, attributes, callback) => {
    await dispatch(debtorUpdateRequest(id, attributes, callback));
  },
  fetchDebtorTypes: () => {
    dispatch(debtorTypeFetchRequest());
  },
  fetchCountries: () => {
    dispatch(countryFetchRequest());
  },
  fetchPaymentTerms: () => {
    dispatch(paymentTermFetchRequest());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DebtorEdit);
