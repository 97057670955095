import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import { Translate, t } from "react-i18nify";

import {
  settingUpdateRequest,
  singleSettingFetchRequest,
  singleSettingDeleteRequest,
  clearSingleSetting,
} from "redux/actions/setting";

import HasPermission from "hoc/RbacCTA";

import { SETTING } from "constants/routes";

import SettingForm from "components/Setting/Form/SettingForm";
import WholePageSpinner from "components/Spinner/WholePageSpinner";
import DeleteButton from "components/Button/DeleteButton";
import DeleteModal from "components/Modal/DeleteModal";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const AppSettingEdit = ({
  fetchSingleSetting,
  updateSetting,
  clearSetting,
  setting,
  isLoading,
  commitSuccess,
  deleteSetting,
}) => {
  const navigate = useNavigate();
  const { appSettingID } = useParams();

  const [modalData, setModalData] = useState();
  const [modalVisible, setModalVisibility] = useState(false);

  useEffect(() => {
    fetchSingleSetting(appSettingID);

    return () => {
      clearSetting();
    };
  }, [appSettingID]);

  const toggle = () => {
    setModalData(appSettingID);
    setModalVisibility(!modalVisible);
  };

  const resetModalState = () => {
    setModalVisibility(false);
    setModalData();
  };

  const onDeleteSuccess = () => {
    navigate("/settings/app", { replace: true });
  };

  const onConfirmDelete = (id) => {
    if (id) deleteSetting(id, onDeleteSuccess);

    resetModalState();
  };

  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    await updateSetting(appSettingID, values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (isLoading && !setting) {
    return <WholePageSpinner />;
  }

  if (commitSuccess) {
    return <Navigate to={SETTING.INDEX} />;
  }

  // Will need it later for redesign of ui
  // const settingList = settings.map(({ id, name }) => {
  //   return (
  //     <NavItem key={id}>
  //       <NavLink
  //         className="d-flex justify-content-between"
  //         to={`/settings/${id}`}
  //       >
  //         {name}
  //         {/* Delete will come in future */}
  //         {/* <Dropdown isOpen={dropdownOpen} toggle={dropDownToggle}>
  //               <DropdownToggle
  //                 tag="span"
  //                 data-toggle="dropdown"
  //                 aria-expanded={dropdownOpen}
  //               >
  //                 <FontAwesomeIcon icon={faEllipsisH} className="mr-0" />
  //               </DropdownToggle>
  //               <DropdownMenu right className="px-3 py-2">
  //                 <p>
  //                   <FontAwesomeIcon icon={faTrash} />
  //                   Delete
  //                 </p>
  //               </DropdownMenu>
  //             </Dropdown> */}
  //       </NavLink>
  //     </NavItem>
  //   );
  // });

  const formInitialValues = { ...setting };

  return (
    <div className="container-fluid">
      <DeleteModal
        modalVisible={modalVisible}
        modalData={modalData}
        resourceName="setting"
        toggleModal={toggle}
        onConfirm={onConfirmDelete}
        onCancel={resetModalState}
      />
      <div className="d-flex align-items-center justify-content-between">
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.edit"
            resource={t("title.resource.appSetting")}
          />
        </h3>
        <HasPermission perform="settings:delete">
          <DeleteButton handleDelete={toggle} />
        </HasPermission>
      </div>

      <Row>
        <Col lg="12">
          <SettingForm
            onSubmit={handleSubmit}
            formName="settingEditForm"
            initialValues={formInitialValues}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </div>
  );
};

AppSettingEdit.defaultProps = {
  setting: undefined,
  isLoading: true,
  commitError: {},
};

AppSettingEdit.propTypes = {
  fetchSingleSetting: PropTypes.func.isRequired,
  deleteSetting: PropTypes.func.isRequired,
  updateSetting: PropTypes.func.isRequired,
  commitError: PropTypes.shape({}),
  clearSetting: PropTypes.func.isRequired,
  setting: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    category: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string), // This can also hold number but they will also be in form of string due to the need of options being dynamic
    data_type: PropTypes.string,
    user_type: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  commitSuccess: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { setting, isLoading, commitSuccess, commitError } = state.setting;

  return {
    commitSuccess,
    commitError,
    setting,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleSetting: (id) => {
    dispatch(singleSettingFetchRequest(id));
  },
  clearSetting: () => {
    dispatch(clearSingleSetting());
  },
  updateSetting: async (id, attributes, callback) => {
    await dispatch(settingUpdateRequest(id, attributes, callback));
  },
  deleteSetting: (id, callback) => {
    dispatch(singleSettingDeleteRequest(id, callback));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSettingEdit);
