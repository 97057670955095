import {
  PBX_CELL_PHONE_FETCH_REQUEST,
  PBX_CELL_PHONE_FETCH_RECEIVED,
  PBX_CELL_PHONE_FETCH_FAILED,
  PBX_CELL_PHONE_SEARCH_REQUEST,
} from "redux/actions/pbx/cellPhone";

const defaultState = {
  cellPhones: [],
  commitError: "",
  fetchError: "",
  commitSuccess: false,
  isLoading: false,
  isSearching: false,
};

function cellPhone(state = defaultState, action) {
  const { cellPhones } = action;

  switch (action.type) {
    case PBX_CELL_PHONE_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitError: "",
      };

    case PBX_CELL_PHONE_FETCH_RECEIVED:
      return {
        ...state,
        cellPhones,
        isLoading: false,
      };

    case PBX_CELL_PHONE_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case PBX_CELL_PHONE_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    default:
      return state;
  }
}

export default cellPhone;
