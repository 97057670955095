import { DashboardApi } from "internal";
import { getAndDisplayErrors } from "utils/utils";

export const DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_REQUEST =
  "DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_REQUEST";
export const DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_RECEIVED =
  "DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_RECEIVED";
export const DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_FAILED =
  "DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_FAILED";
export const DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_CLEAR_ERRORS =
  "DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_CLEAR_ERRORS";

export function dashboardCellPhoneImportRequestFetchReceived(response) {
  return {
    type: DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_RECEIVED,
    data: response.data,
  };
}

export function dashboardCellPhoneImportRequestFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_FAILED,
    message,
  };
}

export function dashboardCellPhoneImportRequestFetchClearErrors() {
  return {
    type: DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_CLEAR_ERRORS,
  };
}

export const dashboardCellPhoneImportRequestFetchRequest = () => (dispatch) => {
  dispatch({ type: DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_REQUEST });

  DashboardApi.fetchCellPhoneImportRequests()
    .then((data) =>
      dispatch(dashboardCellPhoneImportRequestFetchReceived(data))
    )
    .catch((e) => {
      dispatch(dashboardCellPhoneImportRequestFetchFailed(e));
    });
};
