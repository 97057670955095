/* eslint-disable import/no-cycle */
import {
  AGREEMENT_INVOICE_FETCH_REQUEST,
  AGREEMENT_INVOICE_FETCH_RECEIVED,
  AGREEMENT_INVOICE_FETCH_FAILED,
  AGREEMENT_INVOICE_GENERATE_REQUEST,
  AGREEMENT_INVOICE_GENERATE_RECEIVED,
  AGREEMENT_INVOICE_GENERATE_FAILED,
  AGREEMENT_INVOICE_SEARCH_REQUEST,
} from "redux/actions/agreement/invoice";

const initialState = {
  invoices: [],
  invoiceID: undefined,
  isLoading: false,
  fetchError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AGREEMENT_INVOICE_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: false,
      };

    case AGREEMENT_INVOICE_FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        fetchError: true,
      };

    case AGREEMENT_INVOICE_FETCH_RECEIVED:
      return {
        ...state,
        isLoading: false,
        fetchError: false,
        invoices: action.data,
      };

    case AGREEMENT_INVOICE_GENERATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: false,
      };

    case AGREEMENT_INVOICE_GENERATE_FAILED:
      return {
        ...state,
        isLoading: false,
        fetchError: true,
      };

    case AGREEMENT_INVOICE_GENERATE_RECEIVED:
      return {
        ...state,
        isLoading: false,
        fetchError: false,
        invoiceID: action.data,
      };

    case AGREEMENT_INVOICE_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    default:
      return state;
  }
};
