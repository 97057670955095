import {
  faHandsHelping,
  faBriefcase,
  faReceipt,
  faTty,
  faPhone,
  faSimCard,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import { faHive } from "@fortawesome/free-brands-svg-icons";

import {
  DEBTOR,
  INVOICE,
  PBX,
  AGREEMENT,
  PHONE_NUMBER,
  SIM_CARD,
  NUMBER_PORTING_REQUEST,
  QUOTATION,
} from "constants/routes";

const menuListForCustomer = [
  {
    name: "menuItem.debtor",
    icon: faBriefcase,
    to: DEBTOR.INDEX,
    exact: false,
    hasPermission: false,
    perform: "",
  },
  {
    name: "menuItem.agreement",
    icon: faHandsHelping,
    to: AGREEMENT.INDEX,
    exact: false,
    hasPermission: false,
    perform: "",
  },
  {
    name: "menuItem.pbx",
    icon: faTty,
    to: PBX.INDEX,
    exact: false,
    hasPermission: false,
    perform: "",
  },
  {
    name: "menuItem.invoice",
    icon: faReceipt,
    to: INVOICE.INDEX,
    exact: false,
    hasPermission: false,
    perform: "",
  },
  {
    name: "menuItem.quotations",
    icon: faFileInvoice,
    to: QUOTATION.INDEX,
    exact: false,
    hasPermission: true,
    perform: "quotation:view",
  },
];

const menuForTelecom = [
  {
    name: "menuItem.phoneNumbers",
    icon: faPhone,
    to: PHONE_NUMBER.INDEX,
    exact: false,
    hasPermission: false,
    perform: "",
  },
  {
    name: "menuItem.simCards",
    icon: faSimCard,
    to: SIM_CARD.INDEX,
    exact: false,
    hasPermission: false,
    perform: "",
  },
  {
    to: NUMBER_PORTING_REQUEST.INDEX,
    name: "menuItem.numberPortingRequest",
    icon: faHive,
    exact: false,
    hasPermission: false,
    perform: "",
  },
];

const menuList = {
  customer: menuListForCustomer,
  telecom: menuForTelecom,
};

export default menuList;
