import {
  CREDIT_NOTE_FETCH_REQUEST,
  DEBTOR_CREDIT_NOTE_FETCH_REQUEST,
  CREDIT_NOTE_FETCH_RECEIVED,
  DEBTOR_CREDIT_NOTE_FETCH_RECEIVED,
  CREDIT_NOTE_FETCH_FAILED,
  DEBTOR_CREDIT_NOTE_FETCH_FAILED,
  CREDIT_NOTE_SEARCH_REQUEST,
  CREDIT_NOTE_FETCH_CLEAR_ERRORS,
} from "redux/actions/creditNote";

const defaultState = {
  creditNotes: [],
  fetchError: "",
  commitSuccess: false,
  isSearching: false,
};

function creditNote(state = defaultState, action) {
  switch (action.type) {
    case CREDIT_NOTE_FETCH_REQUEST:
    case DEBTOR_CREDIT_NOTE_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: null,
      };

    case CREDIT_NOTE_FETCH_RECEIVED:
    case DEBTOR_CREDIT_NOTE_FETCH_RECEIVED:
      return {
        ...state,
        creditNotes: action.creditNotes,
        isLoading: false,
        fetchError: null,
      };

    case CREDIT_NOTE_FETCH_FAILED:
    case DEBTOR_CREDIT_NOTE_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case CREDIT_NOTE_FETCH_CLEAR_ERRORS:
      return {
        ...state,
        fetchError: "",
        isLoading: false,
        commitSuccess: false,
      };

    case CREDIT_NOTE_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    default:
      return state;
  }
}

export default creditNote;
