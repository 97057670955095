import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.odooEndPoint()}/countries`,
};

class CountryApi extends AuthenticatedRequest {
  // Fetch all countries
  static fetchAll() {
    return this.get(URLS.INDEX);
  }
}

export default CountryApi;
