import {
  PBX_SIP_DEVICES_FETCH_REQUEST,
  PBX_SIP_DEVICES_FETCH_RECEIVED,
  PBX_SIP_DEVICES_FETCH_FAILED,
  PBX_SIP_DEVICES_SEARCH_REQUEST,
} from "redux/actions/pbx/sipDevice";

const defaultState = {
  sipDevices: [],
  fetchError: "",
  isLoading: false,
  isSearching: false,
};

function sipDevice(state = defaultState, action) {
  const { sipDevices } = action;

  switch (action.type) {
    case PBX_SIP_DEVICES_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
      };

    case PBX_SIP_DEVICES_FETCH_RECEIVED:
      return {
        ...state,
        sipDevices,
        isLoading: false,
      };

    case PBX_SIP_DEVICES_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case PBX_SIP_DEVICES_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    default:
      return state;
  }
}

export default sipDevice;
