import React, { useState } from "react";
import PropTypes from "prop-types";

import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Translate, translate } from "react-i18nify";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Tooltip from "components/Tooltip/CustomTooltip";

import HasPermission from "hoc/RbacCTA";

import MenuNavItem from "components/Menu/SideMenu/MenuItem";

const MenuItemCheckPermission = ({
  hasPermission,
  perform,
  to,
  name,
  icon,
  closeMenu,
  menuMinimized,
}) => {
  if (hasPermission)
    return (
      <MenuNavItem
        to={to}
        name={name}
        icon={icon}
        closeMenu={closeMenu}
        menuMinimized={menuMinimized}
      />
    );

  return (
    <HasPermission perform={perform}>
      <MenuNavItem
        to={to}
        name={name}
        icon={icon}
        closeMenu={closeMenu}
        menuMinimized={menuMinimized}
      />
    </HasPermission>
  );
};

MenuItemCheckPermission.defaultProps = {
  hasPermission: false,
  perform: null,
  to: null,
  name: null,
  icon: null,
  closeMenu: null,
  menuMinimized: null,
};

MenuItemCheckPermission.propTypes = {
  hasPermission: PropTypes.bool,
  perform: PropTypes.string,
  to: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  closeMenu: PropTypes.func,
  menuMinimized: PropTypes.bool,
};

const DropdownItemWithPermission = ({
  hasPermission,
  perform,
  to,
  name,
  icon,
  dataCy,
}) => {
  if (!hasPermission)
    return (
      <DropdownItem
        tag={Link}
        to={to}
        className="d-flex"
        data-cy={`${dataCy}-${name}`}
      >
        <div className="menu-icon-dropdown">
          <FontAwesomeIcon icon={icon} />
        </div>
        <Translate value={name} />
      </DropdownItem>
    );

  return (
    <HasPermission perform={perform}>
      <DropdownItem
        tag={Link}
        to={to}
        className="d-flex"
        data-cy={`${dataCy}-${name}`}
      >
        <div className="menu-icon-dropdown">
          <FontAwesomeIcon icon={icon} />
        </div>
        <Translate value={name} />
      </DropdownItem>
    </HasPermission>
  );
};

DropdownItemWithPermission.defaultProps = {
  hasPermission: false,
  perform: null,
  to: null,
  name: null,
  icon: null,
  dataCy: "",
};

DropdownItemWithPermission.propTypes = {
  hasPermission: PropTypes.bool,
  perform: PropTypes.string,
  to: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  dataCy: PropTypes.string,
};

const SubMenu = ({
  subMenuName,
  list,
  menuIcon,
  text,
  menuMinimized,
  dropdownListClassName,
  dataCy,
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { pathname } = useLocation();

  const isActiveRouteClassName = list.some(({ to: path }) => path === pathname)
    ? "active-path"
    : "";

  const toggle = () => setDropdownOpen((value) => !value);

  if (menuMinimized) {
    return (
      <div className="w-100 submenu-minimized mb-1">
        <Dropdown isOpen={isDropdownOpen} toggle={toggle} direction="end">
          <DropdownToggle
            data-cy={dataCy}
            className={`border-0 d-flex align-items-center p-0 me-0 rounded-circle justify-content-center ${isActiveRouteClassName}`}
            caret
          >
            <Tooltip title={translate(text)} show={!isDropdownOpen}>
              <div className="menu-icon me-0 ms-1">
                <FontAwesomeIcon icon={menuIcon} />
              </div>
            </Tooltip>
          </DropdownToggle>
          <div
            className={`sidebar-dropdown-menu submenu-minimized-list ${dropdownListClassName} position-fixed`}
          >
            <DropdownMenu>
              {list.map(
                ({ name, icon, to, exact, hasPermission, perform }, index) => (
                  <DropdownItemWithPermission
                    name={name}
                    icon={icon}
                    to={to}
                    exact={exact}
                    hasPermission={hasPermission}
                    perform={perform}
                    key={index}
                    dataCy={dataCy}
                  />
                )
              )}
            </DropdownMenu>
          </div>
        </Dropdown>
      </div>
    );
  }

  return (
    <>
      <div
        className="accordion w-100 sub-menu d-none d-md-block mb-1"
        id={`${subMenuName}Container`}
      >
        <div className="accordion-item border-0">
          <button
            className={`accordion-button sub-menu-button collapsed p-2 ${isActiveRouteClassName}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#${subMenuName}`}
            aria-expanded="true"
            aria-controls={subMenuName}
          >
            <div className="menu-icon mx-2">
              <FontAwesomeIcon icon={menuIcon} />
            </div>
            <span className="ps-3 ms-1">
              <Translate value={text} />
            </span>
          </button>
          <div
            id={`${subMenuName}`}
            className="accordion-collapse collapse"
            data-bs-parent={`#${`${subMenuName}Container`}`}
          >
            <ul className="accordion-body ps-3 pe-1">
              {list.map(
                ({ name, icon, to, exact, hasPermission, perform }, index) => (
                  <li>
                    <MenuItemCheckPermission
                      name={name}
                      icon={icon}
                      to={to}
                      exact={exact}
                      hasPermission={hasPermission}
                      perform={perform}
                      key={index}
                    />
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="d-block d-md-none">
        {list.map(
          ({ name, icon, to, exact, hasPermission, perform }, index) => (
            <MenuItemCheckPermission
              name={name}
              icon={icon}
              to={to}
              exact={exact}
              hasPermission={hasPermission}
              perform={perform}
              key={index}
            />
          )
        )}
      </div>
    </>
  );
};

SubMenu.defaultProps = {
  subMenuName: "",
  menuMinimized: false,
  text: "",
  menuIcon: {},
  dropdownListClassName: "",
  dataCy: "",
};

SubMenu.propTypes = {
  subMenuName: PropTypes.string,
  text: PropTypes.string,
  menuIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  menuMinimized: PropTypes.bool,
  list: PropTypes.shape([{}]).isRequired,
  dropdownListClassName: PropTypes.string,
  dataCy: PropTypes.string,
};

export default SubMenu;
