import React from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import { FormGroup, Col } from "reactstrap";

import { required } from "utils/validation";

import { SUBSCRIPTION_LINE_TYPE } from "constants/subscriptionLine";

import { renderInputField } from "components/ReduxForm/RenderField";

const SubscriptionLineProperty = ({ property, fields, index, lineType }) => {
  if (lineType === SUBSCRIPTION_LINE_TYPE.SUBSCRIPTION) {
    return (
      <Col md={6}>
        <FormGroup>
          <Field
            name={`${fields.name}[${index}].value`}
            type="text"
            component={renderInputField}
            label={property}
            key={`${fields.name}[${index}].property`}
            validate={required}
          />
        </FormGroup>
      </Col>
    );
  }

  return (
    <Field
      name={`${fields.name}[${index}].value`}
      type="text"
      component={renderInputField}
      label={property}
      key={`${fields.name}[${index}].property`}
      validate={required}
    />
  );
};

const SubscriptionLineProperties = ({ fields, lineType }) =>
  fields.value.map((subscriptionLineProperty, index) => {
    const { property } = subscriptionLineProperty;

    return (
      <SubscriptionLineProperty
        property={property}
        fields={fields}
        index={index}
        lineType={lineType}
      />
    );
  });

SubscriptionLineProperty.defaultProps = {};

SubscriptionLineProperty.propTypes = {
  fields: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.shape({
      property: PropTypes.string,
      value: PropTypes.string,
      map: PropTypes.func,
    }),
  }).isRequired,
  lineType: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  property: PropTypes.string.isRequired,
};

SubscriptionLineProperties.defaultProps = {};

SubscriptionLineProperties.propTypes = {
  fields: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.shape({
      property: PropTypes.string,
      value: PropTypes.string,
      map: PropTypes.func,
    }),
  }).isRequired,
  lineType: PropTypes.string.isRequired,
};

export default SubscriptionLineProperties;
