import { createSearchAction } from "redux-search";
import { toastr } from "react-redux-toastr";

import { t } from "react-i18nify";

import { NumberPortingRequestApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

import { NUMBER_PORTING_REQUEST_STATUSES } from "constants/constant";

import { NUMBER_PORTING_REQUEST_PHONE_NUMBER_RESET_STATES } from "redux/actions/numberPortingRequestPhoneNumber";

export const NUMBER_PORTING_REQUEST_FETCH_REQUEST =
  "NUMBER_PORTING_REQUEST_FETCH_REQUEST";
export const NUMBER_PORTING_REQUEST_FETCH_RECEIVED =
  "NUMBER_PORTING_REQUEST_FETCH_RECEIVED";
export const NUMBER_PORTING_REQUEST_FETCH_FAILED =
  "NUMBER_PORTING_REQUEST_FETCH_FAILED";
export const NUMBER_PORTING_REQUEST_FETCH_CLEAR_ERRORS =
  "NUMBER_PORTING_REQUEST_FETCH_CLEAR_ERRORS";
export const NUMBER_PORTING_REQUEST_UPDATE_REQUEST =
  "NUMBER_PORTING_REQUEST_UPDATE_REQUEST";
export const NUMBER_PORTING_REQUEST_UPDATE_RECEIVED =
  "NUMBER_PORTING_REQUEST_UPDATE_RECEIVED";
export const NUMBER_PORTING_REQUEST_UPDATE_FAILED =
  "NUMBER_PORTING_REQUEST_UPDATE_FAILED";
export const NUMBER_PORTING_REQUEST_CREATE_REQUEST =
  "NUMBER_PORTING_REQUEST_CREATE_REQUEST";
export const NUMBER_PORTING_REQUEST_CREATE_RECEIVED =
  "NUMBER_PORTING_REQUEST_CREATE_RECEIVED";
export const NUMBER_PORTING_REQUEST_CREATE_FAILED =
  "NUMBER_PORTING_REQUEST_CREATE_FAILED";
export const SINGLE_NUMBER_PORTING_REQUEST_FETCH_REQUEST =
  "SINGLE_NUMBER_PORTING_REQUEST_FETCH_REQUEST";
export const SINGLE_NUMBER_PORTING_REQUEST_FETCH_RECEIVED =
  "SINGLE_NUMBER_PORTING_REQUEST_FETCH_RECEIVED";
export const SINGLE_NUMBER_PORTING_REQUEST_FETCH_FAILED =
  "SINGLE_NUMBER_PORTING_REQUEST_FETCH_FAILED";
export const NUMBER_PORTING_REQUESTS_SEARCH_REQUEST =
  "NUMBER_PORTING_REQUESTS_SEARCH_REQUEST";
export const NUMBER_PORTING_REQUEST_IMPORT_STATUS_POLL_REQUEST =
  "NUMBER_PORTING_REQUEST_IMPORT_STATUS_POLL_REQUEST";
export const NUMBER_PORTING_REQUEST_IMPORT_STATUS_POLL_RECEIVED =
  "NUMBER_PORTING_REQUEST_IMPORT_STATUS_POLL_RECEIVED";
export const NUMBER_PORTING_REQUEST_IMPORT_STATUS_POLL_FAILED =
  "NUMBER_PORTING_REQUEST_IMPORT_STATUS_POLL_FAILED";
export const NUMBER_PORTING_REQUEST_CANCEL_REQUEST =
  "NUMBER_PORTING_REQUEST_CANCEL_REQUEST";
export const NUMBER_PORTING_REQUEST_CANCEL_RECEIVED =
  "NUMBER_PORTING_REQUEST_CANCEL_RECEIVED";
export const NUMBER_PORTING_REQUEST_CANCEL_FAILED =
  "NUMBER_PORTING_REQUEST_CANCEL_FAILED";

export const searchNumberPortingRequests = createSearchAction(
  "numberPortingRequests"
);

export const numberPortingRequestsSearchRequest = (value) => {
  const isSearching = Boolean(value);

  return {
    type: NUMBER_PORTING_REQUESTS_SEARCH_REQUEST,
    isSearching,
  };
};

// Fetch all number porting requests
export function numberPortingRequestFetchReceived(response) {
  return {
    type: NUMBER_PORTING_REQUEST_FETCH_RECEIVED,
    numberPortingRequests: response.data,
  };
}

export function numberPortingRequestFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: NUMBER_PORTING_REQUEST_FETCH_FAILED,
    message,
  };
}

export function numberPortingRequestFetchClearErrors() {
  return {
    type: NUMBER_PORTING_REQUEST_FETCH_CLEAR_ERRORS,
  };
}

export const numberPortingRequestFetchRequest =
  (portingType, numberType, status = NUMBER_PORTING_REQUEST_STATUSES.ACTIVE) =>
  (dispatch) => {
    dispatch({ type: NUMBER_PORTING_REQUEST_FETCH_REQUEST });

    NumberPortingRequestApi.fetchAll(portingType, numberType, status)
      .then((data) => dispatch(numberPortingRequestFetchReceived(data)))
      .catch((e) => {
        dispatch(numberPortingRequestFetchFailed(e));
      });
  };

// Update single numberPortingRequest
export function numberPortingRequestUpdateReceived(response) {
  const numberPortingRequestTranslation = t("attributes.numberPortingRequest");

  toastr.success(
    t("common.success"),
    t("toasterMessage.resource.archive", {
      resource: numberPortingRequestTranslation,
    })
  );

  return {
    type: NUMBER_PORTING_REQUEST_UPDATE_RECEIVED,
    numberPortingRequest: response.data,
  };
}

export function numberPortingRequestUpdateFailed(
  errorData,
  callback = () => {}
) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: NUMBER_PORTING_REQUEST_UPDATE_FAILED,
    message: response.data,
  };
}

export const numberPortingRequestUpdateRequest =
  (id, attributes, callback, customerForm = false) =>
  async (dispatch) => {
    dispatch({ type: NUMBER_PORTING_REQUEST_UPDATE_REQUEST });

    await NumberPortingRequestApi.update(id, attributes, customerForm)
      .then((data) => {
        dispatch(numberPortingRequestUpdateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(numberPortingRequestUpdateFailed(errors, callback));
      });
  };

// Create new number porting request
export function numberPortingRequestCreateReceived(response) {
  return {
    type: NUMBER_PORTING_REQUEST_CREATE_RECEIVED,
    numberPortingRequest: response.data,
  };
}

export function numberPortingRequestCreateFailed(
  errorData,
  callback = () => {}
) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: NUMBER_PORTING_REQUEST_CREATE_FAILED,
    message: response.data,
  };
}

export const numberPortingRequestCreateRequest =
  (attributes, callback) => async (dispatch) => {
    dispatch({ type: NUMBER_PORTING_REQUEST_CREATE_REQUEST });

    await NumberPortingRequestApi.create(attributes)
      .then((data) => {
        dispatch(numberPortingRequestCreateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(numberPortingRequestCreateFailed(errors, callback));
      });
  };

// Fetch single number porting request
export function singleNumberPortingRequestFetchReceived(response) {
  return {
    type: SINGLE_NUMBER_PORTING_REQUEST_FETCH_RECEIVED,
    numberPortingRequest: response.data,
  };
}

export function singleNumberPortingRequestFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: SINGLE_NUMBER_PORTING_REQUEST_FETCH_FAILED,
    message,
  };
}

export const singleNumberPortingRequestFetchRequest =
  (refNumber, customerForm = false) =>
  (dispatch) => {
    dispatch({ type: SINGLE_NUMBER_PORTING_REQUEST_FETCH_REQUEST });
    dispatch({ type: NUMBER_PORTING_REQUEST_PHONE_NUMBER_RESET_STATES });

    NumberPortingRequestApi.fetchOne(refNumber, customerForm)
      .then((data) => dispatch(singleNumberPortingRequestFetchReceived(data)))
      .catch((e) => {
        dispatch(singleNumberPortingRequestFetchFailed(e));
      });
  };

// Poll import status of number porting request phone numbers
export function numberPortingRequestImportStatusPollReceived(response) {
  return {
    type: NUMBER_PORTING_REQUEST_IMPORT_STATUS_POLL_RECEIVED,
    numberPortingRequest: response.data,
  };
}

export function numberPortingRequestImportStatusPollFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: NUMBER_PORTING_REQUEST_IMPORT_STATUS_POLL_FAILED,
    message,
  };
}

export const numberPortingRequestImportStatusPollRequest =
  (refNumber) => (dispatch) => {
    dispatch({ type: NUMBER_PORTING_REQUEST_IMPORT_STATUS_POLL_REQUEST });

    NumberPortingRequestApi.pollImportStatus(refNumber)
      .then((data) =>
        dispatch(numberPortingRequestImportStatusPollReceived(data))
      )
      .catch((e) => {
        dispatch(numberPortingRequestImportStatusPollFailed(e));
      });
  };

// Cancel all import flows inside the number porting request
export function numberPortingRequestCancelReceived(response, callBack) {
  const translation = t("attributes.numberPortingRequest");

  if (typeof callBack === "function") {
    callBack();

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.cancel", {
        resource: translation,
      })
    );
  }

  return {
    type: translation,
    numberPortingRequest: response.data,
  };
}

export function numberPortingRequestCancelFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: NUMBER_PORTING_REQUEST_CANCEL_FAILED,
    message,
  };
}

export const numberPortingRequestCancelRequest =
  (refNumber, callBack) => (dispatch) => {
    dispatch({ type: NUMBER_PORTING_REQUEST_CANCEL_REQUEST });

    NumberPortingRequestApi.cancelAllImportFlows(refNumber)
      .then((response) =>
        dispatch(numberPortingRequestCancelReceived(response, callBack))
      )
      .catch((e) => {
        dispatch(numberPortingRequestCancelFailed(e));
      });
  };
