import React from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";

import { renderInputField } from "components/ReduxForm/RenderField";
import { required } from "utils/validation";
import { SUBSCRIPTION_LINE_TYPE } from "constants/subscriptionLine";

const SubscriptionLineProperties = ({
  fields,
  isPropertyEditable,
  lineType,
}) => {
  const subscriptionLineProperties = fields.map(
    (subscriptionLineProperty, index) => {
      const currentLineProperty = fields.value[index];
      const { property } = currentLineProperty;
      const isTopic = property === "Topic";
      const isSetupProduct = lineType === SUBSCRIPTION_LINE_TYPE.SETUP;

      return (
        <Field
          name={`${subscriptionLineProperty}.value`}
          type="text"
          component={renderInputField}
          label={property}
          key={`${subscriptionLineProperty}.property`}
          disabled={!isSetupProduct && !isPropertyEditable && !isTopic}
          warning={required}
          dataTestId={property.toLowerCase()}
        />
      );
    }
  );

  return <span>{subscriptionLineProperties}</span>;
};

SubscriptionLineProperties.defaultProps = {
  isPropertyEditable: true,
};

SubscriptionLineProperties.propTypes = {
  fields: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.shape({ property: PropTypes.string, value: PropTypes.string })
    ),
    map: PropTypes.func,
  }).isRequired,
  isPropertyEditable: PropTypes.bool,
  lineType: PropTypes.string.isRequired,
};

export default SubscriptionLineProperties;
