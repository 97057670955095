import {
  CALL_RATE_FETCH_REQUEST,
  CALL_RATE_FETCH_RECEIVED,
  CALL_RATE_FETCH_FAILED,
  CALL_RATE_FETCH_CLEAR_ERRORS,
} from "redux/actions/pbxEngine/callRate";

const defaultState = {
  callRates: [],
  callRatesFetchError: "",
};

function callRate(state = defaultState, action) {
  const { callRates } = action;

  switch (action.type) {
    case CALL_RATE_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CALL_RATE_FETCH_RECEIVED:
      return {
        ...state,
        callRates,
        isLoading: false,
      };

    case CALL_RATE_FETCH_FAILED:
      return {
        ...state,
        callRatesFetchError: action.message,
      };

    case CALL_RATE_FETCH_CLEAR_ERRORS:
      return { ...state, callRatesFetchError: "" };

    default:
      return state;
  }
}

export default callRate;
