import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faBriefcase,
  faMoneyCheckAlt,
  faCalendarDay,
  faCalendarTimes,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-i18nify";

import { singlePbxDeleteRequest } from "redux/actions/pbx";

import { DEBTOR, PBX } from "constants/routes";

import {
  isAnAdminUser,
  isABusinessAdminUser,
  isABusinessUser,
} from "utils/userRole";

import DetailUIHeader from "components/Header/DetailUI/DetailUIHeader";
import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import TranslateDate from "components/i18n/TranslateDate";
import DeleteModal from "components/Modal/DeleteModal";

const DebtorContent = ({ debtorName, debtorID, isBusinessUser }) => {
  if (!debtorName) return "N/A";

  if (isBusinessUser) {
    return (
      <>
        {debtorName}
        <Link to={DEBTOR.addId(DEBTOR.DETAIL, debtorID)} className="ml-1">
          (<Translate value="common.viewDetails" />)
        </Link>
      </>
    );
  }

  return debtorName;
};

const PbxBasicDetail = ({ pbx, deletePbx, resourceName }) => {
  const {
    id: pbxId,
    ref_number: refNumber,
    te_name: name,
    te_payment_type: paymentType,
    deployment_date: deploymentDate,
    invoice_start_date: invoiceStartDate,
    odoo_debtor_id: debtorID,
    odoo_debtor_name: debtorName,
    is_active: isActive,
    termination_date: terminationDate,
    pbx_billing_setting: {
      is_voip_billing_enabled: voipBillingIsEnabled,
      is_cell_phone_billing_enabled: cellPhoneBillingIsEnabled,
    },
  } = pbx;

  const navigate = useNavigate();

  const [modalData, setModalData] = useState();
  const [modalVisible, setModalVisibility] = useState(false);

  const {
    currentUser: { role },
  } = useSelector(({ auth }) => auth);

  const isAdminUser = isAnAdminUser(role);
  const isBusinessAdmin = isABusinessAdminUser(role);
  const isBusinessUser = isABusinessUser(role);

  const toggle = () => {
    setModalData(pbxId);
    setModalVisibility(!modalVisible);
  };

  const resetModalState = () => {
    setModalVisibility(false);
    setModalData();
  };

  const onDeleteSuccess = () => {
    navigate(PBX.INDEX, { replace: true });
  };

  const onConfirmDelete = (id) => {
    if (id) deletePbx(id, onDeleteSuccess);

    resetModalState();
  };

  const formattedPaymentType =
    paymentType && paymentType === "pre_paid" ? "Pre Paid" : "Post Paid";

  const basicDetailContent = [
    {
      icon: faUser,
      title: <Translate value="attributes.name" />,
      content: name,
      dataCyFieldName: "name",
    },
    {
      icon: faBriefcase,
      title: <Translate value="attributes.debtor" />,
      content: (
        <DebtorContent
          debtorName={debtorName}
          debtorID={debtorID}
          isBusinessUser={isBusinessUser}
        />
      ),
      dataCyFieldName: "debtor",
    },
    isBusinessAdmin && {
      icon: faCalendarDay,
      title: <Translate value="attributes.deploymentDate" />,
      content: <TranslateDate date={deploymentDate} />,
      dataCyFieldName: "deploymentDate",
    },
    isBusinessAdmin && {
      icon: faCalendarDay,
      title: <Translate value="attributes.invoiceStartDate" />,
      content: <TranslateDate date={invoiceStartDate} />,
      dataCyFieldName: "invoiceStartDate",
    },
    isBusinessAdmin && {
      icon: faMoneyCheckAlt,
      title: <Translate value="attributes.paymentType" />,
      content: formattedPaymentType,
      dataCyFieldName: "paymentType",
    },
  ];

  if (terminationDate) {
    basicDetailContent.push({
      icon: faCalendarTimes,
      title: <Translate value="attributes.terminationDate" />,
      content: <TranslateDate date={terminationDate} />,
      dataCyFieldName: "terminationDate",
    });
  }

  const additionalHeaderContent = (
    <>
      <span className="is-deactivated ms-3 px-2 py-2" hidden={isActive}>
        <FontAwesomeIcon icon={faCalendarTimes} className="me-1" />
        <Translate value="attributes.isDeactivated" />
      </span>

      <span
        className="is-archived ms-3 px-2 py-2"
        hidden={voipBillingIsEnabled || !isAdminUser}
      >
        <FontAwesomeIcon icon={faFileInvoice} className="me-1" />
        <Translate value="common.voipBillingDisabled" />
      </span>

      <span
        className="is-archived ms-3 px-2 py-2"
        hidden={cellPhoneBillingIsEnabled || !isAdminUser}
      >
        <FontAwesomeIcon icon={faFileInvoice} className="me-1" />
        <Translate value="common.cellPhoneBillingDisabled" />
      </span>
    </>
  );

  return (
    <div>
      <DeleteModal
        modalVisible={modalVisible}
        modalData={modalData}
        resourceName="pbx"
        toggleModal={toggle}
        onConfirm={onConfirmDelete}
        onCancel={resetModalState}
      />
      <DetailUIHeader
        perform="pbx:edit"
        headerTitle={refNumber}
        editPath={PBX.addId(PBX.EDIT, pbxId)}
        readOnly={!isActive}
        additionalHeaderContent={additionalHeaderContent}
        deletePerform="pbx:delete"
        handleDelete={toggle}
        hasDelete
      />
      <CardWithHeader
        title={<Translate value="common.basicDetails" />}
        className="shadow-sm"
        cardContent={basicDetailContent}
        dataCy={resourceName}
      />
    </div>
  );
};

DebtorContent.defaultProps = {};

DebtorContent.propTypes = {
  debtorName: PropTypes.string.isRequired,
  debtorID: PropTypes.number.isRequired,
  isBusinessUser: PropTypes.bool.isRequired,
};

PbxBasicDetail.defaultProps = {};

PbxBasicDetail.propTypes = {
  deletePbx: PropTypes.func.isRequired,
  pbx: PropTypes.shape({
    id: PropTypes.number,
    ref_number: PropTypes.string,
    te_name: PropTypes.string,
    te_payment_type: PropTypes.string,
    deployment_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    invoice_start_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    termination_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    odoo_debtor_id: PropTypes.number,
    odoo_debtor_name: PropTypes.string,
    is_active: PropTypes.bool,
    pbx_billing_setting: PropTypes.shape({
      is_voip_billing_enabled: PropTypes.bool,
      is_cell_phone_billing_enabled: PropTypes.bool,
    }),
  }).isRequired,
  resourceName: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  deletePbx: (id, cb) => dispatch(singlePbxDeleteRequest(id, cb)),
});

export default connect(null, mapDispatchToProps)(PbxBasicDetail);
