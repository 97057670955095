// NOTE: Please maintain by alphabetical order; it will be easier to search and look through the file.

export default {
  // Application wide attributes of features
  attributes: {
    address: "Address",
    address1: "Address 1",
    address2: "Address 2",
    addANewSubscription: "Add a new subscription",
    affiliation: "Affiliation",
    agreement: "Agreement",
    amount: "Amount",
    amountTaxSigned: "Tax",
    amountTotalSigned: "Total",
    amountUntaxedInvoiceSigned: "Tax Excluded",
    bill: "Bill",
    bills: "Bills",
    billable: "Billable",
    billingGroup: "Billing Group",
    billCellPhoneUsages: "Bill Cell Phone Usages",
    billVoipUsages: "Bill VoIP Usages",
    bindingPeriodInMonths: "Binding Period In Months",
    callRate: "Call Rate",
    callWaiting: "Call Waiting",
    campaignRoutingProfile: "Campaign Routing Profile",
    category: "Category",
    cellPhone: "Cell Phone",
    cellPhoneNumber: "Cell Phone Number",
    circuit: "Circuit",
    city: "City",
    comment: "Comment",
    confirmedPortingDate: "Confirmed Porting Date",
    country: "Country",
    createdAt: "Created At",
    creditNote: "Credit Note",
    creditNotes: "Credit Notes",
    customer: "Customer",
    customerEmail: "Customer Email Address",
    customOption: "Custom option",
    customerNumber: "Customer Number",
    customerSince: "Customer since",
    cvrSearchField: "Vat Number or Company Name",
    dataType: "Data Type",
    date: "Date",
    dateDue: "Due Date",
    dateFormat: "Date Format",
    dateInvoice: "Invoice Date",
    dateInvoiced: "Invoiced Date",
    debtor: "Debtor",
    debtorName: "Debtor Name",
    debtorType: "Business Type",
    deploymentDate: "Deployment Date",
    description: "Description",
    destination: "Destination",
    destinationNumber: "Destination Number",
    dialTimeout: "Dial Timeout",
    direction: "Direction",
    due: "Due",
    dueDate: "Due Date",
    duration: "Duration",
    email: "Email Address",
    emailAddress: "Email Address",
    emailNotification: "Email Notification",
    emergencyServices: "Emergency Services",
    employee: "Employee",
    enterThePrice: "End The Price",
    endNumber: "End Number",
    endingNumber: "Ending Number",
    establishedAt: "Established At",
    expiryDate: "Expiry Date",
    exportStatus: "Export Status",
    extensionNumber: "Extension Number",
    extensionNumberAbbr: "Ext. No.",
    fax: "Fax",
    faxName: "Fax Name",
    faxServiceEnabled: "Fax Service Enabled",
    fileSize: "File Size",
    firstName: "First Name",
    flowID: "Flow ID",
    fmfmDialMethod: "FMFM Dial Method",
    fmfmDialTimeout: "FMFM Dial Timeout",
    forwardTo: "Forward to",
    from: "From",
    fullName: "Full Name",
    holdMusic: "Music on Hold",
    hostname: "Hostname",
    iccNumber: "ICC Number",
    iccid: "ICCID",
    iccidNumber: "ICCID Number",
    id: "#",
    imsi: "IMSI",
    imsiNumber: "IMSI Number",
    inboundCalls: "Inbound Calls",
    inboundNumber: "Inbound Number",
    inboundNumberAbbr: "Inbound No.",
    installationAddress: "Installation Address",
    interfaceLanguage: "Interface Language",
    interval: "Interval",
    invoice: "Invoice",
    invoiceNumber: "Invoice Number",
    invoicePeriod: "Invoice Period",
    invoiceStartDate: "Invoice Start Date",
    invoicedUntil: "Invoiced Until",
    isArchived: "Archived",
    isACustomer: "Is a Customer",
    language: "Language",
    lastActiveAt: "Last Active At",
    lastInvoiceDate: "Last Invoice Date",
    lastLogin: "Last Login",
    lastName: "Last Name",
    lineNumber: "Line Number",
    location: "Location",
    locationConstraint: "Location Constraint",
    login: "Login",
    loginCount: "Login Count",
    macAddress: "MAC Address",
    mainEmailAddress: "Email Address",
    mainNumber: "Main Number",
    mainPhoneNumber: "Phone Number",
    maxChannels: "Max Channels",
    maximumLengthOfMessageInSeconds: "Maximum Length of Message in Seconds",
    maximumNumberOfMessageInVoicemailBox:
      "Maximum Number of Messages allowed in the Voicemail Box  ",
    maximumNumberOfAllowedMessages: "Maximum Number of Allowed Messages",
    minimumLengthOfAMessage: "Minimum Length of a Message",
    maximumLengthOfAMessage: "Maximum Length of a Message",
    members: "Members",
    messages: "Messages",
    minimumLengthOfMessageInSeconds: "Minimum length of a message in seconds",
    mobile: "Mobile Number",
    model: "Model",
    modelName: "Model Name",
    monthlyPrice: "Monthly Price",
    name: "Name",
    newPassword: "New Password",
    notes: "Notes",
    number: "Number",
    numberOfPages: "Number of Pages",
    numberPortingRequest: "Number Porting Request",
    numberRange: "Number Range",
    numberType: "Number Type",
    networkOperator: "Network Operator",
    ochRejectionCode: "OCH Rejection Code",
    odooDebtorID: "Customer",
    options: "Options",
    outgoingCalls: "Outgoing Calls",
    outgoingNumber: "Outgoing Number",
    owner: "Owner",
    parkingLotEnd: "Parking Lot End",
    parkingLotStart: "Parking Lot Start",
    password: "Password",
    paymentTerm: "Payment Terms",
    paymentType: "Payment Type",
    pbx: "Pbx",
    pbxNumber: "Pbx Number",
    phoneNumber: "Phone Number",
    phoneNumbers: "Phone Numbers",
    phone: "Phone",
    phones: "Phones",
    pinCode: "PIN Code",
    pin1: "PIN 1",
    pin2: "PIN 2",
    puk1: "PUK 1",
    puk2: "PUK 2",
    poi: "POI",
    portingDate: "Porting Date",
    preview: "Preview",
    price: "Price",
    product: "Product",
    productName: "Product Name",
    productNumber: "Product Number",
    productPrice: "Product Price",
    productProperties: "Product Properties",
    productType: "Product Type",
    properties: "Properties",
    proposedDate: "Proposed Date",
    proposedDateReason: "Proposed Date Reason",
    providers: "Providers",
    puk1Code: "PUK1 Code",
    quantity: "Quantity",
    quotation: "Quotation",
    reason: "Reason",
    recordCalls: "Record Calls",
    refNumber: "Number",
    refundDate: "Refund Date",
    refundedMonths: "Refunded Months",
    release: "Release",
    reseller: "Reseller",
    requestReceivedDate: "Request Received Date",
    requestedNumberStatus: "Requested Number Status",
    requestedPortingDate: "Requested Porting Date",
    residualSigned: "Amount Due",
    role: "Role",
    routingProfile: "Routing Profile",
    sender: "Sender",
    senderNumber: "Sender Number",
    sentDate: "Sent Date",
    services: "Services",
    serviceName: "Service Name",
    serviceType: "Service Type",
    selectICCIDNumber: "Select ICCID Number",
    selectUserLicense: "Select a User License",
    seller: "Seller",
    setup: "Setup",
    shortcuts: "Shortcuts",
    simCardHolder: "SIM Card Holder",
    size: "Size",
    smsNotification: "SMS Notification",
    speed: "Speed",
    startDate: "Start Date",
    startNumber: "Start Number",
    startingNumber: "Starting Number",
    state: "Status",
    status: "Status",
    strategy: "Strategy",
    sipDevice: "SIP Device",
    subTotal: "Subtotal",
    subscriptions: "Subscriptions",
    subscriptionType: "Subscription Type",
    superAdmin: "Super Admin",
    suspended: "Suspended",
    suspendedDate: "Suspended Date",
    symbol: "Symbol",
    terminationAddress: "Termination Address",
    terminationDate: "Termination Date",
    terminationTerm: "Termination Term",
    termsAndConditions: "Terms and Conditions",
    timeFormat: "Time Format",
    timeZone: "Time Zone",
    timeout: "Timeout",
    to: "To",
    total: "Total",
    totalAmount: "Total Amount",
    totalPrice: "Total Price",
    totalMonthlyPrice: "Total Monthly Price",
    totalSetupPrice: "Total Setup Price",
    type: "Type",
    unit: "Unit",
    unitOfMeasure: "Unit of Measure",
    upcomingInvoiceDate: "Upcoming Invoice Date",
    usage: "Usage",
    usageType: "Usage Type",
    user: "User",
    userInterfaceLocale: "UI Language",
    userLicense: "User License",
    userLicenseNumber: "User License Number",
    username: "Username",
    userRole: "User Role",
    userType: "User Type",
    validityPeriod: "Validity Period",
    vatNumber: "VAT Number",
    vendor: "Vendor",
    vendors: "Vendors",
    viewStatus: "View Status",
    voicemailBox: "Voicemail Box",
    wallpaper: "Wallpaper",
    website: "Website",
    zipCode: "Zip Code",
  },

  // common words used throughout the app
  common: {
    action: "Action",
    active: "Active",
    add: "Add",
    addNew: "Add new",
    addNewOption: "Add new option",
    addNumber: "Add a Number",
    addOneNow: "Add one now",
    addOnSubscriptions: "Add-on Subscriptions",
    addService: "Add Service",
    addSubscription: "Add Subscription",
    additionalDetails: "Additional Details",
    advanced: "Advanced",
    addTermsAndConditions: "Add terms and conditions...",
    allActive: "All Active",
    allowedKeys: "Allowed Keys",
    announcement: "Announcement",
    archive: "Archive",
    archived: "Archived",
    assign: "Assign",
    assigned: "Assigned",
    assignToSimCardHolder: "Assign to SIM Card Holder",
    assignToUserLicense: "Assign to User License",
    assignSimCard: "Assign SIM Card",
    assignNewSimCard: "Assign New SIM Card",
    attachedTermsAndConditions: "Attached Terms and Conditions",
    automaticallySelected: "Automatically selected",
    available: "Available",
    basicDetails: "Basic Details",
    billingDisabled: "Billing Disabled",
    bindingPeriod: "Binding Period",
    bindingPeriods: "Binding Periods",
    cancel: "Cancel",
    cellPhone: "Cell Phone",
    cellPhoneBillingDisabled: "Cell Phone Billing Disabled",
    changePassword: "Change Password",
    chooseSubscription: "Choose Subscription",
    clear: "Clear",
    comingSoon: "Coming Soon",
    confirm: "Confirm",
    confirmation: "Confirmation",
    consentForm: "Consent Form",
    consumedEuUsages: "Consumed EU Usages",
    consumedNationalUsages: "Consumed National Usages",
    contactDetails: "Contact Details",
    cost: "Cost",
    currentPassword: "Current Password",
    data: "Data",
    deactivated: "Deactivated",
    default: "default",
    defaultPhoneNumberFormat: "Default Phone Number Format",
    delete: "Delete",
    deleteAll: "Delete All",
    deleteSelected: "Delete Selected",
    detail: "Details",
    desktopPhone: "Desktop Phone",
    didNumber: "Did Number",
    domestic: "Domestic",
    download: "Download",
    draft: "Draft",
    dropdown: "Dropdown",
    edit: "Edit",
    editService: "Edit Service",
    editSubscription: "Edit Subscription",
    emailPlaceholder: "name@email.com",
    enable: "Enable",
    error: "Error",
    eu: "EU",
    euRoaming: "EU Roaming",
    fetchingResources: "Fetching %{resource}s...",
    for: "For",
    forgotPassword: "Forgot Password",
    filters: "Filters",
    fixed: "Fixed",
    free: "Free",
    generateInvoice: "Generate Invoice",
    gold: "Gold",
    hide: "Hide",
    info: "Info",
    inputField: "Input Field",
    instructions: "Instructions",
    international: "International",
    internationalRoaming: "International Roaming",
    internet: "Internet",
    imsiRange: "IMSI Ranges",
    loading: "Loading",
    logout: "Logout",
    mainSubscription: "Main Subscription",
    mapUnavailable: "Map Unavailable",
    mms: "MMS",
    mobile: "Mobile",
    networkServices: "Network Services",
    next: "Next",
    new: "New",
    no: "No",
    noAffiliation: "No affiliation",
    noName: "No Name",
    noOptions: "No options",
    noRecordFound: "No records found",
    noRecords: "No Records",
    noResults: "No Results",
    noValue: "No Value",
    none: "None",
    noneYet: "None Yet",
    notAssociatedWithExchange: "Not associated with exchange",
    notConfigured: "Not configured",
    notFound: "Not Found",
    notifications: "Notifications",
    number: "Number",
    numberOfDays: "%{days} Days",
    numberOfMonths: "%{months} Months",
    numberRange: "Number Range",
    notSet: "Not Set",
    optional: "Optional",
    order: "Order",
    orderNewSimCard: "Order New Sim Card",
    owner: "Owner",
    overview: "Overview",
    portedOut: "Ported Out",
    products: "Products",
    profile: "Profile",
    quarantined: "Quarantined",
    refresh: "Refresh",
    rejectionDate: "Rejection Date",
    rejectionReason: "Rejection Reason",
    reload: "Reload Page",
    rename: "Rename",
    requestedNumberDetails: "Requested Number Details",
    resourceName: "%{resource} Name",
    response: "Response",
    reseller: "Reseller",
    reactivate: "Reactivate",
    sales: "Sales",
    save: "Save",
    search: "Search",
    seconds: "Seconds",
    service: "service",
    services: "services",
    sent: "Sent",
    selectAll: "Select All",
    selectDebtor: "Select a debtor",
    selectDebtorType: "Select Debtor Type",
    selectProduct: "Select a Product",
    searching: "Searching",
    searchResultNotFound: "Search Result Not Found",
    setupPrice: "Setup Price",
    show: "SHOW",
    signIn: "Sign In",
    signOut: "Sign Out",
    simCardDetails: "SIM Card Details",
    sipDevice: "SIP Device",
    simCard: "Sim Card",
    simCardBorrower: "Sim Card Borrower",
    sms: "SMS",
    subscription: "subscription",
    subscriptionDetails: "Subscription Details",
    subscriptionUsage: "Subscription Usage",
    success: "Success",
    suspend: "Suspend",
    simCardHolder: "SIM Card Holder",
    termsAndConditions: "Terms and Conditions",
    thankYou: "Thank You!",
    today: "Today",
    total: "Total",
    unauthorized: "Unauthorized",
    unspecified: "Unspecified",
    untitled: "Untitled",
    update: "Update",
    updateService: "Update Service",
    updateSubscription: "Update Subscription",
    usageDetails: "Usage Details",
    verify: "Verify",
    view: "View",
    viewDetails: "View Details",
    viewInvoices: "View Invoices",
    viewUsage: "View Usage",
    voice: "Voice",
    voicemailMessageDetails: "Voicemail Message Details",
    voipBillingDisabled: "VoIP Billing Disabled",
    whoShouldFillTheForm: "Choose a person to fill the request form",
    yes: "Yes",
  },

  date: {
    long: "MMMM Do, YYYY",
  },

  // All enums/dropdown options in the app
  enum: {
    cellPhone: {
      recordCall: {
        external: "External",
        yesChange: "Yes, but allowed stopping by both parties",
        noChange: "No, but allowed starting by both parties",
      },
    },

    dataTypes: {
      integer: "Integer",
      string: "String",
      boolean: "Boolean",
      dateTime: "Date Time",
      time: "Time",
    },

    invoicePeriod: {
      monthly: "Monthly",
      quarterly: "Quarterly",
      semiAnnually: "Semi Annually",
      annually: "Annually",
    },

    locale: {
      "da-DK": "Danish",
      "en-US": "English",
      da_DK: "Danish",
      en_US: "English",
    },

    numberPorting: {
      exportStatus: {
        accept: "Accept",
        decline: "Decline",
        propose_new_date: "Propose New Date",
      },

      proposedDateReason: {
        contractPeriodViolated: "Contract period is violated",
        excessiveLoad: "Date moved due to excessive load",
        terminationPeriodViolated: "Termination period is violated",
        tooEarly: "Too early according to Rules & Procedures",
      },

      import: {
        whoShouldFillTheForm: {
          self: "Self",
          customer: "Customer",
        },
      },
    },

    pbx: {
      agreement: {
        addNew: "Add pbx to new agreement",
        addToExisting: "Add pbx to existing agreement",
      },
      billingGroup: {
        userLicense: "Usages grouped by user license",
        cidNum: "Usages grouped by caller id number",
        pbx: "Usages grouped by PBX",
      },
    },

    setting: {
      categoryOptions: {
        invoice: "Invoice",
        pbx: "Pbx",
      },
    },

    subscriptionProduct: {
      subscriptionType: {
        addon: "Addon",
      },
    },

    terminationTerm: {
      thirty_days: "30 Days",
      next_month_end: "End of Next Month",
      three_months: "3 Months",
      next_quarter_end: "End of Next Quarter",
    },

    simCard: {
      available: "Available",
      active: "Active",
      assigned: "Assigned",
      blocked: "Blocked",
    },
  },

  pill: {
    status: {
      active: "Active",
      assigned: "Assigned",
      available: "Available",
      blocked: "Blocked",
      deactivated: "Deactivated",
      lost: "Lost",
      newSimCardRequested: "New Sim Card Requested",
      quarantined: "Quarantined",
      setup: "Setup",
      suspended: "Suspended",
      block: "Block",
      employee: "Employee",
      debtor: "Debtor",
    },
  },

  // Fetch error
  error: {
    error: "An error has occured",
    description: "An error has occured while retrieving data",
    refresh: "Refresh",
    retry: "Retry",
  },

  // Labels used in the app
  label: {
    dropdown: {
      selectResource: "Select %{resource}",
      chooseProduct: "Choose Product",
    },
  },

  menu: {
    customer: "Customer",
    user: "Users",
    telecom: "Telecom",
    setting: "Settings",
    dropdown: {
      quotation: {
        preview: "Preview",
        delete: "Delete",
        send: "Send",
        convertToAgreement: "Convert to Agreement",
      },
    },

    customerAdmin: {
      debtor: "Debtor",
      contact: "Contacts",
    },
  },

  // Menu related Translations
  menuItem: {
    agreement: "Agreements",
    bills: "Bills",
    creditNotes: "Credit Notes",
    debtor: "Debtors",
    invoice: "Invoices",
    numberPortingRequest: "Number Porting Requests",
    pbx: "Pbxs",
    profile: "Profile",
    userSetting: "User Settings",
    phoneNumbers: "Phone Numbers",
    quotations: "Quotations",
    simCards: "Sim Cards",
  },

  message: {
    auth: {
      incorrectEmailOrPassword: "Incorrect email address or password",
      notExist: "Account does not exist",
      onlyMobileAllowed: "You are only authorized to access Mobile App",
      resetLink:
        "If you have typed an existing mail address you will receive a mail about how to reset your password in a few minutes.",
      setPasswordSuccessMessage: "Password Changed Successfully",
      setPasswordError400: "Could Not Save Password",
      setPasswordError404: "Your Token has expired",
      setPasswordError:
        "Could not process this request, please try again later",
      signIn: "Sign in using your email address",
      unableToSignIn: "Unable to Sign in",
      unauthorized: "You are not authorized to view this page",
      unauthorizedToAccessWebApp:
        "You are not authorized to access this Web App",
    },

    debtor: {
      blankField: "Search field cannot be blank",
    },

    invoice: {
      noLine: "No Invoice Lines",
    },

    product: {
      chooseDifferent: "Choose different product",
    },

    quotation: {
      convertToAgreement: {
        noProperties: "No Properties!",
      },
    },

    setting: {
      empty: "No Settings Found",
    },

    subscription: {
      noLine: "No Subscription Lines",
      noProperties: "No Properties! Please add a subscription product.",
      noSubscription: "No Subscriptions",
      selectProduct: "Please select a product to continue",
      selectSubscription: "Please select a subscription product first!",
    },

    subscriptionProduct: {
      noProperties: "No properties added yet!",
    },
  },

  resourceStatus: {
    pbxs: "Pbxs",
    users: "Users",
    cellPhones: "Cell Phones",
    didNumbers: "Did Numbers",
    userLicenses: "User Licenses",
    active: "Active %{resource}",
    deactivated: "Deactivated %{resource}",
  },

  // modal messages
  modal: {
    archive: {
      resource: "Are you sure you want to archive this record?",
    },

    reactivate: {
      cellPhone: "Are you sure you want to reactivate this cell phone?",
    },

    delete: {
      resource: "Are you sure you want to delete this record?",
      user: "Are you sure you want to delete user",
      userAssociationWarning:
        "Deleting this user will delete all data associated with this user",
      irreversible: "This action is irreversible.",
    },

    orderNewSimCard: {
      resource: "Are you sure you want to order a new sim card?",
      existingSimCardBlockWarning:
        "The existing sim card will be blocked immediately after ordering the new sim card",
    },

    sessionExpired: {
      whoopsYourSessionHasExpired: "Whoops, your session has expired!",
      description:
        " Your session has expired due to inactivity. Please login again.",
    },
  },

  // Page or tab titles, any other titles? Put it here!
  title: {
    card: {
      resourceDetails: "%{resource} Details",
    },

    dashboardComponent: {
      cellPhoneExportRequests: "Cell Phone Export Requests",
      cellPhoneImportRequests: "Cell Phone Import Requests",
    },

    // modal titles
    modal: {
      numberPortingRequest: {
        authenticationModal: {
          verifyEmailAddress: "Verify Email Address",
        },
      },
    },

    timeUnit: {
      sec: "sec",
      m: "m",
      hr: "hr",
      s: "s",
    },

    page: {
      dashboard: "Dashboard",
      faxStatus: "Fax Status",
      desktopPhones: "Desktop Phones",

      appSetting: {
        list: "App Settings",
      },

      numberPortingRequest: {
        imports: "Imports",
        exports: "Exports",
        customerForm: "Number Porting Request Form",
      },

      resource: {
        create: "Create %{resource}",
        edit: "Edit %{resource}",
        list: "%{resource} Overview",
      },
    },

    resource: {
      appSetting: "App Setting",
      didNumber: "Did Number",
      phoneNumber: "Phone Number",
      rangeOfPhoneNumbers: "Range Of Phone Numbers",
      iccidNumber: "ICCID Number",
      addResource: "Please add a %{resource}",
      convertToAgreement: "Convert to Agreement",

      button: {
        addResource: "Add a %{resource}",
      },

      info: {
        noResources: "No %{resource}",
      },
    },

    section: {
      billingSettings: "Billing Settings",
      faxService: "Fax Service",
      subscription: "Subscription",

      numberPortingRequest: {
        numbersToImport: "Numbers to Import",
      },
    },

    tab: {
      agreement: {
        cellPhones: "Cell Phones",
        cellPhone: "Cell Phone",
        desktopPhone: "Desktop Phone",
        hostingServices: "Hosting Services",
        internetConnections: "Internet Connections",
        serviceContract: "Service Contracts",
        serviceContracts: "Service Contracts",
        voip: "VoIP",

        subscription: {
          active: "Active Subscription",
          archived: "Archived Subscription",
        },

        subscriptionModal: {
          addonProducts: "Addon Products",
          setupProducts: "Setup Products",
          subscriptionProduct: "Subscription Product",
        },
      },

      cellPhone: {
        networkServices: "Network Services",
      },

      pbx: {
        userLicense: "User Licenses",
        didNumber: "Did Number",
      },

      setting: {
        subscriptionProducts: "Subscription Products",
      },

      userLicense: {
        cellPhones: "Cell Phones",
        didNumbers: "Did Numbers",
      },
    },
  },

  // All toaster message by feature
  toasterMessage: {
    networkError: "Network Error",
    processError: "Could not process this request, please try again later.",
    unauthorized: "You are not authorized to perform this action!",

    numberPortingRequest: {
      importAccepted: "Import Request Accepted",
    },

    resource: {
      create: "%{resource} created",
      delete: "%{resource} deleted successfully",
      update: "%{resource} updated",
      archive: "%{resource} archived",
      assigned: "%{resource} assigned",
    },

    requestFailed: "Unable to complete the request.",

    session: {
      expired: "Your session has expired",
    },

    user: {
      fetchError: "User does not exist with this email.",
      languageUpdatedSuccessfully: "Language updated successfully",
    },

    viewGeneratedInvoiceHere: "View generated invoice here",

    cellPhone: {
      cellPhoneSuspended: "Cell Phone Suspended",
      cellPhoneReactivated: "Cell phone is reactivated successfully",
      newSimCardRequested: "New Sim Card requested successfully",
      newSimCardAssignedSuccessfully: "New Sim Card assigned successfully",

      networkService: {
        networkServiceCreatedSuccessfully:
          "%{networkService} created successfully",
        networkServiceDeletedSuccessfully:
          "%{networkService} deleted successfully",
      },
    },

    didNumber: {
      assignedToUserLicense:
        "Did Number assigned to a User License successfully",
    },

    phoneNumber: "Phone Number",

    quotation: {
      quotationBeingSentToCustomerByEmail:
        "Quotation is being sent to the customer by email",
      quotationHasBeenSentToTheCustomer:
        "Quotation has been sent to the customer",
      quotationHasBeenConvertedToAnAgreement:
        "Quotation has been converted to an agreement",
    },
  },

  // Validation Errors and messages
  validationErrors: {
    ALPHANUMERIC_ONLY: "Only alphanumeric allowed!",
    BLANK: "Must not be blank",
    CANNOT_BE_NEGATIVE: "Cannot be less than 0",
    CANNOT_BE_ZERO: "Cannot be 0",
    CONFIRMATION: "Must be identical",
    EXTENSION_PATTERN_RESTRICTED: "Extension with pattern 9** not allowed!",
    GREATER_THAN: "Must be greater than %{count}",
    GREATER_THAN_OR_EQUAL_TO: "Must be greater than or equal to %{count}",
    INCLUSION: "Is not allowed",
    INCORRECT_CREDENTIALS: "Wrong password",
    INVALID: "Invalid format",
    INVALID_EMAIL_ADDRESS: "Invalid email address",
    INVALID_PHONE_NUMBER: "Invalid phone number",
    INVALID_URL: "Invalid URL",
    MIN_AGE: "You do not meet the minimum age requirement!",
    NOT_A_NUMBER: "Must be a number",
    REQUIRED: "Required",
    TAKEN: "Is already in use",
    TOO_LONG: "Must be at most %{count}",
    TOO_SHORT: "Must be at least %{count}",
    WRONG_LENGTH: "Must be %{count}",
    SHORT_PHONE_NUMBER: "Must be at least %{count} digit number",
    ONLY_NUMBER_AND_PLUS:
      "Numbers starting with “+” or only plain numbers allowed",
    NOT_A_DIGIT: "Must be a digit",
    PUK_CODE_VALIDATION: "PUK code should be equal to 8 digit",
    PIN_CODE_VALIDATION:
      "PIN code has a minimum of 4 digits and a maximum of 8 digits",

    date: {
      INVALID: "Invalid date",
      OUT_OF_RANGE: "Out of range",
    },

    networkService: {
      LOCATION_CONSTRAINT_OUTSIDE_RESTRICTION:
        "%{restrictedServices} restricted to INSIDE",
      LOCATION_ENTIRE_WORLD_RESTRICTION:
        "%{restrictedServices} restricted to Entire World",
    },
  },

  // Feature wise translations (in alphabetical order)
  agreement: {
    active: "Active",
    archived: "Archived",
    billable: "Billable",
    disabled: "Disabled",
    empty: "No Agreements",
    invoice: "Invoice Agreement",
    nextInvoiceDate: "Next Invoice Date",
    number: "Agreement Number",
    option: "Agreement Option",
    select: "Select Agreement",
  },

  appSetting: {
    category: {
      self: "Category",
      desc: "Set category/type of setting based on what it controls. Is it invoice? profile? You can choose whatever you want the setting to control.",
    },

    dataType: {
      self: "Data Type",
      desc: "Type of data setting supports. Input type of user setting UI is based on data type.",
    },

    description: {
      self: "Description",
      desc: "Add description so users know what this setting intends to do.",
    },

    displayType: {
      self: "Display Type",
      desc: "Choose how this field should be shown to users in their setting page.",
    },

    name: {
      self: "Name",
      desc: "Name that will be displayed in the user setting.",
    },

    options: {
      self: "Options",
      desc: "Options to choose from.",
    },

    userType: {
      self: "User Type",
      desc: "Choose user to whom this setting is to be displayed. Are they super admin? customer admin?",
    },
  },

  auth: {
    // login page
    deactivatedAccount: "Your account has been deactivated.",
    emailField: "Email Address",
    forgotPassword: "Forgot your password?",
    loginLabel: "Login here using your email address.",
    loginText: "Login",
    passwordField: "Password",
    keepMeLoggedIn: "Keep me Logged In",

    // reset password page
    resetPasswordLabel:
      "Enter your registered email address to reset your password.",
    resetPasswordHelpText:
      "Locked out? If you have typed an existing mail address you will receive a mail about how to reset your password in a few minutes",
    resetPasswordText: "Reset my password",
    signInInstead: "Sign In Instead?",

    // set password page
    setNewPasswordLabel: "Set your new password",
    confirmPasswordField: "Confirm Password",
    setPasswordBtn: "Set Password",
    passwordPlaceholder: "Your strong password",
  },

  cellPhone: {
    defaultForMobileApp: "Make this number default for mobile app",
    defaultInMobileApp: "Default in mobile app",
    emailToSendRecordedCalls: "Email to Send Recorded Calls",
    addASubscriptions: "Add a subscriptions",
    addOnSubscriptions: "Add-on subscriptions",
    pleaseSelectTheSubscriptionToAdd: "Please select the subscription to add",
    pleaseSelectASubscriptionProductFirst:
      "Please select a subscription product first",
    reasonForSuspending: "Reason for suspending",
    youCannotEditTheSuspendedCellPhone:
      "You cannot edit the suspended cell phone",
    youCannotDeleteTheSuspendedCellPhone:
      "Cell Phone cannot be deleted. Please reactivate the cell phone to delete it.",
    chooseICCIDNumberToAssignTheUser:
      "Choose ICCID Number to assign to the user",

    networkServices: {
      chooseLocation: "Choose a Location",
      chooseLocationConstraint: "Choose a Constraint",
      chooseService: "Choose a Service",
      pleaseAddService: "Please add a service",
      selectService: "Select Service",

      location: {
        entire_world: "Entire World",
        eu_ess: "EU/ESS",
        sweden: "Sweden",
        denmark: "Denmark",
        us_verizon: "US Verizon",
        ipn_kjit_countries: "IPN-KJIT-Countries",
        iot_sydamerika: "IoT-Sydamerika",
        iot_nordamerika: "IoT-Nordamerika",
        iot_eu_plus: "IoT-EU+",
        iot_nordics: "IoT-Nordics",
        maritime_and_satellite: "Maritime & Satellite",
      },

      locationConstraint: {
        inside: "INSIDE",
        outside: "OUTSIDE",
      },
    },
  },

  dashboard: {
    agreement: {
      active: "Active Agreements",
      activeAmount: "Active Agreement Amount",
      archived: "Archived Agreements",
      archivedAmount: "Archived Agreement Amount",
      billableAmount: "Billable Agreements",
      billableCount: "Billable Agreements Amount",
      disabled: "Disabled Agreements",
      new: "New Agreements",
      total: "Total Agreements",
      noResults: "No results found",
      searchTitle: "Search agreements",
      searchHelpText: "Search results will be displayed here",
    },

    debtor: {
      total: "Total Debtors",
    },

    invoice: {
      due: "Due Invoices",
      dueAmount: "Due Amount",
      paid: "Paid Invoices",
      total: "Total Invoices",
      validate: "Invoice to Validate",
      validateAmount: "Amount to Validate",
    },

    bill: {
      due: "Due Bills",
      dueAmount: "Due Amount",
      paid: "Paid Bills",
      total: "Total Bills",
      validate: "Bill to Validate",
      validateAmount: "Amount to Validate",
    },

    user: {
      new: "New Users",
      noNew: "No new users",
      total: "Total Users",
    },
  },

  debtor: {
    invoiceAddress: "Invoice Address",
    noDebtors: "No Debtors",
    technicalContact: "Technical Contact",
  },

  didNumber: {
    chooseUserLicenseToAssignToThisDidNumber:
      "Choose user license to assign to this did number",
  },

  faxService: {
    senderEmails: "Emails For Sending Faxes",
    storeInServer: "Store Fax in Server",
  },

  filterType: {
    status: "Status",
  },

  forbidden: {
    accessRestricted: "Access Restricted",
    youAreNotAllowed: "You are not allowed to access this app",
    returnToLoginPage: "Return to Login Page",
  },

  invoice: {
    addLine: "Add Line",
    addNote: "Add Note",
    detail: "Invoice Details",
    download: "Download Invoice",
    line: "Invoice Line",
    notInvoiced: "Not Invoiced",
  },

  numberPortingRequest: {
    acceptRequest: "Accept Request",
    activityLogs: "Activity Logs",
    cancelAllImportFlows: "Cancel All Flows",
    requestedPhoneNumbers: "Requested Phone Numbers",
    respondToRequest: "Respond to Request",

    authenticationModal: {
      subTitle:
        "Please verify the email where you received the import request notification",
      incorrectEmail: "Incorrect email address!",
    },

    exportRequest: {
      requestedNumberNotFound: "Requested Number was not found in the app!",
      askCustomerToSubmitNewRequest:
        "Pbx and debtor used in this request are dummy and not accurate. Please reject this request and ask customers to submit new request with the correct number.",
    },

    importForm: {
      csvFileImport: {
        addCellAndICCNumberToCSV: "Add required phone numbers to the CSV File",
        downloadCSVTemplate:
          "Download the CSV template with pre-configured headers",
        importingNumbers: "Importing Numbers",
        importNumbersFromCSVFile: "Import numbers from CSV file",
        selectAndUploadCSVFile:
          "Select and upload the CSV file to import numbers",
        uploadSeparateCSVFileInstructions:
          "You can also upload a separate file than this template. If you decide to do so, here are some notes to keep in mind:",

        cellPhone: {
          addNumbersBelowHeader:
            'In the CSV file, you will find the headers "cell_number" and "icc_number" added by default. You can add numbers you want to import just below the header.',
          csvColumnSeparatedByComma:
            "Each column should be separated by a comma (,) with no whitespace after comma. For example: 70160160,12345",
          csvHeaderOrderNote:
            'File should have headers in the order "cell_number,icc_number" i.e. Cell phone should be the first column in the row.',
          downloadCSVTemplateInstructions:
            "You can download the CSV template file with pre-configured headers for adding Cell Phones and ICC numbers by clicking the button below.",
        },

        didNumber: {
          addNumbersBelowHeader:
            'In the CSV file, you will find the header "phone_number" added by default. You can add numbers you want to import just below the header.',
          csvHeaderOrderNote: 'File should only have the header "phone_number"',
          downloadCSVTemplateInstructions:
            "You can download the CSV template file with pre-configured header for adding phone numbers by clicking the button below.",
        },

        validationErrors: {
          headerFormatNotSupported:
            "Header format supported by the app is %{supportedHeaderFormat} but got %{uploadedHeaderFormat}",
          multipleFileImportNotSupported:
            "Multiple file import is not supported!",
          onlyCSVFileSupported:
            "Only CSV file is supported for importing cell phones!",
        },
      },
    },

    requestAcceptModal: {
      acceptRequest: "Are you sure you want to accept the request?",
    },

    requestCancelModal: {
      cancelRequest: "Are you sure you want to cancel this flow?",
    },

    requestSubmittedModal: {
      successMessage:
        "Your request has been successfully submitted. You will receive a notification in your email when request is accepted or rejected by the donor operator.",
      alreadySubmittedMessage:
        "You have already filled the request form. You will receive a notification in your email when request is accepted or rejected by the donor operator.",
    },
  },

  password: {
    veryStrong: "Very Strong Password",
  },

  pbx: {
    allDesktopPhones: "All Desktop Phones",
    billVoipUsages: "Bill VoIP Usages",

    subscription: {
      chooseDifferentProduct: "Choose different product",
    },
  },

  phoneNumber: {
    numberType: "Number Type",
    costType: "Cost Type",
    onlyAvailableNumbersCanBeDeleted: "Only available numbers can be deleted",
  },

  quotation: {
    addProduct: "Add Product",
    chooseProduct: "Choose a product",
    otherProducts: "Other Products",
    pleaseSelectProductToAdd: "Please select a product to add",
    pleaseSelectAMainSubscriptionFirst:
      "Please select a main subscription first",
    preview: "Quotation Preview",
    quotationOverview: "Quotation Overview",
    selectValidityPeriod: "Select a Validity Period",
    termsAndConditionsPreview: "Terms and Conditions Preview",

    bindingPeriod: {
      chooseSubscriptionType: "Choose a Subscription Type",
      chooseBindingPeriodInMonths: "Choose a Binding Period in Months",

      subscriptionType: {
        voice: "Voice",
        fiber: "Fiber",
        fwa: "FWA",
        x_dsl: "xDSL",
        hosting_services: "Hosting Services",
      },
    },
  },

  serverErrors: {
    internalServerError: "Internal server error",
  },

  setting: {},

  subscription: {
    add: "Add Subscription",
    category: "Subscription Category",
    detail: "Subscription Detail",
    edit: "Edit Subscription",
    for: "Subscription For",
    line: "Subscription Lines",
    productProperties: "Subscription Product Properties",
  },

  subscriptionUsage: {
    metric: {
      minute: "minutes",
      piece: "pieces",
      gb: "GB",
    },
  },

  tooltip: {
    title: {
      clickOnFilesToPreviewTermsAndConditions:
        "Click on the file to preview the terms and conditions",
    },
  },

  user: {
    active: "Active",
    chooseNewPassword: "Choose New Password",
    companyUsers: "Company Users",
    customerUsers: "Customer Users",
    deactivated: "Deactivated",
    deleteProfile: "Delete Profile",
    editProfile: "Edit Profile",
    pbxUsers: "Pbx Users",
    resetPassword: "Reset Password",
    resellers: "Resellers",
    roleType: "Role Type",
    status: "Status",
  },

  userLicense: {
    editUser: "Edit User",
  },
};
