import { createSearchAction } from "redux-search";

import { UserLicenseApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

import { DEFAULT_FILTER } from "constants/filters";

export const USER_LICENSE_DID_NUMBER_FETCH_REQUEST =
  "USER_LICENSE_DID_NUMBER_FETCH_REQUEST";
export const USER_LICENSE_DID_NUMBER_FETCH_RECEIVED =
  "USER_LICENSE_DID_NUMBER_FETCH_RECEIVED";
export const USER_LICENSE_DID_NUMBER_FETCH_FAILED =
  "USER_LICENSE_DID_NUMBER_FETCH_FAILED";
export const USER_LICENSE_DID_NUMBER_SEARCH_REQUEST =
  "USER_LICENSE_DID_NUMBER_SEARCH_REQUEST";

export function didNumberFetchReceived(response) {
  return {
    type: USER_LICENSE_DID_NUMBER_FETCH_RECEIVED,
    didNumbers: response.data,
  };
}

export function didNumberFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: USER_LICENSE_DID_NUMBER_FETCH_FAILED,
    message,
  };
}

// fetch all didNumbers
export const didNumberFetchRequest =
  (userLicenseID, filter = DEFAULT_FILTER) =>
  (dispatch) => {
    dispatch({ type: USER_LICENSE_DID_NUMBER_FETCH_REQUEST });

    UserLicenseApi.fetchDidNumbers(userLicenseID, filter)
      .then((data) => dispatch(didNumberFetchReceived(data)))
      .catch((e) => {
        dispatch(didNumberFetchFailed(e));
      });
  };

export const searchDidNumbers = createSearchAction("userLicenseDidNumbers");

export const didNumberSearchRequest = (value) => {
  const isSearching = Boolean(value);

  return {
    type: USER_LICENSE_DID_NUMBER_SEARCH_REQUEST,
    isSearching,
  };
};
