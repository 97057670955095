import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Outlet, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import { singlePbxFetchRequest } from "redux/actions/pbx";
import { routingProfileFetchRequest } from "redux/actions/pbxEngine/routingProfile";
import { callRateFetchRequest } from "redux/actions/pbxEngine/callRate";

import UserLicenseIndex from "views/UserLicense/UserLicenseIndex";
import DidNumberIndex from "views/DidNumber/DidNumberIndex";
import CellPhoneIndex from "views/Pbx/CellPhone/CellPhoneIndex";
import SipDeviceIndex from "views/Pbx/SipDevices/SipDeviceIndex";

import PbxBasicDetail from "components/Pbx/Detail/PbxBasicDetail/PbxBasicDetail";
import PbxAdditionalDetail from "components/Pbx/Detail/PbxAdditionalDetail";
import TabWithRoute from "components/Tab/TabWithRoute/TabWithRoute";
import FetchError from "components/Error/FetchError";
import PbxDetailSkeleton from "components/Skeleton/ResourceDetail/PbxDetail/PbxDetailSkeleton";

import { isAnAdminUser } from "utils/userRole";

import { RESOURCE_NAMES } from "constants/constant";
import {
  CELL_PHONES,
  DETAILS,
  DID_NUMBERS,
  SIP_DEVICES,
  USER_LICENSES,
} from "constants/routes/routesResources";

const PbxDetail = ({
  pbx,
  fetchSinglePbx,
  fetchRoutingProfiles,
  fetchCallRates,
  routingProfiles,
  isRoutingProfileFetching,
  callRates,
  isCallRateFetching,
  fetchError,
  isLoading,
  currentlyLoggedInUser,
}) => {
  const location = useLocation();
  const { pbxID } = useParams();

  const fetchPbxDetail = () => {
    fetchSinglePbx(pbxID);
    fetchRoutingProfiles();
    fetchCallRates();
  };

  useEffect(() => {
    fetchPbxDetail();
  }, []);

  if (fetchError) {
    return <FetchError onRetry={fetchPbxDetail} />;
  }

  if (isLoading || isRoutingProfileFetching || isCallRateFetching || !pbx) {
    return <PbxDetailSkeleton />;
  }

  const { odoo_debtor_id: odooDebtorID } = pbx;
  const { role: userRole } = currentlyLoggedInUser;

  const isAdminUser = isAnAdminUser(userRole);

  const getSharedRouteComponent = () => {
    const pbxDetailsRoute = `/pbxs/${pbxID}`;

    if (location.pathname === `${pbxDetailsRoute}/details`)
      return (
        <PbxAdditionalDetail
          pbx={pbx}
          callRates={callRates}
          routingProfiles={routingProfiles}
          resourceName={RESOURCE_NAMES.PBX}
        />
      );

    if (location.pathname.includes(`${pbxDetailsRoute}/user_licenses`))
      return (
        <UserLicenseIndex pbxActiveStatus={pbx.is_active} parentID={pbxID} />
      );

    if (location.pathname.includes(`${pbxDetailsRoute}/did_numbers`))
      return (
        <DidNumberIndex
          pbxActiveStatus={pbx.is_active && isAdminUser}
          parentID={pbxID}
        />
      );

    if (location.pathname.includes(`${pbxDetailsRoute}/cell_phones`))
      return (
        <CellPhoneIndex parentActiveStatus={pbx.is_active} parentID={pbxID} />
      );

    if (location.pathname.includes(`${pbxDetailsRoute}/sip_devices`))
      return (
        <SipDeviceIndex
          parentActiveStatus={pbx.is_active}
          parentID={pbxID}
          parentResource="pbx"
        />
      );

    return <Outlet />;
  };

  const tabRoutesDetail = [
    {
      tabRoute: DETAILS,
      tabTitle: <Translate value="common.additionalDetails" />,
    },
    {
      tabRoute: USER_LICENSES,
      tabTitle: <Translate value="title.tab.pbx.userLicense" />,
    },
    {
      tabRoute: DID_NUMBERS,
      tabTitle: <Translate value="title.tab.pbx.didNumber" />,
      perform: "didNumbers:list",
      data: {
        debtorID: odooDebtorID,
      },
    },
    {
      tabRoute: CELL_PHONES,
      tabTitle: <Translate value="title.tab.userLicense.cellPhones" />,
      perform: "pbx:cellPhones",
    },
    {
      tabRoute: SIP_DEVICES,
      tabTitle: <Translate value="pbx.allDesktopPhones" />,
      perform: "pbx:sipDevices",
    },
  ];

  return (
    <div className="pbx-detail container-fluid w-100">
      <PbxBasicDetail
        pbx={pbx}
        callRates={callRates}
        routingProfiles={routingProfiles}
        resourceName={RESOURCE_NAMES.PBX}
      />
      <TabWithRoute
        resourceName={RESOURCE_NAMES.PBX}
        routesDetail={tabRoutesDetail}
      >
        {getSharedRouteComponent()}
      </TabWithRoute>
    </div>
  );
};

PbxDetail.defaultProps = {
  isRoutingProfileFetching: true,
  isCallRateFetching: true,
  isLoading: true,
  fetchError: null,
};

PbxDetail.propTypes = {
  pbx: PropTypes.shape({
    id: PropTypes.number,
    is_active: PropTypes.bool,
    odoo_debtor_id: PropTypes.number,
  }).isRequired,
  fetchSinglePbx: PropTypes.func.isRequired,
  routingProfiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isRoutingProfileFetching: PropTypes.bool,
  fetchRoutingProfiles: PropTypes.func.isRequired,
  fetchCallRates: PropTypes.func.isRequired,
  isCallRateFetching: PropTypes.bool,
  callRates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  fetchError: PropTypes.string,
  isLoading: PropTypes.bool,
  currentlyLoggedInUser: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = ({
  pbx: pbxResource,
  routingProfile,
  callRate,
  auth,
}) => {
  const { pbx, fetchError, isLoading } = pbxResource;

  const { routingProfiles, isLoading: isRoutingProfileFetching } =
    routingProfile;
  const { callRates, isLoading: isCallRateFetching } = callRate;
  const { currentUser: currentlyLoggedInUser } = auth;

  return {
    pbx,
    isLoading,
    routingProfiles,
    isRoutingProfileFetching,
    callRates,
    isCallRateFetching,
    fetchError,
    currentlyLoggedInUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSinglePbx: (id) => {
    dispatch(singlePbxFetchRequest(id));
  },
  fetchRoutingProfiles: () => {
    dispatch(routingProfileFetchRequest());
  },
  fetchCallRates: () => {
    dispatch(callRateFetchRequest());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PbxDetail);
