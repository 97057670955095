import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import SettingIndex from "views/Setting/SettingIndex";
import AppSettingIndex from "views/AppSetting/AppSettingIndex";
import SubscriptionProductIndex from "views/SubscriptionProduct/SubscriptionProductIndex";

import { INDEX, SETTINGS } from "constants/routes/routesResources";
import { Admins } from "constants/userRoles";

const AdminSettingIndex = Admins(SettingIndex);

const SettingRoutes = () => (
  <Routes>
    <Route path={INDEX} element={<AdminSettingIndex />}>
      <Route path={SETTINGS.APP} element={<AppSettingIndex />} />
      <Route
        path={SETTINGS.SUBSCRIPTION_PRODUCT}
        element={<SubscriptionProductIndex />}
      />
    </Route>
    <Route index element={<Navigate to={SETTINGS.APP} replace />} />
  </Routes>
);

export default SettingRoutes;
