import { DashboardApi } from "internal";
import { getAndDisplayErrors } from "utils/utils";

export const DASHBOARD_AGREEMENT_FETCH_REQUEST =
  "DASHBOARD_AGREEMENT_FETCH_REQUEST";
export const DASHBOARD_AGREEMENT_FETCH_RECEIVED =
  "DASHBOARD_AGREEMENT_FETCH_RECEIVED";
export const DASHBOARD_AGREEMENT_FETCH_FAILED =
  "DASHBOARD_AGREEMENT_FETCH_FAILED";
export const DASHBOARD_AGREEMENT_FETCH_CLEAR_ERRORS =
  "DASHBOARD_AGREEMENT_FETCH_CLEAR_ERRORS";

export function dashboardAgreementFetchReceived(response) {
  return {
    type: DASHBOARD_AGREEMENT_FETCH_RECEIVED,
    data: response.data,
  };
}

export function dashboardAgreementFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: DASHBOARD_AGREEMENT_FETCH_FAILED,
    message,
  };
}

export function dashboardAgreementFetchClearErrors() {
  return {
    type: DASHBOARD_AGREEMENT_FETCH_CLEAR_ERRORS,
  };
}

export const dashboardAgreementFetchRequest = () => (dispatch) => {
  dispatch({ type: DASHBOARD_AGREEMENT_FETCH_REQUEST });

  DashboardApi.fetchAgreementDasboardDetail()
    .then((data) => dispatch(dashboardAgreementFetchReceived(data)))
    .catch((e) => {
      dispatch(dashboardAgreementFetchFailed(e));
    });
};
