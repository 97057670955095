import React from "react";
import { FormGroup, Col } from "reactstrap";
import { Field } from "react-final-form";
import PropTypes from "prop-types";

import { required } from "utils/validation";

import { renderDropdownField } from "components/ReduxForm/RenderField";
import LANGUAGE_DROPDOWN from "constants/languageTypes";

export const FIELD_TYPE = {
  FINAL_FORM: "final-form",
  REDUX_FORM: "redux-form",
};

const LanguageField = ({ show, isDebtorLanguageLoading }) => {
  if (!show) return null;

  return (
    <Col md="6">
      <FormGroup>
        <Field
          component={renderDropdownField}
          validate={required}
          dropdownOptions={LANGUAGE_DROPDOWN}
          isLoading={isDebtorLanguageLoading}
          placeholderTranslation="attributes.language"
          labelTranslation="attributes.language"
          name="locale"
          disabled={isDebtorLanguageLoading}
        />
      </FormGroup>
    </Col>
  );
};

LanguageField.defaultProps = {
  isDebtorLanguageLoading: false,
  show: true,
};

LanguageField.propTypes = {
  isDebtorLanguageLoading: PropTypes.bool,
  show: PropTypes.bool,
};

export default LanguageField;
