import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  pbxSipDevicesFetchRequest,
  pbxSipDevicesSearchRequest,
  pbxSearchSipDevices,
} from "redux/actions/pbx/sipDevice";

import filterSearchResults from "utils/filterSearchResults";

import SipDeviceIndexContent from "components/SipDevices/Index/SipDeviceIndexContent";

const SipDeviceIndex = ({
  sipDevices,
  isLoading,
  isSearching,
  parentActiveStatus,
  pbxSipDevicesFetchRequest: fetchAllSipDevices,
  parentID,
  searchRecords,
  parentResource,
}) => {
  useEffect(() => {
    fetchAllSipDevices(parentID);
  }, [parentID]);

  return (
    <SipDeviceIndexContent
      parentActiveStatus={parentActiveStatus}
      parentID={parentID}
      sipDevices={sipDevices}
      isLoading={isLoading}
      isSearching={isSearching}
      searchRecords={searchRecords}
      parentResource={parentResource}
    />
  );
};

SipDeviceIndex.defaultProps = {
  parentID: undefined,
  isLoading: true,
  sipDevices: undefined,
  isSearching: false,
};

SipDeviceIndex.propTypes = {
  sipDevices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      extension_number: PropTypes.number,
      user_license_id: PropTypes.number,
      user_license_extension_number: PropTypes.number,
      ex_description: PropTypes.string,
      ex_name: PropTypes.string,
    })
  ),
  pbxSipDevicesFetchRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  parentID: PropTypes.string,
  parentActiveStatus: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool,
  searchRecords: PropTypes.func.isRequired,
  parentResource: PropTypes.string.isRequired,
};

const mapStateToProps = ({ pbxSipDevice: pbxSipDeviceReducer, search }) => {
  const { sipDevices, isLoading, isSearching } = pbxSipDeviceReducer;

  const results = filterSearchResults({
    resource: sipDevices,
    searchResult: search.pbxSipDevices,
  });

  return {
    sipDevices: results,
    isLoading,
    isSearching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  pbxSipDevicesFetchRequest: (pbxID, filter) => {
    dispatch(pbxSipDevicesFetchRequest(pbxID, filter));
  },

  searchRecords: (e) => {
    dispatch(pbxSipDevicesSearchRequest(e));
    dispatch(pbxSearchSipDevices(e));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SipDeviceIndex);
