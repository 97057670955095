import React from "react";
import { Translate, t } from "react-i18nify";
import PropTypes from "prop-types";

import ServiceModal from "components/CellPhone/Form/NetworkOperatorSubscriptionServices/Modal/ServiceModal";
import ServiceTableRow from "components/CellPhone/Form/NetworkOperatorSubscriptionServices/ServiceTableRow";

const SubscriptionServicesList = ({
  fields,
  filteredSubscriptionServices,
  setFilteredSubscriptionServices,
  toggleModalVisibility,
  cellPhoneServices,
}) => {
  if (!fields) return null;

  return fields.value.map((subscriptionService, index) => {
    const fieldName = `network_operator_subscription_services[${index}]`;

    const { _destroy: isDeleted } = subscriptionService;

    if (isDeleted) {
      return null;
    }

    return (
      <ServiceTableRow
        fieldName={fieldName}
        filteredSubscriptionServices={filteredSubscriptionServices}
        setFilteredSubscriptionServices={setFilteredSubscriptionServices}
        toggleModalVisibility={toggleModalVisibility}
        key={fieldName}
        fields={fields}
        currentIndex={index}
        cellPhoneServices={cellPhoneServices}
      />
    );
  });
};

const ServiceListTable = ({
  fields,
  filteredSubscriptionServices,
  setFilteredSubscriptionServices,
  modalVisible,
  addNewSubscriptionService,
  toggleModalVisibility,
  resetTemporarySubscriptionServices,
  cellPhoneServices,
}) => {
  const enabledClassName = fields.length === 0 ? "pe-none" : "";

  return (
    <div className="table-responsive">
      <table
        className={`table subscription-listing-table text-center table-borderless card-with-header ${enabledClassName}`}
      >
        <thead className="fw-bold card-header border">
          <tr className="subscription-detail">
            <th className="align-middle text-center py-4">
              <p className="mb-0">
                <Translate value="attributes.services" />
              </p>
            </th>
            <th className="align-middle text-center py-4">
              <p className="mb-0">
                <Translate value="attributes.location" />
              </p>
            </th>
            <th className="align-middle text-center py-4">
              <p className="mb-0 ">
                <Translate value="attributes.locationConstraint" />
              </p>
            </th>
            <th className="align-middle text-center py-4">
              <p className="mb-0 ">
                <Translate value="common.action" />
              </p>
            </th>
          </tr>
        </thead>

        <tbody className="border border-top-0 rounded-bottom">
          <SubscriptionServicesList
            fields={fields}
            filteredSubscriptionServices={filteredSubscriptionServices}
            setFilteredSubscriptionServices={setFilteredSubscriptionServices}
            toggleModalVisibility={toggleModalVisibility}
            cellPhoneServices={cellPhoneServices}
          />

          <tr className="subscription-listing-table-row py-3 border">
            <td colSpan={4} className="align-middle text-end">
              <ServiceModal
                fieldName="temporary_network_operator_subscription_service"
                title={<Translate value="common.addService" />}
                buttonTitle={<Translate value="common.addService" />}
                modalVisible={modalVisible}
                onCancel={resetTemporarySubscriptionServices}
                onSubmit={addNewSubscriptionService}
                filteredSubscriptionServices={filteredSubscriptionServices}
                setFilteredSubscriptionServices={
                  setFilteredSubscriptionServices
                }
              />

              <button
                type="button"
                className="btn btn-primary mt-3"
                onClick={toggleModalVisibility}
                data-cy="add-network-service"
              >
                <Translate
                  value="title.resource.button.addResource"
                  resource={t(`common.service`)}
                />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

SubscriptionServicesList.defaultProps = {};

SubscriptionServicesList.propTypes = {
  fields: PropTypes.shape({
    remove: PropTypes.func,
    push: PropTypes.func,
    pop: PropTypes.func,
    map: PropTypes.func,
    length: PropTypes.func,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        length: PropTypes.func,
      })
    ),
  }).isRequired,
  filteredSubscriptionServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  setFilteredSubscriptionServices: PropTypes.func.isRequired,
  toggleModalVisibility: PropTypes.func.isRequired,
  cellPhoneServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
};

ServiceListTable.defaultProps = {};

ServiceListTable.propTypes = {
  fields: PropTypes.shape({
    remove: PropTypes.func,
    push: PropTypes.func,
    pop: PropTypes.func,
    map: PropTypes.func,
    length: PropTypes.func,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        length: PropTypes.func,
      })
    ),
  }).isRequired,
  toggleModalVisibility: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  resetTemporarySubscriptionServices: PropTypes.func.isRequired,
  temporarySubscriptionServices: PropTypes.shape({}).isRequired,
  addNewSubscriptionService: PropTypes.func.isRequired,
  filteredSubscriptionServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      is_default: PropTypes.bool,
    })
  ).isRequired,
  setFilteredSubscriptionServices: PropTypes.func.isRequired,
  cellPhoneServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      is_default: PropTypes.bool,
    })
  ).isRequired,
};

export default ServiceListTable;
