import React from "react";
import Skeleton from "react-loading-skeleton";

import HeaderSkeleton from "components/Skeleton/HeaderSkeleton";
import TabTableSkeleton from "components/Skeleton/TabTableSkeleton";

const PbxDetailSkeleton = () => (
  <div className="container-fluid">
    <HeaderSkeleton />
    <TabTableSkeleton />
    <div className="bg-white rounded shadow-sm">
      <div className="d-flex p-3">
        <Skeleton width={75} height={20} className="me-4" />
        <Skeleton width={75} height={20} className="me-4" />
        <Skeleton width={75} height={20} />
      </div>
      <div className="m-0 border-bottom border-gray-100" />
      <div className="d-flex flex-column p-3">
        <TabTableSkeleton />
        <TabTableSkeleton />
        <TabTableSkeleton />
      </div>
    </div>
  </div>
);

export default PbxDetailSkeleton;
