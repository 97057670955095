import { createSearchAction } from "redux-search";

import { InvoiceApi, DebtorApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

export const INVOICE_FETCH_REQUEST = "INVOICE_FETCH_REQUEST";
export const INVOICE_FETCH_RECEIVED = "INVOICE_FETCH_RECEIVED";
export const INVOICE_FETCH_FAILED = "INVOICE_FETCH_FAILED";
export const SINGLE_INVOICE_FETCH_REQUEST = "SINGLE_INVOICE_FETCH_REQUEST";
export const SINGLE_INVOICE_FETCH_RECEIVED = "SINGLE_INVOICE_FETCH_RECEIVED";
export const SINGLE_INVOICE_FETCH_FAILED = "SINGLE_INVOICE_FETCH_FAILED";
export const INVOICE_FETCH_CLEAR_ERRORS = "INVOICE_FETCH_CLEAR_ERRORS";
export const INVOICE_SEARCH_REQUEST = "INVOICE_SEARCH_REQUEST";

export const DEBTOR_INVOICE_FETCH_REQUEST = "DEBTOR_INVOICE_FETCH_REQUEST";
export const DEBTOR_INVOICE_FETCH_RECEIVED = "DEBTOR_INVOICE_FETCH_RECEIVED";
export const DEBTOR_INVOICE_FETCH_FAILED = "DEBTOR_INVOICE_FETCH_FAILED";

export const searchInvoices = createSearchAction("invoices");

export function invoiceFetchReceived(response) {
  return {
    type: INVOICE_FETCH_RECEIVED,
    invoices: response.data,
  };
}

export function invoiceFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: INVOICE_FETCH_FAILED,
    message,
  };
}

export const invoiceFetchRequest = () => (dispatch) => {
  dispatch({ type: INVOICE_FETCH_REQUEST });

  InvoiceApi.fetchAll()
    .then((data) => dispatch(invoiceFetchReceived(data)))
    .catch((e) => {
      dispatch(invoiceFetchFailed(e));
    });
};

// Fetch single invoice
export function singleInvoiceFetchReceived(response) {
  return {
    type: SINGLE_INVOICE_FETCH_RECEIVED,
    invoice: response.data,
  };
}

export function singleInvoiceFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: SINGLE_INVOICE_FETCH_FAILED,
    message,
  };
}

export const singleInvoiceFetchRequest = (id) => (dispatch) => {
  dispatch({ type: SINGLE_INVOICE_FETCH_REQUEST });

  InvoiceApi.fetchOne(id)
    .then((data) => dispatch(singleInvoiceFetchReceived(data)))
    .catch((e) => {
      dispatch(singleInvoiceFetchFailed(e));
    });
};

export function invoiceFetchClearErrors() {
  return {
    type: INVOICE_FETCH_CLEAR_ERRORS,
  };
}

export function invoiceSearchRequest(value) {
  const isSearching = Boolean(value);

  return {
    type: INVOICE_SEARCH_REQUEST,
    isSearching,
  };
}

export const debtorInvoiceFetchReceived = (response) => ({
  type: DEBTOR_INVOICE_FETCH_RECEIVED,
  invoices: response.data,
});

export const debtorInvoiceFetchFailed = (errorData) => {
  const message = getAndDisplayErrors(errorData);

  return {
    type: DEBTOR_INVOICE_FETCH_FAILED,
    message,
  };
};

export const debtorInvoiceFetchRequest = (debtorID) => (dispatch) => {
  dispatch({
    type: DEBTOR_INVOICE_FETCH_REQUEST,
  });

  DebtorApi.invoices(debtorID)
    .then((data) => dispatch(debtorInvoiceFetchReceived(data)))
    .catch((err) => {
      dispatch(debtorInvoiceFetchFailed(err));
    });
};
