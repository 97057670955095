import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Navigate, useParams } from "react-router-dom";
import { Translate, t } from "react-i18nify";

import {
  didNumberUpdateRequest,
  singleDidNumberFetchRequest,
} from "redux/actions/didNumber";

import DidNumberForm from "components/DidNumber/Form/DidNumberForm";
import WholePageSpinner from "components/Spinner/WholePageSpinner";

import { DID_NUMBER } from "constants/routes";
import PageNotFound from "components/Error/PageNotFound/PageNotFound";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const DidNumberEdit = ({
  updateDidNumber,
  commitSuccess,
  didNumber,
  isLoading,
  fetchSingleDidNumber,
}) => {
  const { didNumberID } = useParams();

  useEffect(() => {
    fetchSingleDidNumber(didNumberID);
  }, [didNumberID]);

  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    const { id } = didNumber;

    await updateDidNumber(id, values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess) {
    const { id } = didNumber;

    return <Navigate to={DID_NUMBER.addId(DID_NUMBER.SHOW, id)} />;
  }

  if (!didNumber) {
    return <WholePageSpinner />;
  }

  const {
    fax_service_enabled: faxServiceEnabled,
    is_active: isActive,
    termination_date_end_limit: terminationDateEndLimit,
    subscription_line: { id: subscriptionLineId },
    user_license_id: userLicenseID,
  } = didNumber;

  if (!isActive) {
    return (
      <div className="d-flex h-100 justify-content-center">
        <PageNotFound />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.edit"
            resource={t("title.resource.didNumber")}
          />
        </h3>
      </div>
      <DidNumberForm
        onSubmit={handleSubmit}
        formName="didNumberForm"
        formType="edit"
        isLoading={isLoading}
        initialValues={didNumber}
        faxServiceEnabled={faxServiceEnabled}
        terminationDateEndLimit={terminationDateEndLimit}
        subscriptionLineId={subscriptionLineId}
        userLicenseID={userLicenseID}
        showFaxServiceSection={faxServiceEnabled}
      />
    </div>
  );
};

const mapStateToProps = ({ didNumber: didNumberReducer }) => {
  const { didNumberError, didNumber, commitSuccess, commitError, isLoading } =
    didNumberReducer;

  return {
    didNumberUpdateError: didNumberError,
    isLoading,
    didNumber,
    commitError,
    commitSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleDidNumber: (id) => {
    dispatch(singleDidNumberFetchRequest(id));
  },
  updateDidNumber: async (id, attributes, callback) => {
    await dispatch(didNumberUpdateRequest(id, attributes, false, callback));
  },
});

DidNumberEdit.defaultProps = {
  didNumber: undefined,
  commitError: {},
};

DidNumberEdit.propTypes = {
  updateDidNumber: PropTypes.func.isRequired,
  commitSuccess: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchSingleDidNumber: PropTypes.func.isRequired,
  commitError: PropTypes.shape({}),
  didNumber: PropTypes.shape({
    id: PropTypes.number,
    fax_service_enabled: PropTypes.bool,
    is_active: PropTypes.bool,
    termination_date_end_limit: PropTypes.instanceOf(Date),
    subscription_line: PropTypes.shape({
      id: PropTypes.number,
    }),
    user_license_id: PropTypes.number,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(DidNumberEdit);
