import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { t, Translate } from "react-i18nify";

import {
  subscriptionProductFetchRequest,
  subscriptionProductsSearchRequest,
} from "redux/actions/subscriptionProduct";

import SubscriptionProductSkeleton from "components/Skeleton/ResourceList/SubscriptionProduct/SubscriptionProductSkeleton";

import filterSearchResults from "utils/filterSearchResults";

import { SUBSCRIPTION_PRODUCT } from "constants/routes";

import SubscriptionProductContent from "components/SubscriptionProduct/Index/SubscriptionProductContent";

const SubscriptionProductIndex = ({
  subscriptionProducts,
  isLoading,
  fetchAllSubscriptionProducts,
}) => {
  const [collapse, setCollapse] = useState({});

  useEffect(() => {
    fetchAllSubscriptionProducts();
  }, []);

  const subscriptionProductTranslation = t(
    "title.tab.setting.subscriptionProducts"
  );

  useEffect(() => {
    if (subscriptionProducts) {
      const itemWithID = subscriptionProducts.map(({ id }) => ({
        [id]: false,
      }));

      const itemWithIDObj = Object.assign({}, ...itemWithID);

      if (itemWithIDObj) {
        let defaultSelectedItem = null;

        if (itemWithID[0]) {
          const item = Object.keys(itemWithID[0]);

          [defaultSelectedItem] = item; // first item of array for default selected item
        }
        const defaultTrue = Object.keys(itemWithIDObj)[0];

        setCollapse({
          ...itemWithIDObj,
          [defaultSelectedItem || defaultTrue]: true,
        });
      }
    }
  }, [subscriptionProducts]);

  if (isLoading) {
    return <SubscriptionProductSkeleton />;
  }

  return (
    <div>
      <div className="m-4 d-md-flex align-items-center justify-content-between">
        <h3 className="m-0 mb-4 mb-md-0">
          <Translate
            value="title.page.resource.list"
            resource={subscriptionProductTranslation}
          />
        </h3>

        <Link
          data-cy="btn-add"
          to={SUBSCRIPTION_PRODUCT.CREATE}
          className="btn btn-primary"
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          <Translate value="common.add" />
        </Link>
      </div>

      {subscriptionProducts.map((subscriptionProduct) => (
        <SubscriptionProductContent
          dataCy="subscription-product"
          subscriptionProduct={subscriptionProduct}
          setCollapse={setCollapse}
          collapse={collapse}
          key={subscriptionProduct.id}
        />
      ))}
    </div>
  );
};

SubscriptionProductIndex.defaultProps = {
  isLoading: true,
};

SubscriptionProductIndex.propTypes = {
  subscriptionProducts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      category: PropTypes.string,
    })
  ).isRequired,
  isLoading: PropTypes.bool,
  fetchAllSubscriptionProducts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { subscriptionProducts, isLoading, fetchError, isSearching } =
    state.subscriptionProduct;

  const results = filterSearchResults({
    resource: subscriptionProducts,
    searchResult: state.search.subscriptionProducts,
  });

  return {
    subscriptionProducts: results,
    isLoading,
    fetchError,
    isSearching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAllSubscriptionProducts: () => {
    dispatch(subscriptionProductFetchRequest());
  },
  searchRecords: (value) => {
    dispatch(subscriptionProductsSearchRequest(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionProductIndex);
