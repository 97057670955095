export const CELL_PHONE_ACTIONS = {
  SUSPEND_CELL_PHONE: "suspend-cell-phone",
  REACTIVATE_CELL_PHONE: "reactivate-cell-phone",
  ORDER_NEW_SIM_CARD: "order-new-sim-card",
  ASSIGN_SIM_CARD: "assign-sim-card",
};

export const CELL_PHONE_ALLOWED_ACTIONS = [
  CELL_PHONE_ACTIONS.ORDER_NEW_SIM_CARD,
];

export const CELL_PHONE_UNALLOWED_ACTIONS = [
  CELL_PHONE_ACTIONS.REACTIVATE_CELL_PHONE,
  CELL_PHONE_ACTIONS.SUSPEND_CELL_PHONE,
  CELL_PHONE_ACTIONS.ASSIGN_SIM_CARD,
];
