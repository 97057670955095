import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Translate } from "react-i18nify";
import { faTimesCircle, faRedo } from "@fortawesome/free-solid-svg-icons";

const FetchError = ({ onRetry, buttonTitle, errorText }) => {
  const translateBtnText = buttonTitle || "error.refresh";

  return (
    <div className="p-4 h-100 shadow-sm bg-white d-flex flex-column align-items-center justify-content-center error-page">
      <div>
        <FontAwesomeIcon
          size="5x"
          className="error-icon mb-4 text-danger"
          icon={faTimesCircle}
        />
      </div>
      <div className="text-center">
        <p className="fw-bold">
          <Translate value="error.error" />
        </p>
        <p className="sub-text text-danger">
          {errorText || <Translate value="error.description" />}
        </p>
      </div>
      <div>
        <button
          type="button"
          className="btn btn-primary rounded"
          onClick={onRetry}
        >
          <FontAwesomeIcon icon={faRedo} />
          <span className="ps-2">
            <Translate value={translateBtnText} />
          </span>
        </button>
      </div>
    </div>
  );
};

FetchError.defaultProps = {
  onRetry: () => {},
  buttonTitle: "error.refresh",
  errorText: undefined,
};

FetchError.propTypes = {
  onRetry: PropTypes.func,
  buttonTitle: PropTypes.string,
  errorText: PropTypes.string,
};

export default FetchError;
