// eslint-disable-next-line import/prefer-default-export
export const SIM_CARD_HOLDER = {
  EMPLOYEE: "employee",
  CUSTOMER: "customer",
  STAY_CONNECTED: "stay connected",
};

export const SIM_CARD_ASSIGN_TYPE = {
  RESELLER: "reseller",
  BORROW_SIM_CARD: "borrow sim card",
};

export const SIM_CARD_STATUS = {
  AVAILABLE: "available",
  BLOCKED: "blocked",
};

export const CELL_PHONE_STATUS = {
  SUSPENDED: "suspended",
  ACTIVE: "active",
};

export const SIM_CARD_BORROWERS = {
  EMPLOYEE: "employee",
  DEBTOR: "debtor",
  CUSTOMER: "customer",
};

export const SUBSCRIPTION_TYPES = {
  1: "voip",
  2: "cell_phone",
  3: "internet_connections",
  4: "hosting_services",
  5: "service_contracts",
};

export const SUBSCRIPTION_LINE_TYPES = {
  1: "subscription",
  2: "add_on",
  3: "setup",
};

export const DROPDOWN_ELLIPSIS_ACTIONS = {
  QUOTATION_PREVIEW: "quotation-preview",
  DELETE_MODAL: "delete-modal",
  SEND_EMAIL: "send-email",
  CONVERT_TO_AGREEMENT: "convert-to-agreement",
};

export const SUBCRIPTION_LINE_TYPE = {
  INTERNET_CONNECTIONS: "internet_connections",
};

export const SUBSCRIPTION_LINE_PROPERTY_NAME = {
  TERMINATION_ADDRESS: "Termination address",
};

export const BRAND_NAME = "Stay Connected";

export const ODOO_CONTEXT_TYPES = {
  voip: "1",
  cell_phone: "2",
  internet_connections: "3",
  hosting_services: "4",
  service_contracts: "5",
};

export const NETWORK_SERVICE_DEFAULT_LOCATIONS = {
  DENMARK: "denmark",
  ENTIRE_WORLD: "entire_world",
};

export const NETWORK_SERVICE_DEFAULT_LOCATION_CONSTRAINT = {
  INSIDE: "inside",
};

export const NETWORK_SERVICE_LOCATION_CONSTRAINT_TYPES = {
  INSIDE: "inside",
  OUTSIDE: "outside",
};

export const NETWORK_SERVICES_WITH_RESTRICTIONS = [
  "CW",
  "VOLTE",
  "VOWIFI",
  "NB_IOT",
  "QOS",
];

export const NETWORK_SERVICES_INSIDE_DENMARK = [
  "O_CSI (Type ID: 15)",
  "T_CSI (Type ID: 16)",
];

export const BINDING_PERIOD_AND_SUBSCRIPTION_TYPES_MAPPING = {
  "voice-voip": 0,
  "voice-cell_phone": 0,
  "xdsl-internet_connections": 24,
  "fiber-internet_connections": 24,
  "fwa-internet_connections": 12,
  "hosting_services-hosting_services": 24,
};

export const XDSL_SUBSCRIPTION = "xdsl";

export const PARENT_IDENTIFIER_KEY = {
  PBX_ID: "pbx_id",
};

export const RESOURCE_NAMES = {
  PBX: "pbx",
  DEBTOR: "debtor",
  USER_LICENSE: "user_license",
  CELL_PHONE: "cell_phone",
};

export const PBX = "pbx";

export const AGREEMENT_OPTIONS = {
  NEW: "new",
};

export const NUMBER_PORTING_REQUESTS = {
  EXPORT_STATUS: {
    ACCEPTED: "accepted",
    REJECTED: "rejected",
    PROPOSED_NEW_DATE: "proposed_new_date",
  },
  PERSON_FILLING_FORM: {
    SUPER_ADMIN: "super_admin",
    CUSTOMER: "customer",
  },
};

export const USER_STATUSES = {
  ACTIVE: "active",
};

export const USER_ROLE_TYPES = {
  COMPANY_USERS: "company_users",
};

export const AGREEMENT_FILTERS = {
  ACTIVE: "active",
};

export const NUMBER_PORTING_REQUEST_STATUSES = {
  ACTIVE: "active",
};

export const QUOTATION_STATUSES = {
  SENT: "sent",
};

export const DASHBOARD_COMPONENT_FEATURE_TYPES = {
  AGREEMENTS: "agreements",
  INVOICES: "invoices",
  BILLS: "bills",
  USERS: "users",
  DEBTORS: "debtors",
};

export const USER_ALERTS = {
  ERROR: "error",
  WARNING: "warning",
  DANGER: "danger",
};

export const PHONE_NUMBER_REQUEST_ATTRIBUTES = {
  NUMBER_TYPE: "cell_phone",
  STATUS: "available",
  COST_TYPE: "free",
};
