import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Column } from "react-virtualized";

import { t, Translate } from "react-i18nify";
import TranslateDate from "components/i18n/TranslateDate";

import { numberPortingRequestPhoneNumberCancelRequest } from "redux/actions/numberPortingRequestPhoneNumber";
import { numberPortingRequestImportStatusPollRequest } from "redux/actions/numberPortingRequest";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";

import CancelSingleImportFlowModal from "components/NumberPortingRequest/Detail/CancelSingleImportFlowModal";
import ActivityLogModal from "components/NumberPortingRequest/Import/Detail/ActivityLogModal";

const renderPhoneNumberTableTranslateDate = ({ cellData }) => (
  <TranslateDate date={cellData} />
);

const renderCancelSingleImportFlowModal = ({
  cellData,
  rowData,
  cancelPortingFlow,
}) =>
  cellData && (
    <CancelSingleImportFlowModal
      resourceIdentifier={rowData.service_number}
      cancelPortingFlow={cancelPortingFlow}
      resourceID={rowData.id}
    />
  );

const renderActivityLogModal = ({ cellData, rowData }) => (
  <ActivityLogModal
    resourceIdentifier={rowData.service_number}
    activityLogs={cellData}
  />
);

const NumberPortingRequestPhoneNumberTable = ({
  requestedPhoneNumbers,
  canRefresh,
  isRefreshing,
  numberType,
  refNumber,
  isArchived,
}) => {
  const dispatch = useDispatch();

  const refreshRequestedNumbers = () => {
    dispatch(numberPortingRequestImportStatusPollRequest(refNumber));
  };

  const cancelPortingFlow = (id, callBack) => {
    dispatch(numberPortingRequestPhoneNumberCancelRequest(id, callBack));

    refreshRequestedNumbers();
  };

  const cancellableRequests = requestedPhoneNumbers.map(
    (number) => number.can_cancel
  );
  const showCancelColumn = cancellableRequests.includes(true);

  const confirmedPortingDates = requestedPhoneNumbers.map(
    (number) => number.confirmed_porting_date?.length > 0
  );
  const showConfirmedPortingDateColumn = confirmedPortingDates.includes(true);

  const rejectionReasons = requestedPhoneNumbers.map(
    (number) => number.rejection_reason?.length > 0
  );
  const showRejectionReasonColumn = rejectionReasons.includes(true);

  const acceptedRequests = requestedPhoneNumbers.filter(
    (number) => number.import_status === "Accepted"
  );
  const allAccepted = acceptedRequests.length === requestedPhoneNumbers.length;

  return (
    <div className="h-30em">
      <RecordListingTable
        records={requestedPhoneNumbers}
        resourceTitle={
          <Translate
            value="title.page.resource.list"
            resource={t("numberPortingRequest.requestedPhoneNumbers")}
          />
        }
        hideAddButton
        hideSearchBar
        disableRowClick
        rowClassName="row-click-disabled"
        refreshRecords={canRefresh && !allAccepted && refreshRequestedNumbers}
        isRefreshing={isRefreshing}
      >
        <Column
          width={160}
          label={<Translate value="attributes.phone" />}
          dataKey="service_number"
        />

        {numberType === "cell_phone" && (
          <Column
            width={230}
            label={<Translate value="attributes.iccNumber" />}
            dataKey="icc_number"
          />
        )}

        <Column
          width={150}
          label={<Translate value="attributes.status" />}
          dataKey="import_status"
          flexGrow={1}
        />

        {showRejectionReasonColumn && (
          <Column
            width={250}
            label={<Translate value="common.rejectionReason" />}
            dataKey="rejection_reason"
            flexGrow={1}
          />
        )}

        {showConfirmedPortingDateColumn && (
          <Column
            width={210}
            label={<Translate value="attributes.confirmedPortingDate" />}
            dataKey="confirmed_porting_date"
            cellRenderer={renderPhoneNumberTableTranslateDate}
          />
        )}

        {!isArchived && !showCancelColumn && (
          <Column
            width={100}
            label={<Translate value="common.cancel" />}
            dataKey="can_cancel"
            cellRenderer={({ cellData, rowData }) =>
              renderCancelSingleImportFlowModal({
                cellData,
                rowData,
                cancelPortingFlow,
              })
            }
          />
        )}

        <Column
          width={100}
          label={<Translate value="numberPortingRequest.activityLogs" />}
          dataKey="number_porting_request_phone_numbers_activity_logs"
          cellRenderer={renderActivityLogModal}
        />
      </RecordListingTable>
    </div>
  );
};

NumberPortingRequestPhoneNumberTable.defaultProps = {};

NumberPortingRequestPhoneNumberTable.propTypes = {
  requestedPhoneNumbers: PropTypes.arrayOf(PropTypes.shape).isRequired,
  canRefresh: PropTypes.bool.isRequired,
  isRefreshing: PropTypes.bool.isRequired,
  numberType: PropTypes.string.isRequired,
  refNumber: PropTypes.string.isRequired,
  isArchived: PropTypes.bool.isRequired,
};

export default NumberPortingRequestPhoneNumberTable;
