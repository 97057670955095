import {
  AGREEMENT_FETCH_REQUEST,
  AGREEMENT_FETCH_RECEIVED,
  AGREEMENT_FETCH_FAILED,
  AGREEMENT_UPDATE_REQUEST,
  AGREEMENT_UPDATE_RECEIVED,
  AGREEMENT_UPDATE_FAILED,
  AGREEMENT_CREATE_REQUEST,
  AGREEMENT_CREATE_RECEIVED,
  AGREEMENT_CREATE_FAILED,
  SINGLE_AGREEMENT_FETCH_REQUEST,
  SINGLE_AGREEMENT_FETCH_RECEIVED,
  SINGLE_AGREEMENT_FETCH_FAILED,
  AGREEMENT_FETCH_CLEAR_ERRORS,
  AGREEMENT_SEARCH_REQUEST,
  AGREEMENT_OVERVIEW_REQUEST,
  AGREEMENT_OVERVIEW_RECEIVED,
  AGREEMENT_OVERVIEW_FAILED,
  SEARCH_AGREEMENTS_FAILED,
  SEARCH_AGREEMENTS_RECEIVED,
  SEARCH_AGREEMENTS_REQUEST,
  CLEAR_SEARCH,
  CLEAR_AGREEMENTS,
  DEBTOR_AGREEMENT_FETCH_FAILED,
  DEBTOR_AGREEMENT_FETCH_RECEIVED,
  DEBTOR_AGREEMENT_FETCH_REQUEST,
} from "redux/actions/agreement";

const defaultState = {
  agreements: [],
  fetchError: "",
  commitError: "",
  commitSuccess: false,
  isSearching: false,
  agreementOverview: {},
  searchedAgreements: [],
};

function agreement(state = defaultState, action) {
  const { agreements, agreement: singleAgreement, agreementOverview } = action;

  switch (action.type) {
    case AGREEMENT_FETCH_REQUEST:
    case DEBTOR_AGREEMENT_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
      };

    case AGREEMENT_FETCH_RECEIVED:
    case DEBTOR_AGREEMENT_FETCH_RECEIVED:
      return {
        ...state,
        agreements,
        isLoading: false,
      };

    case AGREEMENT_FETCH_FAILED:
    case DEBTOR_AGREEMENT_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case AGREEMENT_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case AGREEMENT_UPDATE_RECEIVED:
      return {
        ...state,
        agreement: singleAgreement,
        isLoading: false,
        commitSuccess: true,
      };

    case AGREEMENT_UPDATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case AGREEMENT_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case AGREEMENT_CREATE_RECEIVED:
      return {
        ...state,
        agreement: singleAgreement,
        isLoading: false,
        commitSuccess: true,
      };

    case AGREEMENT_CREATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case SINGLE_AGREEMENT_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitSuccess: false,
      };

    case SINGLE_AGREEMENT_FETCH_RECEIVED:
      return {
        ...state,
        agreement: singleAgreement,
        isLoading: false,
      };

    case SINGLE_AGREEMENT_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case AGREEMENT_OVERVIEW_REQUEST:
      return {
        ...state,
        fetchError: "",
      };

    case AGREEMENT_OVERVIEW_RECEIVED:
      return {
        ...state,
        agreementOverview,
        isLoading: false,
      };

    case AGREEMENT_OVERVIEW_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case AGREEMENT_FETCH_CLEAR_ERRORS:
      return {
        ...state,
        fetchError: "",
        isLoading: false,
        commitSuccess: false,
        commitError: "",
      };

    case AGREEMENT_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    case SEARCH_AGREEMENTS_FAILED:
      return {
        ...state,
        searching: false,
        searchSuccess: false,
        searchError: true,
      };

    case SEARCH_AGREEMENTS_RECEIVED:
      return {
        ...state,
        searchedAgreements: action.searchedAgreements,
        searching: false,
        searchSuccess: true,
        searchError: false,
      };

    case SEARCH_AGREEMENTS_REQUEST:
      return {
        ...state,
        searching: true,
        searchSuccess: false,
        searchError: false,
      };

    case CLEAR_SEARCH:
      return {
        ...state,
        searchedAgreements: [],
        searching: false,
        searchSuccess: false,
        searchError: false,
      };

    case CLEAR_AGREEMENTS:
      return {
        ...defaultState,
      };

    default:
      return state;
  }
}

export default agreement;
