import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { dashboardDebtorFetchRequest } from "redux/actions/dashboard/debtor";

import DashboardComponent from "components/Dashboard/DashboardComponent/DashboardComponent";
import DebtorItem from "components/Dashboard/DashboardItem/DebtorItem";

import { DASHBOARD_COMPONENT_FEATURE_TYPES } from "constants/constant";

const DebtorView = ({ fetchDebtor, debtor, isLoading, fetchError }) => {
  useEffect(() => {
    fetchDebtor();
  }, [fetchDebtor]);

  return (
    <DashboardComponent
      translationTitle="menuItem.debtor"
      isLoading={isLoading || !debtor}
      fetchError={fetchError}
      onRetry={fetchDebtor}
      featureType={DASHBOARD_COMPONENT_FEATURE_TYPES.DEBTORS}
    >
      <DebtorItem item={debtor} />
    </DashboardComponent>
  );
};

DebtorView.defaultProps = {
  fetchDebtor: () => {},
  debtor: null,
  isLoading: false,
  fetchError: false,
};

DebtorView.propTypes = {
  fetchDebtor: PropTypes.func,
  debtor: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  fetchError: PropTypes.bool,
};

const mapStateToProps = ({ dashboard }) => ({
  ...dashboard.debtor,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDebtor: () => dispatch(dashboardDebtorFetchRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DebtorView);
