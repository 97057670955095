import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabContent,
  TabPane,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faReceipt, faTty } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { Translate } from "react-i18nify";

import UserSettingSkeleton from "components/Skeleton/ResourceDetail/UserSetting/UserSettingSkeleton";
import InvoiceSettingsForm from "components/UserSetting/Form/InvoiceSettingsForm";
import PbxsSettingsForm from "components/UserSetting/Form/PbxsSettingsForm";

import { camelize } from "utils/string";

const UserSettingForm = ({
  settings,
  userSettings,
  userID,
  updateUserSettings,
}) => {
  useEffect(() => {}, [settings, userSettings]);

  const [activeTab, setActiveTab] = useState("invoice");

  const settingValues = settings.map((setting) => {
    const {
      id,
      options,
      data_type: dataType,
      category,
      name,
      description,
      display_type: displayType,
    } = setting;

    const settingValue = userSettings.find(
      (userSetting) => userSetting.setting_id === id
    );

    const userSettingValue = settingValue || {};

    const userSetting = {
      id: userSettingValue.id,
      settingID: id,
      options,
      dataType,
      category,
      value: userSettingValue.value,
      name,
      description,
      displayType,
    };

    return userSetting;
  });

  const pbxSettings = settingValues.filter(
    (setting) => setting.category === "pbx"
  );

  const invoiceSettings = settingValues.filter(
    (setting) => setting.category === "invoice"
  );

  const fieldNamesAndValues = (settingsCategory) =>
    settingsCategory.map((settingValue) => {
      let value = null;

      if (settingValue.value === "true") {
        value = true;
      } else if (settingValue.value === "false") {
        value = false;
      } else if (settingValue.dataType === "time") {
        value = settingValue.value
          ? moment(new Date(`${settingValue.value}`))
          : moment();
      } else {
        value = settingValue.value;
      }

      return {
        [camelize(settingValue.name)]: value,
      };
    });

  const invoiceSettingsInitialValues = Object.assign(
    {},
    ...fieldNamesAndValues(invoiceSettings)
  );

  const pbxSettingsInitialValues = Object.assign(
    {},
    ...fieldNamesAndValues(pbxSettings)
  );

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  if (invoiceSettings.length === 0 && pbxSettings.length === 0) {
    return <UserSettingSkeleton withHeader={false} />;
  }

  return (
    <div className="mt-4">
      <Row>
        <Col lg="3">
          <Nav vertical className="ml-0 pt-3 left-nav-links">
            <NavItem>
              <NavLink
                className={{ active: activeTab === "invoice" }}
                onClick={() => toggleTab("invoice")}
              >
                <FontAwesomeIcon icon={faReceipt} />
                <Translate value="enum.setting.categoryOptions.invoice" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "pbx" }}
                onClick={() => toggleTab("pbx")}
              >
                <FontAwesomeIcon icon={faTty} />
                <Translate value="enum.setting.categoryOptions.pbx" />
              </NavLink>
            </NavItem>
            <NavItem className="user-setting-coming-soon">
              <NavLink>
                <FontAwesomeIcon icon={faUser} />
                <Translate value="common.comingSoon" />
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
        <Col lg="9">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="invoice">
              <InvoiceSettingsForm
                userID={userID}
                initialValues={invoiceSettingsInitialValues}
                updateUserSettings={updateUserSettings}
                invoiceSettings={invoiceSettings}
              />
            </TabPane>
            <TabPane tabId="pbx">
              <PbxsSettingsForm
                userID={userID}
                initialValues={pbxSettingsInitialValues}
                updateUserSettings={updateUserSettings}
                pbxSettings={pbxSettings}
              />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

UserSettingForm.propTypes = {
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      category: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.string),
      data_type: PropTypes.string,
      user_type: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  userSettings: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.number,
      setting_id: PropTypes.number,
      value: PropTypes.string,
    })
  ).isRequired,
  userID: PropTypes.number.isRequired,
  updateUserSettings: PropTypes.func.isRequired,
};

export default UserSettingForm;
