import React, { useState } from "react";
import { connect } from "react-redux";
import { Translate } from "react-i18nify";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";

import SuspendModal from "components/Modal/CellPhoneSuspendModal";

import HasPermission from "hoc/RbacCTA";

import { CELL_PHONE_ACTIONS } from "constants/rolePermission";

import {
  cellPhoneFetchClearErrors,
  suspendCellPhoneRequest,
} from "redux/actions/cellPhone";

const SuspendCellPhone = ({
  cellNumber,
  cellNumberID,
  error,
  suspendCellPhone,
  clearError,
  isSubmitting,
}) => {
  const [isModalVisible, setModalVisibility] = useState(false);

  const resetModalState = () => {
    clearError();
    setModalVisibility(false);
  };

  const handleSubmit = (values) => {
    suspendCellPhone(cellNumberID, values);
    resetModalState();
  };

  return (
    <HasPermission
      perform="cellPhone:suspend"
      data={{ action: CELL_PHONE_ACTIONS.SUSPEND_CELL_PHONE }}
    >
      <div className="ps-2 cell-phone-suspended">
        <button
          className="btn suspended-button"
          type="button"
          onClick={() => setModalVisibility((prevState) => !prevState)}
        >
          <FontAwesomeIcon icon={faBan} /> <Translate value="common.suspend" />
        </button>
        <SuspendModal
          modalVisible={isModalVisible}
          onCancel={() => setModalVisibility(false)}
          onSubmit={handleSubmit}
          cellNumber={cellNumber}
          error={error}
          isLoading={isSubmitting}
        />
      </div>
    </HasPermission>
  );
};

SuspendCellPhone.defaultProps = {
  error: "",
  isSubmitting: false,
};

SuspendCellPhone.propTypes = {
  cellNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  cellNumberID: PropTypes.number.isRequired,
  error: PropTypes.string,
  suspendCellPhone: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

const mapStateToProps = ({ cellPhone: cellPhoneResource }) => {
  const { commitError, commitSuccess, isSubmitting } = cellPhoneResource;

  return {
    commitSuccess,
    error: commitError,
    isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  suspendCellPhone: (id, attributes) => {
    dispatch(suspendCellPhoneRequest(id, attributes));
  },
  clearError: () => {
    dispatch(cellPhoneFetchClearErrors());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SuspendCellPhone);
