import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Translate } from "react-i18nify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faMobile,
  faNetworkWired,
  faServer,
} from "@fortawesome/free-solid-svg-icons";
import CardWithHeader from "components/Card/WithHeader/CardWithHeader";

import { camelize } from "utils/string";

const BindingPeriodModalBody = ({ bindingPeriods, childResourceName }) => {
  if (bindingPeriods.length === 0) {
    return (
      <div className="p-4 h-100 shadow-sm bg-white d-flex flex-column align-items-center justify-content-center empty-table">
        <div className="info-icon-wrapper border-danger mb-2">
          <FontAwesomeIcon className="info-icon text-danger" icon={faInfo} />
        </div>
        <div className="text-center fw-bold">
          <p className="d-inline-block mb-2">
            <Translate value="common.noRecords" />
          </p>
        </div>
      </div>
    );
  }

  const bindingPeriodIcons = {
    voice: faMobile,
    fiber: faNetworkWired,
    fwa: faNetworkWired,
    x_dsl: faNetworkWired,
    hosting_services: faServer,
  };

  const bindingPeriodContent = bindingPeriods.map((bindingPeriod) => {
    const {
      subscription_type: subscriptionType,
      binding_period_in_months: bindingPeriodInMonths,
    } = bindingPeriod;

    const subscriptionFieldName = camelize(subscriptionType);

    const contentJson = {
      icon: bindingPeriodIcons[subscriptionType],
      title: (
        <Translate
          value={`quotation.bindingPeriod.subscriptionType.${subscriptionType}`}
        />
      ),
      content: (
        <Translate
          value="common.numberOfMonths"
          months={bindingPeriodInMonths}
        />
      ),
      dataCyFieldName: subscriptionFieldName,
      dataCyFieldTitle: subscriptionFieldName,
      resourceName: "subscription-type",
    };

    return contentJson;
  });

  return (
    <CardWithHeader
      className="shadow-sm"
      cardContent={bindingPeriodContent}
      displayHeader={false}
      dataCy={childResourceName}
    />
  );
};

BindingPeriodModalBody.propTypes = {
  bindingPeriods: PropTypes.arrayOf(
    PropTypes.shape({
      subscription_type: PropTypes.string,
      binding_period_in_months: PropTypes.number,
    })
  ).isRequired,
  childResourceName: PropTypes.string.isRequired,
};

const BindingPeriodDetail = ({ bindingPeriods, resourceName }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <div>
      <Modal
        isOpen={isModalVisible}
        centered
        toggle={toggleModal}
        className="modal-lg"
      >
        <ModalHeader className="border-0 pb-0" toggle={toggleModal}>
          <Translate value="common.bindingPeriods" />
        </ModalHeader>

        <ModalBody>
          <BindingPeriodModalBody
            bindingPeriods={bindingPeriods}
            childResourceName="binding-period"
          />
        </ModalBody>
      </Modal>

      <button
        type="button"
        className="btn btn-outline-primary"
        onClick={toggleModal}
        data-cy={`${resourceName}-binding-periods`}
      >
        <Translate value="common.viewDetails" />
      </button>
    </div>
  );
};

BindingPeriodDetail.defaultProps = {
  bindingPeriods: [],
  resourceName: "quotation",
};

BindingPeriodDetail.propTypes = {
  bindingPeriods: PropTypes.arrayOf(
    PropTypes.shape({
      subscription_type: PropTypes.string,
      binding_period_in_months: PropTypes.number,
    })
  ),
  resourceName: PropTypes.string,
};

export default BindingPeriodDetail;
