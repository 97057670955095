import apiUrlConfig from "configs/apiUrlConfig";

import { AuthenticatedRequest } from "internal";

const URLS = {
  INDEX: `${apiUrlConfig.apiEndPoint()}/quotations?filter[status]=:statusType`,
  SHOW: `${apiUrlConfig.apiEndPoint()}/quotations/:id`,
  UPDATE: `${apiUrlConfig.apiEndPoint()}/quotations/:id`,
  CREATE: `${apiUrlConfig.apiEndPoint()}/quotations`,
  DELETE: `${apiUrlConfig.apiEndPoint()}/quotations/:id`,
  PREVIEW_QUOTATION: `${apiUrlConfig.apiEndPoint()}/quotations/:id/download_pdf`,
  SEND_EMAIL: `${apiUrlConfig.apiEndPoint()}/quotations/:id/send_email_to_customer`,
  PREVIEW_TERMS_AND_CONDITIONS: `${apiUrlConfig.apiEndPoint()}/quotations/download_terms_and_conditions?file_name=:fileName`,
  CONVERT_TO_AGREEMENT: `${apiUrlConfig.apiEndPoint()}/quotations/:id/convert_to_agreement`,
};

class QuotationApi extends AuthenticatedRequest {
  /**
   * Fetch all quotations
   *
   * @param {string} status Status of quotations i.e., either `sent` or `draft`
   */
  static fetchAll(status) {
    const fetchAllUrl = URLS.INDEX.replace(":statusType", status);

    return this.get(fetchAllUrl);
  }

  /**
   * Fetch single quotation by its ID
   *
   * @param {int} id ID of a quotation
   */
  static fetchOne(id) {
    const fetchOneUrl = URLS.SHOW.replace(":id", id);

    return this.get(fetchOneUrl);
  }

  /**
   * create a new quotation
   *
   * @param {string} terms_and_conditions terms and conditions associated with the subscriptions
   *
   * @param {object} agreement_attributes attributes of an agreement
   * @param {number} odoo_debtor_id debtor in ODOO for whom the quotation is created for
   * @param {array} subscriptions_attributes attributes of the selected subscriptions
   * @param {string} subscription_type type of a subscription i.e., voip, internet_connections, cell_phone, etc.
   * @param {number} quantity quantity for a selected subscription
   * @param {array} subscription_lines_attributes attributes of the selected subscription lines
   * @param {number} odoo_debtor_id debtor in ODOO for whom the quotation is created for
   * @param {number} price price for a subscription
   * @param {string} line_type (enum) type of a subscription line i.e., subscription, add_on or setup
   * @param {string} product_name name of product for a subscription line
   *
   */
  static create(attributes) {
    const requestBody = {
      quotation: {
        ...attributes,
      },
    };

    return this.post(URLS.CREATE, requestBody);
  }

  /**
   * Update a quotation
   *
   * @param {int} id ID of a quotation
   * @param {string} terms_and_conditions Terms and conditions applied to the quotation
   *
   * @param {object} agreement_attributes
   * @param {int} agreement_attributes.id ID of an agreement
   * @param {number} agreement_attributes.odoo_debtor_id debtor in ODOO for whom quotation/agreement is created for
   * @param {object} agreement_attributes.subscriptions_attributes array of subscriptions for an agreement
   * @param {number} agreement_attributes.subscriptions_attributes[i].subscription_type type of the subscription (is it voip, internet connections, service contracts, etc..)
   * @param {number} agreement_attributes.subscriptions_attributes[i].total_amount total amount for a subscription
   * * @param {object} agreement_attributes.subscriptions_attributes[i].subscription_lines_attributes array of subscription lines for a subscription in an agreement
   * @param {number} agreement_attributes.subscriptions_attributes[i].subscription_lines_attributes[i].odoo_product_id product id of a subscription line
   * @param {number} agreement_attributes.subscriptions_attributes[i].subscription_lines_attributes[i].price price of a subscription line
   * @param {string} agreement_attributes.subscriptions_attributes[i].subscription_lines_attributes[i].line_type (enum) type of a subscription line (is it subscription, add_on or setup?)
   * @param {string} agreement_attributes.subscriptions_attributes[i].subscription_lines_attributes[i].product_name name of product for a subscription line
   * @param {object} agreement_attributes.subscriptions_attributes[i].subscription_lines_attributes[i].subscription_line_properties_attributes array of properties of a subscription line
   * @param {string} agreement_attributes.subscriptions_attributes[i].subscription_lines_attributes[i].subscription_line_properties_attributes[i].property name of a subscription line property
   * @param {string} agreement_attributes.subscriptions_attributes[i].subscription_lines_attributes[i].subscription_line_properties_attributes[i].value value of a property
   *
   */
  static update(id, attributes) {
    const updateUrl = URLS.UPDATE.replace(":id", id);

    const requestBody = {
      quotation: {
        ...attributes,
      },
    };

    return this.patch(updateUrl, requestBody);
  }

  /**
   * Delete a quotation
   *
   * @param {int} id ID of a Quotation
   */
  static deleteOne(id) {
    const deleteUrl = URLS.DELETE.replace(":id", id);

    return this.delete(deleteUrl);
  }

  static previewQuotation(id) {
    const previewQuotationUrl = URLS.PREVIEW_QUOTATION.replace(":id", id);

    return this.get(previewQuotationUrl, false, "blob");
  }

  static sendEmailToCustomer(id) {
    const sendEmailToCustomerUrl = URLS.SEND_EMAIL.replace(":id", id);

    return this.patch(sendEmailToCustomerUrl);
  }

  static previewTermsAndConditions(fileName) {
    const previewTermsAndConditionsUrl =
      URLS.PREVIEW_TERMS_AND_CONDITIONS.replace(":fileName", fileName);

    return this.get(previewTermsAndConditionsUrl, false, "blob");
  }

  /**
   * Convert a quotation to an agreement
   *
   * @param {int} id ID of a quotation
   *
   * @param {object} agreement_attributes
   * @param {int} agreement_attributes.id ID of an agreement
   * @param {number} agreement_attributes.odoo_debtor_id debtor in ODOO for whom quotation/agreement is created for
   * @param {number} agreement_attributes.odoo_payment_term_id ID of the Payment Term in ODOO
   * @param {number} agreement_attributes.invoice_period (enum) Frequency for creating the invoice for this agreement
   * @param {number} agreement_attributes.termination_term (enum) Period after which agreement can be terminated together with any subscription and service inside it
   * @param {number} agreement_attributes.start_date agreement start date
   * @param {number} agreement_attributes.is_billable Enable or disable the invoicing for this agreement
   * @param {number} agreement_attributes.is_voip_billing_enabled Enable or disable the invoicing for of VoIP/PBX related subscriptions on this agreement
   *
   */
  static convertToAgreement(id, attributes) {
    const convertToAgreementUrl = URLS.CONVERT_TO_AGREEMENT.replace(":id", id);

    const requestBody = {
      quotation: {
        ...attributes,
      },
    };

    return this.patch(convertToAgreementUrl, requestBody);
  }
}

export default QuotationApi;
