import React from "react";
import Skeleton from "react-loading-skeleton";

const TableSkeleton = () => (
  <table className="table skeleton-table  table-borderless">
    <thead className="skeleton-table-header">
      <tr>
        <th scope="col">
          <Skeleton height={20} width={155} />
        </th>
        <th scope="col">
          <Skeleton height={20} width={50} />
        </th>
        <th scope="col">
          <Skeleton height={20} width={70} />
        </th>
        <th scope="col">
          <Skeleton height={20} width={120} />
        </th>
        <th scope="col">
          <Skeleton height={20} width={130} />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <Skeleton height={20} width={50} />
        </td>
        <td>
          <Skeleton height={20} width={70} />
        </td>
        <td>
          <Skeleton height={20} width={100} />
        </td>
        <td>
          <Skeleton height={20} width={60} />
        </td>
        <td>
          <Skeleton height={20} width={160} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={20} width={100} />
        </td>
        <td>
          <Skeleton height={20} width={200} />
        </td>
        <td>
          <Skeleton height={20} width={150} />
        </td>
        <td>
          <Skeleton height={20} width={140} />
        </td>
        <td>
          <Skeleton height={20} width={80} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={20} width={150} />
        </td>
        <td>
          <Skeleton height={20} width={170} />
        </td>
        <td>
          <Skeleton height={20} width={100} />
        </td>
        <td>
          <Skeleton height={20} width={90} />
        </td>
        <td>
          <Skeleton height={20} width={140} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={20} width={70} />
        </td>
        <td>
          <Skeleton height={20} width={120} />
        </td>
        <td>
          <Skeleton height={20} width={100} />
        </td>
        <td>
          <Skeleton height={20} width={80} />
        </td>
        <td>
          <Skeleton height={20} width={60} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton height={20} width={90} />
        </td>
        <td>
          <Skeleton height={20} width={200} />
        </td>
        <td>
          <Skeleton height={20} width={140} />
        </td>
        <td>
          <Skeleton height={20} width={70} />
        </td>
        <td>
          <Skeleton height={20} width={160} />
        </td>
      </tr>
    </tbody>
  </table>
);

export default TableSkeleton;
