import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Translate } from "react-i18nify";
import PropTypes from "prop-types";
import { Field, useField } from "react-final-form";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { connect } from "react-redux";

import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";
import SearchableDropdown from "components/ReduxForm/SearchableDropdown/SearchableDropdown";

import {
  clearSimCardErrors,
  searchIMSIRangesRequest,
} from "redux/actions/simCard";

import { required, number, composeValidators } from "utils/validation";

import BasicSimCardForm from "components/SimCard/Form/BasicSimCardForm";

const IMSI_TYPE = {
  START_NUMBER: "startNumber",
  END_NUMBER: "endNumber",
};

const AssignSimCardForm = ({
  submitting,
  pristine,
  isLoading,
  isReseller,
  setReseller,
  setSimCardHolder,
  simCardHolder,
  searchImsiNumbers,
  clearError,
  imsiNumbers,
  isSearching,
}) => {
  const navigate = useNavigate();
  const startNumberRef = useRef(null);
  const {
    input: { value: startIMSINumber },
  } = useField("imsi_number_range_start");

  useEffect(() => () => clearError(), []);

  const resellerClassName = isReseller ? "active" : "";
  const simCardClassName = !isReseller ? "active" : "";

  const handleSimCardHolder = () => {
    setReseller(false);
    setSimCardHolder(null);
  };

  useEffect(() => {
    if (startIMSINumber) {
      startNumberRef.current = startIMSINumber;
    } else {
      startNumberRef.current = false;
    }
  }, [startIMSINumber]);

  const updateSearch = (value, imsiType) => {
    if (imsiType === IMSI_TYPE.START_NUMBER) {
      searchImsiNumbers(value);
    } else if (startNumberRef.current) {
      searchImsiNumbers(value, startNumberRef.current);
    } else {
      searchImsiNumbers(value);
    }
  };

  const handleSearchIMSINumber = (value, imsiType) => {
    updateSearch(value, imsiType);
  };

  const searchResult = imsiNumbers.map(({ imsi_number: imsiNumber }) => ({
    value: imsiNumber,
    label: imsiNumber,
  }));

  return (
    <>
      <div className="pb-4 d-flex flex-column flex-md-row card-holder">
        <FormGroup check className="ps-0">
          <button
            className={`assign-sim-card-type ${resellerClassName} py-2 px-2 d-flex align-items-center`}
            type="button"
            onClick={() => setReseller(true)}
          >
            <Input
              type="checkbox"
              id="reseller"
              onClick={() => setReseller(true)}
              checked={isReseller}
              className="ms-2"
            />
            <Label htmlFor="reseller" className="fw-normal ms-2 mt-1">
              <Translate value="common.reseller" />
            </Label>
          </button>
        </FormGroup>
        <FormGroup check className="py-3 py-md-0 ps-0 ps-md-2">
          <button
            className={`assign-sim-card-type ${simCardClassName} py-2 px-2 ms-md-3 d-flex justify-content-md-center align-items-center`}
            type="button"
            onClick={handleSimCardHolder}
          >
            <Input
              type="checkbox"
              id="simCardHolder"
              onClick={handleSimCardHolder}
              checked={!isReseller}
              className="ms-2"
            />
            <Label htmlFor="simCardHolder" className="fw-normal ms-2 mt-1">
              <Translate value="common.assignToSimCardHolder" />
            </Label>
          </button>
        </FormGroup>
      </div>
      <div className="pbx-content pt-4 col-xl-8 col-xxl-6">
        <BasicSimCardForm
          isReseller={isReseller}
          setSimCardHolder={setSimCardHolder}
          simCardHolder={simCardHolder}
        />
        <div className="my-4">
          <p className="h4 mb-3">
            <Translate value="common.imsiRange" />
          </p>
          <Row>
            <Col md="6">
              <FormGroup>
                <Field
                  name="imsi_number_range_start"
                  component={SearchableDropdown}
                  validate={composeValidators(required, number)}
                  labelTranslation="attributes.startNumber"
                  placeholderTranslation="attributes.startNumber"
                  onChangeCb={(e) =>
                    handleSearchIMSINumber(e, IMSI_TYPE.START_NUMBER)
                  }
                  searchResult={searchResult}
                  isLoading={isSearching}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Field
                  name="imsi_number_range_end"
                  component={SearchableDropdown}
                  validate={composeValidators(required, number)}
                  labelTranslation="attributes.endNumber"
                  placeholderTranslation="attributes.endNumber"
                  onChangeCb={(e) =>
                    handleSearchIMSINumber(e, IMSI_TYPE.END_NUMBER)
                  }
                  isLoading={isSearching}
                  searchResult={searchResult}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </div>
      <div className="flexo-form-footer d-flex flex-column flex-md-row justify-content-between">
        <button
          className="btn btn-outline-secondary mt-4 mb-md-3 px-md-3"
          type="button"
          onClick={() => navigate(-1)}
        >
          <Translate value="common.cancel" />
        </button>

        <ReduxFormCommitButton
          title={<Translate value="common.save" />}
          submitting={submitting}
          pristine={pristine}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

AssignSimCardForm.defaultProps = {
  submitting: false,
  pristine: false,
  isLoading: false,
  isReseller: false,
  setReseller: () => {},
  setSimCardHolder: () => {},
  simCardHolder: "",
  isSearching: false,
};

AssignSimCardForm.propTypes = {
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  isLoading: PropTypes.bool,
  isReseller: PropTypes.bool,
  setReseller: PropTypes.func,
  setSimCardHolder: PropTypes.func,
  simCardHolder: PropTypes.string,
  searchImsiNumbers: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  imsiNumbers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSearching: PropTypes.bool,
};

const mapStateToProps = ({ simCard }) => {
  const { imsiNumbers, isSearching } = simCard;

  return {
    imsiNumbers,
    isSearching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchImsiNumbers: (startNumber, endNumber) => {
    dispatch(searchIMSIRangesRequest(startNumber, endNumber));
  },
  clearError: () => {
    dispatch(clearSimCardErrors());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignSimCardForm);
