/* eslint-disable import/no-cycle */
import {
  DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_REQUEST,
  DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_FAILED,
  DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_RECEIVED,
  DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_CLEAR_ERRORS,
} from "redux/actions/dashboard/cellPhoneImportRequest";

const initialState = {
  cellPhoneImportRequests: [],
  isLoading: false,
  fetchError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: false,
      };

    case DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        fetchError: true,
      };

    case DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_RECEIVED:
      return {
        ...state,
        isLoading: false,
        fetchError: false,
        cellPhoneImportRequests: action.data,
      };

    case DASHBOARD_CELL_PHONE_IMPORT_REQUEST_FETCH_CLEAR_ERRORS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
