import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { t } from "react-i18nify";

const TableHeader = ({
  resourceTitle,
  recordCreateButton,
  hideSearchBar,
  searchRecords,
  fetchError,
  refreshButton,
  resourceName,
}) => (
  <div className="p-2 p-md-4 border-bottom">
    <div className="row">
      <div className="col-lg-8 d-flex align-items-center">
        <h3 className="mb-2 m-lg-0">{resourceTitle}</h3>

        {/* <div className="sm-only">{recordCreateButton}</div> */}
      </div>
      <div className="col-lg-4">
        <div className="d-flex justify-content-end">
          {!hideSearchBar && (
            <div className="search-bar me-2 mb-0">
              <div className="input-group">
                <div className="w-100">
                  {searchRecords ? (
                    <input
                      type="text"
                      placeholder={t("common.search")}
                      className="form-control"
                      onChange={(e) => searchRecords(e.target.value)}
                      disabled={fetchError}
                      data-cy={`search-${resourceName}`}
                    />
                  ) : null}
                  <FontAwesomeIcon icon={faSearch} className="initial-icon" />
                </div>
              </div>
            </div>
          )}
          {recordCreateButton}
          {refreshButton}
        </div>
      </div>
    </div>
  </div>
);

TableHeader.defaultProps = {
  resourceTitle: undefined,
  hideSearchBar: false,
  searchRecords: () => {},
  fetchError: false,
  refreshButton: undefined,
  resourceName: "",
};

TableHeader.propTypes = {
  resourceTitle: PropTypes.string,
  recordCreateButton: PropTypes.instanceOf(Element).isRequired,
  hideSearchBar: PropTypes.bool,
  searchRecords: PropTypes.func,
  fetchError: PropTypes.bool,
  refreshButton: PropTypes.instanceOf(Element),
  resourceName: PropTypes.string,
};

export default TableHeader;
