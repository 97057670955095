import React, { useState } from "react";
import Select from "react-select";
import { FormGroup, Row, Col, Button, Spinner } from "reactstrap";
import { Field } from "react-final-form";
import PropTypes from "prop-types";
import { Translate, t } from "react-i18nify";

import { SUBSCRIPTION_LINE_TYPE } from "constants/subscriptionLine";
import { DEFAULT_SETUP_PRODUCT_NAME } from "constants/cellPhone";

import { getSubscriptionLineType } from "utils/subscription";
import { required } from "utils/validation";
import { formatDropdownOptions } from "utils/utils";

import ContextPropertyApi from "apis/ContextProperty";

import {
  renderInputField,
  renderPriceInputField,
} from "components/ReduxForm/RenderField";
import FormError from "components/Form/FormError";

const SubscriptionProductsField = ({
  input,
  meta,
  productFetchError,
  subscriptionProducts,
  onProductSelect,
  subscriptionProductTranslation,
}) => {
  const productFetchErrorField =
    productFetchError.length === 0 ? null : (
      <div className="text-danger">{productFetchError}</div>
    );

  return (
    <>
      <Select
        id="subscription-products"
        className="pl-0"
        options={formatDropdownOptions(subscriptionProducts)}
        onChange={(e) => {
          input.onChange(e);
          onProductSelect(e);
        }}
        value={[
          {
            label: t("label.dropdown.selectResource", {
              resource: subscriptionProductTranslation,
            }),
          },
        ]}
      />
      <FormError
        error={meta.error}
        touched={meta.touched}
        className="error-message"
      />
      {productFetchErrorField}
    </>
  );
};

const SubscriptionSection = ({
  fields,
  products,
  isProductFetching,
  contextTypeID,
  form,
}) => {
  const [fetchingProperties, setFetchingProperties] = useState(false);
  const [productFetchError, setProductFetchError] = useState("");
  const subscriptionLines = fields.value;

  const addDefaultSetupProduct = () => {
    const defaultProduct = products.find(
      ({ context_type: contextType, name }) =>
        getSubscriptionLineType(contextType) === SUBSCRIPTION_LINE_TYPE.SETUP &&
        name === DEFAULT_SETUP_PRODUCT_NAME
    );

    if (!defaultProduct) return;

    const {
      id,
      name,
      list_price: price,
      context_properties_ids: contextPropertiesIDs,
    } = defaultProduct;

    setFetchingProperties(true);

    ContextPropertyApi.fetchAll(id, contextPropertiesIDs)
      .then(({ data: contextProperties }) => {
        const subscriptionLineProperties = contextProperties.map(
          (contextProperty) => {
            const { property, value: propertyValue } = contextProperty;

            const subscriptionLineProperty = {
              property,
              value: propertyValue,
            };

            return subscriptionLineProperty;
          }
        );

        setFetchingProperties(false);

        fields.push({
          odoo_product_id: id,
          product_name: name,
          price,
          line_type: SUBSCRIPTION_LINE_TYPE.SETUP,
          subscription_line_properties_attributes: subscriptionLineProperties,
        });
      })
      .catch((err) => {
        setFetchingProperties(false);
        setProductFetchError(err.message);
      });
  };

  const handleProductSelect = ({ value }) => {
    const selectedProduct = products.find((product) => product.id === value);
    const {
      id,
      name,
      list_price: price,
      context_properties_ids: contextPropertiesIDs,
    } = selectedProduct;

    setFetchingProperties(true);

    ContextPropertyApi.fetchAll(id, contextPropertiesIDs)
      .then(({ data: contextProperties }) => {
        const subscriptionLineProperties = contextProperties.map(
          (contextProperty) => {
            const { property, value: propertyValue } = contextProperty;

            const subscriptionLineProperty = {
              property,
              value: propertyValue,
            };

            return subscriptionLineProperty;
          }
        );

        setFetchingProperties(false);

        fields.push({
          odoo_product_id: id,
          product_name: name,
          price,
          line_type: SUBSCRIPTION_LINE_TYPE.SUBSCRIPTION,
          subscription_line_properties_attributes: subscriptionLineProperties,
        });
      })
      .catch((err) => {
        setFetchingProperties(false);
        setProductFetchError(err.message);
      });

    addDefaultSetupProduct();
  };

  const handleProductChange = () => {
    form.change("subscription_attributes.subscription_lines_attributes", []);
  };

  if (isProductFetching && !products) {
    return (
      <div>
        <Translate
          value="common.fetchingResource"
          resource={t("attributes.product")}
        />
      </div>
    );
  }

  if (fetchingProperties) {
    return (
      <div className="empty-screen text-center py-5">
        <Spinner as="span" size="md" />
      </div>
    );
  }

  const subscriptionProducts = products.filter(
    (product) =>
      parseInt(product.context, 10) === 2 &&
      parseInt(product.context_type, 10) === contextTypeID
  );

  if (fields.length === 0) {
    const subscriptionProductTranslation = t(
      "title.tab.agreement.subscriptionModal.subscriptionProduct"
    );

    return (
      <Row>
        <Col md={6}>
          <FormGroup>
            <Field
              validate={required}
              name="subscriptionProducts"
              component={SubscriptionProductsField}
              subscriptionProducts={subscriptionProducts}
              subscriptionProductTranslation={subscriptionProductTranslation}
              productFetchError={productFetchError}
              onProductSelect={handleProductSelect}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }

  const mainSubscriptionLineIndex = subscriptionLines.findIndex(
    ({ line_type: lineType }) =>
      lineType === SUBSCRIPTION_LINE_TYPE.SUBSCRIPTION
  );
  const mainSubscriptionLineFieldName = `${fields.name}[${mainSubscriptionLineIndex}]`;

  return (
    <Row>
      <Col md={6}>
        <FormGroup>
          <Field
            name={`${mainSubscriptionLineFieldName}.product_name`}
            component={renderInputField}
            validate={required}
            placeholderTranslation="attributes.product"
            labelTranslation="attributes.product"
          />
        </FormGroup>
      </Col>

      <Col md={6}>
        <FormGroup>
          <Field
            name={`${mainSubscriptionLineFieldName}.price`}
            component={renderPriceInputField}
            validate={required}
            placeholderTranslation="attributes.price"
            labelTranslation="attributes.price"
            type="number"
          />
        </FormGroup>
      </Col>

      <Col md={12}>
        <FormGroup>
          <Button onClick={handleProductChange}>
            <Translate value="pbx.subscription.chooseDifferentProduct" />
          </Button>
        </FormGroup>
      </Col>
    </Row>
  );
};

SubscriptionProductsField.defaultProps = {};

SubscriptionProductsField.propTypes = {
  input: PropTypes.shape({ onChange: PropTypes.func }).isRequired,
  meta: PropTypes.shape({ error: PropTypes.string, touched: PropTypes.bool })
    .isRequired,
  productFetchError: PropTypes.string.isRequired,
  subscriptionProducts: PropTypes.shape([]).isRequired,
  onProductSelect: PropTypes.func.isRequired,
  subscriptionProductTranslation: PropTypes.string.isRequired,
};

SubscriptionSection.defaultProps = {};

SubscriptionSection.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fields: PropTypes.shape({
    length: PropTypes.number,
    name: PropTypes.string,
    push: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.shape),
  }).isRequired,
  isProductFetching: PropTypes.bool.isRequired,
  contextTypeID: PropTypes.number.isRequired,
  form: PropTypes.shape({
    batch: PropTypes.func,
    change: PropTypes.func,
  }).isRequired,
};

export default SubscriptionSection;
