import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Form, FormGroup } from "reactstrap";
import { Form as ReactFinalForm, Field } from "react-final-form";
import { Translate } from "react-i18nify";

import { required } from "utils/validation";

import { AUTH } from "constants/routes";

import PasswordField from "components/ReduxForm/PasswordField/PasswordField";
import LoginSpinner from "components/Login/LoginSpinner";

const SetPasswordForm = ({ onSubmit, isLoading }) => (
  <ReactFinalForm
    onSubmit={onSubmit}
    render={({ pristine, submitting, handleSubmit }) => (
      <Form className="login-form w-100" onSubmit={handleSubmit}>
        <p className="mb-4 text-secondary mb-4">
          <Translate value="auth.setNewPasswordLabel" />
        </p>
        <FormGroup className="mt-4">
          <Field
            type="password"
            name="password"
            inputClassName="form-control-lg"
            placeholderTranslation="auth.passwordPlaceholder"
            component={PasswordField}
            labelTranslation="auth.passwordField"
            validate={required}
          />
        </FormGroup>
        <FormGroup className="mt-4">
          <Field
            type="password"
            name="password_confirmation"
            inputClassName="form-control-lg"
            placeholderTranslation="auth.passwordPlaceholder"
            component={PasswordField}
            labelTranslation="auth.confirmPasswordField"
            validate={required}
          />
        </FormGroup>
        <Link
          to={AUTH.LOGIN}
          className="mt-3 d-flex align-items-end justify-content-start justify-content-sm-end"
        >
          <Translate value="auth.signInInstead" />
        </Link>
        <button
          disabled={pristine || submitting || isLoading}
          type="submit"
          className="btn btn-lg btn-block login-button btn-primary mt-4 mb-3 d-flex justify-content-center align-items-center w-100"
          value="Submit"
        >
          <LoginSpinner isLoading={isLoading} submitting={submitting} />
          <Translate value="auth.setPasswordBtn" />
        </button>
      </Form>
    )}
  />
);

SetPasswordForm.defaultProps = {};

SetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default SetPasswordForm;
