import React from "react";
import PropTypes from "prop-types";
import { Localize } from "react-i18nify";

const TranslateDate = ({ date }) => {
  if (!date) return null;

  return (
    <Localize
      value={new Date(date).toDateString()}
      // comes from locale
      dateFormat="date.long"
    />
  );
};

TranslateDate.defaultProps = {
  date: undefined,
};

TranslateDate.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

export default TranslateDate;
