import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toastr } from "react-redux-toastr";
import { t } from "react-i18nify";

import { loginRequest, loginClearErrors } from "redux/actions/auth";

import LoginPageWrapper from "components/Login/LoginPageWrapper";
import LoginForm from "components/Login/Login";

import { ADMIN_ROLES } from "constants/userRoles";
import { AUTH, DASHBOARD, DEBTOR } from "constants/routes";
import STATUS from "constants/statusCode";

const LoginView = ({
  clearErrors,
  loginError,
  isLoggedIn,
  currentUser,
  isLoading,
  loginUser,
  statusCode,
}) => {
  const location = useLocation();

  useEffect(() => {
    clearErrors();
  }, []);

  const handleSubmit = (values) => {
    const { username, password, keepMeLoggedIn } = values;

    loginUser(username, password, keepMeLoggedIn);
  };

  if (loginError) {
    toastr.error("Error", t(loginError));
  }

  if (statusCode === STATUS.FORBIDDEN) {
    return <Navigate to={AUTH.FORBIDDEN} />;
  }

  if (!isLoggedIn) {
    return (
      <LoginPageWrapper>
        <LoginForm
          onSubmit={handleSubmit}
          loginError={loginError}
          isLoading={isLoading}
        />
      </LoginPageWrapper>
    );
  }

  const { state: routerState } = location;

  const defaultLandingPath = ADMIN_ROLES.includes(currentUser.role)
    ? DASHBOARD.INDEX
    : DEBTOR.addId(DEBTOR.DETAIL, currentUser.odoo_debtor_id);

  const redirectPath = routerState?.from ?? defaultLandingPath;

  return <Navigate from={AUTH.LOGIN} to={redirectPath} />;
};

LoginView.defaultProps = {
  loginError: "",
  isLoggedIn: false,
  currentUser: {},
  statusCode: null,
};

LoginView.propTypes = {
  loginError: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  currentUser: PropTypes.shape({
    role: PropTypes.string,
    odoo_debtor_id: PropTypes.string,
  }),
  loginUser: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  clearErrors: PropTypes.func.isRequired,
  statusCode: PropTypes.number,
};

const mapStateToProps = ({ auth }) => {
  const { isLoggedIn, loginError, isLoading, currentUser, statusCode } = auth;

  return {
    currentUser,
    isLoggedIn,
    loginError,
    isLoading,
    statusCode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginUser: (username, password, keepMeLoggedIn) => {
    dispatch(loginRequest(username, password, keepMeLoggedIn));
  },
  clearErrors: () => {
    dispatch(loginClearErrors());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
