import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";
import { Translate } from "react-i18nify";

import {
  contactFetchRequest,
  searchContacts,
  contactsSearchRequest,
} from "redux/actions/odooEngine/contact";

import { getCurrentUser } from "utils/selectors";
import filterSearchResults from "utils/filterSearchResults";

import HasPermission from "hoc/RbacCTA";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";

const ContactIndex = ({
  parentID,
  debtorID,
  fetchDebtorContacts,
  contacts,
  isLoading,
  className,
  searchRecords,
  isSearching,
  fetchError,
}) => {
  const { debtorID: paramsDebtorID } = useParams();

  const fetchID = parentID || debtorID || paramsDebtorID;
  const filterID = parentID || paramsDebtorID;

  useEffect(() => {
    fetchDebtorContacts(fetchID);
  }, [fetchID]);

  let records;

  if (contacts) {
    records = filterID
      ? contacts.filter(
          (contact) => contact.debtor_id === parseInt(filterID, 10)
        )
      : contacts;
  }

  return (
    <HasPermission
      perform="contacts:list"
      data={{
        debtorID: parseInt(debtorID, 10),
      }}
      redirect
    >
      <RecordListingTable
        records={records}
        isLoading={isLoading}
        className={className}
        resourceName="contacts"
        resourceTitle={
          <Translate value="title.page.resource.list" resource="Contact" />
        }
        searchRecords={searchRecords}
        isSearching={isSearching}
        fetchError={fetchError}
        onRetry={() => fetchDebtorContacts(fetchID)}
        hideAddButton
        disableRowClick
      >
        <Column
          width={220}
          label={<Translate value="attributes.name" />}
          dataKey="name"
        />
        <Column
          width={240}
          label={<Translate value="attributes.email" />}
          dataKey="email"
        />
        <Column
          width={160}
          label={<Translate value="attributes.phone" />}
          dataKey="phone"
        />
        <Column
          width={160}
          label={<Translate value="attributes.mobile" />}
          dataKey="mobile"
        />
        <Column
          width={100}
          label={<Translate value="attributes.type" />}
          dataKey="type"
        />
      </RecordListingTable>
    </HasPermission>
  );
};

ContactIndex.defaultProps = {
  contacts: undefined,
  isLoading: true,
  className: "",
  parentID: undefined,
  debtorID: undefined,
  isSearching: false,
  fetchError: null,
};

ContactIndex.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      mobile: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  fetchDebtorContacts: PropTypes.func.isRequired,
  className: PropTypes.string,
  parentID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  debtorID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  searchRecords: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  fetchError: PropTypes.string,
};

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const { odoo_debtor_id: debtorID } = currentUser || {};
  const { contacts, isLoading, fetchError } = state.contact;
  const searchResult = state.search.contacts;
  const results = filterSearchResults({ resource: contacts, searchResult });

  return {
    contacts: results,
    isLoading,
    debtorID,
    fetchError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchRecords: (e) => {
    dispatch(contactsSearchRequest(e));
    dispatch(searchContacts(e));
  },
  fetchDebtorContacts: (debtorID) => {
    dispatch(contactFetchRequest(debtorID));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactIndex);
