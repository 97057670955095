import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Navigate, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Translate, t } from "react-i18nify";

import { paymentTermFetchRequest } from "redux/actions/paymentTerm";
import { debtorFetchRequest } from "redux/actions/debtor";
import { productFetchRequest } from "redux/actions/product";
import { agreementCreateRequest } from "redux/actions/agreement";

import { formatRequestValues } from "utils/agreement";
import { processServerResponseForAsyncValidationErrors } from "utils/forms";

import { AGREEMENT } from "constants/routes";

import AgreementForm from "components/Agreement/Form/AgreementForm";
import WholePageSpinner from "components/Spinner/WholePageSpinner";

const AgreementCreate = ({
  createAgreement,
  commitSuccess,
  isLoading,
  agreement,
  fetchPaymentTerms,
  fetchDebtors,
  fetchProducts,
  debtors,
  paymentTerms,
  products,
  isDebtorFetching,
  isPaymentTermFetching,
  isProductFetching,
}) => {
  const params = useParams();

  useEffect(() => {
    fetchDebtors();
    fetchPaymentTerms();
    fetchProducts();
  }, []);

  const handleSubmit = async (values, registeredFields) => {
    let serverResponse = {};

    const formattedValues = formatRequestValues(values);

    await createAgreement(formattedValues, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess) {
    const { id } = agreement;
    const agreementTranslation = t("attributes.agreement");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.create", {
        resource: agreementTranslation,
      })
    );

    return <Navigate to={AGREEMENT.addId(AGREEMENT.SHOW, id)} />;
  }

  if (isDebtorFetching || !debtors.length) {
    return <WholePageSpinner />;
  }

  const { debtorID } = params;
  const debtor = debtorID
    ? debtors.find((odooDebtor) => odooDebtor.id === parseInt(debtorID, 10))
    : {};

  const initialDebtorValue = debtorID
    ? {
        odoo_debtor_id: parseInt(debtorID, 10),
        odoo_payment_term_id: debtor.payment_term_id,
      }
    : {};

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate value="title.page.resource.create" resource="Agreement" />
        </h3>
      </div>
      <AgreementForm
        onSubmit={handleSubmit}
        isLoading={isLoading}
        formName="agreementForm"
        formType="create"
        debtors={debtors}
        paymentTerms={paymentTerms}
        products={products}
        initialValues={{
          invoice_period: "quarterly",
          is_billable: true,
          start_date: new Date(),
          is_voip_billing_enabled: true,
          is_cell_phone_billing_enabled: true,
          ...initialDebtorValue,
        }}
        isDebtorFetching={isDebtorFetching}
        isPaymentTermFetching={isPaymentTermFetching}
        isProductFetching={isProductFetching}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { isLoading, agreement, commitSuccess, commitError } = state.agreement;
  const { paymentTerms, isLoading: isPaymentTermFetching } = state.paymentTerm;
  const { debtors, isLoading: isDebtorFetching } = state.debtor;
  const { products, isLoading: isProductFetching } = state.product;

  return {
    isLoading,
    commitSuccess,
    commitError,
    agreement,
    paymentTerms,
    isPaymentTermFetching,
    debtors,
    isDebtorFetching,
    products,
    isProductFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createAgreement: async (attributes, callback) => {
    await dispatch(agreementCreateRequest(attributes, callback));
  },
  fetchPaymentTerms: () => {
    dispatch(paymentTermFetchRequest());
  },
  fetchDebtors: () => {
    dispatch(debtorFetchRequest());
  },
  fetchProducts: () => {
    dispatch(productFetchRequest());
  },
});

AgreementCreate.defaultProps = {
  isDebtorFetching: true,
  isPaymentTermFetching: true,
  isProductFetching: true,
  agreement: undefined,
};

AgreementCreate.propTypes = {
  agreement: PropTypes.shape({
    id: PropTypes.number,
  }),
  isLoading: PropTypes.bool.isRequired,
  commitSuccess: PropTypes.bool.isRequired,
  createAgreement: PropTypes.func.isRequired,
  fetchDebtors: PropTypes.func.isRequired,
  fetchPaymentTerms: PropTypes.func.isRequired,
  fetchProducts: PropTypes.func.isRequired,
  debtors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  paymentTerms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isDebtorFetching: PropTypes.bool,
  isPaymentTermFetching: PropTypes.bool,
  isProductFetching: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgreementCreate);
