import React from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";
import { Translate } from "react-i18nify";

import ProgressBar from "components/ProgressBar/ProgressBar";

const ConsumedUsage = ({ usages }) => (
  <Table borderless responsive className="p-3">
    <tbody>
      {usages.map((usage) => {
        const {
          usage_type: usageType,
          subscribed_usage: subscribedUsage,
          consumed_usage: consumedUsage,
          consumed_percentage: consumedPercentage,
          usage_metric: usageMetric,
        } = usage;

        const formattedSubscribedUsage =
          subscribedUsage === "free" ? (
            <Translate value="common.free" />
          ) : (
            <>
              {`${subscribedUsage} `}{" "}
              <Translate value={`subscriptionUsage.metric.${usageMetric}`} />
            </>
          );

        return (
          <tr>
            <td className="w-25">
              <Translate value={`common.${usageType}`} />
              {` (`}
              {formattedSubscribedUsage})
            </td>
            <td>
              <ProgressBar value={consumedPercentage} />
            </td>
            <td className="w-25 text-right">
              {consumedUsage}{" "}
              <Translate value={`subscriptionUsage.metric.${usageMetric}`} />
            </td>
          </tr>
        );
      })}
    </tbody>
  </Table>
);

ConsumedUsage.defaultProps = {
  usages: [],
};

ConsumedUsage.propTypes = {
  usages: PropTypes.arrayOf(
    PropTypes.shape({
      usage_type: PropTypes.string,
      subscribed_usage: PropTypes.number,
      consumed_usage: PropTypes.number,
      consumed_percentage: PropTypes.number,
      usage_metric: PropTypes.string,
    })
  ),
};

export default ConsumedUsage;
