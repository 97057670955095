import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";
import { Translate, t } from "react-i18nify";
import { useLocation, useNavigate } from "react-router-dom";

import { getParams } from "utils/queryString";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import TranslateDate from "components/i18n/TranslateDate";
import ResourceHeader from "components/ResourceHeader/ResourceHeader";

import { PBX_FILTERS } from "constants/filters";
import { RESOURCE_NAMES } from "constants/constant";

const renderDidNumberTranslateDate = ({ cellData }) => (
  <TranslateDate date={cellData} />
);

const DidNumberIndex = ({
  didNumbers,
  isLoading,
  parentActiveStatus,
  parentID,
  isSearching,
  searchRecords,
}) => {
  const { search, pathname } = useLocation();
  const { filter = "active" } = getParams(search);
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = `?filter=${filter}`;

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath, { replace: true });
  }, [filter]);

  const changeFilter = ({ filter: filterParam }) => {
    const searchParams = `?filter=${filterParam}`;

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath, { replace: true });
  };

  const allFilters = [
    {
      filter: PBX_FILTERS,
      selectedFilter: filter,
      type: "filter",
      headerTranslation: "common.filters",
    },
  ];

  return (
    <RecordListingTable
      records={didNumbers}
      isLoading={isLoading}
      hideAddButton={!parentActiveStatus}
      resourceName="did_numbers"
      resourceTitle={t("title.page.resource.list", {
        resource: t("title.tab.userLicense.didNumbers"),
      })}
      additionalHeaderContent={
        <ResourceHeader changeFilter={changeFilter} allFilters={allFilters} />
      }
      parentID={parentID}
      isSearching={isSearching}
      searchRecords={searchRecords}
      dataCy={`create-${RESOURCE_NAMES.USER_LICENSE}-did_numbers`}
    >
      <Column
        width={180}
        label={<Translate value="attributes.number" />}
        dataKey="phone_number"
      />
      <Column
        width={200}
        label={<Translate value="attributes.invoiceStartDate" />}
        dataKey="invoice_start_date"
        cellRenderer={renderDidNumberTranslateDate}
      />
      <Column
        width={210}
        label={<Translate value="attributes.deploymentDate" />}
        dataKey="deployment_date"
        cellRenderer={renderDidNumberTranslateDate}
      />
    </RecordListingTable>
  );
};

DidNumberIndex.defaultProps = {
  isLoading: true,
  didNumbers: undefined,
  parentID: undefined,
  isSearching: false,
};

DidNumberIndex.propTypes = {
  didNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      cell_number: PropTypes.number,
      invoice_start_date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]),
      deployment_date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]),
      is_default: PropTypes.bool,
    })
  ),
  isLoading: PropTypes.bool,
  parentID: PropTypes.string,
  searchRecords: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  parentActiveStatus: PropTypes.bool.isRequired,
};

export default DidNumberIndex;
