import React from "react";
import { Translate } from "react-i18nify";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FORBIDDEN_BANNER } from "constants/images";

const NavigationButton = ({ hideNavigation, handleNavigation }) => {
  if (hideNavigation) return null;

  return (
    <Button
      color="primary"
      className="btn btn-primary-color mt-2"
      onClick={handleNavigation}
    >
      <FontAwesomeIcon icon={faLeftLong} className="me-2" />
      <Translate value="forbidden.returnToLoginPage" />
    </Button>
  );
};

const ForbiddenContent = ({ hideNavigation, handleNavigation }) => (
  <section className="forbidden d-flex flex-column justify-content-center align-items-center h-100 w-100">
    <img src={FORBIDDEN_BANNER.SOURCE} alt="forbidden page" className="me-5" />
    <h3 className="mt-2 pt-2 mb-0 fw-bold">
      <Translate value="forbidden.accessRestricted" />
    </h3>
    <p className="description my-3">
      <Translate value="forbidden.youAreNotAllowed" />
    </p>
    <NavigationButton
      hideNavigation={hideNavigation}
      handleNavigation={handleNavigation}
    />
  </section>
);

ForbiddenContent.defaultProps = {
  hideNavigation: false,
  handleNavigation: () => {},
};

ForbiddenContent.propTypes = {
  hideNavigation: PropTypes.bool,

  handleNavigation: PropTypes.func,
};

NavigationButton.defaultProps = {
  hideNavigation: false,
  handleNavigation: () => {},
};

NavigationButton.propTypes = {
  hideNavigation: PropTypes.bool,
  handleNavigation: PropTypes.func,
};

export default ForbiddenContent;
