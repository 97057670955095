import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import DeleteModal from "components/Modal/DeleteModal";
import FetchError from "components/Error/FetchError";
import DetailUIHeader from "components/Header/DetailUI/DetailUIHeader";
import PhoneNumberBasicDetail from "components/PhoneNumber/Detail/PhoneNumberBasicDetail";
import PhoneNumberDetailSkeleton from "components/Skeleton/ResourceDetail/PhoneNumberDetail/PhoneNumberDetail";

import {
  phoneNumberDeleteRequest,
  phoneNumberDetailRequest,
} from "redux/actions/phoneNumber";

import { PHONE_NUMBER } from "constants/routes";

const PhoneNumberDetail = ({
  phoneNumber,
  fetchError,
  fetchPhoneNumberDetail,
  isLoading,
  deletePhoneNumber,
}) => {
  const navigate = useNavigate();
  const { phoneNumberID } = useParams();

  const [modalData, setModalData] = useState();
  const [modalVisible, setModalVisibility] = useState(false);

  const fetchPhoneNumberData = () => {
    fetchPhoneNumberDetail(phoneNumberID);
  };

  const resetModalState = () => {
    setModalVisibility(false);
    setModalData();
  };

  const toggle = () => {
    setModalData(phoneNumberID);
    setModalVisibility(!modalVisible);
  };

  const onSuccess = () => {
    navigate(PHONE_NUMBER.INDEX);
  };

  const onConfirmDelete = (id) => {
    deletePhoneNumber(id, onSuccess);
    resetModalState();
  };

  useEffect(() => {
    fetchPhoneNumberData();
  }, [phoneNumberID]);

  const isAvailable = fetchPhoneNumberDetail.status !== "available";

  if (fetchError) {
    return <FetchError onRetry={fetchPhoneNumberData} />;
  }

  if (isLoading || phoneNumber?.id !== parseInt(phoneNumberID, 10)) {
    return <PhoneNumberDetailSkeleton />;
  }

  return (
    <div className="phone-number-detail">
      <DeleteModal
        modalVisible={modalVisible}
        modalData={modalData}
        resourceName="phone number"
        onConfirm={onConfirmDelete}
        onCancel={resetModalState}
      />
      <DetailUIHeader
        headerTitle={phoneNumber.phone_number}
        perform="phoneNumber:edit"
        editPath={PHONE_NUMBER.addId(PHONE_NUMBER.EDIT, phoneNumberID)}
        readOnly={false}
        handleDelete={toggle}
        hasDelete
        hasDeleteTooltip={!isAvailable}
        deleteTooltipText="phoneNumber.onlyAvailableNumbersCanBeDeleted"
      />
      <PhoneNumberBasicDetail phoneNumber={phoneNumber} />
    </div>
  );
};

PhoneNumberDetail.defaultProps = {
  fetchError: null,
  isLoading: false,
};

PhoneNumberDetail.propTypes = {
  fetchError: PropTypes.string,
  isLoading: PropTypes.bool,
  fetchPhoneNumberDetail: PropTypes.func.isRequired,
  phoneNumber: PropTypes.shape({
    id: PropTypes.number,
    phone_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  deletePhoneNumber: PropTypes.func.isRequired,
};

const mapStateToProps = ({ phoneNumber: phoneNumberState }) => {
  const { phoneNumber, fetchError, isLoading } = phoneNumberState;

  return {
    phoneNumber,
    fetchError,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPhoneNumberDetail: (id) => {
    dispatch(phoneNumberDetailRequest(id));
  },
  deletePhoneNumber: (id, cb) => {
    dispatch(phoneNumberDeleteRequest(id, cb));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberDetail);
