import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, FormGroup } from "reactstrap";
import { Translate } from "react-i18nify";
import { Field, Form } from "react-final-form";

import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";
import { renderInputField } from "components/ReduxForm/RenderField";

import { required } from "utils/validation";

const SuspendedError = ({ error }) => {
  if (!error) return null;

  return <p className="text-danger mb-0 py-2">{error}</p>;
};

const CellPhoneSuspendModal = ({
  modalVisible,
  onCancel,
  isLoading,
  onSubmit,
  cellNumber,
  error,
}) => {
  if (!modalVisible) return null;

  return (
    <Modal isOpen={modalVisible} toggle={onCancel}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, pristine }) => (
          <form
            className="flexo-form w-100 px-4 py-3 cell-phone-suspended h4"
            data-testid="flexo-form"
            onSubmit={handleSubmit}
          >
            <ModalBody className="p-0">
              <p className="text-black pt-2 mb-3 fw-bold">
                <Translate value="cellPhone.reasonForSuspending" /> {cellNumber}
              </p>
              <SuspendedError error={error} />
              <FormGroup>
                <Field
                  name="cell_phone.suspended_reason"
                  type="textarea"
                  component={renderInputField}
                  validate={required}
                  placeholderTranslation="attributes.description"
                />
              </FormGroup>
            </ModalBody>

            <ModalFooter className="border-0 p-0 justify-content-sm-between flex-column flex-sm-row">
              <button
                className="btn btn-outline-secondary px-4 modal-button"
                type="button"
                onClick={onCancel}
              >
                <Translate value="common.cancel" />
              </button>
              <ReduxFormCommitButton
                title={<Translate value="common.suspend" />}
                submitting={submitting}
                pristine={pristine}
                isLoading={isLoading}
                submitClassName="btn-danger text-white px-5 modal-button"
              />
            </ModalFooter>
          </form>
        )}
      </Form>
    </Modal>
  );
};

SuspendedError.propTypes = {
  error: PropTypes.string.isRequired,
};

CellPhoneSuspendModal.defaultProps = {
  isLoading: false,
  error: "",
};

CellPhoneSuspendModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  cellNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  error: PropTypes.string,
};

export default CellPhoneSuspendModal;
