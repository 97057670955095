import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";
import {
  faMapMarkerAlt,
  faClock,
  faFileAlt,
  faCircle,
  faBug,
  faUndo,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";

import { singleFaxStatusFetchRequest } from "redux/actions/pbxEngine/faxStatus";

import WholePageSpinner from "components/Spinner/WholePageSpinner";
import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import TranslateDate from "components/i18n/TranslateDate";

const FaxStatusDetail = ({ faxStatus, fetchFaxStatus, isLoading }) => {
  const { search } = useLocation();
  const { faxStatusID } = useParams();

  const queryParams = new URLSearchParams(search);
  const didNumber = queryParams.get("did_number");

  useEffect(() => {
    fetchFaxStatus(faxStatusID, didNumber);
  }, []);

  if (!faxStatus || isLoading) {
    return <WholePageSpinner />;
  }

  const {
    source_number: sourceNumber,
    destination_number: destinationNumber,
    direction,
    sent_date: sentDate,
    status,
    error,
    number_of_pages: numberOfPages,
    retry_count: retryCount,
  } = faxStatus;

  const errorContent = error && [
    {
      icon: faBug,
      title: <Translate value="attributes.error" />,
      content: error,
    },
    {
      icon: faUndo,
      title: <Translate value="attributes.retryCount" />,
      content: retryCount,
    },
  ];

  const basicDetailContent = [
    {
      icon: faMapMarkerAlt,
      title: <Translate value="attributes.destinationNumber" />,
      content: destinationNumber,
    },
    {
      icon: faClock,
      title: <Translate value="attributes.sentDate" />,
      content: <TranslateDate date={sentDate} />,
    },
    {
      icon: faExchangeAlt,
      title: <Translate value="attributes.direction" />,
      content: direction,
    },
    {
      icon: faFileAlt,
      title: <Translate value="attributes.numberOfPages" />,
      content: numberOfPages,
    },
    {
      icon: faCircle,
      title: <Translate value="attributes.status" />,
      content: status,
    },
    ...errorContent,
  ];

  return (
    <div className="container-fluid faxStatus-detail">
      <h3 className="m-0">{sourceNumber}</h3>

      <CardWithHeader
        title={<Translate value="common.basicDetails" />}
        className="shadow-sm"
        cardContent={basicDetailContent}
      />
    </div>
  );
};

FaxStatusDetail.defaultProps = {
  isLoading: true,
};

FaxStatusDetail.propTypes = {
  fetchFaxStatus: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  faxStatus: PropTypes.shape({
    source_number: PropTypes.string,
    destination_number: PropTypes.string,
    direction: PropTypes.string,
    sent_date: PropTypes.string,
    status: PropTypes.string,
    error: PropTypes.string,
    number_of_pages: PropTypes.number,
    retry_count: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const { faxStatusFetchError, faxStatus, isLoading } = state.faxStatus;

  return {
    faxStatusFetchError,
    faxStatus,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchFaxStatus: (id, didNumber) => {
    dispatch(singleFaxStatusFetchRequest(id, didNumber));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FaxStatusDetail);
