import React, { useState } from "react";
import PropTypes from "prop-types";
import { useField, useForm } from "react-final-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-i18nify";
import { FieldArray } from "react-final-form-arrays";

import {
  SUBSCRIPTION_LINE_PROPERTIES,
  SUBSCRIPTION_LINE_TYPE,
} from "constants/subscriptionLine";

import {
  calculateSubscriptionMonthlyPrice,
  calculateSubscriptionSetupPrice,
  calculateTotalAmount,
} from "utils/quotation";

import TranslateNumber from "components/i18n/TranslateNumber";
import SubscriptionLinesModal from "components/Quotation/Form/ConvertToAgreement/Modal/SubscriptionLinesModal";

const PropertiesFieldContent = ({
  mainSubscriptionLine,
  subscriptionLineProperties,
  fieldName,
}) => {
  if (Object.keys(mainSubscriptionLine).length > 0) {
    return subscriptionLineProperties.map(({ property, value }, index) => {
      const linePropertyValue = value || <Translate value="common.noValue" />;

      if (property === SUBSCRIPTION_LINE_PROPERTIES.TOPIC) {
        return null;
      }

      return (
        <p
          className="text-start mb-0"
          key={`${index}-${property}`}
          data-field-name={`${fieldName}.properties`}
        >
          {`${property}: `}
          {linePropertyValue}
        </p>
      );
    });
  }

  return (
    <p className="text-start mb-0" data-field-name={`${fieldName}.properties`}>
      {" "}
      <Translate value="message.quotation.convertToAgreement.noProperties" />
    </p>
  );
};

const SubscriptionListTableRow = ({ fieldName, fields, currentIndex }) => {
  const [modalVisible, setModalVisibility] = useState(false);

  const form = useForm();

  const subscriptionField = useField(fieldName);
  const {
    input: { value: subscription },
    meta: { invalid: isUpdatedSubscriptionInvalid },
  } = subscriptionField;

  const {
    subscription_lines: subscriptionLines,
    setup_price: setupPrice,
    monthly_price: monthlyPrice,
  } = subscription;

  const {
    input: { value: subscriptionInitialValue },
  } = useField("agreement.subscription_currently_on_edit");
  const {
    input: {
      value: {
        all_total_setup_price: allTotalSetupPrice,
        all_total_monthly_price: allTotalMonthlyPrice,
      },
    },
  } = useField("agreement");

  const toggleModalVisibility = () => {
    setModalVisibility((prevState) => !prevState);
  };

  const handleSubscriptionEditEffectForQuotationAmountAttributes = (
    updatedTotalSetupPrice,
    updatedTotalMonthlyPrice
  ) => {
    const {
      total_setup_price: totalSetupPriceToDeduct,
      total_monthly_price: totalMonthlyPriceToDeduct,
    } = subscriptionInitialValue;
    const updatedAllTotalSetupPrice =
      allTotalSetupPrice - totalSetupPriceToDeduct + updatedTotalSetupPrice;
    const updatedAllTotalMonthlyPrice =
      allTotalMonthlyPrice -
      totalMonthlyPriceToDeduct +
      updatedTotalMonthlyPrice;
    const totalQuotationAmount = calculateTotalAmount(
      updatedAllTotalSetupPrice,
      updatedAllTotalMonthlyPrice
    );

    form.batch(() => {
      form.change("agreement.all_total_setup_price", updatedAllTotalSetupPrice);
      form.change(
        "agreement.all_total_monthly_price",
        updatedAllTotalMonthlyPrice
      );
      form.change("agreement.total_amount", totalQuotationAmount);
    });
  };

  const updateSubscriptionAmountAttributes = () => {
    const { quantity } = subscription;

    const activeSubscriptionLines = subscriptionLines.filter(
      ({ _destroy: isDeleted }) => !isDeleted
    );

    const updatedMonthlyPrice = calculateSubscriptionMonthlyPrice(
      activeSubscriptionLines
    );
    const updatedSetupPrice = calculateSubscriptionSetupPrice(
      activeSubscriptionLines
    );
    const updatedTotalMonthlyPrice = quantity * updatedMonthlyPrice;
    const updatedTotalSetupPrice = quantity * updatedSetupPrice;

    const subscriptionAttributes = {
      ...subscription,
      monthly_price: updatedMonthlyPrice,
      setup_price: updatedSetupPrice,
      total_monthly_price: updatedTotalMonthlyPrice,
      total_setup_price: updatedTotalSetupPrice,
      total_amount: calculateTotalAmount(setupPrice, monthlyPrice),
    };

    fields.update(currentIndex, subscriptionAttributes);

    handleSubscriptionEditEffectForQuotationAmountAttributes(
      updatedTotalSetupPrice,
      updatedTotalMonthlyPrice
    );
  };

  const updateSubscription = () => {
    if (isUpdatedSubscriptionInvalid) {
      return;
    }

    updateSubscriptionAmountAttributes();

    toggleModalVisibility();
  };

  const resetSubscriptionUpdate = () => {
    toggleModalVisibility();

    if (subscriptionField.meta.dirty) {
      fields.update(currentIndex, subscriptionInitialValue);
    }
  };

  const handleSubscriptionEditButtonClick = () => {
    toggleModalVisibility();

    form.change("agreement.subscription_currently_on_edit", subscription);
  };

  if (subscriptionLines.length === 0) {
    return null;
  }

  const mainSubscriptionLine = subscriptionLines.find(
    ({ line_type: lineType }) =>
      lineType === SUBSCRIPTION_LINE_TYPE.SUBSCRIPTION
  );

  const {
    product_name: mainSubscriptionLineProduct,
    subscription_line_properties: subscriptionLineProperties,
  } = mainSubscriptionLine;

  return (
    <>
      <tr className="subscription-listing-table-row py-3">
        <td className="align-middle text-center">
          <p className="mb-0" data-field-name={`${fieldName}.product_name`}>
            {mainSubscriptionLineProduct}
          </p>
        </td>
        <td className="align-middle text-center">
          <PropertiesFieldContent
            mainSubscriptionLine={mainSubscriptionLine}
            subscriptionLineProperties={subscriptionLineProperties}
            fieldName={fieldName}
          />
        </td>
        <td className="align-middle text-center">
          <p className="mb-0" data-field-name={`${fieldName}.setup_price`}>
            <TranslateNumber value={setupPrice} minimumFractionDigits={2} />
          </p>
        </td>
        <td className="align-middle text-center">
          <p className="mb-0" data-field-name={`${fieldName}.monthly_price`}>
            <TranslateNumber value={monthlyPrice} minimumFractionDigits={2} />
          </p>
        </td>

        <td className="d-flex justify-content-evenly align-middle my-1 py-4">
          <FieldArray
            name={`${fieldName}.subscription_lines`}
            component={SubscriptionLinesModal}
            title={<Translate value="common.editSubscription" />}
            subscriptionTitle={<Translate value="common.mainSubscription" />}
            buttonTitle={<Translate value="common.updateSubscription" />}
            modalVisible={modalVisible}
            onSubmit={updateSubscription}
            onCancel={resetSubscriptionUpdate}
            subscriptionField={subscriptionField}
            dataModalButton="update-subscription-btn"
          />
          <button
            className="border border-success rounded p-2 text-success d-flex align-self-center bg-transparent"
            type="button"
            onClick={handleSubscriptionEditButtonClick}
            data-field-name={`${fieldName}.edit-btn`}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </button>
        </td>
      </tr>
    </>
  );
};

PropertiesFieldContent.defaultProps = {};

PropertiesFieldContent.propTypes = {
  mainSubscriptionLine: PropTypes.shape({}).isRequired,
  subscriptionLineProperties: PropTypes.arrayOf(
    PropTypes.shape({
      property: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  fieldName: PropTypes.string.isRequired,
};

SubscriptionListTableRow.defaultProps = {};

SubscriptionListTableRow.propTypes = {
  fieldName: PropTypes.string.isRequired,
  currentIndex: PropTypes.number.isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func,
    push: PropTypes.func,
    length: PropTypes.number,
    update: PropTypes.func,
    remove: PropTypes.func,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        subscriptionProducts: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.number,
        }),
        subscription_attributes: PropTypes.shape({
          subscription_lines_attributes: PropTypes.shape({
            line_type: PropTypes.string,
            odoo_product_id: PropTypes.number,
            price: PropTypes.number,
            product_name: PropTypes.string,
            quantity: PropTypes.number,
            subscription_lines_properties_attributes: PropTypes.arrayOf(
              PropTypes.shape({
                property: PropTypes.string,
                value: PropTypes.string,
              })
            ),
            setup_subscription_line: PropTypes.shape({
              context_type: PropTypes.number,
              line_type: PropTypes.string,
              odoo_product_id: PropTypes.number,
              price: PropTypes.number,
              product_name: PropTypes.string,
              subscription_lines_properties_attributes: PropTypes.arrayOf(
                PropTypes.shape({
                  property: PropTypes.string,
                  value: PropTypes.string,
                })
              ),
            }),
          }),
        }),
      })
    ),
  }).isRequired,
};

export default SubscriptionListTableRow;
