import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

import { replaceMultiple } from "utils/string";

const URLS = {
  INDEX: `${apiUrlConfig.apiEndPoint()}/users?filter[role_type]=:roleType&filter[status]=:status`,
  CREATE: `${apiUrlConfig.apiEndPoint()}/users`,
  UPDATE: `${apiUrlConfig.apiEndPoint()}/users/:id`,
  SHOW: `${apiUrlConfig.apiEndPoint()}/users/:id`,
  DELETE: `${apiUrlConfig.apiEndPoint()}/users/:id`,
  UPDATE_LANGUAGE: `${apiUrlConfig.apiEndPoint()}/users/:id/switch_locale?locale=:language`,
};

const languageType = {
  "en-US": "en",
  "da-DK": "da",
};

class UserApi extends AuthenticatedRequest {
  /**
   * Creates a new user in the system
   *
   * @param {string} first_name first name of a user
   * @param {string} last_name last name of a user
   * @param {string} email email of a user
   * @param {string} phone phone number of a user
   * @param {string} password password for logging in
   *
   * @param {number} business_id business id of a business user belongs to
   * @param {string} odoo_debtor_id debtor for whom user is being created for
   * @param {string} role_id role of a user
   *
   */
  static create({
    first_name: firstName,
    last_name: lastName,
    email,
    phone,
    password,
    user_license_id: userLicenseID,
    business_id: businessID,
    odoo_debtor_id: odooDebtorID,
    role_id: roleID,
    locale,
  }) {
    const userAttributes = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      password,
      password_confirmation: password,
      user_license_id: userLicenseID,
      locale: languageType[locale],
      user_roles_attributes: [
        {
          odoo_debtor_id: odooDebtorID,
          business_id: businessID,
          role_id: roleID,
        },
      ],
    };

    const requestBody = {
      user: userAttributes,
    };

    return this.post(URLS.CREATE, requestBody);
  }

  /**
   * Update a user in the system
   *
   * @param {int} id ID of a user
   *
   * @param {string} first_name first name of a user
   * @param {string} last_name last name of a user
   * @param {string} email email of a user
   * @param {string} phone phone number of a user
   * @param {string} password password for logging in
   * @param {number} business_id business id of a business user belongs to
   * @param {string} odoo_debtor_id debtor for whom user is being created for
   * @param {string} role_id role of a user
   * @param {string} user_role_id id of a user role
   *
   */
  static update(
    id,
    {
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      password,
      user_license_id: userLicenseID,
      business_id: businessID,
      odoo_debtor_id: odooDebtorID,
      role_id: roleID,
      user_role_id: userRoleID,
      locale,
    }
  ) {
    const userAttributes = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      password,
      password_confirmation: password,
      user_license_id: userLicenseID,
      locale: languageType[locale],
      user_roles_attributes: [
        {
          id: userRoleID,
          odoo_debtor_id: odooDebtorID,
          business_id: businessID,
          role_id: roleID,
        },
      ],
    };
    const updateUrl = URLS.UPDATE.replace(":id", id);

    const requestBody = {
      user: userAttributes,
    };

    return this.patch(updateUrl, requestBody);
  }

  /**
   * Fetch all users in the system
   * @param {string} status status of user, one of ['active', 'deactivated']
   * @param {string} roleType role type to fetch user
   * Explicit to super admins only
   *
   */
  static fetchAll(status, roleType) {
    const replaceWith = {
      ":status": status,
      ":roleType": roleType,
    };

    return this.get(replaceMultiple(URLS.INDEX, replaceWith));
  }

  /**
   * Fetch single user
   *
   * @param {int} id ID of a user to fetch
   */
  static fetchOne(id) {
    const fetchOneUrl = URLS.SHOW.replace(":id", id);

    return this.get(fetchOneUrl);
  }

  static deleteUser(id) {
    const deleteUrl = URLS.DELETE.replace(":id", id);

    return this.delete(deleteUrl);
  }

  static updateLanguage(id, language) {
    const replaceWith = {
      ":language": language,
      ":id": id,
    };

    return this.patch(replaceMultiple(URLS.UPDATE_LANGUAGE, replaceWith));
  }
}

export default UserApi;
