import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";
import { Translate } from "react-i18nify";
import { useLocation, useNavigate } from "react-router-dom";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import ResourceHeader from "components/ResourceHeader/ResourceHeader";

import {
  FILTER_COST_TYPE,
  FILTER_PHONE_NUMBER_TYPE,
  FILTER_STATUS,
} from "constants/filters";

import { getParams } from "utils/queryString";
import filterSearchResults from "utils/filterSearchResults";
import addParams from "utils/addParams";

import {
  phoneNumberRequest,
  phoneNumberSearchRequest,
  searchPhoneNumber,
} from "redux/actions/phoneNumber";

const PhoneNumberIndex = ({
  phoneNumbers,
  isLoading,
  isSearching,
  fetchError,
  fetchAllPhoneNumbers,
  searchRecords,
}) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const {
    status = "available",
    number_type: numberType = "cell_phone",
    cost_type: costType = "free",
  } = getParams(search);

  const allFilters = [
    {
      filter: FILTER_PHONE_NUMBER_TYPE,
      selectedFilter: numberType,
      type: "number",
      headerTranslation: "phoneNumber.numberType",
    },
    {
      filter: FILTER_STATUS,
      selectedFilter: status,
      type: "statusType",
      headerTranslation: "user.status",
    },
    {
      filter: FILTER_COST_TYPE,
      selectedFilter: costType,
      type: "cost",
      headerTranslation: "phoneNumber.costType",
    },
  ];

  useEffect(() => {
    fetchAllPhoneNumbers(numberType, status, costType);
  }, [status, numberType, costType]);

  useEffect(() => {
    const paramsList = {
      status,
      number_type: numberType,
      cost_type: costType,
    };

    const searchParams = addParams(paramsList);

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath);
  }, [status, numberType, costType]);

  const changeFilter = ({ statusType, cost, number }) => {
    const paramsList = {
      status: statusType,
      number_type: number,
      cost_type: cost,
    };

    const searchParams = addParams(paramsList);

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath);
  };

  return (
    <RecordListingTable
      records={phoneNumbers}
      isLoading={isLoading && !fetchError}
      fetchError={fetchError}
      onRetry={() => fetchAllPhoneNumbers(numberType, status, costType)}
      resourceName="phone_numbers"
      additionalHeaderContent={
        <ResourceHeader changeFilter={changeFilter} allFilters={allFilters} />
      }
      resourceTitle={
        <Translate value="title.page.resource.list" resource="Phone Number" />
      }
      searchRecords={searchRecords}
      isSearching={isSearching}
      dataCy="add-btn"
    >
      <Column
        width={380}
        label={<Translate value="attributes.Number" />}
        dataKey="phone_number"
      />
      <Column
        width={180}
        label={<Translate value="attributes.Owner" />}
        dataKey="service_owner_name"
      />
    </RecordListingTable>
  );
};

PhoneNumberIndex.defaultProps = {
  isLoading: true,
  phoneNumbers: [],
  isSearching: false,
  fetchError: null,
};

PhoneNumberIndex.propTypes = {
  isLoading: PropTypes.bool,
  fetchAllPhoneNumbers: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  fetchError: PropTypes.string,
  searchRecords: PropTypes.func.isRequired,
  phoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      phone_number: PropTypes.string,
      service_provider: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
};

const mapStateToProps = (state) => {
  const { phoneNumbers, isLoading, isSearching, fetchError } =
    state.phoneNumber;

  const results = filterSearchResults({
    resource: phoneNumbers,
    searchResult: state.search.phoneNumbers,
  });

  return {
    phoneNumbers: results,
    isLoading,
    isSearching,
    fetchError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchRecords: (e) => {
    dispatch(phoneNumberSearchRequest(e));
    dispatch(searchPhoneNumber(e));
  },
  fetchAllPhoneNumbers: (numberType, status, costType) => {
    dispatch(phoneNumberRequest(numberType, status, costType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberIndex);
