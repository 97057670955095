import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { toastr } from "react-redux-toastr";
import { Translate, t } from "react-i18nify";

import { debtorFetchRequest } from "redux/actions/debtor";
import { contactTelecomUserEmailsFetchRequest } from "redux/actions/odooEngine/contact";
import { numberPortingRequestCreateRequest } from "redux/actions/numberPortingRequest";
import { pbxFetchRequest } from "redux/actions/pbx";

import { NUMBER_PORTING_REQUEST } from "constants/routes";

import ImportForm from "components/NumberPortingRequest/Import/Form/ImportForm";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const ImportCreate = ({
  numberPortingRequest,
  createNumberPortingRequest,
  debtors,
  isLoading,
  commitSuccess,
  commitError,
  fetchDebtors,
  isDebtorsLoading,
  fetchTelecomUserEmails,
  isTelecomUserEmailsLoading,
  telecomUserEmails,
  fetchPbxes,
  pbxes,
  isPbxesLoading,
}) => {
  useEffect(() => {
    fetchDebtors();
    fetchTelecomUserEmails();
    fetchPbxes();
  }, []);

  const todayAt7AM = () => {
    const timeNow = new Date();

    timeNow.setHours(7);
    timeNow.setMinutes(0);
    timeNow.setMilliseconds(0);

    return timeNow;
  };

  if (commitSuccess && !commitError) {
    const { ref_number: refNumber } = numberPortingRequest;
    const featureNameTranslation = t("attributes.numberPortingRequest");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.create", {
        resource: featureNameTranslation,
      })
    );

    return (
      <Navigate
        to={`${NUMBER_PORTING_REQUEST.addRefNumber(
          NUMBER_PORTING_REQUEST.IMPORT_DETAIL,
          refNumber
        )}`}
      />
    );
  }

  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    await createNumberPortingRequest(values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.create"
            resource="Number Porting Request"
          />
        </h3>
      </div>
      <ImportForm
        commitError={commitError}
        debtors={debtors}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        isDebtorsLoading={isDebtorsLoading}
        initialValues={{
          number_type: "cell_phone",
          porting_type: "import",
          requested_porting_date: todayAt7AM(),
        }}
        formAction="create"
        telecomUserEmails={telecomUserEmails}
        isTelecomUserEmailsLoading={isTelecomUserEmailsLoading}
        pbxes={pbxes}
        isPbxesLoading={isPbxesLoading}
      />
    </div>
  );
};

ImportCreate.defaultProps = {
  debtors: [],
  isLoading: false,
  numberPortingRequest: undefined,
  commitError: {},
  isDebtorsLoading: false,
  telecomUserEmails: [],
  pbxes: [],
  isPbxesLoading: false,
};

ImportCreate.propTypes = {
  fetchDebtors: PropTypes.func.isRequired,
  createNumberPortingRequest: PropTypes.func.isRequired,
  debtors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.integer,
      name: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  commitError: PropTypes.shape({}),
  commitSuccess: PropTypes.bool.isRequired,
  numberPortingRequest: PropTypes.shape({
    id: PropTypes.number,
    ref_number: PropTypes.string,
  }),
  isDebtorsLoading: PropTypes.bool,
  fetchTelecomUserEmails: PropTypes.func.isRequired,
  isTelecomUserEmailsLoading: PropTypes.bool.isRequired,
  telecomUserEmails: PropTypes.arrayOf(PropTypes.shape),
  fetchPbxes: PropTypes.func.isRequired,
  pbxes: PropTypes.shape({}),
  isPbxesLoading: PropTypes.bool,
};

function mapStateToProps({
  debtor,
  numberPortingRequest: numberPortingRequestReducer,
  contact,
  pbx,
}) {
  const { isLoading, commitSuccess, numberPortingRequest, commitError } =
    numberPortingRequestReducer;
  const { debtors, isLoading: isDebtorsLoading } = debtor;
  const { telecomUserEmails, isLoading: isTelecomUserEmailsLoading } = contact;
  const { pbxes, isLoading: isPbxesLoading } = pbx;

  return {
    debtors,
    isDebtorsLoading,
    isLoading,
    commitSuccess,
    numberPortingRequest,
    commitError,
    telecomUserEmails,
    isTelecomUserEmailsLoading,
    pbxes,
    isPbxesLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDebtors: () => {
      dispatch(debtorFetchRequest());
    },
    createNumberPortingRequest: async (values, callback) => {
      await dispatch(numberPortingRequestCreateRequest(values, callback));
    },
    fetchTelecomUserEmails: () => {
      dispatch(contactTelecomUserEmailsFetchRequest());
    },
    fetchPbxes: () => dispatch(pbxFetchRequest(null, true)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportCreate);
