import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.odooEndPoint()}/credit_notes/fetch_all`,
};

class CreditNotesApi extends AuthenticatedRequest {
  // Fetch all creditNotes
  static fetchAll() {
    return this.get(URLS.INDEX);
  }
}

export default CreditNotesApi;
