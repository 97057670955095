import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.odooEndPoint()}/vendors`,
};

class VendorApi extends AuthenticatedRequest {
  // Fetch all vendors
  static fetchAll() {
    return this.get(URLS.INDEX);
  }
}

export default VendorApi;
