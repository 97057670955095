import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

const RenderDeleteAndEdit = ({ hasEdit, hasDelete }) => {
  if (!hasEdit) {
    return (
      <div className="d-flex">
        <Skeleton height={40} width={40} />
      </div>
    );
  }

  if (!hasDelete) {
    return (
      <div className="d-flex">
        <Skeleton height={40} width={100} />
      </div>
    );
  }

  return (
    <>
      <div className="d-flex">
        <Skeleton height={40} width={100} />
      </div>
      <div className="ms-3 d-flex">
        <Skeleton height={40} width={40} />
      </div>
    </>
  );
};

const HeaderSkeleton = ({ hasEdit, hasDelete }) => (
  <div className="py-3 d-flex justify-content-between align-items-center">
    <Skeleton height={30} width={150} />
    <div className="d-flex flex-row">
      {hasEdit && (
        <div className="d-flex">
          <Skeleton height={40} width={100} />
        </div>
      )}
      {hasDelete && (
        <div className="ms-3 d-flex">
          <Skeleton height={40} width={40} />
        </div>
      )}
    </div>
  </div>
);

RenderDeleteAndEdit.defaultProps = {
  hasEdit: true,
  hasDelete: false,
};

RenderDeleteAndEdit.propTypes = {
  hasEdit: PropTypes.bool,
  hasDelete: PropTypes.bool,
};

HeaderSkeleton.defaultProps = {
  hasEdit: true,
  hasDelete: false,
};

HeaderSkeleton.propTypes = {
  hasEdit: PropTypes.bool,
  hasDelete: PropTypes.bool,
};

export default HeaderSkeleton;
