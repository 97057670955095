import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const BASE_URL = `${apiUrlConfig.apiEndPoint()}/dashboard_components`;

const URLS = {
  INVOICES: `${BASE_URL}/invoices`,
  USERS: `${BASE_URL}/users`,
  DEBTORS: `${BASE_URL}/debtors`,
  AGREEMENTS: `${BASE_URL}/agreements`,
  BILLS: `${BASE_URL}/invoices?type=in_invoice`,
  CELL_PHONE_IMPORT_REQUESTS: `${BASE_URL}/cell_phone_import_requests`,
  CELL_PHONE_EXPORT_REQUESTS: `${BASE_URL}/cell_phone_export_requests`,
};

class DashboardApi extends AuthenticatedRequest {
  static fetchUserDasboardDetail() {
    return this.get(URLS.USERS);
  }

  static fetchInvoiceDasboardDetail() {
    return this.get(URLS.INVOICES);
  }

  static fetchBillDasboardDetail() {
    return this.get(URLS.BILLS);
  }

  static fetchDebtorDasboardDetail() {
    return this.get(URLS.DEBTORS);
  }

  static fetchAgreementDasboardDetail() {
    return this.get(URLS.AGREEMENTS);
  }

  static fetchCellPhoneImportRequests() {
    return this.get(URLS.CELL_PHONE_IMPORT_REQUESTS);
  }

  static fetchCellPhoneExportRequests() {
    return this.get(URLS.CELL_PHONE_EXPORT_REQUESTS);
  }
}

export default DashboardApi;
