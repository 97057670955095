import React, { useEffect, useState } from "react";
import { useForm, useField } from "react-final-form";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Translate, t } from "react-i18nify";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

import { networkOperatorSubscriptionServiceDeleteRequest } from "redux/actions/cellPhone";

import WholePageSpinner from "components/Spinner/WholePageSpinner";
import ServiceDeleteModal from "components/NetworkOperatorSubscriptionServices/Modal/ServiceDeleteModal";

const ServiceTableRow = ({
  fields,
  fieldName,
  currentIndex,
  setFilteredSubscriptionServices,
  cellPhoneServices,
  deleteCellPhoneNetworkService,
  commitError,
}) => {
  const [modalVisible, setModalVisibility] = useState(false);
  const [modalData, setModalData] = useState();
  const [serverErrorsForDeleteAction, setServerErrorsForDeleteAction] =
    useState();

  const form = useForm();

  const subscriptionServiceField = useField(fieldName);

  useEffect(() => {
    setServerErrorsForDeleteAction(commitError);
  }, [commitError]);

  const {
    input: { value: subscriptionService },
  } = subscriptionServiceField;

  const {
    id: networkServiceID,
    location,
    cell_phone_service_id: serviceID,
    location_constraint: locationConstraint,
  } = subscriptionService;

  const toggleModalVisibility = (index) => {
    setModalData(index);
    setModalVisibility((previousState) => !previousState);
  };

  const resetModalState = () => {
    setServerErrorsForDeleteAction();
    setModalData();
    toggleModalVisibility();
  };

  const onDeleteCallBack = () => {
    const rowToRemoveFromNetworkOperatorSubscriptionServices =
      form.getFieldState(
        `network_operator_subscription_services[${modalData}]`
      );

    const {
      value: { cell_phone_service_id: removedServiceID },
    } = rowToRemoveFromNetworkOperatorSubscriptionServices;

    const addDeselectedServiceToTheFilterDropdownOptions =
      cellPhoneServices.find(
        ({ id: currentServiceID }) => currentServiceID === removedServiceID
      );

    const { name: selectedNetworkServiceName } =
      addDeselectedServiceToTheFilterDropdownOptions;

    fields.remove(modalData);

    setFilteredSubscriptionServices((previous) => [
      ...previous,
      addDeselectedServiceToTheFilterDropdownOptions,
    ]);

    toastr.success(
      t("common.success"),
      t(
        "toasterMessage.cellPhone.networkService.networkServiceDeletedSuccessfully",
        { networkService: selectedNetworkServiceName }
      )
    );

    resetModalState();
  };

  const onConfirmDelete = () => {
    if (networkServiceID) {
      deleteCellPhoneNetworkService(networkServiceID, () => {
        onDeleteCallBack();
      });
    }
  };

  if (cellPhoneServices.length === 0) return <WholePageSpinner />;

  const currentSubscriptionService = cellPhoneServices.find(
    (cellPhoneService) => cellPhoneService.id === serviceID
  );

  const { name: serviceName } = currentSubscriptionService;

  return (
    <tr className="subscription-listing-table-row">
      <td className="align-middle text-center p-3">
        <p className="mb-0">{serviceName}</p>
      </td>
      <td className="align-middle text-center p-3">
        <p className="mb-0">
          <Translate value={`cellPhone.networkServices.location.${location}`} />
        </p>
      </td>
      <td className="align-middle text-center p-3">
        <p className="mb-0">
          <Translate
            value={`cellPhone.networkServices.locationConstraint.${locationConstraint}`}
          />
        </p>
      </td>
      <td className="d-flex justify-content-evenly align-middle p-3">
        <ServiceDeleteModal
          modalVisible={modalVisible}
          modalData={modalData}
          toggleModal={toggleModalVisibility}
          onConfirm={onConfirmDelete}
          onCancel={resetModalState}
          serverErrors={serverErrorsForDeleteAction}
        />

        <button
          className="border border-danger rounded p-2 text-danger d-flex align-self-center bg-transparent"
          type="button"
          onClick={() => toggleModalVisibility(currentIndex)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </td>
    </tr>
  );
};

function mapStateToProps({ cellPhone: cellPhoneReducer }) {
  const { cellPhone, commitError } = cellPhoneReducer;

  return {
    cellPhone,
    commitError,
  };
}

const mapDispatchToProps = (dispatch) => ({
  deleteCellPhoneNetworkService: (id, cb) => {
    dispatch(networkOperatorSubscriptionServiceDeleteRequest(id, cb));
  },
});

ServiceTableRow.defaultProps = {};

ServiceTableRow.propTypes = {
  fields: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.shape({
        cell_phone_service_id: PropTypes.number,
        location: PropTypes.string,
        location_constraint: PropTypes.string,
      })
    ),
    push: PropTypes.func,
    remove: PropTypes.func,
    update: PropTypes.func,
  }).isRequired,
  fieldName: PropTypes.string.isRequired,
  currentIndex: PropTypes.number.isRequired,
  setFilteredSubscriptionServices: PropTypes.func.isRequired,
  cellPhoneServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      is_default: PropTypes.bool,
    })
  ).isRequired,
  deleteCellPhoneNetworkService: PropTypes.func.isRequired,
  commitError: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTableRow);
