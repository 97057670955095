import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.apiEndPoint()}/roles`,
  PBXS: `${apiUrlConfig.apiEndPoint()}/roles?filter=pbx_users`,
};

class RoleApi extends AuthenticatedRequest {
  /**
   * Fetch all roles accessible to users
   *
   */
  static fetchAll() {
    return this.get(URLS.INDEX);
  }

  /**
   * Fetch pbx roles
   *
   */
  static fetchPbxUserRoles() {
    return this.get(URLS.PBXS);
  }
}

export default RoleApi;
