import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrown } from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-i18nify";

import TranslateNumber from "components/i18n/TranslateNumber";

import { USER } from "constants/routes";

const UserItem = ({ item }) => {
  const { count, new: newRecord, new_list: newList } = item;

  const newUserList = newList.map(
    ({ id, name, business_name: businessName }) => {
      const userLink = USER.addId(USER.SHOW, id);

      return (
        <Link key={id} to={userLink}>
          <div className="user">
            <h5>{name}</h5>
            <p>{businessName}</p>
          </div>
        </Link>
      );
    }
  );

  const newUserContent =
    newList.length === 0 ? (
      <div className="d-flex h-100 w-100 justify-content-center align-items-center flex-column">
        <div className="mb-1">
          <FontAwesomeIcon icon={faFrown} className="text-danger" size="3x" />
        </div>
        <Translate value="dashboard.user.noNew" />
      </div>
    ) : (
      newUserList
    );

  return (
    <div className="item-content">
      <div className="new-users">{newUserContent}</div>
      <div className="user-stats text-center d-flex">
        <div className="base-stats">
          <small>
            <Translate value="dashboard.user.total" />
          </small>
          <h2>
            <TranslateNumber value={count} minimumFractionDigits={0} />
          </h2>
        </div>
        <div className="border" />
        <div className="base-stats">
          <small>
            <Translate value="dashboard.user.new" />
          </small>
          <h2>
            <TranslateNumber value={newRecord} minimumFractionDigits={0} />
          </h2>
        </div>
      </div>
    </div>
  );
};

UserItem.defaultProps = {
  item: {},
};

UserItem.propTypes = {
  item: PropTypes.shape({
    count: PropTypes.number,
    new: PropTypes.number,
    active_count: PropTypes.number,
    active_amount: PropTypes.number,
    archive_count: PropTypes.number,
    archive_amount: PropTypes.number,
    paid_count: PropTypes.number,
    unpaid_count: PropTypes.number,
    unpaid_amount: PropTypes.number,
    draft_count: PropTypes.number,
    draft_amount: PropTypes.number,
    new_list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        business_name: PropTypes.string,
      })
    ),
  }),
};

export default UserItem;
