import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import {
  numberPortingRequestUpdateRequest,
  singleNumberPortingRequestFetchRequest,
} from "redux/actions/numberPortingRequest";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

import ImportForm from "components/NumberPortingRequest/Import/Form/ImportForm";
import AuthenticationModal from "components/NumberPortingRequest/Import/Form/AuthenticationModal";
import WholePageSpinner from "components/Spinner/WholePageSpinner";
import BasicDetail from "components/NumberPortingRequest/Detail/BasicDetail";
import RequestSubmittedModal from "components/NumberPortingRequest/Import/Form/RequestSubmittedModal";

const ImportEdit = ({
  numberPortingRequest,
  updateNumberPortingRequest,
  isLoading,
  commitSuccess,
  commitError,
  fetchSingleNumberPortingRequest,
}) => {
  const { refNumber } = useParams();

  const [showImportForm, setShowImportForm] = useState(false);

  useEffect(() => {
    fetchSingleNumberPortingRequest(refNumber);
  }, []);

  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    await updateNumberPortingRequest(refNumber, values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess && !commitError) {
    return <RequestSubmittedModal successModal />;
  }

  if (!numberPortingRequest) {
    return <WholePageSpinner />;
  }

  const closeAuthenticationModal = () => {
    setShowImportForm(true);
  };

  const {
    number_porting_request_phone_numbers: numberPortingRequestPhoneNumbers,
    customer_email: customerEmail,
  } = numberPortingRequest;

  if (numberPortingRequestPhoneNumbers.length !== 0) {
    return <RequestSubmittedModal />;
  }

  if (!showImportForm) {
    return (
      <AuthenticationModal
        closeAuthenticationModal={closeAuthenticationModal}
        customerEmail={customerEmail}
      />
    );
  }

  return (
    <div className="container-fluid" style={{ overflow: "scroll" }}>
      <div>
        <h3 className="mt-3">
          <Translate value="title.page.numberPortingRequest.customerForm" />
        </h3>
      </div>

      <BasicDetail
        numberPortingRequest={numberPortingRequest}
        customerImportForm
      />

      <ImportForm
        commitError={commitError}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        initialValues={{
          ...numberPortingRequest,
          import_status: "form_submitted_by_customer",
          number_porting_request_phone_numbers_attributes:
            numberPortingRequestPhoneNumbers,
        }}
        formAction="edit"
        numberPortingRequest={numberPortingRequest}
      />
    </div>
  );
};

ImportEdit.defaultProps = {
  isLoading: true,
  numberPortingRequest: undefined,
  commitError: {},
  currentUser: undefined,
};

ImportEdit.propTypes = {
  updateNumberPortingRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  commitError: PropTypes.shape({}),
  commitSuccess: PropTypes.bool.isRequired,
  numberPortingRequest: PropTypes.shape({
    id: PropTypes.number,
    ref_number: PropTypes.string,
    number_type: PropTypes.string,
    debtor_name: PropTypes.string,
    customer_email: PropTypes.string,
    number_porting_request_phone_numbers: PropTypes.shape(),
  }),
  currentUser: PropTypes.shape({}),
  fetchSingleNumberPortingRequest: PropTypes.func.isRequired,
};

function mapStateToProps({
  numberPortingRequest: numberPortingRequestReducer,
}) {
  const { isLoading, commitSuccess, numberPortingRequest, commitError } =
    numberPortingRequestReducer;

  return {
    isLoading,
    commitSuccess,
    numberPortingRequest,
    commitError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSingleNumberPortingRequest: (refNumber) => {
      dispatch(singleNumberPortingRequestFetchRequest(refNumber, true));
    },
    updateNumberPortingRequest: async (refNumber, values, callback) => {
      await dispatch(
        numberPortingRequestUpdateRequest(refNumber, values, callback, true)
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportEdit);
