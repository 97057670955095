import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";
import { Translate, t } from "react-i18nify";
import { toastr } from "react-redux-toastr";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive } from "@fortawesome/free-solid-svg-icons";

import {
  singleQuotationFetchRequest,
  quotationDeleteRequest,
  quotationPreviewRequest,
  sendEmailToCustomerRequest,
} from "redux/actions/quotation";

import { QUOTATION } from "constants/routes";

import HasPermission from "hoc/RbacCTA";

import DetailUIHeader from "components/Header/DetailUI/DetailUIHeader";
import FetchError from "components/Error/FetchError";
import QuotationSkeleton from "components/Skeleton/ResourceDetail/Quotation/QuotationSkeleton";
import DeleteModal from "components/Modal/DeleteModal";
import QuotationBasicDetail from "components/Quotation/Detail/QuotationBasicDetail";
import QuotationTermsAndConditions from "components/Quotation/Detail/QuotationTermsAndConditions";
import QuotationSubscriptionList from "components/Quotation/Detail/QuotationSubscriptionList";
import PreviewFileModal from "components/Quotation/Detail/PreviewFileModal";

const QuotationDetail = ({
  fetchSingleQuotation,
  quotation,
  fetchError,
  deleteQuotation,
  isLoading,
  sendQuotationEmail,
  previewQuotation,
  quotationPreview,
}) => {
  const { quotationID } = useParams();
  const navigate = useNavigate();

  const fetchQuotation = () => {
    fetchSingleQuotation(quotationID);
  };

  const [modalData, setModalData] = useState();
  const [modalVisible, setModalVisibility] = useState(false);
  const [isQuotationPreviewModalVisible, setQuotationPreviewModalVisibility] =
    useState(false);

  const toggle = () => {
    setModalData(quotation);
    setModalVisibility(!modalVisible);
  };

  const resetModalState = () => {
    setModalVisibility(false);
    setModalData();
  };

  const onDeleteSuccess = () => {
    navigate(QUOTATION.INDEX, { replace: true });
  };

  const onConfirmDelete = () => {
    if (Number(quotationID))
      deleteQuotation(Number(quotationID), onDeleteSuccess);

    resetModalState();
  };

  const handleQuotationPreview = () => {
    previewQuotation(Number(quotationID));

    setQuotationPreviewModalVisibility((prevState) => !prevState);
  };

  const handleQuotationEmail = () => {
    toastr.info(
      t("common.info"),
      t("toasterMessage.quotation.quotationBeingSentToCustomerByEmail")
    );

    sendQuotationEmail(Number(quotationID));
  };

  useEffect(() => {
    fetchQuotation();
  }, [quotationID]);

  if (fetchError) {
    return <FetchError onRetry={fetchQuotation} />;
  }

  if (!quotation || isLoading) {
    return <QuotationSkeleton />;
  }

  const {
    reference_number: referenceNumber,
    is_accepted_by_customer: isAcceptedByCustomer,
    subscriptions,
    all_total_setup_price: allTotalSetupPrice,
    all_total_monthly_price: allTotalMonthlyPrice,
    sent_date: sentDate,
    is_archived: isArchived,
  } = quotation;

  const renderArchivedLabel = () => {
    if (!isArchived) return null;

    return (
      <span
        className="is-archived ms-3 px-2 py-2"
        data-cy="quotation-archived-label"
      >
        <FontAwesomeIcon icon={faArchive} className="me-1" />
        <Translate value="attributes.isArchived" />
      </span>
    );
  };

  return (
    <HasPermission redirect>
      <DeleteModal
        modalVisible={modalVisible}
        modalData={modalData}
        toggleModal={toggle}
        onConfirm={onConfirmDelete}
        onCancel={resetModalState}
      />
      <div className="container-fluid quotation">
        <DetailUIHeader
          headerTitle={referenceNumber}
          perform="quotations"
          readOnly={isAcceptedByCustomer || sentDate}
          handleDelete={toggle}
          hasDropdownEllipsis
          handleQuotationPreview={handleQuotationPreview}
          handleQuotationEmail={handleQuotationEmail}
          quotationID={Number(quotationID)}
          canConvertToAgreement={Boolean(sentDate) && !isArchived}
          performListClassName="d-flex"
          additionalHeaderContent={renderArchivedLabel()}
        />
        <PreviewFileModal
          filePreview={quotationPreview}
          modalVisible={isQuotationPreviewModalVisible}
          title={<Translate value="quotation.preview" />}
          onCancel={() =>
            setQuotationPreviewModalVisibility((prevState) => !prevState)
          }
        />

        <QuotationBasicDetail quotation={quotation} />
        <QuotationSubscriptionList
          subscriptions={subscriptions}
          allTotalSetupPrice={allTotalSetupPrice}
          allTotalMonthlyPrice={allTotalMonthlyPrice}
        />
        <QuotationTermsAndConditions quotation={quotation} />
      </div>
    </HasPermission>
  );
};

QuotationDetail.defaultProps = {
  fetchError: null,
  quotationPreview: undefined,
};

QuotationDetail.propTypes = {
  quotation: PropTypes.shape({
    id: PropTypes.number,
    reference_number: PropTypes.string.isRequired,
    is_accepted_by_customer: PropTypes.bool.isRequired,
    subscriptions: PropTypes.arrayOf(PropTypes.shape({})),
    all_total_monthly_price: PropTypes.number,
    all_total_setup_price: PropTypes.number,
    sent_date: PropTypes.string.isRequired,
    is_archived: PropTypes.bool.isRequired,
  }).isRequired,
  fetchSingleQuotation: PropTypes.func.isRequired,
  fetchError: PropTypes.string,
  deleteQuotation: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  previewQuotation: PropTypes.func.isRequired,
  quotationPreview: PropTypes.node,
  sendQuotationEmail: PropTypes.func.isRequired,
};

const mapStateToProps = ({ quotation: quotationResource }) => {
  const { quotation, isLoading, fetchError, quotationPreview } =
    quotationResource;

  return {
    quotation,
    isLoading,
    fetchError,
    quotationPreview,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleQuotation: (id) => {
    dispatch(singleQuotationFetchRequest(id));
  },
  deleteQuotation: (id, cb) => {
    dispatch(quotationDeleteRequest(id, cb));
  },
  previewQuotation: (id) => {
    dispatch(quotationPreviewRequest(id));
  },
  sendQuotationEmail: (id) => {
    dispatch(sendEmailToCustomerRequest(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuotationDetail);
