import React from "react";
import { Route, Routes } from "react-router-dom";

import { Admins } from "constants/userRoles";

import SipDeviceDetail from "views/SipDevices/SipDeviceDetail";
import SipDeviceEdit from "views/SipDevices/SipDeviceEdit";
import { EDIT, SIP_DEVICE } from "constants/routes/routesResources";

const AdminSipDeviceEdit = Admins(SipDeviceEdit);
const AdminSipDeviceDetail = Admins(SipDeviceDetail);

const SipDevice = () => (
  <Routes>
    <Route path={SIP_DEVICE.SIP_DEVICE_ID}>
      <Route index element={<AdminSipDeviceDetail />} />
      <Route path={EDIT} element={<AdminSipDeviceEdit />} />
    </Route>
  </Routes>
);

export default SipDevice;
