const AGREEMENTS_FILTERS = [
  {
    value: "active",
    nameTranslation: "common.active",
  },
  {
    value: "archived",
    nameTranslation: "common.archived",
  },
  {
    value: "billable",
    nameTranslation: "agreement.billable",
  },
  {
    value: "disabled",
    nameTranslation: "agreement.disabled",
  },
];

// eslint-disable-next-line import/prefer-default-export
export { AGREEMENTS_FILTERS };
