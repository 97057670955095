import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, useField, useForm } from "react-final-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-i18nify";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from "react-final-form-listeners";

import { SUBSCRIPTION_LINE_TYPE } from "constants/subscriptionLine";
import REGEX_TO_CHECK_XDSL_SUBSCRIPTION_TYPE from "constants/regex";
import { XDSL_SUBSCRIPTION } from "constants/constant";

import { cannotBeZeroOrNegative } from "utils/validation";
import {
  calculateAllTotalMonthlyPrice,
  calculateAllTotalSetupPrice,
  calculateSubscriptionMonthlyPrice,
  calculateSubscriptionSetupPrice,
  calculateTotalAmount,
} from "utils/quotation";

import { renderInputField } from "components/ReduxForm/RenderField";
import TranslateNumber from "components/i18n/TranslateNumber";
import SubscriptionLinesModal from "components/Quotation/Form/Modal/SubscriptionLinesModal";

const SubscriptionListTableRow = ({ fieldName, fields, currentIndex }) => {
  const [modalVisible, setModalVisibility] = useState(false);

  const form = useForm();

  const subscriptionField = useField(fieldName);
  const {
    input: { value: subscription },
    meta: { invalid: isUpdatedSubscriptionInvalid },
  } = subscriptionField;

  const {
    id,
    subscription_lines: subscriptionLines,
    setup_price: setupPrice,
    monthly_price: monthlyPrice,
    total_setup_price: totalSetupPrice,
    total_monthly_price: totalMonthlyPrice,
  } = subscription;

  const {
    input: { value: subscriptionInitialValue },
  } = useField("agreement.subscription_currently_on_edit");
  const {
    input: {
      value: {
        all_total_setup_price: allTotalSetupPrice,
        all_total_monthly_price: allTotalMonthlyPrice,
      },
    },
  } = useField("agreement");

  const updateQuotationAmountAttributes = (deletedSubscriptionIndex) => {
    const allSubscriptions = fields.value.filter(
      ({ _destroy: isDeleted }, index) =>
        !isDeleted && index !== deletedSubscriptionIndex
    );
    const updatedAllTotalSetupPrice =
      calculateAllTotalSetupPrice(allSubscriptions);
    const updatedAllTotalMonthlyPrice =
      calculateAllTotalMonthlyPrice(allSubscriptions);
    const totalQuotationAmount = calculateTotalAmount(
      updatedAllTotalSetupPrice,
      updatedAllTotalMonthlyPrice
    );

    form.batch(() => {
      form.change("agreement.all_total_setup_price", updatedAllTotalSetupPrice);
      form.change(
        "agreement.all_total_monthly_price",
        updatedAllTotalMonthlyPrice
      );
      form.change("agreement.total_amount", totalQuotationAmount);
    });
  };

  const toggleModalVisibility = () => {
    setModalVisibility((prevState) => !prevState);
  };

  const handleSubscriptionEditEffectForQuotationAmountAttributes = (
    updatedTotalSetupPrice,
    updatedTotalMonthlyPrice
  ) => {
    const {
      total_setup_price: totalSetupPriceToDeduct,
      total_monthly_price: totalMonthlyPriceToDeduct,
    } = subscriptionInitialValue;
    const updatedAllTotalSetupPrice =
      allTotalSetupPrice - totalSetupPriceToDeduct + updatedTotalSetupPrice;
    const updatedAllTotalMonthlyPrice =
      allTotalMonthlyPrice -
      totalMonthlyPriceToDeduct +
      updatedTotalMonthlyPrice;
    const totalQuotationAmount = calculateTotalAmount(
      updatedAllTotalSetupPrice,
      updatedAllTotalMonthlyPrice
    );

    form.batch(() => {
      form.change("agreement.all_total_setup_price", updatedAllTotalSetupPrice);
      form.change(
        "agreement.all_total_monthly_price",
        updatedAllTotalMonthlyPrice
      );
      form.change("agreement.total_amount", totalQuotationAmount);
    });
  };

  const updateSubscriptionAmountAttributes = () => {
    const { quantity } = subscription;

    const activeSubscriptionLines = subscriptionLines.filter(
      ({ _destroy: isDeleted }) => !isDeleted
    );

    const updatedMonthlyPrice = calculateSubscriptionMonthlyPrice(
      activeSubscriptionLines
    );
    const updatedSetupPrice = calculateSubscriptionSetupPrice(
      activeSubscriptionLines
    );
    const updatedTotalMonthlyPrice = quantity * updatedMonthlyPrice;
    const updatedTotalSetupPrice = quantity * updatedSetupPrice;

    const subscriptionAttributes = {
      ...subscription,
      monthly_price: updatedMonthlyPrice,
      setup_price: updatedSetupPrice,
      total_monthly_price: updatedTotalMonthlyPrice,
      total_setup_price: updatedTotalSetupPrice,
      total_amount: calculateTotalAmount(setupPrice, monthlyPrice),
    };

    fields.update(currentIndex, subscriptionAttributes);

    handleSubscriptionEditEffectForQuotationAmountAttributes(
      updatedTotalSetupPrice,
      updatedTotalMonthlyPrice
    );
  };

  const updateSubscription = () => {
    if (isUpdatedSubscriptionInvalid) {
      return;
    }

    updateSubscriptionAmountAttributes();

    toggleModalVisibility();
  };

  const resetSubscriptionUpdate = () => {
    toggleModalVisibility();

    if (subscriptionField.meta.dirty) {
      fields.update(currentIndex, subscriptionInitialValue);
    }
  };

  const removeAssociatedBindingPeriodWithSubscription = (productName) => {
    const quotationSubscriptionBindingPeriodsFieldsState = form.getFieldState(
      `quotation_subscription_binding_periods`
    );

    const { value: quotationSubscriptionBindingPeriods } =
      quotationSubscriptionBindingPeriodsFieldsState;

    if (
      quotationSubscriptionBindingPeriods === undefined ||
      quotationSubscriptionBindingPeriods === null
    )
      return;

    const bindingPeriodIndexToRemove =
      quotationSubscriptionBindingPeriods.findIndex(
        ({ subscription_type: subscriptionType }) => {
          const isxDSLSubscription =
            Boolean(
              subscriptionType.match(REGEX_TO_CHECK_XDSL_SUBSCRIPTION_TYPE)
            ) && Boolean(productName.toLowerCase().includes(XDSL_SUBSCRIPTION));

          return (
            productName.toLowerCase().includes(subscriptionType) ||
            isxDSLSubscription
          );
        }
      );

    if (bindingPeriodIndexToRemove === -1) return;

    const filteredBindingPeriods = quotationSubscriptionBindingPeriods.filter(
      (bindingPeriod, index) => bindingPeriodIndexToRemove !== index
    );

    form.batch(() => {
      form.change(
        `quotation_subscription_binding_periods`,
        filteredBindingPeriods
      );
    });
  };

  const handleRemoveSubscription = (index) => {
    const { value: subscriptions } = fields;

    const subscriptionToRemove = subscriptions[index];

    const { subscription_lines: subscriptionLinesToRemove } =
      subscriptionToRemove;

    const mainSubscriptionLine = subscriptionLinesToRemove.find(
      ({ line_type: lineType }) => lineType === "subscription"
    );

    const { product_name: productName } = mainSubscriptionLine;

    removeAssociatedBindingPeriodWithSubscription(productName);

    fields.remove(index);

    updateQuotationAmountAttributes(index);

    if (id) {
      fields.push({ id, _destroy: true });
    }
  };

  const handleSubscriptionEditButtonClick = () => {
    toggleModalVisibility();

    form.change("agreement.subscription_currently_on_edit", subscription);
  };

  const handleQuantityUpdate = (newQuantity) => {
    if (newQuantity === "") {
      return;
    }

    updateSubscriptionAmountAttributes();

    const updatedSubscriptionTotalSetupPrice = newQuantity * setupPrice;
    const updatedSubscriptionTotalMonthlyPrice = newQuantity * monthlyPrice;

    const updatedAllTotalSetupPrice =
      allTotalSetupPrice - totalSetupPrice + updatedSubscriptionTotalSetupPrice;
    const updatedAllTotalMonthlyPrice =
      allTotalMonthlyPrice -
      totalMonthlyPrice +
      updatedSubscriptionTotalMonthlyPrice;
    const updatedQuotationTotalAmount =
      updatedAllTotalSetupPrice + updatedAllTotalMonthlyPrice;

    form.batch(() => {
      form.change("agreement.all_total_setup_price", updatedAllTotalSetupPrice);
      form.change(
        "agreement.all_total_monthly_price",
        updatedAllTotalMonthlyPrice
      );
      form.change("agreement.total_amount", updatedQuotationTotalAmount);
    });
  };

  if (subscriptionLines.length === 0) {
    return null;
  }

  const mainSubscriptionLine = subscriptionLines.find(
    ({ line_type: lineType }) =>
      lineType === SUBSCRIPTION_LINE_TYPE.SUBSCRIPTION
  );

  const { product_name: mainSubscriptionLineProduct } = mainSubscriptionLine;

  return (
    <>
      <tr className="subscription-listing-table-row py-3">
        <td className="align-middle text-center">
          <p className="mb-0" data-field-name={`${fieldName}.product_name`}>
            {mainSubscriptionLineProduct}
          </p>
        </td>
        <td className="align-middle">
          <div className="w-75 mx-auto">
            <Field
              name={`${fieldName}.quantity`}
              type="number"
              component={renderInputField}
              placeholderTranslation="attributes.quantity"
              inputClassName="form-control text-center"
              validate={cannotBeZeroOrNegative}
              min={1}
            />

            <OnChange name={`${fieldName}.quantity`}>
              {(value) => {
                handleQuantityUpdate(value);
              }}
            </OnChange>
          </div>
        </td>
        <td className="align-middle text-center">
          <p className="mb-0" data-field-name={`${fieldName}.setup_price`}>
            <TranslateNumber value={setupPrice} minimumFractionDigits={2} />
          </p>
        </td>
        <td className="align-middle text-center">
          <p className="mb-0" data-field-name={`${fieldName}.monthly_price`}>
            <TranslateNumber value={monthlyPrice} minimumFractionDigits={2} />
          </p>
        </td>
        <td className="align-middle text-center">
          <p
            className="mb-0"
            data-field-name={`${fieldName}.total_setup_price`}
          >
            <TranslateNumber
              value={totalSetupPrice}
              minimumFractionDigits={2}
            />
          </p>
        </td>
        <td className="align-middle text-center">
          <p
            className="mb-0"
            data-field-name={`${fieldName}.total_monthly_price`}
          >
            <TranslateNumber
              value={totalMonthlyPrice}
              minimumFractionDigits={2}
            />
          </p>
        </td>
        <td className="d-flex justify-content-evenly align-middle mt-2">
          <FieldArray
            name={`${fieldName}.subscription_lines`}
            component={SubscriptionLinesModal}
            title={<Translate value="common.editSubscription" />}
            subTitle={<Translate value="common.mainSubscription" />}
            buttonTitle={<Translate value="common.updateSubscription" />}
            modalVisible={modalVisible}
            onSubmit={updateSubscription}
            onCancel={resetSubscriptionUpdate}
            subscriptionField={subscriptionField}
            dataModalButton="update-subscription-btn"
          />
          <button
            className="border border-success rounded p-2 text-success d-flex align-self-center bg-transparent"
            type="button"
            onClick={handleSubscriptionEditButtonClick}
            data-field-name={`${fieldName}.edit-btn`}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </button>
          <button
            className="border border-danger rounded p-2 text-danger d-flex align-self-center bg-transparent"
            type="button"
            onClick={() => handleRemoveSubscription(currentIndex)}
            data-field-name={`${fieldName}.delete-btn`}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </td>
      </tr>
    </>
  );
};

SubscriptionListTableRow.defaultProps = {};

SubscriptionListTableRow.propTypes = {
  fieldName: PropTypes.string.isRequired,
  currentIndex: PropTypes.number.isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func,
    push: PropTypes.func,
    length: PropTypes.number,
    update: PropTypes.func,
    remove: PropTypes.func,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        subscriptionProducts: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.number,
        }),
        subscription_attributes: PropTypes.shape({
          subscription_lines_attributes: PropTypes.shape({
            line_type: PropTypes.string,
            odoo_product_id: PropTypes.number,
            price: PropTypes.number,
            product_name: PropTypes.string,
            quantity: PropTypes.number,
            subscription_lines_properties_attributes: PropTypes.arrayOf(
              PropTypes.shape({
                property: PropTypes.string,
                value: PropTypes.string,
              })
            ),
            setup_subscription_line: PropTypes.shape({
              context_type: PropTypes.number,
              line_type: PropTypes.string,
              odoo_product_id: PropTypes.number,
              price: PropTypes.number,
              product_name: PropTypes.string,
              subscription_lines_properties_attributes: PropTypes.arrayOf(
                PropTypes.shape({
                  property: PropTypes.string,
                  value: PropTypes.string,
                })
              ),
            }),
          }),
        }),
        subscription_lines: PropTypes.shape({
          line_type: PropTypes.string,
          odoo_product_id: PropTypes.number,
          price: PropTypes.number,
          product_name: PropTypes.string,
          quantity: PropTypes.number,
          subscription_line_properties: PropTypes.arrayOf(
            PropTypes.shape({
              property: PropTypes.string,
              value: PropTypes.string,
            })
          ),
          find: PropTypes.func,
          length: PropTypes.func,
        }),
      })
    ),
  }).isRequired,
};

export default SubscriptionListTableRow;
