import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { AutoSizer } from "react-virtualized";
import SortableTable from "components/Table/SortableTable/SortableTable";
import EmptyTableUI from "components/Table/RecordListingTable/EmptyTableUI";

const DashboardComponentTable = ({
  children,
  records,
  recordDetailIDAttribute,
  resourceName,
}) => {
  const navigate = useNavigate();

  // eslint-disable-next-line consistent-return
  const handleRowClick = ({ rowData }) => {
    const { id } = rowData;

    const recordID = recordDetailIDAttribute
      ? rowData[recordDetailIDAttribute]
      : id;

    const redirectUrl = `/${resourceName}/${recordID}`;

    navigate(redirectUrl);
  };

  if (records?.length === 0) {
    return <EmptyTableUI hideAddButton />;
  }

  return (
    <div className="record-listing-table-container h-100 w-100 d-flex flex-column shadow-sm mb-3 ">
      <div data-cy="record-listing-table" className="record-listing-table">
        <AutoSizer>
          {({ height, width }) => {
            const tableWidth = Math.max(700, width);

            return (
              <SortableTable
                width={tableWidth}
                height={height}
                headerHeight={60}
                rowHeight={60}
                records={records}
                onRowClick={handleRowClick}
              >
                {children}
              </SortableTable>
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
};

DashboardComponentTable.defaultProps = {
  records: [],
  recordDetailIDAttribute: null,
};

DashboardComponentTable.propTypes = {
  children: PropTypes.node.isRequired,
  records: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.func,
    })
  ),
  recordDetailIDAttribute: PropTypes.string,
  resourceName: PropTypes.string.isRequired,
};

export default DashboardComponentTable;
