import React from "react";
import Select from "react-select";
import { I18n, t } from "react-i18nify";
import PropTypes from "prop-types";

import InputLabel from "components/ReduxForm/InputLabel";
import FormError from "components/Form/FormError";

import { getPlaceholderText } from "utils/utils";

const renderDropdownField = ({
  input: { name, value, onBlur, onChange, ...input },
  meta: { touched, error, warning, submitError },
  label,
  labelTranslation,
  dropdownOptions,
  placeholder,
  placeholderTranslation,
  isLoading,
  replacementTranslations,
  disabled,
  onChangeCallBack,
  isMultiSelect,
  hideLabel,
  styles,
  dataTestId,
}) => {
  const dropdownFieldStyles = {
    loadingIndicator: (provided) => ({
      ...provided,
      color: "black",
    }),
    ...styles,
  };

  /**
   * Filters the option based on the user input provided.
   * @param {object} option - The snapshot of the react component (we are sending <Translate /> as label).
   * @param {string} userInput - The input provided by the user.
   * @returns {boolean} - Returns true if the option matches the user input, otherwise false.
   */
  const filterOption = (option, userInput) => {
    const { label: optionLabel, value: optionValue } = option;

    if (!optionLabel || !optionValue) return null;

    let resolvedLabel = "";

    if (typeof optionLabel === "string" || typeof optionLabel === "number") {
      // If typeof optionLabel is number, convert it to string for further computation
      resolvedLabel = optionLabel.toString();
    } else {
      const optionLabelProps = optionLabel.props;
      const optionLabelValue = optionLabelProps.value;

      /**
       * optionLabelPropsWithoutValue will ultimately provide the final object which we can pass to the t for dynamic translation.
       * If we have both days and months along with the value like this { value: 'something_related', days: 30, months: 3 } in optionLabelProps then, optionLabelWithoutValue will be { days: 30, months: 3 }
       * For only one value, optionLabelPropsWithoutValue will be { days: 30}, if only days is present
       */
      const optionLabelPropsWithoutValue = Object.fromEntries(
        Object.entries(optionLabelProps).filter(
          ([key]) => !key.includes("value")
        )
      );

      // resolvedLabel will be 30 Days, if optionLabelValue = Days and optionLabelPropsWithoutValue = { days: 30 }
      resolvedLabel = t(optionLabelValue, optionLabelPropsWithoutValue);
    }

    // lowerCaseLabelAndValue contains both label and value for the particular option, due to which we are able to search using either one. Also, sometimes optionValue is sent as number, so .toString() is used to convert it to string value.
    const lowerCaseLabelAndValue = `${resolvedLabel.toLowerCase()} ${optionValue
      .toString()
      .toLowerCase()}`;
    const lowerCaseInput = userInput.trim().toLowerCase();

    return lowerCaseLabelAndValue.includes(lowerCaseInput);
  };

  return (
    <I18n
      render={() => {
        const placeholderText = getPlaceholderText({
          label,
          placeholder,
          placeholderTranslation,
          labelTranslation,
          replacementTranslations: replacementTranslations.placeholder,
        });

        const RenderInputLabel = () => {
          if (hideLabel) return null;

          return (
            <InputLabel
              name={name}
              label={label}
              labelTranslation={labelTranslation}
              replacementTranslations={replacementTranslations.label}
            />
          );
        };

        return (
          <div data-testid={dataTestId}>
            <RenderInputLabel />
            <Select
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...input}
              isDisabled={disabled}
              options={dropdownOptions}
              id={name}
              isLoading={isLoading}
              styles={{
                ...dropdownFieldStyles,
              }}
              onChange={(options) => {
                let dropdownValue;

                if (Array.isArray(options)) {
                  dropdownValue = options.map((opt) => opt.value);
                } else {
                  dropdownValue = options?.value;
                }

                onChange(dropdownValue);
                onChangeCallBack(dropdownValue);
              }}
              onBlur={() => onBlur(value)}
              value={dropdownOptions.filter((option) =>
                Array.isArray(value)
                  ? value.includes(option.value)
                  : option.value === value
              )}
              placeholder={placeholderText}
              isMulti={isMultiSelect}
              filterOption={filterOption}
            />
            <FormError
              touched={touched}
              className="invalid-text"
              error={error}
              submitError={submitError}
              data-testid="error-text"
              warning={warning}
              name={name}
            />
          </div>
        );
      }}
    />
  );
};

renderDropdownField.defaultProps = {
  dropdownOptions: [],
  label: undefined,
  placeholder: undefined,
  replacementTranslations: {
    placeholder: {},
    label: {},
  },
  disabled: false,
  labelTranslation: undefined,
  placeholderTranslation: undefined,
  isMultiSelect: false,
  onChangeCallBack: () => {},
};

renderDropdownField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  labelTranslation: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderTranslation: PropTypes.string,
  replacementTranslations: PropTypes.shape({
    placeholder: PropTypes.shape({}),
    label: PropTypes.shape({}),
  }),
  isMultiSelect: PropTypes.bool,
  onChangeCallBack: PropTypes.func,
};

export default renderDropdownField;
