import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "reactstrap";
import { Translate } from "react-i18nify";

const LinkHeader = ({
  resource,
  translationValue,
  icon,
  itemClassName,
  resourceName,
  subResource,
}) => (
  <Row>
    <Col md={12} className="agreement-status-container ">
      <div className="d-flex justify-content-between px-4 py-2 py-md-4">
        <div />
        <div
          className={`${itemClassName} agreement-stats light-red px-4 py-4 d-flex align-items-center justify-content-between`}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="icon-holder me-4">
              <FontAwesomeIcon icon={icon} className="dark-red" />
            </div>
            <div className="detail">
              <p className="text-secondary mb-0">
                <Translate value={translationValue} />
              </p>
            </div>
          </div>

          <div className="icon-holder">
            {resource && (
              <Link
                to={resource}
                className="agreement-archive-button ms-2"
                data-cy={`${resourceName}-${subResource}`}
              >
                <FontAwesomeIcon icon={faArrowRight} className="ms-5" />
              </Link>
            )}
          </div>
        </div>
      </div>
    </Col>
  </Row>
);

LinkHeader.defaultProps = {
  resource: undefined,
  translationValue: undefined,
  itemClassName: "",
  icon: undefined,
  resourceName: "",
  subResource: "",
};

LinkHeader.propTypes = {
  resource: PropTypes.string,
  translationValue: PropTypes.string,
  itemClassName: PropTypes.string,
  icon: PropTypes.shape({
    icon: PropTypes.instanceOf(Array),
    iconName: PropTypes.string,
    prefix: PropTypes.string,
  }),
  resourceName: PropTypes.string,
  subResource: PropTypes.string,
};

export default LinkHeader;
