import {
  PBX_FETCH_REQUEST,
  PBX_FETCH_RECEIVED,
  PBX_FETCH_FAILED,
  PBX_CREATE_REQUEST,
  PBX_CREATE_RECEIVED,
  PBX_CREATE_FAILED,
  SINGLE_PBX_FETCH_REQUEST,
  SINGLE_PBX_FETCH_RECEIVED,
  SINGLE_PBX_FETCH_FAILED,
  PBX_UPDATE_REQUEST,
  PBX_UPDATE_RECEIVED,
  PBX_UPDATE_FAILED,
  PBX_FETCH_CLEAR_ERRORS,
  PBX_SEARCH_REQUEST,
  DEBTOR_PBX_FETCH_FAILED,
  DEBTOR_PBX_FETCH_RECEIVED,
  DEBTOR_PBX_FETCH_REQUEST,
  CLEAR_PBX,
  USER_LICENSES_PBX_FETCH_REQUEST,
  USER_LICENSES_PBX_FETCH_RECEIVED,
  USER_LICENSES_PBX_FETCH_FAILED,
} from "redux/actions/pbx";

const defaultState = {
  pbxs: [],
  userLicenses: [],
  commitError: "",
  fetchError: "",
  commitSuccess: false,
  isLoading: false,
  isSearching: false,
};

function pbx(state = defaultState, action) {
  const { pbxs, pbx: singlePbx, userLicenses } = action;

  switch (action.type) {
    case PBX_FETCH_REQUEST:
    case DEBTOR_PBX_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
      };

    case PBX_FETCH_RECEIVED:
    case DEBTOR_PBX_FETCH_RECEIVED:
      return {
        ...state,
        pbxs,
        isLoading: false,
      };

    case PBX_FETCH_FAILED:
    case DEBTOR_PBX_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case SINGLE_PBX_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitSuccess: false,
      };

    case SINGLE_PBX_FETCH_RECEIVED:
      return {
        ...state,
        pbx: singlePbx,
        isLoading: false,
      };

    case SINGLE_PBX_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case PBX_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case PBX_UPDATE_RECEIVED:
      return {
        ...state,
        pbx: singlePbx,
        isLoading: false,
        commitSuccess: true,
      };

    case PBX_UPDATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case PBX_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case PBX_CREATE_RECEIVED:
      return {
        ...state,
        pbx: singlePbx,
        isLoading: false,
        commitSuccess: true,
      };

    case PBX_CREATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case PBX_FETCH_CLEAR_ERRORS:
      return {
        ...state,
        fetchError: "",
        isLoading: false,
        commitSuccess: false,
        commitError: "",
      };

    case PBX_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    case CLEAR_PBX:
      return {
        ...defaultState,
      };

    case USER_LICENSES_PBX_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitSuccess: false,
      };

    case USER_LICENSES_PBX_FETCH_RECEIVED:
      return {
        ...state,
        userLicenses,
        isLoading: false,
      };

    case USER_LICENSES_PBX_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    default:
      return state;
  }
}

export default pbx;
