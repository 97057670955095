import { useEffect, useState } from "react";

import SubscriptionLineApi from "apis/SubscriptionLine";

const useSubscriptionTerminationDate = (id) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    if (!id) return;

    const fetchData = async () => {
      setIsLoading(true);
      const { data: responseData } =
        await SubscriptionLineApi.fetchTerminationDateLimit(id);

      setData(responseData);
      setIsLoading(false);
    };

    fetchData();
  }, [id]);

  return {
    isTerminationDateLoading: isLoading,
    subscriptionTerminationEndRange: data.termination_term_end_limit,
  };
};

const isClient = typeof window === "object";

const isApiSupported = (api) => api in window;

const isSupported = () => {
  if (!isClient || !isApiSupported("matchMedia")) {
    // eslint-disable-next-line no-console
    console.warn("Media query not available");

    return null;
  }

  return true;
};

const useMediaQuery = (mediaQuery) => {
  const [isVerified, setIsVerified] = useState(
    isClient && window.matchMedia(mediaQuery).matches
  );

  useEffect(() => {
    if (!isSupported()) return;
    const mediaQueryList = window.matchMedia(mediaQuery);
    const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches);

    mediaQueryList.addListener(documentChangeHandler);
    documentChangeHandler();

    // eslint-disable-next-line consistent-return
    return () => {
      mediaQueryList.removeListener(documentChangeHandler);
    };
  }, [mediaQuery]);
  if (!isSupported) return null;

  return isVerified;
};

export { useSubscriptionTerminationDate, useMediaQuery };
