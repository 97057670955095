import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getCurrentUserRole } from "utils/selectors";

import UnAuthorizedView from "views/Auth/UnauthorizedView";

/**
 * HOC that Handles whether or not the user is allowed to see the page.
 *
 * @param {array} allowedRoles - user roles that are allowed to see the page.
 * @returns {Component}
 */

export default function Authorization(allowedRoles) {
  return (WrappedComponent) => {
    const WithAuthorization = ({ reduxState, ...props }) => {
      const currentUserRole = getCurrentUserRole(reduxState);

      if (allowedRoles.includes(currentUserRole)) {
        return (
          <WrappedComponent
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        );
      }

      return <UnAuthorizedView />;
    };

    WithAuthorization.defaultProps = {
      reduxState: {
        auth: {
          currentUser: {},
        },
      },
    };

    WithAuthorization.propTypes = {
      reduxState: PropTypes.shape({
        auth: PropTypes.shape({
          currentUser: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          }),
        }),
      }),
    };

    return connect((reduxState) => {
      return { reduxState };
    })(WithAuthorization);
  };
}
