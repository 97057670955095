import React from "react";
import PropTypes from "prop-types";

const DotsLoadingIndicator = ({ className }) => {
  const defaultClassName = ["loading-indicator"];

  defaultClassName.push(className);

  return (
    <div className={defaultClassName.join(" ")} data-testid="dot-loader">
      <span className="dot" />
      <span className="dot" />
      <span className="dot" />
    </div>
  );
};

DotsLoadingIndicator.defaultProps = {
  className: "",
};

DotsLoadingIndicator.propTypes = {
  className: PropTypes.string,
};

export default DotsLoadingIndicator;
