const FORM = {
  VOICEMAIL_BOX_FORM: "voicemailBoxForm",
  USER_LICENSE_FORM: "userLicenseForm",
  SIP_DEVICE_FORM: "sipDeviceForm",
};

const FORM_TYPES = {
  EDIT: "edit",
  CREATE: "create",
};

export { FORM_TYPES };

export default FORM;
