import React from "react";
import PropTypes from "prop-types";
import TimePicker from "rc-time-picker";

import InputLabel from "components/ReduxForm/InputLabel";

const renderTimeField = ({
  input: { name, value, onBlur, onChange, ...input },
  meta: { touched, error, warning },
  label,
  labelTranslation,
  replacementTranslations,
}) => (
  <div>
    <InputLabel
      name={name}
      label={label}
      labelTranslation={labelTranslation}
      replacementTranslations={replacementTranslations.label}
    />
    <div>
      <TimePicker
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
        value={value}
        className="w-100"
        popupClassName="time-picker-popup"
        onBlur={() => onBlur(value)}
        onChange={(changedValue) => onChange(changedValue)}
        showSecond={false}
        data-testid={name}
        use12Hours
      />
    </div>
    {touched &&
      ((error && <span className="invalid-text text-danger">{error}</span>) ||
        (warning && (
          <span className="invalid-text text-warning">{warning}</span>
        )))}
  </div>
);

renderTimeField.defaultProps = {
  label: undefined,
  labelTranslation: undefined,
  replacementTranslations: {
    label: {},
    placeholder: {},
  },
};

renderTimeField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  labelTranslation: PropTypes.string,
};

export default renderTimeField;
