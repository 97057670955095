import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";
import { Translate } from "react-i18nify";

import {
  creditNotesFetchRequest,
  debtorCreditNotesFetchRequest,
  searchCreditNotes,
  creditNoteSearchRequest,
} from "redux/actions/creditNote";

import { ADMIN_ROLES } from "constants/userRoles";

import filterSearchResults from "utils/filterSearchResults";
import { getCurrentUser } from "utils/selectors";

import HasPermission from "hoc/RbacCTA";

import TranslateNumber from "components/i18n/TranslateNumber";
import TranslateDate from "components/i18n/TranslateDate";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import InvoiceDownloadButton from "components/Button/InvoiceDownloadButton ";

const renderCreditNotesTranslateDate = ({ cellData }) => (
  <TranslateDate date={cellData} />
);

const renderCreditNotesTranslateNumber = ({ cellData }) => (
  <TranslateNumber value={cellData} minimumFractionDigits={2} />
);

const renderInvoiceDownloadButton = ({ cellData }) => {
  if (cellData.state?.toLowerCase() === "draft") return null;

  return (
    <InvoiceDownloadButton id={cellData.id} fileName={cellData.fileName} />
  );
};

const CreditNotesIndex = ({
  creditNotes,
  isLoading,
  className,
  parentID,
  fetchDebtorCreditNotes,
  searchRecords,
  isSearching,
  fetchCreditNotes,
  fetchError,
  currentUserRole,
}) => {
  const { debtorID } = useParams();

  const filterID = parentID || debtorID;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (filterID) {
      return fetchDebtorCreditNotes(filterID);
    }

    fetchCreditNotes();
  }, [filterID]);

  const recordsWithDownload = creditNotes.map((record) => {
    const newRecord = { ...record };

    newRecord.download = {
      id: record.id,
      state: record.state,
      fileName: record.number || record.invoice_date,
    };

    return newRecord;
  });

  const isAdmin = ADMIN_ROLES.includes(currentUserRole);

  return (
    <HasPermission
      data={{
        debtorID: parseInt(debtorID, 10),
      }}
      perform="credit_notes:list"
      redirect
    >
      <RecordListingTable
        records={recordsWithDownload}
        isLoading={isLoading}
        hideAddButton
        resourceName="credit_notes"
        resourceTitle={
          <Translate value="title.page.resource.list" resource="Credit Notes" />
        }
        totalDeactivated={0}
        className={className}
        parentID={parentID}
        searchRecords={searchRecords}
        isSearching={isSearching}
        fetchError={fetchError}
        onRetry={fetchCreditNotes}
        disableRowClick={!isAdmin}
      >
        <Column
          label={<Translate value="attributes.number" />}
          dataKey="number"
          width={180}
        />
        {!filterID && (
          <Column
            width={300}
            label={<Translate value="attributes.debtorName" />}
            dataKey="debtor_name"
          />
        )}
        <Column
          width={220}
          label={<Translate value="attributes.dateInvoice" />}
          dataKey="invoice_date"
          cellRenderer={renderCreditNotesTranslateDate}
        />
        <Column
          width={200}
          label={<Translate value="attributes.total" />}
          dataKey="amount_total_signed"
          cellRenderer={renderCreditNotesTranslateNumber}
        />
        <Column
          width={230}
          label={<Translate value="attributes.residualSigned" />}
          dataKey="residual_signed"
          cellRenderer={renderCreditNotesTranslateNumber}
        />
        <Column
          width={130}
          label={<Translate value="attributes.status" />}
          dataKey="state"
        />
        {!isAdmin && (
          <Column
            width={220}
            label="Download"
            dataKey="download"
            cellRenderer={renderInvoiceDownloadButton}
          />
        )}
      </RecordListingTable>
    </HasPermission>
  );
};

CreditNotesIndex.defaultProps = {
  isLoading: true,
  className: "",
  parentID: undefined,
  creditNotes: undefined,
  isSearching: false,
  fetchError: null,
};

CreditNotesIndex.propTypes = {
  creditNotes: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  parentID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fetchCreditNotes: PropTypes.func.isRequired,
  fetchDebtorCreditNotes: PropTypes.func.isRequired,
  searchRecords: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  fetchError: PropTypes.string,
  currentUserRole: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { creditNotes, isLoading, isSearching, fetchError } = state.creditNote;
  const { role: currentUserRole } = getCurrentUser(state);

  const results = filterSearchResults({
    resource: creditNotes,
    searchResult: state.search.creditNotes,
  });

  return {
    creditNotes: results,
    isLoading,
    isSearching,
    fetchError,
    currentUserRole,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchRecords: (e) => {
    dispatch(creditNoteSearchRequest(e));
    dispatch(searchCreditNotes(e));
  },
  fetchCreditNotes: () => {
    dispatch(creditNotesFetchRequest());
  },
  fetchDebtorCreditNotes: (id) => {
    dispatch(debtorCreditNotesFetchRequest(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditNotesIndex);
