import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { dashboardBillFetchRequest } from "redux/actions/dashboard/bill";

import DashboardComponent from "components/Dashboard/DashboardComponent/DashboardComponent";
import BillItem from "components/Dashboard/DashboardItem/BillItem";
import { DASHBOARD_COMPONENT_FEATURE_TYPES } from "constants/constant";

const BillView = ({ fetchBill, bill, isLoading, fetchError }) => {
  useEffect(() => {
    fetchBill();
  }, [fetchBill]);

  return (
    <DashboardComponent
      translationTitle="menuItem.bills"
      isLoading={isLoading || !bill}
      fetchError={fetchError}
      onRetry={fetchBill}
      featureType={DASHBOARD_COMPONENT_FEATURE_TYPES.BILLS}
    >
      <BillItem item={bill} />
    </DashboardComponent>
  );
};

BillView.defaultProps = {
  fetchBill: () => {},
  bill: null,
  isLoading: false,
  fetchError: false,
};

BillView.propTypes = {
  fetchBill: PropTypes.func,
  bill: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  fetchError: PropTypes.bool,
};

const mapStateToProps = ({ dashboard }) => ({
  ...dashboard.bill,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBill: () => dispatch(dashboardBillFetchRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillView);
