import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { reduxSearch, SearchApi, INDEX_MODES } from "redux-search";

import rootReducer from "redux/reducers/root";

import customResourceSelector from "utils/search";
import configureLocaleWithStore from "utils/configureLocaleWithStore";

// We will have to whitelist all redux object we want to store in Local Storage
const persistConfig = {
  key: "flexStore",
  storage,
  whitelist: ["auth", "i18n"],
};

const applicationEnvironment = process.env.NODE_ENV;

const logger = createLogger({
  predicate: () => applicationEnvironment !== "production",
});

// ALL_SUBSTRINGS matching (eg "c", "a", "t", "ca", "at", "cat" match "cat")
const prefixSearchApi = new SearchApi({
  indexMode: INDEX_MODES.ALL_SUBSTRINGS,
});

// Compose :reduxSearch with other store enhancers
const enhancer = compose(
  applyMiddleware(thunk, logger),
  composeWithDevTools(
    reduxSearch({
      resourceIndexes: {
        agreements: [
          "ref_number",
          "start_date",
          "invoiced_until",
          "debtor_name",
        ],
        agreementCreditNotes: [
          "number",
          "invoice_date",
          "invoice_date_due",
          "state",
        ],
        agreementInvoices: [
          "number",
          "invoice_date",
          "invoice_date_due",
          "state",
        ],
        bills: [
          "vendor_name",
          "number",
          "invoice_date",
          "invoice_date_due",
          "state",
        ],
        contacts: ["name", "email", "phone", "mobile"],
        debtors: ["name", "email", "phone", "customer_number", "address"],
        creditNotes: [
          "debtor_name",
          "number",
          "invoice_date",
          "invoice_date_due",
          "state",
        ],
        didNumbers: [
          "phone_number",
          "deployment_date",
          "invoice_start_date",
          "full_name",
          "user_license_extension_number",
        ],
        faxStatuses: ["destination_number", "direction", "sent_date", "status"],
        invoices: [
          "debtor_name",
          "number",
          "invoice_date",
          "invoice_date_due",
          "state",
        ],
        numberPortingRequests: ["ref_number", "pbx_ref_number"],
        pbxs: [
          "ref_number",
          "te_name",
          "debtor_name",
          "deployment_date",
          "invoice_start_date",
        ],
        pbxCellPhones: ["cell_number", "user_license_extension_number"],
        pbxSipDevices: [
          "ex_name",
          "ex_description",
          "user_license_extension_number",
        ],
        settings: ["name", "category", "data_type", "user_type"],
        subscriptionProducts: [
          "product_name",
          "price",
          "properties",
          "subscription_for",
          "subscription_type",
        ],
        userLicenses: ["extension_number", "user_full_name", "email"],
        userLicenseCellPhones: ["cell_number"],
        userLicenseDidNumbers: ["phone_number"],
        sipDevices: ["extension_number", "ex_name", "ex_description"],
        simCards: ["iccid_number", "imsi_number"],
        users: ["first_name", "last_name", "email", "debtor_name", "role"],
        vendors: ["name", "email", "phone", "customer_number", "address"],
        phoneNumbers: ["phone_number", "service_owner_name"],
        quotations: ["reference_number", "debtor_name", "seller_full_name"],
      },
      resourceSelector: (resourceName, state) =>
        customResourceSelector(resourceName, state),
      searchApi: prefixSearchApi,
    })
  )
);

let appStore = null;
let appPersistor = null;

const pReducer = persistReducer(persistConfig, rootReducer);

const setupJestStore = (preloadedState) =>
  createStore(pReducer, preloadedState, applyMiddleware(thunk, logger));

if (applicationEnvironment !== "test") {
  appStore = createStore(pReducer, enhancer);
  appPersistor = persistStore(appStore);
}

const store = appStore;

configureLocaleWithStore(store);

const persistor = appPersistor;

export { setupJestStore, persistor, store };
