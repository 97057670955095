import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

import { replaceMultiple } from "utils/string";

const URLS = {
  INDEX: `${apiUrlConfig.apiEndPoint()}/sim_cards?filter[status]=:status&filter[service_provider_id]=:serviceProviderID&filter[sim_card_borrower][type]=:simCardBorrowerType&filter[sim_card_borrower][id]=:simCardBorrowerID`,
  INDEX_WITHOUT_SIM_CARD_BORROWER: `${apiUrlConfig.apiEndPoint()}/sim_cards?filter[status]=:status&filter[service_provider_id]=:serviceProviderID`,
  DETAIL: `${apiUrlConfig.apiEndPoint()}/sim_cards/:simCardID`,
  EMPLOYEE: `${apiUrlConfig.apiEndPoint()}/users?filter[role_type]=company_users&filter[status]=active`,
  CUSTOMER: `${apiUrlConfig.odooEndPoint()}/debtors/drop_down`,
  ASSIGN: `${apiUrlConfig.apiEndPoint()}/sim_card_borrowers/assign_sim_cards`,
  SEARCH_IMSI_RANGE: `${apiUrlConfig.apiEndPoint()}/sim_cards/search_by_imsi_number`,
  RESELLER: `${apiUrlConfig.apiEndPoint()}/service_providers?filter[type]=resellers`,
  ASSIGN_SIM_TO_SERVICE_PROVIDERS: `${apiUrlConfig.apiEndPoint()}/sim_cards/assign_ranges_to_service_providers`,
  UPDATE: `${apiUrlConfig.apiEndPoint()}/sim_cards/:simCardID`,
  SIM_CARD_BORROWERS: `${apiUrlConfig.apiEndPoint()}/sim_card_borrowers/dropdown`,
  SEARCH_ICCID_RANGE: `${apiUrlConfig.apiEndPoint()}/sim_cards/search_by_iccid_number`,
};

class SimCardApi extends AuthenticatedRequest {
  // Fetch sim cards
  static fetchAll(
    status,
    serviceProviderID,
    simCardBorrowerType,
    simCardBorrowerID
  ) {
    let replaceWith;

    if (simCardBorrowerType === undefined || simCardBorrowerType === null) {
      replaceWith = {
        ":status": status,
        ":serviceProviderID": serviceProviderID,
      };

      return this.get(
        replaceMultiple(URLS.INDEX_WITHOUT_SIM_CARD_BORROWER, replaceWith)
      );
    }
    replaceWith = {
      ":status": status,
      ":serviceProviderID": serviceProviderID,
      ":simCardBorrowerType": simCardBorrowerType,
      ":simCardBorrowerID": simCardBorrowerID,
    };

    return this.get(replaceMultiple(URLS.INDEX, replaceWith));
  }

  /**
   *
   * @param {init} simCardID - ID of sim card to fetch detail
   * @returns
   */
  static fetchSimCardDetail(simCardID) {
    const fetchDetailUrl = URLS.DETAIL.replace(":simCardID", simCardID);

    return this.get(fetchDetailUrl);
  }

  /**
   *
   * employee details
   * @returns
   */
  static fetchEmployee() {
    return this.get(URLS.EMPLOYEE);
  }

  /**
   * fetch customer details
   */

  static fetchCustomer() {
    return this.get(URLS.CUSTOMER);
  }

  /**
   *
   * @param {obj} attributes - assign SIM Card
   * @returns
   */
  static assignSIMCard(attributes) {
    return this.post(URLS.ASSIGN, attributes);
  }

  static searchIMSIRanges(startNumber, endNumber = null) {
    let params = `?imsi_number=${startNumber}`;

    if (endNumber !== null) {
      params = `${params}&greater_than=${endNumber}`;
    }

    const searchUrl = `${URLS.SEARCH_IMSI_RANGE}${params}`;

    return this.get(searchUrl);
  }

  static fetchResellers() {
    return this.get(URLS.RESELLER);
  }

  static assignSIMCardToServiceProviders(attributes) {
    return this.patch(URLS.ASSIGN_SIM_TO_SERVICE_PROVIDERS, attributes);
  }

  static updateSIMCard(simCardID, attributes) {
    const updateUrl = URLS.UPDATE.replace(":simCardID", simCardID);

    return this.patch(updateUrl, attributes);
  }

  static fetchSimCardBorrowers() {
    return this.get(URLS.SIM_CARD_BORROWERS);
  }

  static searchICCIDRanges(startNumber, endNumber = null) {
    let params = `?iccid_number=${startNumber}`;

    if (endNumber !== null) {
      params = `${params}&greater_than=${endNumber}`;
    }

    const searchUrl = `${URLS.SEARCH_ICCID_RANGE}${params}`;

    return this.get(searchUrl);
  }
}

export default SimCardApi;
