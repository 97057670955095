import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Translate } from "react-i18nify";

const CustomLabel = ({ customLabel, nameTranslation }) => {
  if (!customLabel) return <Translate value={nameTranslation} />;

  return <span className="py-1">{customLabel}</span>;
};

const FilterItem = ({
  value,
  nameTranslation,
  changeFilter,
  otherFilters,
  filterType,
  customLabel,
}) => (
  <DropdownItem
    className="drop-down-item"
    onClick={() =>
      changeFilter({
        ...otherFilters,
        [filterType]: value,
      })
    }
    data-cy={`dropdown-item-${filterType}`}
  >
    <CustomLabel customLabel={customLabel} nameTranslation={nameTranslation} />
  </DropdownItem>
);

const ResourceLabel = ({
  selectedFilter,
  headerTranslation,
  selectedFilterTranslation,
}) => {
  if (!selectedFilter) return <Translate value={headerTranslation} />;

  return <Translate value={selectedFilterTranslation} />;
};

const ResourceFilterHeaders = ({ filtersToShow, headerTranslation }) => {
  if (filtersToShow.length === 0) {
    return (
      <>
        <DropdownItem header>
          <Translate value={headerTranslation} />
        </DropdownItem>
        <DropdownItem>
          <Translate value="common.noOptions" />
        </DropdownItem>
      </>
    );
  }

  return (
    <DropdownItem header>
      <Translate value={headerTranslation} />;
    </DropdownItem>
  );
};

function ResourceDropdown({
  selectedFilter,
  changeFilter,
  filters,
  filterType,
  headerTranslation,
  otherFilters,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  const selectedElement = filters.find(({ value }) => value === selectedFilter);

  const selectedFilterTranslation = selectedElement?.nameTranslation || "";

  const filtersToShow = filters.filter(({ value }) => value !== selectedFilter);

  return (
    <div className=" pr-md-0 pr-2 m-2">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          className="p-0"
          data-cy={`dropdown-filter-${filterType}`}
        >
          <div className="d-flex align-items-center resource-dropdown px-3 py-2 justify-content-between">
            <span className="me-2 d-flex align-item-center justify-content-start">
              <ResourceLabel
                headerTranslation={headerTranslation}
                selectedFilterTranslation={selectedFilterTranslation}
                selectedFilter={selectedFilter}
              />
            </span>
            <i className="arrow down mb-2" />
          </div>
        </DropdownToggle>

        <DropdownMenu>
          <ResourceFilterHeaders
            filtersToShow={filtersToShow}
            headerTranslation={headerTranslation}
          />

          {filtersToShow.map(({ value, nameTranslation, customLabel }) => (
            <FilterItem
              value={value}
              nameTranslation={nameTranslation}
              changeFilter={changeFilter}
              otherFilters={otherFilters}
              filterType={filterType}
              customLabel={customLabel}
            />
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

CustomLabel.defaultProps = {
  customLabel: null,
};

CustomLabel.propTypes = {
  nameTranslation: PropTypes.string.isRequired,
  customLabel: PropTypes.node,
};

FilterItem.defaultProps = {
  customLabel: null,
};

FilterItem.propTypes = {
  value: PropTypes.string.isRequired,
  nameTranslation: PropTypes.string.isRequired,
  changeFilter: PropTypes.func.isRequired,
  otherFilters: PropTypes.shape().isRequired,
  filterType: PropTypes.string.isRequired,
  customLabel: PropTypes.node,
};

ResourceLabel.defaultProps = {
  selectedFilterTranslation: "",
  selectedFilter: undefined,
};

ResourceLabel.propTypes = {
  headerTranslation: PropTypes.string.isRequired,
  selectedFilterTranslation: PropTypes.string,
  selectedFilter: PropTypes.string,
};

ResourceFilterHeaders.defaultProps = {};

ResourceFilterHeaders.propTypes = {
  filtersToShow: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  headerTranslation: PropTypes.string.isRequired,
};

ResourceDropdown.defaultProps = {
  selectedFilter: undefined,
  otherFilters: {},
  filterType: undefined,
};

ResourceDropdown.propTypes = {
  selectedFilter: PropTypes.string,
  filterType: PropTypes.string,
  changeFilter: PropTypes.func.isRequired,
  otherFilters: PropTypes.shape(),
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  headerTranslation: PropTypes.string.isRequired,
};

export default ResourceDropdown;
