import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Translate, t } from "react-i18nify";
import PropTypes from "prop-types";

import {
  subscriptionProductUpdateRequest,
  singleSubscriptionProductFetchRequest,
  subscriptionProductFetchClearErrors,
  singleSubscriptionProductDeleteRequest,
} from "redux/actions/subscriptionProduct";

import HasPermission from "hoc/RbacCTA";

import SubscriptionProductForm from "components/SubscriptionProduct/Form/SubscriptionProductForm";
import WholePageSpinner from "components/Spinner/WholePageSpinner";
import DeleteButton from "components/Button/DeleteButton";
import DeleteModal from "components/Modal/DeleteModal";

import { SETTING } from "constants/routes";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const SubscriptionProductEdit = ({
  updateSubscriptionProduct,
  commitSuccess,
  isLoading,
  subscriptionProduct,
  deleteSubscriptionProduct,
  fetchSingleSubscriptionProduct,
  clearErrors,
}) => {
  const navigate = useNavigate();
  const { subscriptionProductID } = useParams();

  const [modalData, setModalData] = useState();
  const [modalVisible, setModalVisibility] = useState(false);

  const toggle = () => {
    setModalData(subscriptionProductID);
    setModalVisibility(!modalVisible);
  };

  const resetModalState = () => {
    setModalVisibility(false);
    setModalData();
  };

  const onSuccess = () => {
    navigate(`${SETTING.INDEX}/subscription_products`, { replace: true });
  };

  const onConfirmDelete = (id) => {
    if (id) deleteSubscriptionProduct(id, onSuccess);
    resetModalState();
  };

  useEffect(() => {
    clearErrors();
    fetchSingleSubscriptionProduct(subscriptionProductID);
  }, []);

  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};
    const { id } = subscriptionProduct;

    await updateSubscriptionProduct(id, values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess && subscriptionProduct) {
    return <Navigate to={`${SETTING.INDEX}/subscription_products`} />;
  }

  if (isLoading || !subscriptionProduct) {
    return <WholePageSpinner />;
  }

  const { usage_product_property: usageProductProperty } = subscriptionProduct;
  const usageProductPropertyAttributes = {
    usage_product_property_attributes: usageProductProperty,
  };

  return (
    <div className="container-fluid">
      <DeleteModal
        modalVisible={modalVisible}
        modalData={modalData}
        resourceName="subscription product"
        toggleModal={toggle}
        onConfirm={onConfirmDelete}
        onCancel={resetModalState}
      />
      <div className="d-flex align-items-center justify-content-between">
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.edit"
            resource={t(
              "title.tab.agreement.subscriptionModal.subscriptionProduct"
            )}
          />
        </h3>
        <HasPermission perform="subscriptionProduct:delete">
          <DeleteButton handleDelete={toggle} />
        </HasPermission>
      </div>
      <SubscriptionProductForm
        onSubmit={handleSubmit}
        formName="subscriptionProductCreateForm"
        isLoading={isLoading}
        initialValues={{
          ...subscriptionProduct,
          ...usageProductPropertyAttributes,
        }}
      />
    </div>
  );
};

SubscriptionProductEdit.defaultProps = {
  subscriptionProduct: undefined,
};

SubscriptionProductEdit.propTypes = {
  updateSubscriptionProduct: PropTypes.func.isRequired,
  deleteSubscriptionProduct: PropTypes.func.isRequired,
  subscriptionProduct: PropTypes.shape({
    id: PropTypes.number,
    usage_product_property: PropTypes.shape({}),
  }),
  isLoading: PropTypes.bool.isRequired,
  commitSuccess: PropTypes.bool.isRequired,
  fetchSingleSubscriptionProduct: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  subscriptionProduct: subscriptionProductResource,
}) => {
  const { subscriptionProduct, commitSuccess, isLoading } =
    subscriptionProductResource;

  return {
    subscriptionProduct,
    isLoading,
    commitSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleSubscriptionProduct: (id) => {
    dispatch(singleSubscriptionProductFetchRequest(id));
  },
  updateSubscriptionProduct: async (id, attributes, callback) => {
    await dispatch(subscriptionProductUpdateRequest(id, attributes, callback));
  },
  deleteSubscriptionProduct: (id, callback) => {
    dispatch(singleSubscriptionProductDeleteRequest(id, callback));
  },
  clearErrors: () => {
    dispatch(subscriptionProductFetchClearErrors());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionProductEdit);
