import React from "react";
import PropTypes from "prop-types";
import { Translate, t } from "react-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

import SubscriptionBindingPeriodTableRow from "components/Quotation/Form/SubscriptionBindingPeriod/SubscriptionBindingPeriodTableRow";

const BindingPeriodContent = ({ fields }) => {
  if (!fields) return null;

  return fields.value?.map((bindingPeriod, index) => (
    <SubscriptionBindingPeriodTableRow
      bindingPeriod={bindingPeriod}
      currentIndex={index}
      fields={fields}
    />
  ));
};

const SubscriptionBindingPeriodTable = ({ fields }) => {
  if (fields.length === 0) {
    return (
      <div className="p-4 h-100 shadow-sm bg-white d-flex flex-column align-items-center justify-content-center empty-table">
        <div className="info-icon-wrapper border-danger mb-2">
          <FontAwesomeIcon className="info-icon text-danger" icon={faInfo} />
        </div>
        <div className="text-center fw-bold">
          <p className="mb-2">
            <Translate value="common.noRecords" />
          </p>

          <button
            type="button"
            className="btn btn-primary"
            onClick={() => fields.push({})}
            data-cy="binding-period-add-btn"
          >
            <Translate
              value="title.resource.button.addResource"
              resource={t(`common.bindingPeriod`)}
            />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="table-responsive">
      <table className="table subscription-listing-table text-center table-borderless">
        <thead>
          <tr className="subscription-detail">
            <th className="align-middle text-center py-3">
              <p className="mb-0 ">
                <Translate value="attributes.subscriptionType" />
              </p>
            </th>
            <th className="align-middle text-center py-3">
              <p className="mb-0 ">
                <Translate value="attributes.bindingPeriodInMonths" />
              </p>
            </th>
            <th className="align-middle text-center py-3">
              <p className="mb-0 ">
                <Translate value="common.action" />
              </p>
            </th>
          </tr>
        </thead>

        <tbody>
          <BindingPeriodContent fields={fields} />
        </tbody>

        <tfoot>
          <tr className="border">
            <td colSpan={3} className="text-end py-4">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => fields.push({})}
                data-cy="binding-period-add-btn"
              >
                <Translate
                  value="title.resource.button.addResource"
                  resource={t(`common.bindingPeriod`)}
                />
              </button>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

BindingPeriodContent.defaultProps = {};

BindingPeriodContent.propTypes = {
  fields: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.shape({
        subscription_type: PropTypes.string,
        binding_period_in_months: PropTypes.number,
      })
    ),
    length: PropTypes.func,
    push: PropTypes.func,
  }).isRequired,
};

SubscriptionBindingPeriodTable.defaultProps = {};

SubscriptionBindingPeriodTable.propTypes = {
  fields: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.shape({
        subscription_type: PropTypes.string,
        binding_period_in_months: PropTypes.number,
      })
    ),
    length: PropTypes.func,
    push: PropTypes.func,
  }).isRequired,
};

export default SubscriptionBindingPeriodTable;
