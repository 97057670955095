import React from "react";
import PropTypes from "prop-types";
import {
  faFolderPlus,
  faBox,
  faCheckDouble,
  faMoneyBillWave,
  faArchive,
  faFolderMinus,
  faPager,
} from "@fortawesome/free-solid-svg-icons";

import ItemRow from "components/Dashboard/DashboardItem/ItemRow";

const AgreementItem = ({ item }) => {
  const {
    count,
    active_count: activeCount,
    new: newRecordCount,
    active_amount: activeAmount,
    archive_count: archiveCount,
    archive_amount: archiveAmount,
    disabled,
    billable_count: billableCount,
    billable_amount: billableAmount,
  } = item;

  return (
    <div className="item-content overflow-auto">
      <ItemRow
        icon={faFolderPlus}
        iconClassName="text-danger"
        title="dashboard.agreement.new"
        value={newRecordCount}
      />
      <ItemRow
        icon={faBox}
        iconClassName="text-info"
        title="dashboard.agreement.total"
        value={count}
      />
      <ItemRow
        icon={faCheckDouble}
        iconClassName="text-success"
        title="dashboard.agreement.active"
        value={activeCount}
      />
      <ItemRow
        icon={faMoneyBillWave}
        iconClassName="text-warning"
        title="dashboard.agreement.activeAmount"
        value={activeAmount}
        minFractionDigits={2}
      />
      <ItemRow
        icon={faArchive}
        iconClassName="text-info"
        title="dashboard.agreement.archived"
        value={archiveCount}
      />
      <ItemRow
        icon={faMoneyBillWave}
        iconClassName="text-success"
        title="dashboard.agreement.archivedAmount"
        value={archiveAmount}
        minFractionDigits={2}
      />
      <ItemRow
        icon={faFolderMinus}
        iconClassName="text-danger"
        title="dashboard.agreement.disabled"
        value={disabled}
      />
      <ItemRow
        icon={faArchive}
        iconClassName="text-warning"
        title="dashboard.agreement.billableCount"
        value={billableCount}
      />
      <ItemRow
        icon={faPager}
        iconClassName="text-warning"
        title="dashboard.agreement.billableAmount"
        value={billableAmount}
        minFractionDigits={2}
      />
    </div>
  );
};

AgreementItem.defaultProps = {
  item: {},
};

AgreementItem.propTypes = {
  item: PropTypes.shape({
    count: PropTypes.number,
    new: PropTypes.number,
    active_count: PropTypes.number,
    active_amount: PropTypes.number,
    archive_count: PropTypes.number,
    archive_amount: PropTypes.number,
    paid_count: PropTypes.number,
    unpaid_count: PropTypes.number,
    unpaid_amount: PropTypes.number,
    draft_count: PropTypes.number,
    draft_amount: PropTypes.number,
    new_list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        business_name: PropTypes.string,
      })
    ),
    disabled: PropTypes.bool,
    billable_count: PropTypes.number,
    billable_amount: PropTypes.number,
  }),
};

export default AgreementItem;
