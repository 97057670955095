import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";
import PropTypes from "prop-types";

import { Translate, t } from "react-i18nify";

const AuthenticationModal = ({ customerEmail, closeAuthenticationModal }) => {
  const [authenticationError, setAuthenticationError] = useState(undefined);
  const [inputEmail, setInputEmail] = useState("");

  const authenticateUser = () => {
    if (inputEmail === customerEmail) {
      closeAuthenticationModal();
    } else {
      setAuthenticationError(
        <Translate value="numberPortingRequest.authenticationModal.incorrectEmail" />
      );
    }
  };

  const handleTextChange = (e) => {
    setAuthenticationError("");
    setInputEmail(e.target.value);
  };

  const errorField = authenticationError && (
    <p className="text-danger mt-2">{authenticationError}</p>
  );

  return (
    <Modal isOpen>
      <ModalHeader>
        <Translate value="title.modal.numberPortingRequest.authenticationModal.verifyEmailAddress" />
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col md="12" className="mb-2">
            <p>
              <Translate value="numberPortingRequest.authenticationModal.subTitle" />
            </p>

            <FormGroup>
              <Input
                data-cy="customer_email"
                name="customer_email"
                placeholder={t("common.emailPlaceholder")}
                onChange={handleTextChange}
              />
              {errorField}
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button
          data-cy="verify-button"
          color="primary"
          onClick={authenticateUser}
        >
          <Translate value="common.verify" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

AuthenticationModal.defaultProps = {};

AuthenticationModal.propTypes = {
  customerEmail: PropTypes.string.isRequired,
  closeAuthenticationModal: PropTypes.func.isRequired,
};

export default AuthenticationModal;
