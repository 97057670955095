import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrown, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";
import { I18n, t, Translate } from "react-i18nify";

import { AGREEMENT } from "constants/routes";
import { formatSearchResults } from "utils/agreement";

const SearchAgreementItem = ({ item, action: searchAgreement }) => {
  const { searching, searchedAgreements, searchSuccess } = item;
  const [searchText, setSearchText] = useState();
  const searchResults = formatSearchResults(searchedAgreements);

  const handleSearch = () => {
    if (searchText) searchAgreement(searchText);
  };

  const onEnterKeyPress = (e) => {
    if (e.which === 13) handleSearch();
  };

  const searchResultsList = searchResults.map(
    ({ id, ref_number: refNumber }) => {
      const agreementLink = AGREEMENT.addId(AGREEMENT.SHOW, id);

      return (
        <Link to={agreementLink}>
          <div className="user">
            <h5>{refNumber}</h5>
          </div>
        </Link>
      );
    }
  );

  const noResultsText = searchSuccess
    ? "dashboard.agreement.noResults"
    : "dashboard.agreement.searchHelpText";
  const searchIcon = searchSuccess ? faFrown : faSearch;
  const colorClass = searchSuccess ? "text-danger" : "text-info";

  let displayContent =
    searchResults.length === 0 ? (
      <div className="d-flex h-100 w-100 justify-content-center align-items-center flex-column text-center px-3">
        <div className="mb-3">
          <FontAwesomeIcon icon={searchIcon} className={colorClass} size="3x" />
        </div>
        <Translate value={noResultsText} />
      </div>
    ) : (
      searchResultsList
    );

  displayContent = searching ? (
    <div className="d-flex justify-content-center">
      <Spinner />
    </div>
  ) : (
    displayContent
  );

  return (
    <div className="item-content">
      {/* <div className="input-group md-form form-sm form-2 p-2"> */}
      <div className="input-group my-3 p-2">
        <I18n
          render={() => (
            // <input
            //   className="form-control my-0 py-1 amber-border"
            //   type="text"
            //   placeholder={t('common.search')}
            //   aria-label="Search"
            //   value={searchText}
            //   onChange={(e) => setSearchText(e.target.value)}
            //   onKeyPress={onEnterKeyPress}
            // />

            <input
              data-cy="agreement-search-field"
              type="text"
              className="form-control"
              placeholder={t("common.search")}
              aria-label="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={onEnterKeyPress}
            />
          )}
        />
        {/* <div className="input-group-append">
          <button type="button" onClick={handleSearch} className=" amber lighten-3 btn-primary">
            <FontAwesomeIcon className="text-grey" icon={faSearch} />
          </button> */}
        <button
          data-cy="button-to-search-agreement"
          onClick={handleSearch}
          className="btn btn-primary input-btn"
          type="button"
          id="button-addon2"
        >
          <FontAwesomeIcon className="text-white m-0" icon={faSearch} />
        </button>
      </div>
      <div className="new-users border-0">{displayContent}</div>
    </div>
  );
};

SearchAgreementItem.defaultProps = {
  item: {
    searching: false,
    searchSuccess: false,
    searchedAgreements: [],
  },
  action: () => {},
};

SearchAgreementItem.propTypes = {
  item: PropTypes.shape({
    searching: PropTypes.bool,
    searchSuccess: PropTypes.bool,
    searchedAgreements: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  action: PropTypes.func,
};

export default SearchAgreementItem;
