import React from "react";
import PropTypes from "prop-types";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";

import SubscriptionProductAttributeItem from "./SubscriptionProductAttributeItem";

const ProductPropertyItem = ({ propertiesArray }) => {
  const propertiesContent = (
    <div className="mt-4 mt-md-0 mb-3 mb-md-0 d-md-flex align-items-center">
      {propertiesArray.map((property) => {
        return (
          <span
            key={property}
            className="badge rounded-pill bg-primary py-1 px-2 tags d-inline-block mt-2 mt-md-0"
          >
            {property}
          </span>
        );
      })}
    </div>
  );

  return (
    <SubscriptionProductAttributeItem
      icon={faListAlt}
      translatedTitle="attributes.productProperties"
      propertiesContent={propertiesContent}
    />
  );
};

ProductPropertyItem.defaultProps = {};

ProductPropertyItem.propTypes = {
  propertiesArray: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProductPropertyItem;
