import React from "react";
import PropTypes from "prop-types";

import CardHeaderSkeleton from "components/Skeleton/CardHeaderSkeleton";
import TableSkeleton from "components/Skeleton/TableSkeleton";
import {
  ResourceHeader,
  DebtorsHeader,
  InvoicesHeader,
} from "components/Skeleton/ResourceList/ResourceHeader";

const header = (resourceName) => {
  switch (resourceName) {
    case "agreements":
      return <ResourceHeader filterCount={1} />;

    case "debtors":
      return <DebtorsHeader />;

    case "pbxs":
    case "did_numbers":
    case "user_licenses":
    case "number_porting_requests/imports":
    case "number_porting_requests/exports":
    case "quotations":
      return <ResourceHeader showOnlyFilters filterCount={1} />;

    case "users":
      return <ResourceHeader showOnlyFilters filterCount={2} />;

    case "invoices":
      return <InvoicesHeader />;
    default:
      return null;
  }
};

const ResourceListSkeleton = ({ resourceName }) => (
  <div className="container-fluid h-100" data-testid="skeleton-table">
    <div className="card border-0 shadow-sm h-100 table-responsive">
      <CardHeaderSkeleton />
      <div className="m-0 border-bottom border-gray-100" />
      {header(resourceName)}
      <TableSkeleton />
    </div>
  </div>
);

ResourceListSkeleton.defaultProps = {
  resourceName: "",
};

ResourceListSkeleton.propTypes = {
  resourceName: PropTypes.string,
};

export default ResourceListSkeleton;
