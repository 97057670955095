import React from "react";
import Skeleton from "react-loading-skeleton";

import HeaderSkeleton from "components/Skeleton/HeaderSkeleton";
import TabTableSkeleton from "components/Skeleton/TabTableSkeleton";

const QuotationSkeleton = () => (
  <div className="container-fluid">
    <HeaderSkeleton hasDelete />
    <TabTableSkeleton />
    <div className="p-3 bg-light rounded shadow-sm border-bottom border-gray-100">
      <div className="d-flex align-items-center justify-content-between">
        <Skeleton height={30} width={200} />
      </div>
    </div>
    <div className="bg-white rounded shadow-sm mb-4">
      <div className="p-3 border-bottom">
        <Skeleton className="w-100" height={20} />
        <Skeleton className="w-100" height={20} />
        <Skeleton className="w-75" height={20} />
        <Skeleton className="w-100 mt-3" height={20} />
        <Skeleton className="w-100" height={20} />
        <Skeleton className="w-75" height={20} />
      </div>
    </div>
    <div className="p-3 bg-light rounded shadow-sm border-bottom border-gray-100">
      <div className="d-flex align-items-center justify-content-between">
        <Skeleton height={30} width={250} />
      </div>
    </div>
    <div className="bg-white rounded shadow-sm mb-4">
      <div className="p-3 border-bottom d-flex flex-row gap-4 flex-wrap">
        <div className="d-flex flex-column">
          <Skeleton width={40} height={40} />
          <Skeleton width={150} height={20} />
        </div>
        <div className="d-flex flex-column">
          <Skeleton width={40} height={40} />
          <Skeleton width={150} height={20} />
        </div>
        <div className="d-flex flex-column">
          <Skeleton width={40} height={40} />
          <Skeleton width={150} height={20} />
        </div>
      </div>
    </div>
  </div>
);

export default QuotationSkeleton;
