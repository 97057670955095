import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";
import { t, Translate } from "react-i18nify";
import { useLocation, useNavigate } from "react-router-dom";

import {
  userLicenseFetchRequest,
  searchUserLicenses,
  userLicenseSearchRequest,
} from "redux/actions/userLicense";

import filterSearchResults from "utils/filterSearchResults";
import { getParams } from "utils/queryString";
import { isAnAdminUser } from "utils/userRole";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import TranslateDate from "components/i18n/TranslateDate";
import ResourceHeader from "components/ResourceHeader/ResourceHeader";

import { PBX_FILTERS } from "constants/filters";

const renderUserLicenseTranslateDate = ({ cellData }) => (
  <TranslateDate date={cellData} />
);

const UserLicenseIndex = ({
  pbxActiveStatus,
  fetchAllUserLicenses,
  parentID,
  userLicenses,
  isLoading,
  searchRecords,
  isSearching,
  currentUser,
}) => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const { filter = "active" } = getParams(search);

  const { role } = currentUser;

  const isAdminUser = isAnAdminUser(role);

  useEffect(() => {
    fetchAllUserLicenses(parentID, filter);
  }, [parentID, filter]);

  useEffect(() => {
    const searchParams = `?filter=${filter}`;

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath, { replace: true });
  }, [filter]);

  let records = null;

  if (userLicenses) {
    records = parentID
      ? userLicenses.filter(
          (userLicense) => userLicense.pbx_id === parseInt(parentID, 10)
        )
      : userLicenses;
  }

  const changeFilter = ({ filter: selectedFilter }) => {
    const searchParams = `?filter=${selectedFilter}`;

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath, { replace: true });
  };

  const userLicenseTranslation = t("attributes.userLicense");

  const allFilters = [
    {
      filter: PBX_FILTERS,
      selectedFilter: filter,
      type: "filter",
      headerTranslation: "common.filters",
    },
  ];

  return (
    <RecordListingTable
      records={records}
      isLoading={isLoading}
      hideAddButton={!pbxActiveStatus || !isAdminUser}
      resourceName="user_licenses"
      resourceTitle={t("title.page.resource.list", {
        resource: userLicenseTranslation,
      })}
      additionalHeaderContent={
        <ResourceHeader allFilters={allFilters} changeFilter={changeFilter} />
      }
      parentID={parentID}
      searchRecords={searchRecords}
      isSearching={isSearching}
      dataCy="create-user_license"
    >
      <Column
        width={180}
        label={<Translate value="attributes.extensionNumber" />}
        dataKey="extension_number"
      />
      <Column
        width={180}
        label={<Translate value="attributes.name" />}
        dataKey="user_full_name"
      />
      <Column
        width={200}
        label={<Translate value="attributes.email" />}
        dataKey="email"
      />
      <Column
        width={200}
        label={<Translate value="attributes.invoiceStartDate" />}
        dataKey="invoice_start_date"
        cellRenderer={renderUserLicenseTranslateDate}
      />
      <Column
        width={210}
        label={<Translate value="attributes.deploymentDate" />}
        dataKey="deployment_date"
        cellRenderer={renderUserLicenseTranslateDate}
      />
    </RecordListingTable>
  );
};

UserLicenseIndex.defaultProps = {
  pbxActiveStatus: true,
  isLoading: true,
  userLicenses: undefined,
  parentID: undefined,
  isSearching: false,
};

UserLicenseIndex.propTypes = {
  pbxActiveStatus: PropTypes.bool,
  userLicenses: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      business_name: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  fetchAllUserLicenses: PropTypes.func.isRequired,
  parentID: PropTypes.string,
  searchRecords: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  currentUser: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = ({ userLicense, search, auth }) => {
  const { userLicenses, isLoading, isSearching } = userLicense;
  const results = filterSearchResults({
    resource: userLicenses,
    searchResult: search.userLicenses,
  });

  const { currentUser } = auth;

  return {
    userLicenses: results,
    isLoading,
    isSearching,
    currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchRecords: (e) => {
    dispatch(userLicenseSearchRequest(e));
    dispatch(searchUserLicenses(e));
  },
  fetchAllUserLicenses: (pbxID, filter) => {
    dispatch(userLicenseFetchRequest(pbxID, filter));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserLicenseIndex);
