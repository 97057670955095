import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive } from "@fortawesome/free-solid-svg-icons";

import { Translate } from "react-i18nify";

import {
  singleNumberPortingRequestFetchRequest,
  numberPortingRequestUpdateRequest,
  numberPortingRequestCancelRequest,
} from "redux/actions/numberPortingRequest";

import { NUMBER_PORTING_REQUEST } from "constants/routes";

import BasicDetail from "components/NumberPortingRequest/Detail/BasicDetail";
import EditButton from "components/Button/EditButton";
import NumberPortingRequestImportDetailSkeleton from "components/Skeleton/ResourceDetail/NumberPortingRequestImportDetailSkeleton";
import ArchiveModal from "components/Modal/ArchiveModal";
import RequestAcceptModal from "components/NumberPortingRequest/Import/Detail/RequestAcceptModal";
import NumberPortingRequestPhoneNumberTable from "components/NumberPortingRequest/Detail/NumberPortingRequestPhoneNumberTable";
import CancelAllImportFlowsModal from "components/NumberPortingRequest/Detail/CancelAllImportFlowsModal";

const ImportDetail = ({
  numberPortingRequest,
  fetchSingleNumberPortingRequest,
  archiveNumberPortingRequest,
  isLoading,
  cancelAllImportFlows,
}) => {
  const { refNumber } = useParams();

  useEffect(() => {
    fetchSingleNumberPortingRequest(refNumber);
  }, [refNumber]);

  if (
    isLoading ||
    !numberPortingRequest ||
    numberPortingRequest?.ref_number !== refNumber
  ) {
    return <NumberPortingRequestImportDetailSkeleton />;
  }

  const {
    number_porting_request_phone_numbers: numberPortingRequestPhoneNumbers,
    number_type: numberType,
    can_archive: canArchive,
    is_archived: isArchived,
    import_request_submitted_to_np_server: requestSubmittedToNPServer,
    can_cancel_all_import_flows: canCancelAllImportFlows,
  } = numberPortingRequest;

  const archiveModal = canArchive && !isArchived && (
    <ArchiveModal
      resourceIdentifier={refNumber}
      archiveRecord={(callBack) =>
        archiveNumberPortingRequest(refNumber, callBack)
      }
    />
  );

  const requestAcceptModal = !isArchived &&
    !requestSubmittedToNPServer &&
    numberPortingRequestPhoneNumbers.length > 0 && (
      <RequestAcceptModal
        refNumber={refNumber}
        fetchSingleNumberPortingRequest={fetchSingleNumberPortingRequest}
      />
    );

  const requestedPhoneNumberTable = numberPortingRequestPhoneNumbers.length >
    0 && (
    <NumberPortingRequestPhoneNumberTable
      requestedPhoneNumbers={numberPortingRequestPhoneNumbers}
      canRefresh={requestSubmittedToNPServer && !canArchive}
      isRefreshing={isLoading}
      numberType={numberType}
      refNumber={refNumber}
      isArchived={isArchived}
    />
  );

  const cancelImportFlowsModal = numberPortingRequestPhoneNumbers.length > 1 &&
    canCancelAllImportFlows && (
      <CancelAllImportFlowsModal
        resourceIdentifier={refNumber}
        cancelPortingFlow={(callBack) =>
          cancelAllImportFlows(refNumber, callBack)
        }
        resourceID={refNumber}
        isLoading={isLoading}
      />
    );

  return (
    <div>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center mb-3 detail-ui-header">
          <div className="d-md-flex">
            <h3 className="m-0 d-flex justify-content-between align-items-center">
              {refNumber}
            </h3>

            <span className="is-archived ms-3 px-2 py-2" hidden={!isArchived}>
              <FontAwesomeIcon icon={faArchive} className="me-1" />
              <Translate value="attributes.isArchived" />
            </span>
          </div>

          <div className="d-md-flex">
            {requestAcceptModal}
            {archiveModal}
            {cancelImportFlowsModal}

            {!requestSubmittedToNPServer && !isArchived && (
              <EditButton
                editUrl={NUMBER_PORTING_REQUEST.addRefNumber(
                  NUMBER_PORTING_REQUEST.IMPORT_EDIT,
                  refNumber
                )}
              />
            )}
          </div>
        </div>

        <BasicDetail numberPortingRequest={numberPortingRequest} />
      </div>

      {requestedPhoneNumberTable}
    </div>
  );
};

ImportDetail.defaultProps = {
  numberPortingRequest: undefined,
};

ImportDetail.propTypes = {
  numberPortingRequest: PropTypes.shape({
    id: PropTypes.number,
    ref_number: PropTypes.string,
    number_porting_request_phone_numbers: PropTypes.shape({
      length: PropTypes.func,
    }),
    number_type: PropTypes.string,
    can_archive: PropTypes.bool,
    is_archived: PropTypes.bool,
    import_request_submitted_to_np_server: PropTypes.bool,
    can_cancel_all_import_flows: PropTypes.bool,
  }),
  fetchSingleNumberPortingRequest: PropTypes.func.isRequired,
  archiveNumberPortingRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  cancelAllImportFlows: PropTypes.func.isRequired,
};

function mapStateToProps({
  numberPortingRequest: numberPortingRequestReducer,
}) {
  const { isLoading, commitSuccess, numberPortingRequest, commitError } =
    numberPortingRequestReducer;

  return {
    isLoading,
    commitSuccess,
    numberPortingRequest,
    commitError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSingleNumberPortingRequest: (refNumber) => {
      dispatch(singleNumberPortingRequestFetchRequest(refNumber));
    },
    archiveNumberPortingRequest: (refNumber, callBack) => {
      dispatch(
        numberPortingRequestUpdateRequest(
          refNumber,
          { is_archived: true },
          callBack
        )
      );
    },
    cancelAllImportFlows: (refNumber, callBack) => {
      dispatch(numberPortingRequestCancelRequest(refNumber, callBack));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportDetail);
