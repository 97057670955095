import Authorization from "hoc/Authorization";

const USER_ROLES = {
  SUPER_ADMIN: "Super Admin",
  ADMIN: "Admin",
  RESELLER_ADMIN: "Reseller Admin",
  CUSTOMER_ADMIN: "Customer Admin",
  CUSTOMER_USER: "Customer User",
  PBX_ADMIN: "Pbx Admin",
  PBX_USER: "Pbx User",
};

export const ADMIN_ROLES = [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN];
export const BUSINESS_ADMIN_ROLES = [
  USER_ROLES.SUPER_ADMIN,
  USER_ROLES.ADMIN,
  USER_ROLES.CUSTOMER_ADMIN,
];
export const BUSINESS_USER_ROLES = [
  ...BUSINESS_ADMIN_ROLES,
  USER_ROLES.CUSTOMER_USER,
];
export const BUSINESS_USER_WITH_PBX_ADMIN_ROLES = [
  ...BUSINESS_USER_ROLES,
  USER_ROLES.PBX_ADMIN,
];
export const ADMIN_ROLES_WITH_PBX_ADMIN = [
  ...ADMIN_ROLES,
  USER_ROLES.PBX_ADMIN,
];
export const DASHBOARD_APP_ACCESS_ROLES = [
  ...BUSINESS_ADMIN_ROLES,
  USER_ROLES.CUSTOMER_USER,
  USER_ROLES.PBX_ADMIN,
];

export const SuperAdmin = Authorization([USER_ROLES.SUPER_ADMIN]);
export const Admins = Authorization(ADMIN_ROLES);
export const BusinessAdmins = Authorization(BUSINESS_ADMIN_ROLES);
export const BusinessUsers = Authorization(BUSINESS_USER_ROLES);
export const BusinessUsersWithPbxAdmin = Authorization(
  BUSINESS_USER_WITH_PBX_ADMIN_ROLES
);
export const AdminRolesWithPbxAdmin = Authorization(ADMIN_ROLES_WITH_PBX_ADMIN);
export const DashboardAppAccessRoles = Authorization(
  DASHBOARD_APP_ACCESS_ROLES
);

export default USER_ROLES;
