import React from "react";
import { Form } from "reactstrap";
import { Form as ReactFinalForm } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Translate } from "react-i18nify";
import PropTypes from "prop-types";

import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";
import ImportFormCreateFields from "components/NumberPortingRequest/Import/Form/ImportFormCreateFields";
import RequestNumberImportSection from "components/NumberPortingRequest/Import/Form/RequestNumberImportSection";

import { NUMBER_PORTING_REQUESTS } from "constants/constant";

const RenderImportFormCreateFields = ({ formCreateAction, formAction }) => {
  if (!formCreateAction) return null;

  return <ImportFormCreateFields formAction={formAction} />;
};

const RenderRequestNumberImportSection = ({
  formCreateAction,
  isSuperAdminFillingTheForm,
  formAction,
}) => {
  if (!formCreateAction || isSuperAdminFillingTheForm)
    return <RequestNumberImportSection formAction={formAction} />;

  return null;
};

const ImportForm = ({ onSubmit, isLoading, formAction, initialValues }) => {
  const formCreateAction = formAction === "create";

  return (
    <ReactFinalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
      render={({ pristine, submitting, handleSubmit, values }) => {
        const { person_filling_the_request_form: personFillingTheRequestForm } =
          values;

        const isSuperAdminFillingTheForm =
          personFillingTheRequestForm ===
          NUMBER_PORTING_REQUESTS.PERSON_FILLING_FORM.SUPER_ADMIN;

        return (
          <Form
            className="flexo-form px-4 py-4 pt-5 w-100"
            onSubmit={handleSubmit}
          >
            <div className="flexo-form-content">
              <RenderImportFormCreateFields
                formAction={formAction}
                formCreateAction={formCreateAction}
              />

              <RenderRequestNumberImportSection
                formAction={formAction}
                formCreateAction={formCreateAction}
                isSuperAdminFillingTheForm={isSuperAdminFillingTheForm}
              />
            </div>

            <div className="flexo-form-footer">
              <ReduxFormCommitButton
                title={<Translate value="common.save" />}
                submitting={submitting}
                pristine={pristine}
                isLoading={isLoading}
              />
            </div>
          </Form>
        );
      }}
    />
  );
};

RenderImportFormCreateFields.propTypes = {
  formCreateAction: PropTypes.string,
  formAction: PropTypes.string,
};

RenderImportFormCreateFields.defaultProps = {
  formCreateAction: "",
  formAction: "",
};

RenderRequestNumberImportSection.propTypes = {
  formCreateAction: PropTypes.string,
  formAction: PropTypes.string,
  isSuperAdminFillingTheForm: PropTypes.bool,
};

RenderRequestNumberImportSection.defaultProps = {
  formCreateAction: "",
  formAction: "",
  isSuperAdminFillingTheForm: false,
};

ImportForm.defaultProps = {
  numberPortingRequest: undefined,
  initialValues: {},
};

ImportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formAction: PropTypes.string.isRequired,
  numberPortingRequest: PropTypes.shape({}),
  isLoading: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}),
};

export default ImportForm;
