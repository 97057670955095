import React from "react";
import { Translate } from "react-i18nify";
import PropTypes from "prop-types";
import { faPhoneFlip, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { faBuffer } from "@fortawesome/free-brands-svg-icons";

import CardWithHeader from "components/Card/WithHeader/CardWithHeader";

const NUMBER_TYPES = {
  cell_phone: <Translate value="common.cellPhone" />,
  did_number: <Translate value="common.fixed" />,
};

const STATUS_LIST = ["available", "active", "qurantined", "ported_out"];

const STATUS_TYPES = {
  available: <Translate value="common.available" />,
  active: <Translate value="common.active" />,
  qurantined: <Translate value="common.quarantined" />,
  ported_out: <Translate value="common.portedOut" />,
};

const CostType = ({ cost }) => {
  if (!cost?.id) {
    return (
      <span className="cost-type free py-2 px-4">
        <Translate value="common.free" />
      </span>
    );
  }

  return (
    <span>
      ${cost.selling_price}
      <span className="cost-type gold ms-2 px-4">
        <Translate value="common.gold" />
      </span>
    </span>
  );
};

const PhoneNumberBasicDetail = ({ phoneNumber }) => {
  const {
    service_provider: { name: serviceProviderName },
    status,
    number_type: numberType,
    gold_phone_number: goldPhoneNumber,
  } = phoneNumber;

  const basicDetailContent = [
    {
      icon: faBuilding,
      title: <Translate value="attributes.owner" />,
      content: serviceProviderName,
    },
    {
      icon: faPhoneFlip,
      title: <Translate value="attributes.type" />,
      content: NUMBER_TYPES[numberType],
    },
    goldPhoneNumber?.id && {
      icon: faBuffer,
      title: <Translate value="common.cost" />,
      content: <CostType cost={goldPhoneNumber} />,
    },
    {
      icon: faUserCheck,
      title: <Translate value="attributes.status" />,
      content: STATUS_LIST.includes(status) ? STATUS_TYPES[status] : status,
    },
  ];

  return (
    <div>
      <CardWithHeader
        title={<Translate value="common.basicDetails" />}
        className="shadow-sm"
        cardContent={basicDetailContent}
      />
    </div>
  );
};

CostType.defaultProps = {
  cost: {},
};

CostType.propTypes = {
  cost: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selling_price: PropTypes.string,
  }),
};

PhoneNumberBasicDetail.propTypes = {
  phoneNumber: PropTypes.shape({
    service_provider: PropTypes.shape({ name: PropTypes.string }),
    status: PropTypes.string,
    number_type: PropTypes.string,
    gold_phone_number: PropTypes.shape({ id: PropTypes.number }),
  }).isRequired,
};

export default PhoneNumberBasicDetail;
