import {
  USER_LICENSE_FETCH_REQUEST,
  USER_LICENSE_FETCH_RECEIVED,
  USER_LICENSE_FETCH_FAILED,
  USER_LICENSE_CREATE_REQUEST,
  USER_LICENSE_CREATE_RECEIVED,
  USER_LICENSE_CREATE_FAILED,
  SINGLE_USER_LICENSE_FETCH_REQUEST,
  SINGLE_USER_LICENSE_FETCH_RECEIVED,
  SINGLE_USER_LICENSE_FETCH_FAILED,
  USER_LICENSE_UPDATE_REQUEST,
  USER_LICENSE_UPDATE_RECEIVED,
  USER_LICENSE_UPDATE_FAILED,
  USER_LICENSE_FETCH_CLEAR_ERRORS,
  USER_LICENSE_SEARCH_REQUEST,
} from "redux/actions/userLicense";

const defaultState = {
  userLicenses: [],
  commitError: "",
  fetchError: "",
  commitSuccess: false,
  isLoading: false,
  isSearching: false,
};

function userLicense(state = defaultState, action) {
  const { userLicenses, userLicense: singleUserLicense } = action;

  switch (action.type) {
    case USER_LICENSE_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitError: "",
      };

    case USER_LICENSE_FETCH_RECEIVED:
      return {
        ...state,
        userLicenses,
        isLoading: false,
      };

    case USER_LICENSE_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case SINGLE_USER_LICENSE_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitError: "",
        commitSuccess: false,
      };

    case SINGLE_USER_LICENSE_FETCH_RECEIVED:
      return {
        ...state,
        userLicense: singleUserLicense,
        isLoading: false,
      };

    case SINGLE_USER_LICENSE_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case USER_LICENSE_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case USER_LICENSE_UPDATE_RECEIVED:
      return {
        ...state,
        userLicense: singleUserLicense,
        isLoading: false,
        commitSuccess: true,
      };

    case USER_LICENSE_UPDATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case USER_LICENSE_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitSuccess: false,
        commitError: "",
      };

    case USER_LICENSE_CREATE_RECEIVED:
      return {
        ...state,
        userLicense: singleUserLicense,
        isLoading: false,
        commitSuccess: true,
      };

    case USER_LICENSE_CREATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isLoading: false,
        commitSuccess: false,
      };

    case USER_LICENSE_FETCH_CLEAR_ERRORS:
      return {
        ...state,
        fetchError: "",
        isLoading: false,
        commitSuccess: false,
        commitError: "",
      };

    case USER_LICENSE_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    default:
      return state;
  }
}

export default userLicense;
