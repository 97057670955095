import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { faHouseSignal } from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-i18nify";

import SubscriptionListTable from "components/Table/SubscriptionListTable/SubscriptionListTable";
import CardWithHeader from "components/Card/WithHeader/CardWithHeader";

const InstallationAddress = ({ address }) => {
  if (!address) return null;

  const addressJson = [
    {
      icon: faHouseSignal,
      title: <Translate value="attributes.installationAddress" />,
      content: address,
    },
  ];

  return (
    <CardWithHeader
      className="shadow-sm"
      cardContent={addressJson}
      displayHeader={false}
    />
  );
};

InstallationAddress.propTypes = {
  address: PropTypes.string.isRequired,
};

const AddOnSubscriptionsModal = ({
  title,
  secondarySubscriptions,
  modalVisible,
  onCancel,
  installationAddress,
}) => {
  if (!modalVisible) return null;

  return (
    <Modal
      isOpen={modalVisible}
      centered
      toggle={onCancel}
      className="modal-lg"
    >
      <ModalHeader className="border-0 pb-0" toggle={onCancel}>
        {title}
      </ModalHeader>
      <ModalBody className="pt-0 table-responsive">
        <InstallationAddress address={installationAddress} />

        <SubscriptionListTable
          secondarySubscriptionProducts={secondarySubscriptions}
        />
      </ModalBody>
    </Modal>
  );
};

AddOnSubscriptionsModal.defaultProps = {
  installationAddress: "",
};

AddOnSubscriptionsModal.propTypes = {
  title: PropTypes.string.isRequired,
  secondarySubscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      end_date: PropTypes.string,
      id: PropTypes.number,
      is_archived: PropTypes.bool,
      last_invoice_date: PropTypes.string,
      line_type: PropTypes.string,
      odoo_product_id: PropTypes.number,
      price: PropTypes.number,
      product_name: PropTypes.string,
      property_editable: PropTypes.bool,
      start_date: PropTypes.string,
      subscription_id: PropTypes.number,
    })
  ).isRequired,
  modalVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  installationAddress: PropTypes.string,
};

export default AddOnSubscriptionsModal;
