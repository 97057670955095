import {
  BUSINESS_FETCH_REQUEST,
  BUSINESS_FETCH_RECEIVED,
  BUSINESS_FETCH_FAILED,
  BUSINESS_FETCH_CLEAR_ERRORS,
} from "redux/actions/business";

const defaultState = {
  businesses: [],
  businessesFetchError: "",
};

function business(state = defaultState, action) {
  const { businesses } = action;

  switch (action.type) {
    case BUSINESS_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case BUSINESS_FETCH_RECEIVED:
      return {
        ...state,
        businesses,
        isLoading: false,
      };

    case BUSINESS_FETCH_FAILED:
      return {
        ...state,
        businessesFetchError: action.message,
      };

    case BUSINESS_FETCH_CLEAR_ERRORS:
      return { ...state, businessesFetchError: "" };

    default:
      return state;
  }
}

export default business;
