import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import PropTypes from "prop-types";
import { Translate, t } from "react-i18nify";

import { roleFetchRequest } from "redux/actions/role";
import { userUpdateRequest, singleUserFetchRequest } from "redux/actions/user";

import { USER } from "constants/routes";

import HasPermission from "hoc/RbacCTA";

import UserForm from "components/User/Form/UserForm";
import WholePageSpinner from "components/Spinner/WholePageSpinner";
import PageNotFound from "components/Error/PageNotFound/PageNotFound";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const UserEdit = ({
  fetchRoles,
  fetchSingleUser,
  user,
  isSingleUserFetching,
  commitSuccess,
  commitError,
  updateUser,
}) => {
  const { userID } = useParams();

  useEffect(() => {
    fetchSingleUser(userID);
  }, [userID]);

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    const { id } = user;
    let serverResponse = {};

    await updateUser(id, values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess && !commitError) {
    const { id } = user;
    const userTranslation = t("attributes.user");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.update", {
        resource: userTranslation,
      })
    );

    return <Navigate to={`${USER.addId(USER.SHOW, id)}`} />;
  }

  if (!user) {
    return <WholePageSpinner />;
  }

  const { is_active: isActive } = user;

  if (!isActive) {
    return (
      <div className="d-flex h-100 justify-content-center">
        <PageNotFound />
      </div>
    );
  }

  return (
    <HasPermission
      perform="users:edit"
      data={{
        role: user.role,
        userId: user.id,
      }}
      redirect
    >
      <div className="container-fluid">
        <div>
          <h3 className="mt-3">
            <Translate value="title.page.resource.edit" resource="User" />
          </h3>
        </div>
        <UserForm
          onSubmit={handleSubmit}
          initialValues={user}
          userID={user.id}
          isLoading={isSingleUserFetching}
        />
      </div>
    </HasPermission>
  );
};

UserEdit.defaultProps = {
  isSingleUserFetching: true,
  user: undefined,
  commitError: {},
};

UserEdit.propTypes = {
  fetchRoles: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    role_id: PropTypes.number,
    odoo_debtor_id: PropTypes.number,
    role: PropTypes.string,
    is_active: PropTypes.bool,
  }),
  fetchSingleUser: PropTypes.func.isRequired,
  isSingleUserFetching: PropTypes.bool,
  commitError: PropTypes.shape({}),
  commitSuccess: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape({
    role: PropTypes.string,
    odoo_debtor_id: PropTypes.number,
  }).isRequired,
  singleDebtor: PropTypes.shape({}).isRequired,
};

function mapStateToProps({ user: userReducer }) {
  const {
    user,
    isLoading: isSingleUserFetching,
    commitError,
    commitSuccess,
  } = userReducer;

  return {
    user,
    isSingleUserFetching,
    commitError,
    commitSuccess,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRoles: () => {
      dispatch(roleFetchRequest());
    },
    updateUser: async (id, values, callback) => {
      await dispatch(userUpdateRequest(id, values, callback));
    },
    fetchSingleUser: (id) => {
      dispatch(singleUserFetchRequest(id));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
