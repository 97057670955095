import React from "react";
import { FormGroup, Row, Col } from "reactstrap";
import { Field } from "react-final-form";
import PropTypes from "prop-types";
import { Translate, t } from "react-i18nify";
import { FieldArray } from "react-final-form-arrays";

import {
  required,
  cannotBeNegative,
  composeValidators,
  requiredForNumber,
} from "utils/validation";

import {
  renderInputField,
  renderPriceInputField,
  renderDateField,
} from "components/ReduxForm/RenderField";
import SubscriptionLineProperties from "components/Quotation/Form/ConvertToAgreement/Modal/SubscriptionLineProperties";

const MainSubscriptionSection = ({ fields, products, isProductFetching }) => {
  if (isProductFetching && !products) {
    return (
      <div>
        <Translate
          value="common.fetchingResource"
          resource={t("attributes.product")}
        />
      </div>
    );
  }

  const mainSubscriptionLineIndex = fields.value.findIndex(
    (line) => line.line_type === "subscription" && line.odoo_product_id
  );

  return (
    <>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Field
              name={`${fields.name}[${mainSubscriptionLineIndex}].product_name`}
              component={renderInputField}
              validate={required}
              placeholderTranslation="attributes.product"
              labelTranslation="attributes.product"
            />
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Field
              name={`${fields.name}[${mainSubscriptionLineIndex}].price`}
              component={renderPriceInputField}
              validate={composeValidators(requiredForNumber, cannotBeNegative)}
              placeholderTranslation="attributes.price"
              labelTranslation="attributes.price"
              type="number"
            />
          </FormGroup>
        </Col>

        <Col md={6}>
          <Field
            name={`${fields.name}[${mainSubscriptionLineIndex}].start_date`}
            component={renderDateField}
            labelTranslation="attributes.startDate"
            placeholderTranslation="attributes.startDate"
            validate={required}
            inputClassName="form-control"
            readOnly={false}
            datePickerClassNames="position-absolute top-0 start-50 translate-middle-x bg-light"
          />
        </Col>

        <FieldArray
          name={`${fields.name}[${mainSubscriptionLineIndex}].subscription_line_properties`}
          component={SubscriptionLineProperties}
          lineType="subscription"
        />
      </Row>
    </>
  );
};

MainSubscriptionSection.defaultProps = {};

MainSubscriptionSection.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func,
    push: PropTypes.func,
    pop: PropTypes.func,
    remove: PropTypes.func,
    length: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
  }).isRequired,
  isProductFetching: PropTypes.bool.isRequired,
};

export default MainSubscriptionSection;
