import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import ForbiddenContent from "components/Auth/ForbiddenContent";

import { AUTH } from "constants/routes";
import STATUS from "constants/statusCode";

import isEmpty from "utils/isEmpty";
import updateStatusReducer, { REDUCER_TYPE } from "utils/updateReducer";

import { logoutRequest } from "redux/actions/auth";

const Forbidden = ({ hideNavigation }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const { currentUser, statusCode } = useSelector(({ auth }) => auth);

  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (statusCode) {
      setStatus(statusCode);
      dispatch(logoutRequest());
    }
  }, [statusCode]);

  const handleNavigation = () => {
    dispatch(logoutRequest());
    navigate(AUTH.LOGIN);
  };

  useEffect(() => {
    dispatch(updateStatusReducer(null, REDUCER_TYPE.AUTH));
  }, []);

  if (status !== STATUS.FORBIDDEN && statusCode !== STATUS.FORBIDDEN) {
    return <Navigate to={AUTH.LOGIN} />;
  }

  if (!isEmpty(currentUser) && location.pathname === AUTH.FORBIDDEN) {
    return navigate(-1);
  }

  return (
    <ForbiddenContent
      hideNavigation={hideNavigation}
      handleNavigation={handleNavigation}
    />
  );
};

Forbidden.defaultProps = {
  hideNavigation: false,
};

Forbidden.propTypes = {
  hideNavigation: PropTypes.bool,
};

export default Forbidden;
