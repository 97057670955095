import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Translate, t } from "react-i18nify";

import {
  sipDeviceCreateRequest,
  sipDeviceFetchClearErrors,
} from "redux/actions/sipDevices";

import FORM from "constants/form";
import { SIP_DEVICES } from "constants/routes";

import SipDeviceForm from "components/SipDevices/Form/SipDeviceForm";
import WholePageSpinner from "components/Spinner/WholePageSpinner";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const SipDeviceFormContent = ({
  createSipDevice,
  commitSuccess,
  isLoading,
  sipDevice,
  initialValues,
  parentType,
  clearError,
  userLicenseID,
}) => {
  useEffect(
    () => () => {
      clearError();
    },
    []
  );

  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    await createSipDevice(userLicenseID, values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess) {
    const { id } = sipDevice;

    const desktopPhoneTranslation = t("common.desktopPhone");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.create", {
        resource: desktopPhoneTranslation,
      })
    );

    return <Navigate to={`${SIP_DEVICES.addId(SIP_DEVICES.SHOW, id)}`} />;
  }

  if (isLoading) {
    return <WholePageSpinner />;
  }

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.create"
            resource={t("common.desktopPhone")}
          />
        </h3>
      </div>
      <SipDeviceForm
        onSubmit={handleSubmit}
        formName={FORM.SIP_DEVICE_FORM}
        formType="create"
        isLoading={isLoading}
        initialValues={{ ...initialValues }}
        parentType={parentType}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { sipDevice, isLoading, commitSuccess, commitError } = state.sipDevice;

  return {
    sipDevice,
    isLoading,
    commitSuccess,
    commitError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearError: () => {
    dispatch(sipDeviceFetchClearErrors());
  },
  createSipDevice: async (userLicenseId, attributes, cb) => {
    await dispatch(sipDeviceCreateRequest(userLicenseId, attributes, cb));
  },
});

SipDeviceFormContent.defaultProps = {
  sipDevice: undefined,
  commitError: {},
  initialValues: {},
  userLicenseID: null,
};

SipDeviceFormContent.propTypes = {
  sipDevice: PropTypes.shape({
    id: PropTypes.number,
  }),
  isLoading: PropTypes.bool.isRequired,
  commitSuccess: PropTypes.bool.isRequired,
  commitError: PropTypes.shape({}),
  createSipDevice: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  parentType: PropTypes.string.isRequired,
  clearError: PropTypes.func.isRequired,
  userLicenseID: PropTypes.number,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SipDeviceFormContent);
