import React from "react";
import { FormGroup, Row, Col } from "reactstrap";
import { Field } from "react-final-form";
import PropTypes from "prop-types";

import { required } from "utils/validation";

import { renderDropdownField } from "components/ReduxForm/RenderField";
import { formatDropdownOptions } from "utils/utils";

const ProviderMultiSelectField = ({
  providers,
  isProviderLoading,
  isAdmin,
}) => {
  if (!isAdmin) {
    return null;
  }

  const formattedProviders = formatDropdownOptions(providers);

  return (
    <Row>
      <Col md="6">
        <FormGroup>
          <Field
            name="provider_ids"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={formattedProviders}
            labelTranslation="attributes.providers"
            isMultiSelect
            isLoading={isProviderLoading}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

ProviderMultiSelectField.defaultProps = {
  providers: [],
  isAdmin: false,
};

ProviderMultiSelectField.propTypes = {
  providers: PropTypes.arrayOf(PropTypes.shape()),
  isProviderLoading: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool,
};

export default ProviderMultiSelectField;
