import React from "react";
import Skeleton from "react-loading-skeleton";

const DebtorsSkeletion = () => {
  return (
    <div className="bg-white rounded shadow-sm d-flex flex-column justify-content-between h-100">
      <div className="p-3 border-bottom">
        <Skeleton height={18} width={120} />
      </div>
      <div className="p-3 d-flex flex-column align-items-center justify-content-center h-100">
        <Skeleton circle height={100} width={100} className="mb-2" />
        <Skeleton height={40} width={80} className="mb-2" />
        <Skeleton height={18} width={100} className="mb-4" />
        <Skeleton height={18} width={200} />
      </div>
    </div>
  );
};

export default DebtorsSkeletion;
