import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Column } from "react-virtualized";

import { Translate } from "react-i18nify";

import { dashboardCellPhoneImportRequestFetchRequest } from "redux/actions/dashboard/cellPhoneImportRequest";

import DashboardComponent from "components/Dashboard/DashboardComponent/DashboardComponent";
import TranslateDate from "components/i18n/TranslateDate";
import DashboardComponentTable from "components/Table/DashboardComponent/DashboardComponentTable";

const renderCellPhoneImportRequestTranslateDate = ({ cellData }) => (
  <TranslateDate date={cellData} />
);

const CellPhoneImportRequestView = ({
  fetchCellPhoneImportRequest,
  cellPhoneImportRequests,
  isLoading,
  fetchError,
}) => {
  useEffect(() => {
    fetchCellPhoneImportRequest();
  }, [fetchCellPhoneImportRequest]);

  return (
    <DashboardComponent
      translationTitle="title.dashboardComponent.cellPhoneImportRequests"
      isLoading={isLoading || !cellPhoneImportRequests}
      fetchError={fetchError}
      onRetry={fetchCellPhoneImportRequest}
      columnSize={{ lg: 8, md: 12 }}
      height="30em"
    >
      <DashboardComponentTable
        records={cellPhoneImportRequests}
        resourceName="number_porting_requests/imports"
        recordDetailIDAttribute="ref_number"
      >
        <Column
          width={120}
          label={<Translate value="attributes.flowID" />}
          dataKey="ref_number"
        />
        <Column
          width={150}
          label={<Translate value="attributes.phone" />}
          dataKey="service_number"
        />
        <Column
          width={200}
          label={<Translate value="attributes.requestedPortingDate" />}
          dataKey="requested_porting_date"
          cellRenderer={renderCellPhoneImportRequestTranslateDate}
        />
        <Column
          width={130}
          label={<Translate value="attributes.status" />}
          dataKey="import_status"
        />
      </DashboardComponentTable>
    </DashboardComponent>
  );
};

CellPhoneImportRequestView.defaultProps = {
  fetchCellPhoneImportRequest: () => {},
  cellPhoneImportRequests: null,
  isLoading: false,
  fetchError: false,
};

CellPhoneImportRequestView.propTypes = {
  fetchCellPhoneImportRequest: PropTypes.func,
  cellPhoneImportRequests: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  fetchError: PropTypes.bool,
};

const mapStateToProps = ({ dashboard: { cellPhoneImportRequest } }) => ({
  ...cellPhoneImportRequest,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCellPhoneImportRequest: () =>
    dispatch(dashboardCellPhoneImportRequestFetchRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CellPhoneImportRequestView);
