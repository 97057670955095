/* eslint-disable import/no-cycle */
import {
  AGREEMENT_CREDIT_NOTE_FETCH_REQUEST,
  AGREEMENT_CREDIT_NOTE_FETCH_RECEIVED,
  AGREEMENT_CREDIT_NOTE_FETCH_FAILED,
  AGREEMENT_CREDIT_NOTE_SEARCH_REQUEST,
} from "redux/actions/agreement/creditNote";

const initialState = {
  creditNotes: [],
  isLoading: false,
  fetchError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AGREEMENT_CREDIT_NOTE_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: false,
      };

    case AGREEMENT_CREDIT_NOTE_FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        fetchError: true,
      };

    case AGREEMENT_CREDIT_NOTE_FETCH_RECEIVED:
      return {
        ...state,
        isLoading: false,
        fetchError: false,
        creditNotes: action.data,
      };

    case AGREEMENT_CREDIT_NOTE_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    default:
      return state;
  }
};
