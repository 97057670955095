import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";
import { Field } from "react-final-form";

import { required } from "utils/validation";

import { renderInputField } from "components/ReduxForm/RenderField";

const ProductNameWithLabel = ({ fieldName, isSetup }) => {
  if (!isSetup) {
    return (
      <Field
        name={fieldName}
        component={renderInputField}
        validate={required}
        placeholderTranslation="attributes.product"
      />
    );
  }

  return (
    <div className="d-flex align-items-center justify-content-center editable-product-name-with-label">
      <Field
        name={fieldName}
        component={renderInputField}
        validate={required}
        placeholderTranslation="attributes.product"
      />
      <span className="pill status bg-green px-3 ms-2">
        <Translate value="pill.status.setup" />
      </span>
    </div>
  );
};

ProductNameWithLabel.defaultProps = {
  isSetup: false,
};

ProductNameWithLabel.propTypes = {
  isSetup: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
};

export default ProductNameWithLabel;
