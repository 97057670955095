import React from "react";
import { Translate } from "react-i18nify";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const SubscriptionDetailHeader = ({ showTitle, isProductAdded }) => {
  if (!showTitle) {
    return (
      <div className="subscription-detail d-flex justify-content-center w-100 py-3">
        <p className="mb-0 d-inline-block">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="d-block mx-auto text-danger mb-2"
            size="2x"
          />
          <Translate value="cellPhone.pleaseSelectASubscriptionProductFirst" />
        </p>
      </div>
    );
  }

  if (showTitle && !isProductAdded) {
    return (
      <p className="subscription-detail mb-0 fw-bold py-3 px-2">
        <Translate value="cellPhone.addOnSubscriptions" />
      </p>
    );
  }

  return (
    <tr className="w-100 subscription-detail add-on-detail">
      <th className="py-3">
        <p className="mb-0 fw-bold">
          <Translate value="attributes.product" />
        </p>
      </th>
      <th className="py-3">
        <p className="mb-0 text-center fw-bold">
          <Translate value="attributes.price" />
        </p>
      </th>
      <th className="py-3 pe-4">
        <p className="mb-0 text-end fw-bold">
          <Translate value="common.action" />
        </p>
      </th>
    </tr>
  );
};

SubscriptionDetailHeader.defaultProps = {
  showTitle: false,
  isProductAdded: false,
};

SubscriptionDetailHeader.propTypes = {
  showTitle: PropTypes.bool,
  isProductAdded: PropTypes.bool,
};

export default SubscriptionDetailHeader;
