import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Translate } from "react-i18nify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { setLanguage } from "services/i18n";

import { getCurrentLocale } from "utils/selectors";
import { switchUserLanguageRequest } from "redux/actions/user";

const languageType = {
  "en-US": "en",
  "da-DK": "da",
};

const LanguageToggler = ({ currentLocale, userID, switchUserLanguage }) => {
  const handleLanguageToggle = (lang) => {
    setLanguage(lang);
    switchUserLanguage(userID, languageType[lang]);
  };

  return (
    <div className="w-100">
      <UncontrolledDropdown direction="up" className="language-toggler py-2">
        <DropdownToggle
          className="language-toggler-btn"
          data-cy="language-toggler-btn"
        >
          <FontAwesomeIcon icon={faLanguage} />
          <p className="m-0 d-flex align-items-center text-white">
            <Translate value={`enum.locale.${currentLocale}`} />
            <FontAwesomeIcon icon={faCaretUp} className="caret-icon ms-2" />
          </p>
        </DropdownToggle>
        <div className="sidebar-language-toggler">
          <DropdownMenu className="language-dropdown p-2">
            <DropdownItem
              className="d-flex flex-column p-2"
              onClick={() => handleLanguageToggle("en-US")}
              data-cy="en-US"
            >
              <Translate value="enum.locale.en-US" />
            </DropdownItem>
            <DropdownItem
              className="d-flex flex-column p-2"
              onClick={() => handleLanguageToggle("da-DK")}
            >
              <Translate value="enum.locale.da-DK" />
            </DropdownItem>
          </DropdownMenu>
        </div>
      </UncontrolledDropdown>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    auth: { currentUser },
  } = state;
  const currentLocale = getCurrentLocale(state);

  return {
    currentLocale,
    userID: currentUser?.id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  switchUserLanguage: (id, language) =>
    dispatch(switchUserLanguageRequest(id, language)),
});

LanguageToggler.defaultProps = {
  currentLocale: "en_us",
  userID: "",
  switchUserLanguage: () => {},
};

LanguageToggler.propTypes = {
  currentLocale: PropTypes.string,
  userID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  switchUserLanguage: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageToggler);
