import { createSearchAction } from "redux-search";

import { DebtorApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";
import displayUnMappedFormErrors from "utils/displayUnMappedFormErrors";

export const DEBTOR_FETCH_REQUEST = "DEBTOR_FETCH_REQUEST";
export const DEBTOR_FETCH_RECEIVED = "DEBTOR_FETCH_RECEIVED";
export const DEBTOR_FETCH_FAILED = "DEBTOR_FETCH_FAILED";
export const SINGLE_DEBTOR_FETCH_REQUEST = "SINGLE_DEBTOR_FETCH_REQUEST";
export const SINGLE_DEBTOR_FETCH_RECEIVED = "SINGLE_DEBTOR_FETCH_RECEIVED";
export const SINGLE_DEBTOR_FETCH_FAILED = "SINGLE_DEBTOR_FETCH_FAILED";
export const DEBTOR_BY_CVR_FETCH_REQUEST = "DEBTOR_BY_CVR_FETCH_REQUEST";
export const DEBTOR_UPDATE_REQUEST = "DEBTOR_UPDATE_REQUEST";
export const DEBTOR_UPDATE_RECEIVED = "DEBTOR_UPDATE_RECEIVED";
export const DEBTOR_UPDATE_FAILED = "DEBTOR_UPDATE_FAILED";
export const DEBTOR_CREATE_REQUEST = "DEBTOR_CREATE_REQUEST";
export const DEBTOR_CREATE_RECEIVED = "DEBTOR_CREATE_RECEIVED";
export const DEBTOR_CREATE_FAILED = "DEBTOR_CREATE_FAILED";
export const DEBTOR_FETCH_CLEAR_ERRORS = "DEBTOR_FETCH_CLEAR_ERRORS";
export const DEBTOR_SEARCH_REQUEST = "DEBTOR_SEARCH_REQUEST";
export const FETCH_DEBTOR_LANGUAGE_REQUEST = "FETCH_DEBTOR_LANGUAGE_REQUEST";
export const FETCH_DEBTOR_LANGUAGE_RECEIVED = "FETCH_DEBTOR_LANGUAGE_RECEIVED";
export const FETCH_DEBTOR_LANGUAGE_FAILED = "FETCH_DEBTOR_LANGUAGE_FAILED";

export const searchDebtors = createSearchAction("debtors");

export function debtorFetchReceived(response) {
  return {
    type: DEBTOR_FETCH_RECEIVED,
    debtors: response.data,
  };
}

export function debtorFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: DEBTOR_FETCH_FAILED,
    message,
  };
}

export function debtorFetchClearErrors() {
  return {
    type: DEBTOR_FETCH_CLEAR_ERRORS,
  };
}

// Fetch all debtors
export const debtorFetchRequest =
  (withProspects = false) =>
  (dispatch) => {
    dispatch({ type: DEBTOR_FETCH_REQUEST });

    DebtorApi.fetchAll(withProspects)
      .then((data) => dispatch(debtorFetchReceived(data)))
      .catch((errors) => {
        dispatch(debtorFetchFailed(errors));
      });
  };

// Fetch single debtor
export function singleDebtorFetchReceived(response) {
  return {
    type: SINGLE_DEBTOR_FETCH_RECEIVED,
    debtor: response.data,
  };
}

export function singleDebtorFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: SINGLE_DEBTOR_FETCH_FAILED,
    message,
  };
}

export const singleDebtorFetchRequest = (id) => (dispatch) => {
  dispatch({ type: SINGLE_DEBTOR_FETCH_REQUEST });

  DebtorApi.fetchOne(id)
    .then((data) => dispatch(singleDebtorFetchReceived(data)))
    .catch((errors) => {
      dispatch(singleDebtorFetchFailed(errors));
    });
};

// Fetch debtor by cvr number or debtor name using cvr api
export const debtorByCVRFetchRequest = (query) => (dispatch) => {
  dispatch({ type: DEBTOR_BY_CVR_FETCH_REQUEST });

  DebtorApi.fetchByCVR(query)
    .then((data) => dispatch(debtorFetchReceived(data)))
    .catch((errors) => {
      dispatch(debtorFetchFailed(errors));
    });
};

// Update a debtor
export function debtorUpdateReceived(response) {
  return {
    type: DEBTOR_UPDATE_RECEIVED,
    debtor: response.data,
  };
}

export function debtorUpdateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response.data) {
    displayUnMappedFormErrors(response);
    callback(response.data);
  }

  return {
    type: DEBTOR_UPDATE_FAILED,
    message: response.data,
  };
}

export const debtorUpdateRequest =
  (id, attributes, callback) => async (dispatch) => {
    dispatch({ type: DEBTOR_UPDATE_REQUEST });

    await DebtorApi.update(id, attributes)
      .then((data) => {
        dispatch(debtorUpdateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(debtorUpdateFailed(errors, callback));
      });
  };

// Create a debtor
export function debtorCreateReceived(response) {
  return {
    type: DEBTOR_CREATE_RECEIVED,
    debtor: response.data,
  };
}

export function debtorCreateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: DEBTOR_CREATE_FAILED,
    message: response.data,
  };
}

export function debtorSearchRequest(value) {
  const isSearching = Boolean(value);

  return {
    type: DEBTOR_SEARCH_REQUEST,
    isSearching,
  };
}

export const debtorCreateRequest =
  (attributes, callback) => async (dispatch) => {
    dispatch({ type: DEBTOR_CREATE_REQUEST });

    await DebtorApi.create(attributes)
      .then((data) => {
        dispatch(debtorCreateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(debtorCreateFailed(errors, callback));
      });
  };

export const fetchDebtorLanguageReceived = (response) => ({
  type: FETCH_DEBTOR_LANGUAGE_RECEIVED,
  language: response.data,
});

export const fetchDebtorLanguageFailed = (errorData) => {
  const { response } = errorData;

  if (response.data.status) {
    getAndDisplayErrors(errorData);
  }

  return {
    type: DEBTOR_UPDATE_FAILED,
    message: response.data,
  };
};

// fetch language for debtor
export const fetchDebtorLanguageRequest = (id) => (dispatch) => {
  dispatch({ type: FETCH_DEBTOR_LANGUAGE_REQUEST });

  DebtorApi.language(id)
    .then((data) => dispatch(fetchDebtorLanguageReceived(data)))
    .catch((errors) => {
      dispatch(fetchDebtorLanguageFailed(errors));
    });
};
