import React from "react";
import { Progress } from "reactstrap";
import PropTypes from "prop-types";

const ProgressBar = ({ value, label }) => {
  const progressColor = (progressValue) => {
    if (progressValue < 59) return "";

    if (progressValue < 90) return "warning";

    return "danger";
  };

  return (
    <Progress
      value={value}
      color={progressColor(value)}
      className="subscription-progress-bar"
    >
      {label || value}%
    </Progress>
  );
};

ProgressBar.defaultProps = {
  label: undefined,
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ProgressBar;
