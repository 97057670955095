import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, Row, FormGroup } from "reactstrap";
import { Translate } from "react-i18nify";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";

import SearchableDropdown from "components/ReduxForm/SearchableDropdown/SearchableDropdown";
import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";

import {
  clearSimCardErrors,
  searchICCIDRangesRequest,
} from "redux/actions/simCard";
import { assignNewSimCardRequest } from "redux/actions/cellPhone";

import { composeValidators, number, required } from "utils/validation";

const AssignNewSimCardModal = ({
  modalVisible,
  onCancel,
  searchIccidNumbers,
  clearError,
  iccidNumbers,
  isSearching,
  assignNewSimCard,
  isSubmitting,
  cellPhoneID,
}) => {
  const handleSearchICCIDNumber = (value) => {
    searchIccidNumbers(value);
  };

  useEffect(() => () => clearError(), []);

  const searchResult = iccidNumbers.map(
    ({ iccid_number: iccidNumber, id }) => ({
      value: id,
      label: iccidNumber,
    })
  );

  const onSubmit = (values) => {
    const attributes = {
      cell_phone: {
        ...values,
        active_sim_card_id:
          values.cell_phone_sim_cards_attributes[0].sim_card_id,
      },
    };

    assignNewSimCard(cellPhoneID, attributes, () => {
      onCancel();
    });
  };

  if (!modalVisible) return null;

  return (
    <Modal isOpen={modalVisible} toggle={onCancel} className="p-1">
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, pristine }) => (
          <form
            className="flexo-form w-100"
            data-testid="assign-sim-card-form"
            onSubmit={handleSubmit}
          >
            <ModalBody className="pt-0">
              <p className="h4 fw-bold pt-3">
                <Translate value="common.assignNewSimCard" />
              </p>
              <p className="assign-sim-card py-2 mb-0">
                <Translate value="cellPhone.chooseICCIDNumberToAssignTheUser" />
              </p>
              <Row>
                <FormGroup>
                  <Field
                    name="cell_phone_sim_cards_attributes[0].sim_card_id"
                    component={SearchableDropdown}
                    validate={composeValidators(required, number)}
                    placeholderTranslation="attributes.selectICCIDNumber"
                    onChangeCb={(e) => handleSearchICCIDNumber(e)}
                    searchResult={searchResult}
                    isLoading={isSearching}
                  />
                </FormGroup>
              </Row>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <button
                className=" btn btn-outline-secondary"
                type="button"
                onClick={onCancel}
              >
                <Translate value="common.cancel" />
              </button>
              <ReduxFormCommitButton
                title={<Translate value="common.assign" />}
                submitting={submitting}
                pristine={pristine}
                isLoading={isSubmitting}
                submitClassName="btn-primary"
              />
            </ModalFooter>
          </form>
        )}
      </Form>
    </Modal>
  );
};

AssignNewSimCardModal.defaultProps = {
  isSearching: false,
  isSubmitting: false,
};

AssignNewSimCardModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  searchIccidNumbers: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  iccidNumbers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSearching: PropTypes.bool,
  assignNewSimCard: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  cellPhoneID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

const mapStateToProps = ({ simCard, cellPhone }) => {
  const { iccidNumbers, isSearching } = simCard;
  const { isSubmitting } = cellPhone;

  return {
    iccidNumbers,
    isSearching,
    isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchIccidNumbers: (startNumber) => {
    dispatch(searchICCIDRangesRequest(startNumber, null));
  },
  clearError: () => {
    dispatch(clearSimCardErrors());
  },
  assignNewSimCard: (cellNumberID, attributes, cb) => {
    dispatch(assignNewSimCardRequest(cellNumberID, attributes, cb));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignNewSimCardModal);
