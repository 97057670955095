import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.odooEndPoint()}/debtor_types`,
};

class DebtorTypeApi extends AuthenticatedRequest {
  // Fetch all debtor types
  static fetchAll() {
    return this.get(URLS.INDEX);
  }
}

export default DebtorTypeApi;
