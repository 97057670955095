import React from "react";
import { Form as ReactFinalForm } from "react-final-form";
import { Form } from "reactstrap";
import arrayMutators from "final-form-arrays";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";
import { FieldArray } from "react-final-form-arrays";

import BasicInfoSection from "components/Quotation/Form/ConvertToAgreement/BasicInfoSection";
import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";
import SubscriptionSection from "components/Quotation/Form/ConvertToAgreement/Subscription/SubscriptionSection";

const ConvertToAgreementForm = ({
  isLoading,
  paymentTerms,
  debtors,
  isDebtorFetching,
  isPaymentTermFetching,
  initialValues,
  onSubmit,
}) => (
  <ReactFinalForm
    onSubmit={onSubmit}
    mutators={{
      ...arrayMutators,
    }}
    initialValues={initialValues}
    keepDirtyOnReinitialize
    render={({ pristine, submitting, handleSubmit }) => (
      <Form className="flexo-form px-4 py-4 pt-5 w-100" onSubmit={handleSubmit}>
        <div className="flexo-form-content">
          <BasicInfoSection
            debtors={debtors}
            paymentTerms={paymentTerms}
            isDebtorFetching={isDebtorFetching}
            isPaymentTermFetching={isPaymentTermFetching}
          />
          <FieldArray
            name="agreement.subscriptions"
            component={SubscriptionSection}
          />
        </div>
        <div className="flexo-form-footer">
          <ReduxFormCommitButton
            title={<Translate value="common.save" />}
            isLoading={isLoading}
            submitting={submitting}
            pristine={pristine}
          />
        </div>
      </Form>
    )}
  />
);

ConvertToAgreementForm.defaultProps = {
  isDebtorFetching: false,
  isPaymentTermFetching: false,
};

ConvertToAgreementForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  paymentTerms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  debtors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDebtorFetching: PropTypes.bool,
  isPaymentTermFetching: PropTypes.bool,
  initialValues: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ConvertToAgreementForm;
