import React, {
  Component,
  isValidElement,
  Children,
  cloneElement,
} from "react";
import PropTypes from "prop-types";
import { Table, SortDirection } from "react-virtualized";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAmountDownAlt,
  faSortAmountDown,
} from "@fortawesome/free-solid-svg-icons";

const headerRenderer = ({ sortBy, label, sortDirection, dataKey }) => {
  const showSortIndicator = sortBy === dataKey;
  const icons = {
    ASC: faSortAmountDownAlt,
    DESC: faSortAmountDown,
  };

  return (
    <>
      <span className="me-1">{label}</span>
      {showSortIndicator && <FontAwesomeIcon icon={icons[sortDirection]} />}
    </>
  );
};

headerRenderer.defaultProps = {
  sortBy: null,
  label: null,
  sortDirection: null,
  dataKey: null,
};

headerRenderer.propTypes = {
  sortBy: PropTypes.string,
  label: PropTypes.node,
  sortDirection: PropTypes.string,
  dataKey: PropTypes.string,
};

class SortableTable extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      list: [...props.records],
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { records } = this.props;

    if (records.length !== nextProps.records.length) {
      const list = [...nextProps.records];

      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({ list });
    }

    const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;

    if (
      nextState.sortBy !== prevSortBy ||
      nextState.sortDirection !== prevSortDirection
    ) {
      this.reSortList(nextState);
    }
  }

  sort = ({ sortBy, sortDirection }) => {
    const { sortDirection: prevSortDirection } = this.state;
    let nextSortBy = sortBy;
    let nextSortDirection = sortDirection;

    // If list was sorted DESC by this column.
    // Rather than switch to ASC, return to "natural" order.
    if (prevSortDirection === SortDirection.DESC) {
      nextSortBy = null;
      nextSortDirection = null;
    }

    this.setState({ sortBy: nextSortBy, sortDirection: nextSortDirection });
  };

  reSortList(nextState) {
    const { sortBy, sortDirection } = nextState;
    const { records } = this.props;
    const list = [...records];

    if (sortBy) {
      list.sort((a, b) => {
        if (typeof a[sortBy] === "string")
          return a[sortBy].localeCompare(b[sortBy]);

        return a[sortBy] - b[sortBy];
      });
      if (sortDirection === SortDirection.DESC) list.reverse();
    }
    this.setState({ list });
  }

  render() {
    const { children, ...rest } = this.props;
    const { list, sortBy, sortDirection } = this.state;

    const columnChildren = Children.map(children, (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, { headerRenderer });
      }

      return child;
    });

    return (
      <Table
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        sort={this.sort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        rowCount={list.length}
        rowGetter={({ index }) => list[index]}
        className="sortable-table"
        rowHeight={40}
        rowClassName="py-1"
      >
        {columnChildren}
      </Table>
    );
  }
}

SortableTable.defaultProps = {
  records: [],
};

SortableTable.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape()),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SortableTable;
