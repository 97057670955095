import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { Translate } from "react-i18nify";

import CancelButton from "components/NumberPortingRequest/Detail/CancelButton";

const CancelSingleImportFlowModal = ({
  resourceIdentifier,
  cancelPortingFlow,
  resourceID,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <CancelButton handleButtonClick={() => setModalOpen(true)} />

      <Modal isOpen={modalOpen} toggle={closeModal}>
        <ModalHeader>{resourceIdentifier}</ModalHeader>

        <ModalBody>
          <Translate value="numberPortingRequest.requestCancelModal.cancelRequest" />

          <p className="text-danger pt-3">
            <Translate value="modal.delete.irreversible" />
          </p>
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={() => cancelPortingFlow(resourceID, closeModal)}
          >
            <Translate value="common.confirm" />
          </Button>

          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={closeModal}
          >
            <Translate value="common.cancel" />
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

CancelSingleImportFlowModal.propTypes = {
  resourceIdentifier: PropTypes.string.isRequired,
  cancelPortingFlow: PropTypes.func.isRequired,
  resourceID: PropTypes.number.isRequired,
};

export default CancelSingleImportFlowModal;
