export const LOAD_TRANSLATIONS = "@@react-i18nify/LOAD_TRANSLATIONS";
export const SET_LOCALE = "@@react-i18nify/SET_LOCALE";

export const loadTranslations = (translations) => (dispatch) => {
  dispatch({
    type: LOAD_TRANSLATIONS,
    translations,
  });
};

export const setAppLocale = (locale) => (dispatch) => {
  dispatch({
    type: SET_LOCALE,
    locale,
  });
};
