import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchMinus } from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-i18nify";

const PageNotFound = () => {
  return (
    <div className="d-flex page-not-found">
      <FontAwesomeIcon icon={faSearchMinus} className="mb-4" />
      <h2 className="mb-2">
        <Translate value="common.notFound" />
      </h2>
    </div>
  );
};

export default PageNotFound;
