import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Translate } from "react-i18nify";

const DeleteModal = ({
  resourceName,
  modalData,
  modalVisible,
  onConfirm,
  onCancel,
}) => {
  if (!modalVisible) return null;

  return (
    <Modal isOpen={modalVisible} toggle={onCancel}>
      <ModalHeader className="border-0 pb-0" toggle={onCancel} />
      <ModalBody className="pt-0">
        <Translate value="modal.delete.resource" resource={resourceName} />{" "}
        <p className="text-danger pt-3">
          <Translate value="modal.delete.irreversible" />
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => onConfirm(modalData)}
          data-cy="confirm-deletion"
        >
          <Translate value="common.confirm" />
        </Button>
        <button
          className=" btn btn-outline-secondary"
          type="button"
          onClick={onCancel}
          data-cy="cancel-deletion"
        >
          <Translate value="common.cancel" />
        </button>
      </ModalFooter>
    </Modal>
  );
};

DeleteModal.propTypes = {
  modalData: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  modalVisible: PropTypes.bool.isRequired,
  resourceName: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DeleteModal;
