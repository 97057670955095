import React from "react";
import HeaderSkeleton from "components/Skeleton/HeaderSkeleton";

import TabTableSkeleton from "../TabTableSkeleton";
import ResourceListSkeleton from "../ResourceList/ResourceListSkeleton";

const NumberPortingRequestImportDetailSkeleton = () => (
  <>
    <div className="container-fluid">
      <HeaderSkeleton />

      <TabTableSkeleton />
    </div>

    <ResourceListSkeleton />
  </>
);

export default NumberPortingRequestImportDetailSkeleton;
