import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import OtherProductsTable from "components/Quotation/Form/ConvertToAgreement/Modal/OtherProductsTable";

const OtherProductsSection = ({ fields, products, subscriptionField }) => {
  const {
    input: {
      value: { _destroy: isDeleted },
    },
  } = subscriptionField;

  if (isDeleted) {
    return null;
  }

  if (fields.length === 0) {
    return (
      <div className="subscription-detail d-flex justify-content-center w-100 py-5">
        <p className="mb-0 d-inline-block">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="d-block mx-auto text-danger mb-2"
            size="2x"
          />
          <Translate value="quotation.pleaseSelectAMainSubscriptionFirst" />
        </p>
      </div>
    );
  }

  return <OtherProductsTable fields={fields} products={products} />;
};

OtherProductsSection.defaultProps = {};

OtherProductsSection.propTypes = {
  fields: PropTypes.shape({
    remove: PropTypes.func,
    push: PropTypes.func,
    pop: PropTypes.func,
    length: PropTypes.func,
    insert: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      context: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      context_type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  subscriptionField: PropTypes.shape({
    input: PropTypes.shape({
      value: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default OtherProductsSection;
