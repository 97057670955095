import { createSearchAction } from "redux-search";

import { CellPhoneApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

import { DEFAULT_FILTER } from "constants/filters";

export const USER_LICENSE_CELL_PHONE_FETCH_REQUEST =
  "USER_LICENSE_CELL_PHONE_FETCH_REQUEST";
export const USER_LICENSE_CELL_PHONE_FETCH_RECEIVED =
  "USER_LICENSE_CELL_PHONE_FETCH_RECEIVED";
export const USER_LICENSE_CELL_PHONE_FETCH_FAILED =
  "USER_LICENSE_CELL_PHONE_FETCH_FAILED";
export const USER_LICENSE_CELL_PHONE_SEARCH_REQUEST =
  "USER_LICENSE_CELL_PHONE_SEARCH_REQUEST";

export function cellPhoneFetchReceived(response) {
  return {
    type: USER_LICENSE_CELL_PHONE_FETCH_RECEIVED,
    cellPhones: response.data,
  };
}

export function cellPhoneFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: USER_LICENSE_CELL_PHONE_FETCH_FAILED,
    message,
  };
}

// Fetch all cellPhones
export const cellPhoneFetchRequest =
  (userLicenseID, filter = DEFAULT_FILTER) =>
  (dispatch) => {
    dispatch({ type: USER_LICENSE_CELL_PHONE_FETCH_REQUEST });

    CellPhoneApi.fetchAll(userLicenseID, filter, "userLicense")
      .then((data) => dispatch(cellPhoneFetchReceived(data)))
      .catch((e) => {
        dispatch(cellPhoneFetchFailed(e));
      });
  };

// Search Cell Phone
export const searchCellPhones = createSearchAction("userLicenseCellPhones");

export const cellPhoneSearchRequest = (value) => {
  const isSearching = Boolean(value);

  return {
    type: USER_LICENSE_CELL_PHONE_SEARCH_REQUEST,
    isSearching,
  };
};
