import React from "react";
import { Route, Routes } from "react-router-dom";

import { Admins } from "constants/userRoles";

import SubscriptionProductEdit from "views/SubscriptionProduct/SubscriptionProductEdit";
import SubscriptionProductCreate from "views/SubscriptionProduct/SubscriptionProductCreate";
import {
  EDIT,
  NEW,
  SUBSCRIPTION_PRODUCT,
} from "constants/routes/routesResources";

const AdminSubscriptionProductEdit = Admins(SubscriptionProductEdit);
const AdminSubscriptionProductCreate = Admins(SubscriptionProductCreate);

const SubscriptionProductRoutes = () => (
  <Routes>
    <Route path={NEW} element={<AdminSubscriptionProductCreate />} />
    <Route path={SUBSCRIPTION_PRODUCT.SUBSCRIPTION_PRODUCT_ID}>
      <Route path={EDIT} element={<AdminSubscriptionProductEdit />} />
    </Route>
  </Routes>
);

export default SubscriptionProductRoutes;
