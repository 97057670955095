import React from "react";
import { Translate } from "react-i18nify";
import PropTypes from "prop-types";

import { SUBSCRIPTION_LINE_TYPE } from "constants/subscriptionLine";

import ProductNameWithLabel from "components/Quotation/Form/Modal/ProductNameWithLabel";
import OtherProductsTableRow from "components/Quotation/Form/Modal/OtherProductsTableRow";

const OtherProductsTable = ({ fields, setFilteredProducts, products }) => {
  const handleRemoveAddOnService = (deletedLine, index) => {
    const { odoo_product_id: odooProductID, id } = deletedLine;
    const odooProductToRemove = products.find(
      ({ id: productID }) => productID === Number(odooProductID)
    );

    odooProductToRemove.name = (
      <ProductNameWithLabel name={odooProductToRemove.name} />
    );

    setFilteredProducts((previous) => [...previous, odooProductToRemove]);

    fields.remove(index);

    if (id) {
      fields.push({ id, _destroy: true });
    }
  };

  const fieldsName = fields.name;

  const otherProductsRowList = fields.value.map((subscriptionLine, index) => {
    const {
      line_type: lineType,
      product_name: productName,
      _destroy: isDeleted,
    } = subscriptionLine;
    const isSetup = lineType === SUBSCRIPTION_LINE_TYPE.SETUP;
    const isMainSubscription = lineType === SUBSCRIPTION_LINE_TYPE.SUBSCRIPTION;

    if (isMainSubscription || isDeleted) return null;

    return (
      <OtherProductsTableRow
        subscriptionLine={subscriptionLine}
        subscriptionLineIndex={index}
        handleRemoveAddOnService={handleRemoveAddOnService}
        productName={productName}
        isSetup={isSetup}
        subscriptionLinesFieldName={fieldsName}
      />
    );
  });

  return (
    <div className="table-responsive">
      <table
        className="table table-borderless mb-0"
        data-cy="other-products-table"
      >
        <thead className="other-products-table-headers mb-0">
          <tr className="w-100 subscription-detail add-on-detail">
            <th className="py-3">
              <p className="mb-0 fw-bold">
                <Translate value="attributes.product" />
              </p>
            </th>
            <th className="py-3">
              <p className="mb-0 text-center fw-bold">
                <Translate value="attributes.price" />
              </p>
            </th>
            <th className="py-3 pe-4">
              <p className="mb-0 text-end fw-bold">
                <Translate value="common.action" />
              </p>
            </th>
          </tr>
        </thead>

        <tbody>{otherProductsRowList}</tbody>
      </table>
    </div>
  );
};

OtherProductsTable.defaultProps = {};

OtherProductsTable.propTypes = {
  fields: PropTypes.shape({
    remove: PropTypes.func,
    push: PropTypes.func,
    pop: PropTypes.func,
    length: PropTypes.func,
    insert: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
  }).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      context: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      context_type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  subscriptionField: PropTypes.shape({
    input: PropTypes.shape({
      value: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  setFilteredProducts: PropTypes.func.isRequired,
};

export default OtherProductsTable;
