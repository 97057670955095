import React from "react";
import { FormGroup, Row, Col } from "reactstrap";
import { Field, useForm } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import PropTypes from "prop-types";

import {
  renderDropdownField,
  renderDateField,
} from "components/ReduxForm/RenderField";

import { required } from "utils/validation";
import { formatDropdownOptions } from "utils/utils";

import ToggleButtonField from "components/ReduxForm/ToggleButtonField";

import {
  invoicePeriodOptions,
  terminationTermOptions,
} from "constants/dropdownOptions";
import { FORM_TYPES } from "constants/form";

const EndDateInputField = ({ formType, startDate }) => {
  if (formType === FORM_TYPES.CREATE) return null;

  return (
    <Col md="4">
      <FormGroup>
        <Field
          dataTestId="end-date"
          name="end_date"
          component={renderDateField}
          labelTranslation="attributes.terminationDate"
          disabledDays={{ before: new Date(startDate) }}
        />
      </FormGroup>
    </Col>
  );
};

const BasicInfoSection = ({
  debtors,
  paymentTerms,
  startDate,
  isDebtorFetching,
  isPaymentTermFetching,
  formType,
}) => {
  const form = useForm();

  const handleDebtorSelect = (debtorID) => {
    const selectedDebtor = debtors.find((debtor) => debtor.id === debtorID);
    const { payment_term_id: debtorPaymentTermID } = selectedDebtor;

    form.change("odoo_payment_term_id", debtorPaymentTermID);
  };

  const handleStartDateSelect = () => {
    form.change("end_date", "");
  };

  return (
    <Row>
      <Col md="4">
        <FormGroup>
          <Field
            name="odoo_debtor_id"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={formatDropdownOptions(debtors)}
            labelTranslation="attributes.debtor"
            isLoading={isDebtorFetching}
            dataTestId="debtors-dropdown-field"
          />

          <OnChange name="odoo_debtor_id">
            {(value) => {
              handleDebtorSelect(value);
            }}
          </OnChange>
        </FormGroup>
      </Col>
      <Col md="4">
        <FormGroup>
          <Field
            name="odoo_payment_term_id"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={formatDropdownOptions(paymentTerms)}
            labelTranslation="attributes.paymentTerm"
            isLoading={isPaymentTermFetching}
            dataTestId="payment-terms-dropdown-field"
          />
        </FormGroup>
      </Col>
      <Col md="4">
        <FormGroup>
          <Field
            name="invoice_period"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={invoicePeriodOptions}
            labelTranslation="attributes.invoicePeriod"
            dataTestId="invoice-periods-dropdown-field"
          />
        </FormGroup>
      </Col>
      <Col md="4">
        <FormGroup>
          <Field
            name="termination_term"
            component={renderDropdownField}
            validate={required}
            dropdownOptions={terminationTermOptions}
            labelTranslation="attributes.terminationTerm"
            dataTestId="termination-terms-dropdown-field"
          />
        </FormGroup>
      </Col>
      <Col md="4">
        <FormGroup>
          <Field
            name="start_date"
            component={renderDateField}
            validate={required}
            labelTranslation="attributes.startDate"
            dataTestId="start-date-field"
          />

          <OnChange name="start_date">
            {(value) => {
              handleStartDateSelect(value);
            }}
          </OnChange>
        </FormGroup>
      </Col>

      <EndDateInputField formType={formType} startDate={startDate} />

      <Col md="12">
        <Row>
          <Col md="4">
            <FormGroup>
              <Field
                name="is_billable"
                component={ToggleButtonField}
                labelTranslation="attributes.billable"
                className="d-block"
                dataTestId="billable-toggle-button-field"
              />
            </FormGroup>
          </Col>

          <Col md="4">
            <FormGroup>
              <Field
                name="is_voip_billing_enabled"
                component={ToggleButtonField}
                labelTranslation="attributes.billVoipUsages"
                className="d-block"
                dataTestId="bill-voip-usages-toggle-button-field"
              />
            </FormGroup>
          </Col>

          <Col md="4">
            <FormGroup>
              <Field
                name="is_cell_phone_billing_enabled"
                component={ToggleButtonField}
                labelTranslation="attributes.billCellPhoneUsages"
                className="d-block"
              />
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

EndDateInputField.defaultProps = { formType: "", startDate: undefined };

EndDateInputField.propTypes = {
  formType: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
};

BasicInfoSection.defaultProps = {
  startDate: undefined,
  isDebtorFetching: false,
  isPaymentTermFetching: false,
};

BasicInfoSection.propTypes = {
  debtors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  paymentTerms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  isDebtorFetching: PropTypes.bool,
  isPaymentTermFetching: PropTypes.bool,
  formType: PropTypes.string.isRequired,
};

export default BasicInfoSection;
