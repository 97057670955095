import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Translate, t } from "react-i18nify";

import {
  faBoxOpen,
  faDollarSign,
  faCalendarDay,
  faCalendarCheck,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";

import { CELL_PHONE } from "constants/routes";
import { RESOURCE_NAMES } from "constants/constant";

import TranslateNumber from "components/i18n/TranslateNumber";
import TranslateDate from "components/i18n/TranslateDate";
import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import AddOnSubscriptionsModal from "components/Modal/AddOnSubscriptionsModal";

import HasPermission from "hoc/RbacCTA";

const CellPhoneSubscriptionDetail = ({ cellPhone }) => {
  const {
    main_subscription_product: {
      start_date: startDate,
      product_name: productName,
      price,
      last_invoice_date: lastInvoiceDate,
    },
    is_active: isActive,
    cell_number: cellNumber,
    secondary_subscription_products: secondarySubscriptionProducts,
    total_subscription_price: totalSubscriptionPrice,
  } = cellPhone;

  const subscriptionUsage = isActive && {
    icon: faChartBar,
    title: <Translate value="common.subscriptionUsage" />,
    content: (
      <Link
        to={CELL_PHONE.addCellNumber(CELL_PHONE.SUBSCRIPTION_USAGE, cellNumber)}
        className="btn-outline-primary btn px-2 py-1"
      >
        <Translate value="common.viewUsage" />
      </Link>
    ),
    dataCyFieldName: "subscriptionUsage",
  };

  const [modalVisible, setModalVisibility] = useState(false);

  const toggle = () => {
    setModalVisibility((prevState) => !prevState);
  };

  const subscriptionDetailContent = [
    {
      icon: faBoxOpen,
      title: <Translate value="attributes.product" />,
      content: productName,
      dataCyFieldName: "product",
    },
    {
      icon: faDollarSign,
      title: <Translate value="attributes.price" />,
      content: <TranslateNumber value={price} minimumFractionDigits={2} />,
      dataCyFieldName: "price",
    },
    {
      icon: faChartBar,
      title: <Translate value="common.addOnSubscriptions" />,
      content: (
        <button
          className="btn btn-outline-primary px-2 py-1"
          type="button"
          onClick={toggle}
        >
          <Translate value="common.viewDetails" />
        </button>
      ),
      dataCyFieldName: "addOnSubscriptions",
    },
    {
      icon: faDollarSign,
      title: <Translate value="attributes.totalPrice" />,
      content: (
        <TranslateNumber
          value={totalSubscriptionPrice}
          minimumFractionDigits={2}
        />
      ),
      dataCyFieldName: "totalPrice",
    },
    {
      ...subscriptionUsage,
    },
    {
      icon: faCalendarDay,
      title: <Translate value="attributes.startDate" />,
      content: <TranslateDate date={startDate} />,
      dataCyFieldName: "startDate",
    },
    lastInvoiceDate !== null && {
      icon: faCalendarCheck,
      title: <Translate value="attributes.lastInvoiceDate" />,
      content: <TranslateDate date={lastInvoiceDate} />,
      dataCyFieldName: "lastInvoiceDate",
    },
  ];

  const subscriptionDetailTranslation = (
    <Translate
      value="title.card.resourceDetails"
      resource={t("title.section.subscription")}
    />
  );

  return (
    <HasPermission perform="cellPhone:subscriptionDetails">
      <CardWithHeader
        title={subscriptionDetailTranslation}
        className="shadow-sm"
        cardContent={subscriptionDetailContent}
        dataCy={RESOURCE_NAMES.CELL_PHONE}
      />
      <AddOnSubscriptionsModal
        title={<Translate value="common.addOnSubscriptions" />}
        secondarySubscriptions={secondarySubscriptionProducts}
        modalVisible={modalVisible}
        onCancel={toggle}
      />
    </HasPermission>
  );
};

CellPhoneSubscriptionDetail.defaultProps = {};

CellPhoneSubscriptionDetail.propTypes = {
  cellPhone: PropTypes.shape({
    main_subscription_product: PropTypes.shape({
      start_date: PropTypes.instanceOf(Date),
      product_name: PropTypes.string,
      price: PropTypes.number,
      last_invoice_date: PropTypes.instanceOf(Date),
    }),
    is_active: PropTypes.bool,
    cell_number: PropTypes.string,
    secondary_subscription_products: PropTypes.arrayOf(
      PropTypes.shape({
        end_date: PropTypes.string,
        id: PropTypes.number,
        is_archived: PropTypes.bool,
        last_invoice_date: PropTypes.string,
        line_type: PropTypes.string,
        odoo_product_id: PropTypes.number,
        price: PropTypes.number,
        product_name: PropTypes.string,
        property_editable: PropTypes.bool,
        start_date: PropTypes.string,
        subscription_id: PropTypes.number,
      })
    ),
    total_subscription_price: PropTypes.number,
  }).isRequired,
};

export default CellPhoneSubscriptionDetail;
