export const getAuthData = (state) => {
  const { auth } = state;

  return auth;
};

export const getCurrentUser = (state) => {
  const { currentUser } = getAuthData(state) || {};

  return currentUser;
};

export const getCurrentUserRole = (state) => {
  const { role } = getCurrentUser(state) || {};

  return role;
};

export const getCurrentLocale = (state) => {
  const { i18n } = state;
  const { locale } = i18n;

  return locale;
};
