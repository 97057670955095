import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Navigate, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Translate, t } from "react-i18nify";

import {
  cellPhoneUpdateRequest,
  singleCellPhoneFetchRequest,
} from "redux/actions/cellPhone";
import { providerFetchRequest } from "redux/actions/pbxEngine/provider";

import { CELL_PHONE } from "constants/routes";

import CellPhoneForm from "components/CellPhone/Form/CellPhoneForm";
import WholePageSpinner from "components/Spinner/WholePageSpinner";
import PageNotFound from "components/Error/PageNotFound/PageNotFound";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const CellPhoneEdit = ({
  updateCellPhone,
  commitSuccess,
  cellPhone,
  isLoading,
  fetchSingleCellPhone,
  fetchProviders,
  providers,
  isProviderLoading,
}) => {
  const { cellPhoneID } = useParams();

  useEffect(() => {
    fetchSingleCellPhone(cellPhoneID);
    fetchProviders();
  }, []);

  const handleSubmit = async (values, registeredFields) => {
    let serverResponse = {};
    const { id } = cellPhone;

    await updateCellPhone(id, values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess) {
    const { id } = cellPhone;
    const cellPhoneTranslation = t("title.tab.agreement.cellPhone");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.update", {
        resource: cellPhoneTranslation,
      })
    );

    return <Navigate to={CELL_PHONE.addId(CELL_PHONE.SHOW, id)} />;
  }

  if (!cellPhone || isProviderLoading) {
    return <WholePageSpinner />;
  }

  const {
    is_active: isActive,
    termination_date_end_limit: terminationDateEndLimit,
    main_subscription_product: { id: subscriptionLineId },
    custom_extension_providers: customExtensionProviders,
    user_license_id: userLicenseId,
  } = cellPhone;

  if (!isActive) {
    return (
      <div className="d-flex h-100 justify-content-center">
        <PageNotFound />
      </div>
    );
  }

  const asteriskProviderIds = customExtensionProviders.map(
    (provider) => provider.cp_pr_id
  );
  const parentType = userLicenseId ? "userLicense" : "pbx";

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.edit"
            resource={t("title.tab.agreement.cellPhone")}
          />
        </h3>
      </div>

      <CellPhoneForm
        onSubmit={handleSubmit}
        formName="cellPhoneForm"
        formType="edit"
        isLoading={isLoading}
        initialValues={{ ...cellPhone, provider_ids: asteriskProviderIds }}
        terminationDateEndLimit={terminationDateEndLimit}
        subscriptionLineId={subscriptionLineId}
        isProviderLoading={isProviderLoading}
        providers={providers}
        parentType={parentType}
      />
    </div>
  );
};

const mapStateToProps = ({ cellPhone: cellPhoneReducer, provider }) => {
  const { cellPhoneError, cellPhone, commitSuccess, commitError, isLoading } =
    cellPhoneReducer;
  const { providers, isLoading: isProviderLoading } = provider;

  return {
    cellPhoneUpdateError: cellPhoneError,
    isLoading,
    cellPhone,
    commitError,
    commitSuccess,
    providers,
    isProviderLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleCellPhone: (id) => {
    dispatch(singleCellPhoneFetchRequest(id));
  },
  updateCellPhone: async (id, attributes, callback) => {
    await dispatch(cellPhoneUpdateRequest(id, attributes, callback));
  },
  fetchProviders: () => {
    dispatch(providerFetchRequest());
  },
});

CellPhoneEdit.defaultProps = {
  cellPhone: undefined,
  commitError: {},
  isProviderLoading: undefined,
};

CellPhoneEdit.propTypes = {
  updateCellPhone: PropTypes.func.isRequired,
  commitSuccess: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchSingleCellPhone: PropTypes.func.isRequired,
  commitError: PropTypes.shape({}),
  cellPhone: PropTypes.shape({
    id: PropTypes.number,
    fax_service_enabled: PropTypes.bool,
    is_active: PropTypes.bool,
    termination_date_end_limit: PropTypes.instanceOf(Date),
    main_subscription_product: PropTypes.shape({
      id: PropTypes.number,
    }),
    custom_extension_providers: PropTypes.arrayOf(
      PropTypes.shape({
        cp_pr_id: PropTypes.number,
      })
    ),
    user_license_id: PropTypes.number,
  }),
  fetchProviders: PropTypes.func.isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isProviderLoading: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(CellPhoneEdit);
