import { createSearchAction } from "redux-search";

import { VendorApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

export const VENDORS_FETCH_REQUEST = "VENDORS_FETCH_REQUEST";
export const VENDORS_FETCH_RECEIVED = "VENDORS_FETCH_RECEIVED";
export const VENDORS_FETCH_FAILED = "VENDORS_FETCH_FAILED";
export const VENDORS_SEARCH_REQUEST = "VENDORS_SEARCH_REQUEST";
export const VENDORS_FETCH_CLEAR_ERRORS = "VENDORS_FETCH_CLEAR_ERRORS";

export const searchVendors = createSearchAction("vendors");

export function vendorsFetchReceived(response) {
  return {
    type: VENDORS_FETCH_RECEIVED,
    vendors: response.data,
  };
}

export function vendorsFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: VENDORS_FETCH_FAILED,
    message,
  };
}

export const vendorsFetchRequest = () => (dispatch) => {
  dispatch({ type: VENDORS_FETCH_REQUEST });

  VendorApi.fetchAll()
    .then((data) => dispatch(vendorsFetchReceived(data)))
    .catch((e) => {
      dispatch(vendorsFetchFailed(e));
    });
};

export function vendorsFetchClearErrors() {
  return {
    type: VENDORS_FETCH_CLEAR_ERRORS,
  };
}

export function vendorsSearchRequest(value) {
  const isSearching = Boolean(value);

  return {
    type: VENDORS_SEARCH_REQUEST,
    isSearching,
  };
}
