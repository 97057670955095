import React from "react";
import PropTypes from "prop-types";

import { Spinner } from "reactstrap";

const LoginSpinner = ({ submitting, isLoading }) => {
  if (submitting || isLoading)
    return <Spinner as="span" size="sm" className="me-1" />;

  return null;
};

LoginSpinner.defaultProps = {
  submitting: false,
  isLoading: false,
};
LoginSpinner.propTypes = {
  submitting: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default LoginSpinner;
