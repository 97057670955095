import React, { useState } from "react";
import { Translate } from "react-i18nify";
import PropTypes from "prop-types";

import AssignNewSimCardModal from "components/Modal/AssignNewSimCardModal";

import HasPermission from "hoc/RbacCTA";

import { CELL_PHONE_ACTIONS } from "constants/rolePermission";

const AssignNewSimCard = ({ cellNumberID }) => {
  const [isModalVisible, setModalVisibility] = useState(false);

  return (
    <HasPermission
      perform="cellPhone:assignNewSIMCard"
      data={{ action: CELL_PHONE_ACTIONS.ASSIGN_SIM_CARD }}
    >
      <button
        className="btn btn-primary ms-2"
        type="button"
        onClick={() => setModalVisibility((prevState) => !prevState)}
      >
        <Translate value="common.assignNewSimCard" />
      </button>

      <AssignNewSimCardModal
        modalVisible={isModalVisible}
        onCancel={() => setModalVisibility(false)}
        cellPhoneID={cellNumberID}
      />
    </HasPermission>
  );
};

AssignNewSimCard.defaultProps = {};

AssignNewSimCard.propTypes = {
  cellNumberID: PropTypes.number.isRequired,
};

export default AssignNewSimCard;
