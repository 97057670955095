import { DashboardApi } from "internal";
import { getAndDisplayErrors } from "utils/utils";

export const DASHBOARD_CELL_PHONE_EXPORT_REQUEST_FETCH_REQUEST =
  "DASHBOARD_CELL_PHONE_EXPORT_REQUEST_FETCH_REQUEST";
export const DASHBOARD_CELL_PHONE_EXPORT_REQUEST_FETCH_RECEIVED =
  "DASHBOARD_CELL_PHONE_EXPORT_REQUEST_FETCH_RECEIVED";
export const DASHBOARD_CELL_PHONE_EXPORT_REQUEST_FETCH_FAILED =
  "DASHBOARD_CELL_PHONE_EXPORT_REQUEST_FETCH_FAILED";
export const DASHBOARD_CELL_PHONE_EXPORT_REQUEST_FETCH_CLEAR_ERRORS =
  "DASHBOARD_CELL_PHONE_EXPORT_REQUEST_FETCH_CLEAR_ERRORS";

export function dashboardCellPhoneExportRequestFetchReceived(response) {
  return {
    type: DASHBOARD_CELL_PHONE_EXPORT_REQUEST_FETCH_RECEIVED,
    data: response.data,
  };
}

export function dashboardCellPhoneExportRequestFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: DASHBOARD_CELL_PHONE_EXPORT_REQUEST_FETCH_FAILED,
    message,
  };
}

export function dashboardCellPhoneExportRequestFetchClearErrors() {
  return {
    type: DASHBOARD_CELL_PHONE_EXPORT_REQUEST_FETCH_CLEAR_ERRORS,
  };
}

export const dashboardCellPhoneExportRequestFetchRequest = () => (dispatch) => {
  dispatch({ type: DASHBOARD_CELL_PHONE_EXPORT_REQUEST_FETCH_REQUEST });

  DashboardApi.fetchCellPhoneExportRequests()
    .then((data) =>
      dispatch(dashboardCellPhoneExportRequestFetchReceived(data))
    )
    .catch((e) => {
      dispatch(dashboardCellPhoneExportRequestFetchFailed(e));
    });
};
