import React, { useState } from "react";
import { useField } from "react-final-form";
import { Translate } from "react-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import { NumberPortingRequestApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

import DotsLoadingIndicator from "components/Spinner/DotsLoadingIndicator";

const CSVFileDownload = () => {
  const [isFileDownloading, setIsFileDownloading] = useState(false);
  const {
    input: { value: numberType },
  } = useField("number_type");
  const cellPhoneNumberType = numberType === "cell_phone";

  const downloadImportTemplate = (e) => {
    e.stopPropagation();
    setIsFileDownloading(true);

    NumberPortingRequestApi.downloadPhoneNumberImportCSVTemplate(numberType)
      .then((csv) => {
        const fileName =
          numberType === "cell_phone"
            ? "cell_phone_import"
            : "did_number_import";

        const blob = new Blob([csv.data]);
        const link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileName}_template.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setIsFileDownloading(false);
      })
      .catch((errorData) => {
        getAndDisplayErrors(errorData);

        setIsFileDownloading(false);
      });
  };

  const downloadingIcon = isFileDownloading ? <DotsLoadingIndicator /> : null;
  const translationPhoneNumberKey = cellPhoneNumberType
    ? "cellPhone"
    : "didNumber";

  return (
    <div>
      <h5>
        <Translate value="numberPortingRequest.importForm.csvFileImport.downloadCSVTemplate" />
      </h5>
      <p>
        <Translate
          value={`numberPortingRequest.importForm.csvFileImport.${translationPhoneNumberKey}.downloadCSVTemplateInstructions`}
        />
      </p>

      <button
        type="button"
        disabled={isFileDownloading}
        className="d-flex align-items-center btn-small btn-outline mb-4 btn-primary text-white"
        onClick={downloadImportTemplate}
      >
        <FontAwesomeIcon icon={faDownload} className="me-2" />
        <Translate value="common.download" />
        <span className="ms-2">{downloadingIcon}</span>
      </button>

      <p>
        <Translate value="numberPortingRequest.importForm.csvFileImport.uploadSeparateCSVFileInstructions" />
        <ul className="mt-3">
          <li>
            <Translate
              value={`numberPortingRequest.importForm.csvFileImport.${translationPhoneNumberKey}.csvHeaderOrderNote`}
            />
          </li>

          {cellPhoneNumberType && (
            <li>
              <Translate
                value={`numberPortingRequest.importForm.csvFileImport.${translationPhoneNumberKey}.csvColumnSeparatedByComma`}
              />
            </li>
          )}
        </ul>
      </p>
    </div>
  );
};

export default CSVFileDownload;
