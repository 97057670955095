import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import HasPermission from "hoc/RbacCTA";
import CardContent from "components/Card/WithHeader/CardContent";

const RenderIcon = ({ localIconSource, icon, altAttributeText }) => {
  if (!localIconSource)
    return <FontAwesomeIcon icon={icon} className="text-tertiary" />;

  return <img src={localIconSource} alt={altAttributeText} className="me-3" />;
};

const CardWithHeader = ({
  title,
  className,
  cardContent,
  displayHeader,
  dataCy,
  cardHeaderClassName,
  cardContentClassName,
}) => {
  const defaultClassName = ["card-with-header"];
  const headerClassName = ["fw-bold card-header mt-3"];

  defaultClassName.push(className);
  headerClassName.push(cardHeaderClassName);

  const cardBody =
    (!Array.isArray(cardContent) && cardContent) ||
    cardContent.filter(Boolean).map((cardChild) => {
      const {
        icon,
        localIconSource,
        altAttributeText,
        title: childTitle,
        content,
        perform,
        performData,
        isHideAbleContent,
        dataCyFieldName,
        dataCyFieldTitle,
        resourceName,
      } = cardChild;

      const localIconParentClassName = localIconSource ? "d-flex" : "";

      const ContentWrapper = perform ? HasPermission : React.Fragment;

      return (
        <ContentWrapper perform={perform} data={performData}>
          <div className="details">
            <div className="row">
              <div className={`col-md-5 title ${localIconParentClassName}`}>
                <RenderIcon
                  localIconSource={localIconSource}
                  icon={icon}
                  altAttributeText={altAttributeText}
                />
                <span data-cy={`${resourceName}-${dataCyFieldTitle}`}>
                  {childTitle}
                </span>
              </div>
              <CardContent
                isHideAbleContent={isHideAbleContent}
                content={content}
                dataCy={`${dataCy}-${dataCyFieldName}`}
              />
            </div>
          </div>
        </ContentWrapper>
      );
    });

  return (
    <div className={defaultClassName.join(" ")} data-cy="card-with-header">
      {displayHeader && (
        <div className={headerClassName.join(" ")}>{title}</div>
      )}
      <div className={cardContentClassName} data-cy={dataCy}>
        {cardBody}
      </div>
    </div>
  );
};

RenderIcon.defaultProps = {
  localIconSource: "",
  altAttributeText: "",
};

RenderIcon.propTypes = {
  localIconSource: PropTypes.string,
  icon: PropTypes.shape({}).isRequired,
  altAttributeText: PropTypes.string,
};

CardWithHeader.defaultProps = {
  className: "",
  displayHeader: true,
  dataCy: "",
  cardHeaderClassName: "",
  cardContentClassName: "",
};

CardWithHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  cardContent: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({}),
      title: PropTypes.string,
      content: PropTypes.string,
      isHideAbleContent: PropTypes.bool,
    })
  ).isRequired,
  className: PropTypes.string,
  displayHeader: PropTypes.bool,
  dataCy: PropTypes.string,
  cardHeaderClassName: PropTypes.string,
  cardContentClassName: PropTypes.string,
};

export default CardWithHeader;
