import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-i18nify";

import SubscriptionDetail from "components/Agreement/AgreementDetail/Subscription/SubscriptionDetail/SubscriptionDetail";
import SubscriptionLineTab from "components/Agreement/AgreementDetail/SubscriptionLine/SubscriptionLineTab/SubscriptionLineTab";
import SubscriptionLineList from "components/Agreement/AgreementDetail/SubscriptionLine/SubscriptionLineList";

const SubscriptionDetailModal = ({
  subscription,
  subscriptionLines,
  subscriptionProduct,
  products,
  isAgreementArchived,
  isSubscriptionArchived,
  dataCyFieldName,
}) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const subscriptionLinesContent =
    isAgreementArchived || isSubscriptionArchived ? (
      <SubscriptionLineList subscriptionLines={subscriptionLines} />
    ) : (
      <SubscriptionLineTab
        subscriptionLines={subscriptionLines}
        isSubscriptionArchived={isSubscriptionArchived}
      />
    );

  return (
    <div className="subscription-detail-modal-container">
      <button
        onClick={toggleModal}
        type="button"
        className="btn btn-success table-action-btn me-3"
        data-field-name={dataCyFieldName}
      >
        <FontAwesomeIcon className="text-white d-block" icon={faEye} />
      </button>
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className="subscription-detail-modal modal-xl"
      >
        <ModalHeader className="px-4" toggle={toggleModal}>
          <Translate value="subscription.detail" />
        </ModalHeader>
        <ModalBody className="subscription-detail-modal-body p-0">
          <SubscriptionDetail
            subscription={subscription}
            products={products}
            subscriptionProduct={subscriptionProduct}
          />
          <div className="px-4">
            <div className="fw-bold">
              <Translate value="subscription.line" />
            </div>
            {subscriptionLinesContent}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

SubscriptionDetailModal.defaultProps = {};

SubscriptionDetailModal.propTypes = {
  subscription: PropTypes.shape({}).isRequired,
  subscriptionProduct: PropTypes.shape({}).isRequired,
  subscriptionLines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isAgreementArchived: PropTypes.bool.isRequired,
  isSubscriptionArchived: PropTypes.bool.isRequired,
  dataCyFieldName: PropTypes.string.isRequired,
};

export default SubscriptionDetailModal;
