import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useField, useForm } from "react-final-form";

import FieldsSection from "components/CellPhone/Form/NetworkOperatorSubscriptionServices/Modal/FieldsSection";

const ServiceModal = ({
  title,
  buttonTitle,
  modalVisible,
  onCancel,
  cellPhoneServices,
  onSubmit,
  fieldName,
  filteredSubscriptionServices,
  setFilteredSubscriptionServices,
}) => {
  const form = useForm();
  const subscriptionServiceField = useField(fieldName);

  const handleOnSubmit = () => {
    const {
      meta: { invalid },
    } = subscriptionServiceField;

    if (invalid) {
      form.submit();
    }

    onSubmit();
  };

  if (!modalVisible) return null;

  return (
    <Modal isOpen={modalVisible} centered toggle={onCancel} size="xl">
      <ModalHeader className="border-0 pb-0 mb-4" toggle={onCancel}>
        {title}
      </ModalHeader>
      <ModalBody className="subscription-modal-body pt-0">
        <FieldsSection
          fieldName={fieldName}
          cellPhoneServices={cellPhoneServices}
          filteredSubscriptionServices={filteredSubscriptionServices}
          setFilteredSubscriptionServices={setFilteredSubscriptionServices}
        />
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-primary mt-3 d-flex ms-auto"
          type="button"
          onClick={handleOnSubmit}
          data-cy="add-network-service-button"
        >
          {buttonTitle}
        </button>
      </ModalFooter>
    </Modal>
  );
};

ServiceModal.propTypes = {
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  cellPhoneServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  filteredSubscriptionServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      is_default: PropTypes.bool,
    })
  ).isRequired,
  setFilteredSubscriptionServices: PropTypes.func.isRequired,
};

const mapStateToProps = ({ cellPhoneServices: cellPhoneServicesReducer }) => {
  const { cellPhoneServices, isLoading } = cellPhoneServicesReducer;

  return {
    cellPhoneServices,
    isLoading,
  };
};

export default connect(mapStateToProps)(ServiceModal);
