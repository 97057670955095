import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import SubscriptionListingTableRow from "components/Quotation/Form/ConvertToAgreement/Subscription/SubscriptionListingTableRow";

const SubscriptionListingTable = ({ fields, toggleModalVisibility }) => {
  const enabledClassName = fields.length === 0 ? "pe-none" : "";

  return (
    <div className={`subscription-listing-table ${enabledClassName}`}>
      <div className="overflow-auto col-12">
        <table className="table table-borderless subscription-listing-table-container mb-0">
          <thead className="accordion-header">
            <tr className="w-100 subscription-detail add-on-detail">
              <th className="py-3 align-middle">
                <p className="subscription-detail-header mb-0 text-center fw-bold">
                  <Translate value="attributes.productName" />
                </p>
              </th>
              <th className="py-3 align-middle">
                <p className="subscription-detail-header mb-0 text-center fw-bold">
                  <Translate value="attributes.properties" />
                </p>
              </th>
              <th className="py-3 align-middle">
                <p className="subscription-detail-header mb-0 text-center fw-bold">
                  <Translate value="common.setupPrice" />
                </p>
              </th>
              <th className="py-3 align-middle">
                <p className="subscription-detail-header mb-0 text-center fw-bold">
                  <Translate value="attributes.monthlyPrice" />
                </p>
              </th>
              <th className="py-3 align-middle">
                <p className="subscription-detail-header mb-0 text-center fw-bold">
                  <Translate value="common.action" />
                </p>
              </th>
            </tr>
          </thead>

          <tbody className="border-bottom-0 px-3 py-0">
            {fields.value.map((subscription, index) => {
              const fieldName = `agreement.subscriptions[${index}]`;

              const { _destroy: isDeleted } = subscription;

              if (isDeleted) return null;

              return (
                <SubscriptionListingTableRow
                  fieldName={fieldName}
                  toggleModalVisibility={toggleModalVisibility}
                  key={fieldName}
                  fields={fields}
                  currentIndex={index}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

SubscriptionListingTable.defaultProps = {};

SubscriptionListingTable.propTypes = {
  fields: PropTypes.shape({
    remove: PropTypes.func,
    push: PropTypes.func,
    pop: PropTypes.func,
    map: PropTypes.func,
    length: PropTypes.func,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        length: PropTypes.func,
      })
    ),
  }).isRequired,
  toggleModalVisibility: PropTypes.func.isRequired,
};

export default SubscriptionListingTable;
