import { combineReducers } from "redux";

import user from "./user";
import agreement from "./agreement";
import debtor from "./debtor";
import invoice from "./invoice";
import bill from "./bill";
import cellPhoneImportRequest from "./cellPhoneImportRequest";
import cellPhoneExportRequest from "./cellPhoneExportRequest";

export default combineReducers({
  user,
  agreement,
  debtor,
  invoice,
  bill,
  cellPhoneImportRequest,
  cellPhoneExportRequest,
});
