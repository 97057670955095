import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";

import { ICON_INFO } from "constants/images";

import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import Tooltip from "components/Tooltip/Tooltip";
import PreviewFileModal from "components/Quotation/Detail/PreviewFileModal";

import { termsAndConditionsPreviewRequest } from "redux/actions/quotation";

const AttachedFiles = ({
  fileNameList,
  termsAndConditionsFilesRequest,
  setTermsAndConditionsPreviewModalVisibility,
}) => {
  const handleAttachedFilesPreview = (fileName) => {
    termsAndConditionsFilesRequest(fileName);

    setTermsAndConditionsPreviewModalVisibility((prevState) => !prevState);
  };

  const content = (
    <div className="attached-files row m-4 py-4">
      {fileNameList.map((fileName) => (
        <button
          className="attached-terms-and-condition col-md-2 bg-transparent border-0 d-flex justify-content-center flex-column align-items-center"
          key={fileName}
          type="button"
          onClick={() => handleAttachedFilesPreview(fileName)}
        >
          <FontAwesomeIcon
            icon={faFilePdf}
            className="attached-file-icon mb-2"
          />
          <p title={fileName} className="file-name w-100 text-truncate">
            {fileName}
          </p>
        </button>
      ))}
    </div>
  );

  return content;
};

const QuotationTermsAndConditions = ({
  quotation,
  termsAndConditionsFilesRequest,
  termsAndConditionsPreview,
}) => {
  const { attached_terms_and_conditions: attachedTermsAndConditions } =
    quotation;

  const [
    isTermsAndConditionsPreviewModalVisible,
    setTermsAndConditionsPreviewModalVisibility,
  ] = useState(false);

  const attachedTermsAndConditionsTitle = (
    <div className="card-header-contents d-flex gap-2">
      <Translate value="common.attachedTermsAndConditions" />
      <Tooltip
        title={
          <Translate value="tooltip.title.clickOnFilesToPreviewTermsAndConditions" />
        }
        tooltipType="custom"
        className="terms-and-conditions-tooltip-text"
      >
        <img src={ICON_INFO.SOURCE} alt="information icon" />
      </Tooltip>
    </div>
  );

  if (
    attachedTermsAndConditions !== undefined &&
    attachedTermsAndConditions.length === 0
  ) {
    return null;
  }

  return (
    <div>
      <CardWithHeader
        title={attachedTermsAndConditionsTitle}
        className="shadow-sm"
        cardContent={
          <AttachedFiles
            fileNameList={attachedTermsAndConditions}
            termsAndConditionsFilesRequest={termsAndConditionsFilesRequest}
            setTermsAndConditionsPreviewModalVisibility={
              setTermsAndConditionsPreviewModalVisibility
            }
          />
        }
        dataCy="attached-terms-and-conditions"
      />

      <PreviewFileModal
        filePreview={termsAndConditionsPreview}
        modalVisible={isTermsAndConditionsPreviewModalVisible}
        title={<Translate value="quotation.termsAndConditionsPreview" />}
        onCancel={() =>
          setTermsAndConditionsPreviewModalVisibility((prevState) => !prevState)
        }
      />
    </div>
  );
};

AttachedFiles.defaultProps = {};

AttachedFiles.propTypes = {
  fileNameList: PropTypes.arrayOf(PropTypes.string).isRequired,
  termsAndConditionsFilesRequest: PropTypes.func.isRequired,
  setTermsAndConditionsPreviewModalVisibility: PropTypes.func.isRequired,
};

QuotationTermsAndConditions.defaultProps = {};

QuotationTermsAndConditions.propTypes = {
  quotation: PropTypes.shape({
    terms_and_conditions: PropTypes.string,
    attached_terms_and_conditions: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  termsAndConditionsFilesRequest: PropTypes.func.isRequired,
  termsAndConditionsPreview: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ quotation: quotationResource }) => {
  const { quotation, isLoading, fetchError, termsAndConditionsPreview } =
    quotationResource;

  return {
    quotation,
    isLoading,
    fetchError,
    termsAndConditionsPreview,
  };
};

const mapDispatchToProps = (dispatch) => ({
  termsAndConditionsFilesRequest: (fileName) => {
    dispatch(termsAndConditionsPreviewRequest(fileName));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotationTermsAndConditions);
