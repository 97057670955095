/* eslint-disable import/no-cycle */
import {
  CELL_PHONE_REPORT_SUBSCRIPTION_USAGE_FETCH_REQUEST,
  CELL_PHONE_REPORT_SUBSCRIPTION_USAGE_FETCH_FAILED,
  CELL_PHONE_REPORT_SUBSCRIPTION_USAGE_FETCH_RECEIVED,
} from "redux/actions/cellPhoneReport/subscriptionUsage";

const initialState = {
  subscriptionUsage: null,
  isLoading: false,
  fetchError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CELL_PHONE_REPORT_SUBSCRIPTION_USAGE_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: false,
      };

    case CELL_PHONE_REPORT_SUBSCRIPTION_USAGE_FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        fetchError: true,
      };

    case CELL_PHONE_REPORT_SUBSCRIPTION_USAGE_FETCH_RECEIVED:
      return {
        ...state,
        isLoading: false,
        fetchError: false,
        subscriptionUsage: action.data,
      };

    default:
      return state;
  }
};
