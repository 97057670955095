import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Translate, t } from "react-i18nify";

import { cellPhoneCreateRequest } from "redux/actions/cellPhone";
import { providerFetchRequest } from "redux/actions/pbxEngine/provider";

import {
  NETWORK_SERVICES_INSIDE_DENMARK,
  NETWORK_SERVICE_DEFAULT_LOCATIONS,
  NETWORK_SERVICE_DEFAULT_LOCATION_CONSTRAINT,
  PBX,
} from "constants/constant";
import { CELL_PHONE } from "constants/routes";

import { cellPhoneServicesFetchRequest } from "redux/actions/cellPhoneServices";

import CellPhoneForm from "components/CellPhone/Form/CellPhoneForm";
import WholePageSpinner from "components/Spinner/WholePageSpinner";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const CellPhoneFormContent = ({
  createCellPhone,
  commitSuccess,
  isLoading,
  cellPhone,
  initialValues,
  parentType,
  fetchProviders,
  providers,
  isProviderLoading,
  phoneNumbers,
  fetchCellPhoneServices,
  cellPhoneServices,
  isCellPhoneServicesLoading,
  email,
}) => {
  useEffect(() => {
    fetchProviders();
    fetchCellPhoneServices();
  }, []);

  const handleSubmit = async (values, registeredFields) => {
    let serverResponse = {};

    const {
      subscriptionProducts,
      temporary_network_operator_subscription_service:
        temporaryNetworkOperatorSubscriptionService,
      subscription_service_currently_on_edit:
        subscriptionServiceCurrentlyOnEdit,
      network_operator_subscription_services:
        networkOperatorSubscriptionServices,
      ...rest
    } = values;

    const { phone_number: phoneNumber } = phoneNumbers.find(
      ({ id }) => id === rest.phone_number_id
    );

    rest.active_sim_card_id =
      rest.cell_phone_sim_cards_attributes[0].sim_card_id;

    const formattedFormValues = {
      ...rest,
      cell_number: phoneNumber,
      network_operator_subscription_services_attributes:
        networkOperatorSubscriptionServices,
    };

    await createCellPhone(formattedFormValues, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  const constructDefaultVoicemailBoxAttributes = () => {
    if (parentType === PBX) {
      return {};
    }

    const defaultAttributes = {
      voicemail_box_attributes: { maxsecs: 120, minsecs: 1, maxmsg: 50, email },
    };

    return defaultAttributes;
  };

  if (commitSuccess) {
    const { id } = cellPhone;
    const cellPhoneTranslation = t("title.tab.agreement.cellPhone");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.create", {
        resource: cellPhoneTranslation,
      })
    );

    return <Navigate to={`${CELL_PHONE.addId(CELL_PHONE.SHOW, id)}`} />;
  }

  if (isProviderLoading || isCellPhoneServicesLoading) {
    return <WholePageSpinner />;
  }

  const defaultProviders = providers.filter((provider) =>
    ["SBC-IC-HOR", "SBC-IC-BYEN"].includes(provider.name)
  );
  const defaultProviderIds = defaultProviders.map((provider) => provider.id);
  const voicemailBoxAttributes = constructDefaultVoicemailBoxAttributes();

  const defaultCellPhoneServices = cellPhoneServices.filter(
    ({ is_default: isDefaultService }) => isDefaultService
  );

  const filterSelectedServices = cellPhoneServices.filter(
    ({ is_default: isDefaultService }) => !isDefaultService
  );

  const networkOperatorSubscriptionServices = defaultCellPhoneServices.map(
    ({ id, name }) => {
      const isDanishService = NETWORK_SERVICES_INSIDE_DENMARK.find(
        (serviceName) => serviceName === name
      );

      if (isDanishService) {
        return {
          cell_phone_service_id: id,
          location: NETWORK_SERVICE_DEFAULT_LOCATIONS.DENMARK,
          location_constraint:
            NETWORK_SERVICE_DEFAULT_LOCATION_CONSTRAINT.INSIDE,
        };
      }

      return {
        cell_phone_service_id: id,
        location: NETWORK_SERVICE_DEFAULT_LOCATIONS.ENTIRE_WORLD,
        location_constraint: NETWORK_SERVICE_DEFAULT_LOCATION_CONSTRAINT.INSIDE,
      };
    }
  );

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.create"
            resource={t("title.tab.agreement.cellPhone")}
          />
        </h3>
      </div>
      <CellPhoneForm
        onSubmit={handleSubmit}
        formName="cellPhoneForm"
        formType="create"
        isLoading={isLoading}
        initialValues={{
          ...initialValues,
          invoice_start_date: new Date(),
          deployment_date: new Date(),
          provider_ids: defaultProviderIds,
          ...voicemailBoxAttributes,
          cell_phone_sim_cards_attributes: [{ sim_card_id: "" }],
          network_operator_subscription_services:
            networkOperatorSubscriptionServices,
        }}
        parentType={parentType}
        isProviderLoading={isProviderLoading}
        providers={providers}
        cellPhoneServices={cellPhoneServices}
        filterSelectedServices={filterSelectedServices}
      />
    </div>
  );
};

const mapStateToProps = ({
  cellPhone: cellPhoneReducer,
  provider: providerReducer,
  cellPhoneServices: cellPhoneServicesReducer,
}) => {
  const { cellPhone, isLoading, commitSuccess, commitError, phoneNumbers } =
    cellPhoneReducer;
  const { providers, isLoading: isProviderLoading } = providerReducer;
  const { cellPhoneServices, isLoading: isCellPhoneServicesLoading } =
    cellPhoneServicesReducer;

  return {
    cellPhone,
    isLoading,
    commitSuccess,
    commitError,
    providers,
    isProviderLoading,
    phoneNumbers,
    cellPhoneServices,
    isCellPhoneServicesLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProviders: () => {
    dispatch(providerFetchRequest());
  },
  createCellPhone: async (attributes, callback) => {
    await dispatch(cellPhoneCreateRequest(attributes, callback));
  },
  fetchCellPhoneServices: () => dispatch(cellPhoneServicesFetchRequest()),
});

CellPhoneFormContent.defaultProps = {
  cellPhone: undefined,
  commitError: {},
  initialValues: {},
  isProviderLoading: undefined,
  email: undefined,
};

CellPhoneFormContent.propTypes = {
  cellPhone: PropTypes.shape({
    id: PropTypes.number,
  }),
  isLoading: PropTypes.bool.isRequired,
  commitSuccess: PropTypes.bool.isRequired,
  commitError: PropTypes.shape({}),
  createCellPhone: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  parentType: PropTypes.string.isRequired,
  fetchProviders: PropTypes.func.isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isProviderLoading: PropTypes.bool,
  phoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      phone_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  fetchCellPhoneServices: PropTypes.func.isRequired,
  isCellPhoneServicesLoading: PropTypes.bool.isRequired,
  cellPhoneServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      is_default: PropTypes.bool,
    })
  ).isRequired,
  email: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CellPhoneFormContent);
