export const UPDATE_STATUS_CODE_AUTH = "UPDATE_STATUS_CODE_AUTH";

export const REDUCER_TYPE = {
  AUTH: "auth",
};
/**
 * function which update status code through out the application
 * @param {number} statusCode - Network status code such as 403,404
 * @param {*} reducerType - action which we need to perform
 * @returns
 */
// eslint-disable-next-line consistent-return
const updateStatusReducer = (statusCode, reducerType) => (dispatch) => {
  switch (reducerType) {
    case REDUCER_TYPE.AUTH:
      if (dispatch) {
        dispatch({
          type: UPDATE_STATUS_CODE_AUTH,
          statusCode,
        });
      } else {
        return {
          type: UPDATE_STATUS_CODE_AUTH,
          statusCode,
        };
      }
      break;

    default:
      return null;
  }
};

export default updateStatusReducer;
