import React from "react";
import { Form } from "reactstrap";
import { Form as ReactFinalForm } from "react-final-form";
import arrayMutators from "final-form-arrays";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import BasicInfoSection from "components/Agreement/Form/BasicInfoSection";
import SubscriptionGroup from "components/Agreement/Form/Subscription/SubscriptionGroup/SubscriptionGroup";
import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";

import { getDirtyFieldsValue } from "utils/utils";

const AgreementForm = ({
  onSubmit,
  initialValues,
  isLoading,
  paymentTerms,
  debtors,
  products,
  isDebtorFetching,
  isPaymentTermFetching,
  isProductFetching,
  formType,
}) => {
  const handleFormSubmit = (fields, form) => {
    const registeredFields = form.getRegisteredFields();
    const values = getDirtyFieldsValue(fields, form);

    return onSubmit(values, registeredFields);
  };

  return (
    <ReactFinalForm
      keepDirtyOnReinitialize
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      render={({ pristine, submitting, handleSubmit, values }) => {
        const { start_date: startDate } = values;

        return (
          <Form
            className="flexo-form px-4 py-4 pt-5 w-100"
            onSubmit={handleSubmit}
          >
            <div className="flexo-form-content">
              <BasicInfoSection
                debtors={debtors}
                paymentTerms={paymentTerms}
                startDate={startDate}
                isDebtorFetching={isDebtorFetching}
                isPaymentTermFetching={isPaymentTermFetching}
                isProductFetching={isProductFetching}
                formType={formType}
              />
              <SubscriptionGroup
                agreementStartDate={startDate}
                products={products}
                isProductFetching={isProductFetching}
              />
            </div>
            <div className="flexo-form-footer">
              <ReduxFormCommitButton
                title={<Translate value="common.save" />}
                submitting={submitting}
                pristine={pristine}
                isLoading={
                  isLoading || isDebtorFetching || isPaymentTermFetching
                }
              />
            </div>
          </Form>
        );
      }}
    />
  );
};

AgreementForm.defaultProps = {
  isDebtorFetching: false,
  isPaymentTermFetching: false,
  isProductFetching: false,
  initialValues: {},
};

AgreementForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  paymentTerms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  debtors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isDebtorFetching: PropTypes.bool,
  isPaymentTermFetching: PropTypes.bool,
  isProductFetching: PropTypes.bool,
  formType: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({}),
};

export default AgreementForm;
