import React from "react";
import { Input } from "reactstrap";
import PropTypes from "prop-types";

import { I18n } from "react-i18nify";

import InputLabel from "components/ReduxForm/InputLabel";

import { getPlaceholderText } from "utils/utils";
import FormError from "components/Form/FormError";

const renderInputField = ({
  input: { name, onBlur, onChange, type, value, ...input },
  min,
  max,
  placeholder,
  placeholderTranslation,
  label,
  labelTranslation,
  inputClassName,
  disabled,
  hidden,
  replacementTranslations,
  inputOnChange,
  rows,
  warning,
  meta: { touched, error, submitError },
  dataTestId,
}) => (
  <I18n
    render={() => {
      const placeholderText = getPlaceholderText({
        label,
        placeholder,
        placeholderTranslation,
        labelTranslation,
        replacementTranslations: replacementTranslations.placeholder,
      });

      const warningMessage = warning && touched ? warning(value) : "";

      return (
        <div data-testid={dataTestId}>
          <InputLabel
            name={name}
            label={label}
            labelTranslation={labelTranslation}
            replacementTranslations={replacementTranslations.label}
          />
          <Input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...input}
            value={value}
            placeholder={placeholderText}
            type={type}
            id={name}
            disabled={disabled}
            hidden={hidden}
            min={min}
            max={max}
            className={inputClassName}
            rows={rows}
            onBlur={onBlur}
            onChange={(e) => {
              onChange(e);
              inputOnChange(e);
            }}
            data-field-name={name}
            data-testid={name}
          />
          <FormError
            touched={touched}
            className="invalid-text"
            error={error}
            submitError={submitError}
            warning={warningMessage}
            name={name}
          />
        </div>
      );
    }}
  />
);

renderInputField.defaultProps = {
  placeholder: "",
  label: undefined,
  disabled: false,
  hidden: false,
  min: undefined,
  max: undefined,
  inputClassName: "",
  labelTranslation: undefined,
  placeholderTranslation: undefined,
  replacementTranslations: {
    label: {},
    placeholder: {},
  },
  inputOnChange: () => {},
  dataTestId: "",
};

renderInputField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  placeholderTranslation: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  labelTranslation: PropTypes.string,
  inputClassName: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  replacementTranslations: PropTypes.shape({
    placeholder: PropTypes.shape({}),
    label: PropTypes.shape({}),
  }),
  inputOnChange: PropTypes.func,
  dataTestId: PropTypes.string,
};

export default renderInputField;
