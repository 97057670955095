import { createSearchAction } from "redux-search";
import { toastr } from "react-redux-toastr";

import { t } from "react-i18nify";

import { SettingApi } from "internal";

import {
  displayFetchErrorToaster,
  getServerError,
  getAndDisplayErrors,
} from "utils/utils";

export const SETTING_FETCH_REQUEST = "SETTING_FETCH_REQUEST";
export const SETTING_FETCH_RECEIVED = "SETTING_FETCH_RECEIVED";
export const SETTING_FETCH_FAILED = "SETTING_FETCH_FAILED";
export const SETTING_FETCH_CLEAR_ERRORS = "SETTING_FETCH_CLEAR_ERRORS";
export const SETTING_UPDATE_REQUEST = "SETTING_UPDATE_REQUEST";
export const SETTING_UPDATE_RECEIVED = "SETTING_UPDATE_RECEIVED";
export const SETTING_UPDATE_FAILED = "SETTING_UPDATE_FAILED";
export const SETTING_CREATE_REQUEST = "SETTING_CREATE_REQUEST";
export const SETTING_CREATE_RECEIVED = "SETTING_CREATE_RECEIVED";
export const SETTING_CREATE_FAILED = "SETTING_CREATE_FAILED";
export const SINGLE_SETTING_FETCH_REQUEST = "SINGLE_SETTING_FETCH_REQUEST";
export const SINGLE_SETTING_FETCH_RECEIVED = "SINGLE_SETTING_FETCH_RECEIVED";
export const SINGLE_SETTING_FETCH_FAILED = "SINGLE_SETTING_FETCH_FAILED";
export const SINGLE_SETTING_DELETE_REQUEST = "SINGLE_SETTING_DELETE_REQUEST";
export const SINGLE_SETTING_DELETE_RECEIVED = "SINGLE_SETTING_DELETE_RECEIVED";
export const SINGLE_SETTING_DELETE_FAILED = "SINGLE_SETTING_DELETE_FAILED";
export const SETTING_SEARCH_REQUEST = "SETTING_SEARCH_REQUEST";
export const CLEAR_SETTING = "CLEAR_SETTING";

// Fetch all settings
export function settingFetchReceived(response) {
  return {
    type: SETTING_FETCH_RECEIVED,
    settings: response.data,
  };
}

export function settingFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: SETTING_FETCH_FAILED,
    message,
  };
}

export function settingFetchClearErrors() {
  return {
    type: SETTING_FETCH_CLEAR_ERRORS,
  };
}

export const settingFetchRequest = () => (dispatch) => {
  dispatch({ type: SETTING_FETCH_REQUEST });

  SettingApi.fetchAll()
    .then((data) => dispatch(settingFetchReceived(data)))
    .catch((e) => {
      dispatch(settingFetchFailed(e));
    });
};

// Update single setting
export function settingUpdateReceived(response) {
  const appSettingTranslation = t("title.resource.appSetting");

  toastr.success(
    t("common.success"),
    t("toasterMessage.resource.update", {
      resource: appSettingTranslation,
    })
  );

  return {
    type: SETTING_UPDATE_RECEIVED,
    setting: response.data,
  };
}

export function settingUpdateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: SETTING_UPDATE_FAILED,
    message: response.data,
  };
}

export const settingUpdateRequest =
  (id, attributes, callback) => async (dispatch) => {
    dispatch({ type: SETTING_UPDATE_REQUEST });

    await SettingApi.update(id, attributes)
      .then((data) => {
        dispatch(settingUpdateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(settingUpdateFailed(errors, callback));
      });
  };

// Create new setting
export function settingCreateReceived(response) {
  return {
    type: SETTING_CREATE_RECEIVED,
    setting: response.data,
  };
}

export function settingCreateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: SETTING_CREATE_FAILED,
    message: response.data,
  };
}

export const settingCreateRequest =
  (attributes, callback) => async (dispatch) => {
    dispatch({ type: SETTING_CREATE_REQUEST });

    await SettingApi.create(attributes)
      .then((data) => {
        dispatch(settingCreateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(settingCreateFailed(errors, callback));
      });
  };

// Fetch single setting
export function singleSettingFetchReceived(response) {
  return {
    type: SINGLE_SETTING_FETCH_RECEIVED,
    setting: response.data,
  };
}

export function singleSettingFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: SINGLE_SETTING_FETCH_FAILED,
    message,
  };
}

export const singleSettingFetchRequest = (id) => (dispatch) => {
  dispatch({ type: SINGLE_SETTING_FETCH_REQUEST });

  SettingApi.fetchOne(id)
    .then((data) => dispatch(singleSettingFetchReceived(data)))
    .catch((e) => {
      dispatch(singleSettingFetchFailed(e));
    });
};

// Delete single setting
export function singleSettingDeleteReceived(response, callback) {
  return (dispatch) => {
    dispatch({
      type: SINGLE_SETTING_DELETE_RECEIVED,
    });

    const appSettingTranslation = t("title.resource.appSetting");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.delete", {
        resource: appSettingTranslation,
      })
    );

    if (typeof callback === "function") {
      callback();
    }
  };
}

export function singleSettingDeleteFailed(errorData) {
  const { response } = errorData;
  const error = getServerError(response);
  const errorText =
    error === "not found" ? t("toasterMessage.processError") : error;

  displayFetchErrorToaster(errorText);

  return (dispatch) => {
    dispatch({
      type: SINGLE_SETTING_DELETE_FAILED,
      message: errorText,
    });
  };
}

export const singleSettingDeleteRequest = (id, callback) => (dispatch) => {
  dispatch({ type: SINGLE_SETTING_DELETE_REQUEST });

  SettingApi.deleteOne(id)
    .then((data) => dispatch(singleSettingDeleteReceived(data, callback)))
    .catch((e) => {
      dispatch(singleSettingDeleteFailed(e));
    });
};

export const searchSettings = createSearchAction("settings");

export const settingSearchRequest = (value) => {
  const isSearching = Boolean(value);

  return {
    type: SETTING_SEARCH_REQUEST,
    isSearching,
  };
};

export const clearSingleSetting = () => ({
  type: CLEAR_SETTING,
});
