import { setTranslationsGetter, setLocaleGetter } from "react-i18nify";

const configureLocaleWithStore = (store) => {
  setTranslationsGetter(() => store.getState().i18n.translations || {});

  setLocaleGetter(() => {
    try {
      return store.getState().i18n.locale || "da-DK";
    } catch (e) {
      return "da-DK";
    }
  });
};

export default configureLocaleWithStore;
