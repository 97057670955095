import apiUrlConfig from "configs/apiUrlConfig";
import { AuthenticatedRequest } from "internal";

const URLS = {
  INDEX: `${apiUrlConfig.apiEndPoint()}/pbxes/:pbx_id/user_licenses?filter=:filterName`,
  SHOW: `${apiUrlConfig.apiEndPoint()}/user_licenses/:id`,
  UPDATE: `${apiUrlConfig.apiEndPoint()}/user_licenses/:id`,
  CREATE: `${apiUrlConfig.apiEndPoint()}/user_licenses`,
  FETCH_ALL: `${apiUrlConfig.apiEndPoint()}/user_licenses/all`,
  DELETE: `${apiUrlConfig.apiEndPoint()}/user_licenses/:id`,
  DID_NUMBERS: `${apiUrlConfig.apiEndPoint()}/user_licenses/:user_licenses_id/did_numbers?filter=:filterName`,
};

class UserLicenseApi extends AuthenticatedRequest {
  /**
   * Fetch all user_licenses
   * @param {string} filter filter to fetch filtered user license
   */
  static fetchAll(pbxID, filter) {
    if (!pbxID) return this.get(URLS.FETCH_ALL);

    const url = URLS.INDEX.replace(":pbx_id", pbxID);
    const filterUrl = url.replace(":filterName", filter);

    return this.get(filterUrl);
  }

  /**
   * Fetch single user_license by its ID
   *
   * @param {int} id ID of a user_license to be fetched
   */
  static fetchOne(id) {
    const fetchOneUrl = URLS.SHOW.replace(":id", id);

    return this.get(fetchOneUrl);
  }

  /**
   * Update a user_license
   *
   * @param {int} id ID of a user_license
   *
   * @param {object} attributes attributes of a user_license
   * @param {string} name name of a user_license
   * @param {number} payment_type payment type for a user_license (post paid OR pre paid)
   * @param {number} max_channels maximum channel for a user_license
   * @param {number} remote_routing_profile_id routing profile id for a user_license
   * @param {number} remote_campaign_routing_profile_id campaign routing profile for a user_license
   * @param {number} remote_call_rate_id call rate id for a user_license
   * @param {date} deployment_date deployment date for a user_license
   * @param {date} invoice_start_date invoice start date for a user_license
   * @param {number} odoo_debtor_id debtor for whom user_license belongs to
   * @param {number} agreement_id agreement where user_license subscriptions are saved
   *
   * @param {object} agreement_attributes attributes for an agreement for linking to user_license
   * @param {string} invoice_period invoice period for agreement invoicing
   *
   */
  static update(id, attributes) {
    const updateUrl = URLS.UPDATE.replace(":id", id);

    const userAttributes = attributes.user;
    const { user_role: userRoleAttributes, ...rest } = userAttributes;

    const requestBody = {
      user_license: {
        ...attributes,
        user_attributes: {
          ...rest,
          user_roles_attributes: [
            {
              id: userRoleAttributes.id,
              role_id: userRoleAttributes.role_id,
            },
          ],
        },
      },
    };

    return this.patch(updateUrl, requestBody);
  }

  /**
   * Create a user_license
   *
   * @param {object} attributes attributes of a user_license
   * @param {string} name name of a user_license
   * @param {number} payment_type payment type for a user_license (post paid OR pre paid)
   * @param {number} max_channels maximum channel for a user_license
   * @param {number} remote_routing_profile_id routing profile id for a user_license
   * @param {number} remote_campaign_routing_profile_id campaign routing profile for a user_license
   * @param {number} remote_call_rate_id call rate id for a user_license
   * @param {date} deployment_date deployment date for a user_license
   * @param {date} invoice_start_date invoice start date for a user_license
   * @param {number} odoo_debtor_id debtor for whom user_license belongs to
   * @param {number} agreement_id agreement where user_license subscriptions are saved
   *
   * @param {object} agreement_attributes attributes for an agreement for linking to user_license
   * @param {string} invoice_period invoice period for agreement invoicing
   *
   */
  static create(attributes) {
    const userAttributes = attributes.user_attributes;
    const { user_role_attributes: userRoleAttributes, ...rest } =
      userAttributes;

    const requestBody = {
      user_license: {
        ...attributes,
        user_attributes: {
          ...rest,
          user_roles_attributes: [
            {
              role_id: userRoleAttributes.role_id,
            },
          ],
        },
      },
    };

    return this.post(URLS.CREATE, requestBody);
  }

  /**
   * delete pbx
   *
   * @param {int} id ID of a pbx to user license
   */
  static deleteOne(id) {
    const updateUrl = URLS.DELETE.replace(":id", id);

    return this.delete(updateUrl);
  }

  /**
   *
   * @param {int} userLicenseId ID of a user_license
   */
  static fetchDidNumbers(userLicenseId, filterName) {
    const fetchDidNumbersUrl = URLS.DID_NUMBERS.replace(
      ":user_licenses_id",
      userLicenseId
    ).replace(":filterName", filterName);

    return this.get(fetchDidNumbersUrl);
  }
}

export default UserLicenseApi;
