import {
  COUNTRY_FETCH_REQUEST,
  COUNTRY_FETCH_RECEIVED,
  COUNTRY_FETCH_FAILED,
  COUNTRY_FETCH_CLEAR_ERRORS,
} from "redux/actions/country";

const defaultAuthState = {
  countries: [],
  countryFetchError: "",
};

function country(state = defaultAuthState, action) {
  const { countries } = action;

  switch (action.type) {
    case COUNTRY_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case COUNTRY_FETCH_RECEIVED:
      return {
        ...state,
        countries,
        isLoading: false,
      };

    case COUNTRY_FETCH_FAILED:
      return {
        ...state,
        countryFetchError: action.message,
        isLoading: false,
      };

    case COUNTRY_FETCH_CLEAR_ERRORS:
      return { ...state, countryFetchError: "", isLoading: false };

    default:
      return state;
  }
}

export default country;
