import { CallRateApi } from "internal";
import { getAndDisplayErrors } from "utils/utils";

export const CALL_RATE_FETCH_REQUEST = "CALL_RATE_FETCH_REQUEST";
export const CALL_RATE_FETCH_RECEIVED = "CALL_RATE_FETCH_RECEIVED";
export const CALL_RATE_FETCH_FAILED = "CALL_RATE_FETCH_FAILED";
export const CALL_RATE_FETCH_CLEAR_ERRORS = "CALL_RATE_FETCH_CLEAR_ERRORS";

// Fetch all Routing Profiles
export function callRateFetchReceived(response) {
  return {
    type: CALL_RATE_FETCH_RECEIVED,
    callRates: response.data,
  };
}

export function callRateFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: CALL_RATE_FETCH_FAILED,
    message,
  };
}

export const callRateFetchRequest = () => (dispatch) => {
  dispatch({ type: CALL_RATE_FETCH_REQUEST });

  CallRateApi.fetchAll()
    .then((data) => dispatch(callRateFetchReceived(data)))
    .catch((e) => {
      dispatch(callRateFetchFailed(e));
    });
};
