import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.pbxEndPoint()}/routing_profiles`,
};

class RoutingProfileApi extends AuthenticatedRequest {
  /**
   * Fetch all routing profiles
   *
   */
  static fetchAll() {
    return this.get(URLS.INDEX);
  }
}

export default RoutingProfileApi;
