import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import HasPermission from "hoc/RbacCTA";

const TabWithRoute = ({ resourceName, children, routesDetail }) => {
  const tabRoutes = routesDetail.map(
    ({ tabRoute, tabTitle, perform, data }) => {
      if (!perform)
        return (
          <NavLink
            key={tabRoute}
            to={tabRoute}
            data-cy={`${resourceName}-${tabRoute}`}
          >
            {tabTitle}
          </NavLink>
        );

      return (
        <HasPermission perform={perform} data={data}>
          <NavLink
            key={tabRoute}
            to={tabRoute}
            data-cy={`${resourceName}-${tabRoute}`}
          >
            {tabTitle}
          </NavLink>
        </HasPermission>
      );
    }
  );

  return (
    <div className="tab-route-container shadow-sm d-flex flex-column">
      <div className="tab-route-header text-secondary">{tabRoutes}</div>
      <div className="tab-route-content">{children}</div>
    </div>
  );
};

TabWithRoute.propTypes = {
  resourceName: PropTypes.string.isRequired,
  routesDetail: PropTypes.shape([]).isRequired,
  children: PropTypes.element.isRequired,
};

export default TabWithRoute;
