import React, { useState } from "react";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import { InvoiceApi } from "internal";

import DotsLoadingIndicator from "components/Spinner/DotsLoadingIndicator";

import { getAndDisplayErrors } from "utils/utils";

const InvoiceDownloadButton = ({ id, fileName }) => {
  const [isLoading, setisLoading] = useState(false);

  const loadingIcon = isLoading ? <DotsLoadingIndicator /> : null;

  const onClick = (e) => {
    e.stopPropagation();
    setisLoading(true);
    InvoiceApi.downloadOne(id)
      .then((pdf) => {
        const blob = new Blob([pdf.data]);
        const link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileName}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setisLoading(false);
      })
      .catch((errorData) => {
        getAndDisplayErrors(errorData);
        setisLoading(false);
      });
  };

  return (
    <button
      type="button"
      disabled={isLoading}
      className="btn btn-primary"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faDownload} />
      <Translate value="common.download" />
      <span className="ms-2">{loadingIcon}</span>
    </button>
  );
};

InvoiceDownloadButton.propTypes = {
  id: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default InvoiceDownloadButton;
