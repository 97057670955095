import React, { useState } from "react";
import { Form } from "react-final-form";
import { t, Translate } from "react-i18nify";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Navigate } from "react-router-dom";

import AssignSimCardForm from "components/SimCard/Form/AssignSimCardForm";
import {
  assignSIMCardRequest,
  clearSimCardErrors,
} from "redux/actions/simCard";

import { SIM_CARD } from "constants/routes";
import { SIM_CARD_ASSIGN_TYPE } from "constants/constant";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const AssignSimCard = ({
  isLoading,
  commitSuccess,
  assignSIMCard,
  clearError,
}) => {
  const [isReseller, setReseller] = useState(false);
  const [simCardHolder, setSimCardHolder] = useState(null);

  const onSubmit = async (
    {
      imsi_number_range_start: imsiStart,
      imsi_number_range_end: imsiEnd,
      ...rest
    },
    form
  ) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    if (isReseller) {
      await assignSIMCard(
        {
          sim_card: {
            ...rest,
            imsi_number_range_start: imsiStart,
            imsi_number_range_end: imsiEnd,
          },
        },
        SIM_CARD_ASSIGN_TYPE.RESELLER,
        (errors) => {
          serverResponse = errors;
        }
      );
    } else {
      await assignSIMCard(
        {
          sim_card_borrower: {
            ...rest,
            imsi_number_range_start: imsiStart,
            imsi_number_range_end: imsiEnd,
          },
        },
        SIM_CARD_ASSIGN_TYPE.BORROW_SIM_CARD,
        (errors) => {
          serverResponse = errors;
        }
      );
    }

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess) {
    const simCardTranslation = t("common.simCard");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.assigned", {
        resource: simCardTranslation,
      })
    );

    clearError();

    return <Navigate to={SIM_CARD.INDEX} />;
  }

  return (
    <div className="container-fluid assign-sim-card-form">
      <div>
        <h3 className="mt-3">
          <Translate value="common.assignSimCard" />
        </h3>
      </div>
      <Form onSubmit={onSubmit} keepDirtyOnReinitialize>
        {({ handleSubmit, submitting, pristine }) => (
          <form className="flexo-form px-4 py-4 w-100" onSubmit={handleSubmit}>
            <AssignSimCardForm
              submitting={submitting}
              pristine={pristine}
              isReseller={isReseller}
              setReseller={setReseller}
              simCardHolder={simCardHolder}
              setSimCardHolder={setSimCardHolder}
              isLoading={isLoading}
            />
          </form>
        )}
      </Form>
    </div>
  );
};

AssignSimCard.defaultProps = {
  isLoading: false,
  commitSuccess: false,
  assignSIMCard: () => {},
};

AssignSimCard.propTypes = {
  isLoading: PropTypes.bool,
  commitSuccess: PropTypes.bool,
  assignSIMCard: PropTypes.func,
  clearError: PropTypes.func.isRequired,
};

const mapStateToProps = ({ simCard }) => {
  const { isSubmitting, commitSuccess } = simCard;

  return {
    isLoading: isSubmitting,
    commitSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  assignSIMCard: async (attributes, type, callback) => {
    await dispatch(assignSIMCardRequest(attributes, type, callback));
  },
  clearError: () => {
    dispatch(clearSimCardErrors());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignSimCard);
