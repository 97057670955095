import { t } from "react-i18nify";

import { createSearchAction } from "redux-search";
import { toastr } from "react-redux-toastr";
import { getAndDisplayErrors } from "utils/utils";

import { AgreementInvoiceApi } from "internal";

import { INVOICE } from "constants/routes";

export const AGREEMENT_INVOICE_FETCH_REQUEST =
  "AGREEMENT_INVOICE_FETCH_REQUEST";
export const AGREEMENT_INVOICE_FETCH_RECEIVED =
  "AGREEMENT_INVOICE_FETCH_RECEIVED";
export const AGREEMENT_INVOICE_FETCH_FAILED = "AGREEMENT_INVOICE_FETCH_FAILED";
export const AGREEMENT_INVOICE_GENERATE_REQUEST =
  "AGREEMENT_INVOICE_GENERATE_REQUEST";
export const AGREEMENT_INVOICE_GENERATE_RECEIVED =
  "AGREEMENT_INVOICE_GENERATE_RECEIVED";
export const AGREEMENT_INVOICE_GENERATE_FAILED =
  "AGREEMENT_INVOICE_GENERATE_FAILED";
export const AGREEMENT_INVOICE_SEARCH_REQUEST =
  "AGREEMENT_INVOICE_SEARCH_REQUEST";

export function invoiceFetchReceived(response) {
  return {
    type: AGREEMENT_INVOICE_FETCH_RECEIVED,
    data: response.data,
  };
}

export function invoiceFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: AGREEMENT_INVOICE_FETCH_FAILED,
    message,
  };
}

export const invoiceFetchRequest = (agreementID) => (dispatch) => {
  dispatch({ type: AGREEMENT_INVOICE_FETCH_REQUEST });

  AgreementInvoiceApi.fetchAll(agreementID)
    .then((data) => dispatch(invoiceFetchReceived(data)))
    .catch((e) => {
      dispatch(invoiceFetchFailed(e));
    });
};

export function invoiceGenerateRecieved(response) {
  const { protocol, host } = window.location;
  const basePath = protocol.concat("//", host);

  const invoiceID = response.data;
  const invoiceUrl = INVOICE.addId(INVOICE.DETAIL, invoiceID);
  const invoiceDetailsUrl = basePath.concat(invoiceUrl);

  const toastrOptions = {
    timeOut: 0,
    showCloseButton: true,
    closeOnToastrClick: false,
    onToastrClick: () => window.open(invoiceDetailsUrl, "_blank").focus(),
  };

  return (dispatch) => {
    dispatch({
      type: AGREEMENT_INVOICE_GENERATE_RECEIVED,
      data: invoiceID,
    });

    toastr.success(
      t("toasterMessage.viewGeneratedInvoiceHere"),
      invoiceDetailsUrl,
      toastrOptions
    );
  };
}

export function invoiceGenerateFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: AGREEMENT_INVOICE_GENERATE_FAILED,
    message,
  };
}

export const invoiceGenerateRequest = (agreementID) => (dispatch) => {
  dispatch({ type: AGREEMENT_INVOICE_GENERATE_REQUEST });

  AgreementInvoiceApi.runInvoicing(agreementID)
    .then((data) => dispatch(invoiceGenerateRecieved(data)))
    .catch((e) => {
      dispatch(invoiceGenerateFailed(e));
    });
};

export const searchInvoices = createSearchAction("agreementInvoices");

export const invoiceSearchRequest = (value) => {
  const isSearching = Boolean(value);

  return {
    type: AGREEMENT_INVOICE_SEARCH_REQUEST,
    isSearching,
  };
};
