import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  faCalendarDay,
  faBriefcase,
  faEnvelope,
  faUser,
  faHandshakeAngle,
  faFileContract,
} from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-i18nify";
import TranslateDate from "components/i18n/TranslateDate";

import { DEBTOR, USER, AGREEMENT } from "constants/routes";

import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import BindingPeriodDetail from "components/Quotation/Detail/BindingPeriodDetail";

const QuotationBasicDetail = ({ quotation }) => {
  const {
    debtor_name: debtorName,
    odoo_debtor_id: odooDebtorID,
    seller_full_name: sellerName,
    seller_id: sellerID,
    debtor_email: debtorEmail,
    sent_date: sentDate,
    is_accepted_by_customer: isAcceptedByCustomer,
    agreement_id: agreementID,
    agreement_ref_number: agreementReferenceNumber,
    validity_period_in_days: validityPeriodInDays,
    quotation_subscription_binding_periods: bindingPeriods,
  } = quotation;

  const basicDetailContent = [
    {
      icon: faBriefcase,
      title: <Translate value="attributes.debtor" />,
      content: (
        <>
          {debtorName}
          <Link to={DEBTOR.addId(DEBTOR.SHOW, odooDebtorID)} className="ml-1">
            {" ( "}
            <Translate value="common.viewDetails" />
            {" )"}
          </Link>
        </>
      ),
      dataCyFieldName: "debtor",
    },
    {
      icon: faUser,
      title: <Translate value="attributes.seller" />,
      content: (
        <>
          {sellerName}
          <Link to={USER.addId(USER.SHOW, sellerID)} className="ml-1">
            {" ( "}
            <Translate value="common.viewDetails" />
            {" )"}
          </Link>
        </>
      ),
      dataCyFieldName: "seller",
    },
    {
      icon: faEnvelope,
      title: <Translate value="attributes.customerEmail" />,
      content: debtorEmail,
      dataCyFieldName: "customer-email",
    },
    {
      icon: faCalendarDay,
      title: <Translate value="attributes.validityPeriod" />,
      content: (
        <Translate value="common.numberOfDays" days={validityPeriodInDays} />
      ),
      dataCyFieldName: "validity-period",
    },
    sentDate && {
      icon: faCalendarDay,
      title: <Translate value="attributes.sentDate" />,
      content: <TranslateDate date={sentDate} />,
      dataCyFieldName: "sent-date",
    },
    isAcceptedByCustomer && {
      icon: faHandshakeAngle,
      title: <Translate value="attributes.agreement" />,
      content: (
        <>
          {agreementReferenceNumber}
          <Link
            to={AGREEMENT.addId(AGREEMENT.SHOW, agreementID)}
            className="ml-1"
          >
            {" ( "}
            <Translate value="common.viewDetails" />
            {" )"}
          </Link>
        </>
      ),
      dataCyFieldName: "agreement-details",
    },
    {
      icon: faFileContract,
      title: <Translate value="common.bindingPeriods" />,
      content: <BindingPeriodDetail bindingPeriods={bindingPeriods} />,
      dataCyFieldName: "validity-period",
    },
  ];

  return (
    <div>
      <CardWithHeader
        title={<Translate value="common.basicDetails" />}
        className="shadow-sm"
        cardContent={basicDetailContent}
        dataCy="quotation"
      />
    </div>
  );
};

QuotationBasicDetail.defaultProps = {};

QuotationBasicDetail.propTypes = {
  quotation: PropTypes.shape({
    debtor_name: PropTypes.string,
    odoo_debtor_id: PropTypes.number,
    seller_full_name: PropTypes.string,
    seller_id: PropTypes.number,
    debtor_email: PropTypes.string,
    sent_date: PropTypes.instanceOf(Date),
    is_accepted_by_customer: PropTypes.bool,
    agreement_id: PropTypes.number,
    agreement_ref_number: PropTypes.string,
    validity_period_in_days: PropTypes.string,
    quotation_subscription_binding_periods: PropTypes.arrayOf(
      PropTypes.shape({})
    ),
  }).isRequired,
};

export default QuotationBasicDetail;
