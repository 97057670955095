import React from "react";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";

import { Translate } from "react-i18nify";
import TranslateDate from "components/i18n/TranslateDate";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import { Row } from "reactstrap";

const renderActivityLogTableTranslateDate = ({ cellData }) => (
  <TranslateDate date={cellData} />
);

const ActivityLogTable = ({ activityLogs }) => {
  if (activityLogs.length === 0) return null;

  return (
    <Row className="h-30em">
      <RecordListingTable
        records={activityLogs}
        isLoading={false}
        resourceTitle={<Translate value="numberPortingRequest.activityLogs" />}
        hideAddButton
        hideSearchBar
        disableRowClick
      >
        <Column
          width={200}
          label={<Translate value="attributes.status" />}
          dataKey="status"
          flexGrow={1}
        />
        <Column
          width={150}
          label={<Translate value="attributes.date" />}
          dataKey="activity_datetime"
          flexGrow={1}
          cellRenderer={renderActivityLogTableTranslateDate}
        />
      </RecordListingTable>
    </Row>
  );
};

ActivityLogTable.propTypes = {
  activityLogs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      status: PropTypes.string,
      activity_datetime: PropTypes.instanceOf(Date),
    })
  ).isRequired,
};

export default ActivityLogTable;
