import { createSearchAction } from "redux-search";
import { toastr } from "react-redux-toastr";
import { t } from "react-i18nify";

import { QuotationApi } from "internal";

import {
  getAndDisplayErrors,
  displayFetchErrorToaster,
  getServerError,
} from "utils/utils";

import { QUOTATION_STATUSES } from "constants/constant";

export const QUOTATION_FETCH_REQUEST = "QUOTATION_FETCH_REQUEST";
export const QUOTATION_FETCH_RECEIVED = "QUOTATION_FETCH_RECEIVED";
export const QUOTATION_FETCH_FAILED = "QUOTATION_FETCH_FAILED";

export const SINGLE_QUOTATION_FETCH_REQUEST = "SINGLE_QUOTATION_FETCH_REQUEST";
export const SINGLE_QUOTATION_FETCH_RECEIVED =
  "SINGLE_QUOTATION_FETCH_RECEIVED";
export const SINGLE_QUOTATION_FETCH_FAILED = "SINGLE_QUOTATION_FETCH_FAILED";

export const QUOTATION_SEARCH_REQUEST = "QUOTATION_SEARCH_REQUEST";

export const QUOTATION_PREVIEW_REQUEST = "QUOTATION_PREVIEW_REQUEST";
export const QUOTATION_PREVIEW_FAILED = "QUOTATION_PREVIEW_FAILED";
export const QUOTATION_PREVIEW_RECEIVED = "QUOTATION_PREVIEW_RECEIVED";

export const SEND_QUOTATION_EMAIL_FAILED = "SEND_QUOTATION_EMAIL_FAILED";
export const SEND_QUOTATION_EMAIL_RECEIVED = "SEND_QUOTATION_EMAIL_RECEIVED";
export const SEND_QUOTATION_EMAIL_REQUEST = "SEND_QUOTATION_EMAIL_REQUEST";

export const TERMS_AND_CONDITIONS_PREVIEW_REQUEST =
  "TERMS_AND_CONDITIONS_PREVIEW_REQUEST";
export const TERMS_AND_CONDITIONS_PREVIEW_FAILED =
  "TERMS_AND_CONDITIONS_PREVIEW_FAILED";
export const TERMS_AND_CONDITIONS_PREVIEW_RECEIVED =
  "TERMS_AND_CONDITIONS_PREVIEW_RECEIVED";

export const QUOTATION_CREATE_REQUEST = "QUOTATION_CREATE_REQUEST";
export const QUOTATION_CREATE_RECEIVED = "QUOTATION_CREATE_RECEIVED";
export const QUOTATION_CREATE_FAILED = "QUOTATION_CREATE_FAILED";

export const QUOTATION_UPDATE_REQUEST = "QUOTATION_UPDATE_REQUEST";
export const QUOTATION_UPDATE_RECEIVED = "QUOTATION_UPDATE_RECEIVED";
export const QUOTATION_UPDATE_FAILED = "QUOTATION_UPDATE_FAILED";

export const QUOTATION_DELETE_REQUEST = "QUOTATION_DELETE_REQUEST";
export const QUOTATION_DELETE_RECEIVED = "QUOTATION_DELETE_RECEIVED";
export const QUOTATION_DELETE_FAILED = "QUOTATION_DELETE_FAILED";

export const CONVERT_TO_AGREEMENT_REQUEST = "CONVERT_TO_AGREEMENT_REQUEST";
export const CONVERT_TO_AGREEMENT_RECEIVED = "CONVERT_TO_AGREEMENT_RECEIVED";
export const CONVERT_TO_AGREEMENT_FAILED = "CONVERT_TO_AGREEMENT_FAILED";

export const searchQuotations = createSearchAction("quotations");

export function quotationFetchReceived(response) {
  return {
    type: QUOTATION_FETCH_RECEIVED,
    quotations: response.data,
  };
}

export function quotationFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: QUOTATION_FETCH_FAILED,
    message,
  };
}

// fetch all quotations
export const quotationFetchRequest =
  (status = QUOTATION_STATUSES.SENT) =>
  (dispatch) => {
    dispatch({ type: QUOTATION_FETCH_REQUEST });

    QuotationApi.fetchAll(status)
      .then((data) => dispatch(quotationFetchReceived(data)))
      .catch((errors) => {
        dispatch(quotationFetchFailed(errors));
      });
  };

// fetch single quotation
export function singleQuotationFetchReceived(response) {
  return {
    type: SINGLE_QUOTATION_FETCH_RECEIVED,
    quotation: response.data,
  };
}

export function singleQuotationFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: SINGLE_QUOTATION_FETCH_FAILED,
    message,
  };
}

export const singleQuotationFetchRequest = (id) => (dispatch) => {
  dispatch({ type: SINGLE_QUOTATION_FETCH_REQUEST });

  QuotationApi.fetchOne(id)
    .then((data) => dispatch(singleQuotationFetchReceived(data)))
    .catch((errors) => {
      dispatch(singleQuotationFetchFailed(errors));
    });
};

export function quotationSearchRequest(value) {
  const isSearching = Boolean(value);

  return {
    type: QUOTATION_SEARCH_REQUEST,
    isSearching,
  };
}

// create a quotation
export function quotationCreateReceived(response) {
  return {
    type: QUOTATION_CREATE_RECEIVED,
    quotation: response.data,
  };
}

export function quotationCreateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: QUOTATION_CREATE_FAILED,
    message: response.data,
  };
}

export const quotationCreateRequest =
  (values, callback) => async (dispatch) => {
    dispatch({ type: QUOTATION_CREATE_REQUEST });

    await QuotationApi.create(values)
      .then((data) => {
        dispatch(quotationCreateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(quotationCreateFailed(errors, callback));
      });
  };

// Update single quotation
export function quotationUpdateReceived(response) {
  return {
    type: QUOTATION_UPDATE_RECEIVED,
    quotation: response.data,
  };
}

export function quotationUpdateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: QUOTATION_UPDATE_FAILED,
    message: response.data,
  };
}

export const quotationUpdateRequest =
  (id, attributes, callback) => async (dispatch) => {
    dispatch({ type: QUOTATION_UPDATE_REQUEST });

    await QuotationApi.update(id, attributes)
      .then((data) => {
        dispatch(quotationUpdateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(quotationUpdateFailed(errors, callback));
      });
  };

export const quotationDeleteReceived = (response, callback) => (dispatch) => {
  dispatch({
    type: QUOTATION_DELETE_RECEIVED,
  });

  toastr.success(
    t("common.success"),
    t("toasterMessage.resource.delete", {
      resource: t("attributes.quotation"),
    })
  );

  if (typeof callback === "function") {
    callback();
  }
};

export const quotationDeleteFailed = (errorData) => {
  const { response } = errorData;
  const error = getServerError(response);
  const errorText =
    error === "not found" ? t("toasterMessage.processError") : error;

  displayFetchErrorToaster(errorText);

  return (dispatch) => {
    dispatch({
      type: QUOTATION_DELETE_FAILED,
      message: errorText,
    });
  };
};

export const quotationDeleteRequest = (id, callback) => (dispatch) => {
  dispatch({
    type: QUOTATION_DELETE_REQUEST,
  });

  QuotationApi.deleteOne(id)
    .then((data) => dispatch(quotationDeleteReceived(data, callback)))
    .catch((errors) => {
      dispatch(quotationDeleteFailed(errors));
    });
};

export function quotationPreviewFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: QUOTATION_PREVIEW_FAILED,
    message,
  };
}

export const quotationPreviewReceived = (response) => ({
  type: QUOTATION_PREVIEW_RECEIVED,
  quotationPreview: response.data,
});

export const quotationPreviewRequest = (id) => (dispatch) => {
  dispatch({ type: QUOTATION_PREVIEW_REQUEST });

  QuotationApi.previewQuotation(id)
    .then((data) => dispatch(quotationPreviewReceived(data)))
    .catch((errors) => {
      dispatch(quotationPreviewFailed(errors));
    });
};

export function sendEmailToCustomerFailed(errorData) {
  const { response } = errorData;
  const error = getServerError(response);

  return {
    type: SEND_QUOTATION_EMAIL_FAILED,
    message: error,
  };
}

export const sendEmailToCustomerReceived = (response) => (dispatch) => {
  dispatch({
    type: SEND_QUOTATION_EMAIL_RECEIVED,
    quotation: response.data,
  });

  toastr.success(
    t("common.success"),
    t("toasterMessage.quotation.quotationHasBeenSentToTheCustomer")
  );
};

export const sendEmailToCustomerRequest = (id) => (dispatch) => {
  dispatch({ type: SEND_QUOTATION_EMAIL_REQUEST });

  QuotationApi.sendEmailToCustomer(id)
    .then((data) => dispatch(sendEmailToCustomerReceived(data)))
    .catch((errors) => {
      dispatch(sendEmailToCustomerFailed(errors));
    });
};

export function termsAndConditionsPreviewFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: TERMS_AND_CONDITIONS_PREVIEW_FAILED,
    message,
  };
}

export const termsAndConditionsPreviewReceived = (response) => ({
  type: TERMS_AND_CONDITIONS_PREVIEW_RECEIVED,
  termsAndConditionsPreview: response.data,
});

export const termsAndConditionsPreviewRequest = (fileName) => (dispatch) => {
  dispatch({ type: TERMS_AND_CONDITIONS_PREVIEW_REQUEST });

  QuotationApi.previewTermsAndConditions(fileName)
    .then((data) => dispatch(termsAndConditionsPreviewReceived(data)))
    .catch((errors) => {
      dispatch(termsAndConditionsPreviewFailed(errors));
    });
};

export function convertToAgreementFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: CONVERT_TO_AGREEMENT_FAILED,
    message: response.data,
  };
}

export const convertToAgreementReceived = (response) => (dispatch) => {
  dispatch({
    type: CONVERT_TO_AGREEMENT_RECEIVED,
    agreement: response.data,
  });

  toastr.success(
    t("common.success"),
    t("toasterMessage.quotation.quotationHasBeenConvertedToAnAgreement")
  );
};

export const convertToAgreementRequest =
  (id, attributes, callback) => async (dispatch) => {
    dispatch({ type: CONVERT_TO_AGREEMENT_REQUEST });

    await QuotationApi.convertToAgreement(id, attributes)
      .then((data) => {
        dispatch(convertToAgreementReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(convertToAgreementFailed(errors, callback));
      });
  };
