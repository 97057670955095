import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";
import { Translate } from "react-i18nify";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";

import {
  invoiceFetchRequest,
  invoiceSearchRequest,
  searchInvoices,
} from "redux/actions/agreement/invoice";
import { singleAgreementFetchRequest } from "redux/actions/agreement";

import filterSearchResults from "utils/filterSearchResults";
import { getCurrentUser } from "utils/selectors";

import HasPermission from "hoc/RbacCTA";

import { AGREEMENT } from "constants/routes";
import { ADMIN_ROLES } from "constants/userRoles";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import TranslateNumber from "components/i18n/TranslateNumber";
import TranslateDate from "components/i18n/TranslateDate";
import InvoiceDownloadButton from "components/Button/InvoiceDownloadButton ";
import LinkHeader from "components/ResourceHeader/LinkHeader";
import ResourceListSkeleton from "components/Skeleton/ResourceList/ResourceListSkeleton";

const renderAgreementInvoiceTranslateDate = ({ cellData }) => (
  <TranslateDate date={cellData} />
);

const renderAgreementInvoiceTranslateNumber = ({ cellData }) => (
  <TranslateNumber value={cellData} minimumFractionDigits={2} />
);

const renderInvoiceDownloadButton = ({ cellData }) => {
  if (cellData.state.toLowerCase() === "draft") return null;

  return (
    <InvoiceDownloadButton id={cellData.id} fileName={cellData.fileName} />
  );
};

const AgreementInvoiceIndex = ({
  invoices,
  isLoading,
  className,
  currentUserRole,
  parentID,
  searchRecords,
  isSearching,
  fetchInvoices,
  fetchError,
  fetchSingleAgreement,
  isAgreementLoading,
  agreement,
}) => {
  const { agreementID } = useParams();

  useEffect(() => {
    fetchSingleAgreement(agreementID);
    fetchInvoices(agreementID);
  }, [agreementID]);

  if (isAgreementLoading || !agreement || isLoading || !invoices) {
    return <ResourceListSkeleton resourceName="invoices" />;
  }

  const recordsWithDownload = invoices.map((record) => {
    const newRecord = { ...record };

    newRecord.download = {
      id: record.id,
      state: record.state,
      fileName: record.number || record.invoice_date,
    };

    return newRecord;
  });

  const title = (
    <>
      {`${agreement.ref_number} `}
      <Translate value="title.page.resource.list" resource="Invoice" />
    </>
  );

  const isAdmin = ADMIN_ROLES.includes(currentUserRole);

  return (
    <HasPermission
      data={{
        debtorID: agreement.odoo_debtor_id,
      }}
      perform="invoices:list"
      redirect
    >
      <RecordListingTable
        records={isAdmin ? invoices : recordsWithDownload}
        isLoading={isLoading}
        resourceName="invoices"
        resourceTitle={title}
        additionalHeaderContent={
          <div className="d-md-flex align-items-center justify-content-end">
            <LinkHeader
              translationValue="attributes.creditNotes"
              itemClassName="w-100"
              resource={AGREEMENT.addId(
                AGREEMENT.CREDIT_NOTE_INDEX,
                agreementID
              )}
              icon={faFileInvoiceDollar}
              resourceName="agreement-invoice-index"
              subResource="credit-notes"
            />
          </div>
        }
        className={className}
        parentID={parentID}
        searchRecords={searchRecords}
        isSearching={isSearching}
        fetchError={fetchError}
        onRetry={fetchInvoices}
        recordDetailIDAttribute="odoo_invoice_id"
        hideAddButton
        disableRowClick={!isAdmin}
      >
        <Column
          label={<Translate value="attributes.number" />}
          dataKey="number"
          width={180}
        />
        <Column
          width={220}
          label={<Translate value="attributes.dateInvoice" />}
          dataKey="invoice_date"
          cellRenderer={renderAgreementInvoiceTranslateDate}
        />
        <Column
          width={200}
          label={<Translate value="attributes.dateDue" />}
          dataKey="invoice_date_due"
          cellRenderer={renderAgreementInvoiceTranslateDate}
        />
        <Column
          width={200}
          label={<Translate value="attributes.total" />}
          dataKey="amount_total_signed"
          cellRenderer={renderAgreementInvoiceTranslateNumber}
        />
        <Column
          width={230}
          label={<Translate value="attributes.invoicedUntil" />}
          dataKey="invoiced_until"
          cellRenderer={renderAgreementInvoiceTranslateDate}
        />
        <Column
          width={130}
          label={<Translate value="attributes.status" />}
          dataKey="state"
        />
        {!isAdmin && (
          <Column
            width={220}
            label="Download"
            dataKey="download"
            cellRenderer={renderInvoiceDownloadButton}
          />
        )}
      </RecordListingTable>
    </HasPermission>
  );
};

AgreementInvoiceIndex.defaultProps = {
  isLoading: true,
  className: "",
  parentID: undefined,
  invoices: undefined,
  currentUserRole: undefined,
  isSearching: false,
  fetchError: null,
  isAgreementLoading: false,
};

AgreementInvoiceIndex.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      agreement_ref_number: PropTypes.string,
      partner_id: PropTypes.arrayOf[PropTypes.any],
    })
  ),
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  parentID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fetchInvoices: PropTypes.func.isRequired,
  searchRecords: PropTypes.func.isRequired,
  currentUserRole: PropTypes.string,
  isSearching: PropTypes.bool,
  fetchError: PropTypes.string,
  agreement: PropTypes.shape({
    ref_number: PropTypes.string,
    odoo_debtor_id: PropTypes.number,
  }).isRequired,
  fetchSingleAgreement: PropTypes.func.isRequired,
  isAgreementLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { invoices, isLoading, isSearching, fetchError } =
    state.agreementBilling.invoice;
  const { role: currentUserRole } = getCurrentUser(state);
  const {
    agreement,
    isLoading: isAgreementLoading,
    fetchError: fetchAgreeementError,
  } = state.agreement;

  const results = filterSearchResults({
    resource: invoices,
    searchResult: state.search.agreementInvoices,
  });

  return {
    invoices: results,
    isLoading,
    isSearching,
    fetchError,
    currentUserRole,
    agreement,
    isAgreementLoading,
    fetchAgreeementError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchRecords: (e) => {
    dispatch(invoiceSearchRequest(e));
    dispatch(searchInvoices(e));
  },
  fetchInvoices: (agreementID) => {
    dispatch(invoiceFetchRequest(agreementID));
  },
  fetchSingleAgreement: (id) => {
    dispatch(singleAgreementFetchRequest(id, false, true));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgreementInvoiceIndex);
