import React, { useEffect } from "react";
import { connect } from "react-redux";
import { t } from "react-i18nify";
import PropTypes from "prop-types";
import { toastr } from "react-redux-toastr";
import qs from "qs";

import {
  setPasswordRequest,
  resetPasswordClearErrors,
} from "redux/actions/auth";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

import LoginPageWrapper from "components/Login/LoginPageWrapper";
import SetPasswordForm from "components/Login/SetPassword";

const SetPassword = ({
  clearErrors,
  setPassword,
  resetPasswordToken,
  isLoading,
  setPasswordSuccessMessage,
  setPasswordError,
}) => {
  useEffect(() => {
    clearErrors();
  }, []);

  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    const { password, password_confirmation: passwordConfirmation } = values;

    await setPassword(
      password,
      passwordConfirmation,
      resetPasswordToken,
      (errors) => {
        serverResponse = errors;
      }
    );

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (setPasswordSuccessMessage) {
    toastr.success(t("common.success"), t(setPasswordSuccessMessage));
    clearErrors();
  }
  if (setPasswordError) {
    toastr.error(t("common.error"), t(setPasswordError));
    clearErrors();
  }

  return (
    <LoginPageWrapper>
      <SetPasswordForm onSubmit={handleSubmit} isLoading={isLoading} />
    </LoginPageWrapper>
  );
};

SetPassword.defaultProps = {};

SetPassword.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setPassword: PropTypes.func.isRequired,
  resetPasswordToken: PropTypes.string.isRequired,
  setPasswordError: PropTypes.string.isRequired,
  setPasswordSuccessMessage: PropTypes.string.isRequired,
  clearErrors: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }, { location }) => {
  const queryParams = qs.parse(location.search);
  const resetPasswordToken = queryParams["?token"];
  const { isLoading, setPasswordError, setPasswordSuccessMessage } = auth;

  return {
    setPasswordSuccessMessage,
    setPasswordError,
    isLoading,
    resetPasswordToken,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setPassword: async (
    password,
    passwordConfirmation,
    resetPasswordToken,
    callback
  ) => {
    await dispatch(
      setPasswordRequest(
        password,
        passwordConfirmation,
        resetPasswordToken,
        callback
      )
    );
  },
  clearErrors: () => {
    dispatch(resetPasswordClearErrors());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
