import {
  USER_LICENSE_DID_NUMBER_FETCH_REQUEST,
  USER_LICENSE_DID_NUMBER_FETCH_FAILED,
  USER_LICENSE_DID_NUMBER_FETCH_RECEIVED,
  USER_LICENSE_DID_NUMBER_SEARCH_REQUEST,
} from "redux/actions/userLicense/didNumber";

const defaultState = {
  didNumbers: [],
  commitError: "",
  fetchError: "",
  commitSuccess: false,
  isLoading: false,
  isSearching: false,
};

function didNumber(state = defaultState, action) {
  const { didNumbers } = action;

  switch (action.type) {
    case USER_LICENSE_DID_NUMBER_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitError: "",
      };

    case USER_LICENSE_DID_NUMBER_FETCH_RECEIVED:
      return {
        ...state,
        didNumbers,
        isLoading: false,
      };

    case USER_LICENSE_DID_NUMBER_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case USER_LICENSE_DID_NUMBER_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    default:
      return state;
  }
}

export default didNumber;
