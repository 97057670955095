import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { fetchSipDeviceDefaultFormValueRequest } from "redux/actions/sipDevices";

import WholePageSpinner from "components/Spinner/WholePageSpinner";
import SipDeviceFormContent from "components/SipDevices/Form/SipDeviceFormContent";

const SipDeviceCreate = ({ isLoading, sipDevice, fetchDefaultFormValues }) => {
  const { userLicenseID } = useParams();

  useEffect(() => {
    fetchDefaultFormValues(userLicenseID);
  }, []);

  if (isLoading) {
    return <WholePageSpinner />;
  }

  const { extension_number: extensionNumber, description } = sipDevice;

  const initialValue = {
    extension_number: extensionNumber,
    ex_description: description,
  };

  return (
    <SipDeviceFormContent
      initialValues={initialValue}
      parentType="userLicense"
      userLicenseID={userLicenseID}
    />
  );
};

const mapStateToProps = (state) => {
  const { sipDevice, isLoading } = state.sipDevice;

  return {
    sipDevice,
    isLoading,
  };
};

SipDeviceCreate.defaultProps = {
  sipDevice: {},
  isLoading: false,
};

const mapDispatchToProps = (dispatch) => ({
  fetchDefaultFormValues: (id) => {
    dispatch(fetchSipDeviceDefaultFormValueRequest(id));
  },
});

SipDeviceCreate.propTypes = {
  sipDevice: PropTypes.shape({
    id: PropTypes.number,
    extension_number: PropTypes.number,
    user_license_id: PropTypes.number,
    description: PropTypes.string,
    ex_name: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  fetchDefaultFormValues: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SipDeviceCreate);
