import React from "react";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import Col from "reactstrap/lib/Col";

const DetailListSkeleton = ({ className }) => (
  <Col md={8} className="pr-0">
    <div className={className}>
      <div className="p-3 border-bottom">
        <div className="row justify-content-between align-items-center">
          <Col md={6} className="d-flex align-items-center">
            <Skeleton circle height={30} width={30} className="me-3" />
            <Skeleton height={18} width={100} />
          </Col>
          <Col md={6} className="d-flex flex-column">
            <Skeleton height={18} width={200} />
          </Col>
        </div>
      </div>
      <div className="p-3 border-bottom">
        <div className="row justify-content-between align-items-center">
          <Col md={6} className="d-flex align-items-center">
            <Skeleton circle height={30} width={30} className="me-3" />
            <Skeleton height={18} width={130} />
          </Col>
          <Col md={6} className="d-flex flex-column">
            <Skeleton height={18} width={150} />
          </Col>
        </div>
      </div>
      <div className="p-3 border-bottom">
        <div className="row justify-content-between align-items-center">
          <Col md={6} className="d-flex align-items-center">
            <Skeleton circle height={30} width={30} className="me-3" />
            <Skeleton height={18} width={60} />
          </Col>
          <Col md={6} className="d-flex flex-column">
            <Skeleton height={18} width={160} />
          </Col>
        </div>
      </div>
      <div className="p-3 border-bottom">
        <div className="row justify-content-between align-items-center">
          <Col md={6} className="d-flex align-items-center">
            <Skeleton circle height={30} width={30} className="me-3" />
            <Skeleton height={18} width={140} />
          </Col>
          <Col md={6} className="d-flex flex-column">
            <Skeleton height={18} width={140} />
          </Col>
        </div>
      </div>
      <div className="p-3 border-bottom">
        <div className="row justify-content-between align-items-center">
          <Col md={6} className="d-flex align-items-center">
            <Skeleton circle height={30} width={30} className="me-3" />
            <Skeleton height={18} width={130} />
          </Col>
          <Col md={6} className="d-flex flex-column">
            <Skeleton height={18} width={200} />
          </Col>
        </div>
      </div>
      <div className="p-3 border-bottom">
        <div className="row justify-content-between align-items-center">
          <Col md={6} className="d-flex align-items-center">
            <Skeleton circle height={30} width={30} className="me-3" />
            <Skeleton height={18} width={100} />
          </Col>
          <Col md={6} className="d-flex flex-column">
            <Skeleton height={18} width={250} />
          </Col>
        </div>
      </div>
      <div className="p-3">
        <div className="row justify-content-between align-items-center">
          <Col md={6} className="d-flex align-items-center">
            <Skeleton circle height={30} width={30} className="me-3" />
            <Skeleton height={18} width={100} />
          </Col>
          <Col md={6} className="d-flex flex-column">
            <Skeleton height={18} width={90} />
          </Col>
        </div>
      </div>
    </div>
  </Col>
);

DetailListSkeleton.defaultProps = {
  className: "",
};

DetailListSkeleton.propTypes = {
  className: PropTypes.string,
};

export default DetailListSkeleton;
