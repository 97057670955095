import React, { useState } from "react";
import { Input } from "reactstrap";
import { I18n } from "react-i18nify";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { getPlaceholderText } from "utils/utils";

import FormError from "components/Form/FormError";
import InputLabel from "components/ReduxForm/InputLabel";

const PasswordField = ({
  input: { name, onBlur, value, onChange, ...input },
  placeholder,
  placeholderTranslation,
  label,
  labelTranslation,
  inputClassName,
  disabled,
  hidden,
  replacementTranslations,
  meta: { touched, error, warning, submitError },
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const icon = showPassword ? faEyeSlash : faEye;
  const inputType = showPassword ? "text" : "password";

  return (
    <I18n
      render={() => {
        const placeholderText = getPlaceholderText({
          label,
          placeholder,
          placeholderTranslation,
          labelTranslation,
          replacementTranslations: replacementTranslations.placeholder,
        });

        return (
          <div>
            <InputLabel
              name={name}
              label={label}
              labelTranslation={labelTranslation}
              replacementTranslations={replacementTranslations.label}
            />
            <div className="position-relative">
              <Input
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...input}
                placeholder={placeholderText}
                type={inputType}
                id={name}
                disabled={disabled}
                className={inputClassName}
                hidden={hidden}
                onBlur={onBlur}
                onChange={onChange}
                data-field-name={name}
                data-testid={name}
                value={value}
              />
              <FontAwesomeIcon
                icon={icon}
                onClick={() => setShowPassword(!showPassword)}
                className="password-toggle-icon"
                data-testid="password-toggle-icon"
              />
            </div>
            <FormError
              touched={touched}
              className="invalid-text"
              error={error}
              submitError={submitError}
              warning={warning}
              name={name}
            />
          </div>
        );
      }}
    />
  );
};

PasswordField.defaultProps = {
  placeholder: "",
  label: undefined,
  disabled: false,
  hidden: false,
  labelTranslation: undefined,
  inputClassName: "",
  placeholderTranslation: undefined,
  replacementTranslations: {
    label: {},
    placeholder: {},
  },
};

PasswordField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
    submitError: PropTypes.string,
  }).isRequired,
  replacementTranslations: PropTypes.shape({
    label: PropTypes.shape({}),
    placeholder: PropTypes.shape({}),
  }),
  label: PropTypes.string,
  inputClassName: PropTypes.string,
  labelTranslation: PropTypes.string,
  placeholderTranslation: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
};

export default PasswordField;
