import React, { useState } from "react";
import { connect } from "react-redux";
import { Translate } from "react-i18nify";
import PropTypes from "prop-types";

import OrderNewSimCardModal from "components/Modal/OrderNewSimCardModal";

import HasPermission from "hoc/RbacCTA";

import { CELL_PHONE_ACTIONS } from "constants/rolePermission";

import {
  cellPhoneFetchClearErrors,
  orderSimCardRequest,
} from "redux/actions/cellPhone";

const OrderSimCard = ({ cellNumberID, clearError, orderSimCard }) => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [modalData, setModalData] = useState();

  const resetModalState = () => {
    clearError();
    setModalVisibility(false);
    setModalData();
  };

  const onConfirmOrder = () => {
    orderSimCard(cellNumberID);
    resetModalState();
  };

  return (
    <HasPermission
      perform="cellPhone:orderSIMCard"
      data={{ action: CELL_PHONE_ACTIONS.ORDER_NEW_SIM_CARD }}
    >
      <button
        className="btn btn-primary ms-2"
        type="button"
        onClick={() => setModalVisibility((prevState) => !prevState)}
      >
        <Translate value="common.orderNewSimCard" />
      </button>

      <OrderNewSimCardModal
        modalVisible={isModalVisible}
        modalData={modalData}
        onConfirm={onConfirmOrder}
        onCancel={() => setModalVisibility(false)}
      />
    </HasPermission>
  );
};

OrderSimCard.defaultProps = {};

OrderSimCard.propTypes = {
  cellNumberID: PropTypes.number.isRequired,
  clearError: PropTypes.func.isRequired,
  orderSimCard: PropTypes.func.isRequired,
};

const mapStateToProps = ({ cellPhone: cellPhoneResource }) => {
  const { commitError, commitSuccess, isSubmitting } = cellPhoneResource;

  return {
    commitSuccess,
    error: commitError,
    isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearError: () => {
    dispatch(cellPhoneFetchClearErrors());
  },
  orderSimCard: (id) => {
    dispatch(orderSimCardRequest(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderSimCard);
