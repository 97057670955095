import { AuthenticatedRequest } from "internal";
import apiUrlConfig from "configs/apiUrlConfig";

import { replaceMultiple } from "utils/string";

const URLS = {
  INDEX: `${apiUrlConfig.apiEndPoint()}/number_porting_requests?filter[porting_type]=:portingType&filter[number_type]=:numberType&filter[status]=:status`,
  UPDATE: `${apiUrlConfig.apiEndPoint()}/number_porting_requests/:ref_number`,
  IMPORT_REQUEST_CUSTOMER_FORM_UPDATE: `${apiUrlConfig.apiEndPoint()}/number_porting_import_request_forms/:ref_number`,
  CREATE: `${apiUrlConfig.apiEndPoint()}/number_porting_requests`,
  SHOW: `${apiUrlConfig.apiEndPoint()}/number_porting_requests/:ref_number`,
  IMPORT_REQUEST_CUSTOMER_FORM_SHOW: `${apiUrlConfig.apiEndPoint()}/number_porting_import_request_forms/:ref_number`,
  DOWNLOAD_PHONE_NUMBER_IMPORT_CSV_TEMPLATE: `${apiUrlConfig.apiEndPoint()}/number_porting_requests/phone_number_import_template`,
  ACCEPT_IMPORT_REQUEST: `${apiUrlConfig.apiEndPoint()}/number_porting_requests/:refNumber/accept_import_request`,
  POLL_IMPORT_STATUS: `${apiUrlConfig.apiEndPoint()}/number_porting_requests/:refNumber/poll_import_status`,
  CANCEL_ALL_IMPORT_FLOWS: `${apiUrlConfig.apiEndPoint()}/number_porting_requests/:refNumber/cancel_all_import_flows`,
};

class NumberPortingRequestApi extends AuthenticatedRequest {
  /**
   * Create a number_porting_request
   *
   * @param {object} attributes attributes of a number_porting_request
   * @param {string} porting_type porting type of a number_porting_request, one of [import, export]
   * @param {string} number_type service number type of a number_porting_request, one of [did_number, cell_phone]
   * @param {string} customer_email email of a customer to send notification about import request
   * @param {number} odoo_debtor_id debtor for whom number porting request is generated
   * @param {number} pbx_id pbx to store phone numbers requested in number porting request
   * @param {string} person_filling_the_request_form person to fill the porting request form, one of [customer, super_admin]
   * @param {Date} requested_porting_date general porting date for the request
   *
   */
  static create(attributes) {
    const requestBody = {
      number_porting_request: {
        ...attributes,
      },
    };

    return this.post(URLS.CREATE, requestBody);
  }

  /**
   * Fetch all number porting requests in the system
   * @param {string} portingType type of porting request, one of ['import', 'export']
   * @param {string} numberType type of service, one of ['cell_phone', 'did_number']
   * @param {string} status status of the request, one of ['active', 'archived']
   *
   */
  static fetchAll(portingType, numberType, portingStatus) {
    const replaceWith = {
      ":portingType": portingType,
      ":numberType": numberType,
      ":status": portingStatus,
    };

    return this.get(replaceMultiple(URLS.INDEX, replaceWith));
  }

  /**
   * Update a number_porting_request
   *
   * @param {string} refNumber unique reference number of a number_porting_request
   *
   * @param {object} attributes attributes of a number_porting_request
   * :porting_type, :number_type, :customer_email, :odoo_debtor_id, :pbx_id, :person_filling_the_request_form
   * @param {Date} requested_porting_date general porting date for the request
   *
   * @param {object[]} number_porting_request_phone_numbers_attributes attributes for the list of requested phone numbers
   * @param {Date} requested_porting_date requested porting date for the requested phone number
   * @param {number} icc_number ICC Number of the requested phone number
   * @param {number} service_number Number of the requested phone number
   *
   */
  static update(refNumber, attributes, customerForm) {
    const updateUrl = customerForm
      ? URLS.IMPORT_REQUEST_CUSTOMER_FORM_UPDATE
      : URLS.UPDATE;
    const withReplacedRefNumber = updateUrl.replace(":ref_number", refNumber);

    const requestBody = {
      number_porting_request: {
        ...attributes,
      },
    };

    return this.patch(withReplacedRefNumber, requestBody);
  }

  /**
   * Fetch single number_porting_request
   *
   * @param {int} refNumber Reference number of a number_porting_request to fetch
   */
  static fetchOne(refNumber, customerForm) {
    const fetchOneUrl = customerForm
      ? URLS.IMPORT_REQUEST_CUSTOMER_FORM_SHOW
      : URLS.SHOW;
    const withReplacedRefNumber = fetchOneUrl.replace(":ref_number", refNumber);

    return this.get(withReplacedRefNumber);
  }

  /**
   * Download csv template file for importing phone numbers
   */
  static downloadPhoneNumberImportCSVTemplate(numberType) {
    const url = `${URLS.DOWNLOAD_PHONE_NUMBER_IMPORT_CSV_TEMPLATE}?number_type=${numberType}`;

    return this.get(url, true);
  }

  /**
   * Accept requested numbers from customer to create request for those numbers in NP Server
   */
  static acceptImportRequest(refNumber) {
    const url = URLS.ACCEPT_IMPORT_REQUEST.replace(":refNumber", refNumber);

    return this.patch(url);
  }

  /**
   * Poll import status of number porting request phone numbers
   */
  static pollImportStatus(refNumber) {
    const url = URLS.POLL_IMPORT_STATUS.replace(":refNumber", refNumber);

    return this.get(url);
  }

  /**
   * Cancel all import flows inside the number porting request
   *
   * @param {string} refNumber reference number of a number porting request
   *
   */
  static cancelAllImportFlows(refNumber) {
    const url = URLS.CANCEL_ALL_IMPORT_FLOWS.replace(":refNumber", refNumber);

    return this.patch(url, {});
  }
}

export default NumberPortingRequestApi;
