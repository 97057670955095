import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form, FormGroup } from "reactstrap";
import { Field, Form as ReactForm } from "react-final-form";
import { Translate } from "react-i18nify";

import { composeValidators, email, required } from "utils/validation";

import { logoutRequest } from "redux/actions/auth";

import { renderInputField } from "components/ReduxForm/RenderField";
import CheckBoxField from "components/ReduxForm/CheckBoxField";
import PasswordField from "components/ReduxForm/PasswordField/PasswordField";
import DeactivatedBanner from "components/Banner/DeactivatedBanner";
import Spinner from "components/Spinner/Spinner";

import { AUTH } from "constants/routes";

const username = (value) => email(value);

const LoginForm = ({ onSubmit, isLoading, currentUser, logout }) => {
  const [isDeactivated, setIsDeactivated] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser.is_active === false) {
      setIsDeactivated(true);
      logout();
    }
  }, [currentUser]);

  const hideBanner = () => setIsDeactivated(false);

  return (
    <>
      <ReactForm
        onSubmit={onSubmit}
        validate={required}
        render={({ handleSubmit, pristine, submitting }) => (
          <Form
            className="login-form w-100"
            data-testid="login-form"
            onSubmit={handleSubmit}
          >
            <h4 className="mb-4 text-secondary login-information">
              <Translate value="auth.loginLabel" />
            </h4>
            {isDeactivated && (
              <DeactivatedBanner
                text={<Translate value="auth.deactivatedAccount" />}
                hideBanner={hideBanner}
              />
            )}
            <FormGroup>
              <Field
                name="username"
                type="text"
                inputClassName="form-control-lg"
                component={renderInputField}
                labelTranslation="auth.emailField"
                placeholder="user@sc.dk"
                validate={composeValidators((required, username))}
              />
            </FormGroup>
            <FormGroup className="mt-4 ">
              <Field
                name="password"
                inputClassName="form-control-lg"
                placeholderTranslation="auth.passwordPlaceholder"
                component={PasswordField}
                labelTranslation="auth.passwordField"
                validate={required}
                type="password"
              />
            </FormGroup>
            <FormGroup className="mt-4 d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
              <Field
                initialValue
                name="keepMeLoggedIn"
                component={CheckBoxField}
                labelClassName="fw-normal"
                labelTranslation="auth.keepMeLoggedIn"
              />
              <Link
                to={AUTH.FORGOT_PASSWORD}
                data-cy="forgot-password"
                data-testid="forgot-password"
              >
                <Translate value="auth.forgotPassword" />
              </Link>
            </FormGroup>
            <div className="d-grid gap-2">
              <button
                disabled={pristine || submitting || isLoading}
                type="submit"
                className="btn btn-primary my-4 btn-lg "
                data-cy="submit"
                data-testid="submit"
              >
                <Spinner isLoading={isLoading || submitting} />
                <Translate value="auth.loginText" />
              </button>
            </div>
          </Form>
        )}
      />
    </>
  );
};

LoginForm.defaultProps = {
  currentUser: {},
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape({
    is_active: PropTypes.bool,
  }),

  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => {
  const { currentUser } = auth;

  return {
    currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
