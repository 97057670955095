import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const HideShowContent = ({ showContent, content }) => {
  if (showContent) return <span>{content}</span>;

  return Array.from({ length: content.length }, (_, k) => k).map((key) => (
    <span className="mb-0 hidden-content" key={key}>
      *
    </span>
  ));
};

const CardContent = ({ content, isHideAbleContent, dataCy }) => {
  const [showContent, setContent] = useState(false);

  const icon = !showContent ? faEyeSlash : faEye;

  const iconClassName = !showContent ? "mb-2" : "";
  const containerClassName = !showContent ? "mt-2" : "";

  const handleContent = () => {
    setContent((previous) => !previous);
  };

  if (isHideAbleContent) {
    return (
      <div className={`col-md-7 ${containerClassName}`}>
        <HideShowContent showContent={showContent} content={content} />
        <FontAwesomeIcon
          icon={icon}
          className={`text-tertiary ms-3 ${iconClassName}`}
          onClick={handleContent}
        />
      </div>
    );
  }

  return (
    <div className="col-md-7" data-cy={dataCy}>
      {content}
    </div>
  );
};

HideShowContent.defaultProps = {
  showContent: false,
  content: "",
};

HideShowContent.propTypes = {
  showContent: PropTypes.bool,
  content: PropTypes.string,
};

CardContent.defaultProps = {
  content: "",
  isHideAbleContent: false,
  dataCy: "",
};

CardContent.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isHideAbleContent: PropTypes.bool,
  dataCy: PropTypes.string,
};

export default CardContent;
