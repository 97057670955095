import React from "react";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import Col from "reactstrap/lib/Col";

const TabTableSkeleton = ({ hasEdit }) => {
  const classNames = ["d-flex", "flex-column"];

  if (hasEdit) {
    classNames.push("text-right");
  }

  return (
    <>
      <div className="p-3 bg-light rounded shadow-sm border-bottom border-gray-100">
        <div className="d-flex align-items-center justify-content-between">
          {hasEdit && <Skeleton height={30} width={150} />}
          <Skeleton height={30} width={150} />
        </div>
      </div>
      <div className="bg-white rounded shadow-sm mb-4">
        <div className="p-3 border-bottom">
          <div className="row justify-content-between align-items-center">
            <Col md={6} className="d-flex align-items-center ">
              <Skeleton circle height={30} width={30} className="me-3" />
              <Skeleton height={18} width={100} />
            </Col>
            <Col md={6} className={classNames.join(" ")}>
              <Skeleton height={18} width={200} />
            </Col>
          </div>
        </div>
        <div className="p-3 border-bottom">
          <div className="row justify-content-between align-items-center">
            <Col md={6} className="d-flex align-items-center">
              <Skeleton circle height={30} width={30} className="me-3" />
              <Skeleton height={18} width={130} />
            </Col>
            <Col md={6} className={classNames.join(" ")}>
              <Skeleton height={18} width={150} />
            </Col>
          </div>
        </div>
        <div className="p-3 border-bottom">
          <div className="row justify-content-between align-items-center">
            <Col md={6} className="d-flex align-items-center">
              <Skeleton circle height={30} width={30} className="me-3" />
              <Skeleton height={18} width={60} />
            </Col>
            <Col md={6} className={classNames.join(" ")}>
              <Skeleton height={18} width={160} />
            </Col>
          </div>
        </div>
        <div className="p-3 border-bottom">
          <div className="row justify-content-between align-items-center">
            <Col md={6} className="d-flex align-items-center">
              <Skeleton circle height={30} width={30} className="me-3" />
              <Skeleton height={18} width={140} />
            </Col>
            <Col md={6} className={classNames.join(" ")}>
              <Skeleton height={18} width={140} />
            </Col>
          </div>
        </div>
      </div>
    </>
  );
};

TabTableSkeleton.defaultProps = {
  hasEdit: false,
};

TabTableSkeleton.propTypes = {
  hasEdit: PropTypes.bool,
};

export default TabTableSkeleton;
