import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

const DeactivatedBanner = ({ hideBanner, text }) => (
  <div
    className="bg-danger position-relative rounded-lg mb-4 p-3 text-white d-flex align-items-center  justify-content-between"
    data-testid="deactivated-banner"
  >
    <div className="d-flex align-items-center justify-content-center">
      <FontAwesomeIcon icon={faInfoCircle} className="icon" />
      <span className="ms-2 fw-bold">{text}</span>
    </div>
    <FontAwesomeIcon
      icon={faTimes}
      className="icon icon-close"
      onClick={hideBanner}
    />
  </div>
);

DeactivatedBanner.propTypes = {
  hideBanner: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default DeactivatedBanner;
