import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Translate, t } from "react-i18nify";
import { useForm, useField } from "react-final-form";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

import ServiceTableEmptyUI from "components/NetworkOperatorSubscriptionServices/ServiceTableEmptyUI";
import ServiceListTable from "components/NetworkOperatorSubscriptionServices/ServiceListTable";

import { FORM_TYPES } from "constants/form";

import { networkOperatorSubscriptionServiceCreateRequest } from "redux/actions/cellPhone";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const SubscriptionServiceSectionContent = ({
  fields,
  isModalVisible,
  filteredSubscriptionServices,
  setFilteredSubscriptionServices,
  addNewSubscriptionService,
  toggleModalVisibility,
  resetTemporarySubscriptionServices,
  temporarySubscriptionService,
  cellPhoneServices,
  serverErrorsForCreateAction,
  pristine,
  submitting,
}) => {
  if (fields.length === 0) {
    return (
      <ServiceTableEmptyUI
        show
        isModalVisible={isModalVisible}
        toggleModalVisibility={toggleModalVisibility}
        addNewSubscriptionService={addNewSubscriptionService}
        resetTemporarySubscriptionServices={resetTemporarySubscriptionServices}
        filteredSubscriptionServices={filteredSubscriptionServices}
        serverErrorsForCreateAction={serverErrorsForCreateAction}
        pristine={pristine}
        submitting={submitting}
      />
    );
  }

  return (
    <ServiceListTable
      fields={fields}
      filteredSubscriptionServices={filteredSubscriptionServices}
      setFilteredSubscriptionServices={setFilteredSubscriptionServices}
      modalVisible={isModalVisible}
      addNewSubscriptionService={addNewSubscriptionService}
      toggleModalVisibility={toggleModalVisibility}
      resetTemporarySubscriptionServices={resetTemporarySubscriptionServices}
      temporarySubscriptionService={temporarySubscriptionService}
      cellPhoneServices={cellPhoneServices}
      serverErrorsForCreateAction={serverErrorsForCreateAction}
      pristine={pristine}
      submitting={submitting}
    />
  );
};

const RenderTitle = ({ hideTitle }) => {
  if (hideTitle) return null;

  return (
    <h4 className="my-4">
      <Translate value="common.networkServices" />
    </h4>
  );
};

const ServiceSection = ({
  fields,
  formType,
  cellPhoneServices,
  hideTitle,
  filterSelectedServices,
  createCellPhoneNetworkService,
  commitError,
  cellPhoneID,
  pristine,
}) => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [filteredSubscriptionServices, setFilteredSubscriptionServices] =
    useState(filterSelectedServices);
  const [serverErrorsForCreateAction, setServerErrorsForCreateAction] =
    useState();
  const [isFormSubmitting, setFormSubmitting] = useState(false);

  useEffect(() => {
    setServerErrorsForCreateAction(commitError);
    setFormSubmitting(false);
  }, [commitError]);

  const toggleModalVisibility = () => {
    setModalVisibility((prevState) => !prevState);
    setFormSubmitting(false);
  };

  const form = useForm();

  const temporarySubscriptionService = useField(
    "temporary_network_operator_subscription_service"
  );

  const onCreateCallBack = (networkService) => {
    const { cell_phone_service_id: selectedServiceID } = networkService;

    const selectedNetworkService = cellPhoneServices.find(
      ({ id }) => id === selectedServiceID
    );

    const { name: selectedNetworkServiceName } = selectedNetworkService;

    fields.push(networkService);

    setFilteredSubscriptionServices((prev) =>
      prev.filter(
        ({ id: currentServiceID }) => currentServiceID !== selectedServiceID
      )
    );

    setFormSubmitting(false);

    toastr.success(
      t("common.success"),
      t(
        "toasterMessage.cellPhone.networkService.networkServiceCreatedSuccessfully",
        { networkService: selectedNetworkServiceName }
      )
    );

    toggleModalVisibility();
    form.change("temporary_network_operator_subscription_service", {});
  };

  const addNewSubscriptionService = async () => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    const {
      input: { value: subscriptionService },
      meta: { invalid },
    } = temporarySubscriptionService;

    setFormSubmitting(true);

    if (invalid) {
      setFormSubmitting(false);

      return null;
    }

    await createCellPhoneNetworkService(
      cellPhoneID,
      subscriptionService,
      (networkService) => {
        onCreateCallBack(networkService);
      },
      (errors) => {
        serverResponse = errors;
      }
    );

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  const resetTemporarySubscriptionServices = () => {
    setFormSubmitting(false);
    setServerErrorsForCreateAction();
    toggleModalVisibility();
    form.change("temporary_network_operator_subscription_service", {});
  };

  if (formType !== FORM_TYPES.CREATE) return null;

  return (
    <div>
      <RenderTitle hideTitle={hideTitle} />

      <SubscriptionServiceSectionContent
        fields={fields}
        isModalVisible={isModalVisible}
        toggleModalVisibility={toggleModalVisibility}
        addNewSubscriptionService={addNewSubscriptionService}
        resetTemporarySubscriptionServices={resetTemporarySubscriptionServices}
        filteredSubscriptionServices={filteredSubscriptionServices}
        setFilteredSubscriptionServices={setFilteredSubscriptionServices}
        temporarySubscriptionService={temporarySubscriptionService}
        cellPhoneServices={cellPhoneServices}
        serverErrorsForCreateAction={serverErrorsForCreateAction}
        pristine={pristine}
        submitting={isFormSubmitting}
      />
    </div>
  );
};

function mapStateToProps({ cellPhone: cellPhoneReducer }) {
  const { commitError } = cellPhoneReducer;

  return {
    commitError,
  };
}

const mapDispatchToProps = (dispatch) => ({
  createCellPhoneNetworkService: async (
    cellPhoneID,
    attributes,
    networkServiceCreateCallBack,
    callback
  ) => {
    await dispatch(
      networkOperatorSubscriptionServiceCreateRequest(
        cellPhoneID,
        attributes,
        networkServiceCreateCallBack,
        callback
      )
    );
  },
});

SubscriptionServiceSectionContent.defaultProps = {};

SubscriptionServiceSectionContent.propTypes = {
  fields: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.shape({
        cell_phone_service_id: PropTypes.number,
        location: PropTypes.string,
        location_constraint: PropTypes.string,
      })
    ),
    push: PropTypes.func,
    length: PropTypes.func,
  }).isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  filteredSubscriptionServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  setFilteredSubscriptionServices: PropTypes.func.isRequired,
  addNewSubscriptionService: PropTypes.func.isRequired,
  toggleModalVisibility: PropTypes.func.isRequired,
  resetTemporarySubscriptionServices: PropTypes.func.isRequired,
  temporarySubscriptionService: PropTypes.shape({
    cell_phone_service_id: PropTypes.number,
    location: PropTypes.string,
    location_constraint: PropTypes.string,
  }).isRequired,
  cellPhoneServices: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  serverErrorsForCreateAction: PropTypes.string.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

ServiceSection.defaultProps = {
  formType: "create",
  hideTitle: false,
};

ServiceSection.propTypes = {
  fields: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.shape({
        cell_phone_service_id: PropTypes.number,
        location: PropTypes.string,
        location_constraint: PropTypes.string,
      })
    ),
    push: PropTypes.func,
    length: PropTypes.func,
  }).isRequired,
  cellPhoneServices: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    is_default: PropTypes.bool,
    find: PropTypes.func,
  }).isRequired,
  formType: PropTypes.string,
  hideTitle: PropTypes.bool,
  filterSelectedServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      is_default: PropTypes.bool,
    })
  ).isRequired,
  createCellPhoneNetworkService: PropTypes.func.isRequired,
  commitError: PropTypes.string.isRequired,
  cellPhoneID: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  pristine: PropTypes.bool.isRequired,
};

RenderTitle.propTypes = {
  hideTitle: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceSection);
