import React from "react";
import { Outlet } from "react-router-dom";
import { Translate } from "react-i18nify";

import TabWithRoute from "components/Tab/TabWithRoute/TabWithRoute";

const SettingIndex = () => {
  const tabRoutesDetail = [
    {
      tabRoute: "app",
      tabTitle: <Translate value="title.page.appSetting.list" />,
    },
    {
      tabRoute: "subscription_products",
      tabTitle: <Translate value="title.tab.setting.subscriptionProducts" />,
    },
  ];

  return (
    <TabWithRoute resourceName="settings" routesDetail={tabRoutesDetail}>
      <Outlet />
    </TabWithRoute>
  );
};

export default SettingIndex;
