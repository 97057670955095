import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { Translate } from "react-i18nify";

import { singleInvoiceFetchRequest } from "redux/actions/invoice";

import getNameInitials from "utils/user";
import { capitalizeWords } from "utils/string";

import { DEBTOR, VENDORS } from "constants/routes";

import HasPermission from "hoc/RbacCTA";

import WholePageSpinner from "components/Spinner/WholePageSpinner";
import FetchError from "components/Error/FetchError";
import TranslateNumber from "components/i18n/TranslateNumber";
import TranslateDate from "components/i18n/TranslateDate";
import InvoiceDownloadButton from "components/Button/InvoiceDownloadButton ";

const InvoiceDetail = ({ invoice, fetchInvoice, fetchError }) => {
  const params = useParams();
  const { invoiceID, creditNoteID, billID } = params;

  const recordID = invoiceID || creditNoteID || billID;

  const fetchInvoiceData = () => {
    fetchInvoice(recordID);
  };

  useEffect(() => {
    fetchInvoiceData();
  }, [recordID]);

  if (fetchError) {
    return <FetchError onRetry={fetchInvoiceData} />;
  }

  if (!invoice || (invoice && invoice.id !== parseInt(recordID, 10))) {
    return <WholePageSpinner />;
  }

  const attribute = Object.keys(params)[1].replace("ID", "");

  const {
    amount_total: totalAmount,
    amount_tax: taxAmount,
    amount_untaxed: amountBeforeTax,
    state,
    number,
    invoice_date: invoiceDate,
    invoice_date_due: dueDate,
    invoice_lines: invoiceLines,
    payment_term: paymentTerm,
    debtor_name: debtorName,
    partner_id: debtorID,
  } = invoice;
  const invoiceLineArray = invoiceLines || [];

  const invoiceLineSection = invoiceLineArray.map((invoiceLine) => {
    const {
      id,
      name,
      quantity,
      product_uom_id: uomID,
      price_unit: price,
      price_subtotal: subtotal,
      product_id: productID,
    } = invoiceLine;
    const unit = uomID ? uomID[1] : "";
    const productName = productID ? productID[1] : "";

    return (
      <tr key={id}>
        <td>{productName}</td>
        <td>{name}</td>
        <td>{quantity}</td>
        <td>{unit}</td>
        <td>
          <TranslateNumber value={price} minimumFractionDigits={2} />
        </td>
        <td>
          <TranslateNumber value={subtotal} minimumFractionDigits={2} />
        </td>
      </tr>
    );
  });

  const downloadButton = recordID && (
    <InvoiceDownloadButton id={recordID} fileName={number || invoiceDate} />
  );

  return (
    <HasPermission perform="invoiceDetail:view" redirect>
      <div className="container-fluid">
        <Row className="mt-3">
          <Col md="6">
            <h3 className="mt-2">
              {number || <Translate value="invoice.detail" />}
            </h3>
          </Col>
          <Col md="6 text-right">
            <div className="d-flex align-items-center justify-content-end">
              {downloadButton}
            </div>
          </Col>
        </Row>
        <div className="invoice-detail fold py-5 px-5 mt-3">
          <div className="invoice-detail-header mt-5">
            <Row className="invoice-detail-header-row">
              <Col md="7">
                <div className="user-short-name mb-3">
                  {getNameInitials(debtorName)}
                </div>
                <div className="invoice-name mb-4">
                  <div className="debtor-name d-flex align-items-center">
                    <h4 className="mb-0">{debtorName}</h4>
                    <Link
                      to={
                        billID
                          ? VENDORS.addId(VENDORS.DETAIL, debtorID)
                          : DEBTOR.addId(DEBTOR.DETAIL, debtorID)
                      }
                      className="ml-1"
                    >
                      {"( "}
                      <Translate value="common.viewDetails" />
                      {" )"}
                    </Link>
                  </div>
                  <p className="text-secondary">{paymentTerm}</p>
                </div>
              </Col>
              <Col md="5" className="invoice-state-col">
                <h1 className="invoice-label mb-3">
                  <Translate value={`attributes.${attribute}`} />
                </h1>
                <div className="invoice-status px-3">
                  {capitalizeWords(state)}
                </div>
              </Col>
            </Row>
            <div className="invoice-basic-details">
              <Row>
                <Col md="5">
                  <div className="line">
                    <Row>
                      <Col md="6">
                        <Translate value="attributes.invoiceNumber" />
                      </Col>
                      <Col md="6">{number}</Col>
                    </Row>
                  </div>
                  <div className="line">
                    <Row>
                      <Col md="6">
                        <Translate value="attributes.dateInvoiced" />
                      </Col>
                      <Col md="6">
                        <TranslateDate date={invoiceDate} />
                      </Col>
                    </Row>
                  </div>
                  <div className="line">
                    <Row>
                      <Col md="6">
                        <Translate value="attributes.dueDate" />
                      </Col>
                      <Col md="6">
                        <TranslateDate date={dueDate} />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="invoice-lines mt-4">
            <div className="table-responsive invoice-table">
              <table className="w-100">
                <thead>
                  <tr>
                    <th>
                      <Translate value="attributes.product" />
                    </th>
                    <th>
                      <Translate value="attributes.description" />
                    </th>
                    <th>
                      <Translate value="attributes.quantity" />
                    </th>
                    <th>
                      <Translate value="attributes.unit" />
                    </th>
                    <th>
                      <Translate value="attributes.price" />
                    </th>
                    <th>
                      <Translate value="attributes.subTotal" />
                    </th>
                  </tr>
                </thead>

                <tbody>{invoiceLineSection}</tbody>
              </table>
            </div>
          </div>

          <div className="amount-section mt-4">
            <Row>
              <Col md={{ size: 6, offset: 6 }}>
                <div className="border-line">
                  <div className="line px-3">
                    <Row>
                      <Col md="6">
                        <Translate value="attributes.amountUntaxedInvoiceSigned" />
                      </Col>
                      <Col md="6">
                        <TranslateNumber
                          value={amountBeforeTax}
                          minimumFractionDigits={2}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="line px-3">
                    <Row>
                      <Col md="6">
                        <Translate value="attributes.amountTaxSigned" />
                      </Col>
                      <Col md="6">
                        <TranslateNumber
                          value={taxAmount}
                          minimumFractionDigits={2}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="line px-3">
                    <Row>
                      <Col md="6">
                        <Translate value="attributes.amountTotalSigned" />
                      </Col>
                      <Col md="6">
                        <TranslateNumber
                          value={totalAmount}
                          minimumFractionDigits={2}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </HasPermission>
  );
};

InvoiceDetail.defaultProps = {
  fetchError: null,
};

InvoiceDetail.propTypes = {
  fetchInvoice: PropTypes.func.isRequired,
  invoice: PropTypes.shape({
    id: PropTypes.number,
    number: PropTypes.number,
    amount_total: PropTypes.number,
    amount_tax: PropTypes.number,
    amount_untaxed: PropTypes.number,
    state: PropTypes.string,
    invoice_date: PropTypes.string,
    invoice_date_due: PropTypes.string,
    partner_id: PropTypes.string,
    invoice_lines: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        product_id: PropTypes.shape([]),
        quantity: PropTypes.number,
        umo_id: PropTypes.shape([]),
        price_unit: PropTypes.number,
        price_subtotal: PropTypes.number,
      })
    ),
    payment_term: PropTypes.string,
    debtor_name: PropTypes.string,
  }).isRequired,
  fetchError: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { invoice, isLoading, fetchError } = state.invoice;

  return {
    invoice,
    isLoading,
    fetchError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchInvoice: (id) => {
    dispatch(singleInvoiceFetchRequest(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail);
