import React, { useEffect } from "react";
import { Translate, t } from "react-i18nify";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import VoicemailBoxForm from "components/VoicemailBox/Form/VoicemailBoxForm";
import WholePageSpinner from "components/Spinner/WholePageSpinner";
import PageNotFound from "components/Error/PageNotFound/PageNotFound";

import {
  fetchVoicemailBoxRequest,
  updateVoicemailBoxRequest,
} from "redux/actions/voicemailBox";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

import { VOICEMAIL_BOX } from "constants/routes";

const VoicemailBoxEdit = ({
  fetchSingleVoicemailBox,
  updateVoicemailBox,
  voicemailBox,
  isLoading,
  commitSuccess,
}) => {
  const { voicemailBoxID } = useParams();

  const { email, fullname, maxmsg, maxsecs, minsecs, password } = voicemailBox;

  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};
    const { voicemail_box_attributes: attributes } = values;

    await updateVoicemailBox(voicemailBoxID, attributes, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  const fetchVoicemailBox = () => {
    fetchSingleVoicemailBox(voicemailBoxID);
  };

  useEffect(() => {
    fetchVoicemailBox();
  }, [voicemailBoxID]);

  if (commitSuccess) {
    const voicemailBoxTranslation = t("attributes.voicemailBox");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.update", {
        resource: voicemailBoxTranslation,
      })
    );

    return (
      <Navigate to={VOICEMAIL_BOX.addId(VOICEMAIL_BOX.INDEX, voicemailBoxID)} />
    );
  }

  if (isLoading) {
    return <WholePageSpinner />;
  }

  if (!voicemailBox) {
    return (
      <div className="d-flex h-100 justify-content-center">
        <PageNotFound />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="pbx-content">
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.edit"
            resource={t("attributes.voicemailBox")}
          />
        </h3>
      </div>
      <VoicemailBoxForm
        onSubmit={handleSubmit}
        showVoicemailBoxTitle={false}
        initialValues={{
          voicemail_box: {
            maxsecs,
            minsecs,
            maxmsg,
            email,
            fullname,
            password,
          },
        }}
      />
    </div>
  );
};

VoicemailBoxEdit.defaultProps = {
  isLoading: false,
  commitSuccess: false,
};

VoicemailBoxEdit.propTypes = {
  fetchSingleVoicemailBox: PropTypes.func.isRequired,
  voicemailBox: PropTypes.shape({
    email: PropTypes.string,
    extension_number: PropTypes.number,
    fullname: PropTypes.string,
    maxmsg: PropTypes.number,
    maxsecs: PropTypes.number,
    minsecs: PropTypes.number,
    parent_record_id: PropTypes.number,
    cell_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    password: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool,
  updateVoicemailBox: PropTypes.func.isRequired,
  commitSuccess: PropTypes.bool,
};

const mapStateToProps = ({ voicemailBox: voicemailBoxResource }) => {
  const { voicemailBox, isLoading, commitError, commitSuccess } =
    voicemailBoxResource;

  return {
    voicemailBox,
    isLoading,
    commitError,
    commitSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleVoicemailBox: (id) => {
    dispatch(fetchVoicemailBoxRequest(id));
  },
  updateVoicemailBox: async (id, attributes, callback) => {
    await dispatch(updateVoicemailBoxRequest(id, attributes, callback));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VoicemailBoxEdit);
