import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";
import { Translate } from "react-i18nify";
import {
  faFileInvoiceDollar,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";

import {
  invoiceFetchRequest,
  searchInvoices,
  invoiceSearchRequest,
  debtorInvoiceFetchRequest,
} from "redux/actions/invoice";

import filterSearchResults from "utils/filterSearchResults";
import { getCurrentUser } from "utils/selectors";

import HasPermission from "hoc/RbacCTA";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import TranslateNumber from "components/i18n/TranslateNumber";
import TranslateDate from "components/i18n/TranslateDate";
import InvoiceDownloadButton from "components/Button/InvoiceDownloadButton ";
import LinkHeader from "components/ResourceHeader/LinkHeader";

import { CREDIT_NOTE, DEBTOR, VENDORS, BILLS } from "constants/routes";
import { ADMIN_ROLES, BUSINESS_ADMIN_ROLES } from "constants/userRoles";

const renderInvoiceTranslateDate = ({ cellData }) => (
  <TranslateDate date={cellData} />
);

const renderInvoiceTranslateNumber = ({ cellData }) => (
  <TranslateNumber value={cellData} minimumFractionDigits={2} />
);

const renderInvoiceDownloadButton = ({ cellData }) => {
  if (cellData.state?.toLowerCase() === "draft") return null;

  return (
    <InvoiceDownloadButton id={cellData.id} fileName={cellData.fileName} />
  );
};

const InvoiceIndex = ({
  invoices,
  isLoading,
  className,
  currentUserRole,
  parentID,
  searchRecords,
  isSearching,
  fetchInvoices,
  fetchError,
  fetchDebtorInvoices,
}) => {
  const { debtorID } = useParams();
  const filterID = parentID || debtorID;

  useEffect(() => {
    if (filterID) {
      fetchDebtorInvoices(filterID);
    } else {
      fetchInvoices();
    }
  }, []);

  const recordsWithDownload = invoices.map((record) => {
    const newRecord = { ...record };

    newRecord.download = {
      id: record.id,
      state: record.state,
      fileName: record.number || record.invoice_date,
    };

    return newRecord;
  });

  const isAdmin = ADMIN_ROLES.includes(currentUserRole);

  const isBusinessAdminUser = BUSINESS_ADMIN_ROLES.includes(currentUserRole);

  const filterForResource = filterID ? "debtor" : "invoice";

  const classNameForResource = !filterID
    ? "d-md-flex align-items-center justify-content-between"
    : "";

  const debtorAndCreditNoteResource = filterID
    ? DEBTOR.addId(DEBTOR.CREDIT_NOTES, filterID)
    : CREDIT_NOTE.INDEX;

  const vendorAndBillsResource = filterID
    ? VENDORS.addId(VENDORS.BILLS, filterID)
    : BILLS.INDEX;

  const renderBillsResource = !filterID && (
    <LinkHeader
      translationValue="attributes.bills"
      itemClassName="w-100"
      resource={vendorAndBillsResource}
      icon={faReceipt}
      resourceName="invoice-index"
      subResource="bills"
    />
  );

  return (
    <HasPermission
      data={{
        debtorID: parseInt(debtorID, 10),
      }}
      perform="invoices:list"
      redirect
    >
      <RecordListingTable
        records={!isAdmin ? recordsWithDownload : invoices}
        isLoading={isLoading}
        resourceName="invoices"
        resourceTitle={
          <Translate value="title.page.resource.list" resource="Invoice" />
        }
        additionalHeaderContent={
          <HasPermission perform="invoiceCreditNotesAndBills:view">
            <div className={classNameForResource}>
              <LinkHeader
                translationValue="attributes.creditNotes"
                itemClassName={!filterID ? "w-100" : ""}
                resource={debtorAndCreditNoteResource}
                icon={faFileInvoiceDollar}
                resourceName={filterForResource}
                subResource="credit-notes"
              />
              {renderBillsResource}
            </div>
          </HasPermission>
        }
        className={className}
        parentID={parentID}
        searchRecords={searchRecords}
        isSearching={isSearching}
        fetchError={fetchError}
        onRetry={fetchInvoices}
        hideAddButton
        disableRowClick={!isBusinessAdminUser}
      >
        <Column
          label={<Translate value="attributes.number" />}
          dataKey="number"
          width={180}
        />
        {!filterID && (
          <Column
            width={300}
            label={<Translate value="attributes.debtorName" />}
            dataKey="debtor_name"
          />
        )}
        <Column
          width={220}
          label={<Translate value="attributes.dateInvoice" />}
          dataKey="invoice_date"
          cellRenderer={renderInvoiceTranslateDate}
        />
        <Column
          width={200}
          label={<Translate value="attributes.dateDue" />}
          dataKey="invoice_date_due"
          cellRenderer={renderInvoiceTranslateDate}
        />
        <Column
          width={200}
          label={<Translate value="attributes.total" />}
          dataKey="amount_total_signed"
          cellRenderer={renderInvoiceTranslateNumber}
        />
        <Column
          width={230}
          label={<Translate value="attributes.residualSigned" />}
          dataKey="residual_signed"
          cellRenderer={renderInvoiceTranslateNumber}
        />
        <Column
          width={130}
          label={<Translate value="attributes.status" />}
          dataKey="state"
        />
        {!isAdmin && (
          <Column
            width={220}
            label="Download"
            dataKey="download"
            cellRenderer={renderInvoiceDownloadButton}
          />
        )}
      </RecordListingTable>
    </HasPermission>
  );
};

InvoiceIndex.defaultProps = {
  isLoading: true,
  className: "",
  parentID: undefined,
  invoices: undefined,
  currentUserRole: undefined,
  isSearching: false,
  fetchError: null,
};

InvoiceIndex.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  parentID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fetchInvoices: PropTypes.func.isRequired,
  searchRecords: PropTypes.func.isRequired,
  currentUserRole: PropTypes.string,
  isSearching: PropTypes.bool,
  fetchError: PropTypes.string,
  fetchDebtorInvoices: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { invoices, isLoading, isSearching, fetchError } = state.invoice;
  const { role: currentUserRole } = getCurrentUser(state);
  const results = filterSearchResults({
    resource: invoices,
    searchResult: state.search.invoices,
  });

  return {
    invoices: results,
    isLoading,
    isSearching,
    fetchError,
    currentUserRole,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchRecords: (e) => {
    dispatch(invoiceSearchRequest(e));
    dispatch(searchInvoices(e));
  },
  fetchInvoices: () => {
    dispatch(invoiceFetchRequest());
  },
  fetchDebtorInvoices: (debtorID) => {
    dispatch(debtorInvoiceFetchRequest(debtorID));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceIndex);
