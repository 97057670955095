import React from "react";
import { Translate } from "react-i18nify";
import PropTypes from "prop-types";

import { SUBSCRIPTION_LINE_TYPE } from "constants/subscriptionLine";

import OtherProductsTableRow from "components/Quotation/Form/ConvertToAgreement/Modal/OtherProductsTableRow";

const OtherProductsTable = ({ fields }) => {
  const fieldsName = fields.name;

  return (
    <div className="table-responsive">
      <table
        className="table table-borderless mb-0"
        data-cy="other-products-table"
      >
        <thead className="other-products-table-headers mb-0">
          <tr className="w-100 subscription-detail add-on-detail">
            <th className="py-3">
              <p className="mb-0 fw-bold">
                <Translate value="attributes.product" />
              </p>
            </th>
            <th className="py-3 pe-4">
              <p className="mb-0 text-center fw-bold">
                <Translate value="attributes.properties" />
              </p>
            </th>
            <th className="py-3">
              <p className="mb-0 text-center fw-bold">
                <Translate value="attributes.price" />
              </p>
            </th>
            <th className="py-3 pe-4">
              <p className="mb-0 text-center fw-bold">
                <Translate value="attributes.startDate" />
              </p>
            </th>
          </tr>
        </thead>

        <tbody>
          {fields.value.map((subscriptionLine, index) => {
            const { line_type: lineType, _destroy: isDeleted } =
              subscriptionLine;
            const isSetup = lineType === SUBSCRIPTION_LINE_TYPE.SETUP;
            const isMainSubscription =
              lineType === SUBSCRIPTION_LINE_TYPE.SUBSCRIPTION;

            if (isMainSubscription || isDeleted) return null;

            return (
              <OtherProductsTableRow
                subscriptionLineIndex={index}
                isSetup={isSetup}
                subscriptionLinesFieldName={fieldsName}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

OtherProductsTable.defaultProps = {};

OtherProductsTable.propTypes = {
  fields: PropTypes.shape({
    remove: PropTypes.func,
    push: PropTypes.func,
    pop: PropTypes.func,
    length: PropTypes.func,
    insert: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
  }).isRequired,
};

export default OtherProductsTable;
