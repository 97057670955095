import {
  INVOICE_FETCH_REQUEST,
  INVOICE_FETCH_RECEIVED,
  INVOICE_FETCH_FAILED,
  SINGLE_INVOICE_FETCH_REQUEST,
  SINGLE_INVOICE_FETCH_RECEIVED,
  SINGLE_INVOICE_FETCH_FAILED,
  INVOICE_FETCH_CLEAR_ERRORS,
  INVOICE_SEARCH_REQUEST,
  DEBTOR_INVOICE_FETCH_FAILED,
  DEBTOR_INVOICE_FETCH_RECEIVED,
  DEBTOR_INVOICE_FETCH_REQUEST,
} from "redux/actions/invoice";

const defaultState = {
  invoices: [],
  fetchError: "",
  commitError: "",
  commitSuccess: false,
  isSearching: false,
};

function invoice(state = defaultState, action) {
  const { invoices, invoice: singleInvoice } = action;

  switch (action.type) {
    case INVOICE_FETCH_REQUEST:
    case DEBTOR_INVOICE_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: null,
      };

    case INVOICE_FETCH_RECEIVED:
    case DEBTOR_INVOICE_FETCH_RECEIVED:
      return {
        ...state,
        invoices,
        isLoading: false,
        fetchError: null,
      };

    case INVOICE_FETCH_FAILED:
    case DEBTOR_INVOICE_FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        fetchError: action.message,
      };

    case SINGLE_INVOICE_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        commitSuccess: false,
      };

    case SINGLE_INVOICE_FETCH_RECEIVED:
      return {
        ...state,
        invoice: singleInvoice[0],
        isLoading: false,
      };

    case SINGLE_INVOICE_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case INVOICE_FETCH_CLEAR_ERRORS:
      return {
        ...state,
        fetchError: "",
        isLoading: false,
        commitError: "",
        commitSuccess: false,
      };

    case INVOICE_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    default:
      return state;
  }
}

export default invoice;
