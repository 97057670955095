import { CellPhoneReportApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

export const CELL_PHONE_REPORT_SUBSCRIPTION_USAGE_FETCH_REQUEST =
  "CELL_PHONE_REPORT_SUBSCRIPTION_USAGE_FETCH_REQUEST";
export const CELL_PHONE_REPORT_SUBSCRIPTION_USAGE_FETCH_RECEIVED =
  "CELL_PHONE_REPORT_SUBSCRIPTION_USAGE_FETCH_RECEIVED";
export const CELL_PHONE_REPORT_SUBSCRIPTION_USAGE_FETCH_FAILED =
  "CELL_PHONE_REPORT_SUBSCRIPTION_USAGE_FAILED";

export function subscriptionUsageReportFetchReceived(response) {
  return {
    type: CELL_PHONE_REPORT_SUBSCRIPTION_USAGE_FETCH_RECEIVED,
    data: response.data,
  };
}

export function subscriptionUsageReportFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: CELL_PHONE_REPORT_SUBSCRIPTION_USAGE_FETCH_FAILED,
    message,
  };
}

export const subscriptionUsageReportFetchRequest =
  (cellNumber) => (dispatch) => {
    dispatch({ type: CELL_PHONE_REPORT_SUBSCRIPTION_USAGE_FETCH_REQUEST });

    CellPhoneReportApi.fetchSubscriptionUsage(cellNumber)
      .then((data) => dispatch(subscriptionUsageReportFetchReceived(data)))
      .catch((e) => {
        dispatch(subscriptionUsageReportFetchFailed(e));
      });
  };
