import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  searchAgreementRequest,
  clearAgreementSearch,
} from "redux/actions/agreement";
import { connect } from "react-redux";

import DashboardComponent from "components/Dashboard/DashboardComponent/DashboardComponent";
import SearchAgreementItem from "components/Dashboard/DashboardItem/SearchAgreementItem";

const AgreementSearchView = ({
  clearSearchResults,
  searchAgreements,
  searchItem,
}) => {
  useEffect(() => {
    clearSearchResults();
  }, [clearSearchResults]);

  return (
    <DashboardComponent
      title="Search"
      translationTitle="dashboard.agreement.searchTitle"
    >
      <SearchAgreementItem item={searchItem} action={searchAgreements} />
    </DashboardComponent>
  );
};

AgreementSearchView.defaultProps = {
  searchAgreements: () => {},
  clearSearchResults: () => {},
};

AgreementSearchView.propTypes = {
  searchItem: PropTypes.shape({}).isRequired,
  searchAgreements: PropTypes.func,
  clearSearchResults: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { agreement } = state;
  const { searchedAgreements, searching, searchSuccess } = agreement;

  const searchItem = {
    searchedAgreements,
    searching,
    searchSuccess,
  };

  return {
    searchItem,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchAgreements: (searchText) => {
      dispatch(searchAgreementRequest(searchText));
    },
    clearSearchResults: () => dispatch(clearAgreementSearch()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgreementSearchView);
