import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Navigate, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Translate, t } from "react-i18nify";
import { Form } from "react-final-form";

import { userLicenseCreateRequest } from "redux/actions/userLicense";
import { singlePbxFetchRequest } from "redux/actions/pbx";

import { USER_LICENSE } from "constants/routes";

import UserLicenseForm from "components/UserLicense/Form/UserLicenseForm";
import WholePageSpinner from "components/Spinner/WholePageSpinner";

import { formatRequestValues } from "utils/user";
import { processServerResponseForAsyncValidationErrors } from "utils/forms";

const UserLicenseCreate = ({
  createUserLicense,
  commitSuccess,
  userLicense,
  fetchPbx,
  pbx,
  debtorLanguage,
  isDebtorLanguageLoading,
  isLoading,
}) => {
  const { pbxID } = useParams();

  useEffect(() => {
    fetchPbx(pbxID);
  }, []);

  const onSubmitHandler = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};
    const submittedValues = values;

    submittedValues.invoice_start_date = String(
      submittedValues.invoice_start_date
    );
    submittedValues.deployment_date = String(submittedValues.deployment_date);

    const formattedValues = formatRequestValues(values);

    await createUserLicense(formattedValues, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess) {
    const { id } = userLicense;
    const userLicenseTranslation = t("attributes.userLicense");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.create", {
        resource: userLicenseTranslation,
      })
    );

    return <Navigate to={`${USER_LICENSE.addId(USER_LICENSE.SHOW, id)}`} />;
  }

  if (!pbx || (pbx && pbx.id !== parseInt(pbxID, 10))) {
    return <WholePageSpinner />;
  }

  const { agreement_id: agreementID } = pbx;

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.create"
            resource={t("attributes.userLicense")}
          />
        </h3>
      </div>
      <Form
        initialValues={{
          pbx_id: pbxID,
          subscription_attributes: { agreement_id: agreementID },
          voicemail_box: { maxsecs: 120, minsecs: 1, maxmsg: 50 },
          locale: debtorLanguage,
        }}
        onSubmit={onSubmitHandler}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, pristine }) => (
          <form
            className="flexo-form px-4 py-4 pt-5 w-100"
            onSubmit={handleSubmit}
          >
            <UserLicenseForm
              submitting={submitting}
              pristine={pristine}
              isLoading={isLoading}
              formType="create"
              isDebtorLanguageLoading={isDebtorLanguageLoading}
            />
          </form>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userLicense, isLoading, commitSuccess, commitError } =
    state.userLicense;
  const { pbx } = state.pbx;
  const { language: debtorLanguage, isProcessing: isDebtorLanguageLoading } =
    state.debtor;

  return {
    userLicense,
    isLoading,
    commitSuccess,
    commitError,
    pbx,
    debtorLanguage,
    isDebtorLanguageLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createUserLicense: async (attributes, cb) => {
    await dispatch(userLicenseCreateRequest(attributes, cb));
  },
  fetchPbx: (id) => {
    dispatch(singlePbxFetchRequest(id));
  },
});

UserLicenseCreate.defaultProps = {
  userLicense: undefined,
  commitError: {},
  debtorLanguage: "",
  isDebtorLanguageLoading: "",
  isLoading: false,
};

UserLicenseCreate.propTypes = {
  userLicense: PropTypes.shape({
    id: PropTypes.number,
  }),
  commitSuccess: PropTypes.bool.isRequired,
  commitError: PropTypes.shape({}),
  createUserLicense: PropTypes.func.isRequired,
  fetchPbx: PropTypes.func.isRequired,
  pbx: PropTypes.shape().isRequired,
  debtorLanguage: PropTypes.string,
  isDebtorLanguageLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLicenseCreate);
