import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Column } from "react-virtualized";

import { Translate } from "react-i18nify";

import { dashboardCellPhoneExportRequestFetchRequest } from "redux/actions/dashboard/cellPhoneExportRequest";

import DashboardComponent from "components/Dashboard/DashboardComponent/DashboardComponent";
import TranslateDate from "components/i18n/TranslateDate";
import DashboardComponentTable from "components/Table/DashboardComponent/DashboardComponentTable";

const renderCellPhoneExportRequestTranslateDate = ({ cellData }) => (
  <TranslateDate date={cellData} />
);

const CellPhoneExportRequestView = ({
  fetchCellPhoneExportRequest,
  cellPhoneExportRequests,
  isLoading,
  fetchError,
}) => {
  useEffect(() => {
    fetchCellPhoneExportRequest();
  }, [fetchCellPhoneExportRequest]);

  return (
    <DashboardComponent
      translationTitle="title.dashboardComponent.cellPhoneExportRequests"
      isLoading={isLoading || !cellPhoneExportRequests}
      fetchError={fetchError}
      onRetry={fetchCellPhoneExportRequest}
      columnSize={{ lg: 8, md: 12 }}
      height="30em"
    >
      <DashboardComponentTable
        records={cellPhoneExportRequests}
        resourceName="number_porting_requests/exports"
        recordDetailIDAttribute="ref_number"
      >
        <Column
          width={120}
          label={<Translate value="attributes.flowID" />}
          dataKey="ref_number"
        />
        <Column
          width={150}
          label={<Translate value="attributes.phone" />}
          dataKey="service_number"
        />
        <Column
          width={200}
          label={<Translate value="attributes.requestedPortingDate" />}
          dataKey="requested_porting_date"
          cellRenderer={renderCellPhoneExportRequestTranslateDate}
        />
        <Column
          width={200}
          label={<Translate value="attributes.requestReceivedDate" />}
          dataKey="created_at"
          cellRenderer={renderCellPhoneExportRequestTranslateDate}
        />
      </DashboardComponentTable>
    </DashboardComponent>
  );
};

CellPhoneExportRequestView.defaultProps = {
  fetchCellPhoneExportRequest: () => {},
  cellPhoneExportRequests: null,
  isLoading: false,
  fetchError: false,
};

CellPhoneExportRequestView.propTypes = {
  fetchCellPhoneExportRequest: PropTypes.func,
  cellPhoneExportRequests: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  fetchError: PropTypes.bool,
};

const mapStateToProps = ({ dashboard: { cellPhoneExportRequest } }) => ({
  ...cellPhoneExportRequest,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCellPhoneExportRequest: () =>
    dispatch(dashboardCellPhoneExportRequestFetchRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CellPhoneExportRequestView);
