import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import {
  cellPhoneFetchRequest,
  cellPhoneSearchRequest,
  searchCellPhones,
} from "redux/actions/pbx/cellPhone";

import filterSearchResults from "utils/filterSearchResults";
import { getParams } from "utils/queryString";

import CellPhoneIndexContent from "components/CellPhone/Index/CellPhoneIndexContent";

import { RESOURCE_NAMES } from "constants/constant";

const CellPhoneIndex = ({
  cellPhones,
  isLoading,
  isSearching,
  parentActiveStatus,
  cellPhoneFetchRequest: fetchAllCellPhones,
  parentID,
  searchRecords,
}) => {
  const { search } = useLocation();
  const { filter = "active_under_pbx" } = getParams(search);

  useEffect(() => {
    fetchAllCellPhones(parentID, filter);
  }, [parentID, filter]);

  return (
    <CellPhoneIndexContent
      parentActiveStatus={parentActiveStatus}
      parentIdentifierKey="pbx_id"
      parentID={parentID}
      cellPhones={cellPhones}
      isLoading={isLoading}
      isSearching={isSearching}
      searchRecords={searchRecords}
      parentResourceName={RESOURCE_NAMES.PBX}
    />
  );
};

CellPhoneIndex.defaultProps = {
  parentID: undefined,
  isLoading: true,
  cellPhones: undefined,
  isSearching: false,
};

CellPhoneIndex.propTypes = {
  cellPhones: PropTypes.arrayOf(
    PropTypes.shape({
      cell_number: PropTypes.number,
      invoice_start_date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]),
      deployment_date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]),
      is_default: PropTypes.bool,
    })
  ),
  cellPhoneFetchRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  parentID: PropTypes.string,
  parentActiveStatus: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool,
  searchRecords: PropTypes.func.isRequired,
};

const mapStateToProps = ({ pbxCellPhone, search }) => {
  const { cellPhones, isLoading, isSearching } = pbxCellPhone;

  const results = filterSearchResults({
    resource: cellPhones,
    searchResult: search.pbxCellPhones,
  });

  return {
    cellPhones: results,
    isLoading,
    isSearching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  cellPhoneFetchRequest: (pbxID, filter) => {
    dispatch(cellPhoneFetchRequest(pbxID, filter));
  },
  searchRecords: (e) => {
    dispatch(cellPhoneSearchRequest(e));
    dispatch(searchCellPhones(e));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CellPhoneIndex);
