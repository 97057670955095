import React, { useState } from "react";
import { Translate } from "react-i18nify";
import { useForm, useField } from "react-final-form";
import PropTypes from "prop-types";

import ServiceTableEmptyUI from "components/CellPhone/Form/NetworkOperatorSubscriptionServices/ServiceTableEmptyUI";
import ServiceListTable from "components/CellPhone/Form/NetworkOperatorSubscriptionServices/ServiceListTable";

import { FORM_TYPES } from "constants/form";

const SubscriptionServiceSectionContent = ({
  fields,
  isModalVisible,
  filteredSubscriptionServices,
  setFilteredSubscriptionServices,
  addNewSubscriptionService,
  toggleModalVisibility,
  resetTemporarySubscriptionServices,
  temporarySubscriptionService,
  cellPhoneServices,
}) => {
  if (fields.length === 0) {
    return (
      <ServiceTableEmptyUI
        show
        isModalVisible={isModalVisible}
        toggleModalVisibility={toggleModalVisibility}
        addNewSubscriptionService={addNewSubscriptionService}
        resetTemporarySubscriptionServices={resetTemporarySubscriptionServices}
        filteredSubscriptionServices={filteredSubscriptionServices}
      />
    );
  }

  return (
    <ServiceListTable
      fields={fields}
      filteredSubscriptionServices={filteredSubscriptionServices}
      setFilteredSubscriptionServices={setFilteredSubscriptionServices}
      modalVisible={isModalVisible}
      addNewSubscriptionService={addNewSubscriptionService}
      toggleModalVisibility={toggleModalVisibility}
      resetTemporarySubscriptionServices={resetTemporarySubscriptionServices}
      temporarySubscriptionService={temporarySubscriptionService}
      cellPhoneServices={cellPhoneServices}
    />
  );
};

const RenderTitle = ({ hideTitle }) => {
  if (hideTitle) return null;

  return (
    <h4 className="my-4">
      <Translate value="common.networkServices" />
    </h4>
  );
};

const ServiceSection = ({
  fields,
  formType,
  cellPhoneServices,
  hideTitle,
  filterSelectedServices,
}) => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [filteredSubscriptionServices, setFilteredSubscriptionServices] =
    useState(filterSelectedServices);

  const form = useForm();

  const temporarySubscriptionService = useField(
    "temporary_network_operator_subscription_service"
  );

  const toggleModalVisibility = () => {
    setModalVisibility((prevState) => !prevState);
  };

  const addNewSubscriptionService = () => {
    const {
      input: { value: subscriptionService },
      meta: { invalid },
    } = temporarySubscriptionService;

    const { cell_phone_service_id: selectedServiceID } = subscriptionService;

    if (invalid) {
      return;
    }

    fields.push(subscriptionService);

    setFilteredSubscriptionServices((prev) =>
      prev.filter(
        ({ id: currentServiceID }) => currentServiceID !== selectedServiceID
      )
    );

    toggleModalVisibility();
    form.change("temporary_network_operator_subscription_service", {});
  };

  const resetTemporarySubscriptionServices = () => {
    toggleModalVisibility();
    form.change("temporary_network_operator_subscription_service", {});
  };

  if (formType !== FORM_TYPES.CREATE) return null;

  return (
    <div>
      <RenderTitle hideTitle={hideTitle} />

      <SubscriptionServiceSectionContent
        fields={fields}
        isModalVisible={isModalVisible}
        toggleModalVisibility={toggleModalVisibility}
        addNewSubscriptionService={addNewSubscriptionService}
        resetTemporarySubscriptionServices={resetTemporarySubscriptionServices}
        filteredSubscriptionServices={filteredSubscriptionServices}
        setFilteredSubscriptionServices={setFilteredSubscriptionServices}
        temporarySubscriptionService={temporarySubscriptionService}
        cellPhoneServices={cellPhoneServices}
      />
    </div>
  );
};

SubscriptionServiceSectionContent.defaultProps = {};

SubscriptionServiceSectionContent.propTypes = {
  fields: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.shape({
        cell_phone_service_id: PropTypes.number,
        location: PropTypes.string,
        location_constraint: PropTypes.string,
      })
    ),
    push: PropTypes.func,
    length: PropTypes.func,
  }).isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  filteredSubscriptionServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  setFilteredSubscriptionServices: PropTypes.func.isRequired,
  addNewSubscriptionService: PropTypes.func.isRequired,
  toggleModalVisibility: PropTypes.func.isRequired,
  resetTemporarySubscriptionServices: PropTypes.func.isRequired,
  temporarySubscriptionService: PropTypes.shape({
    cell_phone_service_id: PropTypes.number,
    location: PropTypes.string,
    location_constraint: PropTypes.string,
  }).isRequired,
  cellPhoneServices: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

RenderTitle.propTypes = { hideTitle: PropTypes.bool.isRequired };

ServiceSection.defaultProps = {
  formType: "create",
  hideTitle: false,
};

ServiceSection.propTypes = {
  fields: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.shape({
        cell_phone_service_id: PropTypes.number,
        location: PropTypes.string,
        location_constraint: PropTypes.string,
      })
    ),
    push: PropTypes.func,
    length: PropTypes.func,
  }).isRequired,
  cellPhoneServices: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    is_default: PropTypes.bool,
  }).isRequired,
  formType: PropTypes.string,
  hideTitle: PropTypes.bool,
  filterSelectedServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      is_default: PropTypes.bool,
    })
  ).isRequired,
};

export default ServiceSection;
