import React from "react";
import { Label, Input } from "reactstrap";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

const CheckBoxField = ({
  input: { name, onBlur, onChange, value, ...input },
  label,
  labelClassName,
  labelTranslation,
  disabled,
  hidden,
  meta: { touched, error, warning },
}) => (
  <div className="form-check">
    {(label || labelTranslation) && (
      <>
        <Label for={name} className={`${labelClassName} form-check-label`}>
          {label || <Translate value={labelTranslation} />}
        </Label>
        <Input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...input}
          type="checkbox"
          id={name}
          disabled={disabled}
          hidden={hidden}
          onBlur={onBlur}
          onChange={onChange}
          className="form-check-input"
          checked={value}
          data-testid={name}
        />
      </>
    )}
    {touched &&
      ((error && (
        <span className="invalid-text text-danger" data-testid="error-text">
          {error}
        </span>
      )) ||
        (warning && (
          <span
            className="invalid-text text-warning"
            data-testid="warning-text"
          >
            {warning}
          </span>
        )))}
  </div>
);

CheckBoxField.defaultProps = {
  label: undefined,
  disabled: false,
  hidden: false,
  min: undefined,
  max: undefined,
  labelTranslation: undefined,
  labelClassName: "",
};

CheckBoxField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.bool,
  }).isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  labelTranslation: PropTypes.string,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default CheckBoxField;
