import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { Translate, t } from "react-i18nify";
import { FieldArray } from "react-final-form-arrays";

import SubscriptionLinesModal from "components/Quotation/Form/Modal/SubscriptionLinesModal";

const SubscriptionListEmptyUI = ({
  resourceName,
  toggleModalVisibility,
  isModalVisible,
  addNewSubscription,
  resetTemporarySubscription,
  temporarySubscription,
}) => {
  return (
    <div className="p-4 h-100 shadow-sm bg-white d-flex flex-column align-items-center justify-content-center empty-table">
      <div className="info-icon-wrapper border-danger mb-2">
        <FontAwesomeIcon className="info-icon text-danger" icon={faInfo} />
      </div>
      <div className="text-center fw-bold">
        <p className="d-inline-block mb-2">
          <Translate
            value="title.resource.addResource"
            resource={t(`common.${resourceName}`)}
          />
        </p>
      </div>

      <FieldArray
        name="agreement.temporary_subscription.subscription_lines"
        component={SubscriptionLinesModal}
        title={<Translate value="common.addSubscription" />}
        subTitle={<Translate value="common.mainSubscription" />}
        buttonTitle={<Translate value="common.addSubscription" />}
        modalVisible={isModalVisible}
        onSubmit={addNewSubscription}
        onCancel={resetTemporarySubscription}
        subscriptionField={temporarySubscription}
        dataModalButton="add-subscription-btn"
      />

      <button
        className="btn btn-primary mt-3"
        type="button"
        onClick={toggleModalVisibility}
        data-cy="add-subscription"
      >
        <Translate
          value="title.resource.button.addResource"
          resource={t(`common.subscription`)}
        />
      </button>
    </div>
  );
};

SubscriptionListEmptyUI.defaultProps = {};

SubscriptionListEmptyUI.propTypes = {
  resourceName: PropTypes.string.isRequired,
  toggleModalVisibility: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  addNewSubscription: PropTypes.func.isRequired,
  resetTemporarySubscription: PropTypes.func.isRequired,
  temporarySubscription: PropTypes.shape({}).isRequired,
};

export default SubscriptionListEmptyUI;
