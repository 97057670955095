import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faCalendarTimes } from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-i18nify";

import DidNumberDetailSkeleton from "components/Skeleton/ResourceDetail/DidNumberDetail/DidNumberDetailSkeleton";
import DetailUIHeader from "components/Header/DetailUI/DetailUIHeader";
import CellPhoneBasicDetail from "components/CellPhone/Detail/CellPhoneBasicDetail";
import CellPhoneSimCardDetail from "components/CellPhone/Detail/CellPhoneSimCardDetail";
import FetchError from "components/Error/FetchError";
import DeleteModal from "components/Modal/DeleteModal";
import CellPhoneActions from "components/CellPhone/Detail/CellPhoneActions";
import Pill from "components/Pill/Pill";

import {
  singleCellPhoneFetchRequest,
  singleCellPhoneDeleteRequest,
} from "redux/actions/cellPhone";
import { providerFetchRequest } from "redux/actions/pbxEngine/provider";

import { PBX, USER_LICENSE } from "constants/routes";

const AdditionalHeaderContent = ({
  isActive,
  isSuspended,
  newSimCardRequestedDate,
}) => {
  if (!isActive) {
    return (
      <span className="is-deactivated ms-3 px-2 py-2">
        <FontAwesomeIcon icon={faCalendarTimes} className="me-1" />
        <Translate value="common.deactivated" />
      </span>
    );
  }

  if (isSuspended && newSimCardRequestedDate) {
    return (
      <>
        <span className="suspended ms-3 px-3 py-2">
          <FontAwesomeIcon icon={faBan} className="me-1 text-danger" />
          <Translate value="attributes.suspended" />
        </span>
        <span className="ms-3">
          <Pill
            content={<Translate value="pill.status.newSimCardRequested" />}
            show
            className="bg-green py-1 px-3"
          />
        </span>
      </>
    );
  }

  if (isSuspended) {
    return (
      <span className="suspended ms-3 px-3 py-2">
        <FontAwesomeIcon icon={faBan} className="me-1 text-danger" />
        <Translate value="attributes.suspended" />
      </span>
    );
  }

  if (newSimCardRequestedDate) {
    return (
      <span className="ms-3">
        <Pill
          content={<Translate value="pill.status.newSimCardRequested" />}
          show
          className="bg-green py-1 px-3"
        />
      </span>
    );
  }

  return null;
};

const CellPhoneDetail = ({
  cellPhone,
  fetchSingleCellPhone,
  fetchError,
  fetchProviders,
  providers,
  isProviderLoading,
  deleteCellphone,
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const { cellPhoneID } = params;

  const [modalData, setModalData] = useState();
  const [modalVisible, setModalVisibility] = useState(false);

  const toggle = () => {
    setModalData(cellPhoneID);
    setModalVisibility(!modalVisible);
  };

  const resetModalState = () => {
    setModalVisibility(false);
    setModalData();
  };

  const onSuccess = () => {
    const { user_license_id: userLicenseID, pbx_id: pbxID } = cellPhone;

    const goBackUrl = userLicenseID
      ? USER_LICENSE.addId(USER_LICENSE.CELL_PHONES, userLicenseID)
      : PBX.addId(PBX.CELL_PHONES, pbxID);

    navigate(goBackUrl, { replace: true });
  };

  const onConfirmDelete = (id) => {
    if (id) deleteCellphone(id, onSuccess);
    resetModalState();
  };

  const fetchCellPhoneData = () => {
    fetchSingleCellPhone(cellPhoneID);
  };

  useEffect(() => {
    fetchCellPhoneData();
    fetchProviders();
  }, [cellPhoneID]);

  if (fetchError) {
    return <FetchError onRetry={fetchCellPhoneData} />;
  }

  if (
    !cellPhone ||
    (cellPhone && cellPhone.id !== parseInt(cellPhoneID, 10)) ||
    isProviderLoading
  ) {
    return <DidNumberDetailSkeleton />;
  }

  const {
    cell_number: cellNumber,
    is_active: isActive,
    active_sim_card: activeSimCard,
    id,
    is_suspended: isSuspended,
    new_sim_card_requested_date: newSimCardRequestedDate,
  } = cellPhone;

  return (
    <div className="cellPhone-detail w-100">
      <DeleteModal
        modalVisible={modalVisible}
        modalData={modalData}
        resourceName="cell phone"
        toggleModal={toggle}
        onConfirm={onConfirmDelete}
        onCancel={resetModalState}
      />
      <DetailUIHeader
        headerTitle={cellNumber}
        readOnly={!isActive}
        hasDeleteTooltip={isSuspended}
        deleteTooltipText="cellPhone.youCannotDeleteTheSuspendedCellPhone"
        editTooltipText="cellPhone.youCannotEditTheSuspendedCellPhone"
        hasEditTooltip={isSuspended}
        perform="cellPhone:edit"
        additionalHeaderContent={
          <AdditionalHeaderContent
            isActive={isActive}
            isSuspended={isSuspended}
            newSimCardRequestedDate={newSimCardRequestedDate}
          />
        }
        deletePerform="cellPhone:delete"
        handleDelete={toggle}
        additionalComponent={
          <CellPhoneActions
            cellNumber={cellNumber}
            cellNumberID={id}
            isSuspended={isSuspended}
            newSimCardRequestedDate={newSimCardRequestedDate}
            isActive={isActive}
          />
        }
        hasDelete
      />
      <CellPhoneBasicDetail cellPhone={cellPhone} providers={providers} />
      <CellPhoneSimCardDetail
        simCard={activeSimCard}
        newSimCardRequestedDate={newSimCardRequestedDate}
        cellPhoneID={cellPhoneID}
      />
    </div>
  );
};

AdditionalHeaderContent.defaultProps = {
  isActive: true,
  isSuspended: false,
  newSimCardRequestedDate: "",
};

AdditionalHeaderContent.propTypes = {
  isActive: PropTypes.bool,
  isSuspended: PropTypes.bool,
  newSimCardRequestedDate: PropTypes.string,
};

CellPhoneDetail.defaultProps = {
  fetchError: null,
  isProviderLoading: undefined,
};

CellPhoneDetail.propTypes = {
  cellPhone: PropTypes.shape({
    id: PropTypes.number,
    cell_number: PropTypes.string,
    subscription_line: PropTypes.shape({}),
    is_active: PropTypes.bool,
    user_license_id: PropTypes.number,
    pbx_id: PropTypes.number,
    active_sim_card: PropTypes.shape({}),
    is_suspended: PropTypes.bool,
    new_sim_card_requested_date: PropTypes.string,
  }).isRequired,
  fetchSingleCellPhone: PropTypes.func.isRequired,
  deleteCellphone: PropTypes.func.isRequired,
  fetchError: PropTypes.string,
  fetchProviders: PropTypes.func.isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isProviderLoading: PropTypes.bool,
};

const mapStateToProps = ({ cellPhone: cellPhoneResource, provider }) => {
  const { cellPhone, fetchError } = cellPhoneResource;
  const { providers, isLoading: isProviderLoading } = provider;

  return {
    cellPhone,
    fetchError,
    providers,
    isProviderLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleCellPhone: (id) => {
    dispatch(singleCellPhoneFetchRequest(id));
  },
  fetchProviders: () => {
    dispatch(providerFetchRequest());
  },
  deleteCellphone: (id, cb) => {
    dispatch(singleCellPhoneDeleteRequest(id, cb));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CellPhoneDetail);
