import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  sipDevicesFetchRequest,
  searchSipDevices,
  sipDevicesSearchRequest,
} from "redux/actions/sipDevices";

import filterSearchResults from "utils/filterSearchResults";

import SipDeviceIndexContent from "components/SipDevices/Index/SipDeviceIndexContent";

const SipDeviceIndex = ({
  sipDevices,
  isLoading,
  isSearching,
  parentActiveStatus,
  sipDevicesFetchRequest: fetchAllSipDevices,
  parentID,
  searchRecords,
  parentResource,
}) => {
  useEffect(() => {
    fetchAllSipDevices(parentID);
  }, [parentID]);

  return (
    <SipDeviceIndexContent
      parentActiveStatus={parentActiveStatus}
      parentID={parentID}
      sipDevices={sipDevices}
      isLoading={isLoading}
      isSearching={isSearching}
      searchRecords={searchRecords}
      parentResource={parentResource}
    />
  );
};

SipDeviceIndex.defaultProps = {
  parentID: undefined,
  isLoading: true,
  sipDevices: undefined,
  isSearching: false,
};

SipDeviceIndex.propTypes = {
  sipDevices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      user_license_id: PropTypes.number,
      extension_number: PropTypes.number,
      user_license_extension_number: PropTypes.number,
      ex_description: PropTypes.string,
      ex_name: PropTypes.string,
    })
  ),
  sipDevicesFetchRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  parentID: PropTypes.string,
  parentActiveStatus: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool,
  searchRecords: PropTypes.func.isRequired,
  parentResource: PropTypes.string.isRequired,
};

const mapStateToProps = ({ sipDevice: sipDeviceReducer, search }) => {
  const { sipDevices, isLoading, isSearching } = sipDeviceReducer;

  const results = filterSearchResults({
    resource: sipDevices,
    searchResult: search.sipDevices,
  });

  return {
    sipDevices: results,
    isLoading,
    isSearching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sipDevicesFetchRequest: (userLicenseID, filter) => {
      dispatch(sipDevicesFetchRequest(userLicenseID, filter));
    },
    searchRecords: (e) => {
      dispatch(sipDevicesSearchRequest(e));
      dispatch(searchSipDevices(e));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SipDeviceIndex);
