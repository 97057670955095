import apiUrlConfig from "configs/apiUrlConfig";
import { AuthenticatedRequest } from "internal";

const URLS = {
  LIST: `${apiUrlConfig.apiEndPoint()}/user_licenses/:user_license_id/sip_devices`,
  CREATE: `${apiUrlConfig.apiEndPoint()}/user_licenses/:user_license_id/sip_devices`,
  FETCH_DEFAULT_FORM_VALUES: `${apiUrlConfig.apiEndPoint()}/sip_devices/default_form_values?user_license_id=:user_license_id`,
  SHOW: `${apiUrlConfig.apiEndPoint()}/sip_devices/:id`,
  UPDATE: `${apiUrlConfig.apiEndPoint()}/sip_devices/:id`,
  DELETE: `${apiUrlConfig.apiEndPoint()}/sip_devices/:id`,
};

class SipDevicesApi extends AuthenticatedRequest {
  static fetchAllSipDevices(userLicenseId) {
    return this.get(URLS.LIST.replace(":user_license_id", userLicenseId));
  }

  static create(userLicenseId, attributes) {
    const requestBody = {
      sip_device: {
        ...attributes,
      },
    };

    return this.post(
      URLS.CREATE.replace(":user_license_id", userLicenseId),
      requestBody
    );
  }

  static fetchDefaultFormValue(userLicenseId) {
    return this.get(
      URLS.FETCH_DEFAULT_FORM_VALUES.replace(":user_license_id", userLicenseId)
    );
  }

  static fetchOne(id) {
    return this.get(URLS.SHOW.replace(":id", id));
  }

  static update(id, attributes) {
    const updateUrl = URLS.UPDATE.replace(":id", id);

    const requestBody = {
      sip_device: {
        ...attributes,
      },
    };

    return this.patch(updateUrl, requestBody);
  }

  static deleteOne(id) {
    const updateUrl = URLS.DELETE.replace(":id", id);

    return this.delete(updateUrl);
  }
}

export default SipDevicesApi;
