import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";
import { connect } from "react-redux";

import { productFetchRequest } from "redux/actions/product";

import SubscriptionListingTable from "components/Quotation/Form/ConvertToAgreement/Subscription/SubscriptionListingTable";

const SubscriptionSection = ({ fields, fetchProducts }) => {
  const [isModalVisible, setModalVisibility] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, []);

  const toggleModalVisibility = () => {
    setModalVisibility((prevState) => !prevState);
  };

  return (
    <div>
      <h4 className="my-4">
        <Translate value="attributes.subscriptions" />
      </h4>

      <SubscriptionListingTable
        fields={fields}
        modalVisible={isModalVisible}
        toggleModalVisibility={toggleModalVisibility}
      />
    </div>
  );
};

SubscriptionSection.defaultProps = {};

SubscriptionSection.propTypes = {
  fetchProducts: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.shape({})),
    length: PropTypes.number,
    push: PropTypes.func,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  fetchProducts: () => dispatch(productFetchRequest()),
});

export default connect(null, mapDispatchToProps)(SubscriptionSection);
