import {
  ADMIN_ROLES,
  BUSINESS_ADMIN_ROLES,
  BUSINESS_USER_ROLES,
} from "constants/userRoles";

const isAnAdminUser = (currentUserRole) =>
  ADMIN_ROLES.includes(currentUserRole);

const isABusinessAdminUser = (currentUserRole) =>
  BUSINESS_ADMIN_ROLES.includes(currentUserRole);

const isABusinessUser = (currentUserRole) =>
  BUSINESS_USER_ROLES.includes(currentUserRole);

export { isAnAdminUser, isABusinessAdminUser, isABusinessUser };
