import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { FormGroup, Row, Col } from "reactstrap";
import { Form, Field } from "react-final-form";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import { required } from "utils/validation";

import { renderInputField } from "components/ReduxForm/RenderField";
import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";

const SipDeviceForm = ({ isLoading, onSubmit, initialValues }) => (
  <Form
    onSubmit={onSubmit}
    validate={required}
    initialValues={initialValues}
    render={({ handleSubmit, pristine, submitting }) => (
      <form className="flexo-form px-4 py-4 pt-5 w-100" onSubmit={handleSubmit}>
        <div className="pbx-content">
          <Row>
            <Col md="6">
              <FormGroup>
                <Field
                  name="extension_number"
                  component={renderInputField}
                  validate={required}
                  labelTranslation="attributes.extensionNumber"
                  placeholderTranslation="attributes.extensionNumber"
                  type="number"
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Field
                  name="ex_name"
                  component={renderInputField}
                  validate={required}
                  labelTranslation="attributes.name"
                  placeholderTranslation="attributes.name"
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Field
                  name="ex_description"
                  component={renderInputField}
                  validate={required}
                  labelTranslation="attributes.description"
                  placeholderTranslation="attributes.description"
                  type="textarea"
                />
              </FormGroup>
            </Col>
          </Row>
        </div>

        <div className="flexo-form-footer">
          <ReduxFormCommitButton
            title={<Translate value="common.save" />}
            submitting={submitting}
            pristine={pristine}
            isLoading={isLoading}
          />
        </div>
      </form>
    )}
  />
);

SipDeviceForm.defaultProps = {
  initialValues: {},
};

SipDeviceForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}),
};

const mapStateToProps = (state) => {
  const {
    currentUser: { role: currentUserRole },
  } = state.auth;

  return {
    currentUserRole,
  };
};

export default compose(connect(mapStateToProps, null))(SipDeviceForm);
