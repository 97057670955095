import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { Translate } from "react-i18nify";

import ArchiveButton from "components/Button/ArchiveButton";

const ArchiveModal = ({ resourceIdentifier, archiveRecord }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <ArchiveButton handleButtonClick={() => setModalOpen(true)} />

      <Modal isOpen={modalOpen} toggle={closeModal} data-testid="archive-modal">
        <ModalHeader>{resourceIdentifier}</ModalHeader>

        <ModalBody>
          <Translate
            value="modal.archive.resource"
            resource={resourceIdentifier}
          />

          <p className="text-danger pt-3">
            <Translate value="modal.delete.irreversible" />
          </p>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={() => archiveRecord(closeModal)}>
            <Translate value="common.confirm" />
          </Button>

          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={closeModal}
            data-testid="cancel-button"
          >
            <Translate value="common.cancel" />
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

ArchiveModal.propTypes = {
  resourceIdentifier: PropTypes.string.isRequired,
  archiveRecord: PropTypes.func.isRequired,
};

export default ArchiveModal;
