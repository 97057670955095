import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Translate, t } from "react-i18nify";
import PropTypes from "prop-types";

import { pbxCreateRequest } from "redux/actions/pbx";
import { debtorFetchRequest } from "redux/actions/debtor";
import { routingProfileFetchRequest } from "redux/actions/pbxEngine/routingProfile";
import { callRateFetchRequest } from "redux/actions/pbxEngine/callRate";

import { PBX } from "constants/routes";

import PbxForm from "components/Pbx/Form/PbxForm";

import { processServerResponseForAsyncValidationErrors } from "utils/forms";

import { SUBSCRIPTION_TYPES } from "constants/constant";

const PbxCreate = ({
  createPbx,
  commitSuccess,
  isLoading,
  fetchDebtors,
  debtors,
  isDebtorFetching,
  routingProfiles,
  isRoutingProfileFetching,
  fetchRoutingProfiles,
  fetchCallRates,
  callRates,
  isCallRateFetching,
  pbx,
}) => {
  const { debtorID } = useParams();

  useEffect(() => {
    fetchDebtors();
    fetchRoutingProfiles();
    fetchCallRates();
  }, []);

  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    await createPbx(values, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess) {
    const { id } = pbx;
    const pbxTranslation = t("enum.setting.categoryOptions.pbx");

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.create", { resource: pbxTranslation })
    );

    return <Navigate to={PBX.addId(PBX.DETAIL, id)} />;
  }

  const initialDebtorIDValue =
    typeof debtorID === "number"
      ? { odoo_debtor_id: parseInt(debtorID, 2) }
      : {};

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate
            value="title.page.resource.create"
            resource={t("enum.setting.categoryOptions.pbx")}
          />
        </h3>
      </div>
      <PbxForm
        onSubmit={handleSubmit}
        formName="pbxForm"
        formType="create"
        debtors={debtors}
        routingProfiles={routingProfiles}
        callRates={callRates}
        isLoading={isLoading}
        initialValues={{
          ...initialDebtorIDValue,
          agreement_option: "existing",
          subscription_attributes: {
            subscription_type: SUBSCRIPTION_TYPES[1],
          },
          pbx_billing_setting_attributes: {
            is_voip_billing_enabled: true,
            is_cell_phone_billing_enabled: true,
          },
        }}
        isDebtorFetching={isDebtorFetching}
        isRoutingProfileFetching={isRoutingProfileFetching}
        isCallRateFetching={isCallRateFetching}
      />
    </div>
  );
};

const mapStateToProps = ({
  pbx: pbxReducer,
  debtor,
  routingProfile,
  callRate,
  agreement,
}) => {
  const { pbx, commitSuccess, isLoading, commitError } = pbxReducer;
  const { debtors, isLoading: isDebtorFetching } = debtor;
  const { routingProfiles, isLoading: isRoutingProfileFetching } =
    routingProfile;
  const { callRates, isLoading: isCallRateFetching } = callRate;
  const { agreements, isLoading: isAgreementFetching } = agreement;

  return {
    isLoading,
    pbx,
    debtors,
    isDebtorFetching,
    routingProfiles,
    isRoutingProfileFetching,
    callRates,
    isCallRateFetching,
    commitSuccess,
    commitError,
    agreements,
    isAgreementFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createPbx: async (attributes, callback) => {
    await dispatch(pbxCreateRequest(attributes, callback));
  },
  fetchDebtors: () => {
    dispatch(debtorFetchRequest());
  },
  fetchRoutingProfiles: () => {
    dispatch(routingProfileFetchRequest());
  },
  fetchCallRates: () => {
    dispatch(callRateFetchRequest());
  },
  fetchAgreements: () => {},
});

PbxCreate.defaultProps = {
  isDebtorFetching: true,
  isRoutingProfileFetching: true,
  isCallRateFetching: true,
  pbx: {},
  commitError: {},
};

PbxCreate.propTypes = {
  createPbx: PropTypes.func.isRequired,
  fetchDebtors: PropTypes.func.isRequired,
  debtors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  isDebtorFetching: PropTypes.bool,
  routingProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  isRoutingProfileFetching: PropTypes.bool,
  fetchRoutingProfiles: PropTypes.func.isRequired,
  fetchCallRates: PropTypes.func.isRequired,
  isCallRateFetching: PropTypes.bool,
  callRates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  commitSuccess: PropTypes.bool.isRequired,
  commitError: PropTypes.shape({}),
  isLoading: PropTypes.bool.isRequired,
  pbx: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(PbxCreate);
