import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  faDollarSign,
  faCalendarDay,
  faBoxOpen,
  faHandsHelping,
  faFlag,
  faFlagCheckered,
  faRoute,
  faExchangeAlt,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-i18nify";
import { useSelector } from "react-redux";

import { AGREEMENT } from "constants/routes";

import { camelize } from "utils/string";
import { isABusinessAdminUser } from "utils/userRole";

import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import TranslateNumber from "components/i18n/TranslateNumber";
import TranslateDate from "components/i18n/TranslateDate";

import HasPermission from "hoc/RbacCTA";

const PbxAdditionalDetail = ({
  pbx,
  callRates,
  routingProfiles,
  resourceName,
}) => {
  const {
    te_maxchannels: maxChannels,
    te_rp_id: routingProfileID,
    te_cl_id: callRateID,
    pk_start: parkingLotStart,
    pk_end: parkingLotEnd,
    subscription_line: subscriptionLine,
    agreement_number: agreementNumber,
    agreement_id: agreementID,
    pbx_billing_setting: { billing_group: billingGroup },
  } = pbx;

  const {
    start_date: startDate,
    product_name: productName,
    price,
    last_invoice_date: lastInvoiceDate,
  } = subscriptionLine || {};

  const {
    currentUser: { role },
  } = useSelector(({ auth }) => auth);

  const isBusinessAdmin = isABusinessAdminUser(role);

  const routingProfile = routingProfiles.find(
    (record) => record.id === routingProfileID
  );
  const callRate = callRates.find((record) => record.id === callRateID);

  const agreementContent = (
    <>
      {agreementNumber}
      <Link to={AGREEMENT.addId(AGREEMENT.SHOW, agreementID)} className="ml-1">
        {"( "}
        <Translate value="common.viewDetails" />
        {" )"}
      </Link>
    </>
  );

  const additionalDetailContent = [
    isBusinessAdmin && {
      icon: faDollarSign,
      title: <Translate value="attributes.callRate" />,
      content: callRate && callRate.name,
      dataCyFieldName: "callRateName",
    },
    {
      icon: faExchangeAlt,
      title: <Translate value="attributes.maxChannels" />,
      content: maxChannels,
      dataCyFieldName: "maxChannels",
    },
    {
      icon: faRoute,
      title: <Translate value="attributes.routingProfile" />,
      content: routingProfile && routingProfile.name,
      dataCyFieldName: "routingProfileName",
    },
    {
      icon: faRoute,
      title: <Translate value="attributes.campaignRoutingProfile" />,
      content: routingProfile && routingProfile.name,
      dataCyFieldName: "campaignRoutingProfileName",
    },
    {
      icon: faFlag,
      title: <Translate value="attributes.parkingLotStart" />,
      content: parkingLotStart,
      dataCyFieldName: "parkingLotStart",
    },
    {
      icon: faFlagCheckered,
      title: <Translate value="attributes.parkingLotEnd" />,
      content: parkingLotEnd,
      dataCyFieldName: "parkingLotEnd",
    },
  ];
  const subscriptionDetailContent = [
    {
      icon: faHandsHelping,
      title: <Translate value="agreement.number" />,
      content: agreementContent,
      dataCyFieldName: "agreementContent",
    },
    {
      icon: faBoxOpen,
      title: <Translate value="attributes.product" />,
      content: productName,
      dataCyFieldName: "productName",
    },
    {
      icon: faDollarSign,
      title: <Translate value="attributes.price" />,
      content: <TranslateNumber value={price} minimumFractionDigits={0} />,
      dataCyFieldName: "price",
    },
    {
      icon: faCalendarDay,
      title: <Translate value="attributes.startDate" />,
      content: <TranslateDate date={startDate} />,
      dataCyFieldName: "startDate",
    },
    {
      icon: faCalendarDay,
      title: <Translate value="attributes.lastInvoiceDate" />,
      content: <TranslateDate date={lastInvoiceDate} />,
      dataCyFieldName: "lastInvoiceDate",
    },
    billingGroup && {
      icon: faLayerGroup,
      title: <Translate value="attributes.billingGroup" />,
      content: (
        <Translate value={`enum.pbx.billingGroup.${camelize(billingGroup)}`} />
      ),
      dataCyFieldName: "billingGroup",
    },
  ];

  return (
    <div>
      <CardWithHeader
        className="shadow-sm"
        cardContent={additionalDetailContent}
        displayHeader={false}
        dataCy={resourceName}
      />

      <HasPermission perform="pbx:subscriptionDetails:view">
        <CardWithHeader
          title={<Translate value="common.subscriptionDetails" />}
          className="shadow-sm"
          cardContent={subscriptionDetailContent}
          dataCy={resourceName}
        />
      </HasPermission>
    </div>
  );
};

PbxAdditionalDetail.defaultProps = {};

PbxAdditionalDetail.propTypes = {
  pbx: PropTypes.shape({
    te_maxchannels: PropTypes.number,
    te_rp_id: PropTypes.number,
    te_ca_rp_id: PropTypes.number,
    te_cl_id: PropTypes.number,
    pk_start: PropTypes.number,
    pk_end: PropTypes.number,
    agreement_number: PropTypes.string,
    agreement_id: PropTypes.number,
    subscription_line: PropTypes.shape({
      start_date: PropTypes.instanceOf(Date),
      product_name: PropTypes.string,
      price: PropTypes.number,
      last_invoice_date: PropTypes.instanceOf(Date),
    }),
    pbx_billing_setting: PropTypes.shape({
      billing_group: PropTypes.string,
    }),
  }).isRequired,
  callRates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  routingProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  resourceName: PropTypes.string.isRequired,
};

export default PbxAdditionalDetail;
