import React from "react";

import HeaderSkeleton from "components/Skeleton/HeaderSkeleton";
import TabTableSkeleton from "components/Skeleton/TabTableSkeleton";

const DidNumberDetailSkeleton = () => (
  <div className="container-fluid">
    <HeaderSkeleton />
    <TabTableSkeleton />
    <TabTableSkeleton />
  </div>
);

export default DidNumberDetailSkeleton;
