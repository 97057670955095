import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getCurrentUser } from "utils/selectors";

import UnAuthorizedView from "views/Auth/UnauthorizedView";

import USER_ROLES from "constants/userRoles";
import userPermissionRules from "constants/userPermissions";

const check = (rules, currentUser, action, data) => {
  const permissions = rules[currentUser?.role];
  const isSuperAdmin = currentUser?.role === USER_ROLES.SUPER_ADMIN;
  const isAdmin = currentUser?.role === USER_ROLES.ADMIN;

  if (isSuperAdmin && (action === "users:edit" || action === "users:delete")) {
    const { role, userId } = data;

    if (role !== USER_ROLES.SUPER_ADMIN && userId !== currentUser.id) {
      return true;
    }

    if (role === USER_ROLES.SUPER_ADMIN && userId === currentUser.id) {
      return true;
    }

    return false;
  }

  if (isSuperAdmin && action !== "users:delete") return true;
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];

    if (!permissionCondition) {
      if (isAdmin) return true;

      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition({ ...data, currentUser });
  }

  return false;
};

const Can = ({ reduxState, perform, data, yes, no, children, redirect }) => {
  const currentUser = getCurrentUser(reduxState);
  const hasPermission = check(userPermissionRules, currentUser, perform, data);

  if (hasPermission) {
    if (yes && typeof yes === "function") return yes();

    return children;
  }
  if (redirect) return <UnAuthorizedView />;

  return no();
};

Can.defaultProps = {
  redirect: false,
  no: () => null,
};

Can.propTypes = {
  reduxState: PropTypes.shape({}).isRequired,
  perform: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
  yes: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  no: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  children: PropTypes.shape({}).isRequired,
  redirect: PropTypes.bool,
};

export default connect((reduxState) => ({ reduxState }))(Can);
