import { createSearchAction } from "redux-search";

import { SimCardApi } from "internal";

import { SIM_CARD_ASSIGN_TYPE } from "constants/constant";

import { displayFetchErrorToaster, getServerError } from "utils/utils";

export const FETCH_SIM_CARDS_REQUEST = "FETCH_SIM_CARDS_REQUEST";
export const FETCH_SIM_CARDS_RECEIVED = "FETCH_SIM_CARDS_RECEIVED";
export const FETCH_SIM_CARDS_ERROR = "FETCH_SIM_CARDS_ERROR";

export const FETCH_SIM_CARD_DETAIL_REQUEST = "FETCH_SIM_CARD_DETAIL_REQUEST";
export const FETCH_SIM_CARD_DETAIL_RECEIVED = "FETCH_SIM_CARD_DETAIL_RECEIVED";
export const FETCH_SIM_CARD_DETAIL_ERROR = "FETCH_SIM_CARD_DETAIL_ERROR";

export const FETCH_EMPLOYEE_LIST_REQUEST = "FETCH_EMPLOYEE_LIST_REQUEST";
export const FETCH_EMPLOYEE_LIST_RECEIVED = "FETCH_EMPLOYEE_LIST_RECEIVED";
export const FETCH_EMPLOYEE_LIST_ERROR = "FETCH_EMPLOYEE_LIST_ERROR";

export const FETCH_CUSTOMER_LIST_REQUEST = "FETCH_CUSTOMER_LIST_REQUEST";
export const FETCH_CUSTOMER_LIST_RECEIVED = "FETCH_CUSTOMER_LIST_RECEIVED";
export const FETCH_CUSTOMER_LIST_ERROR = "FETCH_CUSTOMER_LIST_ERROR";

export const ASSIGN_SIM_CARD_REQUEST = "ASSIGN_SIM_CARD_REQUEST";
export const ASSIGN_SIM_CARD_RECEIVED = "ASSIGN_SIM_CARD_RECEIVED";
export const ASSIGN_SIM_CARD_ERROR = "ASSIGN_SIM_CARD_ERROR";

export const SEARCH_IMSI_RANGES_REQUEST = "SEARCH_IMSI_RANGES_REQUEST";
export const SEARCH_IMSI_RANGES_RECEIVED = "SEARCH_IMSI_RANGES_RECEIVED";
export const SEARCH_IMSI_RANGES_ERROR = "SEARCH_IMSI_RANGES_ERROR";

export const SEARCH_ICCID_RANGES_REQUEST = "SEARCH_ICCID_RANGES_REQUEST";
export const SEARCH_ICCID_RANGES_RECEIVED = "SEARCH_ICCID_RANGES_RECEIVED";
export const SEARCH_ICCID_RANGES_ERROR = "SEARCH_ICCID_RANGES_ERROR";

export const UPDATE_SIM_CARD_REQUEST = "UPDATE_SIM_CARD_REQUEST";
export const UPDATE_SIM_CARD_RECEIVED = "UPDATE_SIM_CARD_RECEIVED";
export const UPDATE_SIM_CARD_ERROR = "UPDATE_SIM_CARD_ERROR";

export const FETCH_RESELLER_LIST_REQUEST = "FETCH_RESELLER_LIST_REQUEST";
export const FETCH_RESELLER_LIST_RECEIVED = "FETCH_RESELLER_LIST_RECEIVED";
export const FETCH_RESELLER_LIST_ERROR = "FETCH_RESELLER_LIST_ERROR";

export const FETCH_SIM_CARD_BORROWER_LIST_REQUEST =
  "FETCH_SIM_CARD_BORROWER_LIST_REQUEST";
export const FETCH_SIM_CARD_BORROWER_LIST_RECEIVED =
  "FETCH_SIM_CARD_BORROWER_LIST_RECEIVED";
export const FETCH_SIM_CARD_BORROWER_LIST_ERROR =
  "FETCH_SIM_CARD_BORROWER_LIST_ERROR";

export const SIM_CARDS_SEARCH_REQUEST = "SIM_CARDS_SEARCH_REQUEST";

export const CLEAR_SIM_CARDS_ERRORS = "CLEAR_SIM_CARDS_ERRORS";

export const searchSimCard = createSearchAction("simCards");

export function simCardSearchRequest(value) {
  const isSearching = Boolean(value);

  return {
    type: SIM_CARDS_SEARCH_REQUEST,
    isSearching,
  };
}

export function fetchSimCardsReceived(data) {
  return {
    type: FETCH_SIM_CARDS_RECEIVED,
    phoneNumbers: data,
  };
}

export function fetchSimCardsFailed(errorData) {
  const { response } = errorData;
  const error = getServerError(response);

  displayFetchErrorToaster(error);

  return {
    type: FETCH_SIM_CARDS_ERROR,
    message: error,
  };
}

export const simCardsRequest =
  (
    status = "available",
    serviceProviderID,
    simCardBorrowerType,
    simCardBorrowerID = null
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_SIM_CARDS_REQUEST });

      const response = await SimCardApi.fetchAll(
        status,
        serviceProviderID,
        simCardBorrowerType,
        simCardBorrowerID
      );

      dispatch(fetchSimCardsReceived(response.data));
    } catch (error) {
      dispatch(fetchSimCardsFailed(error));
    }
  };

export function FetchSimCardDetailReceived(data) {
  return {
    type: FETCH_SIM_CARD_DETAIL_RECEIVED,
    simCard: data,
  };
}

export function FetchSimCardDetailError(errorData) {
  const { response } = errorData;
  const error = getServerError(response);

  displayFetchErrorToaster(error);

  return {
    type: FETCH_SIM_CARD_DETAIL_ERROR,
    message: error,
  };
}

export const simCardDetailRequest = (simCardID) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SIM_CARD_DETAIL_REQUEST });

    const response = await SimCardApi.fetchSimCardDetail(simCardID);

    dispatch(FetchSimCardDetailReceived(response.data));
  } catch (error) {
    dispatch(FetchSimCardDetailError(error));
  }
};

export function fetchEmployeeListReceived(data) {
  return {
    type: FETCH_EMPLOYEE_LIST_RECEIVED,
    employees: data,
  };
}

export function fetchEmployeeListError(errorData) {
  const { response } = errorData;
  const error = getServerError(response);

  displayFetchErrorToaster(error);

  return {
    type: FETCH_EMPLOYEE_LIST_ERROR,
    message: error,
  };
}

export const employeeListRequest = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_EMPLOYEE_LIST_REQUEST });

    const response = await SimCardApi.fetchEmployee();

    dispatch(fetchEmployeeListReceived(response.data));
  } catch (error) {
    dispatch(fetchEmployeeListError(error));
  }
};

export function fetchCustomerListReceived(data) {
  return {
    type: FETCH_CUSTOMER_LIST_RECEIVED,
    customers: data,
  };
}

export function fetchCustomerListError(errorData) {
  const { response } = errorData;
  const error = getServerError(response);

  displayFetchErrorToaster(error);

  return {
    type: FETCH_CUSTOMER_LIST_ERROR,
    message: error,
  };
}

export const customerListRequest = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CUSTOMER_LIST_REQUEST });

    const response = await SimCardApi.fetchCustomer();

    dispatch(fetchCustomerListReceived(response.data));
  } catch (error) {
    dispatch(fetchCustomerListError(error));
  }
};

export function assignSIMCardReceived(data) {
  return {
    type: ASSIGN_SIM_CARD_RECEIVED,
    simCard: data,
  };
}

export function updateSIMCardReceived(data) {
  return {
    type: UPDATE_SIM_CARD_RECEIVED,
    simCard: data,
  };
}

export function assignSIMCardError(errorData, callback = () => {}) {
  const { response } = errorData;
  const error = getServerError(response);

  if (response && response.data) {
    callback(response);
  }

  return {
    type: ASSIGN_SIM_CARD_ERROR,
    message: error,
  };
}

export function updateSIMCardError(errorData, callback = () => {}) {
  const { response } = errorData;
  const error = getServerError(response);

  if (response && response.data) {
    callback(response);
  }

  return {
    type: UPDATE_SIM_CARD_ERROR,
    message: error,
  };
}

export const assignSIMCardRequest =
  (attributes, simCardType, callback) => async (dispatch) => {
    try {
      dispatch({ type: ASSIGN_SIM_CARD_REQUEST });

      let response;

      if (simCardType === SIM_CARD_ASSIGN_TYPE.RESELLER) {
        response = await SimCardApi.assignSIMCardToServiceProviders(attributes);
      } else {
        response = await SimCardApi.assignSIMCard(attributes);
      }

      dispatch(assignSIMCardReceived(response.data));

      callback();
    } catch (errors) {
      dispatch(assignSIMCardError(errors, callback));
    }
  };

export const searchIMSIRangesReceived = (data) => ({
  type: SEARCH_IMSI_RANGES_RECEIVED,
  imsiNumbers: data,
});

export const searchIMSIRangesError = (errorData) => {
  const { response } = errorData;
  const error = getServerError(response);

  displayFetchErrorToaster(error);

  return {
    type: SEARCH_IMSI_RANGES_ERROR,
    message: error,
  };
};

export const searchIMSIRangesRequest =
  (startNumber, endNumber = null) =>
  async (dispatch) => {
    try {
      dispatch({ type: SEARCH_IMSI_RANGES_REQUEST });

      const response = await SimCardApi.searchIMSIRanges(
        startNumber,
        endNumber
      );

      dispatch(searchIMSIRangesReceived(response.data));
    } catch (error) {
      dispatch(searchIMSIRangesError(error));
    }
  };

export function fetchResellerListReceived(data) {
  return {
    type: FETCH_RESELLER_LIST_RECEIVED,
    resellers: data,
  };
}

export function fetchResellerListError(errorData) {
  const { response } = errorData;
  const error = getServerError(response);

  displayFetchErrorToaster(error);

  return {
    type: FETCH_RESELLER_LIST_ERROR,
    message: error,
  };
}

export const resellerListRequest = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_RESELLER_LIST_REQUEST });

    const response = await SimCardApi.fetchResellers();

    dispatch(fetchResellerListReceived(response.data));
  } catch (error) {
    dispatch(fetchResellerListError(error));
  }
};

export const updateSimCardRequest =
  (simCardID, attributes, callback) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SIM_CARD_REQUEST });

      const response = await SimCardApi.updateSIMCard(simCardID, attributes);

      dispatch(updateSIMCardReceived(response.data));
      callback();
    } catch (errors) {
      dispatch(updateSIMCardError(errors, callback));
    }
  };

export function fetchSimCardBorrowerListReceived(data) {
  return {
    type: FETCH_SIM_CARD_BORROWER_LIST_RECEIVED,
    simCardBorrowers: data,
  };
}

export function fetchSimCardBorrowerListError(errorData) {
  const { response } = errorData;
  const error = getServerError(response);

  displayFetchErrorToaster(error);

  return {
    type: FETCH_SIM_CARD_BORROWER_LIST_ERROR,
    message: error,
  };
}

export const simCardBorrowerListRequest = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SIM_CARD_BORROWER_LIST_REQUEST });

    const response = await SimCardApi.fetchSimCardBorrowers();

    dispatch(fetchSimCardBorrowerListReceived(response.data));
  } catch (error) {
    dispatch(fetchSimCardBorrowerListError(error));
  }
};

export const clearSimCardErrors = () => ({
  type: CLEAR_SIM_CARDS_ERRORS,
});

export const searchICCIDRangesReceived = (data) => ({
  type: SEARCH_ICCID_RANGES_RECEIVED,
  iccidNumbers: data,
});

export const searchICCIDRangesError = (errorData) => {
  const { response } = errorData;
  const error = getServerError(response);

  displayFetchErrorToaster(error);

  return {
    type: SEARCH_ICCID_RANGES_ERROR,
    message: error,
  };
};

export const searchICCIDRangesRequest =
  (startNumber, endNumber = null) =>
  async (dispatch) => {
    try {
      dispatch({ type: SEARCH_ICCID_RANGES_REQUEST });

      const response = await SimCardApi.searchICCIDRanges(
        startNumber,
        endNumber
      );

      dispatch(searchICCIDRangesReceived(response.data));
    } catch (error) {
      dispatch(searchICCIDRangesError(error));
    }
  };
