import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

import Pill from "components/Pill/Pill";

import { SUBSCRIPTION_LINE_TYPE } from "constants/subscriptionLine";

const SubscriptionListEmptyUI = () => (
  <div className="p-4 h-100 shadow-sm bg-white d-flex flex-column align-items-center justify-content-center empty-table">
    <div className="info-icon-wrapper border-danger mb-2">
      <FontAwesomeIcon className="info-icon text-danger" icon={faInfo} />
    </div>
    <div className="text-center fw-bold">
      <p className="d-inline-block mb-2">
        <Translate value="message.subscription.noSubscription" />
      </p>
    </div>
  </div>
);

const SubscriptionListTable = ({ secondarySubscriptionProducts }) => {
  if (!secondarySubscriptionProducts || !secondarySubscriptionProducts.length) {
    return <SubscriptionListEmptyUI />;
  }

  const productPrices = secondarySubscriptionProducts.map(
    (product) => product.price
  );

  const productPricesSum = productPrices.reduce(
    (partialSum, a) => partialSum + a,
    0
  );

  const subscriptionList = secondarySubscriptionProducts.map((subscription) => (
    <tr
      className="product-list fw-normal"
      key={subscription.id}
      data-testid="product-list"
    >
      <td className="text-left">
        <span className="pe-3">{subscription.product_name}</span>
        <Pill
          content={
            <Translate value={`pill.status.${subscription.line_type}`} />
          }
          show={subscription.line_type === SUBSCRIPTION_LINE_TYPE.SETUP}
          className="bg-green px-2 py-1"
        />
      </td>
      <td>{subscription.price}</td>
    </tr>
  ));

  return (
    <table className="subscription-list-table shadow-sm pill w-100 mt-3">
      <thead>
        <tr className="thead fw-bold">
          <th className="text-left" scope="col">
            <Translate value="attributes.product" />
          </th>
          <th scope="col">
            <Translate value="attributes.price" />
          </th>
        </tr>
      </thead>
      <tbody>
        {subscriptionList}
        <tr className="total-price">
          <td className="fw-bold">
            <Translate value="attributes.totalPrice" />
          </td>
          <td className="fw-bold">{productPricesSum}</td>
        </tr>
      </tbody>
    </table>
  );
};

SubscriptionListTable.propTypes = {
  secondarySubscriptionProducts: PropTypes.arrayOf(
    PropTypes.shape({
      end_date: PropTypes.string,
      id: PropTypes.number,
      is_archived: PropTypes.bool,
      last_invoice_date: PropTypes.string,
      line_type: PropTypes.string,
      odoo_product_id: PropTypes.number,
      price: PropTypes.number,
      product_name: PropTypes.string,
      property_editable: PropTypes.bool,
      start_date: PropTypes.string,
      subscription_id: PropTypes.number,
    })
  ).isRequired,
};

export default SubscriptionListTable;
