import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownItem } from "reactstrap";
import { Translate } from "react-i18nify";
import { Link } from "react-router-dom";

import dropdownEllipsisList from "utils/dropdownEllipsisList";

import { DROPDOWN_ELLIPSIS_ACTIONS } from "constants/constant";
import { QUOTATION } from "constants/routes";

const DropdownEllipsisList = ({
  handleQuotationPreview,
  handleQuotationEmail,
  handleDelete,
  quotationID,
  canConvertToAgreement,
}) =>
  dropdownEllipsisList.map(({ name, icon, actionType }) => {
    if (actionType === DROPDOWN_ELLIPSIS_ACTIONS.QUOTATION_PREVIEW) {
      return (
        <DropdownItem
          className="d-flex"
          key={name}
          onClick={handleQuotationPreview}
          data-cy={actionType}
        >
          <div className="menu-icon-dropdown">
            <FontAwesomeIcon icon={icon} />
          </div>
          <Translate value={name} />
        </DropdownItem>
      );
    }

    if (actionType === DROPDOWN_ELLIPSIS_ACTIONS.SEND_EMAIL) {
      return (
        <DropdownItem
          className="d-flex"
          key={name}
          onClick={handleQuotationEmail}
          data-cy={actionType}
        >
          <div className="menu-icon-dropdown">
            <FontAwesomeIcon icon={icon} />
          </div>
          <Translate value={name} />
        </DropdownItem>
      );
    }

    if (actionType === DROPDOWN_ELLIPSIS_ACTIONS.CONVERT_TO_AGREEMENT) {
      if (!canConvertToAgreement) return null;

      return (
        <DropdownItem
          className="d-flex"
          key={name}
          tag={Link}
          to={`${QUOTATION.addId(QUOTATION.CONVERT_TO_AGREEMENT, quotationID)}`}
          data-cy={actionType}
        >
          <div className="menu-icon-dropdown">
            <FontAwesomeIcon icon={icon} />
          </div>
          <Translate value={name} />
        </DropdownItem>
      );
    }

    return (
      <DropdownItem
        className="d-flex"
        key={name}
        onClick={handleDelete}
        data-cy={actionType}
      >
        <div className="menu-icon-dropdown">
          <FontAwesomeIcon icon={icon} />
        </div>
        <Translate value={name} />
      </DropdownItem>
    );
  });

DropdownEllipsisList.defaultProps = {};

DropdownEllipsisList.propTypes = {
  handleQuotationPreview: PropTypes.func.isRequired,
  handleQuotationEmail: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  quotationID: PropTypes.number.isRequired,
  canConvertToAgreement: PropTypes.bool.isRequired,
};

export default DropdownEllipsisList;
