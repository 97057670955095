import React from "react";
import { Route, Routes } from "react-router-dom";

import AppSettingEdit from "views/AppSetting/AppSettingEdit";
import AppSettingCreate from "views/AppSetting/AppSettingCreate";

import { NEW, EDIT, APP_SETTING } from "constants/routes/routesResources";
import { Admins } from "constants/userRoles";

const AdminAppSettingEdit = Admins(AppSettingEdit);
const AdminAppSettingCreate = Admins(AppSettingCreate);

const AppSettingRoutes = () => (
  <Routes>
    <Route path={NEW} element={<AdminAppSettingCreate />} />
    <Route path={APP_SETTING.APP_SETTING_ID}>
      <Route path={EDIT} element={<AdminAppSettingEdit />} />
    </Route>
  </Routes>
);

export default AppSettingRoutes;
