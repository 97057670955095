import { toastr } from "react-redux-toastr";

import { t } from "react-i18nify";

import { NumberPortingRequestPhoneNumberApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

export const NUMBER_PORTING_REQUEST_PHONE_NUMBER_UPDATE_REQUEST =
  "NUMBER_PORTING_REQUEST_PHONE_NUMBER_UPDATE_REQUEST";
export const NUMBER_PORTING_REQUEST_PHONE_NUMBER_UPDATE_RECEIVED =
  "NUMBER_PORTING_REQUEST_PHONE_NUMBER_UPDATE_RECEIVED";
export const NUMBER_PORTING_REQUEST_PHONE_NUMBER_UPDATE_FAILED =
  "NUMBER_PORTING_REQUEST_PHONE_NUMBER_UPDATE_FAILED";
export const NUMBER_PORTING_REQUEST_PHONE_NUMBER_CANCEL_REQUEST =
  "NUMBER_PORTING_REQUEST_PHONE_NUMBER_UPDATE_REQUEST";
export const NUMBER_PORTING_REQUEST_PHONE_NUMBER_CANCEL_RECEIVED =
  "NUMBER_PORTING_REQUEST_PHONE_NUMBER_UPDATE_RECEIVED";
export const NUMBER_PORTING_REQUEST_PHONE_NUMBER_CANCEL_FAILED =
  "NUMBER_PORTING_REQUEST_PHONE_NUMBER_UPDATE_FAILED";
export const NUMBER_PORTING_REQUEST_PHONE_NUMBER_RESET_STATES =
  "NUMBER_PORTING_REQUEST_PHONE_NUMBER_RESET_STATES";

// Update single numberPortingRequestPhoneNumber
export function numberPortingRequestPhoneNumberUpdateReceived(response) {
  const numberPortingRequestPhoneNumberTranslation = t(
    "attributes.numberPortingRequest"
  );

  toastr.success(
    t("common.success"),
    t("toasterMessage.resource.update", {
      resource: numberPortingRequestPhoneNumberTranslation,
    })
  );

  return {
    type: NUMBER_PORTING_REQUEST_PHONE_NUMBER_UPDATE_RECEIVED,
    numberPortingRequestPhoneNumber: response.data,
  };
}

export function numberPortingRequestPhoneNumberUpdateFailed(
  errorData,
  callback = () => {}
) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: NUMBER_PORTING_REQUEST_PHONE_NUMBER_UPDATE_FAILED,
    message: response.data,
  };
}

export const numberPortingRequestPhoneNumberUpdateRequest =
  (id, attributes, callback) => async (dispatch) => {
    dispatch({ type: NUMBER_PORTING_REQUEST_PHONE_NUMBER_UPDATE_REQUEST });

    await NumberPortingRequestPhoneNumberApi.update(id, attributes)
      .then((data) => {
        dispatch(numberPortingRequestPhoneNumberUpdateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(numberPortingRequestPhoneNumberUpdateFailed(errors, callback));
      });
  };

// Cancel single import flow
export function numberPortingRequestPhoneNumberCancelReceived(
  response,
  callBack
) {
  const numberPortingRequestPhoneNumberTranslation = t(
    "attributes.numberPortingRequest"
  );

  if (typeof callBack === "function") {
    callBack();

    toastr.success(
      t("common.success"),
      t("toasterMessage.resource.cancel", {
        resource: numberPortingRequestPhoneNumberTranslation,
      })
    );
  }

  return {
    type: NUMBER_PORTING_REQUEST_PHONE_NUMBER_CANCEL_RECEIVED,
    numberPortingRequestPhoneNumber: response.data,
  };
}

export function numberPortingRequestPhoneNumberCancelFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: NUMBER_PORTING_REQUEST_PHONE_NUMBER_CANCEL_FAILED,
    message,
  };
}

export const numberPortingRequestPhoneNumberCancelRequest =
  (id, callBack) => (dispatch) => {
    dispatch({ type: NUMBER_PORTING_REQUEST_PHONE_NUMBER_CANCEL_REQUEST });

    NumberPortingRequestPhoneNumberApi.cancelImportFlow(id)
      .then((response) =>
        dispatch(
          numberPortingRequestPhoneNumberCancelReceived(response, callBack)
        )
      )
      .catch((e) => {
        dispatch(numberPortingRequestPhoneNumberCancelFailed(e));
      });
  };
