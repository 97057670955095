import {
  PAYMENT_TERM_FETCH_REQUEST,
  PAYMENT_TERM_FETCH_RECEIVED,
  PAYMENT_TERM_FETCH_FAILED,
  PAYMENT_TERM_FETCH_CLEAR_ERRORS,
} from "redux/actions/paymentTerm";

const defaultAuthState = {
  paymentTerms: [],
  paymentTermFetchError: "",
};

function paymentTerm(state = defaultAuthState, action) {
  const { paymentTerms } = action;

  switch (action.type) {
    case PAYMENT_TERM_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case PAYMENT_TERM_FETCH_RECEIVED:
      return {
        ...state,
        paymentTerms,
        isLoading: false,
      };

    case PAYMENT_TERM_FETCH_FAILED:
      return {
        ...state,
        paymentTermFetchError: action.message,
        isLoading: false,
      };

    case PAYMENT_TERM_FETCH_CLEAR_ERRORS:
      return { ...state, paymentTermFetchError: "", isLoading: false };

    default:
      return state;
  }
}

export default paymentTerm;
