import React from "react";
import PropTypes from "prop-types";
import { faBox, faFile, faDollarSign } from "@fortawesome/free-solid-svg-icons";

import { SUBSCRIPTION_PRODUCT } from "constants/routes";

import { capitalizeWords } from "utils/string";

import CollapseItem from "components/CollapseItem/CollapseItem";
import TranslateNumber from "components/i18n/TranslateNumber";
import UsageProductPropertySection from "components/SubscriptionProduct/Index/UsageProductPropertySection";
import SubscriptionProductAttributeItem from "components/SubscriptionProduct/Index/SubscriptionProductAttributeItem";
import ProductPropertyItem from "components/SubscriptionProduct/Index/ProductPropertyItem";

const SubscriptionProductContent = ({
  subscriptionProduct: {
    id,
    product_name: productName,
    price,
    properties,
    subscription_for: subscriptionFor,
    subscription_type: subscriptionType,
    usage_product_property: usageProductProperty,
  },
  setCollapse,
  collapse,
  dataCy,
}) => {
  const propertiesArray =
    typeof properties === "string" ? properties.split(",") : properties;
  const usageSubscription = subscriptionType === "usage";

  const usageProductPropertySection = usageProductProperty && (
    <UsageProductPropertySection usageProductProperty={usageProductProperty} />
  );

  return (
    <CollapseItem
      dataCy={dataCy}
      title={productName}
      wrapperClass="shadow-sm  border-0 rounded border-bottom m-4"
      editUrl={SUBSCRIPTION_PRODUCT.addId(SUBSCRIPTION_PRODUCT.EDIT, id)}
      className="p-0"
      headingSize="h5"
      setCollapse={setCollapse}
      collapse={collapse}
      id={id}
    >
      <SubscriptionProductAttributeItem
        icon={faFile}
        translatedTitle="subscription.for"
        attributeValue={capitalizeWords(subscriptionFor)}
      />

      <SubscriptionProductAttributeItem
        icon={faBox}
        translatedTitle="attributes.productName"
        attributeValue={productName}
      />

      <SubscriptionProductAttributeItem
        icon={faDollarSign}
        translatedTitle="attributes.productPrice"
        attributeValue={
          <TranslateNumber value={price} minimumFractionDigits={0} />
        }
      />

      {usageProductPropertySection}

      {!usageSubscription && (
        <ProductPropertyItem propertiesArray={propertiesArray} />
      )}
    </CollapseItem>
  );
};

SubscriptionProductContent.defaultProps = {
  dataCy: "",
};

SubscriptionProductContent.propTypes = {
  subscriptionProduct: PropTypes.shape({
    id: PropTypes.number,
    subscription_for: PropTypes.string,
    subscription_type: PropTypes.string,
    product_name: PropTypes.string,
    price: PropTypes.number,
    properties: PropTypes.string,
    usage_product_property: PropTypes.shape({}),
  }).isRequired,
  setCollapse: PropTypes.func.isRequired,
  collapse: PropTypes.node.isRequired,
  dataCy: PropTypes.string,
};

export default SubscriptionProductContent;
