import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { singlePbxFetchRequest } from "redux/actions/pbx";

import WholePageSpinner from "components/Spinner/WholePageSpinner";
import CellPhoneFormContent from "components/CellPhone/Form/CellPhoneFormContent";

const CellPhoneCreate = ({ fetchPbx, pbx }) => {
  const { pbxID } = useParams();

  useEffect(() => {
    fetchPbx(pbxID);
  }, []);

  const isResourceValidOrLoading =
    !pbx || (pbx && pbx.id !== parseInt(pbxID, 10));

  if (isResourceValidOrLoading) {
    return <WholePageSpinner />;
  }

  const { agreement_id: agreementID } = pbx;

  const initialValues = {
    pbx_id: pbxID,
    ex_recording: "no",
    ex_dialtimeout: 30,
    subscription_attributes: {
      agreement_id: agreementID,
      subscription_type: "cell_phone",
    },
  };

  return (
    <CellPhoneFormContent initialValues={initialValues} parentType="pbx" />
  );
};

const mapStateToProps = ({ pbx: pbxReducer }) => {
  const { pbx } = pbxReducer;

  return {
    pbx,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPbx: (id) => {
    dispatch(singlePbxFetchRequest(id));
  },
});

CellPhoneCreate.defaultProps = {};

CellPhoneCreate.propTypes = {
  fetchPbx: PropTypes.func.isRequired,
  pbx: PropTypes.shape().isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CellPhoneCreate);
