import React from "react";
import { Translate } from "react-i18nify";
import { Field, useField } from "react-final-form";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, UncontrolledTooltip, FormGroup } from "reactstrap";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import { required } from "utils/validation";

import { renderInputField } from "components/ReduxForm/RenderField";
import renderDateTimeField from "components/ReduxForm/DateTimeField/DateTimeField";

const RequestedNumberList = ({ fields }) => {
  const {
    input: { value: requestedPortingDate },
  } = useField("requested_porting_date");
  const {
    input: { value: numberType },
  } = useField("number_type");

  const cellPhone = numberType === "cell_phone";

  return (
    <>
      <div className="table-responsive subscription-list-table requested-number-list-table mt-3">
        <table hidden={fields.length === 0} className="table">
          <thead>
            <tr>
              <th className="w-30em">
                <Translate value="attributes.phone" />
              </th>

              {cellPhone && (
                <th className="w-30em">
                  <Translate value="attributes.iccNumber" />
                </th>
              )}

              {/* w-30em is a custom css class and not from bootstrap */}
              <th className="w-30em">
                <Translate value="attributes.portingDate" />
              </th>
              <th>
                <Translate value="common.delete" />
              </th>
            </tr>
          </thead>

          <tbody>
            {fields.map((record, index) => (
              <tr key={index}>
                <td className="w-30em">
                  <Field
                    name={`${record}.service_number`}
                    type="text"
                    component={renderInputField}
                    validate={required}
                  />
                </td>

                {cellPhone && (
                  <td className="w-30em">
                    <Field
                      name={`${record}.icc_number`}
                      type="text"
                      component={renderInputField}
                    />
                  </td>
                )}

                <td className="w-30em">
                  <FormGroup>
                    <Field
                      name={`${record}.requested_porting_date`}
                      component={renderDateTimeField}
                      disabledDays={{
                        before: new Date(requestedPortingDate),
                      }}
                    />
                  </FormGroup>
                </td>

                <td>
                  <button
                    data-cy="btn-to-remove-number"
                    type="button"
                    className="btn btn-danger table-action-btn"
                    onClick={() => fields.remove(index)}
                  >
                    <FontAwesomeIcon icon={faTrash} id="trashToolTip" />
                    <UncontrolledTooltip placement="top" target="trashToolTip">
                      <Translate value="common.delete" />
                    </UncontrolledTooltip>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Row className="mt-3">
        <Col md="6">
          <button
            data-cy="btn-to-add-number"
            type="button"
            className="btn btn-outline-primary"
            onClick={() =>
              fields.push({ requested_porting_date: requestedPortingDate })
            }
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <Translate value="common.addNumber" />
          </button>
        </Col>
      </Row>
    </>
  );
};

RequestedNumberList.defaultProps = {
  fields: [],
};

RequestedNumberList.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})),
};

export default RequestedNumberList;
