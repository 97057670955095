class ApiUrlConfig {
  static endPoint() {
    let basePath;

    switch (process.env.REACT_APP_ENV) {
      case "development":
        basePath = "http://localhost:3000";
        // basePath = "https://mit-dev-api.flexonet.dk";
        break;
      case "staging":
        basePath = "https://mit-dev-api.flexonet.dk";
        break;
      case "production":
        basePath = "https://mit-api.flexonet.dk";
        break;
      default:
        // basePath = "http://localhost:3000";
        basePath = "https://mit-dev-api.flexonet.dk";

        break;
    }

    return basePath;
  }

  static apiPath() {
    return "/api/v1";
  }

  static odooPath() {
    return "/flexonet_odoo";
  }

  static pbxPath() {
    return "/flexonet_pbx";
  }

  static apiEndPoint() {
    return ApiUrlConfig.endPoint() + ApiUrlConfig.apiPath();
  }

  static odooEndPoint() {
    return (
      ApiUrlConfig.endPoint() + ApiUrlConfig.odooPath() + ApiUrlConfig.apiPath()
    );
  }

  static pbxEndPoint() {
    return (
      ApiUrlConfig.endPoint() + ApiUrlConfig.pbxPath() + ApiUrlConfig.apiPath()
    );
  }
}

export default ApiUrlConfig;
