function calculateSumOfObjectsArray(array, field) {
  return array
    .map((items) => items[field])
    .reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0);
}

function calculateSumOfObjectsArrayWithNaNValues(array, field) {
  return array
    .map((items) => items[field])
    .reduce((previous, current) => {
      if (current === undefined) return parseFloat(previous);

      return parseFloat(previous) + parseFloat(current);
    }, 0);
}

// Format attributes of agreement before sending to backend for the create or update
function formatRequestValues(values) {
  const { subscriptions } = values;

  // Filter subscriptions with empty subscription lines so they are not saved in database
  const filteredSubscriptions = subscriptions.filter(
    (subscription) => subscription.subscription_lines.length !== 0
  );

  const subscriptionsAttributes = filteredSubscriptions.map((subscription) => {
    const { subscription_lines: subscriptionLines } = subscription;

    const subscriptionLinesAttributes = subscriptionLines.map(
      (subscriptionLine) => {
        const { subscription_line_properties: subscriptionLineProperties } =
          subscriptionLine;

        const subscriptionLineAttribute = {
          ...subscriptionLine,
          subscription_line_properties_attributes: subscriptionLineProperties,
        };

        return subscriptionLineAttribute;
      }
    );

    const recurringLines = subscriptionLines.filter(
      (line) => line.line_type !== "setup"
    );
    const subscriptionTotalAmount = calculateSumOfObjectsArray(
      recurringLines,
      "price"
    );

    const subscriptionAttribute = {
      ...subscription,
      total_amount: subscriptionTotalAmount,
      subscription_lines_attributes: subscriptionLinesAttributes,
    };

    return subscriptionAttribute;
  });

  const totalAmount = calculateSumOfObjectsArray(
    subscriptionsAttributes,
    "total_amount"
  );

  const agreement = {
    ...values,
    total_amount: totalAmount,
    subscriptions_attributes: subscriptionsAttributes,
  };

  return agreement;
}

function formatSearchResults(result) {
  if (!result) return [];
  if (Array.isArray(result)) return result;
  if (typeof result === "object") return [result];

  return result;
}

function calculateTotalAmount(attributeName) {
  return attributeName
    .map((item) => item.total_amount)
    .reduce((prev, curr) => prev + curr, 0);
}

export {
  calculateSumOfObjectsArray,
  calculateSumOfObjectsArrayWithNaNValues,
  formatRequestValues,
  formatSearchResults,
  calculateTotalAmount,
};
