import { ProviderApi } from "internal";
import { getAndDisplayErrors } from "utils/utils";

export const PROVIDER_FETCH_REQUEST = "PROVIDER_FETCH_REQUEST";
export const PROVIDER_FETCH_RECEIVED = "PROVIDER_FETCH_RECEIVED";
export const PROVIDER_FETCH_FAILED = "PROVIDER_FETCH_FAILED";
export const PROVIDER_FETCH_CLEAR_ERRORS = "PROVIDER_FETCH_CLEAR_ERRORS";

// Fetch all Routing Profiles
export function providerFetchReceived(response) {
  return {
    type: PROVIDER_FETCH_RECEIVED,
    providers: response.data,
  };
}

export function providerFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: PROVIDER_FETCH_FAILED,
    message,
  };
}

export const providerFetchRequest = () => (dispatch) => {
  dispatch({ type: PROVIDER_FETCH_REQUEST });

  ProviderApi.fetchAll()
    .then((data) => dispatch(providerFetchReceived(data)))
    .catch((e) => {
      dispatch(providerFetchFailed(e));
    });
};
