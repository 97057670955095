import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";

import { paymentTermFetchRequest } from "redux/actions/paymentTerm";
import { debtorFetchRequest } from "redux/actions/debtor";
import {
  convertToAgreementRequest,
  singleQuotationFetchRequest,
} from "redux/actions/quotation";

import { AGREEMENT } from "constants/routes";

import { formatConvertToAgreementRequestValues } from "utils/quotation";
import { processServerResponseForAsyncValidationErrors } from "utils/forms";

import WholePageSpinner from "components/Spinner/WholePageSpinner";
import ConvertToAgreementForm from "components/Quotation/Form/ConvertToAgreement/ConvertToAgreementForm";
import PageNotFound from "components/Error/PageNotFound/PageNotFound";

const ConvertQuotationToAgreement = ({
  convertToAgreement,
  commitSuccess,
  isLoading,
  agreement,
  quotation,
  fetchQuotation,
  fetchPaymentTerms,
  fetchDebtors,
  debtors,
  paymentTerms,
  isDebtorFetching,
  isPaymentTermFetching,
}) => {
  const { quotationID } = useParams();

  const [dissociatedSubscriptionsList, setDissociatedSubscriptionsList] =
    useState([]);

  useEffect(() => {
    fetchDebtors();
    fetchPaymentTerms();
  }, []);

  useEffect(() => {
    fetchQuotation(quotationID);
  }, [quotationID]);

  const subscriptionLinePropertiesWithoutID = (subscriptionLineProperties) =>
    subscriptionLineProperties.map(
      ({
        id,
        subscription_line_id: subscriptionLineID,
        ...remainingSubscriptionLinePropertiesAttributes
      }) => remainingSubscriptionLinePropertiesAttributes
    );

  const subscriptionLinesWithoutID = (subscriptionLines) =>
    subscriptionLines.map(
      ({
        id: subscriptionLineID,
        subscription_id: subscriptionID,
        subscription_line_properties: subscriptionLineProperties,
        ...remainingSubscriptionLineAttributes
      }) => ({
        start_date: new Date(),
        ...remainingSubscriptionLineAttributes,
        subscription_line_properties: subscriptionLinePropertiesWithoutID(
          subscriptionLineProperties
        ),
      })
    );

  const createNewSubscriptionsList = (subscriptions) => {
    const newSubscriptionsList = [];

    subscriptions.forEach(
      ({ id, quantity, subscription_lines: subscriptionLines, ...rest }) => {
        const quantityArray = new Array(quantity)
          .fill()
          .map((val, index) => index);

        const newSubscriptions = quantityArray.map(() => ({
          ...rest,
          quantity: 1,
          subscription_lines: subscriptionLinesWithoutID(subscriptionLines),
        }));

        newSubscriptions.push({ id, _destroy: true });

        newSubscriptionsList.push(...newSubscriptions);
      }
    );

    return newSubscriptionsList;
  };

  useEffect(() => {
    if (!quotation) return;

    const { subscriptions } = quotation;

    const subscriptionsList = createNewSubscriptionsList(subscriptions);

    setDissociatedSubscriptionsList(subscriptionsList);
  }, [quotation]);

  const handleSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    const formattedValues = formatConvertToAgreementRequestValues(values);

    await convertToAgreement(quotationID, formattedValues, (errors) => {
      serverResponse = errors;
    });

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (commitSuccess) {
    const { id } = agreement;

    return <Navigate to={AGREEMENT.addId(AGREEMENT.SHOW, id)} />;
  }

  if (!quotation || isDebtorFetching) {
    return <WholePageSpinner />;
  }

  const {
    odoo_debtor_id: odooDebtorID,
    agreement_id: agreementID,
    sent_date: sentDate,
  } = quotation;

  if (!sentDate) {
    return (
      <div className="d-flex h-100 justify-content-center">
        <PageNotFound />
      </div>
    );
  }

  const debtor = odooDebtorID
    ? debtors.find((odooDebtor) => odooDebtor.id === parseInt(odooDebtorID, 10))
    : {};

  const initialDebtorValue = odooDebtorID
    ? {
        odoo_debtor_id: parseInt(odooDebtorID, 10),
        odoo_payment_term_id: debtor?.payment_term_id,
      }
    : {};

  return (
    <div className="container-fluid">
      <div>
        <h3 className="mt-3">
          <Translate value="title.resource.convertToAgreement" />
        </h3>
      </div>

      <ConvertToAgreementForm
        onSubmit={handleSubmit}
        isLoading={isLoading}
        debtors={debtors}
        paymentTerms={paymentTerms}
        initialValues={{
          id: agreementID,
          invoice_period: "quarterly",
          start_date: new Date(),
          is_billable: false,
          is_voip_billing_enabled: false,
          agreement: {
            subscriptions: dissociatedSubscriptionsList,
          },
          ...initialDebtorValue,
        }}
        isDebtorFetching={isDebtorFetching}
        isPaymentTermFetching={isPaymentTermFetching}
      />
    </div>
  );
};

ConvertQuotationToAgreement.defaultProps = {
  debtors: [],
  isLoading: true,
  quotation: undefined,
  isDebtorFetching: false,
  isPaymentTermFetching: false,
};

ConvertQuotationToAgreement.propTypes = {
  agreement: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  paymentTerms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  fetchDebtors: PropTypes.func.isRequired,
  fetchQuotation: PropTypes.func.isRequired,
  fetchPaymentTerms: PropTypes.func.isRequired,
  convertToAgreement: PropTypes.func.isRequired,
  debtors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  isDebtorFetching: PropTypes.bool,
  isPaymentTermFetching: PropTypes.bool,
  commitSuccess: PropTypes.bool.isRequired,
  quotation: PropTypes.shape({
    id: PropTypes.number,
    agreement_id: PropTypes.number,
    odoo_debtor_id: PropTypes.number,
    sent_date: PropTypes.string,
    subscriptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        is_archived: PropTypes.bool,
        subscription_lines: PropTypes.arrayOf(PropTypes.shape({})),
        monthly_price: PropTypes.number,
        setup_price: PropTypes.number,
        forEach: PropTypes.func,
      })
    ),
  }),
};

function mapStateToProps({ quotation: quotationReducer, paymentTerm, debtor }) {
  const { isLoading, quotation, agreement, commitSuccess } = quotationReducer;
  const { paymentTerms, isLoading: isPaymentTermFetching } = paymentTerm;
  const { debtors, isLoading: isDebtorFetching } = debtor;

  return {
    isLoading,
    commitSuccess,
    quotation,
    agreement,
    paymentTerms,
    isPaymentTermFetching,
    debtors,
    isDebtorFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    convertToAgreement: async (id, attributes, callback) => {
      await dispatch(convertToAgreementRequest(id, attributes, callback));
    },
    fetchPaymentTerms: () => {
      dispatch(paymentTermFetchRequest());
    },
    fetchDebtors: () => {
      dispatch(debtorFetchRequest());
    },
    fetchQuotation: (id) => {
      dispatch(singleQuotationFetchRequest(id));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConvertQuotationToAgreement);
