import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Admins } from "constants/userRoles";

import VendorsIndex from "views/Vendors/VendorsIndex";
import VendorDetail from "views/Vendors/VendorDetail";
import DebtorEdit from "views/Debtor/DebtorEdit";
import { DETAILS, EDIT, VENDOR } from "constants/routes/routesResources";

const AdminVendorsIndex = Admins(VendorsIndex);
const AdminVendorDetail = Admins(VendorDetail);
const AdminDebtorEdit = Admins(DebtorEdit);

const VendorsRoute = () => (
  <Routes>
    <Route index element={<AdminVendorsIndex />} />
    <Route path={`${VENDOR.VENDOR_ID}/*`} element={<AdminVendorDetail />}>
      <Route index element={<Navigate to={DETAILS} replace />} />
    </Route>

    <Route path={`${VENDOR.VENDOR_ID}/${EDIT}`} element={<AdminDebtorEdit />} />
  </Routes>
);

export default VendorsRoute;
