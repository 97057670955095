import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { dashboardUserFetchRequest } from "redux/actions/dashboard/user";
import { connect } from "react-redux";

import DashboardComponent from "components/Dashboard/DashboardComponent/DashboardComponent";
import UserItem from "components/Dashboard/DashboardItem/UserItem";

import { DASHBOARD_COMPONENT_FEATURE_TYPES } from "constants/constant";

const UserView = ({ fetchUser, user, isLoading, fetchError }) => {
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <DashboardComponent
      translationTitle="menu.user"
      isLoading={isLoading || !user}
      fetchError={fetchError}
      onRetry={fetchUser}
      featureType={DASHBOARD_COMPONENT_FEATURE_TYPES.USERS}
    >
      <UserItem item={user} />
    </DashboardComponent>
  );
};

UserView.defaultProps = {
  fetchUser: () => {},
  user: null,
  isLoading: false,
  fetchError: false,
};

UserView.propTypes = {
  fetchUser: PropTypes.func,
  user: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  fetchError: PropTypes.bool,
};

const mapStateToProps = ({ dashboard }) => {
  const { user } = dashboard;

  return {
    ...user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUser: () => dispatch(dashboardUserFetchRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserView);
