import React from "react";
import PropTypes from "prop-types";
import {
  faCalendarWeek,
  faCommentAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faMobileAlt,
  faTty,
} from "@fortawesome/free-solid-svg-icons";
import { faShareSquare } from "@fortawesome/free-regular-svg-icons";

import { Translate } from "react-i18nify";

import { capitalizeWords } from "utils/string";

import TranslateDate from "components/i18n/TranslateDate";
import CardWithHeader from "components/Card/WithHeader/CardWithHeader";
import FixedBanner from "components/Banner/FixedBanner";

const RequestedNumberDetail = ({
  numberPortingRequestPhoneNumbers,
  numberType,
}) => {
  const [requestedPhoneNumberRecord] = numberPortingRequestPhoneNumbers;

  const {
    service_number: serviceNumber,
    icc_number: iccNumber,
    export_status: exportStatus,
    confirmed_porting_date: confirmedPortingDate,
    rejection_date: rejectionDate,
    rejection_reason: rejectionReason,
    export_requested_number_status: exportRequestedNumberStatus,
  } = requestedPhoneNumberRecord;

  const rejectedAttributes = exportStatus === "rejected" && [
    {
      icon: faCalendarWeek,
      title: <Translate value="attributes.rejectionDate" />,
      content: <TranslateDate date={rejectionDate} />,
    },
    {
      icon: faCommentAlt,
      title: <Translate value="attributes.rejectionReason" />,
      content: rejectionReason,
    },
  ];

  const detailContent = [
    {
      icon: faTty,
      title: <Translate value="attributes.phone" />,
      content: serviceNumber,
    },
    numberType === "cell_phone" && {
      icon: faMobileAlt,
      title: <Translate value="attributes.iccNumber" />,
      content: iccNumber || <Translate value="common.notSet" />,
    },
    {
      icon: faShareSquare,
      title: <Translate value="attributes.exportStatus" />,
      content: capitalizeWords(exportStatus),
    },
    exportRequestedNumberStatus && {
      icon: faExclamationCircle,
      title: <Translate value="attributes.requestedNumberStatus" />,
      content: capitalizeWords(exportStatus),
    },
    exportStatus === "accepted" && {
      icon: faCalendarWeek,
      title: <Translate value="attributes.confirmedPortingDate" />,
      content: <TranslateDate date={confirmedPortingDate} />,
    },
    rejectedAttributes && { ...rejectedAttributes },
  ];

  return (
    <>
      {exportStatus === "requested_number_not_found" && (
        <FixedBanner
          title={
            <Translate value="numberPortingRequest.exportRequest.requestedNumberNotFound" />
          }
          text={
            <Translate value="numberPortingRequest.exportRequest.askCustomerToSubmitNewRequest" />
          }
          iconName={faExclamationTriangle}
          bannerBackgroundClass="bg-warning"
        />
      )}

      <CardWithHeader
        title={<Translate value="common.requestedNumberDetails" />}
        className="shadow-sm"
        cardContent={detailContent}
      />
    </>
  );
};

RequestedNumberDetail.defaultProps = {};

RequestedNumberDetail.propTypes = {
  numberPortingRequestPhoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      number_type: PropTypes.string,
      debtor_name: PropTypes.string,
      pbx_ref_number: PropTypes.string,
      customer_email: PropTypes.string,
      requested_porting_date: PropTypes.instanceOf(Date),
    })
  ).isRequired,
  numberType: PropTypes.string.isRequired,
};

export default RequestedNumberDetail;
