import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const DeleteButton = ({ handleDelete, className, ...rest }) => (
  <button
    type="button"
    onClick={handleDelete}
    className={`${className} btn btn-danger text-white mt-2 mt-md-0 ms-md-2 px-3`}
    data-cy="delete-btn"
    data-testid="delete-btn"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...rest}
  >
    <FontAwesomeIcon
      className="text-white m-0"
      icon={faTrash}
      data-testid="fa-trash"
    />
  </button>
);

DeleteButton.defaultProps = {
  handleDelete: () => {},
  className: "",
};

DeleteButton.propTypes = {
  handleDelete: PropTypes.func,
  className: PropTypes.string,
};

export default DeleteButton;
