import { DebtorTypeApi } from "internal";
import { getAndDisplayErrors } from "utils/utils";

export const DEBTOR_TYPE_FETCH_REQUEST = "DEBTOR_TYPE_FETCH_REQUEST";
export const DEBTOR_TYPE_FETCH_RECEIVED = "DEBTOR_TYPE_FETCH_RECEIVED";
export const DEBTOR_TYPE_FETCH_FAILED = "DEBTOR_TYPE_FETCH_FAILED";
export const DEBTOR_TYPE_FETCH_CLEAR_ERRORS = "DEBTOR_TYPE_FETCH_CLEAR_ERRORS";

export function debtorTypeFetchReceived(response) {
  return {
    type: DEBTOR_TYPE_FETCH_RECEIVED,
    debtorTypes: response.data,
  };
}

export function debtorTypeFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: DEBTOR_TYPE_FETCH_FAILED,
    message,
  };
}

export function debtorTypeFetchClearErrors() {
  return {
    type: DEBTOR_TYPE_FETCH_CLEAR_ERRORS,
  };
}

export const debtorTypeFetchRequest = () => (dispatch) => {
  dispatch({ type: DEBTOR_TYPE_FETCH_REQUEST });

  DebtorTypeApi.fetchAll()
    .then((data) => dispatch(debtorTypeFetchReceived(data)))
    .catch((e) => {
      dispatch(debtorTypeFetchFailed(e));
    });
};
