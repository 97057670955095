import React from "react";
import { Route, Routes } from "react-router-dom";

import { Admins } from "constants/userRoles";

import DidNumberDetail from "views/DidNumber/DidNumberDetail";
import DidNumberEdit from "views/DidNumber/DidNumberEdit";
import FaxStatusIndex from "views/FaxStatus/FaxStatusIndex";
import { EDIT, DID_NUMBER } from "constants/routes/routesResources";

const AdminDidNumberEdit = Admins(DidNumberEdit);

const DidNumberRoutes = () => (
  <Routes>
    <Route path={DID_NUMBER.DID_NUMBER_ID}>
      <Route index element={<DidNumberDetail />} />
      <Route path={EDIT} element={<AdminDidNumberEdit />} />
    </Route>
    <Route path={DID_NUMBER.PHONE_NUMBER}>
      <Route path={DID_NUMBER.FAX_STATUSES} element={<FaxStatusIndex />} />
    </Route>
  </Routes>
);

export default DidNumberRoutes;
