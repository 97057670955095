import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";
import { Translate } from "react-i18nify";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSimCard } from "@fortawesome/free-solid-svg-icons";

import ResourceHeader from "components/ResourceHeader/ResourceHeader";
import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import Pill from "components/Pill/Pill";

import {
  searchSimCard,
  simCardBorrowerListRequest,
  simCardSearchRequest,
  simCardsRequest,
} from "redux/actions/simCard";
import { serviceProviderRequest } from "redux/actions/phoneNumber";

import filterSearchResults from "utils/filterSearchResults";
import { getParams } from "utils/queryString";
import addParams from "utils/addParams";

import { FILTER_STATUS_SIM_CARD } from "constants/filters";
import { SIM_CARD } from "constants/routes";
import { SIM_CARD_BORROWERS, SIM_CARD_STATUS } from "constants/constant";

const SimCardIndex = ({
  fetchSimCards,
  simCards,
  isLoading,
  isSearching,
  fetchError,
  fetchServiceProviders,
  searchRecords,
  serviceProviders,
  fetchSimCardBorrowerListRequest,
  simCardBorrowers,
}) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const {
    status = SIM_CARD_STATUS.AVAILABLE,
    service_provider_id: serviceProviderID,
    sim_card_borrower_id: simCardBorrowerID,
  } = getParams(search);

  const isOwnBrand =
    serviceProviders.find(({ id }) => id === Number(serviceProviderID))
      ?.is_own_brand || false;

  const filterSimCardsHandler = (
    statusType,
    serviceProviderId,
    simCardBorrowerId = null
  ) => {
    let simCardBorrowerType = null;

    if (simCardBorrowerId) {
      const simCardBorrower = simCardBorrowers.find(
        ({ borrower_id: borrowerID }) =>
          borrowerID === Number(simCardBorrowerId)
      );

      if (simCardBorrower) {
        const { is_employee: isEmployee } = simCardBorrower;

        simCardBorrowerType = isEmployee
          ? SIM_CARD_BORROWERS.EMPLOYEE
          : SIM_CARD_BORROWERS.CUSTOMER;
      }

      fetchSimCards(
        statusType,
        serviceProviderId,
        simCardBorrowerType,
        simCardBorrowerId
      );

      return;
    }

    fetchSimCards(statusType, serviceProviderId);
  };

  useEffect(() => {
    fetchServiceProviders();
    fetchSimCardBorrowerListRequest();
  }, []);

  useEffect(() => {
    if (serviceProviders.length === 0) return;

    const { id } = serviceProviders.find(
      ({ is_own_brand: ownBrand }) => ownBrand
    );

    const paramsList = {
      status,
      service_provider_id: id,
    };

    if (
      typeof simCardBorrowerID !== "undefined" &&
      simCardBorrowerID !== "undefined"
    ) {
      paramsList.sim_card_borrower_id = simCardBorrowerID;

      filterSimCardsHandler(status, id, simCardBorrowerID);
    } else {
      filterSimCardsHandler(status, id);
    }

    const searchParams = addParams(paramsList);

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath, { replace: true });
  }, [serviceProviders, simCardBorrowers]);

  const allFilters = [
    {
      filter: FILTER_STATUS_SIM_CARD,
      selectedFilter: status,
      type: "statusType",
      headerTranslation: "user.status",
    },
    {
      filter: serviceProviders.map(({ id, name }) => ({
        value: id.toString(),
        nameTranslation: name,
      })),
      selectedFilter: serviceProviderID,
      type: "serviceProvider",
      headerTranslation: "common.owner",
    },
  ];

  if (isOwnBrand) {
    allFilters.push({
      filter: simCardBorrowers.map(
        ({ id, name, is_employee: isEmployee, borrower_id: borrowerID }) => {
          const simCardBorrowerType = isEmployee
            ? SIM_CARD_BORROWERS.EMPLOYEE
            : SIM_CARD_BORROWERS.DEBTOR;

          const pillClassName =
            simCardBorrowerType === SIM_CARD_BORROWERS.EMPLOYEE
              ? "bg-green"
              : "bg-yellow";

          return {
            value: borrowerID.toString(),
            nameTranslation: name,
            customLabel: (
              <span className="py-1" key={id}>
                {name}
                <Pill
                  content={
                    <Translate value={`pill.status.${simCardBorrowerType}`} />
                  }
                  show
                  className={`${pillClassName} ms-2 py-1 px-3`}
                />
              </span>
            ),
          };
        }
      ),
      selectedFilter: simCardBorrowerID,
      type: "simCardBorrower",
      headerTranslation: "common.simCardHolder",
    });
  } else if (!isOwnBrand) {
    allFilters.filter(({ type }) => type !== "simCardBorrower");
  }

  const changeFilter = ({ statusType, serviceProvider, ...rest }) => {
    const paramsList = {
      status: statusType,
      service_provider_id: serviceProvider,
    };

    if (isOwnBrand) {
      const { simCardBorrower } = rest;

      paramsList.sim_card_borrower_id = simCardBorrower;

      let simCardBorrowerType = null;

      if (
        simCardBorrower !== "undefined" &&
        simCardBorrowers !== undefined &&
        simCardBorrowers.length !== 0
      ) {
        const simCard = simCardBorrowers.find(
          ({ borrower_id: borrowerID }) =>
            borrowerID === Number(simCardBorrower)
        );

        if (simCardBorrower) {
          const { is_employee: isEmployee } = simCard;

          simCardBorrowerType = isEmployee
            ? SIM_CARD_BORROWERS.EMPLOYEE
            : SIM_CARD_BORROWERS.CUSTOMER;
        }
      }

      fetchSimCards(
        status,
        serviceProviderID,
        simCardBorrowerType,
        simCardBorrower
      );
    } else {
      fetchSimCards(status, serviceProviderID);
    }

    const searchParams = addParams(paramsList);

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath, { replace: true });
  };

  const navigateToAssignSimCard = () => {
    navigate(SIM_CARD.ASSIGN, { replace: true });
  };

  return (
    <RecordListingTable
      records={simCards}
      isLoading={isLoading}
      fetchError={fetchError}
      hideAddButton
      onRetry={() => fetchSimCards(status, serviceProviderID)}
      resourceName="sim_cards"
      additionalHeaderContent={
        <ResourceHeader
          changeFilter={changeFilter}
          allFilters={allFilters}
          resourceStatusComponent={
            <button
              data-cy="assign-sim-card"
              className="btn btn-primary"
              type="button"
              onClick={navigateToAssignSimCard}
            >
              <FontAwesomeIcon icon={faSimCard} />
              <Translate value="common.assignSimCard" />
            </button>
          }
        />
      }
      resourceTitle={
        <Translate value="title.page.resource.list" resource="Sim Card" />
      }
      searchRecords={searchRecords}
      isSearching={isSearching}
    >
      <Column
        width={320}
        label={<Translate value="attributes.iccid" />}
        dataKey="iccid_number"
      />
      <Column
        width={200}
        label={<Translate value="attributes.networkOperator" />}
        cellDataGetter={({ rowData }) => rowData.network_operator.name}
        dataKey="name"
      />
      <Column
        width={300}
        label={<Translate value="attributes.imsi" />}
        dataKey="imsi_number"
      />
    </RecordListingTable>
  );
};

SimCardIndex.defaultProps = {
  isLoading: false,
  isSearching: false,
  simCards: [],
  serviceProviders: [],
  simCardBorrowers: [],
};

SimCardIndex.propTypes = {
  fetchSimCards: PropTypes.func.isRequired,
  simCards: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  isSearching: PropTypes.bool,
  fetchError: PropTypes.func.isRequired,
  fetchServiceProviders: PropTypes.func.isRequired,
  searchRecords: PropTypes.func.isRequired,
  serviceProviders: PropTypes.arrayOf(),
  fetchSimCardBorrowerListRequest: PropTypes.func.isRequired,
  simCardBorrowers: PropTypes.arrayOf(),
};

const mapStateToProps = ({ simCard, search, phoneNumber }) => {
  const { simCards, isLoading, isSearching, fetchError, simCardBorrowers } =
    simCard;
  const { serviceProviders } = phoneNumber;

  const results = filterSearchResults({
    resource: simCards,
    searchResult: search.simCards,
  });

  return {
    simCards: results,
    isLoading,
    isSearching,
    fetchError,
    serviceProviders,
    simCardBorrowers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSimCards: (
    status,
    serviceProviderID,
    simCardBorrowerType = null,
    simCardBorrowerID = null
  ) => {
    dispatch(
      simCardsRequest(
        status,
        serviceProviderID,
        simCardBorrowerType,
        simCardBorrowerID
      )
    );
  },
  searchRecords: (e) => {
    dispatch(simCardSearchRequest(e));
    dispatch(searchSimCard(e));
  },
  fetchServiceProviders: () => {
    dispatch(serviceProviderRequest());
  },
  fetchSimCardBorrowerListRequest: () => {
    dispatch(simCardBorrowerListRequest());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SimCardIndex);
