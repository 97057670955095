import React from "react";
import PropTypes from "prop-types";

import { Translate } from "react-i18nify";
import TranslateDate from "components/i18n/TranslateDate";

import {
  faCalendarWeek,
  faEnvelope,
  faPhoneAlt,
  faSuitcase,
  faTty,
} from "@fortawesome/free-solid-svg-icons";
import { capitalizeWords } from "utils/string";
import CardWithHeader from "components/Card/WithHeader/CardWithHeader";

const BasicDetail = ({ numberPortingRequest, customerImportForm }) => {
  const {
    number_type: serviceType,
    debtor_name: debtorName,
    pbx_ref_number: pbxRefNumber,
    customer_email: customerEmail,
    requested_porting_date: requestedPortingDate,
  } = numberPortingRequest;

  const translatedRequestedPortingDate = requestedPortingDate ? (
    <TranslateDate date={requestedPortingDate} />
  ) : (
    <Translate value="common.notSet" />
  );

  const basicDetailContent = [
    {
      icon: faPhoneAlt,
      title: <Translate value="attributes.serviceType" />,
      content: capitalizeWords(serviceType),
    },
    {
      icon: faSuitcase,
      title: <Translate value="attributes.affiliation" />,
      content: debtorName,
    },
    !customerImportForm && {
      icon: faTty,
      title: <Translate value="attributes.pbx" />,
      content: pbxRefNumber,
    },
    customerEmail && {
      icon: faEnvelope,
      title: <Translate value="attributes.customerEmail" />,
      content: customerEmail,
    },
    !customerImportForm && {
      icon: faCalendarWeek,
      title: <Translate value="attributes.requestedPortingDate" />,
      content: translatedRequestedPortingDate,
    },
  ];

  return (
    <CardWithHeader
      title={<Translate value="common.basicDetails" />}
      className="shadow-sm"
      cardContent={basicDetailContent}
    />
  );
};

BasicDetail.defaultProps = {
  customerImportForm: false,
};

BasicDetail.propTypes = {
  customerImportForm: PropTypes.bool,
  numberPortingRequest: PropTypes.shape({
    number_type: PropTypes.string,
    debtor_name: PropTypes.string,
    pbx_ref_number: PropTypes.string,
    customer_email: PropTypes.string,
    requested_porting_date: PropTypes.instanceOf(Date),
  }).isRequired,
};

export default BasicDetail;
