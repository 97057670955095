import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { Admins, BusinessAdmins } from "constants/userRoles";
import { DETAILS, INVOICE } from "constants/routes/routesResources";

import InvoiceIndex from "views/Invoice/InvoiceIndex";
import InvoiceDetail from "views/Invoice/InvoiceDetail/InvoiceDetail";

const AdminInvoiceIndex = Admins(InvoiceIndex);
const BusinessAdminInvoiceDetail = BusinessAdmins(InvoiceDetail);

const InvoiceRoutes = () => (
  <Routes>
    <Route index element={<AdminInvoiceIndex />} />
    <Route path={INVOICE.INVOICE_ID}>
      <Route index element={<Navigate to={DETAILS} replace />} />
      <Route path={DETAILS} element={<BusinessAdminInvoiceDetail />} />
    </Route>
  </Routes>
);

export default InvoiceRoutes;
