import { AuthenticatedRequest } from "internal";

import apiUrlConfig from "configs/apiUrlConfig";

import { replaceMultiple } from "utils/string";

const URLS = {
  INDEX: `${apiUrlConfig.apiEndPoint()}/phone_numbers?filter[number_type]=:numberType&filter[status]=:status&filter[cost_type]=:costType`,
  SERVICE_PROVIDERS: `${apiUrlConfig.apiEndPoint()}/service_providers`,
  CREATE: `${apiUrlConfig.apiEndPoint()}/phone_numbers`,
  BULK_CREATE: `${apiUrlConfig.apiEndPoint()}/phone_numbers/bulk_create`,
  DETAIL: `${apiUrlConfig.apiEndPoint()}/phone_numbers/:phoneNumberID`,
  UPDATE: `${apiUrlConfig.apiEndPoint()}/phone_numbers/:phoneNumberID`,
  DELETE: `${apiUrlConfig.apiEndPoint()}/phone_numbers/:phoneNumberID`,
};

class PhoneNumberApi extends AuthenticatedRequest {
  // Fetch all services
  static fetchAll(numberType, status, costType) {
    const replaceWith = {
      ":numberType": numberType,
      ":status": status,
      ":costType": costType,
    };

    return this.get(replaceMultiple(URLS.INDEX, replaceWith));
  }

  static fetchServiceProviders() {
    return this.get(URLS.SERVICE_PROVIDERS);
  }

  /**
   *
   * @param {obj} attributes - Attribute to create phone number
   * @returns
   */
  static createPhoneNumber(attributes) {
    return this.post(URLS.CREATE, attributes);
  }

  /**
   *
   * @param {obj} attributes - Attribute to create bulk phone number
   * @returns
   */
  static createBulkPhoneNumber(attributes) {
    return this.post(URLS.BULK_CREATE, attributes);
  }

  /**
   *
   * @param {init} phoneNumberID - ID of phone number to fetch detail
   * @returns
   */
  static fetchPhoneNumberDetail(phoneNumberID) {
    const fetchDetailUrl = URLS.DETAIL.replace(":phoneNumberID", phoneNumberID);

    return this.get(fetchDetailUrl);
  }

  /**
   *
   * @param {init} phoneNumberID -  ID of phone number to be updated
   * @param {obj} attributes - Attribute to update phone number
   * @returns
   */

  static updatePhoneNumber(phoneNumberID, attributes) {
    const fetchDetailUrl = URLS.DETAIL.replace(":phoneNumberID", phoneNumberID);

    return this.patch(fetchDetailUrl, attributes);
  }

  /**
   *
   * @param {init} phoneNumberID - ID of phone number to be deleted.
   * @returns
   */
  static deletePhoneNumber(phoneNumberID) {
    const deleteUrl = URLS.DELETE.replace(":phoneNumberID", phoneNumberID);

    return this.delete(deleteUrl);
  }
}

export default PhoneNumberApi;
