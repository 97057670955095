import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, Row, FormGroup } from "reactstrap";
import { Translate } from "react-i18nify";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";

import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";
import { renderDropdownField } from "components/ReduxForm/RenderField";

import { formatUserLicensesDropdownOptions } from "utils/utils";
import { composeValidators, number, required } from "utils/validation";
import { processServerResponseForAsyncValidationErrors } from "utils/forms";

import { userLicensesPbxFetchRequest } from "redux/actions/pbx";
import { didNumberUpdateRequest } from "redux/actions/didNumber";

const AssignToUserLicenseModal = ({
  modalVisible,
  onCancel,
  fetchUserLicenses,
  updateDidNumber,
  userLicenses,
  commitSuccess,
  isSubmitting,
  didNumber,
}) => {
  const { id, pbx_id: pbxID } = didNumber;

  useEffect(() => {
    fetchUserLicenses(pbxID);
  }, []);

  const handleFormSubmit = async (values, form) => {
    const registeredFields = form.getRegisteredFields();

    let serverResponse = {};

    const attributes = {
      user_license_id: values.user_licenses,
    };

    await updateDidNumber(id, attributes, (errors) => {
      serverResponse = errors;
    });

    if (commitSuccess) {
      onCancel();
    }

    return processServerResponseForAsyncValidationErrors(
      serverResponse,
      registeredFields
    );
  };

  if (!modalVisible) return null;

  return (
    <Modal isOpen={modalVisible} toggle={onCancel} className="p-1">
      <Form onSubmit={handleFormSubmit}>
        {({ handleSubmit, submitting, pristine }) => (
          <form className="flexo-form w-100" onSubmit={handleSubmit}>
            <ModalBody className="pt-0">
              <p className="h4 fw-bold pt-3">
                <Translate value="common.assignToUserLicense" />
              </p>
              <p className="assign-sim-card py-2 mb-0">
                <Translate value="didNumber.chooseUserLicenseToAssignToThisDidNumber" />
              </p>
              <Row>
                <FormGroup>
                  <Field
                    name="user_licenses"
                    component={renderDropdownField}
                    validate={composeValidators(required, number)}
                    placeholderTranslation="attributes.selectUserLicense"
                    dropdownOptions={formatUserLicensesDropdownOptions(
                      userLicenses,
                      ["extension_number", "full_name"],
                      "name"
                    )}
                  />
                </FormGroup>
              </Row>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <button
                className=" btn btn-outline-secondary"
                type="button"
                onClick={onCancel}
              >
                <Translate value="common.cancel" />
              </button>
              <ReduxFormCommitButton
                title={<Translate value="common.assign" />}
                submitting={submitting}
                pristine={pristine}
                isLoading={isSubmitting}
                submitClassName="btn-primary"
              />
            </ModalFooter>
          </form>
        )}
      </Form>
    </Modal>
  );
};

AssignToUserLicenseModal.defaultProps = {
  commitSuccess: false,
  isSubmitting: false,
};

AssignToUserLicenseModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  userLicenses: PropTypes.arrayOf(
    PropTypes.shape({
      extension_number: PropTypes.string,
      full_name: PropTypes.string,
      id: PropTypes.number,
    })
  ).isRequired,
  fetchUserLicenses: PropTypes.func.isRequired,
  updateDidNumber: PropTypes.func.isRequired,
  commitSuccess: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  didNumber: PropTypes.shape({
    id: PropTypes.number,
    pbx_id: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = ({ pbx, didNumber: didNumberReducer }) => {
  const { userLicenses } = pbx;
  const { commitSuccess, isSubmitting } = didNumberReducer;

  return {
    userLicenses,
    commitSuccess,
    isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUserLicenses: (id) => {
    dispatch(userLicensesPbxFetchRequest(id));
  },

  updateDidNumber: (id, attributes, callback) => {
    dispatch(didNumberUpdateRequest(id, attributes, true, callback));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignToUserLicenseModal);
