import {
  VENDORS_FETCH_REQUEST,
  VENDORS_FETCH_RECEIVED,
  VENDORS_FETCH_FAILED,
  VENDORS_SEARCH_REQUEST,
  VENDORS_FETCH_CLEAR_ERRORS,
} from "redux/actions/vendor";

const defaultState = {
  vendors: [],
  fetchError: "",
  commitSuccess: false,
  isSearching: false,
};

function vendor(state = defaultState, action) {
  switch (action.type) {
    case VENDORS_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: null,
      };

    case VENDORS_FETCH_RECEIVED:
      return {
        ...state,
        vendors: action.vendors,
        isLoading: false,
        fetchError: null,
      };

    case VENDORS_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case VENDORS_FETCH_CLEAR_ERRORS:
      return {
        ...state,
        fetchError: "",
        isLoading: false,
        commitSuccess: false,
      };

    case VENDORS_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    default:
      return state;
  }
}

export default vendor;
