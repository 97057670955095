import {
  FETCH_PHONE_NUMBERS_REQUEST,
  FETCH_PHONE_NUMBERS_RECEIVED,
  FETCH_PHONE_NUMBERS_ERROR,
  CLEAR_PHONE_NUMBERS_ERRORS,
  PHONE_NUMBER_SEARCH_REQUEST,
  FETCH_SERVICE_PROVIDERS_REQUEST,
  FETCH_SERVICE_PROVIDERS_RECEIVED,
  FETCH_SERVICE_PROVIDERS_ERROR,
  CREATE_PHONE_NUMBER_REQUEST,
  CREATE_PHONE_NUMBER_RECEIVED,
  CREATE_PHONE_NUMBER_ERROR,
  CREATE_BULK_PHONE_NUMBER_REQUEST,
  CREATE_BULK_PHONE_NUMBER_RECEIVED,
  CREATE_BULK_PHONE_NUMBER_ERROR,
  FETCH_PHONE_NUMBER_DETAIL_REQUEST,
  FETCH_PHONE_NUMBER_DETAIL_RECEIVED,
  FETCH_PHONE_NUMBER_DETAIL_ERROR,
  UPDATE_PHONE_NUMBER_REQUEST,
  UPDATE_PHONE_NUMBER_RECEIVED,
  UPDATE_PHONE_NUMBER_ERROR,
  DELETE_PHONE_NUMBER_REQUEST,
  DELETE_PHONE_NUMBER_RECEIVED,
  DELETE_PHONE_NUMBER_ERROR,
} from "redux/actions/phoneNumber";

const defaultState = {
  phoneNumbers: [],
  serviceProviders: [],
  fetchError: "",
  commitSuccess: false,
  isSearching: false,
  isLoading: false,
  isProcessing: false,
  isSubmitting: false,
  phoneNumber: {},
};

function phoneNumber(state = defaultState, action) {
  switch (action.type) {
    case FETCH_PHONE_NUMBERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: null,
      };

    case FETCH_PHONE_NUMBERS_RECEIVED:
      return {
        ...state,
        phoneNumbers: action.phoneNumbers,
        isLoading: false,
        fetchError: null,
      };

    case FETCH_PHONE_NUMBERS_ERROR:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case CLEAR_PHONE_NUMBERS_ERRORS:
      return {
        ...state,
        fetchError: "",
        isLoading: false,
        commitSuccess: false,
      };

    case PHONE_NUMBER_SEARCH_REQUEST:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    case FETCH_SERVICE_PROVIDERS_REQUEST:
      return {
        ...state,
        isProcessing: true,
        fetchError: "",
        commitSuccess: false,
      };

    case FETCH_SERVICE_PROVIDERS_RECEIVED:
      return {
        ...state,
        isProcessing: false,
        serviceProviders: action.serviceProviders,
      };

    case FETCH_SERVICE_PROVIDERS_ERROR:
      return {
        ...state,
        isProcessing: false,
        fetchError: action.message,
      };

    case CREATE_PHONE_NUMBER_REQUEST:
    case CREATE_BULK_PHONE_NUMBER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        fetchError: "",
      };

    case CREATE_PHONE_NUMBER_RECEIVED:
    case CREATE_BULK_PHONE_NUMBER_RECEIVED:
      return {
        ...state,
        isSubmitting: false,
        commitSuccess: true,
      };

    case CREATE_PHONE_NUMBER_ERROR:
    case CREATE_BULK_PHONE_NUMBER_ERROR:
      return {
        ...state,
        fetchError: action.message,
        isSubmitting: false,
      };

    case FETCH_PHONE_NUMBER_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
      };

    case FETCH_PHONE_NUMBER_DETAIL_RECEIVED:
      return {
        ...state,
        isLoading: false,
        phoneNumber: action.phoneNumber,
      };

    case FETCH_PHONE_NUMBER_DETAIL_ERROR:
      return {
        ...state,
        isLoading: false,
        fetchError: action.message,
      };

    case UPDATE_PHONE_NUMBER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        fetchError: "",
        commitSuccess: false,
      };

    case UPDATE_PHONE_NUMBER_RECEIVED:
      return {
        ...state,
        isSubmitting: false,
        commitSuccess: true,
      };

    case UPDATE_PHONE_NUMBER_ERROR:
      return {
        ...state,
        isLoading: false,
        fetchError: action.message,
      };

    case DELETE_PHONE_NUMBER_REQUEST:
      return {
        ...state,
        isProcessing: true,
        fetchError: "",
        commitSuccess: false,
      };

    case DELETE_PHONE_NUMBER_RECEIVED:
      return {
        ...state,
        isProcessing: false,
        fetchError: "",
        commitSuccess: true,
      };

    case DELETE_PHONE_NUMBER_ERROR:
      return {
        ...state,
        isProcessing: false,
        fetchError: action.message,
      };

    default:
      return state;
  }
}

export default phoneNumber;
