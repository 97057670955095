import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Admins } from "constants/userRoles";
import { EDIT, USER_LICENSE, DETAILS } from "constants/routes/routesResources";
import { USER_LICENSE as USER_LICENSE_ROUTES } from "constants/routes";

import UserLicenseDetail from "views/UserLicense/UserLicenseDetail";
import UserLicenseEdit from "views/UserLicense/UserLicenseEdit";
import CellPhoneCreate from "views/UserLicense/CellPhone/CellPhoneCreate";
import SipDeviceCreate from "views/UserLicense/SipDevice/SipDeviceCreate";
import DidNumberCreate from "views/UserLicense/DidNumber/DidNumberCreate";

const AdminUserLicenseEdit = Admins(UserLicenseEdit);
const AdminCellPhoneCreate = Admins(CellPhoneCreate);
const AdminSipDeviceCreate = Admins(SipDeviceCreate);
const AdminDidNumberCreate = Admins(DidNumberCreate);

const UserLicenseRoutes = () => (
  <Routes>
    <Route
      path={`${USER_LICENSE.USER_LICENSE_ID}/*`}
      element={<UserLicenseDetail />}
    >
      <Route index element={<Navigate to={DETAILS} replace />} />
    </Route>

    <Route
      path={`${USER_LICENSE.USER_LICENSE_ID}/${EDIT}`}
      element={<AdminUserLicenseEdit />}
    />

    <Route
      path={USER_LICENSE_ROUTES.NEW_CELL_PHONE}
      element={<AdminCellPhoneCreate />}
    />

    <Route
      path={USER_LICENSE_ROUTES.NEW_DID_NUMBER}
      element={<AdminDidNumberCreate />}
    />

    <Route
      path={USER_LICENSE_ROUTES.NEW_SIP_DEVICE}
      element={<AdminSipDeviceCreate />}
    />
  </Routes>
);

export default UserLicenseRoutes;
