import token from "utils/auth";

import { BaseRequest } from "internal";
import { DEFAULT_HEADERS } from "services/requests/Base";

class AuthenticatedRequestService extends BaseRequest {
  static authenticatedHeaders() {
    const authHeader = { Authorization: token() };
    const headers = { ...DEFAULT_HEADERS, ...authHeader };

    return headers;
  }

  static headers(fileDownload = false) {
    let defaultHeaders = { headers: this.authenticatedHeaders() };

    if (fileDownload)
      defaultHeaders = Object.assign(defaultHeaders, {
        responseType: "arraybuffer",
      });

    return { ...defaultHeaders };
  }
}

export default AuthenticatedRequestService;
