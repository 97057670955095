import React, { useEffect } from "react";
import { Translate } from "react-i18nify";
import { useNavigate } from "react-router-dom";
import { Field } from "react-final-form";
import { FormGroup, Row, Col, Input, Label } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { renderDropdownField } from "components/ReduxForm/RenderField";
import { required } from "utils/validation";
import { numberTypeDropdown } from "constants/dropdownOptions";
import ReduxFormCommitButton from "components/Button/Form/ReduxFormCommitButton";
import NumberType from "components/PhoneNumber/Form/NumberType";
import PriceField from "components/PhoneNumber/Form/PriceField";

import { serviceProviderRequest } from "redux/actions/phoneNumber";

const PhoneNumberForm = ({
  serviceProviders,
  isProcessing,
  fetchServiceProviders,
  isNumberRange,
  setNumberType,
  isGold,
  setCostType,
  submitting,
  pristine,
  isLoading,
  isEditForm,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    fetchServiceProviders();
  }, []);

  const numberTypeClassName = isEditForm ? "d-none" : "d-flex";

  return (
    <>
      <div className={`mb-3 ${numberTypeClassName}`}>
        <FormGroup check inline>
          <Input
            type="checkbox"
            id="number"
            onClick={() => setNumberType(false)}
            checked={!isNumberRange}
          />
          <Label htmlFor="number" className="fw-normal">
            <Translate value="common.number" />
          </Label>
        </FormGroup>
        <FormGroup check inline>
          <Input
            type="checkbox"
            id="numberRange"
            onClick={() => setNumberType(true)}
            checked={isNumberRange}
          />
          <Label htmlFor="numberRange" className="fw-normal">
            <Translate value="common.numberRange" />
          </Label>
        </FormGroup>
      </div>
      <div className="pbx-content">
        <Row>
          <NumberType isNumberRange={isNumberRange} isEditForm={isEditForm} />
          <Col md="6">
            <FormGroup>
              <Field
                name="number_type"
                component={renderDropdownField}
                validate={required}
                dropdownOptions={numberTypeDropdown}
                labelTranslation="attributes.numberType"
                placeholderTranslation="attributes.numberType"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <Field
                name="owner"
                component={renderDropdownField}
                validate={required}
                labelTranslation="attributes.owner"
                placeholderTranslation="attributes.owner"
                isLoading={isProcessing}
                dropdownOptions={serviceProviders}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <Row className="h-100">
              <Col md="4">
                <p className="mb-3">
                  <Translate value="common.cost" />
                </p>
                <div className="mb-0 d-flex">
                  <FormGroup check inline>
                    <Field
                      id="free"
                      name="free"
                      component="input"
                      type="checkbox"
                      onClick={() => setCostType(false)}
                      checked={!isGold}
                      className="form-check-input"
                    />
                    <Label htmlFor="free" className="fw-normal">
                      <Translate value="common.free" />
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Field
                      id="gold"
                      name="gold"
                      component="input"
                      type="checkbox"
                      onClick={() => setCostType(true)}
                      checked={isGold}
                      className="form-check-input"
                      data-cy="gold-phone-number-checkbox"
                    />
                    <Label htmlFor="gold" className="fw-normal">
                      <Translate value="common.gold" />
                    </Label>
                  </FormGroup>
                </div>
              </Col>
              <PriceField show={isGold} />
            </Row>
          </Col>
        </Row>
      </div>
      <div className="flexo-form-footer d-flex flex-column flex-md-row justify-content-between">
        <button
          className="btn btn-outline-secondary mt-4 mb-md-3 px-md-3"
          type="button"
          onClick={() => navigate(-1)}
        >
          <Translate value="common.cancel" />
        </button>

        <ReduxFormCommitButton
          title={<Translate value="common.save" />}
          submitting={submitting}
          pristine={pristine}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

PhoneNumberForm.defaultProps = {
  isNumberRange: false,
  setNumberType: () => {},
  isGold: false,
  setCostType: () => {},
  isEditForm: false,
};

PhoneNumberForm.propTypes = {
  serviceProviders: PropTypes.shape([]).isRequired,
  isProcessing: PropTypes.bool.isRequired,
  fetchServiceProviders: PropTypes.func.isRequired,
  isNumberRange: PropTypes.bool,
  setNumberType: PropTypes.func,
  isGold: PropTypes.bool,
  setCostType: PropTypes.func,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isEditForm: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { serviceProviders, isProcessing } = state.phoneNumber;

  const ownerOptions = serviceProviders.map(({ name, id, ...rest }) => ({
    value: id,
    label: name,
    ...rest,
  }));

  return {
    serviceProviders: ownerOptions,
    isProcessing,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchServiceProviders: () => {
    dispatch(serviceProviderRequest());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberForm);
