import { createSearchAction } from "redux-search";

import { BillsApi, DebtorApi } from "internal";

import { getAndDisplayErrors } from "utils/utils";

export const BILLS_FETCH_REQUEST = "BILLS_FETCH_REQUEST";
export const BILLS_FETCH_RECEIVED = "BILLS_FETCH_RECEIVED";
export const BILLS_FETCH_FAILED = "BILLS_FETCH_FAILED";
export const VENDOR_BILLS_FETCH_REQUEST = "VENDOR_BILLS_FETCH_REQUEST";
export const VENDOR_BILLS_FETCH_RECEIVED = "VENDOR_BILLS_FETCH_RECEIVED";
export const VENDOR_BILLS_FETCH_FAILED = "VENDOR_BILLS_FETCH_FAILED";
export const BILLS_SEARCH_REQUEST = "BILLS_SEARCH_REQUEST";
export const BILLS_FETCH_CLEAR_ERRORS = "BILLS_FETCH_CLEAR_ERRORS";

export const searchBills = createSearchAction("bills");

export function billsFetchReceived(response) {
  return {
    type: BILLS_FETCH_RECEIVED,
    bills: response.data,
  };
}

export function billsFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: BILLS_FETCH_FAILED,
    message,
  };
}

export const billsFetchRequest = () => (dispatch) => {
  dispatch({ type: BILLS_FETCH_REQUEST });

  BillsApi.fetchAll()
    .then((data) => dispatch(billsFetchReceived(data)))
    .catch((e) => {
      dispatch(billsFetchFailed(e));
    });
};

export const vendorBillsFetchReceived = (response) => ({
  type: VENDOR_BILLS_FETCH_RECEIVED,
  bills: response.data,
});

export const vendorBillsFetchFailed = (errorData) => {
  const message = getAndDisplayErrors(errorData);

  return {
    type: VENDOR_BILLS_FETCH_FAILED,
    message,
  };
};

export const vendorBillsFetchRequest = (vendorID) => (dispatch) => {
  dispatch({
    type: VENDOR_BILLS_FETCH_REQUEST,
  });

  DebtorApi.bills(vendorID)
    .then((data) => dispatch(vendorBillsFetchReceived(data)))
    .catch((err) => {
      dispatch(vendorBillsFetchFailed(err));
    });
};

export function billsFetchClearErrors() {
  return {
    type: BILLS_FETCH_CLEAR_ERRORS,
  };
}

export function billsSearchRequest(value) {
  const isSearching = Boolean(value);

  return {
    type: BILLS_SEARCH_REQUEST,
    isSearching,
  };
}
