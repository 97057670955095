import { t } from "react-i18nify";

// Validations for redux form

const required = (value) =>
  value ? undefined : t("validationErrors.REQUIRED");
const maxLength = (max) => (value) =>
  value && value.length > max ? t("validationErrors.TOO_LONG") : undefined;
const minLength = (min) => (value) =>
  value && value.length < min
    ? t("validationErrors.TOO_SHORT", { count: min })
    : undefined;
const number = (value) =>
  // eslint-disable-next-line no-restricted-globals
  value && isNaN(Number(value))
    ? t("validationErrors.NOT_A_NUMBER")
    : undefined;
const minValue = (min) => (value) =>
  value && parseInt(value, 16) < parseInt(min, 16)
    ? t("validationErrors.TOO_SHORT", { count: min })
    : undefined;
const maxValue = (max) => (value) =>
  value && value > max
    ? t("validationErrors.TOO_SHORT", { count: max })
    : undefined;
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
    ? t("validationErrors.INVALID_EMAIL_ADDRESS")
    : undefined;
const tooYoung = (value) =>
  value && value < 13 ? t("validationErrors.MIN_AGE") : undefined;
const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? t("validationErrors.ALPHANUMERIC_ONLY")
    : undefined;
const phoneNumber = (value) =>
  value && !/^[\d()\-+]+$/i.test(value)
    ? t("validationErrors.INVALID_PHONE_NUMBER")
    : undefined;

const minPhoneNumber = (min) => (value) => {
  if (value && value.length < min) {
    return t("validationErrors.SHORT_PHONE_NUMBER", { count: min });
  }

  return undefined;
};

const cannotBeNegative = (value) => {
  if (value < 0) {
    return t("validationErrors.CANNOT_BE_NEGATIVE");
  }

  return undefined;
};

const cannotBeZeroOrNegative = (value) => {
  if (Number(value) === 0) {
    return t("validationErrors.CANNOT_BE_ZERO");
  }
  if (value < 0) {
    return t("validationErrors.CANNOT_BE_NEGATIVE");
  }

  return undefined;
};

const pinCodeValidation = (value) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(Number(value))) return t("validationErrors.NOT_A_DIGIT");

  const valueLength = value.toString().length;

  if (valueLength >= 4 && valueLength <= 8) {
    return undefined;
  }

  return t("validationErrors.PIN_CODE_VALIDATION");
};

const pukCodeValidation = (value) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(Number(value))) return t("validationErrors.NOT_A_DIGIT");

  if (value.toString().length !== 8) {
    return t("validationErrors.PUK_CODE_VALIDATION");
  }

  return undefined;
};

const phoneNumberValidation = (value) => {
  // ERROR: Issue #1 - CONSTANT is unable to recognize the regex
  if (value && !/^[\d()+]+$/i.test(value)) {
    return t("validationErrors.ONLY_NUMBER_AND_PLUS");
  }
  const remainingCharacters = value.substring(1);

  if (remainingCharacters.indexOf("+") >= 0) {
    return t("validationErrors.ONLY_NUMBER_AND_PLUS");
  }

  return undefined;
};

const websiteValidation = (value) => {
  if (value && !/^(|http|https):\/\/[^ "]+$/.test(value)) {
    return t("validationErrors.INVALID_URL");
  }

  return undefined;
};

const requiredForNumber = (value) =>
  value || value === 0 ? undefined : t("validationErrors.REQUIRED");

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator?.(value),
      undefined
    );

export {
  required,
  maxLength,
  minLength,
  number,
  minValue,
  maxValue,
  email,
  tooYoung,
  alphaNumeric,
  phoneNumber,
  minPhoneNumber,
  phoneNumberValidation,
  pinCodeValidation,
  pukCodeValidation,
  websiteValidation,
  cannotBeNegative,
  cannotBeZeroOrNegative,
  requiredForNumber,
};
