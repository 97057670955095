import React from "react";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";
import { Translate, t } from "react-i18nify";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";

const SipDeviceIndexContent = ({
  sipDevices,
  isLoading,
  isSearching,
  parentActiveStatus,
  parentID,
  searchRecords,
  parentResource,
}) => {
  const hideAddButton = !parentActiveStatus || parentResource === "pbx";
  const userLicenseColumn = parentResource === "pbx" && (
    <Column
      width={180}
      label={<Translate value="attributes.userLicense" />}
      dataKey="user_license_extension_number"
    />
  );

  return (
    <RecordListingTable
      records={sipDevices}
      isLoading={isLoading}
      hideAddButton={hideAddButton}
      resourceName="sip_devices"
      resourceTitle={t("title.page.resource.list", {
        resource: t("title.page.desktopPhones"),
      })}
      parentID={parentID}
      isSearching={isSearching}
      searchRecords={searchRecords}
      dataCy={`create-${parentResource}-sip_devices`}
    >
      <Column
        width={180}
        label={<Translate value="attributes.extensionNumber" />}
        dataKey="extension_number"
      />
      <Column
        width={180}
        label={<Translate value="attributes.name" />}
        dataKey="ex_name"
      />
      <Column
        width={180}
        label={<Translate value="attributes.description" />}
        dataKey="ex_description"
      />
      {userLicenseColumn}
    </RecordListingTable>
  );
};

SipDeviceIndexContent.defaultProps = {
  parentID: undefined,
  isLoading: true,
  sipDevices: undefined,
  isSearching: false,
};

SipDeviceIndexContent.propTypes = {
  sipDevices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      extension_number: PropTypes.number,
      user_license_id: PropTypes.number,
      user_license_extension_number: PropTypes.number,
      ex_description: PropTypes.string,
      ex_name: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  parentID: PropTypes.string,
  parentActiveStatus: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool,
  searchRecords: PropTypes.func.isRequired,
  parentResource: PropTypes.string.isRequired,
};

export default SipDeviceIndexContent;
