import React from "react";
import { Link } from "react-router-dom";
import { Translate } from "react-i18nify";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import HasPermission from "hoc/RbacCTA";

const CreateButton = ({
  isArchivedResource,
  resourceName,
  createResourceUrl,
  dataCy,
}) => {
  const hidden = isArchivedResource || resourceName === "contacts";

  if (hidden) return null;

  return (
    <HasPermission perform={`${resourceName}:create`}>
      <div>
        <Link
          to={createResourceUrl}
          className="btn btn-primary"
          data-cy={dataCy}
        >
          <FontAwesomeIcon icon={faPlus} />
          <Translate value="common.add" />
        </Link>
      </div>
    </HasPermission>
  );
};

CreateButton.defaultProps = {
  isArchivedResource: false,
  dataCy: "",
};

CreateButton.propTypes = {
  isArchivedResource: PropTypes.bool,
  resourceName: PropTypes.string.isRequired,
  createResourceUrl: PropTypes.string.isRequired,
  dataCy: PropTypes.string,
};

export default CreateButton;
