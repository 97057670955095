import { DashboardApi } from "internal";
import { getAndDisplayErrors } from "utils/utils";

export const DASHBOARD_DEBTOR_FETCH_REQUEST = "DASHBOARD_DEBTOR_FETCH_REQUEST";
export const DASHBOARD_DEBTOR_FETCH_RECEIVED =
  "DASHBOARD_DEBTOR_FETCH_RECEIVED";
export const DASHBOARD_DEBTOR_FETCH_FAILED = "DASHBOARD_DEBTOR_FETCH_FAILED";
export const DASHBOARD_DEBTOR_FETCH_CLEAR_ERRORS =
  "DASHBOARD_DEBTOR_FETCH_CLEAR_ERRORS";

export function dashboardDebtorFetchReceived(response) {
  return {
    type: DASHBOARD_DEBTOR_FETCH_RECEIVED,
    data: response.data,
  };
}

export function dashboardDebtorFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: DASHBOARD_DEBTOR_FETCH_FAILED,
    message,
  };
}

export function dashboardDebtorFetchClearErrors() {
  return {
    type: DASHBOARD_DEBTOR_FETCH_CLEAR_ERRORS,
  };
}

export const dashboardDebtorFetchRequest = () => (dispatch) => {
  dispatch({ type: DASHBOARD_DEBTOR_FETCH_REQUEST });

  DashboardApi.fetchDebtorDasboardDetail()
    .then((data) => dispatch(dashboardDebtorFetchReceived(data)))
    .catch((e) => {
      dispatch(dashboardDebtorFetchFailed(e));
    });
};
