import React from "react";
import Skeleton from "react-loading-skeleton";

const AgreementsSkeletion = () => {
  return (
    <div className="bg-white rounded shadow-sm h-100">
      <div className="p-3 border-bottom">
        <Skeleton height={18} width={150} />
      </div>
      <div className="px-3 py-1 border-bottom">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center">
            <Skeleton circle height={30} width={30} className="me-3" />
            <Skeleton height={18} width={100} />
          </div>
          <div className="d-flex flex-column ms-3">
            <Skeleton height={18} width={40} />
          </div>
        </div>
      </div>
      <div className="px-3 py-1 border-bottom">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center">
            <Skeleton circle height={30} width={30} className="me-3" />
            <Skeleton height={18} width={60} />
          </div>
          <div className="d-flex flex-column ms-3">
            <Skeleton height={18} width={60} />
          </div>
        </div>
      </div>
      <div className="px-3 py-1 border-bottom">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center">
            <Skeleton circle height={30} width={30} className="me-3" />
            <Skeleton height={18} width={140} />
          </div>
          <div className="d-flex flex-column ms-3">
            <Skeleton height={18} width={40} />
          </div>
        </div>
      </div>
      <div className="px-3 py-1 border-bottom">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center">
            <Skeleton circle height={30} width={30} className="me-3" />
            <Skeleton height={18} width={130} />
          </div>
          <div className="d-flex flex-column ms-3">
            <Skeleton height={18} width={50} />
          </div>
        </div>
      </div>
      <div className="px-3 py-2 border-bottom">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center">
            <Skeleton circle height={30} width={30} className="me-3" />
            <Skeleton height={18} width={100} />
          </div>
          <div className="d-flex flex-column ms-3">
            <Skeleton height={18} width={50} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgreementsSkeletion;
