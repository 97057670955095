import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translate } from "react-i18nify";
import { Column } from "react-virtualized";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import {
  agreementFetchRequest,
  searchAgreements,
  agreementSearchRequest,
  debtorAgreementRequest,
  clearAgreements,
} from "redux/actions/agreement";

import HasPermission from "hoc/RbacCTA";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import AgreementStatus from "components/Agreement/AgreementIndex/AgreementStatus";
import TranslateNumber from "components/i18n/TranslateNumber";
import TranslateDate from "components/i18n/TranslateDate";

import { getParams } from "utils/queryString";
import filterSearchResults from "utils/filterSearchResults";
import { calculateTotalAmount } from "utils/agreement";

import { AGREEMENT, DEBTOR } from "constants/routes";
import { AGREEMENTS_FILTERS } from "constants/agreements";

const renderAgreementTranslateDate = ({ cellData }) => (
  <TranslateDate date={cellData} />
);

const renderAgreementTranslateNumber = ({ cellData }) => (
  <TranslateNumber value={cellData} minimumFractionDigits={2} />
);

const AgreementIndex = ({
  fetchAllAgreements,
  fetchDebtorAgreements,
  agreements,
  totalArchived,
  isLoading,
  className,
  parentID,
  clearFetchedAgreements,
  searchRecords,
  isSearching,
  fetchingDebtors,
  fetchError,
}) => {
  const { debtorID } = useParams();

  const filterID = parentID || debtorID;

  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const { filter = "active" } = getParams(search);

  const fetchAgreements = () => {
    if (filterID) {
      fetchDebtorAgreements(filterID, filter);
    } else {
      fetchAllAgreements(filter);
    }
  };

  useEffect(() => {
    const searchParams = `?filter=${filter}`;

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath, { replace: true });
  }, [filter]);

  useEffect(() => {
    fetchAgreements();
  }, [filter]);

  const changeFilter = ({ filter: selectedFilter }) => {
    clearFetchedAgreements();
    const searchParams = `?filter=${selectedFilter}`;

    const pathName =
      parentID || debtorID
        ? DEBTOR.addId(DEBTOR.AGREEMENTS, parentID || debtorID)
        : AGREEMENT.INDEX;

    navigate(`${pathName}${searchParams}`);
  };

  const totalAmount = calculateTotalAmount(agreements);

  return (
    <HasPermission
      perform="agreements:list"
      data={{
        debtorID: parseInt(debtorID, 10),
      }}
      redirect
    >
      <RecordListingTable
        records={agreements}
        isLoading={
          isLoading || fetchingDebtors || (agreements.length === 0 && isLoading)
        }
        totalDeactivated={totalArchived}
        className={className}
        showHeader
        resourceName="agreements"
        additionalHeaderContent={
          <AgreementStatus
            totalAmount={totalAmount}
            totalAgreement={agreements.length}
            filters={AGREEMENTS_FILTERS}
            changeFilter={changeFilter}
            debtorID={filterID}
            resource="agreement"
            selectedFilter={filter}
          />
        }
        resourceTitle={
          <Translate value="title.page.resource.list" resource="Agreement" />
        }
        parentID={filterID}
        searchRecords={searchRecords}
        isSearching={isSearching}
        fetchError={fetchError}
        onRetry={fetchAgreements}
        dataCy="create-agreement"
      >
        <Column
          label={<Translate value="attributes.number" />}
          dataKey="ref_number"
          width={180}
        />
        {!filterID && (
          <Column
            width={280}
            label={<Translate value="attributes.debtor" />}
            dataKey="debtor_name"
          />
        )}
        <Column
          width={230}
          label={<Translate value="attributes.startDate" />}
          dataKey="start_date"
          cellRenderer={renderAgreementTranslateDate}
        />
        <Column
          width={230}
          label={<Translate value="attributes.invoicedUntil" />}
          dataKey="invoiced_until"
          cellRenderer={renderAgreementTranslateDate}
        />
        <Column
          width={160}
          label={<Translate value="attributes.totalAmount" />}
          dataKey="total_amount"
          cellRenderer={renderAgreementTranslateNumber}
        />
      </RecordListingTable>
    </HasPermission>
  );
};

AgreementIndex.defaultProps = {
  agreements: [],
  isLoading: true,
  className: "",
  parentID: undefined,
  isSearching: false,
  fetchError: "",
  totalArchived: 0,
  fetchingDebtors: false,
};

AgreementIndex.propTypes = {
  agreements: PropTypes.arrayOf(
    PropTypes.shape({
      refNumber: PropTypes.string,
    })
  ),
  searchRecords: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  parentID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fetchAllAgreements: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  fetchingDebtors: PropTypes.bool,
  fetchError: PropTypes.string,
  clearFetchedAgreements: PropTypes.func.isRequired,
  fetchDebtorAgreements: PropTypes.func.isRequired,
  totalArchived: PropTypes.number,
};

const mapStateToProps = ({ debtor, agreement, search, auth }) => {
  const {
    agreements,
    isLoading,
    isSearching,
    fetchError,
    agreementOverview: { total_archived: totalArchived },
  } = agreement;
  const { debtors, isLoading: fetchingDebtors } = debtor;

  const { currentUser } = auth;

  const results = filterSearchResults({
    resource: agreements,
    searchResult: search.agreements,
  });

  return {
    agreements: results,
    totalArchived,
    isLoading,
    isSearching,
    debtors,
    fetchingDebtors,
    fetchError,
    currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchRecords: (e) => {
    dispatch(agreementSearchRequest(e));
    dispatch(searchAgreements(e));
  },
  fetchAllAgreements: (filter) => {
    dispatch(agreementFetchRequest(filter));
  },
  fetchDebtorAgreements: (debtorID, filter) => {
    dispatch(debtorAgreementRequest(debtorID, filter));
  },
  clearFetchedAgreements: () => {
    dispatch(clearAgreements());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AgreementIndex);
