import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Column } from "react-virtualized";
import { Translate } from "react-i18nify";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import {
  pbxFetchRequest,
  searchPbxs,
  pbxSearchRequest,
  debtorPbxFetchRequest,
} from "redux/actions/pbx";

import filterSearchResults from "utils/filterSearchResults";
import { getParams } from "utils/queryString";

import HasPermission from "hoc/RbacCTA";

import RecordListingTable from "components/Table/RecordListingTable/RecordListingTable";
import TranslateDate from "components/i18n/TranslateDate";
import ResourceHeader from "components/ResourceHeader/ResourceHeader";

import { PBX_FILTERS } from "constants/filters";

const renderPbxTranslateDate = ({ cellData }) => (
  <TranslateDate date={cellData} />
);

const PbxIndex = ({
  pbxs,
  isLoading,
  parentID,
  searchRecords,
  isSearching,
  fetchAllPbxs,
  fetchError,
  fetchDebtorPbxs,
}) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const { debtorID } = useParams();

  const { filter = "active" } = getParams(search);

  const fetchID = parentID || debtorID;

  const fetchPbxs = (selectedFilter) => {
    if (fetchID) {
      fetchDebtorPbxs(fetchID, selectedFilter);
    } else {
      fetchAllPbxs(selectedFilter);
    }
  };

  useEffect(() => {
    const searchParams = `?filter=${filter}`;

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath, { replace: true });
  }, [filter]);

  useEffect(() => {
    fetchPbxs(filter);
  }, [filter]);

  const changeFilter = ({ filter: selectedFilter }) => {
    const searchParams = `?filter=${selectedFilter}`;

    const newPath = `${pathname}${searchParams}`;

    navigate(newPath, { replace: true });
  };

  const allFilters = [
    {
      filter: PBX_FILTERS,
      selectedFilter: filter,
      type: "filter",
      headerTranslation: "common.filters",
    },
  ];

  return (
    <HasPermission
      perform="pbx:list"
      data={{
        debtorID: parseInt(debtorID, 10),
      }}
      redirect
    >
      <RecordListingTable
        records={pbxs}
        showHeader
        isLoading={isLoading}
        resourceName="pbxs"
        additionalHeaderContent={
          <ResourceHeader allFilters={allFilters} changeFilter={changeFilter} />
        }
        resourceTitle={
          <Translate value="title.page.resource.list" resource="Pbx" />
        }
        parentID={parentID || debtorID}
        searchRecords={searchRecords}
        isSearching={isSearching}
        fetchError={fetchError}
        onRetry={fetchAllPbxs}
        dataCy="create-pbx"
      >
        <Column
          width={180}
          label={<Translate value="attributes.number" />}
          dataKey="ref_number"
        />
        <Column
          width={180}
          label={<Translate value="attributes.name" />}
          dataKey="te_name"
        />
        {!fetchID && (
          <Column
            width={300}
            label={<Translate value="attributes.debtorName" />}
            dataKey="debtor_name"
          />
        )}
        <Column
          width={200}
          label={<Translate value="attributes.paymentType" />}
          dataKey="te_payment_type"
        />
        <Column
          width={210}
          label={<Translate value="attributes.deploymentDate" />}
          dataKey="deployment_date"
          cellRenderer={renderPbxTranslateDate}
        />
        <Column
          width={200}
          label={<Translate value="attributes.invoiceStartDate" />}
          dataKey="invoice_start_date"
          cellRenderer={renderPbxTranslateDate}
        />
      </RecordListingTable>
    </HasPermission>
  );
};

PbxIndex.defaultProps = {
  isLoading: true,
  parentID: undefined,
  isSearching: false,
  fetchError: null,
};

PbxIndex.propTypes = {
  pbxs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      category: PropTypes.string,
    })
  ).isRequired,
  isLoading: PropTypes.bool,
  fetchAllPbxs: PropTypes.func.isRequired,
  parentID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  searchRecords: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  fetchError: PropTypes.string,
  fetchDebtorPbxs: PropTypes.func.isRequired,
};

const mapStateToProps = ({ pbx, search }) => {
  const { pbxs, isLoading, isSearching, fetchError } = pbx;

  const results = filterSearchResults({
    resource: pbxs,
    searchResult: search.pbxs,
  });

  return {
    pbxs: results,
    isLoading,
    isSearching,
    fetchError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchRecords: (e) => {
    dispatch(pbxSearchRequest(e));
    dispatch(searchPbxs(e));
  },
  fetchAllPbxs: (filter) => {
    dispatch(pbxFetchRequest(filter, null));
  },
  fetchDebtorPbxs: (debtorID, filter) => {
    dispatch(debtorPbxFetchRequest(debtorID, filter));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PbxIndex);
