import { createSearchAction } from "redux-search";
import { toastr } from "react-redux-toastr";

import { t } from "react-i18nify";

import { UserLicenseApi } from "internal";

import {
  displayFetchErrorToaster,
  getServerError,
  getAndDisplayErrors,
} from "utils/utils";

import { DEFAULT_FILTER } from "constants/filters";

export const USER_LICENSE_FETCH_REQUEST = "USER_LICENSE_FETCH_REQUEST";
export const USER_LICENSE_FETCH_RECEIVED = "USER_LICENSE_FETCH_RECEIVED";
export const USER_LICENSE_FETCH_FAILED = "USER_LICENSE_FETCH_FAILED";
export const SINGLE_USER_LICENSE_FETCH_REQUEST =
  "SINGLE_USER_LICENSE_FETCH_REQUEST";
export const SINGLE_USER_LICENSE_FETCH_RECEIVED =
  "SINGLE_USER_LICENSE_FETCH_RECEIVED";
export const SINGLE_USER_LICENSE_FETCH_FAILED =
  "SINGLE_USER_LICENSE_FETCH_FAILED";
export const SINGLE_USER_LICENSE_DELETE_REQUEST =
  "SINGLE_USER_LICENSE_DELETE_REQUEST";
export const SINGLE_USER_LICENSE_DELETE_RECEIVED =
  "SINGLE_USER_LICENSE_DELETE_RECEIVED";
export const SINGLE_USER_LICENSE_DELETE_FAILED =
  "SINGLE_USER_LICENSE_DELETE_FAILED";
export const USER_LICENSE_UPDATE_REQUEST = "USER_LICENSE_UPDATE_REQUEST";
export const USER_LICENSE_UPDATE_RECEIVED = "USER_LICENSE_UPDATE_RECEIVED";
export const USER_LICENSE_UPDATE_FAILED = "USER_LICENSE_UPDATE_FAILED";
export const USER_LICENSE_CREATE_REQUEST = "USER_LICENSE_CREATE_REQUEST";
export const USER_LICENSE_CREATE_RECEIVED = "USER_LICENSE_CREATE_RECEIVED";
export const USER_LICENSE_CREATE_FAILED = "USER_LICENSE_CREATE_FAILED";
export const USER_LICENSE_FETCH_CLEAR_ERRORS =
  "USER_LICENSE_FETCH_CLEAR_ERRORS";
export const USER_LICENSE_SEARCH_REQUEST = "USER_LICENSE_SEARCH_REQUEST";

export function userLicenseFetchReceived(response) {
  return {
    type: USER_LICENSE_FETCH_RECEIVED,
    userLicenses: response.data,
  };
}

export function userLicenseFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: USER_LICENSE_FETCH_FAILED,
    message,
  };
}

// delete single user license
export const singleUserLicenseDeleteReceived = (response, cb) => (dispatch) => {
  dispatch({
    type: SINGLE_USER_LICENSE_DELETE_RECEIVED,
  });

  toastr.success(
    t("common.success"),
    t("toasterMessage.resource.delete", {
      resource: t("title.tab.pbx.userLicense"),
    })
  );

  if (typeof cb === "function") {
    cb();
  }
};

export const singleUserLicenseDeleteFailed = (errorData) => {
  const { response } = errorData;
  const error = getServerError(response);
  const errorText =
    error === "not found" ? t("toasterMessage.processError") : error;

  displayFetchErrorToaster(errorText);

  return (dispatch) => {
    dispatch({
      type: SINGLE_USER_LICENSE_DELETE_FAILED,
      message: errorText,
    });
  };
};

export const singleUserLicenseDeleteRequest = (id, cb) => (dispatch) => {
  dispatch({
    type: SINGLE_USER_LICENSE_DELETE_REQUEST,
  });

  UserLicenseApi.deleteOne(id)
    .then((data) => dispatch(singleUserLicenseDeleteReceived(data, cb)))
    .catch((e) => {
      dispatch(singleUserLicenseDeleteFailed(e));
    });
};

export function userLicenseFetchClearErrors() {
  return {
    type: USER_LICENSE_FETCH_CLEAR_ERRORS,
  };
}

// Fetch all userLicenses
export const userLicenseFetchRequest =
  (pbxID, filter = DEFAULT_FILTER) =>
  (dispatch) => {
    dispatch({ type: USER_LICENSE_FETCH_REQUEST });

    UserLicenseApi.fetchAll(pbxID, filter)
      .then((data) => dispatch(userLicenseFetchReceived(data)))
      .catch((e) => {
        dispatch(userLicenseFetchFailed(e));
      });
  };

// Fetch single userLicense
export function singleUserLicenseFetchReceived(response) {
  return {
    type: SINGLE_USER_LICENSE_FETCH_RECEIVED,
    userLicense: response.data,
  };
}

export function singleUserLicenseFetchFailed(errorData) {
  const message = getAndDisplayErrors(errorData);

  return {
    type: SINGLE_USER_LICENSE_FETCH_FAILED,
    message,
  };
}

export const singleUserLicenseFetchRequest = (id) => (dispatch) => {
  dispatch({ type: SINGLE_USER_LICENSE_FETCH_REQUEST });

  UserLicenseApi.fetchOne(id)
    .then((data) => dispatch(singleUserLicenseFetchReceived(data)))
    .catch((e) => {
      dispatch(singleUserLicenseFetchFailed(e));
    });
};

// Update a userLicense
export function userLicenseUpdateReceived(response) {
  return {
    type: USER_LICENSE_UPDATE_RECEIVED,
    userLicense: response.data,
  };
}

export function userLicenseUpdateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response);
  }

  return {
    type: USER_LICENSE_UPDATE_FAILED,
    message: response.data,
  };
}

export const userLicenseUpdateRequest =
  (id, attributes, callback) => async (dispatch) => {
    dispatch({ type: USER_LICENSE_UPDATE_REQUEST });

    await UserLicenseApi.update(id, attributes)
      .then((data) => {
        dispatch(userLicenseUpdateReceived(data));
        callback();
      })
      .catch((errors) => {
        dispatch(userLicenseUpdateFailed(errors, callback));
      });
  };

// Create a userLicense
export function userLicenseCreateReceived(response) {
  return {
    type: USER_LICENSE_CREATE_RECEIVED,
    userLicense: response.data,
  };
}

export function userLicenseCreateFailed(errorData, callback = () => {}) {
  const { response } = errorData;

  if (response && response.data) {
    callback(response.data);
  }

  return {
    type: USER_LICENSE_CREATE_FAILED,
    message: response.data,
  };
}

export const userLicenseCreateRequest =
  (attributes, cb) => async (dispatch) => {
    dispatch({ type: USER_LICENSE_CREATE_REQUEST });

    await UserLicenseApi.create(attributes)
      .then((data) => {
        dispatch(userLicenseCreateReceived(data));
        cb();
      })
      .catch((e) => {
        dispatch(userLicenseCreateFailed(e, cb));
      });
  };

// Search User licenses
export const searchUserLicenses = createSearchAction("userLicenses");

export const userLicenseSearchRequest = (value) => {
  const isSearching = Boolean(value);

  return {
    type: USER_LICENSE_SEARCH_REQUEST,
    isSearching,
  };
};
