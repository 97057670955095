import React from "react";
import { Translate } from "react-i18nify";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TranslateNumber from "components/i18n/TranslateNumber";

const ItemRow = ({ icon, iconClassName, title, value, minFractionDigits }) => (
  <div className="table-row">
    <span>
      <FontAwesomeIcon icon={icon} className={iconClassName} />
      <Translate value={title} />
    </span>
    <span>
      <TranslateNumber
        value={value}
        minimumFractionDigits={minFractionDigits}
      />
    </span>
  </div>
);

ItemRow.defaultProps = {
  icon: "",
  iconClassName: "",
  title: "",
  value: "",
  minFractionDigits: 0,
};

ItemRow.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  iconClassName: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minFractionDigits: PropTypes.number,
};

export default ItemRow;
